import React from 'react';
import { NavLink } from 'react-router-dom';
import { clsx } from 'clsx';
import styles from './NavigateBackButton.module.css';

// IMAGES
import whiteArrowBack from '../../../../images/arrow-back-white.svg';
import blueArrowBack from '../../../../images/arrow-back-blue.svg';
import pinkArrowBack from '../../../../images/arrow-back-pink.svg';

type NavigateBackButtonType = 'white' | 'pink' | 'blue';

const iconMap: Record<NavigateBackButtonType, string> = {
  white: whiteArrowBack,
  pink: pinkArrowBack,
  blue: blueArrowBack,
};

const fontColorMap: Record<NavigateBackButtonType, string> = {
  white: 'white-color',
  pink: 'color-primary',
  blue: 'headings-blue',
};

interface NavigateBackButtonProps {
  route: string | undefined;
  color: NavigateBackButtonType;
  textContent: string;
  additionalClassName?: string;
}

export const NavigateBackButton: React.FC<NavigateBackButtonProps> = ({
  route,
  color,
  textContent,
  additionalClassName,
}) => {
  const textColorStyle = { color: `var(--${fontColorMap[color]})` };

  return (
    <NavLink to={route || '/home'} className={clsx(styles.navBtn, additionalClassName)}>
      <img alt="Arrow back" src={iconMap[color]} className={styles.navBtn__Icon} />
      <p style={textColorStyle} className={styles.navBtn__textContent}>
        {textContent}
      </p>
    </NavLink>
  );
};
