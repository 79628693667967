export const apiUrls = {
  // User
  get_user: (email: string) => `/user?email=${email}`,
  put_user: '/user',
  update_user_from_admin: '/user/from-admin',
  update_user_count_download_csv: '/user/count',
  get_user_with_all_info: (userId: number) => `/user/all-info-about-user?userId=${userId}`,

  // Users
  get_users: '/user/all',

  // Users for admin panel
  get_all_users: (
    limit: number,
    skip: number,
    sortBy: string,
    searchValue: string,
    descOrAsc: string,
    typeOfSubscription: string,
    subscriptionStatus: string,
  ) =>
    `/users?limit=${limit}&skip=${skip}&sortBy=${sortBy}&searchValue=${searchValue}&descOrAsc=${descOrAsc}&typeOfSubscription=${typeOfSubscription}&subscriptionStatus=${subscriptionStatus}`,

  get_count_all_users: (
    sortBy: string,
    searchValue: string,
    descOrAsc: string,
    typeOfSubscription: string,
    subscriptionStatus: string,
  ) =>
    `/users/count-all-users?&sortBy=${sortBy}&searchValue=${searchValue}&descOrAsc=${descOrAsc}&typeOfSubscription=${typeOfSubscription}&subscriptionStatus=${subscriptionStatus}`,

  get_user_auth0: `user/get-user-info-from-auth0`,

  // States
  get_states: '/states',

  // Majors
  get_majors: '/majors',

  // Efcs
  get_efcs: '/efcs',

  // HouseHoldIncome
  get_household_income: '/household-income',

  // Highschools
  get_highschools_by_state_id: (stateId: number) => `/highschools?stateId=${stateId}`,

  // Coupon
  get_coupon_by_id: (couponId: string, packageId: number) => `/coupons/${couponId}?packageId=${packageId}`,

  // Colleges
  get_colleges: '/colleges',
  get_colleges_names: '/colleges/names-only',
  get_college_by_id: (collegeId: number) => `/colleges/unregistered/${collegeId}`,
  get_full_college_by_id: (collegeId: number, userId: number) => `/colleges/${collegeId}?userId=${userId}`,
  get_colleges_by_size: '/colleges/landing-page/small-and-large',
  get_nearest_colleges: (stateName: string) => `/colleges/landing-page/near-you?stateName=${stateName}`,
  get_private_colleges: '/colleges/landing-page/private',
  get_colleges_advanced_search: '/colleges/advanced-search',
  get_public_colleges: '/colleges/landing-page/public',
  put_college: (collegeId: number) => `/colleges/${collegeId}`,
  get_recommended_colleges: (userId: number) => `/colleges/recommended/${userId}`,
  get_recommended_colleges_by_category: (userId: number, category: string) =>
    `/colleges/recommended/${userId}?param=${category}`,
  get_updated_recommended_colleges_by_category: '/colleges/advanced-search',
  get_colleges_for_landing_page: (category: string) => `/colleges/landing-page/${category}`,

  // Offers
  get_all_offers: '/offers',
  get_offers: (userId: number) => `/offers/${userId}`,
  get_crowdsourced_offers: (ipeds: string, year: string) => `/offers?ipeds=${ipeds}&year=${year}`,
  get_crowdsourced_offers_for_pagination: (ipeds: string) => `/offers/only-id/${ipeds}`,

  // Subscription
  get_payment_intent: (packageId: number, userEmail: string, couponId: string | null) =>
    `/subscription/payment-intent?packageId=${packageId}&userEmail=${userEmail}&couponId=${couponId ?? ''}`,
  create_subscription: '/subscription',
  subscription: (id: number) => `/subscription/${id}`,
  change_status_subscription: (id: number) => `/subscription/status/${id}`,
  delete_subscription_by_subscriptionId: (subscriptionId: string) =>
    `/subscription/delete-subscription/${subscriptionId}`,
  get_subscription_by_userId: (userId: number) => `subscription/subscription-by-userId/${userId}`,
  get_payment_method_by_userId: (id: number) => `/subscription/payment-method/${id}`,
  admin_create_internal_subscription: '/subscription/admin/internal',
  admin_create_stripe_subscription: '/subscription/admin/stripe',

  cancel_subscription: (id: number) => `/subscription/cancel-subscription?id=${id}`,
  stripe_subscription_list: (subscriptionId: string) =>
    `/subscription/status-stripe-sub?subscriptionId=${subscriptionId}`,

  // Lists
  get_shared_list: (list: string) => `/list/shared/${list}`,
  get_my_lists: (userId: number) => `/user/my-list/${userId}`,
  get_my_lists_for_sharing: (id: number) => `/list/for-sharing/${id}`,
  get_my_list: (userId: number, listName: string) => `/user/my-list/${userId}?listName=${listName}`,
  my_list: '/user/my-list',
  create_list_and_add_college: '/user/my-list/add-college',
  update_list_name: (listId: number) => `/list/update-name/${listId}`,
  remove_colleges_from_all_my_list: (userId: number, collegeIds: number[]) =>
    `/user/my-list/all?userId=${userId}&collegeIds=${collegeIds}`,
  remove_college_from_my_list: (listId: number, collegeId: number) => `/list/${listId}/college/${collegeId}`,
  remove_colleges_from_my_list: (userId: number, listName: string, collegeIds: number[]) =>
    `/user/my-list?userId=${userId}&listName=${listName}&collegeIds=${collegeIds}`,
  list_by_id: (id: number) => `/list/${id}`,
  delete_college_from_all_lists: (userId: number, collegeId: number) =>
    `/list/delete/college-from-all-lists?userId=${userId}&collegeId=${collegeId}`,
  add_college_to_all_lists: '/list/add-college-to-all-lists',
  delete_all_colleges_from_list: (id: number) => `/list/delete-all-colleges/${id}`,
  get_paginated_colleges_my_list: (
    id: number,
    limit: number,
    skip: number,
    showRecommendations: boolean,
    sortBy?: string,
    order?: string,
  ) =>
    `/list/with-recommended/${id}?limit=${limit}&skip=${skip}${
      sortBy && order ? `&sortBy=${sortBy}&order=${order}` : ``
    }${showRecommendations ? `&recommended=${showRecommendations}` : ''}`,
  get_recommended_for_list_amount: (id: number) => `/list/details/${id}`,
  update_priority_in_list: (listId: string, priority: string) => `/list/update?listId=${listId}&priority=${priority}`,

  uploadCsvs: (type: string) => `/import/${type}`,
  download_users: (usersProfile: string, searchValue: string, typeOfSubscription: string, subscriptionStatus: string) =>
    `/download/users?usersProfile=${usersProfile}&searchValue=${searchValue}&typeOfSubscription=${typeOfSubscription}&subscriptionStatus=${subscriptionStatus}`,

  get_regions: '/regions',

  get_list_notes: (listId: number) => `/notes/by-list/${listId}`,
  create_note: '/notes',
  note_by_id: (id: number) => `/notes/${id}`,

  get_college_notes: (collegeId: number, userId: number) => `/college-notes/${userId}/by-college/${collegeId}`,
  get_user_notes: (userId: number) => `/college-notes/by-user/${userId}`,
  create_college_note: '/college-notes',
  college_note_by_id: (id: number) => `/college-notes/${id}`,

  // Backups
  get_list_backups: '/backup',
  delete_backup_by_name: (backupName: string) => `/backup/${backupName}`,
  download_backup_by_name: (backupName: string) => `/backup/upload/${backupName}`,
  restore_backup_by_name: (backupName: string) => `/backup/${backupName}`,
  create_backup: '/backup',

  // Backups-user-list
  download_backup_user_list_by_listId: (listId: number) => `/user-list/upload/${listId}`,
  get_all_list_backups_by_userId: (userId: number) => `/user-list/${userId}`,
  delete_backup_user_list_by_list_name: (listName: string) => `/user-list/${listName}`,

  // Petersons
  get_petersons_links: () => `/petersons`,
  download_peterson_file: (fileName: string) => `/petersons/${fileName}`,
  pull_petersons_data: () => `/petersons`,

  // History download csv for lists
  create_history_download: `/history-download`,
  get_history_download: (id: number) => `/history-download?id=${id}`,
  // Configurations for admin panel
  get_configurations: '/configuration',
  update_configuration: '/configuration',

  // Upload file for configuration
  upload_file_image_configuration: '/upload/image',

  // Email (SendGrid)
  send_email: '/email',

  // Shared List
  create_shared_list: `/shared-lists`,
  get_shared_list_by_urlId: (listId: number) => `/shared-lists?listId=${listId}`,
};
