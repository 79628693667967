import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { sharedListsService } from '../../services';
import { ISharedListResponse } from '../add-new-list-modal/AddNewListModal';
import './SaveListModal.css';

type Props = {
  close: () => void;
  onHandleSetIsOpenAddNewListModal: () => void;
};

export const SaveListModal = ({ close, onHandleSetIsOpenAddNewListModal }: Props) => {
  const { idSharedList } = useSelector((state: any) => state.myListsState);
  const [sharedList, setSharedList] = useState<ISharedListResponse | null>();

  useEffect(() => {
    if (!idSharedList) return;
    fetchSharedList(Number(idSharedList));
  }, []);

  const fetchSharedList = async (listId: number) => {
    const { data } = await sharedListsService.endpoint_get_sharedList(listId);
    if (data) {
      setSharedList(data);
    }
  };

  return (
    <section className="save-list-modal">
      <span className="save-list-modal__cross" onClick={close} />
      <p className="save-list-modal_text">
        {sharedList ? `Do you want to save ${sharedList?.listName}’s shared list to your account?` : ''}
      </p>
      <div className="save-list-modal__cont-btns">
        <button className="save-list-modal__btn no" onClick={close}>
          No
        </button>
        <button className="save-list-modal__btn" onClick={onHandleSetIsOpenAddNewListModal}>
          Yes
        </button>
      </div>
    </section>
  );
};
