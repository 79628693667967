import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const offerService = (function () {
  const endpoint_crowdsourced_offers = async (payload: { ipeds: string; year: string }) => {
    const { ipeds, year } = payload;
    return axiosService.get(apiUrls.get_crowdsourced_offers(ipeds, year));
  };

  const endpoint_crowdsourced_offers_for_pagination = async (ipeds: string) => {
    return axiosService.get(apiUrls.get_crowdsourced_offers_for_pagination(ipeds));
  };

  const endpoint_get_all_offers = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_all_offers);
  };

  const endpoint_get_offers = async (payload: { userId: number }) => {
    const { userId } = payload;

    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_offers(userId));
  };

  return {
    endpoint_get_all_offers,
    endpoint_get_offers,
    endpoint_crowdsourced_offers,
    endpoint_crowdsourced_offers_for_pagination,
  };
})();
