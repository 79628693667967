import { produce } from 'immer';

import { createReducer } from '.';
import { majorsActionTypes } from '../../consts';
import { IAction, IMajorsState } from '../../models';
import { majorsState } from '../states/majors.state';

export const majorsReducer = createReducer<IMajorsState>(majorsState, {
  [majorsActionTypes.SET_MAJORS]: setMajors,
});

function setMajors(major: IMajorsState, { payload }: IAction<Array<any>>) {
  return produce(major, (draft) => {
    draft.majors = payload;
  });
}
