import { produce } from 'immer';

import { createReducer } from '.';
import { collegesActionTypes } from '../../consts';
import { IAction, ICollege, ICollegesState } from '../../models';
import { collegesState } from '../states/colleges.state';

export const collegesReducer = createReducer<ICollegesState>(collegesState, {
  [collegesActionTypes.SET_COLLEGES]: setColleges,
  [collegesActionTypes.SET_COLLEGE]: setCollege,
  [collegesActionTypes.SET_SELECTED_COLLEGE_ID]: setSelectedCollegeId,
  [collegesActionTypes.SET_TRENDING_COLLEGES]: setTrendingColleges,
  [collegesActionTypes.SET_COLLEGES_NAMES]: setCollegesNames,
  [collegesActionTypes.SET_COLLEGE_BY_ID]: setCollegeById,
  [collegesActionTypes.SET_COLLEGES_ADVANCED_SEARCH]: setCollegesAdvancedSearch,
  [collegesActionTypes.SET_RECOMMENDED_COLLEGES]: setRecommendedColleges,
  [collegesActionTypes.SET_UPDATED_RECOMMENDED_COLLEGES]: setUpdatedRecommendedColleges,
});

function setColleges(state: ICollegesState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.colleges = payload;
  });
}

function setTrendingColleges(state: ICollegesState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.trendingColleges = payload;
  });
}

function setCollegesNames(state: ICollegesState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.collegesNames = payload;
  });
}

function setCollegeById(state: ICollegesState, { payload }: IAction<any>) {
  return produce(state, (draft) => {
    draft.selectedCollege = payload;
  });
}

function setCollege(state: ICollegesState, { payload }: IAction<any>) {
  return produce(state, (draft) => {
    if (draft.colleges && draft.colleges.length > 0) {
      const index = draft.colleges?.findIndex((val) => val.id === payload.id);

      draft.colleges[index] = { ...payload };
    }
  });
}

function setSelectedCollegeId(state: ICollegesState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedCollegeId = payload;
  });
}

function setCollegesAdvancedSearch(state: ICollegesState, { payload }: IAction<ICollege[]>) {
  return produce(state, (draft) => {
    draft.advancedColleges = payload;
  });
}

function setRecommendedColleges(state: ICollegesState, { payload }: IAction<ICollege[]>) {
  return produce(state, (draft) => {
    draft.recommendedColleges = payload;
  });
}

function setUpdatedRecommendedColleges(state: ICollegesState, { payload }: IAction<ICollege[]>) {
  return produce(state, (draft) => {
    draft.updatedRecommendedColleges = payload;
  });
}
