import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FreeUsersNavigator, FullUsersNavigator } from '.';
import { myListsAction, userAction } from '../store/actions';

export const ProfileCompletedNavigator = () => {
  const { user } = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(myListsAction.getMyLists(user.id));
  }, []);

  return user.subscription ? <FullUsersNavigator /> : <FreeUsersNavigator />;
};
