import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ENotesActionTypes } from '../../enums';
import { INote } from '../../models';
import { notesService } from '../../services';
import { notesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ENotesActionTypes.GET_LIST_NOTES as any, watchGetListNotes)]);
  yield all([takeLatest(ENotesActionTypes.GET_NOTE_BY_ID as any, watchGetNoteById)]);
  yield all([takeLatest(ENotesActionTypes.CREATE_NOTE as any, watchCreateNote)]);
  yield all([takeLatest(ENotesActionTypes.UPDATE_NOTE as any, watchUpdateNote)]);
  yield all([takeLatest(ENotesActionTypes.DELETE_NOTE as any, watchDeleteNote)]);
}

function* watchGetNoteById(action: { type: string; payload: number }) {
  try {
    const { data } = yield call(notesService.endpoint_get_note, action.payload);

    yield put(notesAction.setNote(data as INote));
  } catch (error: any) {
    console.error('watchGetNoteById: ', error.response);
  }
}

function* watchGetListNotes(action: { type: string; payload: number }) {
  try {
    const { data } = yield call(notesService.endpoint_get_list_notes, action.payload);

    yield put(notesAction.setListNotes(data as INote[]));
  } catch (error: any) {
    console.error('watchGetListNotes: ', error.response);
  }
}

function* watchCreateNote(action: { type: string; payload: Partial<INote> }) {
  try {
    yield call(notesService.endpoint_create_note, action.payload);

    const { data } = yield call(notesService.endpoint_get_list_notes, action.payload.listId as number);

    yield put(notesAction.setListNotes(data as INote[]));
  } catch (error: any) {
    console.error('watchCreateNote: ', error.response);
  }
}

function* watchUpdateNote(action: { type: string; payload: Partial<INote> }) {
  try {
    yield call(notesService.endpoint_update_note, action.payload.id as number, action.payload.text as string);

    const { data } = yield call(notesService.endpoint_get_list_notes, action.payload.listId as number);

    yield put(notesAction.setListNotes(data as INote[]));
  } catch (error: any) {
    console.error('watchUpdateNote: ', error.response);
  }
}

function* watchDeleteNote(action: { type: string; payload: Partial<INote> }) {
  try {
    yield call(notesService.endpoint_delete_note, action.payload.id as number);

    const { data } = yield call(notesService.endpoint_get_list_notes, action.payload.listId as number);

    yield put(notesAction.setListNotes(data as INote[]));
  } catch (error: any) {
    console.error('watchDeleteNote: ', error.response);
  }
}
