/* eslint-disable */
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { json, NavLink, useNavigate, useSearchParams } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { Autocomplete } from '../autocomplete/Autocomplete';
import './Home-page.css';
import { scoreSmartService } from '../../services/scoresmart.service';
import search from '../../images/Search.svg';
import searchBlack from '../../images/Search-black.svg';
import filters from '../../images/filters.svg';
import filtersPurple from '../../images/filters-purple.svg';
import { ICollege, IMyList } from '../../models';
import arrowDown from '../../images/select-arrow.svg';
import { calendarData, months } from '../../consts/calendar-data.const';
import arrowPrimary from '../../images/ArrowPrimary.svg';
import underline from '../../images/underline-decoration.svg';
import { SelectBtn } from '../select-btn/Select-btn';
import { collegesAction, loadingAction, myListsAction, previousRouteAction, userAction } from '../../store/actions';
import lock from '../../images/locked-icon.svg';
import { FirstModal } from '../first-modal/FirstModal';
import { ScoreSmartModal } from '../scoresmart-modal/ScoreSmartModal';
import { subscriptionService } from '../../services';
import { CollegeCard } from '../../UI/cards/college-card/CollegeCard';
import plus from '../../images/plus-icon.svg';
import { LockedResultsModal } from '../power-search/components/colleges-list/components/locked-results-modal/LockedResultsModal';

const useLocalStorage = () => {
  const recommendationCategory = localStorage.getItem('recommendationCategory');

  try {
    return recommendationCategory ? JSON.parse(recommendationCategory) : null;
  } catch {
    return null;
  }
};

const useLocalStorageRecommendedColleges = () => {
  const updatedCollegeOptions = localStorage.getItem('updateRecommendationSearch');

  try {
    return updatedCollegeOptions ? JSON.parse(updatedCollegeOptions) : null;
  } catch {
    return null;
  }
};

const recommendationCategories = [
  { id: 0, name: 'Location', value: 'location' },
  { id: 1, name: 'Majors', value: 'majors', property: 'collegeMajor' },
  { id: 4, name: 'GPA', value: 'gpa', property: 'avgFreshmenGpa' },
  { id: 5, name: 'ACT', value: 'act', property: 'actAvg' },
  { id: 6, name: 'SAT', value: 'sat', property: 'satAvg' },
];

const firstModalPlan = [
  { text: 'Get personalized recommendations' },
  { text: 'Search for colleges in your price range' },
  {
    text: 'Dig deeper with advanced searches by',
    subtext: [{ text: 'early admit rates' }, { text: 'merit scholarships' }, { text: 'deadlines' }],
  },
  { text: 'Explore Road2College’s curated lists' },
  { text: 'And much more' },
];

export const HomePage = () => {
  const { recommendedColleges, colleges, updatedRecommendedColleges } = useSelector(
    (state: any) => state.collegesState,
  );
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { myLists, isSharedList, idSharedList } = useSelector((state: any) => state.myListsState);
  const [isInputActive, setIsInputActive] = useState(false);
  const [searchCollegeValue, setSearchCollegeValue] = useState('');
  const [filteredColleges, setFilteredColleges] = useState(colleges);

  const [collegesByCategories, setCollegesByCategories] = useState(
    (user.subscription || user.role === 'admin') && useLocalStorageRecommendedColleges()
      ? updatedRecommendedColleges
      : recommendedColleges,
  );
  const [showAll, setShowAll] = useState(false);

  const [showCalendar, setShowCalendar] = useState(false);
  const [calendarDatasetIndex, setCalendarDatasetIndex] = useState(0);

  const [collegeToMyList, setCollegeToMyList] = useState<number[]>([]);

  const [recommendationCategory, setRecommendationCategory] = useState(
    useLocalStorage() || recommendationCategories[0],
  );

  const [collegeSelectedListActive, setCollegeSelectedListActive] = useState<number | null>(null);

  const [numberOpenedModal, setNumberOpenedModal] = useState(0);
  const [showScoreSmart, setShowScoreSmart] = useState(false);

  const dispatch = useDispatch();
  const childRef = useRef<any>();
  const navigate = useNavigate();

  const [searchParams, setSearchParams] = useSearchParams();

  useEffect(() => {
    if (
      (previousRoute.payload === '/create-profile-after-shared-lists' || previousRoute.payload === '/shared-lists') &&
      isSharedList
    ) {
      navigate(`/shared-lists/${idSharedList}`);
    }
  }, []);

  useEffect(() => {
    const param = searchParams.get('calendar');

    if (param === 'true') {
      setShowCalendar(true);
      searchParams.delete('calendar');
      setSearchParams(searchParams);
    }
  }, [searchParams]);

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  const closeFirstModal = () => {
    document?.getElementById('body')?.classList?.remove('body-hidden');
    setNumberOpenedModal(5);
    close();
  };

  const confirmFirstModal = () => {
    setNumberOpenedModal(2);
    close();
  };

  const closeScoreSmartModal = () => {
    document?.getElementById('body')?.classList?.remove('body-hidden');
    setShowScoreSmart(false);
    close();
  };

  useEffect(() => {
    if (searchParams.has('payment_intent')) {
      searchParams.delete('payment_intent');
      setSearchParams(searchParams);
      dispatch(userAction.getUser({ email: user.email }));
    }
  }, []);

  useEffect(() => {
    const now = new Date();
    const currentTime = new Date(now.getFullYear(), now.getMonth(), now.getDate() + 1).toISOString().slice(0, 10);
    const slicedExpiredTimeSub = user?.subscription?.expiresAt.slice(0, 10);

    if (currentTime >= slicedExpiredTimeSub && user.subscription) {
      if (user.subscription.type === 'klarna') {
        const { ...newUser } = user;
        dispatch(userAction.setUser({ ...newUser, canceledSubscription: false }));
        dispatch(userAction.getUser(user));
        dispatch(
          loadingAction.updateLoadingStatus({
            getUserLoading: false,
          }),
        );
        return;
      }
      const getStatusesSubscriptionsFromStripe = async () => {
        const subscriptionsStripe = await subscriptionService.endpoint_get_subscription_from_stripe(
          user.subscription.subscriptionId,
        );

        if (subscriptionsStripe.data !== 'active') {
          const changeStatusSub = async () => {
            await subscriptionService.endpoint_change_status_subscription(user.subscription.id);
          };
          changeStatusSub();
          const { ...newUser } = user;
          dispatch(userAction.setUser({ ...newUser, canceledSubscription: false }));
          dispatch(userAction.getUser(user));
          dispatch(
            loadingAction.updateLoadingStatus({
              getUserLoading: false,
            }),
          );
        }
      };
      getStatusesSubscriptionsFromStripe();
    }
  }, []);

  useEffect(() => {
    const counter = localStorage.getItem('counterVisitingPage');
    if (counter) {
      localStorage.setItem('counterVisitingPage', `${+counter + 1}`);
    } else {
      localStorage.setItem('counterVisitingPage', `${0}`);
    }

    if (previousRoute.payload === '/create-profile') {
      open();
    }

    if (previousRoute.payload === '/create-profile-after-require') {
      navigate('/upgrade');
      dispatch(previousRouteAction.setPreviousRoute('/home-page-after-require'));
    }
    return () => {
      if (previousRoute.payload === '/create-profile') {
        dispatch(previousRouteAction.setPreviousRoute('/home-page'));
        document?.getElementById('body')?.classList?.remove('body-hidden');
      }

      const counter = localStorage.getItem('counterVisitingPage');
      if (counter) {
        localStorage.setItem('counterVisitingPage', `${+counter + 1}`);
      } else {
        localStorage.setItem('counterVisitingPage', `${0}`);
      }
    };
  }, []);

  useEffect(() => {
    if (numberOpenedModal === 5) {
      document?.getElementById('body')?.classList?.remove('body-hidden');
    }
  }, [numberOpenedModal]);

  useEffect(() => {
    document.title = 'Insights';
  });

  useEffect(() => {
    localStorage.setItem('recommendationCategory', JSON.stringify(recommendationCategory));
  }, [recommendationCategory]);

  useEffect(() => {
    if (updatedRecommendedColleges?.length === 0) {
      setCollegesByCategories(
        colleges.filter((c: ICollege) =>
          c?.state?.id === adminNavigatedUser ? adminNavigatedUser?.stateId : user?.stateId,
        ),
      );
    }
  }, [colleges]);

  useEffect(() => {
    if (updatedRecommendedColleges?.length === 0) {
      const sortedColleges = [...colleges];
      setFilteredColleges(sortedColleges.sort((a: ICollege, b: ICollege) => (a.name > b.name ? 1 : -1)));
    }
  }, [colleges]);

  useEffect(() => {
    if ((user && user.id) || (adminNavigatedUser && adminNavigatedUser.id)) {
      const recommendationCategoryLocale = localStorage.getItem('recommendationCategory');
      dispatch(
        collegesAction.getRecommendedCollegesByCategory({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          category: recommendationCategoryLocale
            ? JSON.parse(recommendationCategoryLocale).value
            : recommendationCategories[0].value
            ? recommendationCategory[0].value
            : null,
        }),
      );

      if (updatedRecommendedColleges?.length) {
        sorting(collegesByCategories);
        return;
      }

      if ((user?.subscription || user.role === 'admin') && updatedRecommendedColleges?.length) {
        sorting(collegesByCategories);
      }
    }
  }, []);

  useEffect(() => {
    if (adminNavigatedUser) {
      setCollegesByCategories(updatedRecommendedColleges?.length ? updatedRecommendedColleges : recommendedColleges);
      return;
    }
    setCollegesByCategories(
      (user.subscription || user.role === 'admin') && updatedRecommendedColleges?.length
        ? updatedRecommendedColleges
        : recommendedColleges,
    );
  }, [updatedRecommendedColleges, recommendedColleges]);

  useEffect(() => {
    if (updatedRecommendedColleges?.length) {
      sorting(updatedRecommendedColleges);
    }
  }, [recommendationCategory]);

  useEffect(() => {
    if (adminNavigatedUser && adminNavigatedUser.id) {
      const recommendationCategory = localStorage.getItem('recommendationCategory');
      dispatch(
        collegesAction.getRecommendedColleges({
          userId: adminNavigatedUser.id,
          category: recommendationCategory ? JSON.parse(recommendationCategory).value : 'location',
        }),
      );
    }
  }, [adminNavigatedUser]);

  const sorting = (array: any) => {
    const sortedColleges = [...array];

    if (recommendationCategory.value === 'location') {
      sortedColleges.sort((a: any, b: any) => (a.state?.name < b.state?.name ? -1 : 1));
      setCollegesByCategories(sortedColleges);
    } else if (recommendationCategory.value === 'gpa') {
      sortedColleges.sort((a: any, b: any) => a.avgFreshmenGpa - b.avgFreshmenGpa).reverse();
      setCollegesByCategories(sortedColleges);
    } else if (recommendationCategory.value === 'act') {
      sortedColleges.sort((a: any, b: any) => a.actAvg - b.actAvg).reverse();
      setCollegesByCategories(sortedColleges);
    } else if (recommendationCategory.value === 'sat') {
      sortedColleges.sort((a: any, b: any) => a.satAvg - b.satAvg).reverse();
      setCollegesByCategories(sortedColleges);
    }
  };

  const saveRemoveFromMyList = (id: number) => {
    const list = myLists.find((l: IMyList) => l.colleges.find((c) => c.id === id));
    if (list) {
      dispatch(myListsAction.removeCollegeFromMyList({ userId: list.userId, listId: list.id, collegeId: id }));
    } else {
      setCollegeToMyList([id]);
    }
  };

  const selectRecommendationCategory = (category: any) => {
    rotateArrow('recommended-category', true);
    setRecommendationCategory(category);
    if (updatedRecommendedColleges?.length === 0) {
      dispatch(
        collegesAction.getRecommendedCollegesByCategory({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          category: category.value,
        }),
      );
      if ((user?.subscription || user.role === 'admin') && updatedRecommendedColleges?.length) {
        sorting(collegesByCategories);
      }
    }
  };

  const selectList = (listName: string) => {
    dispatch(
      myListsAction.addCollegesToMyList({
        userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
        collegesIds: collegeToMyList,
        listName,
      }),
    );
    dispatch(myListsAction.getMyLists(adminNavigatedUser ? adminNavigatedUser.id : user.id));
  };

  const filterCollegesNames = (inputValue: string) => {
    setIsInputActive(true);
    const nextValue = colleges.filter((college: ICollege) =>
      college.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredColleges(nextValue.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)));
  };

  const selectCollege = (college: ICollege) => {
    setSearchCollegeValue(college.name);
    setIsInputActive(false);
    navigate(`/college-details/${college.id}`, { state: '/home' });
  };

  const rotateArrow = (id: string, bool: boolean) => {
    const arrow = document.getElementById(id);
    if (!bool) {
      arrow?.classList.add('look-up');
      arrow?.classList.remove('look-down');
    } else {
      arrow?.classList.remove('look-up');
      arrow?.classList.add('look-down');
    }
  };

  return (
    <div className="home-page">
      <div
        onClick={() => {
          rotateArrow('recommended-category', true);
          setCollegeSelectedListActive(null);
        }}
        className="home-page-wrapper"
      >
        <header>
          <nav className="home-page-navigation">
            <a href="/home" id="discover" className="selected-nav">
              Discover
            </a>
            <NavLink to={'/my-lists'}>My Lists</NavLink>
          </nav>
          <div className="home-search-background">
            <div className="home-search-background-cont">
              <h3 className="">Search for Specific Colleges</h3>
              <div className="search-college">
                {numberOpenedModal === 3 && (
                  <div className="shadow">
                    <div className="shadow-second">
                      <div className="shadow-third">
                        <div className="visit-modal visit-modal-second">
                          <div className="visit-modal-second-header">Advanced Filters and Search</div>
                          <div className="visit-modal-main visit-modal-main-second">
                            <div className="visit-modal-main-text">
                              <p>Filter and sort by early admit rates, merit scholarships, deadlines and more.</p>
                            </div>
                          </div>
                          <div className="visit-modal-cont-bottom visit-modal-cont-bottom-two">
                            <p className="visit-modal-cont-bottom-text">2 of 3</p>
                            <div className="visit-modal-cont-bottom-cont-btn">
                              <button
                                className="visit-modal-btn visit-modal-second-modal"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setNumberOpenedModal(4);
                                  dispatch(previousRouteAction.setPreviousRoute(''));
                                }}
                              >
                                Next Tip
                              </button>
                              <button
                                className="visit-modal-btn visit-modal-btn-second visit-modal-second-modal"
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setNumberOpenedModal(5);
                                  dispatch(previousRouteAction.setPreviousRoute(''));
                                }}
                              >
                                Skip Tour
                              </button>
                            </div>
                          </div>
                        </div>

                        <div className="visit-modal-second-box visit-modal-second-box-second">
                          <img src={filters} alt="filters" />
                          <p>Power Search</p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {numberOpenedModal === 4 && (
                  <div className="shadow">
                    <div className="shadow-second" />
                    <div className="shadow-edit">
                      <div className="visit-modal visit-modal-third">
                        <div className="visit-modal-main visit-modal-main-third">
                          <div className="visit-modal-third-header">Personalized recommendations</div>
                          <div className="visit-modal-main-text visit-modal-main-text-third">
                            Update your profile to get personalized college recommendations for you
                          </div>
                        </div>
                        <div className="visit-modal-cont-bottom visit-modal-cont-bottom-third">
                          <p className="visit-modal-cont-bottom-text visit-modal-cont-bottom-text-third">3 of 3</p>
                          <button
                            className="visit-modal-btn"
                            onClick={(e) => {
                              e.stopPropagation();
                              setNumberOpenedModal(5);
                            }}
                          >
                            Ok
                          </button>
                        </div>
                      </div>

                      <div className="visit-modal-third-box">
                        <div className="recommendation-by-categories-description">
                          <h3 className="visit-modal-third-box-recommended-by">Recommended by:</h3>
                          <h6>Recommended Colleges</h6>
                          <p className="recommended-colleges-subtitle">
                            Below is a list of colleges that we recommend according to your profile
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <img src={search} alt="search" className="search-college-img" />
                <input
                  className="search-college-input search-college-input-white"
                  type="text"
                  value={searchCollegeValue}
                  placeholder="Search by College Name"
                  onClick={() => setIsInputActive(true)}
                  onChange={({ target }) => setSearchCollegeValue(target.value)}
                  onInput={({ target }: { target: any }) => filterCollegesNames(target.value)}
                  onBlur={() => setIsInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                <div className="advanced-search-btn">
                  <div className="advanced-btn-hover">
                    <h4>Dig deeper!</h4>
                    <p>Filter by early admit rates, merit scholarships, deadlines, and more.</p>
                  </div>

                  <NavLink to={'/power-search'} className="advanced-wrapper">
                    <div className="advanced-btn">
                      <img src={filters} alt="filters" />
                      <span>Power Search</span>
                    </div>
                  </NavLink>
                </div>
                {isInputActive && filteredColleges && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectCollege}
                    filteredItem={filteredColleges}
                  />
                )}
              </div>
            </div>
            {numberOpenedModal === 2 && (
              <div className="shadow">
                <div className="search-college-cont">
                  <h3 className="search-college-cont-text">Search for Specific Colleges</h3>
                  <div className="search-college">
                    <img src={searchBlack} alt="search" className="search-college-img" />
                    <input
                      className="search-college-input"
                      type="text"
                      value={searchCollegeValue}
                      placeholder="Search by College Name"
                    />
                  </div>
                  <div className="visit-modal">
                    <div className="visit-modal-header">Search, view and save any college</div>
                    <div className="visit-modal-main">
                      <div className="visit-modal-main-text">
                        Search for any college and use the bookmark icon to save to a list
                      </div>
                    </div>
                    <div className="visit-modal-cont-bottom">
                      <p className="visit-modal-cont-bottom-text">1 of 3</p>
                      <div className="visit-modal-cont-bottom-cont-btn">
                        <button
                          className="visit-modal-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            setNumberOpenedModal(3);
                            dispatch(previousRouteAction.setPreviousRoute(''));
                          }}
                        >
                          Next Tip
                        </button>
                        <button
                          className="visit-modal-btn visit-modal-btn-second"
                          onClick={(e) => {
                            e.stopPropagation();
                            setNumberOpenedModal(5);
                            dispatch(previousRouteAction.setPreviousRoute(''));
                          }}
                        >
                          Skip Tour
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="shadow-bg" />
              </div>
            )}
            <hr className="line" />
          </div>
        </header>

        <hr className="line" />

        <div className="recommended-colleges-wrapper" id="recommendations">
          <header>
            <div className="recommendation-by-categories">
              <div className="recommendation-by-categories-top">
                <div className="recommendation-by-categories-description">
                  <h6>Recommended Colleges</h6>
                  <p className="recommended-colleges-subtitle">
                    Below is a list of colleges that we recommend according to your profile
                  </p>
                </div>
              </div>

              <div className="recommended-colleges-btns">
                <div className="recommended-colleges-recommends">
                  <h3>Recommended by:</h3>
                  <SelectBtn
                    onclick={selectRecommendationCategory}
                    filteredItem={recommendationCategories}
                    recommendationCategory={recommendationCategory}
                  />
                </div>

                <div className="edit-btn-mobile-wrapper">
                  <NavLink
                    className="edit-btn-wrapper"
                    to={
                      adminNavigatedUser || user?.subscription || user.role === 'admin'
                        ? '/power-search'
                        : numberOpenedModal === 3
                        ? `${window.location.pathname}`
                        : '/buy-plan'
                    }
                  >
                    <div
                      className={`edit-list-btn ${
                        adminNavigatedUser || user?.subscription || user.role === 'admin' ? '' : 'locked'
                      }`}
                    >
                      {adminNavigatedUser ? (
                        <img src={filtersPurple} alt="lock" />
                      ) : user?.subscription || user.role === 'admin' ? (
                        <img src={filtersPurple} alt="lock" />
                      ) : (
                        <img src={lock} alt="lock" />
                      )}
                      Filter this list
                    </div>
                  </NavLink>
                </div>
              </div>
            </div>
          </header>

          <div className="colleges-by-categories">
            {collegesByCategories && collegesByCategories?.length === 0 ? (
              <div className={'no-list-container no-list-container-home'}>
                <h6>To view recommendations for this section, please update your profile.</h6>
                <NavLink
                  to={'/my-profile'}
                  onClick={() => {
                    dispatch(previousRouteAction.setPreviousRoute('/home-page-after-update'));
                  }}
                >
                  Update Profile
                </NavLink>
              </div>
            ) : collegesByCategories &&
              collegesByCategories?.length > 0 &&
              ((user.state !== null && recommendationCategory.value === 'location') ||
                (user.gpa !== null && recommendationCategory.value === 'gpa') ||
                (user.act !== null && recommendationCategory.value === 'act') ||
                (user.sat !== null && recommendationCategory.value === 'sat') ||
                (user?.majors && user?.majors?.length > 0 && recommendationCategory.value === 'majors')) ? (
              <>
                {collegesByCategories
                  .slice(0, showAll ? (user?.statusSubscription==='active' ? collegesByCategories?.length : 8) : 4)
                  .map((college: ICollege, index: number) => (
                    <CollegeCard
                      key={college.id}
                      college={college}
                      sortedByValue={
                        recommendationCategory && recommendationCategory?.property ? recommendationCategory : undefined
                      }
                      isBlurred={false}
                    />
                  ))}
              </>
            ) : (
              <div className={'no-list-container no-list-container-home'}>
                <h6>To view recommendations for this section, please update your profile.</h6>
                <NavLink
                  to={'/my-profile'}
                  onClick={() => {
                    dispatch(previousRouteAction.setPreviousRoute('/home-page-after-update'));
                  }}
                >
                  Update Profile
                </NavLink>
              </div>
            )}
          </div>
          {showAll && user?.statusSubscription!=='active' && (
            <div className="lockCardHome">
              <div className={'lockedContent__Container ' + showAll}>
                <div className={'containerLockCard lockedContent'}>
                  {collegesByCategories.slice(0, 4).map((college: ICollege) => (
                    <CollegeCard key={college.id} college={college} sortedByValue={undefined} isBlurred={false} />
                  ))}
                </div>
                <LockedResultsModal />
              </div>
            </div>
          )}

          {!showAll && <span onClick={() => setShowAll(true)}>See All</span>}
          {showAll && <span onClick={() => setShowAll(false)}>See Less</span>}
        </div>

        <hr className="line" />

        <div className="calendar-wrapper" id="scoresmart" style={{ paddingBottom: '2rem' }}>
          <header>
            <h6>Free Practice Digital SAT</h6>
          </header>
          <div className="colleges-by-categories">
            <div className={'no-list-container no-list-container-home'}>
              <h6 style={{ margin: '0 2rem 2rem', textAlign: 'center' }}>
                Get a free practice digital SAT from our partner ScoreSmart. You&#39;ll also get a custom report
                identifying key areas to improve that will have the greatest impact on your student&#39;s score.
              </h6>
              {!user.scoresmartUsername && (
                <NavLink
                  to={'#'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowScoreSmart(true);
                    open();
                  }}
                >
                  Get Free Test
                </NavLink>
              )}
              {user.scoresmartUsername && (
                <NavLink
                  to={'#'}
                  onClick={(e) => {
                    e.stopPropagation();
                    scoreSmartService.createScoreSmartAccount(user.scoresmartUsername, user.scoresmartParentEmail);
                  }}
                >
                  Go to Your Tests
                </NavLink>
              )}
            </div>
          </div>
        </div>

        <hr className="line" />

        <div className="calendar-wrapper" id="calendar">
          <header>
            <h6>Yearly Calendar</h6>
            <img
                id="calendar-arrow"
                onClick={() => {
                  rotateArrow('calendar-arrow', showCalendar);
                  setShowCalendar(!showCalendar);
                }}
                src={arrowDown}
                alt="arrow"
            />
          </header>
          {showCalendar && (
            <div>
              <div className="chart-data-header-mobile">
                <img
                  onClick={() => {
                    if (calendarDatasetIndex > 0) {
                      setCalendarDatasetIndex(calendarDatasetIndex - 1);
                    }
                  }}
                  src={arrowPrimary}
                  alt="arrow left"
                />
                <h5>{calendarData[calendarDatasetIndex].title}</h5>
                <img
                  onClick={() => {
                    if (calendarDatasetIndex < 2) {
                      setCalendarDatasetIndex(calendarDatasetIndex + 1);
                    }
                  }}
                  className="right-arrow"
                  src={arrowPrimary}
                  alt="arrow right"
                />
              </div>
              <div className="chart">
                <div className="chart-lables">
                  {calendarData[calendarDatasetIndex].labels.map((l) => (
                    <p className="subtitle2" key={l}>
                      {l}
                    </p>
                  ))}
                  <img src={underline} alt="underline" />
                </div>
                <div className="chart-data">
                  <div className="chart-data-header">
                    <img
                      onClick={() => {
                        if (calendarDatasetIndex > 0) {
                          setCalendarDatasetIndex(calendarDatasetIndex - 1);
                        }
                      }}
                      src={arrowPrimary}
                      alt="arrow left"
                    />
                    <h5>{calendarData[calendarDatasetIndex].title}</h5>
                    <img
                      onClick={() => {
                        if (calendarDatasetIndex < 2) {
                          setCalendarDatasetIndex(calendarDatasetIndex + 1);
                        }
                      }}
                      className="right-arrow"
                      src={arrowPrimary}
                      alt="arrow right"
                    />
                  </div>
                  <div className="chart-data-months">
                    {months.map((m) => (
                      <span key={m}>{m}</span>
                    ))}
                  </div>
                  {calendarData[calendarDatasetIndex].dataset.map((d, i) => (
                    <div key={i} className="chart-section">
                      <div
                        style={{
                          background: d.color,
                          left: `${(d.left * 100) / 12}%`,
                          width: `${(d.with * 100) / 12}%`,
                        }}
                      />
                    </div>
                  ))}
                  <div className="chart-data-legends">
                    <div>
                      <span className="parent" />
                      Parent Responsibility
                    </div>
                    <div>
                      <span className="family" />
                      Family Responsibility
                    </div>
                    <div>
                      <span className="student" />
                      Student Responsibility
                    </div>
                  </div>
                </div>
              </div>
              <div className="chart-data-legends-mobile">
                <div>
                  <span className="parent" />
                  Parent Responsibility
                </div>
                <div>
                  <span className="family" />
                  Family Responsibility
                </div>
                <div>
                  <span className="student" />
                  Student Responsibility
                </div>
              </div>
            </div>
          )}
        </div>

        <hr className="line" />
      </div>

      <Modal>
        {!showScoreSmart ? (
          <FirstModal close={closeFirstModal} plan={firstModalPlan} confirm={confirmFirstModal} />
        ) : (
          <ScoreSmartModal close={closeScoreSmartModal} user={user} />
        )}
      </Modal>
    </div>
  );
};
