import {
  ECollegesActionTypes,
  EfcsActionTypes,
  EHighschoolsActionTypes,
  ELoadingActionTypes,
  EMajorsActionTypes,
  EMyListsActionTypes,
  EOffersActionTypes,
  EStatesActionTypes,
  ESubscriptionActionTypes,
  EUserActionTypes,
  EUsersActionTypes,
  ENotesActionTypes,
  EBackupsActionTypes,
  EBackupsUserListActionTypes,
  EPreviousRouteTypes,
  EHouseHoldIncomeActionTypes,
  ESwitcherBLocksMyListTypes,
  ESwitcherTypeMyListTypes,
  ESelectedCollegesAndColumnsTypes,
  ESelectedCollegesForSharingTypes,
  ECouponActionTypes,
  EPowerSearchTypes,
} from '../enums';

export const collegesActionTypes = {
  ...ECollegesActionTypes,
};

export const efcsActionTypes = {
  ...EfcsActionTypes,
};

export const householdIncomeActionTypes = {
  ...EHouseHoldIncomeActionTypes,
};

export const highschoolsActionTypes = {
  ...EHighschoolsActionTypes,
};

export const loadingActionTypes = {
  ...ELoadingActionTypes,
};

export const offersActionTypes = {
  ...EOffersActionTypes,
};

export const statesActionTypes = {
  ...EStatesActionTypes,
};

export const majorsActionTypes = {
  ...EMajorsActionTypes,
};

export const couponActionTypes = {
  ...ECouponActionTypes,
};

export const userActionTypes = {
  ...EUserActionTypes,
};

export const usersActionTypes = {
  ...EUsersActionTypes,
};

export const myListActionTypes = {
  ...EMyListsActionTypes,
};

export const subscriptionActionTypes = {
  ...ESubscriptionActionTypes,
};

export const notesActionTypes = {
  ...ENotesActionTypes,
};

export const listBackupsActionTypes = {
  ...EBackupsActionTypes,
};

export const backupUserListActionTypes = {
  ...EBackupsUserListActionTypes,
};

export const previousRouteActionTypes = {
  ...EPreviousRouteTypes,
};

export const switcherBlockMyListTypes = {
  ...ESwitcherBLocksMyListTypes,
};

export const switcherTypeMyListTypes = {
  ...ESwitcherTypeMyListTypes,
};

export const selectedCollegesAndColumnsTypes = {
  ...ESelectedCollegesAndColumnsTypes,
};

export const selectedCollegesForSharingTypes = {
  ...ESelectedCollegesForSharingTypes,
};

export const powerSearchActionTypes = {
  ...EPowerSearchTypes,
};
