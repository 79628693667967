import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { clsx } from 'clsx';
import moment from 'moment/moment';
import { SyncLoader } from 'react-spinners';
import { NavLink } from 'react-router-dom';
import { PowerSearchHeader } from './components/power-search-header/PowerSearchHeader';
import { SearchFilters } from './components/search-filters/SearchFilters';
import { CollegesList } from './components/colleges-list/CollegesList';
import { ICollege, IPowerSearch, IPowerSearchFilterOption, IRootState, IState } from '../../models';
import styles from './PowerSearch.module.css';
import { usePlatform } from '../../hooks/usePlatform';
import { EPlatform } from '../../enums/platform.enum';
import { collegesAction, powerSearchAction, userAction } from '../../store/actions';
import imgWithoutResColleges from '../../images/zeroResultsImage.svg';
import noFiltersSelected from '../../images/chooseYourFiltersImg.svg';
import noSearchesLeft from '../../images/advanced-free.png';
import { UniversalModal } from '../universal-modal/UniversalModal';
import { FirstModal } from '../first-modal/FirstModal';
import { SignUpModal } from '../signup-modal/SignUpModal';

export type SortCategory = {
  id: number;
  name: string;
  property: string;
};

export const applicationDeadlines: IPowerSearchFilterOption<string>[] = [
  { id: 1, name: 'Early Decision', value: 'earlyDecision' },
  { id: 2, name: 'Early Action', value: 'earlyAction' },
  { id: 3, name: 'Early Decision II', value: 'earlyDecision2' },
];

const sortCategories: SortCategory[] = [
  { id: 1, name: 'College Name', property: 'name' },
  { id: 2, name: 'Location', property: 'state' },
  { id: 3, name: 'School Size', property: 'fullTimeUndergraduates' },
  { id: 4, name: 'In-State COA', property: 'coaInState' },
  { id: 5, name: 'Out-of-State COA', property: 'coaOutState' },
  { id: 6, name: 'Private COA', property: 'coaPrivate' },
  { id: 7, name: 'Avg SAT', property: 'satAvg' },
  { id: 8, name: 'Avg ACT', property: 'actAvg' },
  { id: 9, name: 'Avg GPA', property: 'avgFreshmenGpa' },
  { id: 10, name: 'SAT 75th Percentile', property: 'sat75thPc' },
  { id: 11, name: 'ACT 75th Percentile', property: 'act75thPc' },
  { id: 12, name: 'Overall Admit Rate', property: 'admissionsRate' },
  { id: 13, name: 'ED Admit Rate', property: 'edAdmitRate' },
  { id: 14, name: 'EA Admit Rate', property: 'eaAdmitRate' },
  { id: 15, name: 'Class Filled ED', property: 'edFilledRate' },
  { id: 16, name: 'Need Met', property: 'avgPcNeedMet' },
  { id: 17, name: 'Freshmen w/out Need Receiving Merit', property: 'pcStudentsReceivingMeritAid' },
  { id: 18, name: 'Avg Merit Aid', property: 'avgMeritAidAward' },
  { id: 19, name: '4 Yr Grad Rate', property: 'fourYearGraduationRate' },
  { id: 20, name: 'Early Decision', property: 'edDeadline' },
  { id: 21, name: 'Early Action', property: 'eaDeadline' },
  { id: 22, name: 'Early Decision II', property: 'ed2Deadline' },
  { id: 23, name: 'In-State Net Cost', property: 'inStateNetCost' },
  { id: 24, name: 'Out-of-State Net Cost', property: 'outStateNetCost' },
  { id: 25, name: 'Private Net Cost', property: 'privateNetCost' },
];

const advancedPlan: { text: string }[] = [
  { text: 'Search by COA, School Size, Major, Locations, GPA, Academic scoring' },
  { text: 'Sort by EA and ED Admit Rates, 75th Percentile,   Scoring and more' },
];

const scrollUp = () => {
  window.scrollTo({
    top: 0,
    left: 0,
    behavior: 'smooth',
  });
};

interface PowerSearchProps {}

export class Storage {
  static getItem<T>(key: string, fallbackValue: T): T {
    try {
      const value = localStorage.getItem(key);
      return value ? JSON.parse(value) : fallbackValue;
    } catch (e) {
      console.error(e);
      return fallbackValue;
    }
  }

  static setItem(key: string, value: any): void {
    try {
      localStorage.setItem(key, value);
    } catch (e) {
      console.error(e);
    }
  }
}

export const PowerSearch: React.FC<PowerSearchProps> = () => {
  const { user, adminNavigatedUser } = useSelector((state: IRootState) => state.userState);
  const { powerSearchState } = useSelector((state: IRootState) => state);
  const { selectedColleges, selectedMajors, selectedLocations, selectedApplicationDeadlines, sortBy } = useSelector(
    (state: IRootState) => state.powerSearchState,
  );

  const collegesResult = useSelector((state: IRootState) => state.collegesState.advancedColleges);
  const { states } = useSelector((state: IRootState) => state.statesState);
  const isLoading = useSelector((state: IRootState) => state.loadingState.getPowerSearchResultLoading);

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const platform = usePlatform();
  const dispatch = useDispatch();

  const [innerWidth] = useState(window.innerWidth);

  const [sortedColleges, setSortedColleges] = useState<ICollege[]>(
    Storage.getItem<ICollege[]>('sortedColleges', []) || collegesResult,
  );

  const [isDescSort, setIsDescSort] = useState<boolean>(false);
  const [isFiltersOpen, setIsFiltersOpen] = useState<boolean>(false);

  const [isSearchWithoutResults, setIsSearchWithoutResults] = useState<boolean>(false);
  const [isPremiumFeatureModalOpen, setIsPremiumFeatureModalOpen] = useState<boolean>(false);

  const [resetFilters, setResetFilters] = useState<number>(0);
  const [countFakeSearch, setCountFakeSearch] = useState<number>(user?.countFreeSearch ?? 0);
  const [isDisabled, setIsDisabled] = useState<boolean>(false);

  const initialRender = useRef({ getResultsEffect: true });

  useEffect(() => {
    if (innerWidth > 900) {
      setIsFiltersOpen(true);
    }

    if (countFakeSearch > 0 && user?.role !== 'admin') {
      setCountFakeSearch(countFakeSearch + 1);
    }
  }, []);

  useEffect(() => {
    if (sortedColleges.length > 0) {
      setIsSearchWithoutResults(false);
    } else {
      setIsSearchWithoutResults(true);
    }
  }, [sortedColleges]);

  if (isFiltersOpen && (platform === EPlatform.TABLET || platform === EPlatform.MOBILE)) {
    document.body.style.overflowY = 'hidden';
  } else {
    document.body.style.overflowY = '';
  }

  useEffect(() => {
    const userHasNotFreeSearch =
      user?.role !== 'admin' && !user?.subscription && user?.countFreeSearch === 0 && !adminNavigatedUser;

    if (userHasNotFreeSearch && innerWidth <= 744) {
      open();
    }

    if (userHasNotFreeSearch) {
      const header = document.querySelector('.app > .header');

      if (header) {
        header.classList.add('modal-opened');
      }
    }

    if (user?.role !== 'admin' && !user?.subscription && ((user?.countFreeSearch ?? 0) >= 0 || (user?.countFreeSearch ?? 0) <= 5)) {
      open();
    }
  }, [user, adminNavigatedUser]);

  const selectSort = (category: SortCategory): void => {
    if (!user?.subscription && user?.role !== 'admin' && category.id > 7) {
      setIsPremiumFeatureModalOpen(true);
      open();
      return;
    }

    dispatch(powerSearchAction.setSort(category));
    setIsDescSort(false);
  };

  const addSortedColleges = (collegesResult: ICollege[]): void => {
    let finishedSortedArray: ICollege[];

    const resultWithoutSelected = collegesResult.filter(
      (c) => !selectedColleges.some((college) => college.id === c.id),
    );

    const sortedL = [...selectedColleges, ...resultWithoutSelected];
    if (sortBy.id === -1) {
      dispatch(powerSearchAction.setSort(sortCategories[0]));
    }

    if (sortBy?.property === sortCategories[0]?.property) {
      finishedSortedArray = sortedL.sort((a: any, b: any) =>
        a.name.toLowerCase() < b.name.toLowerCase() ? (isDescSort ? 1 : -1) : isDescSort ? -1 : 1,
      );
    } else if (sortBy?.property === sortCategories[1]?.property) {
      finishedSortedArray = sortedL.sort((a: any, b: any) =>
        a.state?.name < b.state?.name ? (isDescSort ? 1 : -1) : isDescSort ? -1 : 1,
      );
    } else if (
      sortBy.property === sortCategories[19].property ||
      sortBy.property === sortCategories[20].property ||
      sortBy.property === sortCategories[21].property
    ) {
      finishedSortedArray = sortedL.sort((a: ICollege, b: ICollege) => {
        if (a[sortBy.property] === null) {
          return 1;
        }
        if (b[sortBy.property] === null) {
          return -1;
        }
        if (isDescSort) {
          return moment(a[sortBy.property]).isAfter(b[sortBy.property]) ? 1 : -1;
        }
        return moment(b[sortBy.property]).isAfter(a[sortBy.property]) ? 1 : -1;
      });
    } else {
      finishedSortedArray = sortedL.sort((a: any, b: any) => {
        if (a[sortBy?.property] === null) {
          return 1;
        }
        if (b[sortBy?.property] === null) {
          return -1;
        }
        if (isDescSort) {
          return +a[sortBy?.property] < +b[sortBy?.property] ? -1 : 1;
        }
        return +a[sortBy?.property] < +b[sortBy?.property] ? 1 : -1;
      });
    }
    setSortedColleges(finishedSortedArray);
  };

  useEffect(() => {
    addSortedColleges(collegesResult);
  }, [sortBy, isDescSort, collegesResult]);

  useEffect(() => {
    if (user?.subscription || user?.role === 'admin') {
      addSortedColleges(collegesResult);
    }
  }, [selectedColleges]);

  const onSearchClick = (): void => {
    if (user?.subscription || (user?.countFreeSearch as number) > 0) {
      if (isPowerSearchNotEmpty(powerSearchState)) {
        getSearchResult();
      } else {
        addSortedColleges(collegesResult);
      }
      updateUserCountFreeSearch();
    }
    if (platform === EPlatform.MOBILE || platform === EPlatform.TABLET) setIsFiltersOpen(false);
  };

  const isPowerSearchNotEmpty = (powerSearch) =>
    powerSearch.selectedMajors.length ||
    powerSearch.selectedLocations.length ||
    powerSearch.selectedApplicationDeadlines.length ||
    powerSearch.costOfAttendanceTypesSelected.length ||
    powerSearch.costOfAttendanceSelected ||
    powerSearch.avgMeritAwardSelected ||
    powerSearch.avgMeritAwardTypeSelected ||
    powerSearch.netCostAfterAvgMeritAwardSelected ||
    powerSearch.needMetSelected ||
    powerSearch.schoolSize ||
    powerSearch.testScoreValueSelected ||
    powerSearch.satScoreSelected ||
    powerSearch.actScoreSelected ||
    powerSearch.selectedTestScoreType ||
    powerSearch.gpa ||
    powerSearch.studentGPATypeSelected;

  useEffect(() => {
    if (
      (user?.subscription || user?.role === 'admin' || adminNavigatedUser) &&
      !initialRender.current['getResultsEffect']
    ) {
      if (!isPowerSearchNotEmpty(powerSearchState) && selectedColleges.length > 0) {
        addSortedColleges(selectedColleges);
      } else {
        getSearchResult();
      }
    }
    if (
      (user?.subscription || user?.role === 'admin' || adminNavigatedUser) &&
      initialRender.current['getResultsEffect'] &&
      sortedColleges.length === 0
    ) {
      if (!isPowerSearchNotEmpty(powerSearchState) && selectedColleges.length > 0) {
        addSortedColleges(selectedColleges);
      } else {
        getSearchResult();
      }
    }
    initialRender.current['getResultsEffect'] = false;
  }, [powerSearchState]);

  const getSearchResult = () => {
    const stat: IState[] = [];
    selectedLocations.forEach((s) => {
      if (Object.prototype.hasOwnProperty.call(s, 'code')) {
        stat.push(s as IState);
      } else {
        stat.push(...states.filter((state: IState) => state.region.id === s.id));
      }
    });

    const {
      costOfAttendanceTypesSelected,
      costOfAttendanceSelected,
      avgMeritAwardSelected,
      avgMeritAwardTypeSelected,
      netCostTypesSelected,
      netCostAfterAvgMeritAwardSelected,
      needMetSelected,
      schoolSize,
      testScoreValueSelected,
      satScoreSelected,
      actScoreSelected,
      selectedTestScoreType,
      gpa,
      studentGPATypeSelected,
    } = powerSearchState;

    const schoolSizeData = {
      max: schoolSize?.max || 1000000,
      min: schoolSize?.min || 0,
    };

    const readySat =
      satScoreSelected && (testScoreValueSelected === 'SAT' || testScoreValueSelected === 'Both')
        ? [satScoreSelected.min ? satScoreSelected.min : 700, satScoreSelected.max ? satScoreSelected.max : 1600]
        : testScoreValueSelected === 'Both' || testScoreValueSelected === 'SAT'
        ? [700, 1600]
        : null;
    const readyGpa = gpa ? [gpa.min ? gpa.min : 0, gpa.max ? gpa.max : 4] : null;
    const readyAct =
      actScoreSelected && (testScoreValueSelected === 'ACT' || testScoreValueSelected === 'Both')
        ? [actScoreSelected.min ? actScoreSelected.min : 12, actScoreSelected.max ? actScoreSelected.max : 36]
        : testScoreValueSelected === 'Both' || testScoreValueSelected === 'ACT'
        ? [12, 36]
        : null;
    const options: IPowerSearch = {
      majors: selectedMajors.map((major) => major.id),
      states: stat.length > 0 ? stat.map((s) => s.id) : null,
      schoolSize: schoolSize ? [schoolSizeData.min, schoolSizeData.max] : null,
      edDeadline: selectedApplicationDeadlines.length
        ? selectedApplicationDeadlines.some((el) => el.name === applicationDeadlines[0].name)
        : null,
      eaDeadline: selectedApplicationDeadlines.length
        ? selectedApplicationDeadlines.some((el) => el.name === applicationDeadlines[1].name)
        : null,
      ed2Deadline: selectedApplicationDeadlines.length
        ? selectedApplicationDeadlines.some((el) => el.name === applicationDeadlines[2].name)
        : null,
      regDecisionDeadline: null,
      costOfAttendance: costOfAttendanceSelected
        ? [costOfAttendanceSelected.min || 0, costOfAttendanceSelected.max || 999999]
        : null,
      avgMeritAidAwardCrowdsourced: avgMeritAwardTypeSelected === 'crowdsourced',
      avgMeritAidAward: avgMeritAwardSelected
        ? [avgMeritAwardSelected.min || 0, avgMeritAwardSelected.max || 100000]
        : null,
      netCostAfterAvgMeritAwardType: netCostTypesSelected?.length > 0 ? netCostTypesSelected : null,
      netCostAfterAvgMeritAward: netCostAfterAvgMeritAwardSelected
        ? [netCostAfterAvgMeritAwardSelected.min || 0, netCostAfterAvgMeritAwardSelected.max || 100000]
        : null,
      coaInState: costOfAttendanceTypesSelected.includes('inState'),
      coaOutState: costOfAttendanceTypesSelected.includes('outState'),
      coaPrivate: costOfAttendanceTypesSelected.includes('private'),
      coaUnknown: costOfAttendanceTypesSelected.includes('unknown'),
      avgPcNeedMet: needMetSelected ? [needMetSelected.min || 0, needMetSelected.max || 100] : null,
      gpa: readyGpa,
      gpaCrowdsourced: studentGPATypeSelected === 'crowdsourced',
      avg: !!(testScoreValueSelected && selectedTestScoreType === 'avg'),
      avg75th: !!(testScoreValueSelected && selectedTestScoreType === 'top75'),
      testScoreCrowdsourced: !!(testScoreValueSelected && selectedTestScoreType === 'crowdsourcedColleges'),
      sat: readySat,
      act: readyAct,
    };

    dispatch(collegesAction.getCollegesAdvancedSearch(options));
  };

  const updateUserCountFreeSearch = () => {
    if (user) {
      if (user.role === 'admin') {
        return;
      }
      const body = {
        name: user.name,
        email: user.email,
        role: user.role,
        stateId: user.stateId,
        highschoolId: user.highschoolId ? user.highschoolId : null,
        familyIncome: user.familyIncome,
        familyContributions: user.familyContributions,
        yearOfHighSchoolGraduation: user.yearOfHighSchoolGraduation,
        efcId: user.efcId,
        gender: user.gender,
        athlete: user.athlete,
        sat: user.sat ? user.sat : null,
        act: user.act ? user.act : null,
        gpa: user.gpa,
        countFreeSearch: (user.countFreeSearch ?? 0) - 1,
        hadSubscription: user.hadSubscription,
        canceledSubscription: user.canceledSubscription,
      };

      dispatch(userAction.updateUser({ user: body }));
      setCountFakeSearch(countFakeSearch - 1);
      setIsDisabled(false);
    }
  };

  const clearSearch = (): void => {
    scrollUp();
    if (!isFiltersOpen) dispatch(powerSearchAction.clearFilters());
  };

  return (
    <main className={clsx(styles.container, isFiltersOpen && styles.filtersOpen)}>
      {/*  <div className={clsx(styles.overlay__custom, isDisabled && styles.active)} /> */}
      <PowerSearchHeader
        onClearSearchHandler={() => {
          clearSearch();
          setResetFilters((prevState) => prevState + 1);
        }}
        onSearchClickHandler={onSearchClick}
        onFiltersOpenClick={() => setIsFiltersOpen(true)}
        platform={platform}
        isDisabled={isDisabled}
      />

      <div className={styles.resultsWithFiltersBlock}>
        <div className={styles.resultsWithFiltersContainer}>
          {platform === EPlatform.DESKTOP && (
            <div className={styles.filtersBtn} onClick={() => setIsFiltersOpen((prevState) => !prevState)}>
              {isFiltersOpen ? 'Less' : 'More'} Filters
              <span className={clsx(styles.filtersBtnIcon, isFiltersOpen && styles.active)} />
            </div>
          )}
          {isFiltersOpen && (
            <SearchFilters
              platform={platform}
              onCloseHandler={() => setIsFiltersOpen(false)}
              onClearSearchHandler={clearSearch}
              onSearchClick={onSearchClick}
              resetFilters={resetFilters}
            />
          )}
          {isLoading ? (
            <SyncLoader
              color="var(--color-primary)"
              margin={7}
              size={20}
              speedMultiplier={0.7}
              className={styles.loader}
            />
          ) : sortedColleges.length > 0 && !(!user?.subscription && countFakeSearch === 0) ? (
            <CollegesList
              collegesList={user?.subscription || user?.role === 'admin' ? sortedColleges : sortedColleges.slice(0, 24)}
              amountOfColleges={sortedColleges.length}
              onSortSelectHandler={selectSort}
              selectedSortMethod={sortBy}
              sortCategories={sortCategories}
              isDescSort={isDescSort}
              setIsDescSort={setIsDescSort}
            />
          ) : countFakeSearch === 0 && !user?.subscription && user?.role !== 'admin' && !adminNavigatedUser ? (
            <div className={styles.noResultsContainer}>
              <h4 className={styles.noResultsContainer__Title}>Upgrade for Power Search.</h4>
              <img className={styles.upgradeAccount__Image} alt="Power search illustration" src={noSearchesLeft} />
              <NavLink
                to={'/buy-plan'}
                onClick={() => {
                  close();
                }}
                className={styles.upgradeBtn}
              >
                Upgrade
              </NavLink>
            </div>
          ) : isSearchWithoutResults ? (
            <div className={styles.noResultsContainer}>
              <h4 className={styles.noResultsContainer__Title}>
                Sorry, 0 results here.
                <br />
                Please adjust your filters.
              </h4>
              <img
                className={styles.noResultsContainer__Image}
                alt="No results were found"
                src={imgWithoutResColleges}
              />
            </div>
          ) : (
            <div className={styles.noResultsContainer}>
              <h4 className={styles.noResultsContainer__Title}>Choose your filters to see results.</h4>
              <img className={styles.upgradeAccount__Image} alt="Power search illustration" src={noFiltersSelected} />
            </div>
          )}
        </div>
      </div>

      {!user?.subscription && user?.role !== 'admin' && (
        <Modal>
          <>
            {isPremiumFeatureModalOpen && (
              <SignUpModal
                close={close}
                anyAction={() => {
                  setIsPremiumFeatureModalOpen(false);
                }}
                message={'Upgrade Your Plan To Use This Feature'}
                title={'Premium Feature'}
              />
            )}

            {/* {user?.countFreeSearch === 0 && !isPremiumFeatureModalOpen && (
              <UniversalModal
                close={close}
                textForTitle={'Oh No! Out of Searches?'}
                textForDescription={
                  'Researching and comparing colleges is key for building a balanced college list. Upgrade for unlimited searches.'
                }
                textSecondBtn={'Upgrade Now'}
                withClose={true}
              />
            )} */}

            {user?.hadSubscription && !isPremiumFeatureModalOpen && (
              <UniversalModal
                close={close}
                textForTitle={'Upgrade Again!'}
                textForDescription={
                  'You have downgraded your searches and no new searches are allowed on this account. Please upgrade again to continue doing Advanced Search.'
                }
                textFirstBtn={'Learn More'}
                textSecondBtn={'Upgrade Now'}
                withClose={false}
              />
            )}

            {user?.countFreeSearch === 0 && !user.hadSubscription && !isPremiumFeatureModalOpen && (
              <UniversalModal
                close={close}
                textForTitle={'Oh No! Out of Searches?'}
                textForDescription={
                  'Researching and comparing colleges is key for building a balanced college list. Upgrade for unlimited searches.'
                }
                textFirstBtn={'Learn More'}
                textSecondBtn={'Upgrade Now'}
                withClose={true}
              />
            )}

            {(user?.countFreeSearch as number) < 5 &&
              (user?.countFreeSearch as number) > 0 &&
              !user?.hadSubscription &&
              !isPremiumFeatureModalOpen && (
                <UniversalModal
                  close={close}
                  textForTitle={`${user?.countFreeSearch} Free Search${user?.countFreeSearch === 1 ? '' : 'es'} Left!`}
                  textForDescription={'Keep up the research. Upgrade for unlimited searches.'}
                  textFirstBtn={'Learn More'}
                  textSecondBtn={'Upgrade Now'}
                />
              )}

            {user?.countFreeSearch === 5 && !user?.hadSubscription && !isPremiumFeatureModalOpen && (
              <FirstModal close={close} title={'5 Free Search Filters Included!'} plan={advancedPlan} modalType={2} />
            )}
          </>
        </Modal>
      )}
    </main>
  );
};
