import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { SyncLoader } from 'react-spinners';

import { ProfileCompletedNavigator, ProfileUncompletedNavigator } from '.';
import { collegesAction, efcsAction, loadingAction, majorsAction, statesAction } from '../store/actions';
import { selectUserProfileCompleted } from '../store/selectors';

export const UserNavigator = () => {
  const profileCompleted = useSelector(selectUserProfileCompleted);
  const { getUserLoading } = useSelector((state: any) => state.loadingState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(statesAction.getStates());
    dispatch(collegesAction.getCollegesNames());
    dispatch(majorsAction.getMajors());
    dispatch(efcsAction.getEfcs());
    dispatch(collegesAction.getColleges());
    dispatch(statesAction.getRegions());
  }, []);

  React.useEffect(() => {
    if (typeof profileCompleted !== 'undefined') {
      dispatch(
        loadingAction.updateLoadingStatus({
          getUserLoading: false,
        }),
      );
    }
  }, [profileCompleted]);

  if (getUserLoading) {
    return (
      <div className="app-wrapper">
        {/* <h4 className="app-header">Please wait while your credentials are verified</h4> */}
        {/* <SyncLoader className="app-header" color="var(--color-primary)" margin={7} size={20} speedMultiplier={0.7} /> */}
      </div>
    );
  }

  return profileCompleted ? <ProfileCompletedNavigator /> : <ProfileUncompletedNavigator />;
};
