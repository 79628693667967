import { DebounceInput } from 'react-debounce-input';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myListService, sharedListsService } from '../../services';
import { myListsAction } from '../../store/actions';
import { CustomLoader } from '../custom-loader/CustomLoader';
import './AddNewListModal.css';

export interface ISharedListResponse {
  collegeIds: string;
  colleges: [];
  createdAt: string;
  id: number;
  listId: number;
  listName: string;
  updatedAt: string;
  userId: number;
  userName: string;
}

type Props = {
  close: () => void;
};

export const AddNewListModal = ({ close }: Props) => {
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { idSharedList } = useSelector((state: any) => state.myListsState);
  const [listName, setListName] = useState<string>('');
  const [sharedList, setSharedList] = useState<ISharedListResponse | null>();
  const [isLoader, setIsLoader] = useState<boolean>(false);

  const dispatch = useDispatch();

  useEffect(() => {
    if (!idSharedList) return;
    fetchSharedList(Number(idSharedList));
  }, []);
  const handleChangeListName = (e: { target: { value: string } }) => {
    setListName(e.target.value);
  };

  const createList = async (listName: string) => {
    try {
      if (!sharedList) return;
      setIsLoader(true);
      const idsColleges = sharedList?.collegeIds.split(',');
      const readyIds = idsColleges.map((college) => Number(college));
      const responseFromCreating = await myListService.endpoint_create_my_list({
        userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
        listName,
      });

      if (responseFromCreating.data) {
        const responseFromAdding = await myListService.endpoint_add_colleges_to_my_list({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          listName,
          collegesIds: readyIds,
        });

        if (responseFromAdding.data) {
          const { data } = await myListService.endpoint_get_my_lists(
            adminNavigatedUser ? adminNavigatedUser.id : user.id,
          );
          dispatch(myListsAction.setMyLists(data));
          // dispatch(myListsAction.setSelectedList());
          setIsLoader(true);
        }
      }
      close();
    } catch (e: any) {
      console.error(e);
      setIsLoader(false);
    }
  };

  const handleSubmitCreateList = async (e) => {
    e.preventDefault();
    if (listName.length === 0) return;
    createList(listName);
  };

  const fetchSharedList = async (listId: number) => {
    const { data } = await sharedListsService.endpoint_get_sharedList(listId);
    if (data) {
      setSharedList(data);
    }
  };

  return (
    <form className="add-new-list-modal" onSubmit={handleSubmitCreateList}>
      <span className="add-new-list-modal__cross" onClick={close} />
      <h4 className="add-new-list-modal__title">Add New List</h4>
      <DebounceInput
        className="add-new-list-modal__input"
        value={listName}
        onChange={handleChangeListName}
        minLength={1}
        debounceTimeout={1000}
        type="text"
        placeholder="Name list"
      />
      <div className="add-new-list-modal__cont-btns">
        <button className="add-new-list-modal__btn" onClick={close}>
          Cancel
        </button>
        <button className="add-new-list-modal__btn second" type="submit">
          {isLoader && <CustomLoader backgroundColor="#ffffff" size="small" />}
          {!isLoader && 'OK'}
        </button>
      </div>
    </form>
  );
};
