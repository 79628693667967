import React, { useEffect, useState } from 'react';
import styles from './BackToTopButton.module.css';

interface BackToTopButtonProps {
  showOn: number;
}

export const BackToTopButton: React.FC<BackToTopButtonProps> = ({ showOn }) => {
  const [isVisible, setIsVisible] = useState<boolean>(false);

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > showOn) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  return (
    <span
      onClick={() =>
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth',
        })
      }
      className={styles.wrapper}
      style={!isVisible ? { display: 'none' } : undefined}
    >
      <span className={styles.arrow} />
    </span>
  );
};
