import { produce } from 'immer';
import { createReducer } from './index';
import { IAction, ISelectedCollegesAndColumnsForCsvModel } from '../../models';
import { selectedCollegesAndColumnsTypes } from '../../consts';
import { selectedCollegesAndColumnsForCsvState } from '../states/selected-colleges-and-columns-for-csv.state';

export const selectedCollegesAndColumnsReducer = createReducer<ISelectedCollegesAndColumnsForCsvModel>(
  selectedCollegesAndColumnsForCsvState,
  {
    [selectedCollegesAndColumnsTypes.SET_SELECTED_COLUMNS_FOR_CSV]: setSelectedColumnsForCsv,
    [selectedCollegesAndColumnsTypes.SET_SELECTED_COLLEGES_FOR_CSV]: setSelectedCollegesForCsv,
  },
);

function setSelectedColumnsForCsv(state: ISelectedCollegesAndColumnsForCsvModel, payload: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.selectedColumnsForCsv = payload.payload;
  });
}

function setSelectedCollegesForCsv(state: ISelectedCollegesAndColumnsForCsvModel, payload: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.selectedCollegesForCsv = payload.payload;
  });
}
