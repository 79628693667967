import './Admin-user-list-modal.css';
import { useDispatch } from 'react-redux';
import crossModal from '../../images/cross-modal.svg';
import { backupUserListAction } from '../../store/actions';

export const AdminUserListModal = ({
  close,
  user,
  setIsOpenedListModal,
  backupUserList,
}: {
  close: any;
  user?: any;
  setIsOpenedListModal?: any;
  backupUserList?: any;
}) => {
  const dispatch = useDispatch();
  const downloadUserListCsv = async (list: any) => {
    dispatch(backupUserListAction.downloadBackupUserListByListId(list.id));
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/user-backup/${list.name}.csv`;
  };

  return (
    <div className="admin-user-list-modal">
      <img
        className="admin-user-list-modal-img"
        src={crossModal}
        alt="cross modal"
        onClick={() => {
          close();
          setIsOpenedListModal(false);
        }}
      />
      <h5 className="admin-user-list-modal-username">{user ? `userName: ${user.name}` : 'userName: userName'}</h5>
      <ul className="admin-user-list-modal-list-backups">
        {backupUserList.length > 0 ? (
          backupUserList.map((list) => (
            <li className="admin-user-list-modal-item-backups" key={list.id}>
              <p className="admin-user-list-modal-list-name">{list.name}</p>
              <div className="admin-user-list-modal-list-cont-button">
                {/* <button className="admin-user-list-modal-list-button">View List</button> */}
                <button
                  className="admin-user-list-modal-list-button admin-user-list-modal-list-button-download"
                  onClick={() => downloadUserListCsv(list)}
                >
                  Download as CSV
                </button>
              </div>
            </li>
          ))
        ) : (
          <p className="admin-user-list-modal-zero-list-backups">This user has no lists</p>
        )}
      </ul>
    </div>
  );
};
