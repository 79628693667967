import { ESwitcherBLocksMyListTypes } from '../../enums';

const getProfile = () => {
  return {
    type: ESwitcherBLocksMyListTypes.GET_PROFILE,
  };
};

const setProfile = (payload: boolean) => {
  return {
    type: ESwitcherBLocksMyListTypes.SET_PROFILE,
    payload,
  };
};

const getAdmissions = () => {
  return {
    type: ESwitcherBLocksMyListTypes.GET_ADMISSIONS,
  };
};

const setAdmissions = (payload: boolean) => {
  return {
    type: ESwitcherBLocksMyListTypes.SET_ADMISSIONS,
    payload,
  };
};

const getScholaships = () => {
  return {
    type: ESwitcherBLocksMyListTypes.GET_SCHOLASHIPS,
  };
};

const setScholaships = (payload: boolean) => {
  return {
    type: ESwitcherBLocksMyListTypes.SET_SCHOLASHIPS,
    payload,
  };
};

const getFinance = () => {
  return {
    type: ESwitcherBLocksMyListTypes.GET_FINANCE,
  };
};

const setFinance = (payload: boolean) => {
  return {
    type: ESwitcherBLocksMyListTypes.SET_FINANCE,
    payload,
  };
};

export const switcherBlocksMyListAction = {
  getAdmissions,
  setAdmissions,
  getFinance,
  setFinance,
  getScholaships,
  setScholaships,
  getProfile,
  setProfile,
};
