import './Buy-plan-page.css';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import road2college from '../../images/road2college.svg';
import check from '../../images/membership-check.svg';
import StripeForm from '../stripe-form/Stripe-form';
import StripeFormKlarna from '../stripe-form-klarna/StripeFormKlarna';
import { couponAction, previousRouteAction } from '../../store/actions';
import cardPay from '../../images/cardPay.svg';
import klarnaPay from '../../images/klarnaPay.svg';
import applePay from '../../images/applePay.svg';
import { SubscriptionTypeSwitcher } from './components/subscription-type-switcher/SubscriptionTypeSwitcher';

const subscriptionTypes: string[] = ['Essential', 'Plus', 'Premium'];

const planOptionsBasic = [
  {
    id: 3,
    title: 'Become a R2C Essential Member',
    name: 'yearly-basic',
    price: 19.99,
    class: 'yearly-plan',
    options: [
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 239.88,
    additionalInfo: 'Essential Member Subscription (Annual)',
  },
  {
    id: 2,
    title: 'Become a R2C Essentials Member',
    name: 'monthly-basic',
    price: 24.99,
    class: 'monthly-plan',
    options: [
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 24.99,
    additionalInfo: 'Essential Member Subscription (Monthly)',
  },
];

const planOptionsPlus = [
  {
    id: 5,
    title: 'Become a R2C Plus Member',
    name: 'yearly-plus',
    price: 24.99,
    class: 'yearly-plan',
    options: [
      { subtitle: 'Plus Features' },
      'Access to library of counseling videos, updated monthly',
      'Entry to private Facebook group moderated by experts',
      '10% discount on 1-on-1 services',
      { subtitle: 'R2C Insights Tool' },
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 299.88,
    additionalInfo: 'Plus Member Subscription (Annual)',
  },
  {
    id: 4,
    title: 'Become a R2C Plus Member',
    name: 'monthly-plus',
    price: 39.99,
    class: 'monthly-plan',
    options: [
      { subtitle: 'Plus Features' },
      'Access to library of counseling videos, updated monthly',
      'Entry to private Facebook group moderated by experts',
      '10% discount on 1-on-1 services',
      { subtitle: 'R2C Insights Tool' },
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 39.99,
    additionalInfo: 'Plus Member Subscription (Monthly)',
  },
];

const planOptionsPremium = [
  {
    id: 7,
    title: 'Become a R2C Premium Member',
    name: 'yearly-premium',
    price: 39.99,
    class: 'yearly-plan',
    options: [
      { subtitle: 'Premium Features' },
      'Attend live monthly counseling sessions w/experts & guests',
      'Access to library of counseling videos, updated monthly',
      'Entry to private Facebook group moderated by experts',
      '20% discount on 1-on-1 services',
      { subtitle: 'R2C Insights Tool' },
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 479.88,
    additionalInfo: 'Premium Member Subscription (Annual)',
  },
  {
    id: 6,
    title: 'Become a R2C Premium Member',
    name: 'monthly-premium',
    price: 59.99,
    class: 'monthly-plan',
    options: [
      { subtitle: 'Premium Features' },
      'Attend live monthly counseling sessions w/experts & guests',
      'Access to library of counseling videos, updated monthly',
      'Entry to private Facebook group moderated by experts',
      '20% discount on 1-on-1 services',
      { subtitle: 'R2C Insights Tool' },
      'View real merit scholarship offers',
      'Get profile-based college recommendations',
      'Filter by price, merit scholarships, deadlines and more',
      'Sort by admit rates, GPA, test scores and more',
      'Download your college list',
    ],
    totalPrice: 59.99,
    additionalInfo: 'Premium Member Subscription (Monthly)',
  },
];

export const BuyPlanPage = () => {
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { coupon } = useSelector((state: any) => state.couponState);

  const [selectedPlan, setSelectedPlan] = useState(0);
  const [paymentMethod, setPaymentMethod] = useState('card');
  const [nameBrowser, setNameBrowser] = useState('');
  const [couponCode, setCouponCode] = useState<string>('');
  const [planOptions, setPlanOptions] = useState<any[]>(planOptionsBasic);
  const [subscriptionTypeSelected, setSubscriptionTypeSelected] = useState<string>(subscriptionTypes[0]);
  const [price, setPrice] = useState(planOptions[0].totalPrice);
  const [isCouponValid, setIsCouponValid] = useState(false);
  const [couponCodeInvalidWarningActive, setCouponCodeInvalidWarningActive] = useState<boolean>(false);
  const [email, setEmail] = useState('');
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const [isRegistered, setIsRegistered] = useState(false);

  const [searchParams] = useSearchParams();

  const applyCoupon = () => {
    if (couponCode) {
      dispatch(couponAction.getCoupon({ couponCode, packageId: planOptions[selectedPlan].id }));
    }
  };

  useEffect(() => {
    setCouponCode('');
    applyCoupon();
  }, [selectedPlan, planOptions]);

  useEffect(() => {
    const planOption = searchParams.get('packageId');
    const planType = searchParams.get('type');
    switch (planType) {
      case 'annual':
        setSelectedPlan(0);
        break;
      case 'monthly':
        setSelectedPlan(1);
        break;
      default:
        setSelectedPlan(0);
        break;
    }
    if (planOption === '1') {
      setPlanOptions(planOptionsBasic);
      setSubscriptionTypeSelected(subscriptionTypes[0]);
    }
    if (planOption === '2') {
      setPlanOptions(planOptionsPlus);
      setSubscriptionTypeSelected(subscriptionTypes[1]);
    }
    if (planOption === '3') {
      setPlanOptions(planOptionsPremium);
      setSubscriptionTypeSelected(subscriptionTypes[2]);
    }
  }, []);

  useEffect(() => {
    switch (subscriptionTypeSelected) {
      case subscriptionTypes[0]:
        setPlanOptions(planOptionsBasic);
        break;
      case subscriptionTypes[1]:
        setPlanOptions(planOptionsPlus);
        break;
      case subscriptionTypes[2]:
        setPlanOptions(planOptionsPremium);
        break;
      default:
        setPlanOptions(planOptionsBasic);
        break;
    }
  }, [subscriptionTypeSelected]);

  useEffect(() => {
    dispatch(couponAction.setCoupon(null));
  }, []);

  useEffect(() => {
    dispatch(
      previousRouteAction.setPreviousRoute(
        previousRoute.payload === '/home-page-after-require'
          ? '/upgrade'
          : previousRoute.payload === '/require-pay'
          ? '/require-pay'
          : previousRoute.payload === '/shared-list-after-require-pay'
          ? '/shared-list-after-require-pay'
          : 'create-profile',
      ),
    );
    return () => {
      if (previousRoute.payload === '/create-profile') {
        const counter = localStorage.getItem('counterVisitingPage');
        if (!counter) return;
        localStorage.setItem('counterVisitingPage', `${+counter + 1}`);
      }
    };
  }, []);

  useEffect(() => {
    if (coupon?.valid && coupon?.discount) {
      setIsCouponValid(true);
      const currentPrice =
        coupon.discount === 'percent'
          ? planOptions[selectedPlan].totalPrice * (1 - coupon.amount / 100)
          : planOptions[selectedPlan].totalPrice - coupon.amount;
      setPrice(currentPrice < 0 ? 0 : Number(currentPrice.toFixed(2)));
      setCouponCodeInvalidWarningActive(false);
    } else {
      setIsCouponValid(false);
      setPrice(planOptions[selectedPlan].totalPrice);
      setCouponCodeInvalidWarningActive(true);
    }
  }, [selectedPlan, planOptions, coupon]);

  useEffect(() => {
    const sUsrAg = navigator.userAgent;

    if (sUsrAg.indexOf('Firefox') > -1) {
      setNameBrowser('Mozilla Firefox');
    } else if (sUsrAg.indexOf('SamsungBrowser') > -1) {
      setNameBrowser('SamsungBrowser');
    } else if (sUsrAg.indexOf('Opera') > -1 || sUsrAg.indexOf('OPR') > -1) {
      setNameBrowser('Opera');
    } else if (sUsrAg.indexOf('Trident') > -1) {
      setNameBrowser('Microsoft Internet Explorer');
    } else if (sUsrAg.indexOf('Edge') > -1) {
      setNameBrowser('Microsoft Edge');
    } else if (sUsrAg.indexOf('Chrome') > -1) {
      setNameBrowser('Google Chrome or Chromium');
    } else if (sUsrAg.indexOf('Safari') > -1) {
      setNameBrowser('Apple Safari');
    } else {
      setNameBrowser('uknown');
    }
  }, []);

  const isValidEmail =
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

  const validateEmail = () => {
    const input = document.getElementById('account-email') as HTMLElement;
    if (!email.match(isValidEmail)) {
      input.style.borderColor = 'red';
    } else {
      input.style.borderColor = '';
    }
  };

  return (
    <section className="buy-plan">
      <div className="buy-plan-container">
        <div className="plan-details-wrapper">
          <SubscriptionTypeSwitcher
            typeSelected={subscriptionTypeSelected}
            onTypeSelect={(type) => setSubscriptionTypeSelected(type)}
            subscriptionTypes={subscriptionTypes}
          />
          <div className="plan-details-wrapper-box">
            <div className="plan-details-wrapper-cont">
              <img className="plan-details-wrapper-img" src={road2college} alt="road2college" />
              <div className="plan-details-wrapper-title-wrapper">
                <h5 className="plan-details-wrapper-title">{planOptions[selectedPlan].title}</h5>
              </div>
              <div
                className="plan-details-wrapper-switch-cont"
                onChange={() => setSelectedPlan(selectedPlan === 0 ? 1 : 0)}
              >
                <div className="plan-details-wrapper-switch-option">
                  <input
                    type="radio"
                    id="yearly"
                    name="subscription-type"
                    value="Yearly"
                    checked={selectedPlan === 0}
                    readOnly
                  />
                  <label
                    htmlFor="yearly"
                    className={
                      selectedPlan === 0
                        ? 'plan-details-wrapper-switch-text plan-details-wrapper-switch-text-active'
                        : 'plan-details-wrapper-switch-text'
                    }
                  >
                    Annual
                  </label>
                </div>
                <div className="plan-details-wrapper-switch-option">
                  <input
                    type="radio"
                    id="monthly"
                    name="subscription-type"
                    value="Monthly"
                    checked={selectedPlan === 1}
                    readOnly
                  />
                  <label
                    htmlFor="monthly"
                    className={
                      selectedPlan === 1
                        ? 'plan-details-wrapper-switch-text plan-details-wrapper-switch-text-active'
                        : 'plan-details-wrapper-switch-text'
                    }
                  >
                    Monthly
                  </label>
                </div>
              </div>
              <div className="plan-details-price-wrapper">
                <h5 className="plan-details-title-price">
                  {`$${planOptions[selectedPlan].price}/`}
                  <span> month</span>
                </h5>
                {selectedPlan === 0 && (
                  <>
                    <p className="plan-details-ceiling-with-bg">With an annual payment* (30% savings)</p>
                    <p className="plan-details-ceiling-yearly">You pay ${planOptions[selectedPlan].totalPrice} today</p>
                  </>
                )}
              </div>
            </div>

            <div className="plan-details-cont">
              <h5 className="plan-details-title">Your account includes:</h5>
              <div className="plan-details">
                {planOptions[selectedPlan].options.map((option) =>
                  option.subtitle ? (
                    <div key={option.subtitle}>
                      <b className="purple">{option.subtitle}</b>
                    </div>
                  ) : (
                    <div key={option}>
                      <img className="checkmark-icon" src={check} alt="check" />
                      <p>
                        <b>{option}</b>
                      </p>
                    </div>
                  ),
                )}
                {selectedPlan === 0 && <b className="annual-option">*One Payment. Get Access for 12 Months</b>}
              </div>
            </div>
          </div>
          <ul className="plan-details-additional-info">
            <li>
              <h5>{planOptions[selectedPlan].additionalInfo}</h5>
              <h5>{`US$${planOptions[selectedPlan].totalPrice}`}</h5>
            </li>
            <p>Maximize your financial aid, merit scholarship, and admissions opportunities.</p>
            <li>
              <h5>Subtotal</h5>
              <h5>{`US$${planOptions[selectedPlan].totalPrice}`}</h5>
            </li>
            <li>
              <label htmlFor="card-discount-input" className="plan-discount-label apply-button">
                <input
                  value={couponCode ?? ''}
                  onChange={({ target }) => {
                    setCouponCode(target.value.trim());
                    setCouponCodeInvalidWarningActive(false);
                  }}
                  id="card-discount-input"
                  className="plan-discount-input"
                  placeholder="Add Promotional Code"
                />
                <span
                  className={isCouponValid ? 'apply-coupon-text-inactive' : 'apply-coupon-text'}
                  onClick={applyCoupon}
                >
                  Apply
                </span>
              </label>
            </li>
            {couponCode && couponCodeInvalidWarningActive && (
              <p className="apply-coupon-invalid">This coupon code is not valid.</p>
            )}
            <li>
              <h5>Total Due</h5>
              <h5 className="last-h5">{`US$${price}`}</h5>
            </li>
            {selectedPlan === 0 && <li className="annual-payments">Payment plans available through Klarna</li>}
          </ul>
        </div>
        <div className="buy-plan-form">
          {!isAuthenticated && (
            <div className="account-container">
              <h5 className="email-input-container-header">
                <img className="checkmark-icon" src={check} alt="check" /> Select Account Type
              </h5>
              <div className="email-input-container">
                <div className="switcher-pagination">
                  <span
                    className={`switcher-pagination-text ${!isRegistered ? 'active' : ''}`}
                    onClick={() => {
                      setIsRegistered(false);
                    }}
                  >
                    New User
                  </span>
                  <span
                    className={`switcher-pagination-text switcher-pagination-text-second ${
                      isRegistered ? 'active' : ''
                    }`}
                    onClick={() => {
                      setIsRegistered(true);
                    }}
                  >
                    Existing User
                  </span>
                </div>
                <input
                  id="account-email"
                  className="account-email"
                  placeholder="Email Address"
                  value={email}
                  onChange={(e) => setEmail(e.target.value.trim())}
                  onBlur={validateEmail}
                  type="email"
                  required={true}
                />
              </div>
            </div>
          )}
          <div className="stripe-form-type-payment-cont">
            <h5>
              <img className="checkmark-icon" src={check} alt="check" /> Select Payment Method
            </h5>
            <ul className="stripe-form-type-payment-list">
              <li
                className={
                  paymentMethod === 'card'
                    ? 'stripe-form-type-payment-item stripe-form-type-payment-item-active'
                    : 'stripe-form-type-payment-item'
                }
                onClick={() => setPaymentMethod('card')}
              >
                <img src={cardPay} alt="icon card pay" />
                <p>Card Payment</p>
              </li>
              {selectedPlan === 0 && (
                <li
                  className={
                    paymentMethod === 'klarna'
                      ? 'stripe-form-type-payment-item stripe-form-type-payment-item-active'
                      : 'stripe-form-type-payment-item'
                  }
                  onClick={() => setPaymentMethod('klarna')}
                >
                  <img src={klarnaPay} alt="icon card pay" />
                  <p>Klarna</p>
                </li>
              )}
              <li
                className={
                  nameBrowser !== 'Apple Safari'
                    ? 'stripe-form-type-payment-item stripe-form-type-payment-item-disabled'
                    : paymentMethod === 'apple-pay'
                    ? 'stripe-form-type-payment-item stripe-form-type-payment-item-active'
                    : 'stripe-form-type-payment-item'
                }
                onClick={() => setPaymentMethod(nameBrowser === 'Apple Safari' ? 'apple-pay' : paymentMethod)}
              >
                <img src={applePay} alt="icon card pay" />
                <p>Apple Pay</p>
              </li>
            </ul>
          </div>

          {paymentMethod !== 'klarna' && (
            <StripeForm
              packageId={planOptions[selectedPlan].id}
              paymentMethod={paymentMethod}
              nameBrowser={nameBrowser}
              couponCode={couponCode}
              setSelectedPlan={setSelectedPlan}
              email={email}
              isRegistered={isRegistered}
              isValidEmail={isValidEmail}
            />
          )}
          {paymentMethod === 'klarna' && (
            <StripeFormKlarna
              email={email}
              isValidEmail={isValidEmail}
              couponCode={couponCode}
              selectedPlan={planOptions[selectedPlan].id}
            />
          )}
        </div>
      </div>
    </section>
  );
};
