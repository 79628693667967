import './Add-notes-modal.css';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import cross from '../../images/notes-cross.png';
import notesIcon from '../../images/notes-icon.svg';
import editIcon from '../../images/edit-note.png';
import deleteIcon from '../../images/delete-note.png';
import { ICollegeNote, INote } from '../../models';
import { collegeNotesAction, collegesAction, notesAction } from '../../store/actions';

export const AddNotesModal = ({
  close,
  propNotes,
  collegeId,
  collegeName,
  userId,
  listId,
  isOpenOnCollegeDetails,
}: {
  close: any;
  propNotes: INote[] | ICollegeNote[];
  collegeId?: number;
  collegeName?: string;
  userId?: number;
  listId?: number;
  isOpenOnCollegeDetails?: boolean;
}) => {
  const { listNotes } = useSelector((state: any) => state.notesState);
  const { collegeNotes } = useSelector((state: any) => state.collegeNotesState);
  const [selectedNote, setNote] = useState('');
  const [editNote, setEditNote] = useState<INote | ICollegeNote | null>(null);
  const [notes, setNotes] = useState<INote[] | ICollegeNote[]>([]);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(collegeNotesAction.setCollegeNotes([]));
    setNotes(propNotes);
    if (collegeId && userId) {
      dispatch(collegeNotesAction.getCollegeNotes({ collegeId, userId }));
    }
  }, [propNotes]);

  useEffect(() => {
    if (!listId) {
      setNotes(collegeNotes);
    }
  }, [collegeNotes]);

  useEffect(() => {
    if (editNote) {
      setNote(editNote.text);
    }
  }, [editNote]);

  const createNote = () => {
    if (listId) {
      dispatch(notesAction.createNote({ text: selectedNote, listId }));
    } else {
      dispatch(collegeNotesAction.createCollegeNote({ text: selectedNote, collegeId, userId }));
      if (isOpenOnCollegeDetails)
        dispatch(
          collegesAction.getFullCollegeById({
            collegeId: collegeId as number,
            userId: userId as number,
          }),
        );
    }
    setNote('');
  };
  const updateNote = (n: INote | ICollegeNote) => {
    if (listId) {
      dispatch(notesAction.updateNote({ ...n, text: selectedNote }));
    } else {
      dispatch(collegeNotesAction.updateCollegeNote({ ...n, text: selectedNote }));
    }
    setNote('');
    setEditNote(null);
  };
  const deleteNote = (n: INote | ICollegeNote) => {
    if (listId) {
      dispatch(notesAction.deleteNote(n));
    } else {
      dispatch(collegeNotesAction.deleteCollegeNote(n));
      if (isOpenOnCollegeDetails)
        dispatch(
          collegesAction.getFullCollegeById({
            collegeId: collegeId as number,
            userId: userId as number,
          }),
        );
    }
  };

  const onModalClose = () => {
    dispatch(collegeNotesAction.setCollegeNotes([]));
    close();
  };

  return (
    <div className="modal-add-note">
      <img onClick={onModalClose} src={cross} alt="cross" />
      <h1>
        {' '}
        {collegeName ? `${collegeName} —` : null} Notes <img src={notesIcon} alt="notes" />
      </h1>
      <div onScroll={(e) => e.stopPropagation()} className="notes-body">
        {notes?.length > 0 &&
          notes.map((note) =>
            note.id === editNote?.id ? (
              <div key={note.id} className="edit-note-wrapper">
                <div className="edit-note-body">
                  <textarea
                    maxLength={160}
                    value={selectedNote}
                    onChange={({ target }) => setNote(target.value)}
                    placeholder="Type note here..."
                  />
                  <button type="button" className="add-note-modal-button" onClick={() => updateNote(note)}>
                    Done
                  </button>
                </div>
                <p className="notes-tip">Max 160 characters</p>
              </div>
            ) : (
              <div className="note-wrapper" key={note.id}>
                <div className="note-content">
                  {note.text}
                  <span>{moment(note.updatedAt).format('h:mmA, DD/MM/YY')}</span>
                </div>
                <div className="note-buttons">
                  <img onClick={() => setEditNote(note)} src={editIcon} alt="edit" />
                  <img onClick={() => deleteNote(note)} src={deleteIcon} alt="delete" />
                </div>
              </div>
            ),
          )}
        {listNotes?.length > 0 &&
          notes === undefined &&
          listNotes.map((note) =>
            note.id === editNote?.id ? (
              <div key={note.id} className="edit-note-wrapper">
                <div className="edit-note-body">
                  <textarea
                    maxLength={160}
                    value={selectedNote}
                    onChange={({ target }) => setNote(target.value)}
                    placeholder="Type note here..."
                  />
                  <button type="button" className="add-note-modal-button" onClick={() => updateNote(note)}>
                    Done
                  </button>
                </div>
                <p className="notes-tip">Max 160 characters</p>
              </div>
            ) : (
              <div className="note-wrapper" key={note.id}>
                <div className="note-content">
                  {note.text}
                  <span>{moment(note.updatedAt).format('h:mmA, DD/MM/YY')}</span>
                </div>
                <div className="note-buttons">
                  <img onClick={() => setEditNote(note)} src={editIcon} alt="edit" />
                  <img onClick={() => deleteNote(note)} src={deleteIcon} alt="delete" />
                </div>
              </div>
            ),
          )}
        {!editNote && (
          <div className="edit-note-wrapper">
            <div className="edit-note-body">
              <textarea
                maxLength={160}
                value={selectedNote}
                onChange={({ target }) => setNote(target.value)}
                placeholder="Type note here..."
              />
              <button type="button" className="add-note-modal-button" onClick={createNote}>
                Done
              </button>
            </div>
            <p className="notes-tip">Max 160 characters</p>
          </div>
        )}
      </div>
    </div>
  );
};
