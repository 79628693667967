import { Routes, Route, Navigate } from 'react-router-dom';
import {
  BuyPlanPage,
  CollegeDetails,
  Footer,
  Header,
  HomePage,
  Membership,
  MyProfile,
  MyList,
  MarketingPage,
  SharedList,
  PowerSearch,
} from '../components';
import { ServicesComponent } from '../components/services/services-component';

export const FullUsersNavigator = () => {
  return (
    <>
      <Header />
      <Routes>
        <Route path="/upgrade" element={<BuyPlanPage />} />
        <Route path="/marketing-page" element={<MarketingPage />} />
        <Route path="/buy-plan" element={<BuyPlanPage />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/my-lists" element={<MyList />} />
        <Route path="/my-lists/:id" element={<MyList />} />
        <Route path="/college-details/:id" element={<CollegeDetails />} />
        <Route path="/power-search" element={<PowerSearch />} />
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/*" element={<Navigate replace to="/home" />} />
        <Route path={`/shared-lists/:id`} element={<SharedList />} />
        <Route path="/services" element={<ServicesComponent />} />
      </Routes>
      <Footer />
    </>
  );
};
