import { IPowerSearchState } from '../../models';

export const powerSearchState: IPowerSearchState = {
  sortBy: { id: 1, name: 'College Name', property: 'name' },
  selectedColleges: [],
  selectedMajors: [],
  selectedLocations: [],
  selectedApplicationDeadlines: [],
  costOfAttendanceTypesSelected: [],
  costOfAttendanceSelected: null,
  avgMeritAwardSelected: null,
  avgMeritAwardTypeSelected: '',
  netCostTypesSelected: [],
  netCostAfterAvgMeritAwardSelected: null,
  needMetSelected: null,
  schoolSize: null,
  testScoreValueSelected: '',
  satScoreSelected: null,
  actScoreSelected: null,
  selectedTestScoreType: '',
  gpa: null,
  studentGPATypeSelected: '',
};
