import './CollegeDetailsProfile.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useModal } from 'react-hooks-use-modal';
import { NavLink } from 'react-router-dom';
import lock from '../../images/icon-locked2.svg';
import lockWhite from '../../images/locked-icon-white.svg';
import { SignUpModal } from '..';

export const CollegeDetailsProfile = ({ clickUnlock, openModal }: { clickUnlock: any; openModal: any }) => {
  const { selectedCollege } = useSelector((state: any) => state.collegesState);
  const { isAuthenticated } = useAuth0();
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 520) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <section className="colleges-details-wrapper-container-under">
      <div className="colleges-details-wrapper">
        {selectedCollege && selectedCollege.majors?.length > 0 && (
          <h6 className="majors-header">
            <span className="unlocked-filed unlocked-field-major">
              {selectedCollege && <span className="majors-header-title">Top Majors : </span>}
              {selectedCollege.majors?.map((m: { name: string }) => (
                <div key={m.name}>{`${m.name},`}</div>
              ))}
            </span>
          </h6>
        )}
        <div className="college-details-section">
          <h6>Undergraduates</h6>
          <hr className="section-hr-line" />
          {(user?.subscription && !adminNavigatedUser) ||
          (adminNavigatedUser && adminNavigatedUser?.subscription) ||
          user.role === 'admin' ? (
            <div className="race-info-block">
              <div>
                <p className="unlocked-field">Endowment Per Student </p>
                <p className="unlocked-field">% Undergraduate Women </p>
                <p className="unlocked-field">% Undergraduate Men </p>
                <p className="unlocked-field">% Returning for Sophomore Year </p>
                <p className="unlocked-field">4-Year Graduation Rate </p>
              </div>
              <div className="undergraduates-res-block">
                <span>
                  {selectedCollege?.endowmentPerStudent
                    ? `$ ${(+selectedCollege.endowmentPerStudent).toLocaleString('en-US')}`
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.pcUndergraduateWomen
                    ? `${(+selectedCollege.pcUndergraduateWomen).toFixed(0)} %`
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.pcUndergraduateMen ? `${(+selectedCollege.pcUndergraduateMen).toFixed(0)} %` : '-'}
                </span>
                <span>
                  {selectedCollege?.pcReturningForSophomore
                    ? `${(+selectedCollege.pcReturningForSophomore).toFixed(0)} %`
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.fourYearGraduationRate
                    ? `${(+selectedCollege.fourYearGraduationRate).toFixed(0)} %`
                    : '-'}
                </span>
              </div>
            </div>
          ) : (
            <div className="college-details-section-locked">
              <div>
                <p>Endowment Per Student</p>
                <p>% Undergraduate Women</p>
                <p>% Undergraduate Men</p>
                <p>% Returning for Sophomore Year</p>
                <p>4 year Graduation Rate</p>
              </div>
              <div className="upgrade-profile-message">
                <img src={isMobileScreen ? lockWhite : lock} alt="locked" />
                <p>Information locked</p>
                <span>Upgrade Profile to see all this Information!</span>
                <NavLink
                  to={
                    (isAuthenticated && user.subscription && !adminNavigatedUser) ||
                    (adminNavigatedUser && adminNavigatedUser.subscription && isAuthenticated) ||
                    user.role === 'admin'
                      ? '/buy-plan'
                      : ''
                  }
                  onClick={() => {
                    if (
                      (isAuthenticated && !adminNavigatedUser && user.subscription) ||
                      (isAuthenticated && adminNavigatedUser && adminNavigatedUser.subscription) ||
                      user.role === 'admin'
                    ) {
                      return;
                    }

                    if (
                      (isAuthenticated && !adminNavigatedUser && !user.subscription && user.role !== 'admin') ||
                      (isAuthenticated && adminNavigatedUser && !adminNavigatedUser.subscription)
                    ) {
                      clickUnlock('unlock-modal');
                      openModal();
                      return;
                    }

                    open();
                  }}
                >
                  Unlock
                </NavLink>
              </div>
              <div className="race-res-block">
                <span>$N/A</span>
                <span>N/A%</span>
                <span>N/A%</span>
                <span>N/A%</span>
                <span>N/A%</span>
              </div>
            </div>
          )}
        </div>
        <div className="college-details-section">
          <h6>Race/ Enthnicity</h6>
          <hr className="section-hr-line" />
          <div className="race-info-block">
            <div>
              <p className="unlocked-field">% UG White </p>
              <p className="unlocked-field">% UG African American </p>
              <p className="unlocked-field">% UG Hispanic / Latino </p>
              <p className="unlocked-field">% UG Asian </p>
              <p className="unlocked-field">% UG Non- Residential Alien </p>
            </div>
            <div className="race-res-block">
              <span>
                {selectedCollege?.enWhiteNonhispanic ? `${(+selectedCollege.enWhiteNonhispanic).toFixed(0)} %` : '-'}
              </span>
              <span>
                {selectedCollege?.enBlackNonhispanic ? `${(+selectedCollege.enBlackNonhispanic).toFixed(0)} %` : '-'}
              </span>
              <span>
                {selectedCollege?.enHispanicEthnicity ? `${(+selectedCollege.enHispanicEthnicity).toFixed(0)} %` : '-'}
              </span>
              <span>
                {selectedCollege?.enAsianNonhispanic ? `${(+selectedCollege.enAsianNonhispanic).toFixed(0)} %` : '-'}
              </span>
              <span>{selectedCollege?.enNonresAlien ? `${(+selectedCollege.enNonresAlien).toFixed(0)} %` : '-'}</span>
            </div>
          </div>
        </div>
        <div className="college-details-section-link-faqs">
          <a target="_blank" rel="noreferrer" href="https://www.road2college.com/college-insights-faqs/">
            **College Data sources{' '}
          </a>
        </div>
        <Modal>
          <SignUpModal close={close} message={'Find More Schools and Start Building a College List'} />
        </Modal>
      </div>
    </section>
  );
};
