import './AdminModal.css';
import { useState } from 'react';
import { IConfigurationModel } from '../../models';
import crossBlue from '../../images/crossBlue.svg';
import { axiosService } from '../../services';
import { apiUrls } from '../../consts';

interface Props extends IConfigurationModel {
  close: Function;
  changeConfiguration: Function;
  status: number;
  changeStatus: Function;
}

export const AdminModal = ({ id, name, value, close, adminId, changeConfiguration, status, changeStatus }: Props) => {
  const [valueTextArea, setValueTextArea] = useState<string>(value);
  const [image, setImage] = useState(null);

  const handleSubmitUpdateConfiguration = async (e): Promise<void> => {
    e.preventDefault();
    if (valueTextArea.length === 0) {
      changeStatus(404);
      return;
    }
    changeStatus(200);
    if (image) {
      const formData = new FormData();
      formData.append('file', image);

      axiosService
        .post(apiUrls.upload_file_image_configuration, formData)
        .then((response) => {
          // Handle the response from the backend
        })
        .catch((error) => {
          // Handle any errors
          console.log(error);
        });

      await changeConfiguration({
        id,
        name,
        // @ts-ignore
        value: `${window.location.origin}/images/${image?.name}`,
        adminId,
      });
      // @ts-ignore
      setValueTextArea(`${window.location.origin}/images/${image?.name}`);
      setImage(null);
      setTimeout(() => {
        changeStatus(0);
        close();
      }, 2000);
      return;
    }

    await changeConfiguration({
      id,
      name,
      value: valueTextArea,
      adminId,
    });

    setTimeout(() => {
      changeStatus(0);
      close();
    }, 2000);
  };
  const handleChangeConfigurationValue = (e): void => {
    const { value } = e.target;
    setValueTextArea(value);
    if (value.length !== 0) {
      changeStatus(0);
    }
  };
  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type.startsWith('image/')) {
      changeStatus(800);
      setImage(file);
    } else {
      // The File is not an image, show an error message or take appropriate action
      changeStatus(900);
    }
    setTimeout(() => {
      changeStatus(0);
    }, 2000);
  };

  return (
    <section className="admin-modal">
      <div className="admin-modal__header">
        <h4 className="admin-modal__header__name">{name}</h4>
        <span className="admin-modal__header__cont-cross" onClick={() => close()}>
          <img className="admin-modal__header__cross" src={crossBlue} alt="cross" />
        </span>
      </div>
      <div className="admin-modal__main">
        <p className="admin-modal__main__value">Value:</p>
        <p className={`admin-modal__main__text-info admin-modal__main__text-info-${status}`}>
          {status === 200 && 'Your information was saved'}
          {status === 300 && 'The site will be updated in 1 minute'}
          {status === 404 && 'Please, write a value'}
          {status === 600 && 'Image was not uploaded'}
          {status === 900 && 'Please select an image file'}
          {status === 800 && 'Image was uploaded'}
          {status === 0 && ''}
        </p>
        <form className="admin-modal__main__form" onSubmit={handleSubmitUpdateConfiguration}>
          {id === 3 && (
            <div className="admin-modal__main__form__cont-upload-file">
              <div className="admin-modal__main__form__content-upload-file">
                <div className="admin-modal__main__form__content-upload-file__tooltip">
                  Requirement
                  <br />
                  Size: min 200x200px
                  <br />
                  Type: png, jpeg, gif
                  <br />
                  File size: less than 150kb
                </div>
                <label htmlFor="file-upload" className="admin-modal__main__form__label">
                  Choose File
                </label>
                <input
                  id="file-upload"
                  type="file"
                  onChange={handleFileUpload}
                  className="admin-modal__main__form__input"
                />
              </div>
            </div>
          )}
          <textarea
            disabled={id === 3}
            className="admin-modal__main__form__textarea"
            value={valueTextArea}
            onChange={handleChangeConfigurationValue}
          />
          <button className="admin-configuration__table__item__btn admin-modal__main__form__btn">
            {(status === 0 || status === 404 || status === 900 || status === 800) && 'Submit'}
            {status === 200 && <div className="admin-loader" />}
          </button>
        </form>
      </div>
    </section>
  );
};
