import { EUsersActionTypes } from '../../enums';
import { IUser } from '../../models';

const getUsers = () => {
  return {
    type: EUsersActionTypes.GET_USERS,
  };
};

const setUsers = (payload: Array<IUser>) => {
  return {
    type: EUsersActionTypes.SET_USERS,
    payload,
  };
};

const setSelectedUserId = (payload: number) => {
  return {
    type: EUsersActionTypes.SET_SELECTED_USER_ID,
    payload,
  };
};

const updateUser = (payload: { user: IUser; next: Function }) => {
  return {
    type: EUsersActionTypes.UPDATE_USER,
    payload,
  };
};

const createUpdateUser = (payload: { user: IUser }) => {
  return {
    type: EUsersActionTypes.CREATE_UPDATE_USER,
    payload,
  };
};

const setUser = (payload: IUser) => {
  return {
    type: EUsersActionTypes.SET_USER,
    payload,
  };
};

export const usersAction = {
  getUsers,
  setUsers,
  setSelectedUserId,
  updateUser,
  setUser,
  createUpdateUser,
};
