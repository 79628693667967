import { axiosService } from '.';
import { apiUrls } from '../consts';
import { IUser, IUpdateUser, IUserCountDownloadCsv } from '../models';
import { getApiAccessToken } from '../utils';

export const userService = (function () {
  const endpoint_get_user = async (payload: { email: string }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_user(encodeURIComponent(payload.email)));
  };

  const endpoint_put_user = async (payload: IUpdateUser) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_user, payload);
  };

  const endpoint_get_user_with_all_info = async (userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_user_with_all_info(userId));
  };

  const endpoint_update_user_from_admin = async (user) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.update_user_from_admin, user);
  };

  const endpoint_update_user_count_download_csv = async (user: IUserCountDownloadCsv) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.update_user_count_download_csv, user);
  };

  return {
    endpoint_get_user,
    endpoint_put_user,
    endpoint_get_user_with_all_info,
    endpoint_update_user_from_admin,
    endpoint_update_user_count_download_csv,
  };
})();
