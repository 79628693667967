import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EUsersActionTypes } from '../../enums';
import { IUser } from '../../models';
import { userService, usersService } from '../../services';
import { loadingAction, usersAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EUsersActionTypes.GET_USERS as any, watchGetUsers)]);
  yield all([takeLatest(EUsersActionTypes.UPDATE_USER as any, watchUpdateUser)]);
  yield all([takeLatest(EUsersActionTypes.CREATE_UPDATE_USER as any, watchCreateUpdateUser)]);
}

function* watchGetUsers() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getUsersLoading: true,
      }),
    );

    const { data } = yield call(usersService.endpoint_get_users);

    yield put(usersAction.setUsers(data as Array<IUser>));

    yield put(
      loadingAction.updateLoadingStatus({
        getUsersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetUsers: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getUsersLoading: false,
      }),
    );
  }
}

function* watchUpdateUser(action: { type: string; payload: { user: IUser; next: Function } }) {
  try {
    const { user, next } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: true,
      }),
    );

    const { data } = yield call(userService.endpoint_put_user, user);

    yield put(usersAction.setUser({ ...data } as IUser));

    next();
  } catch (error: any) {
    console.error('watchUpdateUser: ', error);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: false,
      }),
    );
  }
}

function* watchCreateUpdateUser(action: { type: string; payload: { user: IUser } }) {
  try {
    const { user } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: true,
      }),
    );

    yield call(userService.endpoint_put_user, user);

    yield put(
      loadingAction.updateLoadingStatus({
        getUsersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchCreateUpdateUser: ', error);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        updateUserLoading: false,
      }),
    );
  }
}
