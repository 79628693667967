import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';

export const historyDownloadService = (function () {
  const endpoint_create_history_download = async (body) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.post(apiUrls.create_history_download, body);
  };

  const endpoint_get_history_download = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.get(apiUrls.get_history_download(id));
  };

  return {
    endpoint_create_history_download,
    endpoint_get_history_download,
  };
})();
