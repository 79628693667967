import { createSelector } from 'reselect';

import { IHighschoolsState, IRootState } from '../../models';
import { selectUserHighschoolId } from './user.selector';

export const selectHighschoolsState = (rootState: IRootState): IHighschoolsState => rootState.highschoolsState;

export const selectHighschools = createSelector(
  [selectHighschoolsState],
  (highschoolsState) => highschoolsState.highschools,
);

export const selectHighschoolById = createSelector(
  [selectHighschools, selectUserHighschoolId],
  (highschools, highschoolId) => {
    return highschools.find((h) => h.id === highschoolId);
  },
);
