import { produce } from 'immer';

import { createReducer } from '.';
import { subscriptionActionTypes } from '../../consts';
import { IAction, ISubscription, ISubscriptionState } from '../../models';
import { subscriptionState } from '../states/subscription.state';

export const subscriptionReducer = createReducer<ISubscriptionState>(subscriptionState, {
  [subscriptionActionTypes.SET_SUBSCRIPTION]: setSubscription,
});

function setSubscription(state: ISubscriptionState, { payload }: IAction<ISubscription>) {
  return produce(state, (draft) => {
    draft.subscription = payload;
  });
}
