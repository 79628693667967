import { produce } from 'immer';

import { createReducer } from '.';
import { householdIncomeActionTypes } from '../../consts';
import { IAction, IHouseHoldIncomeState } from '../../models';
import { houseHoldIncomeState } from '../states/household-income.state';

export const houseHoldIncomeReducer = createReducer<IHouseHoldIncomeState>(houseHoldIncomeState, {
  [householdIncomeActionTypes.SET_HOUSEHOLDINCOME]: setHouseHoldIncome,
});

function setHouseHoldIncome(houseHoldIncome: IHouseHoldIncomeState, { payload }: IAction<Array<any>>) {
  return produce(houseHoldIncome, (draft) => {
    draft.householdIncome = payload;
  });
}
