import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styles from './SearchFilters.module.css';
import { MinMaxSelectCard } from '../../../../UI/cards/min-max-select-card/MinMaxSelectCard';
import { CostOfAttendanceFilter } from './components/cost-of-attendance-filter/CostOfAttendanceFilter';
import { ApplicationDeadlineFilter } from './components/application-deadline-filter/ApplicationDeadlineFilter';
import { TestScoreFilter } from './components/test-score-filter/TestScoreFilter';
import { StudentGPAFilter } from './components/student-gpa-filter/StudentGPAFilter';
import { AvgMeritAwardFilter } from './components/avg-merit-award/AvgMeritAwardFilter';
import { EPlatform } from '../../../../enums/platform.enum';
import { Button } from '../../../../UI/buttons/button/Button';
import { SearchInputs } from '../search-inputs/SearchInputs';
import { IMinMax, IPowerSearchFilterOption, IRootState } from '../../../../models';
import { powerSearchAction } from '../../../../store/actions';
import { NetCostFilter } from './components/net-cost-filter/NetCostFilter';

const needMetValues: IPowerSearchFilterOption<number>[] = [
  { id: 1, name: '0%', value: 0 },
  { id: 2, name: '5%', value: 5 },
  { id: 3, name: '10%', value: 10 },
  { id: 4, name: '15%', value: 15 },
  { id: 5, name: '20%', value: 20 },
  { id: 6, name: '25%', value: 25 },
  { id: 7, name: '30%', value: 30 },
  { id: 8, name: '35%', value: 35 },
  { id: 9, name: '40%', value: 40 },
  { id: 10, name: '45%', value: 45 },
  { id: 11, name: '50%', value: 50 },
  { id: 12, name: '55%', value: 55 },
  { id: 13, name: '60%', value: 60 },
  { id: 14, name: '65%', value: 65 },
  { id: 15, name: '70%', value: 70 },
  { id: 16, name: '75%', value: 75 },
  { id: 17, name: '80%', value: 80 },
  { id: 18, name: '85%', value: 85 },
  { id: 19, name: '90%', value: 90 },
  { id: 20, name: '95%', value: 95 },
  { id: 21, name: '100%', value: 100 },
];

export const schoolSizeValues: IPowerSearchFilterOption<number>[] = [
  { id: 0, name: '0', value: 0 },
  { id: 1, name: '1,000', value: 1000 },
  { id: 2, name: '2,000', value: 2000 },
  { id: 3, name: '3,000', value: 3000 },
  { id: 4, name: '4,000', value: 4000 },
  { id: 5, name: '5,000', value: 5000 },
  { id: 6, name: '6,000', value: 6000 },
  { id: 7, name: '7,000', value: 7000 },
  { id: 8, name: '8,000', value: 8000 },
  { id: 9, name: '9,000', value: 9000 },
  { id: 10, name: '10,000', value: 10000 },
  { id: 11, name: '11,000', value: 11000 },
  { id: 12, name: '12,000', value: 12000 },
  { id: 13, name: '13,000', value: 13000 },
  { id: 14, name: '14,000', value: 14000 },
  { id: 15, name: '15,000', value: 15000 },
  { id: 16, name: '15,000+', value: 1000000 },
];

const costOfAttendanceRangeMinDistance = 10000;
const costOfAttendanceDefaultRangeValues: number[] = [0, 100000];

const avgMeritAwardMinDistance = 10000;
const avgMeritAwardDefaultRangeValues: number[] = [0, 100000];

const needMetRangeMinDistance = 1;
const needMetDefaultRangeValues: number[] = [0, 100];

const netCostAfterAvgMeritAwardRangeMinDistance = 10000;
const netCostAfterAvgMeritAwardDefaultRangeValues: number[] = [0, 100000];

const schoolSizeRangeMinDistance = 1000;
const schoolSizeDefaultRangeValues: number[] = [0, 70000];

const satRangeMinDistance = 100;
const satDefaultRangeValues: number[] = [700, 1600];

const actRangeMinDistance = 2;
const actDefaultRangeValues: number[] = [12, 36];

const gpaRangeMinDistance = 0.25;
const gpaDefaultRangeValues: number[] = [0, 4.0];

interface SearchFiltersProps {
  platform: EPlatform;
  onCloseHandler?: () => void;
  onClearSearchHandler: () => void;
  onSearchClick: () => void;
  resetFilters: number;
}

export const SearchFilters: React.FC<SearchFiltersProps> = ({
  platform,
  onCloseHandler,
  onClearSearchHandler,
  onSearchClick,
  resetFilters,
}) => {
  const {
    selectedApplicationDeadlines,
    costOfAttendanceTypesSelected,
    costOfAttendanceSelected,
    avgMeritAwardSelected,
    avgMeritAwardTypeSelected,
    netCostTypesSelected,
    netCostAfterAvgMeritAwardSelected,
    needMetSelected,
    schoolSize,
    testScoreValueSelected,
    satScoreSelected,
    actScoreSelected,
    selectedTestScoreType,
    gpa,
    studentGPATypeSelected,
  } = useSelector((state: IRootState) => state.powerSearchState);

  const dispatch = useDispatch();

  const [costOfAttendanceSelectedRangeValues, setCostOfAttendanceSelectedRangeValues] = useState<number[]>(
    costOfAttendanceDefaultRangeValues,
  );

  const [avgMeritAwardRangeSelected, setAvgMeritAwardRangeSelected] = useState<number[]>(
    avgMeritAwardDefaultRangeValues,
  );
  const [avgMeritAwardFilterRadioValueSelected, setAvgMeritAwardFilterRadioValueSelected] = useState<string>('');

  const [netCostAfterAvgMeritAwardRangeSelected, setNetCostAfterAvgMeritAwardRangeSelected] = useState<number[]>(
    netCostAfterAvgMeritAwardDefaultRangeValues,
  );

  const [needMetRangeValuesSelected, setNeedMetRangeValuesSelected] = useState<number[]>(needMetDefaultRangeValues);

  const [schoolSizeValuesRange, setSchoolSizeValuesRange] = useState<number[]>(schoolSizeDefaultRangeValues);

  const [satSelectedRangeValues, setSatSelectedRangeValues] = useState<number[]>(satDefaultRangeValues);
  const [actSelectedRangeValues, setActSelectedRangeValues] = useState<number[]>(actDefaultRangeValues);

  const [gpaSelectedRangeValues, setGpaSelectedRangeValues] = useState<number[]>(gpaDefaultRangeValues);

  const initialRender = useRef({ resetFiltersEffect: true });

  useEffect(() => {
    if (costOfAttendanceSelected) {
      setCostOfAttendanceSelectedRangeValues([
        costOfAttendanceSelected.min || costOfAttendanceDefaultRangeValues[0],
        costOfAttendanceSelected.max || costOfAttendanceDefaultRangeValues[1],
      ]);
    }
    if (schoolSize) {
      setSchoolSizeValuesRange([
        schoolSize.min || schoolSizeDefaultRangeValues[0],
        schoolSize.max || schoolSizeDefaultRangeValues[1],
      ]);
    }
    if (needMetSelected) {
      setNeedMetRangeValuesSelected([
        needMetSelected.min || needMetDefaultRangeValues[0],
        needMetSelected.max || needMetDefaultRangeValues[1],
      ]);
    }
    if (avgMeritAwardSelected) {
      setAvgMeritAwardRangeSelected([
        avgMeritAwardSelected.min || avgMeritAwardDefaultRangeValues[0],
        avgMeritAwardSelected.max || avgMeritAwardDefaultRangeValues[1],
      ]);
    }
    if (netCostAfterAvgMeritAwardSelected) {
      setNetCostAfterAvgMeritAwardRangeSelected([
        netCostAfterAvgMeritAwardSelected.min || netCostAfterAvgMeritAwardDefaultRangeValues[0],
        netCostAfterAvgMeritAwardSelected.max || netCostAfterAvgMeritAwardDefaultRangeValues[1],
      ]);
    }
    if (satScoreSelected) {
      setSatSelectedRangeValues([
        satScoreSelected.min || satDefaultRangeValues[0],
        satScoreSelected.max || satDefaultRangeValues[1],
      ]);
    }
    if (actScoreSelected) {
      setActSelectedRangeValues([
        actScoreSelected.min || actDefaultRangeValues[0],
        actScoreSelected.max || actDefaultRangeValues[1],
      ]);
    }
    if (gpa) {
      setGpaSelectedRangeValues([gpa.min || gpaDefaultRangeValues[0], gpa.max || gpaDefaultRangeValues[1]]);
    }
  }, []);

  const selectMin = (
    newValue: { value: number },
    state: IMinMax,
    setStateFunction: (newValue: IMinMax) => void,
    rangeState: number[],
    setRangeStateFunction: React.Dispatch<SetStateAction<number[]>>,
  ): void => {
    if (state && state.max) {
      if (state.max <= newValue.value) {
        return;
      }
    }
    setStateFunction({ max: state?.max || null, min: newValue.value });
    setRangeStateFunction([newValue.value, rangeState[1]]);
  };

  const selectMax = (
    newValue: { value: number },
    state: IMinMax,
    setStateFunction: (newValue: IMinMax) => void,
    rangeState: number[],
    setRangeStateFunction: React.Dispatch<SetStateAction<number[]>>,
  ): void => {
    if (state?.min) {
      if (newValue.value <= state.min) {
        return;
      }
    }
    setStateFunction({
      max: newValue.value || state?.max || 0,
      min: state?.min ? state.min : state?.min === null ? null : 0,
    });
    setRangeStateFunction([rangeState[0], newValue.value]);
  };

  const handleRangeChange = (
    event: Event,
    newValue: number | number[],
    activeThumb: number,
    rangeState: number[],
    setRangeStateFunction: React.Dispatch<SetStateAction<number[]>>,
    minDistance: number,
    state: IMinMax,
    setStateFunction: (newValue: IMinMax) => void,
  ): void => {
    if (!Array.isArray(newValue)) {
      return;
    }

    if (activeThumb === 0) {
      setRangeStateFunction([Math.min(newValue[0], rangeState[1] - minDistance), rangeState[1]]);
      setStateFunction({ max: state?.max || 0, min: newValue[0] || state?.min || 0 });
    } else {
      setRangeStateFunction([rangeState[0], Math.max(newValue[1], rangeState[0] + minDistance)]);
      setStateFunction({ max: newValue[1] || state?.max || 0, min: state?.min || null });
    }
  };

  const selectOptionalRadioHandler = (
    event: React.MouseEvent<HTMLInputElement>,
    state: string,
    setStateFunction: (newValue: string) => void,
  ): void => {
    if (event.currentTarget.value === state) {
      setStateFunction('');
    } else {
      setStateFunction(event.currentTarget.value);
    }
  };

  const selectDefaultRadioValue = <T,>(
    state: T,
    setStateFunction: (defaultValue: T) => void,
    valueToInsert: T,
  ): void => {
    if (!state) {
      setStateFunction(valueToInsert);
    }
  };

  const resetStateOnRadioDeselect = <T, X>(
    event: React.MouseEvent<HTMLInputElement>,
    radioSelectState: string,
    setStateFunction: (defaultValue: T) => void,
    stateDefaultValue: T,
    setRangeStateFunction: React.Dispatch<SetStateAction<X>>,
    rangeStateDefaultValue: X,
  ): void => {
    if (event.currentTarget.value === radioSelectState) {
      setStateFunction(stateDefaultValue);
      setRangeStateFunction(rangeStateDefaultValue);
    }
  };

  const changeApplicationDeadlineCheckboxValuesSelected = (value: IPowerSearchFilterOption<string>): void => {
    if (selectedApplicationDeadlines.includes(value)) {
      dispatch(powerSearchAction.removeFromSelectedApplicationDeadline(value.id));
    } else {
      dispatch(powerSearchAction.addSelectedApplicationDeadline(value));
    }
  };

  const changeCostOfAttendanceTypesSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (costOfAttendanceTypesSelected.includes(value)) {
      dispatch(powerSearchAction.removeFromCostOfAttendanceTypesSelected(value));
    } else {
      dispatch(powerSearchAction.addCostOfAttendanceTypeSelected(value));
    }
  };

  const changeNetCostTypesSelected = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (netCostTypesSelected.includes(value)) {
      dispatch(powerSearchAction.removeFromNetCostTypeSelected(value));
    } else {
      dispatch(powerSearchAction.addNetCostTypeSelected(value));
    }
  };

  useEffect(() => {
    if (testScoreValueSelected === 'SAT') {
      dispatch(powerSearchAction.setActScoreSelected(null));
      setActSelectedRangeValues(actDefaultRangeValues);
    }
    if (testScoreValueSelected === 'ACT') {
      dispatch(powerSearchAction.setSatScoreSelected(null));
      setSatSelectedRangeValues(satDefaultRangeValues);
    }
    if (testScoreValueSelected === '') {
      dispatch(powerSearchAction.setActScoreSelected(null));
      setActSelectedRangeValues(actDefaultRangeValues);
      dispatch(powerSearchAction.setSatScoreSelected(null));
      setSatSelectedRangeValues(satDefaultRangeValues);
    }
  }, [testScoreValueSelected]);

  const clearFilters = (): void => {
    dispatch(powerSearchAction.clearFilters());
    setCostOfAttendanceSelectedRangeValues(costOfAttendanceDefaultRangeValues);
    setNeedMetRangeValuesSelected(needMetDefaultRangeValues);
    setSchoolSizeValuesRange(schoolSizeDefaultRangeValues);
    setAvgMeritAwardRangeSelected(avgMeritAwardDefaultRangeValues);
    setAvgMeritAwardFilterRadioValueSelected('');
    setNetCostAfterAvgMeritAwardRangeSelected(netCostAfterAvgMeritAwardDefaultRangeValues);
    setSatSelectedRangeValues(satDefaultRangeValues);
    setActSelectedRangeValues(actDefaultRangeValues);
    setGpaSelectedRangeValues(gpaDefaultRangeValues);
  };

  useEffect(() => {
    if (!initialRender.current['resetFiltersEffect']) {
      clearFilters();
    } else {
      initialRender.current['resetFiltersEffect'] = false;
    }
  }, [resetFilters]);

  return (
    <aside className={styles.container}>
      {(platform === EPlatform.TABLET || platform === EPlatform.MOBILE) && (
        <>
          <span onClick={onCloseHandler} className={styles.closeIcon} />
          <h2 className={styles.title}>More Filters</h2>
        </>
      )}
      {(platform === EPlatform.TABLET || platform === EPlatform.MOBILE) && (
        <SearchInputs platform={platform} isFiltersModal={true} />
      )}
      <CostOfAttendanceFilter
        checkboxesSelected={costOfAttendanceTypesSelected}
        changeCheckboxesSelected={changeCostOfAttendanceTypesSelected}
        selectedValues={costOfAttendanceSelected}
        selectMin={(value) =>
          selectMin(
            value,
            costOfAttendanceSelected,
            (newValue) => dispatch(powerSearchAction.setCostOfAttendance(newValue)),
            costOfAttendanceSelectedRangeValues,
            setCostOfAttendanceSelectedRangeValues,
          )
        }
        selectMax={(value) =>
          selectMax(
            value,
            costOfAttendanceSelected,
            (newValue) => dispatch(powerSearchAction.setCostOfAttendance(newValue)),
            costOfAttendanceSelectedRangeValues,
            setCostOfAttendanceSelectedRangeValues,
          )
        }
        rangeValues={costOfAttendanceDefaultRangeValues}
        selectedRangeValues={costOfAttendanceSelectedRangeValues}
        handleChangeRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            costOfAttendanceSelectedRangeValues,
            setCostOfAttendanceSelectedRangeValues,
            costOfAttendanceRangeMinDistance,
            costOfAttendanceSelected,
            (newValue) => dispatch(powerSearchAction.setCostOfAttendance(newValue)),
          )
        }
        platform={platform}
      />
      <AvgMeritAwardFilter
        selectedRadioValue={avgMeritAwardTypeSelected}
        changeSelectedRadioValue={(event) => {
          selectOptionalRadioHandler(event, avgMeritAwardTypeSelected, (newValue) =>
            dispatch(powerSearchAction.setAvgMeritAwardTypeSelected(newValue)),
          );
          resetStateOnRadioDeselect<IMinMax, number[]>(
            event,
            avgMeritAwardTypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setAvgMeritAwardSelected(defaultValue)),
            null,
            setAvgMeritAwardRangeSelected,
            avgMeritAwardDefaultRangeValues,
          );
        }}
        selectedValues={avgMeritAwardSelected}
        selectMin={(value) => {
          selectMin(
            value,
            avgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setAvgMeritAwardSelected(newValue)),
            avgMeritAwardRangeSelected,
            setAvgMeritAwardRangeSelected,
          );
          selectDefaultRadioValue<string>(
            avgMeritAwardFilterRadioValueSelected,
            (defaultValue) => dispatch(powerSearchAction.setAvgMeritAwardTypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        selectMax={(value) => {
          selectMax(
            value,
            avgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setAvgMeritAwardSelected(newValue)),
            avgMeritAwardRangeSelected,
            setAvgMeritAwardRangeSelected,
          );
          selectDefaultRadioValue<string>(
            avgMeritAwardTypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setAvgMeritAwardTypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        selectedRangeValues={avgMeritAwardRangeSelected}
        handleChangeSelectedRange={(event, newValue, activeThumb) => {
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            avgMeritAwardRangeSelected,
            setAvgMeritAwardRangeSelected,
            avgMeritAwardMinDistance,
            avgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setAvgMeritAwardSelected(newValue)),
          );
          selectDefaultRadioValue(
            avgMeritAwardTypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setAvgMeritAwardTypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        platform={platform}
      />
      <NetCostFilter
        checkboxesSelected={netCostTypesSelected}
        changeCheckboxesSelected={changeNetCostTypesSelected}
        selectedValues={netCostAfterAvgMeritAwardSelected}
        selectMin={(value) =>
          selectMin(
            value,
            netCostAfterAvgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setNetCostAfterAvgMeritAwardSelected(newValue)),
            netCostAfterAvgMeritAwardRangeSelected,
            setNetCostAfterAvgMeritAwardRangeSelected,
          )
        }
        selectMax={(value) =>
          selectMax(
            value,
            netCostAfterAvgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setNetCostAfterAvgMeritAwardSelected(newValue)),
            netCostAfterAvgMeritAwardRangeSelected,
            setNetCostAfterAvgMeritAwardRangeSelected,
          )
        }
        selectedRangeValues={netCostAfterAvgMeritAwardRangeSelected}
        handleChangeRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            netCostAfterAvgMeritAwardRangeSelected,
            setNetCostAfterAvgMeritAwardRangeSelected,
            netCostAfterAvgMeritAwardRangeMinDistance,
            netCostAfterAvgMeritAwardSelected,
            (newValue) => dispatch(powerSearchAction.setNetCostAfterAvgMeritAwardSelected(newValue)),
          )
        }
        platform={platform}
      />
      <MinMaxSelectCard
        selectedValues={needMetSelected}
        selectVariant={platform === EPlatform.DESKTOP ? 'autocompleteButtons' : 'rangeSlider'}
        title="% of Need Met"
        values={needMetValues}
        onMinSelectHandler={(value) =>
          selectMin(
            value,
            needMetSelected,
            (newValue) => dispatch(powerSearchAction.setNeedMetSelected(newValue)),
            needMetRangeValuesSelected,
            setNeedMetRangeValuesSelected,
          )
        }
        onMaxSelectHandler={(value) =>
          selectMax(
            value,
            needMetSelected,
            (newValue) => dispatch(powerSearchAction.setNeedMetSelected(newValue)),
            needMetRangeValuesSelected,
            setNeedMetRangeValuesSelected,
          )
        }
        rangeMinValue={needMetDefaultRangeValues[0]}
        rangeMaxValue={needMetDefaultRangeValues[1]}
        rangeStep={needMetRangeMinDistance}
        rangeValues={needMetRangeValuesSelected}
        handleChangeRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            needMetRangeValuesSelected,
            setNeedMetRangeValuesSelected,
            needMetRangeMinDistance,
            needMetSelected,
            (newValue) => dispatch(powerSearchAction.setNeedMetSelected(newValue)),
          )
        }
        valueSign={'%'}
      />
      <MinMaxSelectCard
        selectedValues={schoolSize}
        values={schoolSizeValues}
        selectVariant={platform === EPlatform.DESKTOP ? 'autocompleteButtons' : 'rangeSlider'}
        title="School Size"
        onMinSelectHandler={(value) =>
          selectMin(
            value,
            schoolSize,
            (newValue) => dispatch(powerSearchAction.setSchoolSize(newValue)),
            schoolSizeValuesRange,
            setSchoolSizeValuesRange,
          )
        }
        onMaxSelectHandler={(value) =>
          selectMax(
            value,
            schoolSize,
            (newValue) => dispatch(powerSearchAction.setSchoolSize(newValue)),
            schoolSizeValuesRange,
            setSchoolSizeValuesRange,
          )
        }
        rangeMinValue={schoolSizeDefaultRangeValues[0]}
        rangeMaxValue={schoolSizeDefaultRangeValues[1]}
        rangeValues={schoolSizeValuesRange}
        rangeStep={schoolSizeRangeMinDistance}
        handleChangeRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            schoolSizeValuesRange,
            setSchoolSizeValuesRange,
            schoolSizeRangeMinDistance,
            schoolSize,
            (newValue) => dispatch(powerSearchAction.setSchoolSize(newValue)),
          )
        }
        valueSign={null}
        signBeforeValue={false}
      />
      {platform === EPlatform.DESKTOP && (
        <ApplicationDeadlineFilter
          checkboxesSelected={selectedApplicationDeadlines}
          changeCheckboxesSelected={changeApplicationDeadlineCheckboxValuesSelected}
        />
      )}
      <TestScoreFilter
        selectedTestScore={testScoreValueSelected}
        changeSelectedTestScore={(event) =>
          selectOptionalRadioHandler(event, testScoreValueSelected, (newValue) =>
            dispatch(powerSearchAction.setTestScoreValueSelected(newValue)),
          )
        }
        selectedSatValues={satScoreSelected}
        selectedSatRangeValues={satSelectedRangeValues}
        selectSatMin={(score) =>
          selectMin(
            score,
            satScoreSelected,
            (newValue) => dispatch(powerSearchAction.setSatScoreSelected(newValue)),
            satSelectedRangeValues,
            setSatSelectedRangeValues,
          )
        }
        selectSatMax={(score) =>
          selectMax(
            score,
            satScoreSelected,
            (newValue) => dispatch(powerSearchAction.setSatScoreSelected(newValue)),
            satSelectedRangeValues,
            setSatSelectedRangeValues,
          )
        }
        handleChangeSelectedSatRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            satSelectedRangeValues,
            setSatSelectedRangeValues,
            satRangeMinDistance,
            satScoreSelected,
            (newValue) => dispatch(powerSearchAction.setSatScoreSelected(newValue)),
          )
        }
        selectedActValues={actScoreSelected}
        selectedActRangeValues={actSelectedRangeValues}
        selectActMin={(score) =>
          selectMin(
            score,
            actScoreSelected,
            (newValue) => dispatch(powerSearchAction.setActScoreSelected(newValue)),
            actSelectedRangeValues,
            setActSelectedRangeValues,
          )
        }
        selectActMax={(score) =>
          selectMax(
            score,
            actScoreSelected,
            (newValue) => dispatch(powerSearchAction.setActScoreSelected(newValue)),
            actSelectedRangeValues,
            setActSelectedRangeValues,
          )
        }
        handleChangeSelectedActRange={(event, newValue, activeThumb) =>
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            actSelectedRangeValues,
            setActSelectedRangeValues,
            actRangeMinDistance,
            actScoreSelected,
            (newValue) => dispatch(powerSearchAction.setActScoreSelected(newValue)),
          )
        }
        selectedFallType={selectedTestScoreType}
        changeSelectedFallType={(event) =>
          selectOptionalRadioHandler(event, selectedTestScoreType, (newValue) =>
            dispatch(powerSearchAction.setSelectedTestScoreType(newValue)),
          )
        }
        selectVariant={platform === EPlatform.DESKTOP ? 'autocompleteButtons' : 'rangeSlider'}
      />
      <StudentGPAFilter
        selectedGpa={gpa}
        selectGpaMin={(gpaValue) => {
          selectMin(
            gpaValue,
            gpa,
            (newValue) => dispatch(powerSearchAction.setGpa(newValue)),
            gpaSelectedRangeValues,
            setGpaSelectedRangeValues,
          );
          selectDefaultRadioValue<string>(
            studentGPATypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setStudentGPATypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        selectGpaMax={(gpaValue) => {
          selectMax(
            gpaValue,
            gpa,
            (newValue) => dispatch(powerSearchAction.setGpa(newValue)),
            gpaSelectedRangeValues,
            setGpaSelectedRangeValues,
          );
          selectDefaultRadioValue<string>(
            studentGPATypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setStudentGPATypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        selectedRangeValues={gpaSelectedRangeValues}
        handleChangeRange={(event, newValue, activeThumb) => {
          handleRangeChange(
            event,
            newValue,
            activeThumb,
            gpaSelectedRangeValues,
            setGpaSelectedRangeValues,
            gpaRangeMinDistance,
            gpa,
            (newValue) => dispatch(powerSearchAction.setGpa(newValue)),
          );
          selectDefaultRadioValue<string>(
            studentGPATypeSelected,
            (defaultValue) => dispatch(powerSearchAction.setStudentGPATypeSelected(defaultValue)),
            'schoolReported',
          );
        }}
        selectedRadioValue={studentGPATypeSelected}
        changeSelectedRadioValue={(event) => {
          selectOptionalRadioHandler(event, studentGPATypeSelected, (newValue) =>
            dispatch(powerSearchAction.setStudentGPATypeSelected(newValue)),
          );
          resetStateOnRadioDeselect<IMinMax, number[]>(
            event,
            studentGPATypeSelected,
            () => dispatch(powerSearchAction.setGpa(null)),
            null,
            setGpaSelectedRangeValues,
            gpaDefaultRangeValues,
          );
        }}
        selectVariant={platform === EPlatform.DESKTOP ? 'autocompleteButtons' : 'rangeSlider'}
      />
      <div className={styles.bottomButtonsContainer}>
        <Button textContent="Submit" onClickHandler={onSearchClick} variant="primaryBlue" />
        <p
          onClick={() => {
            clearFilters();
            onClearSearchHandler();
          }}
          className={styles.bottomButtons__ClearBtn}
        >
          Clear Search
        </p>
      </div>
    </aside>
  );
};
