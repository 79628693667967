import React, { useEffect } from 'react';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { subscriptionService } from '../../services';
import { userAction } from '../../store/actions';

const ApplePay = ({
  packageId,
  couponCode,
  price,
  onSubscriptionGranted,
  email,
  isValidEmail,
}: {
  packageId: number;
  couponCode: string | null;
  price: number;
  onSubscriptionGranted: () => void;
  email: string;
  isValidEmail: RegExp;
}) => {
  const { user } = useSelector((state: any) => state.userState);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!stripe || (!isAuthenticated && !email.match(isValidEmail))) {
      return;
    }
    const elements = stripe.elements();

    const pr = stripe.paymentRequest({
      currency: 'usd',
      country: 'US',
      requestPayerName: true,
      requestPayerEmail: true,
      total: {
        label: +packageId % 2 === 0 ? 'College Insights Monthly Subscription' : 'College Insights Annual Subscription',
        amount: price,
      },
    });

    const prButton = elements.create('paymentRequestButton', {
      paymentRequest: pr,
    });

    pr.canMakePayment()
      .then((result) => {
        if (result) {
          prButton.mount('#payment-request-button');
        } else {
          const btn = document.getElementById('payment-request-button');
          if (btn) {
            btn.style.display = 'none';
          }
        }
      })
      .catch((error) => console.error('Apple Pay payment request error: ', error));

    pr.on('paymentmethod', async (e) => {
      let data;

      if (user?.subscription?.id) {
        data = await subscriptionService
          .endpoint_update_subscription(user.subscription.id, {
            email: user.email,
            paymentMethodId: e.paymentMethod?.id,
            packageId,
            couponCode,
            timeSubscription: packageId % 2 === 0 ? 'month' : 'year',
          })
          .catch((e) => {
            e.complete('fail');
            return null;
          });
      } else {
        data = await subscriptionService
          .endpoint_create_subscription({
            email: user?.email || email,
            paymentMethodId: e.paymentMethod?.id,
            packageId,
            couponCode,
            timeSubscription: packageId % 2 === 0 ? 'month' : 'year',
          })
          .catch((e) => {
            e.complete('fail');
            return null;
          });
      }
      if (data && user?.email) {
        const body = {
          email: user.email,
          hadSubscription: true,
          canceledSubscription: false,
        };
        dispatch(userAction.updateUser({ user: body }));
        e.complete('success');
        dispatch(userAction.getUser({ email: user.email }));
        onSubscriptionGranted();
      } else {
        e.complete('success');
        onSubscriptionGranted();
      }
    });
  }, [stripe, elements, packageId, price, email]);

  return (
    <div>
      <div id="payment-request-button" />
    </div>
  );
};

export default ApplePay;
