import { EMajorsActionTypes } from '../../enums';

const getMajors = () => {
  return {
    type: EMajorsActionTypes.GET_MAJORS,
  };
};

const setMajors = (payload: Array<any>) => {
  return {
    type: EMajorsActionTypes.SET_MAJORS,
    payload,
  };
};

export const majorsAction = {
  getMajors,
  setMajors,
};
