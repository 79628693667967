/* eslint-disable */
import './My-profile.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import moment from 'moment';
import { NavLink } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import wave from '../../images/Vector.svg';
import { selectHighschoolById, selectStateById } from '../../store/selectors';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { IEfc, IHighschool, IHouseHoldIncome, IMajors, IState } from '../../models';
import { highschoolsAction, householdIncomeAction, previousRouteAction, userAction } from '../../store/actions';
import arrow from '../../images/select-arrow.svg';
import arrowOrange from '../../images/select-arrow-orange.svg';
import locations from '../../images/Location.svg';
import locationsOrange from '../../images/Location-orange.svg';
import cross from '../../images/cross.svg';
import { EUserRoles } from '../../enums';
import { ScholarshipModal } from '../scholarship-modal/ScholarshipModal';
import { UnsubscribeModal } from '../unsubscribe-modal/UnsubscribeModal';
import { planOptions } from '../../utils/planOptions';
import { getRoles, getGenders } from '../../utils';

export const MyProfile = () => {
  const { user } = useSelector((state: any) => state.userState);

  const { highschools } = useSelector((state: any) => state.highschoolsState);
  const highschool: any = useSelector(selectHighschoolById);
  const location: any = useSelector(selectStateById);
  const { efcs } = useSelector((state: any) => state.efcsState);
  const { householdIncome } = useSelector((state: any) => state.houseHoldIncomeState);
  const { states }: { states: IState[] } = useSelector((state: any) => state.statesState);
  const { majors } = useSelector((state: any) => state.majorsState);
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);

  const roles = getRoles();
  const genders = getGenders();

  const [isFamilyIncomeInputActive, setIsFamilyIncomeInputActive] = useState(false);
  const [familyIncome, setFamilyIncome] = useState(
    householdIncome.find((e: IHouseHoldIncome) => e.name === user?.familyIncome) || null,
  );

  const [isFamilyAverageIncomeInputActive, setIsFamilyAverageIncomeInputActive] = useState(false);
  const [familyAverageIncome, setFamilyAverageIncome] = useState(
    efcs.find((e: IEfc) => e.name === user?.familyContributions) || null,
  );

  const [graduationYear, setGraduationYear] = useState(user?.yearOfHighSchoolGraduation || null);
  const [isGraduationYearInputActive, setIsGraduationYearInputActive] = useState(false);

  const [filteredMajors, setFilteredMajors] = useState(majors || '');
  const [searchMajorsValue, setSearchMajorsValue] = useState('');
  const [isMajorsInputActive, setIsMajorsInputActive] = useState(false);
  const [selectedMajors, setSelectedMajors] = useState<IMajors[]>(user?.majors || []);

  const [isRole, setIsRole] = useState(false);
  const [role, setRole] = useState(roles.find((r) => r.value === user?.role));

  const [isGender, setIsGender] = useState(false);
  const [gender, setGender] = useState(genders.find((g) => g.value === user?.gender));

  const [athelete, setAthelete] = useState(user?.athlete || null);

  const [isStateInputActive, setIsStateInputActive] = useState(false);
  const [searchStateValue, setSearchStateValue] = useState(location?.name);
  const [filteredStates, setFilteredStates] = useState(states || '');

  const [fullNameValue, setfullNameValue] = useState(user.name);

  const [emailValue, setemailValue] = useState(user.email);

  const [filteredHighschools, setFilteredHighschools] = useState(highschools);
  const [searchHighschoolsValue, setSearchHighschoolsValue] = useState(highschool?.name);
  const [isHighschoolsInputActive, setIsHighschoolsInputActive] = useState(false);

  const [gpa, setGpa] = useState<number>(user?.gpa);
  const [satValue, setSatValue] = useState<number | null>(user?.sat || null);
  const [actValue, setActValue] = useState<number | null>(user?.act || null);

  const [subscription, setSubscription] = useState<any>(null);
  const [isSubscription, setIsSubscription] = useState<boolean>(false);
  const [recommendedCategory, setRecommendedCategory] = useState(localStorage.getItem('recommendationCategory') || '');
  const [clickedOrangeInput, setClickedOrangeInput] = useState('');
  const [previousRouteAfterUpdateBtn, setPreviousRouteAfterUpdateBtn] = useState(
    previousRoute?.payload === '/home-page-after-update',
  );
  const [act, setAct] = useState(!!(user?.act || (recommendedCategory.includes('act') && previousRouteAfterUpdateBtn)));
  const [sat, setSat] = useState(!!(user?.sat || (recommendedCategory.includes('sat') && previousRouteAfterUpdateBtn)));

  const [budget, setBudget] = useState<IEfc | null>(null);
  const [isBudgetInputActive, setIsBudgetInputActive] = useState(false);

  const [psatScore, setPsatScore] = useState(user?.psatScore || null);

  const selectBudget = (efc: IEfc) => {
    setBudget(efc);
    setIsBudgetInputActive(false);
  };

  const childRef = useRef<any>();
  const dispatch = useDispatch();

  const locationRef = useRef(null);
  const majorsRef = useRef(null);
  const actRef = useRef(null);
  const gpaRef = useRef(null);
  const satRef = useRef(null);

  const [Modal, open, close] = useModal('root', {
    closeOnOverlayClick: false,
  });

  const scrollingToElement = (refValue) => {
    refValue.current.scrollIntoView({
      behavior: 'smooth',
      block: 'center',
      inline: 'end',
    });
  };

  useEffect(() => {
    previousRouteAction.setPreviousRoute('/my-profile');
    dispatch(householdIncomeAction.getHouseHoldIncome());
    dispatch(previousRouteAction.setPreviousRoute('/my-profile'));
  }, []);

  useEffect(() => {
    if (actRef && actRef.current && recommendedCategory.includes('act')) {
      scrollingToElement(actRef);
      return;
    }
    if (locationRef && locationRef.current && recommendedCategory.includes('location')) {
      scrollingToElement(locationRef);
      return;
    }
    if (gpaRef && gpaRef.current && recommendedCategory.includes('gpa')) {
      scrollingToElement(gpaRef);
      return;
    }
    if (satRef && satRef.current && recommendedCategory.includes('sat')) {
      scrollingToElement(satRef);
      return;
    }
    if (majorsRef && majorsRef.current && recommendedCategory.includes('majors')) {
      scrollingToElement(majorsRef);
    }
  }, [locationRef, gpaRef, actRef, satRef, majorsRef]);

  useEffect(() => {
    setBudget(efcs.find((e: IEfc) => e.id === user.efcId));

    setSubscription(user.subscription ?? null);

    if (user?.stateId) {
      dispatch(highschoolsAction.getHighschools({ stateId: user.stateId }));
    }
  }, [user]);

  useEffect(() => {
    setFamilyAverageIncome(familyAverageIncome);
  }, [familyAverageIncome]);

  useEffect(() => {
    if (highschools) {
      const copyHighschool = [...highschools];
      const sortedHighschool = copyHighschool?.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredHighschools(sortedHighschool);
    }
  }, [highschools]);

  useEffect(() => {
    if (states) {
      const copyStates = [...states];
      const sortedStates = copyStates?.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredStates(sortedStates);
    }
  }, [states]);

  useEffect(() => {
    if (majors) {
      const copyMajors = [...majors];
      const sortedMajors = copyMajors?.sort((a, b) => a.name.localeCompare(b.name));
      setFilteredMajors(sortedMajors);
    }
  }, [majors]);

  const selectUserRole = (role: any) => {
    setRole(role);
    setIsRole(false);
    rotateArrow('role', false);
  };

  const selectUserGender = (gender: any) => {
    setGender(gender);
    setIsGender(false);
    rotateArrow('gender', false);
  };

  const prettyNumbers = (efc: string) => {
    return efc
      .split(' ')
      .map((item: any) => {
        if (Number.isNaN(+item)) {
          return item;
        }
        return `$ ${(+item).toLocaleString('en-US')}`;
      })
      .join(' ');
  };

  const selectFamilyIncome = (householdIncome: IHouseHoldIncome) => {
    setFamilyIncome(householdIncome);
    setIsFamilyIncomeInputActive(false);
    rotateArrow('income', false);
  };

  const selectAverageFamilyIncome = (efc: IEfc) => {
    setFamilyAverageIncome(efc);
    setIsFamilyAverageIncomeInputActive(false);
    rotateArrow('average', false);
  };

  const filterHighschools = (inputValue: string) => {
    const nextValue = highschools.filter(
      (highschool: IHighschool) =>
        highschool.name.toLowerCase().includes(inputValue.toLowerCase().trim()) &&
        highschool.name !== 'School Not Listed',
    );
    setFilteredHighschools([highschools[0], ...nextValue]);
    setIsHighschoolsInputActive(true);
  };

  const selectState = (state: IState) => {
    dispatch(highschoolsAction.getHighschools({ stateId: state.id }));
    setSearchStateValue(state.name);
    setIsStateInputActive(false);
  };

  const filterStatesNames = (inputValue: string) => {
    const nextValue = states.filter((state: IState) =>
      state.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredStates(nextValue);
    setIsStateInputActive(true);
  };

  const filterMajors = (inputValue: string) => {
    const nextValue = majors.filter((major: IMajors) =>
      major.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredMajors(nextValue);
    setIsMajorsInputActive(true);
  };

  const selectHighschool = (highschool: IHighschool) => {
    setSearchHighschoolsValue(highschool.name);
    setIsHighschoolsInputActive(false);
    rotateArrow('school', false);
  };

  const selectMajors = (major: IMajors) => {
    if (selectedMajors.findIndex((m) => m.id === major.id) === -1) {
      setSelectedMajors([...selectedMajors, major]);
      setSearchMajorsValue('');
    }
    setIsMajorsInputActive(false);
    rotateArrow('major', false);
  };

  const selectYearOfGraduation = (year: any) => {
    setGraduationYear(year.name);
    setIsGraduationYearInputActive(false);
    rotateArrow('grade', false);
  };

  const rotateArrow = (id: string, rotate: boolean) => {
    const arrow = document.getElementById(id);
    const focus = document.getElementById(`${id}-input`);
    if (rotate) {
      arrow?.classList.add('look-up');
      arrow?.classList.remove('look-down');
      focus?.focus();
    } else {
      arrow?.classList.remove('look-up');
      arrow?.classList.add('look-down');
    }
  };

  const unsubscribe = () => {
    if (planOptions.some((plan) => plan.id === subscription?.packageId)) {
      setIsSubscription(true);
      open();
    }
  };

  const yearsOfGraduation: any[] = [];

  for (let i = 0; i < 11; i++) {
    const currentYear = moment().year() - 2;
    yearsOfGraduation.push({ id: i, name: currentYear + i });
  }

  const updateProfile = () => {
    const stateId = states.find((s: IState) => s.name === searchStateValue)?.id;
    const highschoolId = highschools.find((h: IHighschool) => h.name === searchHighschoolsValue)?.id;
    if (!fullNameValue) {
      const fullNameInput = document.getElementById('fullNameChecked') as HTMLInputElement;
      if (fullNameInput) {
        fullNameInput.required = true;
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }

    if (!emailValue) {
      const emailInput = document.getElementById('emailChecked') as HTMLInputElement;
      if (emailInput) {
        emailInput.required = true;
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }

    if ((fullNameValue && emailValue && role) || (fullNameValue && emailValue && user?.role === 'admin')) {
      console.log('seleccionaod', budget);

      const body = {
        email: emailValue,
        role: role?.value || user?.role,
        stateId: stateId || null,
        highschoolId: highschoolId || null,
        familyIncome: familyIncome?.name || null,
        familyContributions: familyAverageIncome?.name || null,
        efcId: budget?.id || user.efcId || null,
        yearOfHighSchoolGraduation: graduationYear,
        psatScore,
        gender: gender?.value ? gender.value : null,
        name: fullNameValue,
        athlete: athelete,
        sat: sat ? satValue || null : null,
        act: act ? actValue || null : null,
        gpa: gpa || null,
        majors: selectedMajors.map((m) => m.id),
      };
      dispatch(userAction.updateUser({ user: body }));
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
      setIsSubscription(false);
      open();
    }
  };

  return (
    <>
      <div className="header-profile">
        <h1>Your Profile</h1>
        {/* <img className="wave" src={wave} alt="wave" /> */}
      </div>

      <div className="my-profile-main">
        <div className="my-profile-wrapper">
          <div className="my-profile-body">
            <div className="my-profile-form">
              <div className="input-location" id="fullNameField">
                <input
                  id="fullNameChecked"
                  type="text"
                  name="get"
                  placeholder="Full Name"
                  value={fullNameValue}
                  onChange={({ target }) => setfullNameValue(target.value)}
                  required={false}
                />
                <p>Full Name</p>
              </div>
              <div className="input-location" id="emailField">
                <input
                  id="emailChecked"
                  type="text"
                  placeholder="Email"
                  value={emailValue}
                  onChange={({ target }) => setemailValue(target.value)}
                  required={false}
                />
                <p>Email</p>
              </div>
              <div className="input-select" id="genderField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="gender"
                  onClick={() => {
                    rotateArrow('gender', !isGender);
                    setIsGender(!isGender);
                  }}
                />
                <input
                  readOnly
                  type="text"
                  id="gender-input"
                  placeholder="Gender"
                  value={gender?.name || ''}
                  onClick={() => {
                    setIsGender(true);
                    rotateArrow('gender', true);
                  }}
                  onBlur={() => {
                    setIsGender(false);
                    rotateArrow('gender', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isGender && efcs && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectUserGender}
                    filteredItem={genders}
                    classN="margin-top-mobile"
                  />
                )}
                <p>Gender</p>
              </div>
              <div className="input-select" id="roleField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="role"
                  onClick={() => {
                    rotateArrow('role', !isRole);
                    setIsRole(!isRole);
                  }}
                />
                <input
                  readOnly
                  type="text"
                  id="role-input"
                  placeholder="Role"
                  value={role?.name || ''}
                  onClick={() => {
                    setIsRole(true);
                    rotateArrow('role', true);
                  }}
                  onBlur={() => {
                    setIsRole(false);
                    rotateArrow('role', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isRole && efcs && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectUserRole}
                    filteredItem={roles}
                    classN="margin-top-mobile"
                  />
                )}
                <p>Role</p>
              </div>
              <div className="input-select" id="locationField">
                <img
                  className="location-img"
                  src={
                    recommendedCategory.includes('location') &&
                    clickedOrangeInput !== 'any' &&
                    !user?.state &&
                    previousRouteAfterUpdateBtn
                      ? locationsOrange
                      : locations
                  }
                  alt="location"
                />
                <input
                  ref={locationRef}
                  id="locationChecked"
                  type="text"
                  className={
                    recommendedCategory.includes('location') &&
                    clickedOrangeInput !== 'any' &&
                    !user?.state &&
                    previousRouteAfterUpdateBtn
                      ? 'my-profile-input-orange'
                      : ''
                  }
                  placeholder="Location"
                  value={searchStateValue || ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsStateInputActive(true);
                    setClickedOrangeInput('any');
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    setIsStateInputActive(false);
                  }}
                  onChange={({ target }) => setSearchStateValue(target.value)}
                  onInput={({ target }: { target: any }) => filterStatesNames(target.value)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                <p
                  className={
                    recommendedCategory.includes('location') &&
                    clickedOrangeInput !== 'any' &&
                    !user?.state &&
                    previousRouteAfterUpdateBtn
                      ? 'orange-text'
                      : ''
                  }
                >
                  Location
                </p>
                {isStateInputActive && filteredStates && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectState}
                    filteredItem={filteredStates}
                    classN="margin-top-mobile"
                  />
                )}
              </div>
              <div className="input-select" id="schoolField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="school"
                  onClick={() => {
                    rotateArrow('school', !isHighschoolsInputActive);
                    setIsHighschoolsInputActive(!isHighschoolsInputActive);
                  }}
                />
                <input
                  readOnly={true}
                  id="school-input"
                  type="text"
                  autoComplete="off"
                  placeholder="Choose High School"
                  value={
                    searchHighschoolsValue?.length && searchHighschoolsValue?.length >= 33
                      ? `${searchHighschoolsValue.slice(0, 33)}...`
                      : searchHighschoolsValue
                  }
                  onClick={() => {
                    setIsHighschoolsInputActive(true);
                    rotateArrow('school', true);
                  }}
                  onChange={({ target }) => setSearchHighschoolsValue(target.value)}
                  onInput={({ target }: { target: any }) => filterHighschools(target.value)}
                  onBlur={() => {
                    setIsHighschoolsInputActive(false);
                    rotateArrow('school', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isHighschoolsInputActive && filteredHighschools && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectHighschool}
                    filteredItem={filteredHighschools}
                    classN="margin-top-mobile"
                  />
                )}
                <p>High School</p>
              </div>
              <div className="input-select" id="budgetField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="budget"
                  className={isBudgetInputActive ? 'look-up' : 'look-down'}
                  onClick={(e) => {
                    e.stopPropagation();
                    // if (!isBudgetInputActive) {
                    //   activateInput('budgetChecked');
                    // }
                    setIsBudgetInputActive(!isBudgetInputActive);
                  }}
                />
                <input
                  id="budgetChecked"
                  type="text"
                  placeholder="Your Budget"
                  defaultValue={budget ? prettyNumbers(budget.name) : ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsBudgetInputActive(true);
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    setIsBudgetInputActive(false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isBudgetInputActive && efcs && (
                  <Autocomplete
                    isBudgetItems={true}
                    ref={childRef}
                    onclick={selectBudget}
                    filteredItem={efcs}
                    classN="margin-top-mobile"
                  />
                )}
                <p>College Budget</p>
              </div>
              <div className="input-select" id="contributionField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="average"
                  onClick={() => {
                    rotateArrow('average', !isFamilyAverageIncomeInputActive);
                    setIsFamilyAverageIncomeInputActive(!isFamilyAverageIncomeInputActive);
                  }}
                />
                <input
                  readOnly
                  type="text"
                  id="average-input"
                  placeholder="Family"
                  value={familyAverageIncome ? prettyNumbers(familyAverageIncome.name) : ''}
                  onClick={() => {
                    setIsFamilyAverageIncomeInputActive(true);
                    rotateArrow('average', true);
                  }}
                  onBlur={() => {
                    setIsFamilyAverageIncomeInputActive(false);
                    rotateArrow('average', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isFamilyAverageIncomeInputActive && efcs && (
                  <Autocomplete
                    isBudgetItems={true}
                    ref={childRef}
                    onclick={selectAverageFamilyIncome}
                    filteredItem={efcs}
                    classN="margin-top-mobile"
                  />
                )}
                <p>Student Aid Index (SAI) / EFC</p>
                {/* formerly 'Expected Family Contribution' */}
              </div>
              <div className="input-select" id="majorsField">
                <img
                  src={
                    recommendedCategory.includes('majors') &&
                    clickedOrangeInput !== 'any' &&
                    user?.majors?.length === 0 &&
                    previousRouteAfterUpdateBtn
                      ? arrowOrange
                      : arrow
                  }
                  alt="arrow"
                  id="major"
                  onClick={() => {
                    rotateArrow('major', !isMajorsInputActive);
                    setIsMajorsInputActive(!isMajorsInputActive);
                  }}
                />
                <input
                  ref={majorsRef}
                  type="text"
                  placeholder="Interest"
                  id="major-input"
                  className={
                    recommendedCategory.includes('majors') &&
                    clickedOrangeInput !== 'any' &&
                    user?.majors?.length === 0 &&
                    previousRouteAfterUpdateBtn
                      ? 'my-profile-input-orange'
                      : ''
                  }
                  value={searchMajorsValue}
                  onClick={() => {
                    setIsMajorsInputActive(true);
                    rotateArrow('major', true);
                    setClickedOrangeInput('any');
                  }}
                  onChange={({ target }) => setSearchMajorsValue(target.value)}
                  onInput={({ target }: { target: any }) => filterMajors(target.value)}
                  onBlur={() => {
                    setIsMajorsInputActive(false);
                    rotateArrow('major', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isMajorsInputActive && filteredMajors && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectMajors}
                    filteredItem={filteredMajors}
                    classN="margin-top-mobile"
                  />
                )}
                <p
                  className={
                    recommendedCategory.includes('majors') &&
                    clickedOrangeInput !== 'any' &&
                    user?.majors?.length === 0 &&
                    previousRouteAfterUpdateBtn
                      ? 'orange-text'
                      : ''
                  }
                >
                  Potential Major(s)
                </p>
              </div>
              <div className="input-select" id="incomeField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="income"
                  className={isFamilyIncomeInputActive ? 'look-up' : 'look-down'}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFamilyIncomeInputActive(!isFamilyIncomeInputActive);
                  }}
                />
                <input
                  id="incomeRange"
                  readOnly
                  type="text"
                  placeholder="Your Income Range"
                  value={familyIncome ? prettyNumbers(familyIncome.name) : ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsFamilyIncomeInputActive(true);
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    setIsFamilyIncomeInputActive(false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isFamilyIncomeInputActive && efcs && (
                  <Autocomplete
                    isBudgetItems={true}
                    ref={childRef}
                    onclick={selectFamilyIncome}
                    filteredItem={householdIncome}
                    classN="margin-top-mobile"
                  />
                )}
                <p>Household Income</p>
              </div>
              {selectedMajors.length > 0 && (
                <div className="selected-colleges-list" id="selectedMajorsField">
                  <div className="selected-colleges-list">
                    {selectedMajors.map((major) => (
                      <div key={major.id} className="selected-college">
                        <span>{major.name}</span>
                        <img
                          onClick={() => setSelectedMajors(selectedMajors.filter((m) => m.id !== major.id))}
                          src={cross}
                          alt="cross"
                        />
                      </div>
                    ))}
                  </div>
                </div>
              )}
              <div className="input-select" id="graduationField">
                <img
                  src={arrow}
                  alt="arrow"
                  id="grade"
                  onClick={() => {
                    rotateArrow('grade', !isGraduationYearInputActive);
                    setIsGraduationYearInputActive(!isGraduationYearInputActive);
                  }}
                />
                <input
                  readOnly={true}
                  type="text"
                  id="grade-input"
                  placeholder="Student’s Year of High School Graduation"
                  value={graduationYear || ''}
                  onClick={() => {
                    setIsGraduationYearInputActive(true);
                    rotateArrow('grade', true);
                  }}
                  onBlur={() => {
                    setIsGraduationYearInputActive(false);
                    rotateArrow('grade', false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isGraduationYearInputActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectYearOfGraduation}
                    filteredItem={yearsOfGraduation}
                    classN="margin-top-mobile"
                  />
                )}
                <p>Year of High School Graduation</p>
              </div>
              <div className="input-select" id="gpaField">
                <input
                  ref={gpaRef}
                  id="gpaChecked"
                  type="number"
                  step="0.01"
                  min="0"
                  max="5"
                  className={
                    recommendedCategory.includes('gpa') &&
                    clickedOrangeInput !== 'any' &&
                    user?.gpa === null &&
                    previousRouteAfterUpdateBtn
                      ? 'my-profile-input-orange'
                      : ''
                  }
                  placeholder="GPA"
                  value={gpa || ''}
                  onClick={() => setClickedOrangeInput('any')}
                  onChange={({ target }) => setGpa(+target.value)}
                  onKeyDown={(e) => {
                    if (e.key.includes('+') || e.key.includes('-')) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={({ target }) => {
                    if (+target.value > 5) {
                      setGpa(5);
                    } else if (+target.value < 0) {
                      setGpa(0);
                    } else {
                      setGpa(+(+target.value).toFixed(2));
                    }
                  }}
                />
                <p
                  className={
                    recommendedCategory.includes('gpa') &&
                    clickedOrangeInput !== 'any' &&
                    user?.gpa === null &&
                    previousRouteAfterUpdateBtn
                      ? 'orange-text'
                      : ''
                  }
                >
                  Unweighted GPA
                </p>
              </div>
              <div className="input-select" id="actField">
                <div className="act-select-container">
                  <p>Student took ACT</p>
                  <div>
                    <div className="choice-radio choice-radio-act">
                      <div>
                        <input
                          type="radio"
                          name="studentIsAct"
                          value="yes"
                          checked={!!act}
                          onChange={() => setAct(true)}
                        />
                        <p>Yes</p>
                      </div>

                      <div>
                        <input
                          type="radio"
                          name="studentIsAct"
                          value="no"
                          checked={!act}
                          onChange={() => setAct(false)}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>
                {act && (
                  <div className="my-profile-form-act">
                    <p
                      className={
                        recommendedCategory.includes('act') &&
                        clickedOrangeInput !== 'any' &&
                        user?.act === null &&
                        previousRouteAfterUpdateBtn
                          ? 'orange-text act-score'
                          : 'act-score'
                      }
                    >
                      Student’s ACT Score
                    </p>
                    <input
                      ref={actRef}
                      type="number"
                      step="0.5"
                      min="1"
                      max="36"
                      className={
                        recommendedCategory.includes('act') &&
                        clickedOrangeInput !== 'any' &&
                        user?.act === null &&
                        previousRouteAfterUpdateBtn
                          ? 'my-profile-input-orange'
                          : ''
                      }
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="ACT Score"
                      value={actValue || ''}
                      onClick={() => setClickedOrangeInput('any')}
                      onKeyDown={(e) => {
                        if (e.key.includes('+') || e.key.includes('-')) {
                          e.preventDefault();
                        }
                      }}
                      onChange={({ target }) => setActValue(+target.value)}
                      onBlur={({ target }) => {
                        if (+target.value > 36) {
                          setActValue(36);
                        } else if (+target.value < 1) {
                          setActValue(1);
                        } else {
                          setActValue(+(+target.value).toFixed(2));
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="input-select" id="satField">
                <div className="sat-select-container">
                  <p>Student took SAT</p>
                  <div>
                    <div className="choice-radio choice-radio-sat">
                      <div>
                        <input
                          type="radio"
                          name="studentIsSat"
                          value="yes"
                          checked={!!sat}
                          onChange={() => setSat(true)}
                        />
                        <p>Yes</p>
                      </div>
                      <div>
                        <input
                          type="radio"
                          name="studentIsSat"
                          value="no"
                          checked={!sat}
                          onChange={() => setSat(false)}
                        />
                        <p>No</p>
                      </div>
                    </div>
                  </div>
                </div>
                {sat && (
                  <div className="my-profile-form-sat">
                    <p
                      className={
                        recommendedCategory.includes('sat') &&
                        clickedOrangeInput !== 'any' &&
                        user?.sat === null &&
                        previousRouteAfterUpdateBtn
                          ? 'orange-text'
                          : ''
                      }
                    >
                      Student’s SAT Score
                    </p>
                    <input
                      ref={satRef}
                      type="number"
                      step="50"
                      min="400"
                      max="1600"
                      onWheel={(e) => e.currentTarget.blur()}
                      className={
                        recommendedCategory.includes('sat') &&
                        clickedOrangeInput !== 'any' &&
                        user?.sat === null &&
                        previousRouteAfterUpdateBtn
                          ? 'my-profile-input-orange'
                          : ''
                      }
                      placeholder="SAT Score"
                      value={satValue || ''}
                      onClick={() => setClickedOrangeInput('any')}
                      onKeyDown={(e) => {
                        if (e.key.includes('+') || e.key.includes('-')) {
                          e.preventDefault();
                        }
                      }}
                      onChange={({ target }) => setSatValue(+target.value)}
                      onBlur={({ target }) => {
                        if (+target.value > 1600) {
                          setSatValue(1600);
                        } else if (+target.value < 400) {
                          setSatValue(400);
                        } else {
                          setSatValue(+(+target.value).toFixed(2));
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="input-select" id="athleteField">
                <div className="act-select-container">
                  <p className="student-athlete">Student Athlete</p>
                  <div className="choice-radio choice-radio-athlete">
                    <div>
                      <input
                        type="radio"
                        name="studentIsAthlete"
                        value="yes"
                        checked={!!athelete}
                        onChange={() => setAthelete(true)}
                      />
                      <p>Yes</p>
                    </div>
                    <div>
                      <input
                        type="radio"
                        name="studentIsAthlete"
                        value="no"
                        checked={!athelete}
                        onChange={() => setAthelete(false)}
                      />
                      <p>No</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="input-select" id="psatScoreField">
                <div className="act-select-container">
                  <p>PSAT Score</p>
                  <div>
                    <div>
                      <input
                        id="psatScoreInput"
                        type="number"
                        min="320"
                        max="1520"
                        step="20"
                        placeholder="PSAT Score"
                        className={
                          clickedOrangeInput !== 'any' && user?.psatScore === null ? 'my-profile-input-orange' : ''
                        }
                        value={psatScore || 0}
                        onChange={({ target }) => setPsatScore(+target.value)}
                        required={false}
                        onClick={() => setClickedOrangeInput('any')}
                        onKeyDown={(e) => {
                          if (e.key.includes('+') || e.key.includes('-')) {
                            e.preventDefault();
                          }
                        }}
                        onBlur={({ target }) => {
                          if (+target.value > 1520) {
                            setPsatScore(1520);
                          } else if (+target.value < 320) {
                            setPsatScore(320);
                          } else {
                            setPsatScore(+(+target.value).toFixed(2));
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="membership-plan">
            <p>Membership plan</p>
            <div className="membership-plan-input-wrapper">
              <input
                readOnly
                value={
                  user.subscription
                    ? user.subscription.package.name
                        .toUpperCase()
                        .replace(/YEARLY/, 'ANNUAL')
                        .replace(/BASIC/, 'ESSENTIAL')
                        .replace(/-/g, ' ')
                    : ''
                }
                type="text"
                placeholder="Plan Option"
              />
              <div className="buttons-wrapper">
                {user?.subscription &&
                  user.canceledSubscription === false &&
                  user?.subscription.type === 'card' &&
                  user.subscription.packageId % 2 === 1 && <NavLink to={'/buy-plan'}>Renew Plan</NavLink>}
                {user?.subscription && user.subscription.packageId % 2 === 0 && (
                  <>
                    <button className="cancel" onClick={unsubscribe}>
                      Cancel Subscription
                    </button>
                    <NavLink to={'/buy-plan'}>Renew Plan</NavLink>
                  </>
                )}

                {!user?.subscription && <NavLink to={'/buy-plan'}>Upgrade Plan</NavLink>}
              </div>
            </div>
          </div>
          {user.statusSubscription === 'canceled' && (
            <p className="canceled-subscription-message">
              Your subscription has been successfully canceled. Your account will revert to free status on{' '}
              {moment(user.expiredAtSubscription).format('dddd, MMMM Do, YYYY')}.
            </p>
          )}
          <div className="edit-button">
            <NavLink type="button" to={'/buy-plan'} className="my-profile-mobile-button-billing">
              Edit billing & payment
            </NavLink>
            <button className="my-profile-mobile-button" onClick={updateProfile} type="button">
              Save
            </button>
          </div>
        </div>
      </div>
      <Modal>
        {!isSubscription && (
          <ScholarshipModal
            close={close}
            message={'Your profile has been updated.'}
            title={'Congratulations!'}
            textButton={'Ok'}
            placeUsingThisModal={'/my-profile'}
          />
        )}
        {isSubscription && (
          <UnsubscribeModal
            close={close}
            plan={planOptions.find((plan) => plan.id === subscription?.packageId)}
            withConfirm={true}
          />
        )}
      </Modal>
    </>
  );
};
