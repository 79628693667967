import { EUserRoles } from '../enums';

export const getAuthStatus = () => {
  const auth = localStorage.getItem('auth');
  return auth;
};

export const getApiAccessToken = () => {
  const authStatus = getAuthStatus();
  return authStatus || '';
};

export const getGenders = () => {
  return [
    {
      id: 1,
      name: 'Male',
      value: 'male',
    },
    {
      id: 2,
      name: 'Female',
      value: 'female',
    },
  ];
};

export const getRoles = () => {
  const roles: { id: number; name: string; value: EUserRoles }[] = [
    {
      id: 1,
      name: 'Parent',
      value: EUserRoles.PARENT,
    },
    {
      id: 2,
      name: 'Student',
      value: EUserRoles.STUDENT,
    },
    {
      id: 3,
      name: 'Counselor',
      value: EUserRoles.PROFESSIONAL,
    },
  ];
  return roles;
};
