import { produce } from 'immer';

import { createReducer } from '.';
import { notesActionTypes } from '../../consts';
import { IAction, INote, INotesState } from '../../models';
import { notesState } from '../states/notes.state';

export const notesReducer = createReducer<INotesState>(notesState, {
  [notesActionTypes.SET_NOTE]: setNote,
  [notesActionTypes.SET_LIST_NOTES]: setListNotes,
});

function setNote(note: INotesState, { payload }: IAction<INote>) {
  return produce(note, (draft) => {
    draft.note = payload;
  });
}

function setListNotes(listNotes: INotesState, { payload }: IAction<Array<INote>>) {
  return produce(listNotes, (draft) => {
    draft.listNotes = payload;
  });
}
