import './YoutubeModal.css';

export const YoutubeModal = ({ close, videoUrl }: { close: any; videoUrl: string }) => {
  return (
    <div className="youtube-modal">
      <div className="youtube-modal-close" onClick={close} />

      <div className="youtube-modal-body">
        <iframe
          width="560"
          height="315"
          src={videoUrl}
          title="YouTube video player"
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          allowFullScreen
        />
      </div>
    </div>
  );
};
