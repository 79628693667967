import './MarketingPage.css';
import { NavLink } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import checkMark from '../../images/checkMark.svg';

interface Benefits {
  value: string;
  checkMarkFree: boolean;
  checkMarkPremium: boolean;
  additionalValue?: string;
  additionalValuePremium?: string;
}

const listBenefits: Benefits[] = [
  {
    value: 'Receive personalized college recommendations',
    checkMarkFree: true,
    checkMarkPremium: true,
  },
  {
    value: 'Search for any college',
    checkMarkFree: true,
    checkMarkPremium: true,
  },
  {
    value: 'Stay on track with a timeline of college planning tasks',
    checkMarkFree: true,
    checkMarkPremium: true,
  },
  {
    value: 'Use on mobile, tablet or desktop devices',
    checkMarkFree: true,
    checkMarkPremium: true,
  },
  {
    value: 'View campus videos',
    checkMarkFree: true,
    checkMarkPremium: true,
  },
  {
    value: 'Advanced search by college cost, GPA, test scores, deadlines, major and more',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: 'Limited',
  },
  {
    value: 'Sort college search results by multiple variables',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: 'Limited',
  },
  {
    value: 'Build unlimited college lists',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: 'Limited',
  },
  {
    value: 'Compare school profiles, admissions, financial, and deadline information',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: 'Limited',
  },
  {
    value: 'Add and save notes to any college or list',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: '--',
  },
  {
    value: 'Compare crowdsourced merit scholarship offers from other students',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: '--',
  },
  {
    value: 'Find schools with full ride and full tuition scholarships',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: '--',
  },
  {
    value: 'Share lists with anyone',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: '--',
  },
  {
    value: 'Download a csv list',
    checkMarkFree: false,
    checkMarkPremium: true,
    additionalValue: '--',
  },
];
export const MarketingPage = () => {
  const { user } = useSelector((state: any) => state.userState);
  const { loginWithRedirect } = useAuth0();

  return (
    <section className="marketing-page">
      <div className="marketing-page-container">
        <h4 className="marketing-page-title">Flexible plans for the college journey</h4>
        <table className="marketing-page-table">
          <tr className="marketing-page-tr">
            {/* eslint-disable-next-line jsx-a11y/control-has-associated-label */}
            <th className="marketing-page-th" />
            <th className="marketing-page-th">Free</th>
            <th className="marketing-page-th">Paid</th>
          </tr>
          {listBenefits.map((benefit) => (
            <tr className="marketing-page-tr" key={benefit.value}>
              <td className="marketing-page-td marketing-page-tr-value">{benefit.value}</td>
              <td
                className={
                  benefit.additionalValue === '--'
                    ? 'marketing-page-td marketing-page-td-text-line'
                    : !benefit.checkMarkFree
                    ? 'marketing-page-td marketing-page-td-text'
                    : 'marketing-page-td marketing-page-td-img'
                }
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {benefit.checkMarkFree ? <img src={checkMark} alt="check mark" /> : benefit.additionalValue}
                </div>
              </td>
              <td
                className={!benefit.checkMarkPremium ? 'marketing-page-td marketing-page-td-text' : 'marketing-page-td'}
              >
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  {benefit.checkMarkPremium ? <img src={checkMark} alt="check mark" /> : benefit.additionalValuePremium}
                </div>
              </td>
            </tr>
          ))}
        </table>
        <NavLink
          to={user ? '/buy-plan' : ''}
          className="marketing-page-upgrade-btn"
          onClick={() => {
            if (user) {
              return;
            }
            loginWithRedirect({ screen_hint: 'signup' });
          }}
        >
          {user ? ' Upgrade' : 'Register Now'}
        </NavLink>
      </div>
    </section>
  );
};
