import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';

export const petersonService = (function () {
  const endpoint_get_all_petersons_links = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_petersons_links());
  };

  const endpoint_download_petersons_file = async (fileName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.get(apiUrls.download_peterson_file(fileName));
  };

  const endpoint_pull_petersons_data = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.post(apiUrls.pull_petersons_data());
  };

  return {
    endpoint_get_all_petersons_links,
    endpoint_download_petersons_file,
    endpoint_pull_petersons_data,
  };
})();
