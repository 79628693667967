import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ECollegesActionTypes } from '../../enums';
import { IAdvancedSearch, ICollege, IUpdatedRecommendedSearch } from '../../models';
import { collegeService } from '../../services';
import { loadingAction, collegesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES as any, watchGetColleges)]);
  yield all([takeLatest(ECollegesActionTypes.UPDATE_COLLEGE as any, watchUpdateCollege)]);
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES_BY_SIZE as any, watchGetCollegesBySize)]);
  yield all([takeLatest(ECollegesActionTypes.GET_PRIVATE_COLLEGES as any, watchGetPrivateColleges)]);
  yield all([takeLatest(ECollegesActionTypes.GET_PUBLIC_COLLEGES as any, watchGetPublicColleges)]);
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES_NAMES as any, watchGetCollegesNames)]);
  yield all([takeLatest(ECollegesActionTypes.GET_NEAREST_COLLEGES as any, watchGetNearestCollege)]);
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGE_BY_ID as any, watchGetCollegeById)]);
  yield all([takeLatest(ECollegesActionTypes.GET_FULL_COLLEGE_BY_ID as any, watchGetFullCollegeById)]);
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES_ADVANCED_SEARCH as any, watchGetCollegesAdvancedSearch)]);
  yield all([takeLatest(ECollegesActionTypes.GET_RECOMMENDED_COLLEGES as any, watchGetRecommendedColleges)]);
  yield all([
    takeLatest(ECollegesActionTypes.GET_RECOMMENDED_COLLEGES_BY_CATEGORY as any, watchGetRecommendedCollegesByCategory),
  ]);
  yield all([takeLatest(ECollegesActionTypes.GET_COLLEGES_FOR_LANDING_PAGE as any, watchGetCollegesForLandingPage)]);
  yield all([
    takeLatest(
      ECollegesActionTypes.GET_UPDATED_RECOMMENDED_COLLEGES_BY_CATEGORY as any,
      watchGetUpdatedRecommendedCollegesByCategory,
    ),
  ]);
}

function* watchGetColleges() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: true,
      }),
    );

    const { data } = yield call(collegeService.endpoint_get_colleges);

    yield put(collegesAction.setColleges(data as Array<ICollege>));

    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetColleges: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getCollegesLoading: false,
      }),
    );
  }
}

function* watchGetCollegesBySize() {
  try {
    const { data } = yield call(collegeService.endpoint_get_colleges_by_size);

    yield put(collegesAction.setTrendingColleges(data as Array<ICollege>));
  } catch (error: any) {
    console.error('watchGetCollegesBySize: ', error.response);
  }
}

function* watchGetPrivateColleges() {
  try {
    const { data } = yield call(collegeService.endpoint_get_private_colleges);

    yield put(collegesAction.setTrendingColleges(data as Array<ICollege>));
  } catch (error: any) {
    console.error('watchGetPrivateColleges: ', error.response);
  }
}

function* watchGetPublicColleges() {
  try {
    const { data } = yield call(collegeService.endpoint_get_public_colleges);

    yield put(collegesAction.setTrendingColleges(data as Array<ICollege>));
  } catch (error: any) {
    console.error('watchGetPublicColleges: ', error.response);
  }
}

function* watchGetCollegesNames() {
  try {
    const { data } = yield call(collegeService.endpoint_get_colleges_names);

    yield put(collegesAction.setCollegesNames(data as Array<Partial<ICollege>>));
  } catch (error: any) {
    console.error('watchGetCollegesNames: ', error.response);
  }
}

function* watchGetNearestCollege(action: { type: string; payload: string }) {
  try {
    const collegeState = action.payload;

    const { data } = yield call(collegeService.endpoint_get_nearest_college, collegeState);

    yield put(collegesAction.setTrendingColleges(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetNearestCollege: ', error.response);
  }
}

function* watchGetCollegesForLandingPage(action: { type: string; payload: string }) {
  try {
    const collegeState = action.payload;

    const { data } = yield call(collegeService.endpoint_get_colleges_for_landing_page, collegeState);

    yield put(collegesAction.setTrendingColleges(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetCollegesForLandingPage: ', error.response);
  }
}

function* watchGetCollegeById(action: { type: string; payload: number }) {
  try {
    const collegeId = action.payload;

    const { data } = yield call(collegeService.endpoint_get_college_by_id, collegeId);

    yield put(collegesAction.setCollegeById(data as ICollege));
  } catch (error: any) {
    console.error('watchGetCollegeById: ', error.response);
  }
}

function* watchGetFullCollegeById(action: { type: string; payload: { collegeId: number; userId: number } }) {
  try {
    const collegeId = action.payload.collegeId;
    const userId = action.payload.userId;

    const { data } = yield call(collegeService.endpoint_get_full_college_by_id, collegeId, userId);

    yield put(collegesAction.setCollegeById(data as ICollege));
  } catch (error: any) {
    console.error('watchGetFullCollegeById: ', error.response);
  }
}

function* watchUpdateCollege(action: {
  type: string;
  payload: {
    college: ICollege;
    next: Function;
  };
}) {
  try {
    const { college, next } = action.payload;

    yield put(
      loadingAction.updateLoadingStatus({
        collegeUpdatingFlag: true,
      }),
    );

    const { data } = yield call(collegeService.endpoint_update_college, {
      collegeId: college.id,
      data: college,
    });

    yield put(collegesAction.setCollege(data as ICollege));

    next();
  } catch (error: any) {
    console.error('watchUpdateCollege: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        collegeUpdatingFlag: false,
      }),
    );
  }
}

function* watchGetCollegesAdvancedSearch(action: { type: string; payload: IAdvancedSearch }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getPowerSearchResultLoading: true,
      }),
    );

    const { data } = yield call(collegeService.endpoint_post_college_advanced_search, action.payload);

    yield put(collegesAction.setCollegesAdvancedSearch(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetCollegesAdvancedSearch: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        getPowerSearchResultLoading: false,
      }),
    );
  }
}

function* watchGetRecommendedColleges(action: { type: string; payload: { userId: number; category: string } }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    const { userId, category } = action.payload;

    const { data } = yield call(collegeService.endpoint_get_recommended_colleges, { userId, category });

    yield put(collegesAction.setRecommendedColleges(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetRecommendedColleges: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchGetRecommendedCollegesByCategory(action: {
  type: string;
  payload: { userId: number; category: string };
}) {
  try {
    const { userId, category } = action.payload;

    const { data } = yield call(collegeService.endpoint_get_recommended_colleges_by_category, {
      userId,
      category,
    });

    yield put(collegesAction.setRecommendedColleges(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetRecommendedCollegesByCategory: ', error.response);
  }
}

function* watchGetUpdatedRecommendedCollegesByCategory(action: { type: string; payload: IUpdatedRecommendedSearch }) {
  try {
    const { data } = yield call(collegeService.endpoint_post_updated_recommended_colleges_by_category, action.payload);

    yield put(collegesAction.setUpdatedRecommendedColleges(data as ICollege[]));
  } catch (error: any) {
    console.error('watchGetUpdatedRecommendedCollegesByCategory: ', error.response);
  }
}
