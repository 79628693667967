import './Admin-backups.css';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import arrowLeftAdmin from '../../images/arrowLeftAdmin.svg';
import arrowBackBlue from '../../images/arrow-back-blue.svg';

import { listBackupsAction, backupAction } from '../../store/actions';
import { axiosService } from '../../services';

export const AdminBackups = () => {
  const { listBackups } = useSelector((state: any) => state.listBackupsState);
  const { createBackupLoading, restoreBackupLoading } = useSelector((state: any) => state.loadingState);
  const [listWithBackups, setListWithBackups] = useState([]);
  const [statusBackup, setStatusBackup] = useState('');
  const [isLoaderRestored, setIsLoaderRestored] = useState(false);
  const [isLoaderCreated, setIsLoaderCreated] = useState(false);

  const dispatch = useDispatch();

  const restoreBackup = (backupName: string) => {
    dispatch(backupAction.restoreBackupByName(backupName));
    setIsLoaderRestored(true);
  };
  const deleteBackup = (backupName: string) => {
    dispatch(backupAction.deleteBackupByName(backupName));
    dispatch(listBackupsAction.setListBackups(listWithBackups));
    setStatusBackup('deleted');
  };
  const createBackup = () => {
    dispatch(backupAction.createBackup());
    setIsLoaderCreated(true);
  };
  const downloadBackup = async (backupName: string) => {
    await axiosService.get(`${process.env.REACT_APP_API_BASE_URL}/backup/upload`).then((response) => response.data);
    window.location.href = `${process.env.REACT_APP_API_BASE_URL}/backup/${backupName}.zip`;
    setStatusBackup('downloaded');
  };
  useEffect(() => {
    dispatch(listBackupsAction.getListBackups());
  }, []);

  useEffect(() => {
    if (listBackups.length !== listWithBackups.length) {
      setListWithBackups(listBackups);
    }
  }, [listBackups]);

  useEffect(() => {
    if (statusBackup !== '') {
      setTimeout(() => setStatusBackup('false'), 1000);
    }
  }, [statusBackup]);

  useEffect(() => {
    if (!createBackupLoading && isLoaderCreated) {
      setIsLoaderCreated(false);
      setStatusBackup('created');
    }
  }, [createBackupLoading]);

  useEffect(() => {
    if (!restoreBackupLoading && isLoaderRestored) {
      setIsLoaderRestored(false);
      setStatusBackup('restored');
    }
  }, [restoreBackupLoading]);

  return (
    <div className="admin-backups">
      <NavLink to={'/dashboard'} className="admin-backups-nav-dashboard">
        <img src={arrowBackBlue} alt="arrow back blue" />
        <p>Back to admin dashboard</p>
      </NavLink>
      <div className="admin-backups-main">
        <div className="admin-backups-cont-title">
          <img className="admin-backups-arrow-left" src={arrowLeftAdmin} alt="arrow left admin" />
          <img
            className="admin-backups-arrow-left admin-backups-arrow-left-second"
            src={arrowLeftAdmin}
            alt="arrow left admin"
          />
          <h5 className="admin-backups-main-title">Backups</h5>
        </div>
        <button
          className={
            isLoaderCreated
              ? 'admin-backups-main-button admin-backups-main-button-disabled'
              : 'admin-backups-main-button'
          }
          onClick={createBackup}
        >
          CREATE NEW PERMANENT BACKUP
        </button>
        {isLoaderCreated && (
          <div className="admin-backups-main-loader-cont-total">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
            <div className="admin-backups-main-loader-cont">
              <h6 className="admin-backups-main-loader-title">Please wait, the backup is being created</h6>
              <div className="snippet" data-title="dot-collision">
                <div className="stage">
                  <div className="dot-collision" />
                </div>
              </div>
            </div>
          </div>
        )}
        {isLoaderRestored && (
          <div className="admin-backups-main-loader-cont-total">
            <div className="lds-ring">
              <div />
              <div />
              <div />
              <div />
            </div>
            <div className="admin-backups-main-loader-cont">
              <h6 className="admin-backups-main-loader-title">Please wait, the backup is being restored</h6>
              <div className="snippet" data-title="dot-collision">
                <div className="stage">
                  <div className="dot-collision" />
                </div>
              </div>
            </div>
          </div>
        )}
        {statusBackup === 'restored' && (
          <div className="admin-backups-main-status-backup">Backup restored successfully</div>
        )}
        {statusBackup === 'created' && (
          <div className="admin-backups-main-status-backup">Backup created successfully</div>
        )}
        {statusBackup === 'deleted' && (
          <div className="admin-backups-main-status-backup admin-backups-main-status-backup-deleted">
            Backup deleted successfully
          </div>
        )}
        {statusBackup === 'downloaded' && (
          <div className="admin-backups-main-status-backup admin-backups-main-status-backup-downloaded">
            Backup downloaded successfully
          </div>
        )}
        {listWithBackups.length > 0 && (
          <ul className="admin-list-backups">
            {listWithBackups.length &&
              listWithBackups
                .filter((bac: string) => !bac.endsWith('.zip'))
                ?.map((backup) => (
                  <li className={'admin-item-backups'} key={backup}>
                    <p className="admin-item-backups-name">{`backups/${backup}`}</p>
                    <div className="admin-item-backups-cont-buttons">
                      <button
                        className={
                          isLoaderRestored
                            ? 'admin-item-backups-button admin-item-backups-button-disabled'
                            : 'admin-item-backups-button'
                        }
                        onClick={() => restoreBackup(backup)}
                      >
                        RESTORE
                      </button>
                      <button
                        className="admin-item-backups-button admin-item-backups-button-delete"
                        onClick={() => deleteBackup(backup)}
                      >
                        DELETE
                      </button>
                      <button
                        className="admin-item-backups-button admin-item-backups-button-download"
                        onClick={() => downloadBackup(backup)}
                      >
                        DOWNLOAD
                      </button>
                    </div>
                  </li>
                ))}
          </ul>
        )}
      </div>
    </div>
  );
};
