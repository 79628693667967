import './Select-btn.css';

export const SelectBtn = ({ filteredItem, onclick, recommendationCategory }) => {
  return (
    <div id="select-btn" className="select-btn-list">
      {filteredItem.length === 0 ? (
        <div>No matches..</div>
      ) : (
        filteredItem.map((item: any) => (
          <div
            className={recommendationCategory.value === item.value ? 'select-btn selected' : 'select-btn'}
            onClick={(e) => {
              e.stopPropagation();
              localStorage.setItem('recommendationCategory', item);
              onclick(item);
              // setSelectedIdx(i);
            }}
            key={item.id + item.name}
          >
            {item.name}
          </div>
        ))
      )}
    </div>
  );
};
