import React from 'react';
import { btnStyles, modalStyles } from '../social-medias/SocialMedias';

interface IMetadataTwitter {
  url: string;
  text: string;
  hashtags: string;
  imageUrl: string;
}

type Props = {
  onHandleClickShareList: () => Promise<void>;
  onHandleOpenedSocialMedia: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleOpenModalWithZeroSharingColleges: () => boolean;
};

export const TwitterButton = ({
  onHandleClickShareList,
  onHandleOpenedSocialMedia,
  onHandleOpenModalWithZeroSharingColleges,
}: Props) => {
  const metadataTwitter: IMetadataTwitter = {
    url: `${window.location.href.replace('my-lists', 'shared-lists')}`,
    text: 'Check out my College List!',
    hashtags: 'road2college,college',
    imageUrl:
      'https://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg',
  };

  const handleButtonClick = () => {
    const needOpenModal = onHandleOpenModalWithZeroSharingColleges();
    if (needOpenModal) return;

    const twitterUrl = `https://twitter.com/intent/tweet?url=${encodeURIComponent(
      metadataTwitter.url,
    )}&text=${encodeURIComponent(metadataTwitter.text)}&hashtags=${encodeURIComponent(
      metadataTwitter.hashtags,
    )}&amp;media=${encodeURIComponent(metadataTwitter.imageUrl)}`;

    window.open(
      twitterUrl,
      'Share on Twitter',
      `width=${modalStyles.modalWidth},height=${modalStyles.modalHeight},left=${modalStyles.left},top=${modalStyles.top}`,
    );
    onHandleClickShareList();
    onHandleOpenedSocialMedia(false);
  };

  return (
    <button
      onClick={handleButtonClick}
      className="social-button"
      style={{ width: btnStyles.size, height: btnStyles.size }}
    >
      <span className="social-button__inside twitter" />
    </button>
  );
};
