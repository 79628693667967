import './CollegeDetailsAdmissions.css';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import { SignUpModal } from '../signup-modal/SignUpModal';
import lock from '../../images/icon-locked2.svg';
import lockWhite from '../../images/locked-icon-white.svg';

export const CollegeDetailsAdmissions = ({ clickUnlock, openModal }: { clickUnlock: any; openModal: any }) => {
  const { selectedCollege } = useSelector((state: any) => state.collegesState);
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { isAuthenticated } = useAuth0();
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [isMobileScreen, setIsMobileScreen] = useState(false);

  const formatDate = (str: string) => {
    if (!str) return '-';
    return moment(str, 'YYYY-MM-DD').format('MMM DD');
  };
  useEffect(() => {
    if (window.innerWidth <= 520) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <section className="colleges-details-wrapper-container-under">
      <div className="colleges-details-wrapper">
        {(user?.subscription && !adminNavigatedUser) ||
        (adminNavigatedUser && adminNavigatedUser?.subscription) ||
        user.role === 'admin' ? (
          <>
            <div className="college-details-section">
              <h6>Admission Stats</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Avg HS GPA </p>
                    <p className="unlocked-field">4.0 GPA </p>
                    <p className="unlocked-field">3.75 - 3.99 GPA </p>
                    <p className="unlocked-field">3.5 - 3.74 GPA </p>
                    <p className="unlocked-field">3.0 - 3.49 GPA</p>
                    <p className="unlocked-field">Below 3.0 GPA :</p>
                  </div>
                  <div className="admission-res-block">
                    <span>{selectedCollege?.avgFreshmenGpa ? +selectedCollege.avgFreshmenGpa : '-'}</span>
                    <span>
                      {selectedCollege?.avgFreshmenGpa1P ? `${Math.ceil(+selectedCollege.avgFreshmenGpa1P)} %` : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgFreshmenGpa2P ? `${Math.ceil(+selectedCollege.avgFreshmenGpa2P)} %` : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgFreshmenGpa3P ? `${Math.ceil(+selectedCollege.avgFreshmenGpa3P)} %` : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgFreshmenGpa4P ? `${+Math.ceil(selectedCollege.avgFreshmenGpa4P)} %` : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgFreshmenGpaBelow3P
                        ? `${Math.ceil(+selectedCollege.avgFreshmenGpaBelow3P)} %`
                        : '-'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">SAT/ ACT Required</p>
                    <p className="unlocked-field">Avg SAT</p>
                    <p className="unlocked-field">SAT 75th Percentile</p>
                    <p className="unlocked-field">Avg ACT</p>
                    <p className="unlocked-field">ACT 75th Percentile</p>
                  </div>
                  <div className="race-res-block">
                    <span>
                      {selectedCollege?.testScoreRequirementType ? selectedCollege.testScoreRequirementType : '-'}
                    </span>
                    <span>{selectedCollege?.satAvg ? +selectedCollege.satAvg : '-'}</span>
                    <span>{selectedCollege?.sat75thPc ? +selectedCollege.sat75thPc : '-'}</span>
                    <span>{selectedCollege?.actAvg ? (+selectedCollege.actAvg).toLocaleString('en-US') : '-'}</span>
                    <span>
                      {selectedCollege?.act75thPc ? (+selectedCollege.act75thPc).toLocaleString('en-US') : '-'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Overall Admit Rate</p>
                    <p className="unlocked-field">ED Admit Rate</p>
                    <p className="unlocked-field">EA Admit Rate</p>
                    <p className="unlocked-field">RD Admit Rate</p>
                    <p className="unlocked-field">Percent of Class Filled ED</p>
                    <p className="unlocked-field">Admit Yield</p>
                    <p className="unlocked-field">App Fee</p>
                  </div>
                  <div className="admission-res-block">
                    <span>
                      {selectedCollege?.admissionsRate ? `${(+selectedCollege.admissionsRate).toFixed(0)} %` : '-'}
                    </span>
                    <span>{selectedCollege?.edAdmitRate ? `${(+selectedCollege.edAdmitRate).toFixed(0)} %` : '-'}</span>
                    <span>{selectedCollege?.eaAdmitRate ? `${(+selectedCollege.eaAdmitRate).toFixed(0)} %` : '-'}</span>
                    <span>{selectedCollege?.rdAdmitRate ? `${(+selectedCollege.rdAdmitRate).toFixed(0)} %` : '-'}</span>
                    <span>
                      {selectedCollege?.edFilledRate ? `${(+selectedCollege.edFilledRate).toFixed(0)} %` : '-'}
                    </span>
                    <span>{selectedCollege?.admitYield ? `${(+selectedCollege.admitYield).toFixed(0)} %` : '-'}</span>
                    <span>
                      {selectedCollege?.applicationFee
                        ? `$ ${(+selectedCollege.applicationFee).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="college-details-section">
              <h6>Deadlines</h6>
              <hr className="section-hr-line" />
              <div className="race-info-block">
                <div>
                  <p className="unlocked-field">Early Decision Deadline</p>
                  <p className="unlocked-field">Early Action Deadline</p>
                  <p className="unlocked-field">Early Decision 2 Deadline</p>
                  <p className="unlocked-field">Regular Decision Deadline</p>
                  <p className="unlocked-field">Financial Aid Deadline</p>
                </div>
                <div className="race-res-block">
                  <span>{formatDate(selectedCollege?.edDeadline)}</span>
                  <span>{formatDate(selectedCollege?.eaDeadline)}</span>
                  <span>{formatDate(selectedCollege?.ed2Deadline)}</span>
                  <span>{formatDate(selectedCollege?.regDecisionDeadline)}</span>
                  <span>{formatDate(selectedCollege?.finAidDeadline)}</span>
                </div>
              </div>
            </div>
            <div className="college-details-section-link-faqs">
              <a target="_blank" rel="noreferrer" href="https://www.road2college.com/college-insights-faqs/">
                **College Data sources{' '}
              </a>
            </div>
          </>
        ) : (
          <>
            <div className="college-details-section">
              <h6>Admission Stats</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Average HS GPA </p>
                    <p className="unlocked-field">SAT/ ACT Required </p>
                    <p className="unlocked-field">Average SAT </p>
                    <p className="unlocked-field">Average ACT </p>
                    <p className="unlocked-field">Application Fee</p>
                  </div>
                  <div className="race-res-block">
                    <span>
                      {selectedCollege?.avgFreshmenGpa
                        ? (+selectedCollege.avgFreshmenGpa).toLocaleString('en-US')
                        : '-'}
                    </span>
                    <span>
                      {selectedCollege?.testScoreRequirementType ? selectedCollege.testScoreRequirementType : '-'}
                    </span>
                    <span>{selectedCollege?.satAvg ? +selectedCollege.satAvg : '-'}</span>
                    <span>{selectedCollege?.actAvg ? (+selectedCollege.actAvg).toLocaleString('en-US') : '-'}</span>
                    <span>
                      {selectedCollege?.applicationFee
                        ? `$ ${(+selectedCollege.applicationFee).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                  </div>
                </div>
                <hr />
                <div className="college-details-section-locked-admission">
                  <div>
                    <p className="unlocked-field">Below 3.0 GPA</p>
                    <p className="unlocked-field">4.0 GPA </p>
                    <p className="unlocked-field">3.75 - 3.99 GPA </p>
                    <p className="unlocked-field">3.5 - 3.74 GPA </p>
                    <p className="unlocked-field">3.0 - 3.49 GPA </p>
                    <p className="unlocked-field">SAT 75th Percentile </p>
                    <p className="unlocked-field">ACT 75th Percentile </p>
                    <p className="unlocked-field">Overall Admit Rate </p>
                    <p className="unlocked-field">ED Admit Rate </p>
                    <p className="unlocked-field">EA Admit Rate </p>
                    <p className="unlocked-field">RD Admit Rate </p>
                    <p className="unlocked-field">Percent of Class Filled ED </p>
                    <p className="unlocked-field">Admit Yield</p>
                  </div>
                  <div className="race-res-block race-res-block-admission">
                    <span>N/A</span>
                    <span>N/A</span>
                    <span>N/A </span>
                    <span>N/A</span>
                    <span>N/A</span>
                    <span>N/A</span>
                    <span>N/A</span>
                    <span>N/A</span>
                    <span>N/A%</span>
                    <span>N/A%</span>
                    <span>N/A%</span>
                    <span>N/A%</span>
                    <span>N/A%</span>
                  </div>
                  <div className="upgrade-profile-message-admissions">
                    <img src={isMobileScreen ? lockWhite : lock} alt="locked" />
                    <p>Information locked</p>
                    <span>Upgrade Profile to see all this Information!</span>
                    <NavLink
                      to={
                        (isAuthenticated && user.subscription && !adminNavigatedUser) ||
                        (adminNavigatedUser && adminNavigatedUser.subscription && isAuthenticated) ||
                        user.role === 'admin'
                          ? '/buy-plan'
                          : ''
                      }
                      onClick={() => {
                        if (
                          (isAuthenticated && !adminNavigatedUser && user.subscription) ||
                          (isAuthenticated && adminNavigatedUser && adminNavigatedUser.subscription) ||
                          user.role === 'admin'
                        ) {
                          return;
                        }

                        if (
                          (isAuthenticated && !adminNavigatedUser && !user.subscription && user.role !== 'admin') ||
                          (isAuthenticated && adminNavigatedUser && !adminNavigatedUser.subscription)
                        ) {
                          clickUnlock('unlock-modal');
                          openModal();
                          return;
                        }

                        open();
                      }}
                    >
                      Unlock
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
            <div className="college-details-section">
              <h6>Deadlines</h6>
              <hr className="section-hr-line" />
              <div className="race-info-block">
                <div>
                  <p className="unlocked-field">Early Decision Deadline </p>
                  <p className="unlocked-field">Early Action Deadline </p>
                  <p className="unlocked-field">Early Decision 2 Deadline </p>
                  <p className="unlocked-field">Regular Decision Deadline </p>
                  <p className="unlocked-field">Financial Aid Deadline </p>
                </div>
                <div className="race-res-block">
                  <span>{formatDate(selectedCollege?.edDeadline)}</span>
                  <span>{formatDate(selectedCollege?.eaDeadline)}</span>
                  <span>{formatDate(selectedCollege?.ed2Deadline)}</span>
                  <span>{formatDate(selectedCollege?.regDecisionDeadline)}</span>
                  <span>{formatDate(selectedCollege?.finAidDeadline)}</span>
                </div>
              </div>
            </div>
            <div className="college-details-section-link-faqs">
              <a target="_blank" rel="noreferrer" href="https://www.road2college.com/college-insights-faqs/">
                **College Data sources{' '}
              </a>
            </div>
            <Modal>
              <SignUpModal close={close} message={'Find More Schools and Start Building a College List'} />
            </Modal>
          </>
        )}
      </div>
    </section>
  );
};
