import React from 'react';
import { NavLink } from 'react-router-dom';
import styles from './LockedResultsModal.module.css';
import lockIcon from '../../../../../../images/locked-icon-blue.svg';

export const LockedResultsModal: React.FC = () => {
  return (
    <div className={styles.container}>
      <img src={lockIcon} alt="Lock" className={styles.lockIcon} />
      <p className={styles.subtitle}>Information Locked</p>
      <h5 className={styles.title}>Your Search Has More Results!</h5>
      <p className={styles.text}>Upgrade your plan and see the rest of the schools that match your search criteria.</p>
      <NavLink to={'/buy-plan'} className={styles.navButton}>
        Unlock
      </NavLink>
    </div>
  );
};
