export enum EBasicActionTypes {
  INIT_STORE = 'INIT_STORE',
}

export enum ECollegesActionTypes {
  GET_COLLEGES = 'GET_COLLEGES',
  GET_NEAREST_COLLEGES = 'GET_NEAREST_COLLEGES',
  GET_COLLEGES_BY_SIZE = 'GET_COLLEGES_BY_SIZE',
  GET_PRIVATE_COLLEGES = 'GET_PRIVATE_COLLEGES',
  GET_PUBLIC_COLLEGES = 'GET_PUBLIC_COLLEGES',
  GET_COLLEGE_BY_ID = 'GET_COLLEGE_BY_ID',
  GET_FULL_COLLEGE_BY_ID = 'GET_FULL_COLLEGE_BY_ID',
  SET_COLLEGE_BY_ID = 'SET_COLLEGE_BY_ID',
  SET_COLLEGES = 'SET_COLLEGES',
  SET_TRENDING_COLLEGES = 'SET_TRENDING_COLLEGES',
  GET_COLLEGES_NAMES = 'GET_COLLEGES_NAMES',
  SET_COLLEGES_NAMES = 'SET_COLLEGES_NAMES',
  SET_COLLEGE = 'SET_COLLEGE',
  SET_SELECTED_COLLEGE_ID = 'SET_SELECTED_COLLEGE_ID',
  UPDATE_COLLEGE = 'UPDATE_COLLEGE',
  GET_COLLEGES_ADVANCED_SEARCH = 'GET_COLLEGES_ADVANCED_SEARCH',
  SET_COLLEGES_ADVANCED_SEARCH = 'SET_COLLEGES_ADVANCED_SEARCH',
  GET_RECOMMENDED_COLLEGES = 'GET_RECOMMENDED_COLLEGES',
  SET_RECOMMENDED_COLLEGES = 'SET_RECOMMENDED_COLLEGES',
  GET_RECOMMENDED_COLLEGES_BY_CATEGORY = 'GET_RECOMMENDED_COLLEGES_BY_CATEGORY',
  GET_COLLEGES_FOR_LANDING_PAGE = 'GET_COLLEGES_FOR_LANDING_PAGE',
  SET_UPDATED_RECOMMENDED_COLLEGES = 'SET_UPDATED_RECOMMENDED_COLLEGES',
  GET_UPDATED_RECOMMENDED_COLLEGES_BY_CATEGORY = 'GET_UPDATED_RECOMMENDED_COLLEGES_BY_CATEGORY',
}

export enum EfcsActionTypes {
  GET_EFCS = 'GET_EFCS',
  SET_EFCS = 'SET_EFCS',
}

export enum EHouseHoldIncomeActionTypes {
  GET_HOUSEHOLDINCOME = 'GET_HOUSEHOLDINCOME',
  SET_HOUSEHOLDINCOME = 'SET_HOUSEHOLDINCOME',
}

export enum EHighschoolsActionTypes {
  GET_HIGHSCHOOLS = 'GET_HIGHSCHOOLS',
  SET_HIGHSCHOOLS = 'SET_HIGHSCHOOLS',
}

export enum ELoadingActionTypes {
  UPDATE_LOADING_STATUS = 'UPDATE_LOADING_STATUS',
}

export enum EOffersActionTypes {
  GET_OFFERS = 'GET_OFFERS',
  SET_OFFERS = 'SET_OFFERS',
  GET_CROWDSOURCED_OFFERS = 'GET_CROWDSOURCED_OFFERS',
}

export enum EStatesActionTypes {
  GET_STATES = 'GET_STATES',
  SET_STATES = 'SET_STATES',
  GET_REGIONS = 'GET_REGIONS',
  SET_REGIONS = 'SET_REGIONS',
}

export enum EMajorsActionTypes {
  GET_MAJORS = 'GET_MAJORS ',
  SET_MAJORS = 'SET_MAJORS ',
}

export enum EMyListsActionTypes {
  GET_MY_LISTS = 'GET_MY_LISTS',
  SET_MY_LISTS = 'SET_MY_LISTS',
  GET_MY_LIST = 'GET_MY_LIST',
  DELETE_COLLEGE_FROM_MY_LIST = 'DELETE_COLLEGE_FROM_MY_LIST',
  ADD_COLLEGES_TO_MY_LIST = 'ADD_COLLEGES_TO_MY_LIST',
  CREATE_MY_LIST = 'CREATE_MY_LIST',
  CREATE_MY_LIST_AND_ADD_COLLEGE = 'CREATE_MY_LIST_AND_ADD_COLLEGE',
  DELETE_MY_LIST = 'DELETE_MY_LIST',
  GET_LIST_BY_ID = 'GET_LIST_BY_ID',
  DELETE_COLLEGE_FROM_ALL_LISTS = 'DELETE_COLLEGE_FROM_ALL_LISTS',
  ADD_COLLEGE_TO_ALL_LISTS = 'ADD_COLLEGE_TO_ALL_LISTS',
  SET_SELECTED_LIST = 'SET_SELECTED_LIST',
  SET_RECOMMENDATIONS = 'SET_RECOMMENDATIONS',
  GET_SHARED_LIST = 'GET_SHARED_LIST',
  SET_SHARED_LIST = 'SET_SHARED_LIST',
  DELETE_ALL_COLLEGES_FROM_LIST = 'DELETE_ALL_COLLEGES_FROM_LIST',
  GET_PAGINATED_COLLEGES_MY_LIST = 'GET_PAGINATED_COLLEGES_MY_LIST',
  SET_PAGINATED_COLLEGES_MY_LIST = 'SET_PAGINATED_COLLEGES_MY_LIST',
  GET_RECOMMENDATIONS_AMOUNT_FOR_LIST = 'GET_RECOMMENDATIONS_AMOUNT_FOR_LIST',
  SET_RECOMMENDATIONS_AMOUNT_FOR_LIST = 'SET_RECOMMENDATIONS_AMOUNT_FOR_LIST',
  CANCEL_GET_PAGINATED_LIST = 'CANCEL_GET_PAGINATED_LIST',
  GET_ID_SHARED_LIST = 'GET_ID_SHARED_LIST',
  SET_ID_SHARED_LIST = 'SET_ID_SHARED_LIST',
  GET_IS_SHARED_LIST = 'GET_IS_SHARED_LIST',
  SET_IS_SHARED_LIST = 'SET_IS_SHARED_LIST',
}

export enum EUserActionTypes {
  GET_USER = 'GET_USER',
  UPDATE_USER = '[USER] UPDATE_USER',
  SET_USER = '[USER] SET_USER',
  GET_ADMIN_USER = 'GET_ADMIN_USER',
  SET_ADMIN_USER = 'SET_ADMIN_USER',
}

export enum ECouponActionTypes {
  GET_COUPON = 'GET_COUPON',
  SET_COUPON = '[COUPON] SET_COUPON',
}

export enum EUsersActionTypes {
  GET_USERS = 'GET_USERS',
  SET_USERS = 'SET_USERS',
  SET_SELECTED_USER_ID = 'SET_SELECTED_USER_ID',
  UPDATE_USER = '[USERS] UPDATE_USER',
  SET_USER = '[USERS] SET_USER',
  CREATE_UPDATE_USER = 'CREATE_UPDATE_USER',
}

export enum ESubscriptionActionTypes {
  GET_PAYMENT_METHOD_BY_USER_ID = 'GET_PAYMENT_METHOD_BY_USER_ID',
  GET_SUBSCRIPTION_BY_USERID = 'GET_SUBSCRIPTION_BY_USERID',
  SET_SUBSCRIPTION = 'SET_SUBSCRIPTION',
  DELETE_SUBSCRIPTION = 'DELETE_SUBSCRIPTION',
  ADMIN_ADD_INTERNAL_SUBSCRIPTION = 'ADMIN_ADD_INTERNAL_SUBSCRIPTION',
  ADMIN_ADD_STRIPE_SUBSCRIPTION = 'ADMIN_ADD_STRIPE_SUBSCRIPTION',
}

export enum ENotesActionTypes {
  GET_LIST_NOTES = 'GET_LIST_NOTES',
  GET_NOTE_BY_ID = 'GET_NOTE_BY_ID',
  CREATE_NOTE = 'CREATE_NOTE',
  DELETE_NOTE = 'DELETE_NOTE',
  UPDATE_NOTE = 'UPDATE_NOTE',
  SET_LIST_NOTES = 'SET_LIST_NOTES',
  SET_NOTE = 'SET_NOTE',
}

export enum ECollegeNotesActionTypes {
  GET_COLLEGE_NOTES = 'GET_COLLEGE_NOTES',
  CREATE_COLLEGE_NOTE = 'CREATE_COLLEGE_NOTE',
  UPDATE_COLLEGE_NOTE = 'UPDATE_COLLEGE_NOTE',
  DELETE_COLLEGE_NOTE = 'DELETE_COLLEGE_NOTE',
  SET_COLLEGE_NOTES = 'SET_COLLEGE_NOTES',
}

export enum EBackupsActionTypes {
  GET_LIST_BACKUPS = 'GET_LIST_BACKUPS',
  SET_LIST_BACKUPS = 'SET_LIST_BACKUPS',
  DELETE_BACKUP_BY_NAME = 'DELETE_BACKUP_BY_NAME',
  DOWNLOAD_BACKUP_BY_NAME = 'DOWNLOAD_BACKUP_BY_NAME',

  RESTORE_BACKUP_BY_NAME = 'RESTORE_BACKUP_BY_NAME',
  CREATE_BACKUP = 'CREATE_BACKUP',
}

export enum EBackupsUserListActionTypes {
  DOWNLOAD_BACKUP_USER_LIST_BY_LIST_ID = 'DOWNLOAD_BACKUP_USER_LIST_BY_LIST_ID',
  GET_ALL_BACKUP_LIST_BY_USER_ID = 'GET_ALL_BACKUP_LIST_BY_USER_ID',
  SET_ALL_BACKUP_LIST_BY_USER_ID = 'SET_ALL_BACKUP_LIST_BY_USER_ID',

  DELETE_BACKUP_USER_LIST_BY_LIST_NAME = 'DELETE_BACKUP_USER_LIST_BY_LIST_NAME',
}

export enum EPreviousRouteTypes {
  GET_PREVIOUS_ROUTE = 'GET_PREVIOUS_ROUTE',
  SET_PREVIOUS_ROUTE = 'SET_PREVIOUS_ROUTE',
}

export enum ESwitcherBLocksMyListTypes {
  GET_PROFILE = 'GET_PROFILE',
  SET_PROFILE = 'SET_PROFILE',
  GET_ADMISSIONS = 'GET_ADMISSIONS',
  SET_ADMISSIONS = 'SET_ADMISSIONS',
  GET_SCHOLASHIPS = 'GET_SCHOLASHIPS',
  SET_SCHOLASHIPS = 'SET_SCHOLASHIPS',
  GET_FINANCE = 'GET_FINANCE',
  SET_FINANCE = 'SET_FINANCE',
}

export enum ESwitcherTypeMyListTypes {
  GET_ISVISIBLETABLEPAGINATION = 'GET_ISVISIBLETABLEPAGINATION',
  SET_ISVISIBLETABLEPAGINATION = 'SET_ISVISIBLETABLEPAGINATION',
}

export enum ESelectedCollegesAndColumnsTypes {
  GET_SELECTED_COLLEGES_FOR_CSV = 'GET_SELECTED_COLLEGES_FOR_CSV',
  SET_SELECTED_COLLEGES_FOR_CSV = 'SET_SELECTED_COLLEGES_FOR_CSV',
  GET_SELECTED_COLUMNS_FOR_CSV = 'GET_SELECTED_COLUMNS_FOR_CSV',
  SET_SELECTED_COLUMNS_FOR_CSV = 'SET_SELECTED_COLUMNS_FOR_CSV',
}

export enum ESelectedCollegesForSharingTypes {
  GET_SELECTED_COLLEGES_FOR_SHARING = 'GET_SELECTED_COLLEGES_FOR_SHARING',
  SET_SELECTED_COLLEGES_FOR_SHARING = 'SET_SELECTED_COLLEGES_FOR_SHARING',
}

export enum EPowerSearchTypes {
  CLEAR_FILTERS = 'CLEAR_FILTERS',
  SET_SORT = 'SET_SORT',
  ADD_SELECTED_COLLEGE = 'ADD_SELECTED_COLLEGE',
  REMOVE_FROM_SELECTED_COLLEGES = 'REMOVE_FROM_SELECTED_COLLEGES',
  ADD_SELECTED_MAJOR = 'ADD_SELECTED_MAJOR',
  REMOVE_FROM_SELECTED_MAJORS = 'REMOVE_FROM_SELECTED_MAJORS',
  ADD_SELECTED_LOCATION = 'ADD_SELECTED_LOCATION',
  REMOVE_FROM_SELECTED_LOCATIONS = 'REMOVE_FROM_SELECTED_LOCATIONS',
  ADD_SELECTED_APPLICATION_DEADLINE = 'ADD_SELECTED_APPLICATION_DEADLINE',
  REMOVE_FROM_SELECTED_APPLICATION_DEADLINES = 'REMOVE_FROM_SELECTED_APPLICATION_DEADLINES',
  ADD_COST_OF_ATTENDANCE_TYPE_SELECTED = 'ADD_COST_OF_ATTENDANCE_TYPE_SELECTED',
  REMOVE_FROM_COST_OF_ATTENDANCE_TYPES_SELECTED = 'REMOVE_FROM_COST_OF_ATTENDANCE_TYPES_SELECTED',
  SET_COST_OF_ATTENDANCE = 'SET_COST_OF_ATTENDANCE',
  SET_AVG_MERIT_AWARD_SELECTED = 'SET_AVG_MERIT_AWARD_SELECTED',
  SET_AVG_MERIT_AWARD_TYPE_SELECTED = 'AVG_MERIT_AWARD_TYPE_SELECTED',
  ADD_NET_COST_TYPE_SELECTED = 'ADD_NET_COST_TYPE_SELECTED',
  REMOVE_FROM_NET_COST_TYPE_SELECTED = 'REMOVE_FROM_NET_COST_TYPE_SELECTED',
  SET_NET_COST_AFTER_AVG_MERIT_AWARD_SELECTED = 'SET_NET_COST_AFTER_AVG_MERIT_AWARD_SELECTED',
  SET_NEED_MET_SELECTED = 'SET_NEED_MET_SELECTED',
  SET_SCHOOL_SIZE = 'SET_SCHOOL_SIZE',
  SET_TEST_SCORE_VALUE_SELECTED = 'SET_TEST_SCORE_VALUE_SELECTED',
  SET_SAT_SCORE_SELECTED = 'SET_SAT_SCORE_SELECTED',
  SET_ACT_SCORE_SELECTED = 'SET_ACT_SCORE_SELECTED',
  SET_SELECTED_TEST_SCORE_TYPE = 'SET_SELECTED_TEST_SCORE_TYPE',
  SET_GPA = 'SET_GPA',
  SET_STUDENT_GPA_TYPE_SELECTED = 'SET_STUDENT_GPA_TYPE_SELECTED',
}
