import { useEffect, useState } from 'react';
import { useWindowSize } from './useWindowSize';
import { EPlatform } from '../enums/platform.enum';

export function usePlatform(): EPlatform {
  const windowWidth = useWindowSize()[0];
  const [platform, setPlatform] = useState<EPlatform>(EPlatform.DESKTOP);

  useEffect(() => {
    if (windowWidth >= 0 && windowWidth <= 520) {
      setPlatform(EPlatform.MOBILE);
    } else if (windowWidth >= 521 && windowWidth <= 900) {
      setPlatform(EPlatform.TABLET);
    } else {
      setPlatform(EPlatform.DESKTOP);
    }
  }, [windowWidth]);

  return platform;
}
