import { IRootState } from '../../models';
import { collegesState } from './colleges.state';
import { efcsState } from './efcs.state';
import { highschoolsState } from './highschools.state';
import { loadingState } from './loading.state';
import { offersState } from './offers.state';
import { statesState } from './states.state';
import { userState } from './user.state';
import { usersState } from './users.state';
import { majorsState } from './majors.state';
import { myListsState } from './my-list.state';
import { subscriptionState } from './subscription.state';
import { notesState } from './notes.state';
import { collegeNotesState } from './college-notes.state';
import { listBackupsState } from './list-backups.state';
import { backupUserListState } from './backup-user-list.state';
import { previousRouteState } from './previous-route.state';
import { houseHoldIncomeState } from './household-income.state';
import { switcherBlocksMyListState } from './switcher-blocks-my-list.state';
import { selectedCollegesAndColumnsForCsvState } from './selected-colleges-and-columns-for-csv.state';
import { selectedCollegesForSharingState } from './selected-colleges-for-sharing.state';
import { powerSearchState } from './power-search.state';
import { couponState } from './coupon.state';
// import { switcherTypeMyListState } from './switcher-type-my-list.state';

const rootState: IRootState = {
  collegesState,
  couponState,
  efcsState,
  highschoolsState,
  loadingState,
  offersState,
  statesState,
  userState,
  usersState,
  majorsState,
  myListsState,
  subscriptionState,
  notesState,
  collegeNotesState,
  listBackupsState,
  backupUserListState,
  previousRouteState,
  houseHoldIncomeState,
  switcherBlocksMyListState,
  selectedCollegesAndColumnsForCsvState,
  selectedCollegesForSharingState,
  powerSearchState,
  // switcherTypeMyListState,
};

export default rootState;
