import { produce } from 'immer';

import { createReducer } from '.';
import { userActionTypes } from '../../consts';
import { IAction, IUser, IUserState } from '../../models';
import { userState } from '../states/user.state';

export const userReducer = createReducer<IUserState>(userState, {
  [userActionTypes.SET_USER]: setUser,
  [userActionTypes.SET_ADMIN_USER]: setAdminUser,
});

function setUser(state: IUserState, { payload }: IAction<IUser>) {
  return produce(state, (draft) => {
    draft.user = { ...payload };
  });
}
function setAdminUser(state: IUserState, { payload }: IAction<IUser>) {
  return produce(state, (draft) => {
    draft.adminNavigatedUser = payload;
  });
}
