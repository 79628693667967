import createSagaMiddleware from 'redux-saga';
import { persistStore, persistReducer } from 'redux-persist';

import localforage from 'localforage';
import { configureStore } from '@reduxjs/toolkit';
import rootState from './states';
import rootReducer from './reducers';
import rootSaga from './sagas';

const sagaMiddleware = createSagaMiddleware();

const persistConfig = {
  key: 'root',
  storage: localforage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

const configStore = (initialState = rootState) => {
  const store = configureStore({
    reducer: persistedReducer,
    preloadedState: initialState,
    middleware: [sagaMiddleware],
  });

  const persistor = persistStore(store);

  sagaMiddleware.run(rootSaga);

  return { store, persistor };
};

export const store = configStore();
