import { produce } from 'immer';

import { createReducer } from '.';
import { listBackupsActionTypes } from '../../consts';
import { IListBackupsState, IAction } from '../../models';
import { listBackupsState } from '../states/list-backups.state';

export const listBackupsReducer = createReducer<IListBackupsState>(listBackupsState, {
  [listBackupsActionTypes.SET_LIST_BACKUPS]: setListBackups,
  [listBackupsActionTypes.DELETE_BACKUP_BY_NAME]: setBackupByName,
});

function setListBackups(state: IListBackupsState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.listBackups = payload;
  });
}

function setBackupByName(state: IListBackupsState, { payload }: IAction<any>) {
  return produce(state, (draft) => {
    draft.listBackups = payload;
  });
}
