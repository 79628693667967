import './Admin-upload-csvs.css';
import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { usersService } from '../../services';
import arrowBackBlue from '../../images/arrow-back-blue.svg';
import petersonLinkWarning from '../../images/peterson-link-warning.svg';
import { petersonService } from '../../services/petersons.service';

export const AdminUploadCsvs = () => {
  const [collegeFile, setCollegeFile] = useState<File | null>(null);
  const [majorsFile, setMajorsFile] = useState<File | null>(null);
  const [highschoolsFile, setHighschoolsFile] = useState<File | null>(null);
  const [coasFile, setCoasFile] = useState<File | null>(null);

  const [collegeLoad, setCollegeLoad] = useState(false);
  const [majorLoad, setMajorLoad] = useState(false);
  const [highschoolLoad, setHighschoolLoad] = useState(false);
  const [coasLoad, setCoasLoad] = useState(false);
  const [petersonsLinks, setPetersonsLinks] = useState<any[]>([]);
  const [isPullDataAvailable, setIsPullDataAvailable] = useState(true);

  useEffect(() => {
    getPetersonLinks();
  }, []);

  const getPetersonLinks = async () => {
    const petersonsLinks = await petersonService.endpoint_get_all_petersons_links();
    setPetersonsLinks(petersonsLinks.data);
  };

  const downloadPetersonFile = async (fileName: string) => {
    await petersonService.endpoint_download_petersons_file(fileName).then(() => {
      window.location.href = `${process.env.REACT_APP_API_BASE_URL}/petersons/${fileName}`;
    });
  };

  const pullNewData = async () => {
    setIsPullDataAvailable(false);
    await petersonService
      .endpoint_pull_petersons_data()
      .then(() => {
        setIsPullDataAvailable(true);
      })
      .catch(() => {
        setIsPullDataAvailable(true);
      });
  };

  const uploadCsvs = async () => {
    const colleges = document.getElementById('colleges');
    const majors = document.getElementById('majors');
    const highschools = document.getElementById('highschools');
    const coas = document.getElementById('coas');

    colleges?.classList.remove('upload-error', 'uploadsuccess');
    majors?.classList.remove('upload-error', 'uploadsuccess');
    highschools?.classList.remove('upload-error', 'uploadsuccess');
    coas?.classList.remove('upload-error', 'uploadsuccess');

    if (colleges && majors && highschools && coas) {
      colleges.innerText = '';
      majors.innerText = '';
      highschools.innerText = '';
      coas.innerText = '';
    }

    if (collegeFile && colleges) {
      setCollegeLoad(true);
      const data = await usersService.endpoint_upload_csvs('colleges', collegeFile).catch(() => {
        setCollegeFile(null);
        setCollegeLoad(false);
        colleges?.classList.remove('uploadsuccess');
        colleges?.classList.add('upload-error');
        colleges.innerText = 'Fail';
      });
      if (data) {
        setCollegeFile(null);
        setCollegeLoad(false);
        colleges?.classList.remove('upload-error');
        colleges?.classList.add('uploadsuccess');
        colleges.innerText = 'Success';
      }
    }
    if (majorsFile && majors) {
      setMajorLoad(true);
      const data = await usersService.endpoint_upload_csvs('majors', majorsFile).catch(() => {
        setMajorsFile(null);
        setMajorLoad(false);
        majors?.classList.remove('uploadsuccess');
        majors?.classList.add('upload-error');
        majors.innerText = 'Fail';
      });
      if (data) {
        setMajorsFile(null);
        setMajorLoad(false);
        majors?.classList.remove('upload-error');
        majors?.classList.add('uploadsuccess');
        majors.innerText = 'Success';
      }
    }
    if (highschoolsFile && highschools) {
      setHighschoolLoad(true);
      const data = await usersService.endpoint_upload_csvs('highschools', highschoolsFile).catch(() => {
        setHighschoolsFile(null);
        setHighschoolLoad(false);
        highschools?.classList.remove('uploadsuccess');
        highschools?.classList.add('upload-error');
        highschools.innerText = 'Fail';
      });
      if (data) {
        setHighschoolsFile(null);
        setHighschoolLoad(false);
        highschools?.classList.remove('upload-error');
        highschools?.classList.add('uploadsuccess');
        highschools.innerText = 'Success';
      }
    }
    if (coasFile && coas) {
      setCoasLoad(true);
      const data = await usersService.endpoint_upload_csvs('coas', coasFile).catch(() => {
        setCoasFile(null);
        setCoasLoad(false);
        coas?.classList.remove('uploadsuccess');
        coas?.classList.add('upload-error');
        coas.innerText = 'Fail';
      });
      if (data) {
        setCoasFile(null);
        setCoasLoad(false);
        coas?.classList.remove('upload-error');
        coas?.classList.add('uploadsuccess');
        coas.innerText = 'Success';
      }
    }
  };

  return (
    <div className="admin-upload-csv-container">
      <div className="admin-upload-csv-wrapper">
        <NavLink to={'/dashboard'} className="admin-upload-csv-nav-dashboard">
          <img src={arrowBackBlue} alt="arrow back blue" />
          <p>Back to admin dashboard</p>
        </NavLink>
        <div className="upload-csvs-body">
          <div className="peterson-links-container">
            <h1 className="admin-upload-csv-headers">Petersons data</h1>
            {petersonsLinks?.map((link) => (
              <div className="peterson-links-wrapper">
                <div className="peterson-links">
                  {!link.isViewed && (
                    <img className="peterson-link-warn" src={petersonLinkWarning} alt="link warning" />
                  )}
                  <p>{link}</p>
                </div>
                <div className="peterson-links-buttons">
                  <button
                    className="download"
                    onClick={() => {
                      downloadPetersonFile(link);
                    }}
                  >
                    Download
                  </button>
                </div>
              </div>
            ))}
            <button
              className={isPullDataAvailable ? 'admin-pull-btn' : 'admin-pull-btn-disabled'}
              onClick={pullNewData}
            >
              Pull New Data
              {!isPullDataAvailable && <div className="admin-loader" />}
            </button>
          </div>
          <div className="upload-files-inputs">
            <h1 className="admin-upload-csv-headers">Upload new Insights Data</h1>
            <p>colleges.csv file</p>
            <div>
              {collegeFile ? collegeFile.name : 'No file chosen'}
              <input
                type="file"
                onChange={({ target }) => {
                  if (target.files) setCollegeFile(target.files[0]);
                }}
              />
              <button>
                {collegeLoad ? (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  'Browse'
                )}
              </button>
            </div>
            <div id="colleges" />
            <p>majors.csv file</p>
            <div>
              {majorsFile ? majorsFile.name : 'No file chosen'}
              <input
                type="file"
                onChange={({ target }) => {
                  if (target.files) setMajorsFile(target.files[0]);
                }}
              />
              <button>
                {majorLoad ? (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  'Browse'
                )}
              </button>
            </div>
            <div id="majors" />
            <p>highschools.csv file</p>
            <div>
              {highschoolsFile ? highschoolsFile.name : 'No file chosen'}
              <input
                type="file"
                onChange={({ target }) => {
                  if (target.files) setHighschoolsFile(target.files[0]);
                }}
              />
              <button>
                {highschoolLoad ? (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  'Browse'
                )}
              </button>
            </div>
            <div id="highschools" />
            <p>coas.csv file</p>
            <div>
              {coasFile ? coasFile.name : 'No file chosen'}
              <input
                type="file"
                onChange={({ target }) => {
                  if (target.files) setCoasFile(target.files[0]);
                }}
              />
              <button>
                {coasLoad ? (
                  <div className="lds-ellipsis">
                    <div />
                    <div />
                    <div />
                    <div />
                  </div>
                ) : (
                  'Browse'
                )}
              </button>
            </div>
            <div id="coas" />
            <button className="admin-upload-csv-submit-btn" onClick={uploadCsvs}>
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
