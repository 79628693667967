import React from 'react';
import cross from '../../../../images/cross.svg';
import styles from './SelectedItemsList.module.css';

interface SelectedItemsListProps {
  list: { id: number; name: string }[];
  onDeleteHandler: (id: number) => void;
}

export const SelectedItemsList: React.FC<SelectedItemsListProps> = ({ list, onDeleteHandler }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.container}>
        {list.map((item) => {
          return (
            <div className={styles.item} key={item.id}>
              <p className={styles.itemText}>{item.name}</p>
              <img className={styles.crossIcon} onClick={() => onDeleteHandler(item.id)} src={cross} alt="cross" />
            </div>
          );
        })}
      </div>
    </div>
  );
};
