import WooCommerceRestApi from '@woocommerce/woocommerce-rest-api';

export const WooCommerce = new WooCommerceRestApi({
  url: `${process.env.REACT_APP_LEARN_URL}`,
  consumerKey: `${process.env.REACT_APP_WOOCOMMERCE_CONSUMER_API}`,
  consumerSecret: `${process.env.REACT_APP_WOOCOMMERCE_CONSUMER_SECRET}`,
  version: 'wc/v3',
  axiosConfig: {
    headers: {
      'Content-Type': 'application/json;charset=UTF-8',
    },
  },
});
