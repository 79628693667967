export const months = ['AUG', 'SEP', 'OCT', 'NOV', 'DEC', 'JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUNE', 'JULY'];
export const calendarData = [
  {
    title: 'Sophomore Year',
    labels: [
      'Calculate EFC or SAI',
      'Assess Retirement Readiness',
      'Calculate College Cash Flow',
      'Calendar Issues: Vacations, Visits etc',
      'College Major & Career Assessment',
      'Review Curriculum Strength for Grades 11 & 12',
      'Establish Test Strategy- ACT vs SAT',
      'Identify College Search Criteria',
    ],
    dataset: [
      { color: 'var(--parent-color)', with: 5, left: 0 },
      { color: 'var(--parent-color)', with: 5, left: 0 },
      { color: 'var(--parent-color)', with: 5, left: 0 },
      { color: 'var(--family-color)', with: 12, left: 0 },
      { color: 'var(--student-color)', with: 12, left: 0 },
      { color: 'var(--family-color)', with: 2, left: 9 },
      { color: 'var(--student-color)', with: 3, left: 9 },
      { color: 'var(--family-color)', with: 3, left: 9 },
    ],
  },
  {
    title: 'Junior Year',
    labels: [
      'Calendar Issues: Vacations, Visits etc',
      'College Major & Career Assessment',
      'Prep & Take PSAT/NMSQT',
      'Review PSAT/NMSQT Results',
      'Student Competitive Position (CAP Index)',
      'Establish Test Strategy- ACT vs SAT',
      'Identify College Search Criteria',
      'Start Initial College Search',
      'Prepare for SAT/ACT',
      'Take SAT/ACT',
      'College Research & Evaluation',
      'Letters of Recommendation Requested',
      'Spring College Visits',
      'Summer College Visits',
      'Draft Common Application Personal Essay',
      'Identify & Begin Writing Additional Essays',
    ],
    dataset: [
      { color: 'var(--family-color)', with: 2, left: 0 },
      { color: 'var(--student-color)', with: 2, left: 0 },
      { color: 'var(--student-color)', with: 2, left: 0 },
      { color: 'var(--family-color)', with: 3, left: 3 },
      { color: 'var(--family-color)', with: 5, left: 0 },
      { color: 'var(--student-color)', with: 5, left: 0 },
      { color: 'var(--family-color)', with: 5, left: 0 },
      { color: 'var(--family-color)', with: 7, left: 3 },
      { color: 'var(--student-color)', with: 7, left: 4 },
      { color: 'var(--student-color)', with: 6, left: 4 },
      { color: 'var(--family-color)', with: 8, left: 4 },
      { color: 'var(--student-color)', with: 3, left: 8 },
      { color: 'var(--family-color)', with: 4, left: 6 },
      { color: 'var(--family-color)', with: 2, left: 10 },
      { color: 'var(--student-color)', with: 1, left: 11 },
      { color: 'var(--student-color)', with: 1, left: 11 },
    ],
  },
  {
    title: 'Senior Year',
    labels: [
      'Take SAT/ACT',
      'College Research & Evaluation',
      'Summer College Visits',
      'Finalize College List',
      'Register for Common Application',
      'ID Admissions & Financial Aid Deadlines',
      'Fall College Visits',
      'College Interviews - Fall Senior Year',
      'Draft Common Application Personal Essay',
      'Identify & Begin Writing Additional Essays',
      'Advisor Reviews Common Application',
      'Submit Common Application',
      'Register For CSS Profile',
      'Complete CSS Profile',
      'Registration: FSA ID for FAFSA',
      'Complete & Submit FAFSA',
      'Evaluate Financial Aid Awards',
      'Make Final College Visits if Necessary',
      'Final Decision & Enrollment Deposit',
      'Decide on First Year Financing Options',
    ],
    dataset: [
      { color: 'var(--student-color)', with: 4, left: 1 },
      { color: 'var(--family-color)', with: 2, left: 0 },
      { color: 'var(--family-color)', with: 1, left: 0 },
      { color: 'var(--family-color)', with: 4, left: 0 },
      { color: 'var(--student-color)', with: 3, left: 0 },
      { color: 'var(--parent-color)', with: 2, left: 1 },
      { color: 'var(--family-color)', with: 3, left: 1 },
      { color: 'var(--student-color)', with: 3, left: 1 },
      { color: 'var(--student-color)', with: 3, left: 0 },
      { color: 'var(--student-color)', with: 5, left: 0 },
      { color: 'var(--student-color)', with: 3, left: 1 },
      { color: 'var(--student-color)', with: 4, left: 1 },
      { color: 'var(--parent-color)', with: 4, left: 2 },
      { color: 'var(--parent-color)', with: 4, left: 2 },
      { color: 'var(--parent-color)', with: 5, left: 1 },
      { color: 'var(--parent-color)', with: 5, left: 2 },
      { color: 'var(--parent-color)', with: 1, left: 7 },
      { color: 'var(--family-color)', with: 2, left: 7 },
      { color: 'var(--family-color)', with: 1, left: 11 },
      { color: 'var(--parent-color)', with: 1, left: 11 },
    ],
  },
];
