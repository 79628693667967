import './CollegeDetailsFinance.css';
import { useSelector } from 'react-redux';

export const CollegeDetailsFinance = () => {
  const { selectedCollege } = useSelector((state: any) => state.collegesState);

  return (
    <section className="colleges-details-wrapper-container-under">
      <div className="colleges-details-wrapper">
        <div className="college-details-section">
          <h6>Costs</h6>
          <hr className="section-hr-line" />
          <div>
            <div className="race-info-block costs">
              <div>
                <p className="unlocked-field">
                  Private COA
                  <span>
                    {selectedCollege?.coaPrivate ? `$ ${(+selectedCollege.coaPrivate).toLocaleString('en-US')}` : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  In-State COA{' '}
                  <span>
                    {selectedCollege?.coaInState ? `$ ${(+selectedCollege.coaInState).toLocaleString('en-US')}` : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Out-of-State COA{' '}
                  <span>
                    {selectedCollege?.coaOutState ? `$ ${(+selectedCollege.coaOutState).toLocaleString('en-US')}` : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Avg Net price after Gift Aid{' '}
                  <span>
                    {selectedCollege?.avgNetPriceAfterAid
                      ? `$ ${(+selectedCollege.avgNetPriceAfterAid).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Avg Net Price Family Income $0-$30,000{' '}
                  <span>
                    {selectedCollege?.avgPriceTo30000
                      ? `$ ${(+selectedCollege.avgPriceTo30000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Avg Net Price Family Income $30,001-$48,000
                  <span>
                    {selectedCollege?.avgPriceFrom30001To48000
                      ? `$ ${(+selectedCollege.avgPriceFrom30001To48000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Avg Net Price Family Income $48,001-$75,000{' '}
                  <span>
                    {selectedCollege?.avgPriceFrom48001To75000
                      ? `$ ${(+selectedCollege.avgPriceFrom48001To75000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  {' '}
                  Avg Net Price Family Income $75,001-$110,000{' '}
                  <span>
                    {selectedCollege?.avgPriceFrom75001To110000
                      ? `$ ${(+selectedCollege.avgPriceFrom75001To110000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
                <p className="unlocked-field">
                  Avg Net Price Family Income over $110,000
                  <span>
                    {selectedCollege?.avgPriceOver110000
                      ? `$ ${(+selectedCollege.avgPriceOver110000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </p>
              </div>
            </div>
            <div className="price-calculation">
              <a
                target="_blank"
                href={selectedCollege?.urlAddressPriceCalc}
                rel="noreferrer"
                className={selectedCollege?.urlAddressPriceCalc ? 'active' : 'disable'}
              >
                <button className="price-calculation-finance-btn">Net Price Calculation</button>
              </a>
            </div>
          </div>
        </div>
        <div className="college-details-section">
          <h6>Debt</h6>
          <hr className="section-hr-line" />
          <div>
            <div className="race-info-block">
              <div>
                <p className="unlocked-field">Percent of Undergrads w/ Federal Student Loans</p>
                <p className="unlocked-field">Average Annual Federal Student Loans for Undergrads</p>
                <p className="unlocked-field">Grads From Recent Class w/ Any Type of Loan(s)</p>
                <p className="unlocked-field">Average Balances From Loans (All Types)</p>
                <p className="unlocked-field">Percent of Undergrads w/ Parent PLUS Loans</p>
                <p className="unlocked-field">Average Parent PLUS Loans per Borrower</p>
              </div>
              <div className="race-res-block">
                <span>{selectedCollege?.gradsAnyLoanP ? `${(+selectedCollege.gradsAnyLoanP).toFixed(0)} %` : '-'}</span>
                <span>
                  {selectedCollege?.gradsAnyLoanAvgAmt
                    ? `$ ${(+selectedCollege.gradsAnyLoanAvgAmt).toLocaleString('en-US')}`
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.pcGraduatingWithLoans
                    ? `${(+selectedCollege.pcGraduatingWithLoans).toFixed(0)} %`
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.avgLoanAmount
                    ? `$ ${(+selectedCollege.avgLoanAmount).toLocaleString('en-US')}`
                    : '-'}
                </span>
                <span>{selectedCollege?.parentPlusP ? `${(+selectedCollege.parentPlusP).toFixed(0)} %` : '-'}</span>
                <span>
                  {selectedCollege?.parentPlusAvgAmt
                    ? `$ ${Math.round(+selectedCollege.parentPlusAvgAmt).toLocaleString('en-US')}`
                    : '-'}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="college-details-section-link-faqs">
          <a target="_blank" rel="noreferrer" href="https://www.road2college.com/college-insights-faqs/">
            **College Data sources{' '}
          </a>
        </div>
      </div>
    </section>
  );
};
