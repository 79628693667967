import { produce } from 'immer';

import { createReducer } from '.';
import { ECollegeNotesActionTypes } from '../../enums';
import { IAction, ICollegeNote, ICollegeNotesState } from '../../models';
import { collegeNotesState } from '../states/college-notes.state';

export const collegeNotesReducer = createReducer<ICollegeNotesState>(collegeNotesState, {
  [ECollegeNotesActionTypes.SET_COLLEGE_NOTES]: setCollegeNotes,
});

function setCollegeNotes(collegeNotes: ICollegeNotesState, { payload }: IAction<Array<ICollegeNote>>) {
  return produce(collegeNotes, (draft) => {
    draft.collegeNotes = payload;
  });
}
