import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const majorsService = (function () {
  const endpoint_get_majors = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_majors);
  };

  return {
    endpoint_get_majors,
  };
})();
