import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ECouponActionTypes } from '../../enums';
import { couponAction, loadingAction } from '../actions';
import { couponService } from '../../services/coupon.service';
import { ICoupon } from '../../models/coupon.model';

export default function* root() {
  yield all([takeLatest(ECouponActionTypes.GET_COUPON as any, watchGetCoupon)]);
}

function* watchGetCoupon(action: { type: string; payload: { couponCode: string; packageId: number } }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getCouponLoading: true,
      }),
    );

    const { data } = yield call(couponService.endpoint_get_coupon, {
      couponId: action.payload.couponCode,
      packageId: action.payload.packageId,
    });

    yield put(couponAction.setCoupon(data as ICoupon));
  } catch (error: any) {
    console.error('watchGetCoupon: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        getCouponLoading: false,
      }),
    );
  }
}
