export const planOptions = [
    {
      id: 1,
      title: 'INSIGHTS',
      name: 'yearly',
      price: 14.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'One payment. Get access for 12 months.\n',
        'This would only appear when the toggle is selected for yearly',
      ],
      totalPrice: 174.99,
    },
    {
      id: 2,
      title: 'INSIGHTS ACADEMY',
      additionally: 'Most Popular',
      name: 'monthly',
      price: 24.99,
      class: 'monthly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'Cancel at anytime (all data will be saved for 3 months from the date of cancelling unless user re-activates subscription)',
      ],
      totalPrice: 24.99,
    },
    {
      id: 3,
      title: 'INSIGHTS',
      name: 'yearly-2023',
      price: 19.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'One Payment. Get Access for 12 Months',
      ],
      totalPrice: 239.88,
    },
    {
      id: 4,
      title: 'MONTHLY PLUS',
      name: 'monthly-plus',
      price: 39.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'Cancel at anytime (all data will be saved for 3 months from the date of cancelling unless user re-activates subscription)',
      ],
      totalPrice: 39.99,
    },
    {
      id: 5,
      title: 'YEARLY PLUS',
      name: 'yearly-plus',
      price: 24.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'One Payment. Get Access for 12 Months',
      ],
      totalPrice: 299.88,
    },
    {
      id: 6,
      title: 'MONTHLY PREMIUM',
      name: 'monthly-premium',
      price: 59.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'Cancel at anytime (all data will be saved for 3 months from the date of cancelling unless user re-activates subscription)',
      ],
      totalPrice: 59.99,
    },
    {
      id: 7,
      title: 'YEARLY PREMIUM',
      name: 'yearly-premium',
      price: 39.99,
      class: 'yearly-plan',
      options: [
        'Full Access to our Insights Tool',
        'Exclusive Access to Crowdsourced College Offers',
        'Profile-Based College Recommendations',
        'Merit Scholarship Data by College',
        'Advanced Search to Filter, Sort and Compare Colleges',
        'One Payment. Get Access for 12 Months',
      ],
      totalPrice: 479.88,
    },
  ];