/* eslint-disable */
import './Create-profile.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { useModal } from 'react-hooks-use-modal';
import wave from '../../images/Vector.svg';
import arrow from '../../images/select-arrow.svg';
import { EUserRoles } from '../../enums';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { previousRouteAction, userAction } from '../../store/actions';
import { ScholarshipModal } from '../scholarship-modal/ScholarshipModal';

export const CreateProfile = () => {
  const dispatch = useDispatch();
  const childRef = useRef<any>();
  const yearsOfGraduation: any[] = [];
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { user } = useSelector((state: any) => state.userState);

  const [Modal, open, close] = useModal('root', {
    closeOnOverlayClick: false,
  });

  for (let i = 0; i < 11; i++) {
    const currentYear = moment().year() - 2;
    yearsOfGraduation.push({ id: i, name: currentYear + i });
  }

  const [graduationYear, setGraduationYear] = useState(user.yearOfHighSchoolGraduation);
  const [isGraduationYearInputActive, setIsGraduationYearInputActive] = useState(false);
  const [fullNameValue, setfullNameValue] = useState(user.name);
  const [role, setRole] = useState(user.role);
  const [isCreatedProfile, setIsCreatedProfile] = useState(false);

  const selectYearOfGraduation = (year: any) => {
    setGraduationYear(year.name);
    setIsGraduationYearInputActive(false);
  };

  const activateInput = (id: string) => {
    const inp = document.getElementById(id);
    inp?.focus();
  };

  const createProfile = () => {
    if (!fullNameValue) {
      const fullNameInput = document.getElementById('fullNameChecked') as HTMLInputElement;
      if (fullNameInput) {
        fullNameInput.required = true;
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    if (!graduationYear && role !== EUserRoles.PROFESSIONAL) {
      const year = document.getElementById('studGradYear') as HTMLInputElement;
      if (year) {
        year.required = true;
      }
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth',
      });
    }
    if (fullNameValue && (graduationYear || role === EUserRoles.PROFESSIONAL)) {
      const body = { 
        email: user.email,
        role,
        stateId: null, 
        highschoolId: null,
        familyIncome: null,
        familyContributions: null,
        efcId: null,
        yearOfHighSchoolGraduation: graduationYear || null,
        gender: null,
        name: fullNameValue,
        athlete: null,
        sat: null,
        act: null,
        gpa: null,
        countFreeSearch: 5,
        hadSubscription: false,
        canceledSubscription: false,
        countDownloadCsv: 1,
      };
      dispatch(userAction.updateUser({ user: body }));
    }
  };

  useEffect(() => {
    if (isCreatedProfile) {
      createProfile();
      setIsCreatedProfile(false);
    }
  }, [isCreatedProfile]);

  const openModal = () => {
    if (previousRoute.payload === '/require-pay') {
      dispatch(previousRouteAction.setPreviousRoute('/create-profile-after-require'));
      createProfile();
      return;
    }
    if (previousRoute.payload === '/shared-lists') {
      dispatch(previousRouteAction.setPreviousRoute('/create-profile-after-shared-lists'));
    }
    open();
  };

  return (
    <div
      onClick={() => {
        setIsGraduationYearInputActive(false);
      }}
    >
      <div className="header-create-account">
        <h1>Create Your Insights Account </h1>
        <img className="wave" src={wave} alt="wave" />
      </div>

      <div className="create-account-body">
        <div className="create-account-form">
          <div className="mb-4">
            <div className="input-select">
              <p>Full Name</p>
              <input
                id="fullNameChecked"
                type="text"
                name="get"
                placeholder="Full Name"
                value={fullNameValue}
                onChange={({ target }) => setfullNameValue(target.value)}
                className="input-select-create-profile-mobile"
              />
            </div>
          </div>

          <div className="mb-4">
            <p>Your role</p>
            <div className="roles-radios">
              <div>
                <input
                  type="radio"
                  name="role"
                  value={EUserRoles.PARENT}
                  checked={role === EUserRoles.PARENT}
                  onChange={() => setRole(EUserRoles.PARENT)}
                />
                <p>Parent</p>
              </div>
              <div>
                <input
                  type="radio"
                  name="role"
                  value={EUserRoles.STUDENT}
                  checked={role === EUserRoles.STUDENT}
                  onChange={() => setRole(EUserRoles.STUDENT)}
                />
                <p>Student</p>
              </div>
              <div>
                <input
                  type="radio"
                  name="role"
                  value={EUserRoles.PROFESSIONAL}
                  checked={role === EUserRoles.PROFESSIONAL}
                  onChange={() => {
                    setRole(EUserRoles.PROFESSIONAL);
                    setGraduationYear(undefined);
                  }}
                />
                <p>Professional</p>
              </div>
            </div>
          </div>

          <div className="mb-4">
            <p>Year of High School Graduation</p>
            {role !== EUserRoles.PROFESSIONAL && (
              <div className="input-select graduation">
                <img
                  src={arrow}
                  alt="arrow"
                  id="year"
                  className={isGraduationYearInputActive ? 'look-up' : 'look-down'}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (!isGraduationYearInputActive) {
                      activateInput('studGradYear');
                    }
                    setIsGraduationYearInputActive(!isGraduationYearInputActive);
                  }}
                />
                <input
                  id="studGradYear"
                  type="text"
                  placeholder="Student’s Graduation Year"
                  value={graduationYear}
                  readOnly
                  className="input-select-create-profile-mobile"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsGraduationYearInputActive(true);
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    setIsGraduationYearInputActive(false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isGraduationYearInputActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectYearOfGraduation}
                    filteredItem={yearsOfGraduation}
                  />
                )}
              </div>
            )}
          </div>

          <div className="flex-custom-center">
            <button onClick={openModal} type="button" className="button-submit">
              {previousRoute.payload !== '/require-pay' && 'Submit'}
              {previousRoute.payload === '/require-pay' && 'Next'}
            </button>
          </div>
        </div>
      </div>
      <Modal>
        <ScholarshipModal
          close={close}
          message={'You have successfully created your profile.'}
          title={'Congratulations!'}
          textButton={'Ok'}
          setOk={setIsCreatedProfile}
        />
      </Modal>
    </div>
  );
};
