import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EHouseHoldIncomeActionTypes } from '../../enums';
import { IHouseHoldIncome } from '../../models';
import { householdIncomeService } from '../../services';
import { loadingAction, householdIncomeAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EHouseHoldIncomeActionTypes.GET_HOUSEHOLDINCOME as any, watchGetHouseHoldIncome)]);
}

function* watchGetHouseHoldIncome() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        houseHoldIncomeLoading: true,
      }),
    );

    const { data } = yield call(householdIncomeService.endpoint_get_all_household_income);

    yield put(householdIncomeAction.setHouseHoldIncome(data as Array<IHouseHoldIncome>));
  } catch (error: any) {
    console.error('watchGetHouseHoldIncome: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        houseHoldIncomeLoading: false,
      }),
    );
  }
}
