import { ECollegesActionTypes } from '../../enums';
import { IAdvancedSearch, ICollege, IPowerSearch, IUpdatedRecommendedSearch } from '../../models';

const getColleges = () => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES,
  };
};

const getCollegesBySize = () => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES_BY_SIZE,
  };
};

const getNearestColleges = (payload: string) => {
  return {
    type: ECollegesActionTypes.GET_NEAREST_COLLEGES,
    payload,
  };
};
const getCollegesForLandingPage = (payload: string) => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES_FOR_LANDING_PAGE,
    payload,
  };
};
const getCollegeById = (payload: number) => {
  return {
    type: ECollegesActionTypes.GET_COLLEGE_BY_ID,
    payload,
  };
};

const getFullCollegeById = (payload: { collegeId: number; userId: number }) => {
  return {
    type: ECollegesActionTypes.GET_FULL_COLLEGE_BY_ID,
    payload,
  };
};

const getPrivateColleges = () => {
  return {
    type: ECollegesActionTypes.GET_PRIVATE_COLLEGES,
  };
};

const getPublicColleges = () => {
  return {
    type: ECollegesActionTypes.GET_PUBLIC_COLLEGES,
  };
};

const setColleges = (payload: ICollege[]) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGES,
    payload,
  };
};

const getCollegesNames = () => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES_NAMES,
  };
};

const setCollegesNames = (payload: Partial<ICollege>[]) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGES_NAMES,
    payload,
  };
};

const setTrendingColleges = (payload: Partial<ICollege>[]) => {
  return {
    type: ECollegesActionTypes.SET_TRENDING_COLLEGES,
    payload,
  };
};

const setCollegeById = (payload: ICollege) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGE_BY_ID,
    payload,
  };
};

const setCollege = (payload: ICollege) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGE,
    payload,
  };
};

const setSelectedCollegeId = (payload: number) => {
  return {
    type: ECollegesActionTypes.SET_SELECTED_COLLEGE_ID,
    payload,
  };
};

const updateCollege = (payload: { college: ICollege; next: Function }) => {
  return {
    type: ECollegesActionTypes.UPDATE_COLLEGE,
    payload,
  };
};

const getCollegesAdvancedSearch = (payload: IAdvancedSearch | IPowerSearch) => {
  return {
    type: ECollegesActionTypes.GET_COLLEGES_ADVANCED_SEARCH,
    payload,
  };
};

const setCollegesAdvancedSearch = (payload: ICollege[]) => {
  return {
    type: ECollegesActionTypes.SET_COLLEGES_ADVANCED_SEARCH,
    payload,
  };
};

const setRecommendedColleges = (payload: ICollege[]) => {
  return {
    type: ECollegesActionTypes.SET_RECOMMENDED_COLLEGES,
    payload,
  };
};

const getRecommendedColleges = (payload: { userId: number; category: string }) => {
  return {
    type: ECollegesActionTypes.GET_RECOMMENDED_COLLEGES,
    payload,
  };
};

const getRecommendedCollegesByCategory = (payload: { userId: number; category: string }) => {
  return {
    type: ECollegesActionTypes.GET_RECOMMENDED_COLLEGES_BY_CATEGORY,
    payload,
  };
};

const getUpdatedRecommendedColleges = (payload: IUpdatedRecommendedSearch) => {
  return {
    type: ECollegesActionTypes.GET_UPDATED_RECOMMENDED_COLLEGES_BY_CATEGORY,
    payload,
  };
};

const setUpdatedRecommendedColleges = (payload: ICollege[]) => {
  return {
    type: ECollegesActionTypes.SET_UPDATED_RECOMMENDED_COLLEGES,
    payload,
  };
};

export const collegesAction = {
  getColleges,
  setColleges,
  setCollege,
  setSelectedCollegeId,
  updateCollege,
  getCollegesBySize,
  getPrivateColleges,
  getPublicColleges,
  setTrendingColleges,
  setCollegesNames,
  getCollegesNames,
  getNearestColleges,
  getCollegesAdvancedSearch,
  setCollegesAdvancedSearch,
  getCollegeById,
  setCollegeById,
  getFullCollegeById,
  setRecommendedColleges,
  getRecommendedColleges,
  getRecommendedCollegesByCategory,
  getCollegesForLandingPage,
  getUpdatedRecommendedColleges,
  setUpdatedRecommendedColleges,
};
