import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EMajorsActionTypes } from '../../enums';
import { IMajors } from '../../models';
import { majorsService } from '../../services';
import { majorsAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EMajorsActionTypes.GET_MAJORS as any, watchGetMajors)]);
}

function* watchGetMajors() {
  try {
    const { data } = yield call(majorsService.endpoint_get_majors);

    yield put(majorsAction.setMajors(data as Array<IMajors>));
  } catch (error: any) {
    console.error('watchGetMajors: ', error.response);
  }
}
