import React from 'react';
import { FacebookButton, TwitterButton, FacebookMessengerButton } from '../index';
import './SocialMedias.css';

export interface ISizeAndPositionModals {
  modalWidth: number;
  modalHeight: number;
  left: number;
  top: number;
}

export interface IEmailBody {
  to: string;
  listLink: string;
  listName: string;
  originLink: string;
  userName: string;
  userEmail: string;
}

export const modalStyles: ISizeAndPositionModals = {
  modalWidth: window.innerWidth <= 590 ? 300 : 550,
  modalHeight: window.innerWidth <= 590 ? 230 : 420,
  left: window.innerWidth / 2 - (window.innerWidth <= 590 ? 300 : 550) / 2,
  top: window.innerHeight / 2 - (window.innerWidth <= 590 ? 230 : 420) / 2,
};

export interface IBtnStyles {
  size: number;
  bgColor: string;
}

export const btnStyles: IBtnStyles = {
  size: 48,
  bgColor: '#030054',
};

type Props = {
  openModal: () => void;
  onHandleClickShareList: () => Promise<void>;
  onHandleOpenedSocialMedia: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleOpenModalWithZeroSharingColleges: () => boolean;
  additionalClass?: string;
};

export const SocialMedias = ({
  openModal,
  onHandleClickShareList,
  onHandleOpenedSocialMedia,
  onHandleOpenModalWithZeroSharingColleges,
  additionalClass,
}: Props) => {
  const copiedAddress = (): void => {
    const needOpenModal = onHandleOpenModalWithZeroSharingColleges();
    if (needOpenModal) return;
    const url = window.location.href.replace('my-lists', 'shared-lists');
    navigator.clipboard.writeText(url);
    const sharedTip: HTMLDivElement | null = document.getElementsByClassName(
      'social-medias-copied',
    )[0] as HTMLDivElement;

    if (sharedTip) {
      sharedTip.style.display = 'block';
      setTimeout(() => {
        sharedTip.style.display = 'none';
      }, 2000);
    }
    onHandleClickShareList();

    setTimeout(() => {
      onHandleOpenedSocialMedia(false);
    }, 2000);
  };

  const openEmailModal = (): void => {
    const needOpenModal = onHandleOpenModalWithZeroSharingColleges();
    if (needOpenModal) return;
    openModal();
  };

  return (
    <section className={`social-medias ${additionalClass}`}>
      <button
        className="social-button"
        onClick={openEmailModal}
        aria-label="Social Medias"
        style={{ width: btnStyles.size, height: btnStyles.size }}
      >
        <span className="social-button__inside email" />
      </button>
      <div
        className="social-button social-medias__copy-link"
        onClick={copiedAddress}
        style={{ width: btnStyles.size, height: btnStyles.size }}
      >
        <div className="social-medias-copied">Copied!</div>
        <span className="copy social-button__inside social-medias__copy-link__img" />
      </div>
      <FacebookButton
        onHandleClickShareList={onHandleClickShareList}
        onHandleOpenedSocialMedia={onHandleOpenedSocialMedia}
        onHandleOpenModalWithZeroSharingColleges={onHandleOpenModalWithZeroSharingColleges}
      />
      <FacebookMessengerButton
        onHandleClickShareList={onHandleClickShareList}
        onHandleOpenedSocialMedia={onHandleOpenedSocialMedia}
        onHandleOpenModalWithZeroSharingColleges={onHandleOpenModalWithZeroSharingColleges}
      />
      <TwitterButton
        onHandleClickShareList={onHandleClickShareList}
        onHandleOpenedSocialMedia={onHandleOpenedSocialMedia}
        onHandleOpenModalWithZeroSharingColleges={onHandleOpenModalWithZeroSharingColleges}
      />
    </section>
  );
};
