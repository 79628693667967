import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const statesService = (function () {
  const endpoint_get_states = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_states);
  };

  const endpoint_get_regions = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_regions);
  };

  return {
    endpoint_get_states,
    endpoint_get_regions,
  };
})();
