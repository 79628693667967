import { useEffect } from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';

function withRouter(Component: any) {
  function ComponentWithRouterProp(props: any) {
    const location = useLocation();
    const navigate = useNavigate();
    const params = useParams();
    return <Component {...props} router={{ location, navigate, params }} />;
  }

  return ComponentWithRouterProp;
}

interface ScrollToTopProps {
  children: any;
  router: {
    location: {
      pathname: string;
    };
  };
}

const ScrollToTop: React.FC<ScrollToTopProps> = ({
  children,
  router: {
    location: { pathname },
  },
}) => {
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);

  useEffect(() => {
    if (previousRoute.payload === '/home-page-after-update') return;
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    });
  }, [pathname]);
  return children || null;
};

export default withRouter(ScrollToTop);
