import './Admin-users-list.css';
import { useEffect, useRef, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { SyncLoader } from 'react-spinners';
import { DebounceInput } from 'react-debounce-input';
import moment from 'moment';
import { usersService } from '../../services';
import arrowBackBlue from '../../images/arrow-back-blue.svg';
import userIcon from '../../images/users-list-icon.svg';
import search from '../../images/Search.svg';
import arrowSort from '../../images/arrow-sort.svg';
import arrowPagination from '../../images/arrow-pagination.svg';
import checkmark from '../../images/checkmarkWhite.svg';
import plusViolet from '../../images/plusVioletBold.svg';

const packages = [
  { id: 1, name: 'Annual Old' },
  { id: 2, name: 'Monthly Basic' },
  { id: 3, name: 'Annual Basic' },
  { id: 4, name: 'Monthly Plus' },
  { id: 5, name: 'Annual Plus' },
  { id: 6, name: 'Monthly Premium' },
  { id: 7, name: 'Annual Premium' },
];

export const AdminUsersList = () => {
  const [filterUsersValue] = useState<string>('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [isDownloading, setIsDownloading] = useState<{ downloading: boolean; usersProfile: boolean }>({
    downloading: false,
    usersProfile: false,
  });
  const [isLoader, setIsLoader] = useState<boolean>(false);

  // PAGINATION SETTINGS
  const [isOpenPaginationDropDown, setIsOpenPaginationDropDown] = useState<boolean>(false);
  const [isOpenTypeOfSubscription, setIsOpenTypeOfSubscription] = useState<boolean>(false);
  const [isOpenSubscriptionStatus, setIsOpenSubscriptionStatus] = useState<boolean>(false);
  const [selectedPerPage, setSelectedPerPage] = useState<number>(50);
  const [paginationSkip, setPaginationSkip] = useState<number>(0);
  const [paginationPage, setPaginationPage] = useState<number>(1);
  const [countAllUsers, setCountAllUsers] = useState<number>(0);
  const [countArrayPaginationPages, setCountArrayPaginationPages] = useState<Array<number>>([]);
  const [countPaginationPages, setCountPaginationPages] = useState<number>(1);
  const [sortBy, setSortBy] = useState('default');
  const [searchValue, setSearchValue] = useState('');
  const [descOrAsc, setDescOrAsc] = useState('');
  const [typesOfSubscription, setTypesOfSubscription] = useState<Array<number>>([]);
  const [subscriptionStatuses, setSubscriptionStatuses] = useState<Array<string>>([]);
  const [clickedNext, setClickedNext] = useState(true);
  const [clickedPrev, setClickedPrev] = useState(false);

  // REFS FOR THREE POPUPS IN PAGINATION COLUMNS
  const selectedPerPageRef = useRef(null);
  const typeOfSubscriptionRef = useRef(null);
  const subscriptionStatusRef = useRef(null);
  const navigate = useNavigate();

  async function fetchUsersWithLimit() {
    const all_users: any = [];
    try {
      const res: any = await usersService.endpoint_get_all_users(
        selectedPerPage,
        paginationSkip,
        sortBy,
        searchValue,
        descOrAsc,
        typesOfSubscription.join('|'),
        `${subscriptionStatuses[0] || ''}|${subscriptionStatuses[1] || ''}|${subscriptionStatuses[2] || ''}|${
          subscriptionStatuses[3] || ''
        }`,
      );
      all_users.push(...(res.data.rows || []));
      setCountAllUsers(res.data.count || 0);
    } catch (error) {
      console.error(error);
    }

    setFilteredUsers(all_users);
    setIsLoader(false);
  }

  useEffect(() => {
    setIsLoader(true);
  }, []);

  useEffect(() => {
    fetchUsersWithLimit();
    getCountPaginationPages();
  }, [
    paginationSkip,
    selectedPerPage,
    sortBy,
    searchValue,
    descOrAsc,
    typesOfSubscription,
    subscriptionStatuses,
    countAllUsers,
    paginationPage,
  ]);

  const downloadUsers = async (usersProfile: boolean) => {
    const date = moment();
    const formattedDate = date.format('MM-DD-YYYY');
    const replacedDate = formattedDate.replace(/-/g, '_');
    setIsDownloading({ downloading: true, usersProfile });
    const { data } = await usersService.endpoint_download_users(
      usersProfile ? 'yes' : 'no',
      searchValue,
      typesOfSubscription.join('|'),
      `${subscriptionStatuses[0] || ''}|${subscriptionStatuses[1] || ''}|${subscriptionStatuses[2] || ''}|${
        subscriptionStatuses[3] || ''
      }`,
    );
    const href = URL.createObjectURL(new Blob([data]));
    const link = document.createElement('a');
    link.href = href;
    link.setAttribute('download', `${usersProfile ? 'user’s profile_' : 'users_'}${replacedDate}.csv`);
    document.body.appendChild(link);
    link.click();

    document.body.removeChild(link);
    URL.revokeObjectURL(href);
    setIsDownloading({ downloading: false, usersProfile: false });
  };

  const dateFormat = (dateString: string): string => {
    if (!dateString) return 'N/A';

    const inputDate = new Date(dateString);

    if (Number.isNaN(inputDate.getTime())) {
      // Invalid date
      return 'Invalid Date';
    }

    const date = inputDate.toISOString().split('T')[0];
    const time = inputDate.toISOString().split('T')[1].split('.')[0];

    return `${date} ${time}`;
  };

  const handleInputChange = (event: { target: { value: string } }) => {
    const { value } = event.target;
    setIsLoader(true);
    setSearchValue(value);
    setSortBy('default');
    setPaginationPage(1);
    setIsLoader(false);
  };

  useEffect(() => {
    const handleClickOutsideMenuSubscriptionStatus = (event) => {
      const subscriptionStatus: any = subscriptionStatusRef.current;

      if (subscriptionStatus && !subscriptionStatus.contains(event.target)) {
        setIsOpenSubscriptionStatus(false);
      }
    };
    const handleClickOutsideMenuTypeOf = (event) => {
      const typeOf: any = typeOfSubscriptionRef.current;

      if (typeOf && !typeOf.contains(event.target)) {
        setIsOpenTypeOfSubscription(false);
      }
    };
    const handleClickOutsideMenu = (event) => {
      const perPageRef: any = selectedPerPageRef.current;

      if (perPageRef && !perPageRef.contains(event.target)) {
        setIsOpenPaginationDropDown(false);
      }
    };

    document.addEventListener('click', handleClickOutsideMenuSubscriptionStatus);
    document.addEventListener('click', handleClickOutsideMenuTypeOf);
    document.addEventListener('click', handleClickOutsideMenu);
    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
      document.removeEventListener('click', handleClickOutsideMenuTypeOf);
      document.removeEventListener('click', handleClickOutsideMenuSubscriptionStatus);
    };
  }, []);

  const getPaginationSkip = (numberPage: number): void => {
    const countSkipUsers = (numberPage - 1) * selectedPerPage;
    setPaginationSkip(countSkipUsers);
    setPaginationPage(1);
  };

  const getCountPaginationPages = (): void => {
    const roundedCountPages = Math.ceil(countAllUsers / selectedPerPage);

    setCountPaginationPages(roundedCountPages);
    const readyArray: any = [];
    for (let i = 1; i <= roundedCountPages; i++) {
      readyArray.push(i);
    }
    const resultPage = checkOrPageIsFourMore(paginationPage);
    if (resultPage) {
      if (clickedNext && !clickedPrev) {
        setCountArrayPaginationPages([
          paginationPage,
          paginationPage + 1,
          paginationPage + 2,
          paginationPage + 3,
          paginationPage + 4,
        ]);
        return;
      }

      setCountArrayPaginationPages([
        paginationPage - 4,
        paginationPage - 3,
        paginationPage - 2,
        paginationPage - 1,
        paginationPage,
      ]);
      return;
    }

    if (paginationPage <= 5) {
      setCountArrayPaginationPages([1, 2, 3, 4, 5]);
    }
  };

  const checkOrPageIsFourMore = (num: number): boolean => {
    for (let i = 5; i <= 5000; i += 4) {
      if (num === i) {
        return true;
      }
    }
    return false;
  };

  const typeOfSubscriptionFilters = (e, id) => {
    e.stopPropagation();
    if (typesOfSubscription.includes(id) && typesOfSubscription.length === 1) {
      setTypesOfSubscription([]);
      setSortBy('default');
      setPaginationPage(1);
      return;
    }
    if (typesOfSubscription.includes(id)) {
      const newArray = typesOfSubscription.filter((value) => value !== id);
      setTypesOfSubscription(newArray);
      setSortBy('typeOfSubscription');
      setPaginationPage(1);
    } else {
      setTypesOfSubscription([...typesOfSubscription, id]);
      setSortBy('typeOfSubscription');
      setPaginationPage(1);
    }
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <div className="admin-users-wrapper" style={{ overflowY: 'hidden' }}>
      <div className="admin-users-inner">
        <NavLink to={'/dashboard'} className="admin-backups-nav-dashboard">
          <img src={arrowBackBlue} alt="arrow back blue" />
          <p>Back to admin dashboard</p>
        </NavLink>
        <header className="admin-users-header">
          <div className="admin-users-header-row">
            <div className="admin-users-header-row-left">
              <div className="users-list-title">
                <img src={userIcon} alt="users" />
                Users
              </div>
              <NavLink className="users-list__create-user" to={'/admin-user-profile'}>
                <img className="users-list__create-user__img" src={plusViolet} alt="plus" />
                <p className="users-list__create-user__text">CREATE USER</p>
              </NavLink>
            </div>

            <div className="users-title-right">
              <div className="table-header-buttons">
                <button
                  disabled={isDownloading.usersProfile && isDownloading.downloading}
                  onClick={() => downloadUsers(true)}
                >
                  {isDownloading.downloading && isDownloading.usersProfile ? (
                    <div className="lds-ellipsis">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  ) : (
                    'Download User’s Profile'
                  )}
                </button>
                <button
                  disabled={!isDownloading.usersProfile && isDownloading.downloading}
                  onClick={() => downloadUsers(false)}
                >
                  {isDownloading.downloading && !isDownloading.usersProfile ? (
                    <div className="lds-ellipsis">
                      <div />
                      <div />
                      <div />
                      <div />
                    </div>
                  ) : (
                    'EXPORT AS CSV'
                  )}
                </button>
              </div>
            </div>
          </div>

          <div className="admin-users-search-input-wrapper">
            <div className="admin-users-search-input-wrapper-main-cont">
              <div className="admin-users-search-input-wrapper-cont">
                <DebounceInput
                  className="admin-users-search-input"
                  value={filterUsersValue}
                  onChange={handleInputChange}
                  type="text"
                  placeholder="Search"
                  minLength={1}
                  debounceTimeout={1000}
                  disabled={isLoader}
                />

                <img src={search} alt="search" />
              </div>
              <button
                className="admin-users-clear-filters"
                onClick={(e) => {
                  e.stopPropagation();
                  setTypesOfSubscription([]);
                  setSubscriptionStatuses([]);
                  setSortBy('default');
                  setPaginationPage(1);
                }}
              >
                Clear Filters
              </button>
            </div>
            <div className="admin-users-pagination">
              <span
                className="admin-users-pagination-box admin-users-pagination-box-previous"
                onClick={() => {
                  if (paginationPage === 1) return;
                  getPaginationSkip(paginationPage - 1);
                  setPaginationPage(paginationPage - 1);
                  setClickedPrev(true);
                  setClickedNext(false);
                }}
              >
                <img className="admin-users-pagination-box-previous-img" src={arrowPagination} alt="arrow sort" />
              </span>
              {countArrayPaginationPages.map((el, i) => {
                return (
                  <span
                    key={i}
                    onClick={() => {
                      if (el < paginationPage) {
                        getPaginationSkip(el);
                        setPaginationPage(el);
                        return;
                      }
                      if (el > countPaginationPages) return;
                      getPaginationSkip(el);
                      setPaginationPage(el);
                    }}
                    className={
                      paginationPage === el
                        ? 'admin-users-pagination-box admin-users-pagination-box-selected'
                        : 'admin-users-pagination-box'
                    }
                  >
                    {el}
                  </span>
                );
              })}
              <span
                className="admin-users-pagination-box admin-users-pagination-box-next"
                onClick={() => {
                  if (paginationPage === countPaginationPages) return;
                  getPaginationSkip(paginationPage + 1);
                  setPaginationPage(paginationPage + 1);
                  setClickedPrev(false);
                  setClickedNext(true);
                }}
              >
                <img className="admin-users-pagination-box-next-img" src={arrowPagination} alt="arrow sort" />
              </span>
              <div
                ref={selectedPerPageRef}
                className="admin-users-pagination-drop-down"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpenPaginationDropDown(!isOpenPaginationDropDown);
                  setIsOpenTypeOfSubscription(false);
                  setIsOpenSubscriptionStatus(false);
                }}
              >
                <input
                  readOnly={true}
                  type="text"
                  placeholder={`${selectedPerPage} Per Page`}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenPaginationDropDown(!isOpenPaginationDropDown);
                    setIsOpenTypeOfSubscription(false);
                    setIsOpenSubscriptionStatus(false);
                  }}
                  className="admin-users-pagination-drop-down-input"
                />
                <img
                  src={arrowPagination}
                  alt="arrow"
                  id="state"
                  className={
                    isOpenPaginationDropDown
                      ? 'admin-users-pagination-drop-down-arrow admin-users-pagination-drop-down-arrow-up'
                      : 'admin-users-pagination-drop-down-arrow admin-users-pagination-drop-down-arrow-down'
                  }
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenPaginationDropDown(!isOpenPaginationDropDown);
                    setIsOpenTypeOfSubscription(false);
                    setIsOpenSubscriptionStatus(false);
                  }}
                />
                {isOpenPaginationDropDown && (
                  <div className="admin-users-pagination-drop-down-select">
                    <span
                      onClick={() => {
                        setSelectedPerPage(50);
                        setPaginationPage(1);
                        setPaginationSkip(0);
                      }}
                      className={
                        selectedPerPage === 50
                          ? 'admin-users-pagination-drop-down-select-page admin-users-pagination-drop-down-select-page-selected'
                          : 'admin-users-pagination-drop-down-select-page'
                      }
                    >
                      50 Per Page
                    </span>
                    <span
                      onClick={() => {
                        setSelectedPerPage(100);
                        setPaginationPage(1);
                        setPaginationSkip(0);
                      }}
                      className={
                        selectedPerPage === 100
                          ? 'admin-users-pagination-drop-down-select-page admin-users-pagination-drop-down-select-page-selected'
                          : 'admin-users-pagination-drop-down-select-page'
                      }
                    >
                      100 Per Page
                    </span>
                    <span
                      onClick={() => {
                        setSelectedPerPage(200);
                        setPaginationPage(1);
                        setPaginationSkip(0);
                      }}
                      className={
                        selectedPerPage === 200
                          ? 'admin-users-pagination-drop-down-select-page admin-users-pagination-drop-down-select-page-selected'
                          : 'admin-users-pagination-drop-down-select-page'
                      }
                    >
                      200 Per Page
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </header>
        <div className="users-table">
          {isLoader && (
            <div className="admin-users-list-loader">
              <SyncLoader
                className="payment-loading"
                color="var(--headings-blue)"
                margin={7}
                size={20}
                speedMultiplier={0.7}
              />
            </div>
          )}
          {!isLoader && (
            <div className="users-grid">
              <div className="admin-users-grid">
                <div className="admin-users-grid-header">
                  <span className="admin-users-grid-header__id">ID</span>
                  <span className="user-sub-name-header">Name</span>
                  <span className="user-sub-email-header">Email</span>
                  <div>
                    <span
                      ref={subscriptionStatusRef}
                      className="user-sub-status-header"
                      onClick={() => {
                        setIsOpenSubscriptionStatus(!isOpenSubscriptionStatus);
                        setIsOpenPaginationDropDown(false);
                        setIsOpenTypeOfSubscription(false);
                      }}
                    >
                      Subscription status
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionStatus');
                            setDescOrAsc('desc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionStatus');
                            setPaginationPage(1);
                            setDescOrAsc('asc');
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                      {isOpenSubscriptionStatus && (
                        <div className="admin-users-pagination-drop-down-select admin-users-pagination-drop-down-select-statuses">
                          <div className="admin-users-pagination-drop-down-select-container-btn">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setSubscriptionStatuses([]);
                                setSortBy('default');
                                setPaginationPage(1);
                              }}
                              className="admin-users-pagination-drop-down-select-btn admin-users-pagination-drop-down-select-btn-reset"
                            >
                              RESET
                            </button>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                if (subscriptionStatuses.includes('active') && subscriptionStatuses.length === 1) {
                                  setSubscriptionStatuses([]);
                                  setSortBy('default');
                                  setPaginationPage(1);
                                  return;
                                }
                                if (subscriptionStatuses.includes('active')) {
                                  const newArray = subscriptionStatuses.filter((value) => value !== 'active');
                                  setSubscriptionStatuses(newArray);
                                } else {
                                  setSubscriptionStatuses([...subscriptionStatuses, 'active']);
                                }
                                setSortBy('subscriptionStatus');
                                setPaginationPage(1);
                              }}
                              className={
                                subscriptionStatuses.includes('active')
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {subscriptionStatuses.includes('active') && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">Active</p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                if (subscriptionStatuses.includes('expired') && subscriptionStatuses.length === 1) {
                                  setSubscriptionStatuses([]);
                                  setSortBy('default');
                                  setPaginationPage(1);
                                  return;
                                }
                                if (subscriptionStatuses.includes('expired')) {
                                  const newArray = subscriptionStatuses.filter((value) => value !== 'expired');
                                  setSubscriptionStatuses(newArray);
                                } else {
                                  setSubscriptionStatuses([...subscriptionStatuses, 'expired']);
                                }
                                setSortBy('subscriptionStatus');
                                setPaginationPage(1);
                              }}
                              className={
                                subscriptionStatuses.includes('expired')
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {subscriptionStatuses.includes('expired') && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">Expired</p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                if (subscriptionStatuses.includes('canceled') && subscriptionStatuses.length === 1) {
                                  setSubscriptionStatuses([]);
                                  setSortBy('default');
                                  setPaginationPage(1);
                                  return;
                                }
                                if (subscriptionStatuses.includes('canceled')) {
                                  const newArray = subscriptionStatuses.filter((value) => value !== 'canceled');
                                  setSubscriptionStatuses(newArray);
                                } else {
                                  setSubscriptionStatuses([...subscriptionStatuses, 'canceled']);
                                }
                                setSortBy('subscriptionStatus');
                                setPaginationPage(1);
                              }}
                              className={
                                subscriptionStatuses.includes('canceled')
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {subscriptionStatuses.includes('canceled') && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Cancelled
                            </p>
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                  <div>
                    <span
                      ref={typeOfSubscriptionRef}
                      className="type-of-subscription"
                      onClick={(e) => {
                        e.stopPropagation();
                        setIsOpenTypeOfSubscription(!isOpenTypeOfSubscription);
                        setIsOpenPaginationDropDown(false);
                        setIsOpenSubscriptionStatus(false);
                      }}
                    >
                      Type of Subscription
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('typeSubscription');
                            setDescOrAsc('desc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('typeSubscription');
                            setPaginationPage(1);
                            setDescOrAsc('asc');
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                      {isOpenTypeOfSubscription && (
                        <div className="admin-users-pagination-drop-down-select admin-users-pagination-drop-down-select-type-of-subscription">
                          <div className="admin-users-pagination-drop-down-select-container-btn">
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                setTypesOfSubscription([]);
                                setSortBy('default');
                                setPaginationPage(1);
                              }}
                              className="admin-users-pagination-drop-down-select-btn admin-users-pagination-drop-down-select-btn-reset"
                            >
                              RESET
                            </button>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 1);
                              }}
                              className={
                                typesOfSubscription.includes(1)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(1) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Annual Old
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 3);
                              }}
                              className={
                                typesOfSubscription.includes(3)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(3) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Annual Basic
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 2);
                              }}
                              className={
                                typesOfSubscription.includes(2)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(2) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Monthly Basic
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 5);
                              }}
                              className={
                                typesOfSubscription.includes(5)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(5) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Annual Plus
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 4);
                              }}
                              className={
                                typesOfSubscription.includes(4)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(4) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Monthly Plus
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 7);
                              }}
                              className={
                                typesOfSubscription.includes(7)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(7) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Annual Premium
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 6);
                              }}
                              className={
                                typesOfSubscription.includes(6)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(6) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Monthly Premium
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 8);
                              }}
                              className={
                                typesOfSubscription.includes(8)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(8) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">
                              Internal
                            </p>
                          </div>
                          <div className="admin-users-pagination-drop-down-select-type-of-subscription-cont-text">
                            <span
                              onClick={(e) => {
                                typeOfSubscriptionFilters(e, 9);
                              }}
                              className={
                                typesOfSubscription.includes(9)
                                  ? 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark admin-users-pagination-drop-down-select-type-of-subscription-checkmark-selected'
                                  : 'admin-users-pagination-drop-down-select-type-of-subscription-checkmark'
                              }
                            >
                              {typesOfSubscription.includes(9) && (
                                <img
                                  src={checkmark}
                                  alt="checkmark"
                                  className="admin-users-pagination-drop-down-select-type-of-subscription-checkmark-img"
                                />
                              )}
                            </span>
                            <p className="admin-users-pagination-drop-down-select-type-of-subscription-text">N/A</p>
                          </div>
                        </div>
                      )}
                    </span>
                  </div>
                  <div className="admin-users-grid-header__registration-date">
                    <span className="admin-users-grid-header__registration-date__inside">
                      Registration Date
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('registrationDate');
                            setPaginationPage(1);
                            setDescOrAsc('desc');
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('registrationDate');
                            setDescOrAsc('asc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>
                      Subscription Start Date
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionStart');
                            setDescOrAsc('desc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionStart');
                            setDescOrAsc('asc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>
                      Subscription Cancellation Date
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionCanceled');
                            setDescOrAsc('desc');
                            setPaginationPage(1);
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionCanceled');
                            setPaginationPage(1);
                            setDescOrAsc('asc');
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                    </span>
                  </div>
                  <div>
                    <span>
                      Subscription Expire Date
                      <div className="admin-users-grid-header-container-sort">
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionEnd');
                            setPaginationPage(1);
                            setDescOrAsc('desc');
                          }}
                          className="admin-users-grid-header-container-sort-img"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                        <img
                          onClick={(e) => {
                            e.stopPropagation();
                            setSortBy('subscriptionEnd');
                            setPaginationPage(1);
                            setDescOrAsc('asc');
                          }}
                          className="admin-users-grid-header-container-sort-img rotate"
                          src={arrowSort}
                          alt="arrow-sort"
                        />
                      </div>
                    </span>
                  </div>
                </div>
                {filteredUsers?.length > 0 &&
                  filteredUsers.map((user: any) => (
                    <div
                      className="admin-users-grid-row"
                      key={user?.id}
                      onClick={() => navigate('/admin-user-profile', { state: { user } })}
                    >
                      <span>{user?.id}</span>
                      <span className="admin-users-grid-row__name">
                        {user?.name?.split(' ')[0]} {user?.role === 'admin' ? <span>Admin</span> : ''}
                      </span>
                      <span>{user?.email}</span>
                      <span
                        className={`user-sub-status ${
                          user && !user.subscription?.length
                            ? 'n-a'
                            : user?.statusSubscription === 'canceled'
                            ? 'cancel'
                            : user.statusSubscription === 'expired'
                            ? 'expired'
                            : 'active'
                        }`}
                      >
                        {!user.subscription?.length
                          ? 'N/A'
                          : user?.statusSubscription === 'canceled'
                          ? 'CANCELLED'
                          : user.statusSubscription === 'expired'
                          ? 'EXPIRED'
                          : 'ACTIVE'}
                      </span>
                      <span className="user-sub-type">
                        {user.subscription?.length
                          ? user.subscription[user.subscription.length - 1].subscriptionId
                            ? packages.find((p) => p.id === user.subscription[user.subscription.length - 1].packageId)
                                ?.name
                            : 'Internal'
                          : 'N/A'}
                      </span>
                      <span>{dateFormat(user?.createdAt)}</span>
                      <span>{dateFormat(user?.createdAtSubscription)}</span>
                      <span>{dateFormat(user?.canceledAtSubscription)}</span>
                      <span>{dateFormat(user?.expiredAtSubscription)}</span>
                    </div>
                  ))}
                {filteredUsers?.length === 0 && (
                  <div className="admin-users-grid-no-result">
                    <h4 className="admin-users-grid-no-result-text">
                      There is no result for these parameters. Try to change them
                    </h4>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
