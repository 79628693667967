import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useDispatch } from 'react-redux';
import { previousRouteAction } from '../../store/actions';

export const RequirePay = () => {
  const dispatch = useDispatch();
  const { loginWithRedirect } = useAuth0();
  useEffect(() => {
    dispatch(previousRouteAction.setPreviousRoute('/require-pay'));
    loginWithRedirect({ screen_hint: 'signup' });
  }, []);
  return <div />;
};
