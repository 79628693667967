/* eslint-disable */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment/moment';
import { clsx } from 'clsx';
import { ICollege, IMyList, IRootState } from '../../../models';
import styles from './CollegeCard.module.css';
import locationPurpleIcon from '../../../images/Location_purple.svg';
import defaultCollegePic from '../../../images/default-college.png';
import { AddListModal, Autocomplete, UniversalModal, SortCategory } from '../../../components';
import { useClickOutside } from '../../../hooks/useClickOutside';
import { myListService } from '../../../services';
import { myListsAction } from '../../../store/actions';
import lockIcon from '../../../images/locked-icon-blue.svg';

const meritCategories = [
  { id: 1, name: 'Freshmen w/out Need Receiving Merit', property: 'pcStudentsReceivingMeritAid' },
  { id: 2, name: 'Avg Merit Aid', property: 'avgMeritAidAward' },
];

const staticSortingFields: string[] = ['avgFreshmenGpa', 'coaPrivate', 'coaOutState', 'coaInState'];

interface CollegeCardProps {
  college: ICollege;
  sortedByValue: SortCategory | undefined;
  isBlurred: boolean | false;
}

export const CollegeCard: React.FC<CollegeCardProps> = ({ college, sortedByValue, isBlurred }) => {
  const { user, adminNavigatedUser } = useSelector((state: IRootState) => state.userState);
  const { myLists } = useSelector((state: IRootState) => state.myListsState);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [userLists, setUserLists] = useState<IMyList[]>(myLists);
  const [isInList, setIsInList] = useState<boolean>(false);
  const [allowedCreateList, setAllowedCreateList] = useState<boolean>(true);
  const [error, setError] = useState<string | undefined>();
  const [readyCollegeIdsForAdding, setReadyCollegeIdsForAdding] = useState<number[]>([]);

  const [isAddToListsSelectActive, setIsAddToListsSelectActive] = useState<boolean>(false);

  const addToListsSelectRef = useRef<HTMLSpanElement | null>(null);

  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  useEffect(() => {
    if (userWithoutSubscription() && myLists.length > 1) {
      setAllowedCreateList(false);
      return;
    }
    setAllowedCreateList(true);
  }, []);

  useEffect(() => {
    setUserLists([{ id: -1, name: 'Create New List', userId: -1, colleges: [] }, ...myLists]);
  }, [myLists]);

  const userWithoutSubscription = (): boolean => !user?.subscription && user?.role !== 'admin';

  const formatDate = (str: string) => {
    if (!str) return '-';
    return moment(str, 'YYYY-MM-DD').format('MMM DD');
  };

  useEffect(() => {
    // @ts-ignore
    const isInLists = myLists.some((list) => list.colleges.some((c) => c.collegeId === college.id));
    setIsInList(isInLists);
  }, [college, myLists]);

  const saveOrRemoveFromMyList = (id: number) => {
    const list = myLists.find((l: IMyList) => l.colleges.find((c) => c.id === id));
    if (list) {
      dispatch(myListsAction.removeCollegeFromMyList({ userId: list.userId, listId: list.id, collegeId: id }));
    } else {
      setReadyCollegeIdsForAdding([id]);
    }
  };

  const addToList = (listName: string) => {
    dispatch(
      myListsAction.addCollegesToMyList({
        userId: (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
        collegesIds: readyCollegeIdsForAdding,
        listName,
      }),
    );
    dispatch(myListsAction.getMyLists((adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number));
  };

  const selectListMyList = (list: IMyList) => {
    if (userWithoutSubscription() && list.id === -1) {
      setAllowedCreateList(false);
      open();
      return;
    }

    if (list.id === -1) {
      open();
    } else {
      addToList(list.name);
    }
  };

  const createList = async (listName: string) => {
    try {
      setError(undefined);
      await myListService.endpoint_create_my_list({
        userId: (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
        listName,
      });
      const { data } = await myListService.endpoint_get_my_lists(
        (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
      );
      dispatch(myListsAction.setMyLists(data));
      selectListMyList(data[data.length - 1]);
      close();
      setIsInList(true);
    } catch (e: any) {
      setError(e.response.data.message);
    }
  };

  const goToCollegeDetails = (id: number) => {
    navigate(`/college-details/${id}`, { state: pathname });
  };

  useClickOutside(addToListsSelectRef, [], () => {
    if (isAddToListsSelectActive) setIsAddToListsSelectActive(false);
  });

  const getPropertySign = (sortedByValue) => {
    return (!Number.isNaN(+college[sortedByValue.property]) || college[sortedByValue.property]) &&
      sortedByValue.property !== 'collegeMajor'
      ? ['fourYearGraduationRate', 'admissionsRate', 'edAdmitRate', 'eaAdmitRate', 'avgPcNeedMet'].includes(
          sortedByValue.property,
        )
        ? `${(+college[sortedByValue.property]).toFixed(0)}%`
        : ['outStateNetCost', 'inStateNetCost', 'privateNetCost'].includes(sortedByValue.property)
        ? `$${college[sortedByValue.property].toLocaleString('en-US')}`
        : ['fullTimeUndergraduates'].includes(sortedByValue.property)
        ? `${(+college[sortedByValue.property]).toLocaleString('en-US')}`
        : ['edFilledRate'].includes(sortedByValue.property)
        ? `${Math.round(+college[sortedByValue.property]).toFixed(0)}%`
        : ['actAvg', 'satAvg', 'act75thPc', 'sat75thPc', 'avgFreshmenGpa'].includes(sortedByValue.property)
        ? +college[sortedByValue.property]
        : [['edDeadline', 'eaDeadline', 'ed2Deadline'].includes(sortedByValue.property)]
        ? formatDate(college[sortedByValue.property])
        : sortedByValue.property === 'pcStudentsWithoutNeed'
        ? // @ts-ignore
          college?.pcStudentsWithoutNeed
        : college[sortedByValue.property]
      : sortedByValue.property === 'collegeMajor'
      ? college[sortedByValue.property]?.length > 3
        ? college[sortedByValue.property]?.slice(0, 3).map(
            (collegeFromMajor) =>
              // @ts-ignore
              user?.majors?.map((major) => major?.name === collegeFromMajor?.major?.name) &&
              `${collegeFromMajor?.major?.name} `,
          )
        : college[sortedByValue.property]?.map(
            (collegeFromMajor) =>
              // @ts-ignore
              user?.majors?.map((major) => major?.name === collegeFromMajor?.major?.name) &&
              `${collegeFromMajor?.major?.name} `,
          )
      : 'N/A';
  };

  return (
    <div className={styles.container}>
      <img
        loading="lazy"
        alt="College"
        src={college.hasImage ? `${process.env.REACT_APP_COLLEGE_IMAGES}${college?.ipeds}.png` : defaultCollegePic}
        className={styles.picture}
      />
      <span
        ref={addToListsSelectRef}
        onClick={(event) => {
          event.stopPropagation();
          saveOrRemoveFromMyList(college.id);
          setIsAddToListsSelectActive(!isAddToListsSelectActive);
        }}
        className={clsx(styles.plusIcon, isInList && styles.selected)}
      >
        {isAddToListsSelectActive && (
          <Autocomplete
            isBudgetItems={false}
            filteredItem={userLists}
            onclick={(list) => {
              selectListMyList(list);
              setIsAddToListsSelectActive(false);
            }}
            isAddToListAutocomplete={true}
            classN={styles.autocomplete}
          />
        )}
      </span>
      <div onClick={() => goToCollegeDetails(college.id)} className={styles.detailsContainer}>
        <div className={styles.mainInfo__Container}>
          <h5 className={styles.name}>{college.name}</h5>
          <div className={styles.locationWrapper}>
            <img src={locationPurpleIcon} alt="Location icon" className={styles.locationIcon} draggable={false} />
            <p className={styles.locationText}>{college.state?.name}</p>
          </div>
        </div>
        <div className={styles.info__Container}>
          {college.coaPrivate && (
            <div className={styles.additionalInfo__Container}>
              Private COA
              <b className={styles.additionalInfo__Value}>${college.coaPrivate.toLocaleString('en-US')}</b>
            </div>
          )}
          {(college.coaInState || college.coaOutState) && (
            <>
              <div className={styles.additionalInfo__Container}>
                In-State COA
                <b className={styles.additionalInfo__Value}>
                  {college.coaInState ? `$${college.coaInState.toLocaleString('en-US')}` : '-'}
                </b>
              </div>
              <div className={styles.additionalInfo__Container}>
                Out-of State COA
                <b className={styles.additionalInfo__Value}>
                  {college.coaOutState ? `$${college.coaOutState.toLocaleString('en-US')}` : '-'}
                </b>
              </div>
            </>
          )}
          <div className={styles.additionalInfo__Container}>
            Avg GPA
            <b className={styles.additionalInfo__Value}>{college.avgFreshmenGpa || '-'}</b>
          </div>
          {sortedByValue?.property &&
            !staticSortingFields.includes(sortedByValue.property) &&
            (['pcStudentsReceivingMeritAid', 'avgMeritAidAward'].includes(sortedByValue.property) ? (
              meritCategories.map((prop: any) => {
                return (
                  <div key={prop.id} className={styles.additionalInfo__Container}>
                    {`${prop.name} `}
                    {prop.property === 'avgMeritAidAward' ? (
                      <b className={styles.additionalInfo__Value}>
                        {+college[prop.property] ? `$ ${(+college[prop.property]).toLocaleString('en-US')}` : '-'}
                      </b>
                    ) : (
                      <b className={styles.additionalInfo__Value}>
                        {+college[prop.property] ? `${(+college[prop.property]).toFixed(0)}%` : '-'}
                      </b>
                    )}
                  </div>
                );
              })
            ) : ['edDeadline', 'eaDeadline', 'ed2Deadline'].includes(sortedByValue.property) ? (
              <div className={clsx(styles.additionalInfo__Container, styles.additionalInfo__Container__Hidden)}>
                <div className={styles.hiddenInfo}>
                  Application Deadline <p className={styles.hiddenInfo__Secondary}>(confirm w/school website)</p>
                </div>
                {`${sortedByValue.name} `}
                {college[sortedByValue.property] ? (
                  <b className={styles.additionalInfo__Value}>{getPropertySign(sortedByValue)}</b>
                ) : (
                  <b className={styles.additionalInfo__Value}>-</b>
                )}
              </div>
            ) : (
              <div className={styles.additionalInfo__Container}>
                {`${sortedByValue.name} `}
                {college[sortedByValue.property] ? (
                  <b className={styles.additionalInfo__Value}>{getPropertySign(sortedByValue)}</b>
                ) : (
                  <b className={styles.additionalInfo__Value}>-</b>
                )}
              </div>
            ))}
        </div>
      </div>
      <Modal>
        {!allowedCreateList && (
          <UniversalModal
            close={close}
            textForTitle={'Upgrade Account'}
            textForDescription={
              'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
            }
            textSecondBtn={'Upgrade Now'}
          />
        )}

        {allowedCreateList && <AddListModal createList={createList} close={close} err={error} />}
      </Modal>
    </div>
  );
};
