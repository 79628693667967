import React from 'react';
import { clsx } from 'clsx';
import styles from './AutocompleteSelect.module.css';

interface AutocompleteSelectProps {
  label?: string;
  placeholder: string;
  inputName: string;
  isDisabledInput: boolean;
  value?: string;
  onClickHandler: () => void;
  onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
  inputStyles?: string;
}

export const AutocompleteSelect: React.FC<AutocompleteSelectProps> = ({
  label,
  placeholder,
  inputName,
  isDisabledInput,
  value,
  onClickHandler,
  onChangeHandler,
  children,
  inputStyles,
}) => {
  return (
    <div className={styles.container} onClick={onClickHandler}>
      {label && (
        <label htmlFor={inputName} className={styles.label}>
          {label}
        </label>
      )}
      <div className={styles.inputContainer}>
        <input
          readOnly={isDisabledInput}
          value={value}
          className={clsx(styles.input, inputStyles)}
          name={inputName}
          placeholder={placeholder}
          onChange={onChangeHandler}
        />
        <span className={styles.inputArrow} />
        {children}
      </div>
    </div>
  );
};
