import './Confirm-download-list-modal.css';
import { EmailShareButton } from 'react-share';
import React from 'react';

interface IConfirmModals {
  title: string;
  description?: string;
}

const confirmFirst: IConfirmModals = {
  title: 'Confirm download',
};

const confirmSecond: IConfirmModals = {
  title: 'Confirm',
  description: 'Please confirm you are ready to download the selected colleges and data.',
};
export const ConfirmDownloadListModal = ({
  close,
  stepDownloading,
  setStepDownloading,
  onHandleDownload,
  clearValuesAfterDownload,
  countDownloadCsv,
}: {
  close: Function;
  stepDownloading: number;
  setStepDownloading: Function;
  onHandleDownload: Function;
  clearValuesAfterDownload: Function;
  countDownloadCsv: number;
}) => {
  return (
    <section className="confirm-download-list-modal">
      {stepDownloading === 3 && (
        <>
          <div className="confirm-download-list-modal__up">
            <p className="confirm-download-list-modal__title">{confirmFirst.title}</p>
            <span className="confirm-download-list-modal__close" onClick={() => close()} />
          </div>
          <p className="confirm-download-list-modal__description">
            Your subscription includes {countDownloadCsv} download{countDownloadCsv > 1 ? 's' : null}. Please email
            <span className="confirm-download-list-modal__description--first__main">
              <EmailShareButton
                url={'support@road2college.com'}
                subject={'Support'}
                className="confirm-download-list-modal__description--first__main__support"
              >
                {`support@road2college.com `}
              </EmailShareButton>
            </span>
            {`if you'd like to purchase additional downloads.`}
          </p>
        </>
      )}
      {stepDownloading === 4 && (
        <>
          <div className="confirm-download-list-modal__up">
            <p className="confirm-download-list-modal__title">{confirmSecond.title}</p>
            <span className="confirm-download-list-modal__close" onClick={() => close()} />
          </div>
          <p className="confirm-download-list-modal__description">{confirmSecond.description}</p>
        </>
      )}
      <div className="confirm-download-list-modal__cont-btn">
        <button
          className="confirm-download-list-modal__btn confirm-download-list-modal__btn--cancel"
          onClick={() => {
            setStepDownloading(2);
          }}
        >
          Cancel
        </button>
        <button
          onClick={() => {
            if (stepDownloading === 3) {
              setStepDownloading(4);
              return;
            }

            onHandleDownload();
            close();
            setStepDownloading(1);
            clearValuesAfterDownload();
          }}
          className="confirm-download-list-modal__btn confirm-download-list-modal__btn--confirm"
        >
          Confirm
        </button>
      </div>
    </section>
  );
};
