import './UnsubscribeModal.css';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import cross from '../../images/cross.svg';
import unsubscribe from '../../images/unsubscribe-icon.svg';
import { userAction } from '../../store/actions';
import { subscriptionService } from '../../services/subscription.service';

export const UnsubscribeModal = ({
  close,
  plan,
  withConfirm = false,
}: {
  close: () => void;
  plan: any;
  withConfirm?: boolean;
}) => {
  const dispatch = useDispatch();
  const { user } = useSelector((state: any) => state.userState);
  const [expiresDate, setExpiresDate] = useState<string>('');
  const [confirm, setConfirm] = useState<boolean>(false);

  const monthNames: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  useEffect(() => {
    if (user && user?.subscription && user?.subscription?.expiresAt) {
      const date = new Date(user?.subscription?.expiresAt);
      const expiresDate = `${monthNames[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
      setExpiresDate(expiresDate);
    }
  }, []);

  useEffect(() => {
    setConfirm(false);
  }, []);

  const removeSubscription = () => {
    if (withConfirm && !confirm) {
      setConfirm(true);
      return;
    }

    const callBack = async () => {
      await subscriptionService.endpoint_cancel_subscription(user.id);
    };
    callBack();
    dispatch(userAction.setUser({ ...user, canceledSubscription: true }));
    const body = {
      email: user.email,
      hadSubscription: true,
      canceledAtSubscription: new Date(),
      canceledSubscription: true,
      statusSubscription: 'canceled',
    };
    dispatch(userAction.updateUser({ user: body }));
    close();
  };

  return (
    <div className="modal-unsubscribe">
      {!confirm ? (
        <>
          <img onClick={close} src={cross} alt="cross" />
          <h1>Are you sure you want to Unsubscribe ?</h1>
          <p>Unsubscribe, you will not get:</p>
          <div className="unsubscribe-body">
            {plan.options.map((o: string) => (
              <div key={o}>
                <img src={unsubscribe} alt="cross" />
                <h1>{o}</h1>
              </div>
            ))}
          </div>
          <div>
            <button type="button" className="cancel-button" onClick={close}>
              Cancel
            </button>
            <button type="button" className="unsubscribe-modal-button" onClick={removeSubscription}>
              Confirm
            </button>
          </div>
        </>
      ) : (
        <>
          <img onClick={close} src={cross} alt="cross" />
          <h1>Sorry to see you go!</h1>
          <p>
            Sorry to see you go. Your subscription will cancel at the end of your billing cycle. You will have access
            until {expiresDate}.
          </p>
          <div>
            <button type="button" className="unsubscribe-modal-button" onClick={removeSubscription}>
              Ok
            </button>
          </div>
        </>
      )}
    </div>
  );
};
