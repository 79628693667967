import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  AdminUploadCsvs,
  AdminUsersList,
  HeaderAdmin,
  AdminDashboard,
  AdminUserProfile,
  MyList,
  HomePage,
  CollegeDetails,
  AdminBackups,
  BuyPlanPage,
  Membership,
  MarketingPage,
  MyProfile,
  AdminConfiguration,
  PowerSearch,
  SharedList,
  Footer,
} from '../components';
import { myListsAction, userAction } from '../store/actions';
import { ServicesComponent } from '../components/services/services-component';

interface AdminNavigatorProps {}

export const AdminNavigator: React.FC<AdminNavigatorProps> = () => {
  const { user } = useSelector((state: any) => state.userState);
  const dispatch = useDispatch();

  React.useEffect(() => {
    dispatch(myListsAction.getMyLists(user.id));
  }, []);

  return (
    <>
      <HeaderAdmin />
      <Routes>
        <Route path={'/admin/uploads'} element={<AdminUploadCsvs />} />
        <Route path={'/admin-user-profile'} element={<AdminUserProfile />} />
        <Route path={'/backups'} element={<AdminBackups />} />
        <Route path={'/dashboard'} element={<AdminDashboard />} />
        <Route path={'/users'} element={<AdminUsersList />} />
        <Route path={'/configuration'} element={<AdminConfiguration />} />
        {/* user */}
        <Route path="/my-profile" element={<MyProfile />} />
        <Route path="/marketing-page" element={<MarketingPage />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/buy-plan" element={<BuyPlanPage />} />
        <Route path="/power-search" element={<PowerSearch />} />
        <Route path="/home" element={<HomePage />} />
        <Route path="/my-lists" element={<MyList />} />
        <Route path="/my-lists/:id" element={<MyList />} />
        <Route path="/college-details/:id" element={<CollegeDetails />} />
        <Route path={`/shared-lists/:id`} element={<SharedList />} />
        <Route path="/*" element={<Navigate replace to={'/home'} />} />
        <Route path="/services" element={<ServicesComponent />} />
      </Routes>
      <Footer />
    </>
  );
};
