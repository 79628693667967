import './ScholarshipModal.css';
import { NavLink } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../images/cross.svg';
import { previousRouteAction } from '../../store/actions';

export const ScholarshipModal = ({
  close,
  message,
  title,
  textButton = 'Go Back',
  setOk,
  placeUsingThisModal,
}: {
  close: any;
  message?: string;
  title: string;
  textButton?: string;
  setOk?: any;
  placeUsingThisModal?: string;
}) => {
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const dispatch = useDispatch();
  const clickOk = () => {
    close();
    if (previousRoute.payload === '/require-pay') {
      dispatch(previousRouteAction.setPreviousRoute('/create-profile-after-require'));
    } else if (placeUsingThisModal === '/my-profile') {
      dispatch(previousRouteAction.setPreviousRoute('/my-profile'));
    } else if (previousRoute.payload !== '/create-profile-after-shared-lists') {
      dispatch(previousRouteAction.setPreviousRoute('/create-profile'));
    }

    if (setOk) setOk(true);
  };

  return (
    <div className="modal-scholarship">
      <img onClick={close} src={cross} alt="cross" />
      <h1>{title}</h1>
      <p>{message}</p>
      <div>
        <NavLink
          to={placeUsingThisModal === '/my-profile' ? '/home' : ''}
          type="button"
          className="cancel-button"
          onClick={clickOk}
        >
          {textButton}
        </NavLink>
      </div>
    </div>
  );
};
