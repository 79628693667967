import { produce } from 'immer';

import { createReducer } from '.';
import { backupUserListActionTypes } from '../../consts';
import { IAction, IBackupUserListState } from '../../models';
import { backupUserListState } from '../states/backup-user-list.state';

export const backupUserListReducer = createReducer<IBackupUserListState>(backupUserListState, {
  [backupUserListActionTypes.SET_ALL_BACKUP_LIST_BY_USER_ID]: setBackupUserList,
});

function setBackupUserList(state: IBackupUserListState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.backupUserList = payload;
  });
}
