import './Membership-plan.css';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import check from '../../images/membership-check.svg';
import { UnsubscribeModal } from '../unsubscribe-modal/UnsubscribeModal';

export const MembershipPlan = ({ plan }: { plan: any }) => {
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const { user } = useSelector((state: any) => state.userState);
  const navigate = useNavigate();
  const [subscription, setSubscription] = useState<any>(null);
  const subscribeUnsubscribe = () => {
    if (subscription?.package.name !== plan.name) {
      navigate('/buy-plan', { state: { plan } });
    } else {
      open();
    }
  };
  useEffect(() => {
    setSubscription(user.subscription ?? null);
  }, [user]);
  return (
    <div className={plan.class}>
      {plan.additionally && <span className="most-popular-plan">{plan.additionally}</span>}
      <span>{plan.title}</span>
      <h6>{plan.name === 'essential' ? 'THE TOOL' : plan.name}</h6>
      <p className="plan-price">${plan.price}/MONTH</p>
      <hr className="dashed-hr" />
      {plan.options.map((option: string, i: number) =>
        i === plan.options.length - 1 ? (
          <div key={option}>
            <div>
              <img src={check} alt="check" />
              <p>{option}</p>
            </div>
          </div>
        ) : (
          <div key={option}>
            <div>
              <img src={check} alt="check" />
              <p>{option}</p>
            </div>
            <hr />
          </div>
        ),
      )}
      <button
        onClick={subscribeUnsubscribe}
        className={subscription?.package.name === plan.name ? 'primary-button unsubscribe' : 'primary-button'}
      >
        {subscription?.package.name === plan.name ? 'Unsubscribe' : 'Select this Plan'}
      </button>
      <Modal>
        <UnsubscribeModal close={close} plan={plan} />
      </Modal>
    </div>
  );
};
