import './SignUpModal.css';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink } from 'react-router-dom';
import cross from '../../images/cross.svg';

export const SignUpModal = ({
  close,
  message,
  title,
  anyAction,
}: {
  close: any;
  message?: string;
  title?: string;
  anyAction?: any;
}) => {
  const { loginWithRedirect } = useAuth0();

  const closeModal = () => {
    close();
    anyAction();
  };

  return (
    <div className="modal-sign-up">
      <img onClick={closeModal} src={cross} alt="cross" />
      <h1>{title || 'Create a Free Account'}</h1>
      <p>{message}</p>
      <div>
        {!title && (
          <button type="button" className="cancel-button" onClick={close}>
            Cancel
          </button>
        )}
        <NavLink
          to={title ? '/buy-plan' : ''}
          type="button"
          className="sign-up-modal-button"
          onClick={() => {
            if (title) {
              return;
            }
            loginWithRedirect({ screen_hint: 'signup' });
          }}
        >
          {title && title === 'Premium Data' ? 'Unlock' : ''}
          {title && title === 'Premium Feature' ? 'Upgrade' : ''}
          {!title && 'Register Now'}
        </NavLink>
      </div>
    </div>
  );
};
