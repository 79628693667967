import { ECouponActionTypes } from '../../enums';
import { ICoupon } from '../../models/coupon.model';

const getCoupon = (payload: { couponCode: string; packageId: number }) => {
  return {
    type: ECouponActionTypes.GET_COUPON,
    payload,
  };
};

const setCoupon = (payload: ICoupon | null) => {
  return {
    type: ECouponActionTypes.SET_COUPON,
    payload,
  };
};

export const couponAction = {
  getCoupon,
  setCoupon,
};
