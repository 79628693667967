import { all, call, put, takeLatest } from 'redux-saga/effects';
import { EBackupsActionTypes } from '../../enums';
import { listBackupsService } from '../../services';
import { listBackupsAction, loadingAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EBackupsActionTypes.GET_LIST_BACKUPS as any, watchGetListBackups)]);
}

function* watchGetListBackups() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        listBackupLoading: true,
      }),
    );

    const { data } = yield call(listBackupsService.endpoint_get_name_list_backups);

    yield put(listBackupsAction.setListBackups(data as Array<any>));
  } catch (error: any) {
    console.error('watchGetListBackups: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        listBackupLoading: false,
      }),
    );
  }
}
