import './FirstModal.css';
import { useEffect } from 'react';
import cross from '../../images/cross.svg';
import checkmark from '../../images/checkmark-green.svg';

export const FirstModal = ({
  title,
  close,
  plan,
  confirm,
  modalType,
}: {
  title?: string;
  close: any;
  plan: any;
  confirm?: any;
  modalType?: number;
}) => {
  return (
    <div className={`modal-first ${modalType === 2 ? 'small' : ''}`}>
      <img onClick={close} src={cross} alt="cross" />
      {title ? (
        <h1>{title}</h1>
      ) : (
        <h1>
          Use Insights to <br /> Find the Right College at the Right Price.
        </h1>
      )}
      <div className="modal-first-body">
        {plan.length > 0 &&
          plan.map((el: any) => (
            <div key={el.text}>
              <h1>
                <img src={checkmark} alt="cross" /> {el.text}
              </h1>

              {el.subtext && (
                <ul>
                  {el.subtext.map((subtext: any) => (
                    <li key={subtext.text}>
                      <p>{subtext.text}</p>
                    </li>
                  ))}
                </ul>
              )}
            </div>
          ))}
      </div>
      <div className="modal-first-footer">
        <button type="button" className="modal-first-confirm" onClick={confirm || close}>
          OK
        </button>
      </div>
    </div>
  );
};
