import React, { SetStateAction, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { clsx } from 'clsx';
import { CollegeCard } from '../../../../UI/cards/college-card/CollegeCard';
import { ICollege, IMyList, IRootState } from '../../../../models';
import styles from './CollegeList.module.css';
import { Autocomplete } from '../../../autocomplete/Autocomplete';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { myListsAction } from '../../../../store/actions';
import { myListService } from '../../../../services';
import { AddListModal } from '../../../add-list-modal/AddListModal';
import { SortCategory } from '../../PowerSearch';
import sort from '../../../../images/sort-arrows.svg';
import sortGreyBlack from '../../../../images/sort-arrowsGreyBlack.svg';
import sortBlackGrey from '../../../../images/sort-arrowsBlackGrey.svg';
import { BackToTopButton } from '../../../back-to-top-button/BackToTopButton';
import { LockedResultsModal } from './components/locked-results-modal/LockedResultsModal';
import { AutocompleteSelect } from '../../../../UI/selects/autocomplete-select/AutocompleteSelect';

interface CollegesListProps {
  collegesList: ICollege[];
  amountOfColleges: number;
  onSortSelectHandler: (category: SortCategory) => void;
  selectedSortMethod: SortCategory;
  sortCategories: SortCategory[];
  isDescSort: boolean;
  setIsDescSort: React.Dispatch<SetStateAction<boolean>>;
}

export const CollegesList: React.FC<CollegesListProps> = ({
  collegesList,
  amountOfColleges,
  onSortSelectHandler,
  selectedSortMethod,
  sortCategories,
  isDescSort,
  setIsDescSort,
}) => {
  const { user, adminNavigatedUser } = useSelector((state: IRootState) => state.userState);
  const { myLists } = useSelector((state: IRootState) => state.myListsState);

  const dispatch = useDispatch();

  const [userLists, setUserLists] = useState<IMyList[]>(myLists);

  const [allowedCreateList, setAllowedCreateList] = useState<boolean>(false);
  const [allCollegesResultsIsInList, setAllCollegesResultsIsInList] = useState<boolean>(false);
  const [isAddAllCollegesDropdownMenuActive, setIsAddAllCollegesDropdownMenuActive] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [isSortSelectActive, setIsSortSelectActive] = useState<boolean>(false);

  const addAllCollegesDropdownMenuOpeningButtonRef = useRef<HTMLElement | null>(null);
  const autocompleteForSortSelectRef = useRef<HTMLDivElement | null>(null);

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  useEffect(() => {
    setUserLists([{ id: -1, name: 'Add New List', userId: -1, colleges: [] }, ...myLists]);
  }, [myLists]);

  useEffect(() => {
    const isAllCollegesResultsIsInList: boolean = collegesList.every((college) =>
      // @ts-ignore
      myLists.some((list) => list.colleges.some((c) => c.collegeId === college.id)),
    );
    setAllCollegesResultsIsInList(isAllCollegesResultsIsInList);
  }, [myLists, collegesList]);

  const selectListMyList = (list: IMyList) => {
    if (list.id === -1) {
      open();
      setAllowedCreateList(true);
    } else {
      selectListFromMainSavingAndAddAllResults(list.name);
    }
  };

  const selectListFromMainSavingAndAddAllResults = (listName: string) => {
    const readyArray: number[] = [];
    for (let i = 0; i < collegesList.length; i++) {
      // @ts-ignore
      if (!myLists.includes(collegesList[i])) {
        readyArray.push(collegesList[i].id);
      }
    }

    dispatch(
      myListsAction.addCollegesToMyList({
        userId: (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
        collegesIds: readyArray,
        listName,
      }),
    );
    dispatch(myListsAction.getMyLists((adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number));
  };

  const createList = async (listName: string) => {
    try {
      setError(undefined);
      await myListService.endpoint_create_my_list({
        userId: (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
        listName,
      });
      const { data } = await myListService.endpoint_get_my_lists(
        (adminNavigatedUser ? adminNavigatedUser.id : user?.id) as number,
      );
      dispatch(myListsAction.setMyLists(data));
      selectListMyList(data[data.length - 1]);
      const addNewList = {
        colleges: [],
        createdAt: '2023-04-26T12:02:15.000Z',
        id: -1,
        name: 'Add New List',
        updatedAt: '2023-04-26T12:02:15.000Z',
        userId: user?.id,
      };
      setUserLists([addNewList, ...data]);
      close();
    } catch (e: any) {
      setError(e.response.data.message);
    }
  };

  useClickOutside(addAllCollegesDropdownMenuOpeningButtonRef, [], () => {
    if (isAddAllCollegesDropdownMenuActive) setIsAddAllCollegesDropdownMenuActive(false);
  });
  useClickOutside(autocompleteForSortSelectRef, [], () => {
    if (isSortSelectActive) setIsSortSelectActive(false);
  });

  return (
    <div className={styles.container}>
      <div className={styles.title}>
        <div className={styles.title__Amount}>
          <p className={styles.title__AmountText}>
            {amountOfColleges === 1 ? `1 College` : `${amountOfColleges} Colleges`}
          </p>
          <span
            ref={addAllCollegesDropdownMenuOpeningButtonRef}
            className={clsx(styles.plusIcon, allCollegesResultsIsInList && styles.selected)}
            onClick={() => setIsAddAllCollegesDropdownMenuActive(!isAddAllCollegesDropdownMenuActive)}
          >
            {isAddAllCollegesDropdownMenuActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={userLists}
                onclick={(list: IMyList) => {
                  selectListMyList(list);
                  setIsAddAllCollegesDropdownMenuActive(false);
                }}
                isAddToListAutocomplete={true}
                classN={styles.autocomplete}
              />
            )}
          </span>
        </div>
        <div className={styles.sortSelectContainer}>
          <div ref={autocompleteForSortSelectRef} className={styles.sortSelectWrapper}>
            <AutocompleteSelect
              placeholder={selectedSortMethod.name ? selectedSortMethod.name : 'Sort by'}
              inputName={'sortMethod'}
              isDisabledInput={true}
              onClickHandler={() => setIsSortSelectActive(!isSortSelectActive)}
              label={'Sort by'}
            >
              {isSortSelectActive && (
                <Autocomplete
                  isBudgetItems={false}
                  filteredItem={sortCategories}
                  onclick={(category: SortCategory) => {
                    onSortSelectHandler(category);
                    setIsSortSelectActive(false);
                  }}
                  classN={styles.sortingMethodAutocomplete}
                />
              )}
            </AutocompleteSelect>
          </div>
          <img
            src={selectedSortMethod.property === 'default' ? sort : isDescSort ? sortGreyBlack : sortBlackGrey}
            alt="sort"
            onClick={() => setIsDescSort((prev) => (user?.subscription || user?.role === 'admin' ? !prev : prev))}
            className={styles.sortDirectionButton}
            draggable={false}
          />
        </div>
      </div>
      <div className={styles.content}>
        {user?.subscription || user?.role === 'admin'
          ? collegesList.map((college) => (
              <CollegeCard
                key={college.id}
                college={college}
                sortedByValue={
                  selectedSortMethod.name !== sortCategories[0].name &&
                  selectedSortMethod.name !== sortCategories[1].name
                    ? selectedSortMethod
                    : undefined
                }
                isBlurred={false}
              />
            ))
          : collegesList.slice(0, 12).map((college) => (
              <CollegeCard
                key={college.id}
                college={college}
                sortedByValue={
                  selectedSortMethod.name !== sortCategories[0].name &&
                  selectedSortMethod.name !== sortCategories[1].name
                    ? selectedSortMethod
                    : undefined
                }
                isBlurred={false}
              />
            ))}
      </div>
      {!user?.subscription && user?.role !== 'admin' && collegesList.length > 12 && (
        <div className={styles.lockedContent__Container}>
          <div className={clsx(styles.content, styles.lockedContent)}>
            {collegesList.slice(12, 24).map((college) => (
              <CollegeCard
                /* isBlurred={true} */
                key={college.id}
                college={college}
                sortedByValue={
                  selectedSortMethod.name !== sortCategories[0].name &&
                  selectedSortMethod.name !== sortCategories[1].name
                    ? selectedSortMethod
                    : undefined
                }
                isBlurred={false}
              />
            ))}
          </div>
          <LockedResultsModal />
        </div>
      )}
      <BackToTopButton showOn={2000} />
      <Modal>
        {allowedCreateList && (
          <AddListModal
            createList={createList}
            close={() => {
              close();
              setAllCollegesResultsIsInList(false);
            }}
            err={error}
          />
        )}
      </Modal>
    </div>
  );
};
