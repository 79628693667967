import './CountDownloadForm.css';
import { useEffect, useState } from 'react';
import { historyDownloadService, userService } from '../../services';
import { IUser, IUserCountDownloadCsv } from '../../models';

type Props = {
  user: IUser;
};

export const CountDownloadForm = ({ user }: Props) => {
  const [clickCountDownload, setClickCountDownload] = useState<number>(
    user?.countDownloadCsv ? user.countDownloadCsv : 0,
  );
  const [coundDownloadedCsv, setCountDownloadedCsv] = useState<number>(0);
  const [isLoader, setIsLoader] = useState<boolean>(false);

  useEffect(() => {
    if (!user) return;

    setClickCountDownload(user?.countDownloadCsv || 0);
    const callBack = async () => {
      const response = await historyDownloadService.endpoint_get_history_download(user?.id || 0);
      if (response.data) {
        setCountDownloadedCsv(response.data.length);
      }
    };
    callBack();
  }, [user]);

  const handleSubmitForm = async (e) => {
    e.preventDefault();
    setIsLoader(true);
    const model: IUserCountDownloadCsv = {
      id: user.id || 0,
      countDownloadCsv: clickCountDownload,
    };

    const response = await userService.endpoint_update_user_count_download_csv(model);
    if (response.data) {
      setTimeout(() => {
        setIsLoader(false);
      }, 2000);
    }
  };

  const handleMinus = (): void => {
    if (clickCountDownload === 0) return;
    setClickCountDownload(clickCountDownload - 1);
  };

  const handlePlus = (): void => {
    setClickCountDownload(clickCountDownload + 1);
  };

  return (
    <form className="count-download" onSubmit={handleSubmitForm}>
      <div className="count-download__cont-used">
        <p className="count-download__used">Used</p>
        <p className="count-download__used-count">{coundDownloadedCsv}</p>
      </div>
      <div className="count-download__cont-main">
        <p className="count-download__available">Available</p>
        <div className="count-download_cont">
          <span className="count-download__minus" onClick={handleMinus} />
          <p className="count-download__count-click">{clickCountDownload}</p>
          <span className="count-download__plus" onClick={handlePlus} />
        </div>
        <button id="count-download__btn-submit" className="count-download__btn-submit" type="submit">
          {isLoader && <div className="admin-loader" />}
          {!isLoader && 'Submit'}
        </button>
      </div>
    </form>
  );
};
