import { EMyListsActionTypes } from '../../enums';
import { IMyList } from '../../models';
import { ISharedList } from '../../models/shared-list.model';

const getMyLists = (userId: number) => {
  return {
    type: EMyListsActionTypes.GET_MY_LISTS,
    userId,
  };
};

const getMyList = (payload: { userId: number; listName: string }) => {
  return {
    type: EMyListsActionTypes.GET_MY_LIST,
    payload,
  };
};

const createMyList = (payload: { userId: number; listName: string }) => {
  return {
    type: EMyListsActionTypes.CREATE_MY_LIST,
    payload,
  };
};

const createMyListAndAddCollege = (payload: { userId: number; collegeId: number; listName: string }) => {
  return {
    type: EMyListsActionTypes.CREATE_MY_LIST_AND_ADD_COLLEGE,
    payload,
  };
};

const addCollegesToMyList = (payload: { userId: number; listName: string; collegesIds: number[] }) => {
  return {
    type: EMyListsActionTypes.ADD_COLLEGES_TO_MY_LIST,
    payload,
  };
};

const removeCollegeFromMyList = (payload: { userId: number; listId: number; collegeId: number }) => {
  return {
    type: EMyListsActionTypes.DELETE_COLLEGE_FROM_MY_LIST,
    payload,
  };
};

const addCollegeToAllLists = (payload: { userId: number; collegeId: number }) => {
  return {
    type: EMyListsActionTypes.ADD_COLLEGE_TO_ALL_LISTS,
    payload,
  };
};

const removeCollegeFromAllLists = (payload: { userId: number; collegeId: number }) => {
  return {
    type: EMyListsActionTypes.DELETE_COLLEGE_FROM_ALL_LISTS,
    payload,
  };
};

const removeMyList = (payload: { userId: number; listId: number }) => {
  return {
    type: EMyListsActionTypes.DELETE_MY_LIST,
    payload,
  };
};

const deleteAllCollegesFromMyList = (payload: { userId: number; listId: number }) => {
  return {
    type: EMyListsActionTypes.DELETE_ALL_COLLEGES_FROM_LIST,
    payload,
  };
};

const setMyLists = (payload: IMyList[]) => {
  return {
    type: EMyListsActionTypes.SET_MY_LISTS,
    payload,
  };
};

const getListById = (id: number) => {
  return {
    type: EMyListsActionTypes.GET_LIST_BY_ID,
    payload: id,
  };
};

const setSelectedList = (payload: IMyList | null) => {
  return {
    type: EMyListsActionTypes.SET_SELECTED_LIST,
    payload,
  };
};

const getSharedList = (payload: string) => {
  return {
    type: EMyListsActionTypes.GET_SHARED_LIST,
    payload,
  };
};

const setSharedList = (payload: ISharedList | null) => {
  return {
    type: EMyListsActionTypes.SET_SHARED_LIST,
    payload,
  };
};

const getIdSharedList = () => {
  return {
    type: EMyListsActionTypes.GET_ID_SHARED_LIST,
  };
};

const setIdSharedList = (payload: number | null) => {
  return {
    type: EMyListsActionTypes.SET_ID_SHARED_LIST,
    payload,
  };
};

const setIsSharedList = (payload: boolean) => {
  return {
    type: EMyListsActionTypes.SET_IS_SHARED_LIST,
    payload,
  };
};

const getIsSharedList = () => {
  return {
    type: EMyListsActionTypes.GET_IS_SHARED_LIST,
  };
};

const getPaginatedList = (payload: {
  listId: number;
  limit: number;
  skip: number;
  sortBy: string;
  order: string;
  showRecommendations: boolean;
}) => {
  return {
    type: EMyListsActionTypes.GET_PAGINATED_COLLEGES_MY_LIST,
    payload,
  };
};

const setPaginatedList = (payload: IMyList | null) => {
  return {
    type: EMyListsActionTypes.SET_PAGINATED_COLLEGES_MY_LIST,
    payload,
  };
};

const getRecommendations = (payload: number) => {
  return {
    type: EMyListsActionTypes.GET_RECOMMENDATIONS_AMOUNT_FOR_LIST,
    payload,
  };
};

const setRecommendations = (payload: any) => {
  return {
    type: EMyListsActionTypes.SET_RECOMMENDATIONS_AMOUNT_FOR_LIST,
    payload,
  };
};

export const myListsAction = {
  getMyLists,
  getMyList,
  createMyList,
  createMyListAndAddCollege,
  addCollegesToMyList,
  removeCollegeFromMyList,
  removeMyList,
  setMyLists,
  getListById,
  addCollegeToAllLists,
  removeCollegeFromAllLists,
  setSelectedList,
  setSharedList,
  getSharedList,
  deleteAllCollegesFromMyList,
  getPaginatedList,
  setPaginatedList,
  setRecommendations,
  getRecommendations,
  setIdSharedList,
  getIdSharedList,
  setIsSharedList,
  getIsSharedList,
};
