import { axiosService } from '.';
import { apiUrls } from '../consts';

export const couponService = (function () {
  const endpoint_get_coupon = async (payload: { couponId: string; packageId: number }) => {
    return axiosService.get(apiUrls.get_coupon_by_id(payload.couponId, payload.packageId));
  };

  return {
    endpoint_get_coupon,
  };
})();
