import { ESelectedCollegesAndColumnsTypes } from '../../enums';

const getSelectedCollegesForCsv = () => {
  return {
    type: ESelectedCollegesAndColumnsTypes.GET_SELECTED_COLLEGES_FOR_CSV,
  };
};

const setSelectedCollegesForCsv = (payload: Array<any>) => {
  return {
    type: ESelectedCollegesAndColumnsTypes.SET_SELECTED_COLLEGES_FOR_CSV,
    payload,
  };
};

const getSelectedColumnsForCsv = () => {
  return {
    type: ESelectedCollegesAndColumnsTypes.GET_SELECTED_COLUMNS_FOR_CSV,
  };
};

const setSelectedColumnsForCsv = (payload: Array<any>) => {
  return {
    type: ESelectedCollegesAndColumnsTypes.SET_SELECTED_COLUMNS_FOR_CSV,
    payload,
  };
};

export const selectedCollegesAndColumnsAction = {
  setSelectedCollegesForCsv,
  getSelectedCollegesForCsv,
  getSelectedColumnsForCsv,
  setSelectedColumnsForCsv,
};
