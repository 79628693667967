import { ILoadingState } from '../../models';

export const loadingState: ILoadingState = {
  getHighschoolsLoading: true,
  getUserLoading: true,
  getUsersLoading: true,
  initialLoading: true,
  myListLoading: false,
  restoreBackupLoading: false,
  listBackupLoading: false,
  downloadBackupLoading: false,
  createBackupLoading: false,
  deleteBackupLoading: false,
  getPowerSearchResultLoading: false,
};
