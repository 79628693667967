import { EBackupsUserListActionTypes } from '../../enums';

const downloadBackupUserListByListId = (payload: number) => {
  return {
    type: EBackupsUserListActionTypes.DOWNLOAD_BACKUP_USER_LIST_BY_LIST_ID,
    payload,
  };
};

const getAllUserListBackup = (payload: number) => {
  return {
    type: EBackupsUserListActionTypes.GET_ALL_BACKUP_LIST_BY_USER_ID,
    payload,
  };
};

const setAllUserListBackup = (payload: Array<any>) => {
  return {
    type: EBackupsUserListActionTypes.SET_ALL_BACKUP_LIST_BY_USER_ID,
    payload,
  };
};

export const backupUserListAction = {
  downloadBackupUserListByListId,
  getAllUserListBackup,
  setAllUserListBackup,
};
