import { forwardRef, useImperativeHandle, useState } from 'react';
import './Autocomplete.css';
import plusIcon from '../../images/plus.svg';
import createIcon from '../../images/logoutIcon.svg';

interface AutocompleteProps {
  isBudgetItems: boolean;
  filteredItem: any[];
  onclick: any;
  icon?: string;
  selectedDeadlines?: string[];
  isAddToListAutocomplete?: boolean;
  classN?: string;
  lockedAfterItems?: number;
  setClickedInMyListTable?: any;
}

export const Autocomplete = forwardRef<any, AutocompleteProps>(
  (
    {
      isBudgetItems,
      filteredItem,
      onclick,
      icon,
      selectedDeadlines,
      isAddToListAutocomplete = false,
      classN,
      lockedAfterItems,
      setClickedInMyListTable,
    },
    ref,
  ) => {
    const [selectedIdx, setSelectedIdx] = useState(-1);
    useImperativeHandle(ref, () => ({
      onKeyDown(evt: KeyboardEvent) {
        if (evt.key === 'Enter') {
          onclick(filteredItem[selectedIdx]);
        }
        if (evt.key === 'ArrowDown' || evt.key === 'ArrowUp') {
          let index = selectedIdx;

          index += evt.key === 'ArrowDown' ? 1 : -1;

          if (index > filteredItem.length - 1) {
            index = filteredItem.length - 1;
          } else if (index < 0) {
            index = 0;
          }

          if (index !== selectedIdx) {
            setSelectedIdx(index);
          }
          fixScrolling(evt.key);
        }
      },
    }));

    const fixScrolling = (key: string) => {
      const selectHeight = document.getElementsByClassName('options')[selectedIdx]?.clientHeight;
      const autocomplete = document.getElementById('autocomplete');
      if (autocomplete) {
        if (key === 'ArrowDown') {
          autocomplete.scrollTop = selectHeight * selectedIdx;
        } else {
          autocomplete.scrollTop = selectHeight * (selectedIdx - 2);
        }
      }
    };
    const fixDisplayBudget = (name: string) => {
      return name
        .split(' ')
        .map((item: any) => {
          if (Number.isNaN(+item)) {
            return item;
          }
          return `$ ${(+item).toLocaleString('en-US')}`;
        })
        .join(' ');
    };
    
    return (
      <div id="autocomplete" className={`autocomplete ${classN}`}>
        {filteredItem.length === 0 ? (
          <div>No matches..</div>
        ) : (
          filteredItem
            .filter((item, idx) => idx < 1000)
            .map((item: any, i: number) => (
              <div
                className={`options ${selectedIdx === i ? 'item-hover' : ''} ${
                  lockedAfterItems && i > lockedAfterItems ? 'not-allowed-item' : ''
                }`}
                onMouseDown={(e) => e.preventDefault()}
                onClick={(e) => {
                  e.stopPropagation();

                  if (lockedAfterItems && i > lockedAfterItems) {
                    e.preventDefault();
                    return;
                  }

                  onclick(item);

                  if (setClickedInMyListTable) {
                    setClickedInMyListTable();
                  }
                }}
                key={item.id + item.name}
              >
                {selectedDeadlines && (
                  <div className="container">
                    <input readOnly type="checkbox" name="deadline" checked={selectedDeadlines.includes(item.name)} />
                    <p className="my-list-checkmark" />
                  </div>
                )}
                <div className="flex justify-between w-full">
                  <div>{isBudgetItems ? fixDisplayBudget(item.name) : item.name}</div>
                  {icon && item.id !== -1 && (
                    <div>
                      <img src={icon} alt="icon" />
                    </div>
                  )}
                  {item && item.img && (
                    <div>
                      <img src={item.img} alt="icon" />
                    </div>
                  )}
                  {isAddToListAutocomplete && (
                    <div>
                      <img alt="icon" src={item.id !== -1 ? createIcon : plusIcon} />{' '}
                    </div>
                  )}
                </div>
              </div>
            ))
        )}
      </div>
    );
  },
);
