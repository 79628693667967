/* eslint-disable */
import crypto from "crypto-js";

export const scoreSmartService = (function () {
    const createScoreSmartAccount = (username, parentemail) => {
        const app_key = 'abdjsbdwddasjbddig';
        const app_secret = 'ldfvnkjdbckjbddig';
        const path= '/api/create/login/user';
        const query_string = 'accesskey='+app_key+'&timestamp='+(Date.now()+36000);
        const auth_token = crypto.HmacSHA1('POST'+path+'?'+query_string, app_secret);
        const url= 'https://digitaltests.score-smart.com'+path+'?'+query_string+'&authtoken='+auth_token;
        const newTab = window.open('', '_blank');

        const body = {
            username,
            parentemail
        }

        fetch(url, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        }).then(
            response => response.json()
        ).then(
            json => {

                if (newTab) {
                    newTab.location.href = json;
                }
            }
        );
    }

    return {
        createScoreSmartAccount
    };
})();
