import { EHouseHoldIncomeActionTypes } from '../../enums';

const getHouseHoldIncome = () => {
  return {
    type: EHouseHoldIncomeActionTypes.GET_HOUSEHOLDINCOME,
  };
};

const setHouseHoldIncome = (payload: Array<any>) => {
  return {
    type: EHouseHoldIncomeActionTypes.SET_HOUSEHOLDINCOME,
    payload,
  };
};

export const householdIncomeAction = {
  getHouseHoldIncome,
  setHouseHoldIncome,
};
