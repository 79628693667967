import { axiosService } from './axios.service';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const listBackupsService = (function () {
  const endpoint_get_name_list_backups = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_list_backups);
  };
  const endpoint_delete_list_by_name = async (backupName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_backup_by_name(backupName));
  };
  return {
    endpoint_get_name_list_backups,
    endpoint_delete_list_by_name,
  };
})();
