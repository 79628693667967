import axios from 'axios';
import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const myListService = (function () {
  const endpoint_get_my_lists = async (userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_my_lists(userId));
  };

  const endpoint_get_my_list = async (userId: number, listName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_my_list(userId, listName));
  };

  const endpoint_add_colleges_to_my_list = async (payload: {
    userId: number;
    listName: string;
    collegesIds: number[];
  }) => {
    const { userId, listName, collegesIds } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.my_list, { userId, listName, collegesIds });
  };

  const endpoint_remove_colleges_from_my_list = async (payload: {
    userId: number;
    listName: string;
    collegeIds: number[];
  }) => {
    const { userId, listName, collegeIds } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.remove_colleges_from_my_list(userId, listName, collegeIds));
  };

  const endpoint_remove_colleges_from_all_my_list = async (payload: { userId: number; collegeIds: number[] }) => {
    const { userId, collegeIds } = payload;

    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.remove_colleges_from_all_my_list(userId, collegeIds));
  };

  const endpoint_add_college_to_all_lists = async (payload: { userId: number; collegesId: number }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.add_college_to_all_lists, payload);
  };

  const endpoint_delete_college_from_all_lists = async (payload: { userId: number; collegeId: number }) => {
    const { userId, collegeId } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_college_from_all_lists(userId, collegeId));
  };

  const endpoint_create_my_list = async (payload: { userId: number; listName: string }) => {
    const { userId, listName } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.my_list, { userId, listName });
  };

  const endpoint_remove_college_from_my_list = async (payload: { listId: number; collegeId: number }) => {
    const { listId, collegeId } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.remove_college_from_my_list(listId, collegeId));
  };

  const endpoint_remove_my_list = async (payload: { listId: number }) => {
    const { listId } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.list_by_id(listId));
  };

  const endpoint_delete_all_colleges_from_my_list = async (payload: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.delete_all_colleges_from_list(payload));
  };

  const endpoint_get_list_by_id = async (payload: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.list_by_id(payload));
  };

  const endpoint_create_list_and_add_college = async (payload: {
    userId: number;
    collegeId: number;
    listName: string;
  }) => {
    const { userId, listName, collegeId } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.create_list_and_add_college, { userId, collegeId, listName });
  };

  const endpoint_update_list_name = async (payload: { listId: number; newName: string }) => {
    const { listId, newName } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.patch(apiUrls.update_list_name(listId), { newName });
  };

  const endpoint_get_paginated_list_by_id = async (
    payload: {
      listId: number;
      limit: number;
      skip: number;
      showRecommendations: boolean;
      sortBy?: string;
      order?: string;
    },
    config: any,
  ) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axios.get(
      apiUrls.get_paginated_colleges_my_list(
        payload.listId,
        payload.limit,
        payload.skip,
        payload.showRecommendations,
        payload.sortBy,
        payload.order,
      ),
      config,
    );
  };

  const endpoint_get_shared_list = async (payload: string) => {
    return axiosService.get(apiUrls.get_shared_list(payload));
  };

  const get_recommended_for_list_amount = async (payload: number) => {
    return axiosService.get(apiUrls.get_recommended_for_list_amount(payload));
  };

  const endpoint_update_list = async (listId: string, priority: string) => {
    return axiosService.post(apiUrls.update_priority_in_list(listId, priority));
  };

  const endpoint_colleges_for_sharing = async (listId: number) => {
    return axiosService.get(apiUrls.get_my_lists_for_sharing(listId));
  };

  return {
    endpoint_get_my_lists,
    endpoint_get_my_list,
    endpoint_add_colleges_to_my_list,
    endpoint_create_my_list,
    endpoint_remove_college_from_my_list,
    endpoint_remove_my_list,
    endpoint_get_list_by_id,
    endpoint_add_college_to_all_lists,
    endpoint_delete_college_from_all_lists,
    endpoint_get_shared_list,
    endpoint_delete_all_colleges_from_my_list,
    endpoint_get_paginated_list_by_id,
    get_recommended_for_list_amount,
    endpoint_create_list_and_add_college,
    endpoint_update_list_name,
    endpoint_update_list,
    endpoint_colleges_for_sharing,
    endpoint_remove_colleges_from_my_list,
    endpoint_remove_colleges_from_all_my_list,
  };
})();
