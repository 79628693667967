import './Admin-dashboard.css';
import { NavLink } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import usersIcon from '../../images/users-icon.svg';
import tableIcon from '../../images/table-icon.svg';
import backupIcon from '../../images/backups-icon.svg';
import helpIcon from '../../images/help-icon.svg';
import authIcon from '../../images/auth-icon.svg';
import configIcon from '../../images/config-icon.svg';
import { listBackupsAction } from '../../store/actions';

export const AdminDashboard = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(listBackupsAction.getListBackups());
  }, []);
  return (
    <div className="admin-dashboard-wrapper">
      <div className="admin-dashboard">
        <NavLink to={'/users'}>
          <img src={usersIcon} alt="users" />
          <p>Users</p>
        </NavLink>
        <NavLink to={'/admin/uploads'}>
          <img src={tableIcon} alt="table" />
          <p>Upload data</p>
        </NavLink>
        <NavLink to={'/backups'}>
          <img src={backupIcon} alt="backup" />
          <p>Backups</p>
        </NavLink>
        <div>
          <img src={helpIcon} alt="help" />
          <p>Help</p>
        </div>
        <div>
          <img src={authIcon} alt="auth" />
          <p>Authorize App</p>
        </div>
        <NavLink to={'/configuration'}>
          <img src={configIcon} alt="config" />
          <p>Configuration</p>
        </NavLink>
      </div>
    </div>
  );
};
