import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import sharedStyles from '../Shared.module.css';
import { EPlatform } from '../../../../../../enums/platform.enum';
import { Autocomplete } from '../../../../../autocomplete/Autocomplete';
import { IPowerSearchFilterOption } from '../../../../../../models';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';

const costOfAttendanceTypes: IPowerSearchFilterOption<string>[] = [
  { id: 1, name: 'Out of State', value: 'outState' },
  { id: 2, name: 'In State', value: 'inState' },
  { id: 3, name: 'Private', value: 'private' },
];

const netCostAfterAvgMeritAward: IPowerSearchFilterOption<number>[] = [
  { id: 1, name: '$10k', value: 10000 },
  { id: 2, name: '$20k', value: 20000 },
  { id: 3, name: '$30k', value: 30000 },
  { id: 4, name: '$40k', value: 40000 },
  { id: 5, name: '$50k', value: 50000 },
  { id: 6, name: '$60k', value: 60000 },
  { id: 7, name: '$70k', value: 70000 },
  { id: 8, name: '$80k', value: 80000 },
  { id: 9, name: '$90k', value: 90000 },
  { id: 10, name: '$100k', value: 100000 },
];

const netCostAfterAvgMeritAwardRangeValues: number[] = [0, 100000];

function valuetext(value: number) {
  return `${value}`;
}

interface NetCostFilterProps {
  checkboxesSelected: string[];
  changeCheckboxesSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValues: { min: number | null; max: number | null } | null;
  selectMin: (value: IPowerSearchFilterOption<number>) => void;
  selectMax: (value: IPowerSearchFilterOption<number>) => void;
  selectedRangeValues: number[];
  handleChangeRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  platform: EPlatform;
}

export const NetCostFilter: React.FC<NetCostFilterProps> = ({
  checkboxesSelected,
  changeCheckboxesSelected,
  selectedValues,
  selectMin,
  selectMax,
  selectedRangeValues,
  handleChangeRange,
  platform,
}) => {
  const [isNetCostMinSelectActive, setIsNetCostMinSelectActive] = useState<boolean>(false);
  const [isNetCostMaxSelectActive, setIsNetCostMaxSelectActive] = useState<boolean>(false);

  const netCostMinSelectRef = useRef<HTMLDivElement | null>(null);
  const netCostMaxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(netCostMinSelectRef, [], () => {
    if (isNetCostMinSelectActive) setIsNetCostMinSelectActive(false);
  });
  useClickOutside(netCostMaxSelectRef, [], () => {
    if (isNetCostMaxSelectActive) setIsNetCostMaxSelectActive(false);
  });

  return (
    <div className={sharedStyles.container}>
      <h5 className={sharedStyles.title}>
        Net Cost<p className={sharedStyles.subtitle}>(After Avg. Merit Award)</p>
      </h5>
      <div className={sharedStyles.checkboxesContainer}>
        {costOfAttendanceTypes.map((el) => {
          return (
            <div key={el.id} className={sharedStyles.checkboxContainer}>
              <input
                type="checkbox"
                value={el.value}
                checked={checkboxesSelected?.includes(el.value)}
                onChange={changeCheckboxesSelected}
                name="costOfAttendanceCheckboxes"
                id={el.value}
              />
              <label className={sharedStyles.checkboxLabel} htmlFor={el.value}>
                {el.name}
              </label>
            </div>
          );
        })}
      </div>
      {platform === EPlatform.DESKTOP && (
        <div className={sharedStyles.buttonsContainer}>
          <div
            onClick={() => setIsNetCostMinSelectActive(!isNetCostMinSelectActive)}
            ref={netCostMinSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.min && selectedValues?.min !== 0
              ? 'Min'
              : netCostAfterAvgMeritAward[
                  netCostAfterAvgMeritAward.findIndex((value) => value.value === selectedValues.min)
                ]?.name || `$${selectedValues.min.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isNetCostMinSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={netCostAfterAvgMeritAward}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMin(value);
                  setIsNetCostMinSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
          <div
            onClick={() => setIsNetCostMaxSelectActive(!isNetCostMaxSelectActive)}
            ref={netCostMaxSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.max && selectedValues?.max !== 0
              ? 'Max'
              : netCostAfterAvgMeritAward[
                  netCostAfterAvgMeritAward.findIndex((value) => value.value === selectedValues.max)
                ]?.name || `$${selectedValues.max.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isNetCostMaxSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={netCostAfterAvgMeritAward}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMax(value);
                  setIsNetCostMaxSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
        </div>
      )}
      {(platform === EPlatform.TABLET || platform === EPlatform.MOBILE) && (
        <div className={sharedStyles.rangeWrapper}>
          <Slider
            sx={{
              width: '95%',
              color: '#7e106e',
              '& .MuiSlider-thumb': {
                background: 'white',
                border: '2px solid #7e106e',
              },
            }}
            getAriaLabel={() => 'Minimum distance'}
            step={10000}
            min={netCostAfterAvgMeritAwardRangeValues[0]}
            max={netCostAfterAvgMeritAwardRangeValues[1]}
            value={selectedRangeValues}
            onChange={handleChangeRange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            valueLabelFormat={(value) => `$${value.toLocaleString('en-US')}`}
            disableSwap
          />
        </div>
      )}
    </div>
  );
};
