import { useEffect, useState } from 'react';
import './CollegeDetailsSchoolarships.css';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useModal } from 'react-hooks-use-modal';
import { NavLink } from 'react-router-dom';
import lock from '../../images/icon-locked2.svg';
import lockWhite from '../../images/locked-icon-white.svg';
import lockedGrey from '../../images/locked-icon-grey.svg';
import { ScholarshipModal } from '../scholarship-modal/ScholarshipModal';
import { SignUpModal } from '../signup-modal/SignUpModal';

export const CollegeDetailsSchoolarships = ({ clickUnlock, openModal }: { clickUnlock: any; openModal: any }) => {
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);

  const { selectedCollege } = useSelector((state: any) => state.collegesState);
  const { isAuthenticated } = useAuth0();
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });
  const [message, setMessage] = useState('');
  const [title, setTitle] = useState('');
  const [isMobileScreen, setIsMobileScreen] = useState(false);
  useEffect(() => {
    if (window.innerWidth <= 520) {
      setIsMobileScreen(true);
    } else {
      setIsMobileScreen(false);
    }
  }, [window.innerWidth]);

  return (
    <section className="colleges-details-wrapper-container-under">
      <div className="colleges-details-wrapper">
        {(user?.subscription && !adminNavigatedUser) ||
        (adminNavigatedUser && adminNavigatedUser.subscription) ||
        user.role === 'admin' ? (
          <>
            <div className="college-details-section">
              <h6>Need Based Aid</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Percent of Need Met</p>
                    <p className="unlocked-field">Average Financial Aid for Freshmen w/Need</p>
                    <p className="unlocked-field">CSS Profile</p>
                    <p className="unlocked-field">Non-Custodial CSS Form Required</p>
                  </div>
                  <div className="race-res-block">
                    <span>
                      {selectedCollege?.avgPcNeedMet ? `${(+selectedCollege.avgPcNeedMet).toFixed(0)} %` : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgNeedBasedAward
                        ? `$ ${(+selectedCollege.avgNeedBasedAward).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                    <span>{selectedCollege?.cssProfileRequired ? 'Yes' : 'No'}</span>
                    <span>{selectedCollege?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="college-details-section">
              <h6>Merit Based Scholarship</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Freshmen w/o need</p>
                    <p className="unlocked-field">Freshmen w/o need receiving merit aid</p>
                    <p className="unlocked-field">Avg merit award for freshmen w/o need</p>
                  </div>
                  <div className="race-res-block">
                    <span>
                      {selectedCollege?.pcStudentsWithoutNeed
                        ? `${(+selectedCollege.pcStudentsWithoutNeed).toFixed(0)} %`
                        : '-'}
                    </span>
                    <span>
                      {selectedCollege?.pcStudentsReceivingMeritAid
                        ? `${(+selectedCollege.pcStudentsReceivingMeritAid).toFixed(0)} %`
                        : '-'}
                    </span>
                    <span>
                      {selectedCollege?.avgMeritAidAward
                        ? `$ ${(+selectedCollege.avgMeritAidAward).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className="price-calculation">
                  <a
                    onClick={() => {
                      if (!selectedCollege?.scholarshipsUrl) {
                        setTitle('Merit Scholarship Details not Available');
                        setMessage(
                          'We are sorry to inform you that Merit Scholarship details for this college are not available at the moment.',
                        );
                        open();
                      }
                    }}
                    target="_blank"
                    href={selectedCollege?.scholarshipsUrl || undefined}
                    rel="noreferrer"
                    className="active"
                  >
                    <button>Merit Scholarship (s) Details</button>
                  </a>
                  {selectedCollege?.urlFullScholarship && (
                    <a target="_blank" href={selectedCollege?.urlFullScholarship} rel="noreferrer" className="active">
                      <button>Full Tuition or Full Ride Scholarship</button>
                    </a>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="college-details-section">
              <h6>Need Based Aid</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">CSS Profile </p>
                    <p className="unlocked-field"> Non-Custodial CSS Form Required </p>
                  </div>
                  <div className="race-res-block">
                    <span>{selectedCollege?.cssProfileRequired ? 'Yes' : 'No'}</span>
                    <span>{selectedCollege?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                </div>
                <div className="college-details-section-locked-schoolarships">
                  <div className="college-details-section-locked-schoolarships-content">
                    <div className="college-details-unlocked-field-content">
                      <p className="unlocked-field">Percent of Need Met</p>
                      <p className="unlocked-field">Average Financial Aid for Freshmen w/ Need</p>
                    </div>
                    <div className="upgrade-profile-message-schoolarships">
                      <img src={isMobileScreen ? lockWhite : lock} alt="locked" />
                      <p>Information locked</p>
                      <span>Upgrade Profile to see all this Information!</span>
                      <NavLink
                        to={
                          (isAuthenticated && user.subscription && !adminNavigatedUser) ||
                          (adminNavigatedUser && adminNavigatedUser.subscription && isAuthenticated) ||
                          user.role === 'admin'
                            ? '/buy-plan'
                            : ''
                        }
                        onClick={() => {
                          if (
                            (isAuthenticated && !adminNavigatedUser && user.subscription) ||
                            (isAuthenticated && adminNavigatedUser && adminNavigatedUser.subscription) ||
                            user.role === 'admin'
                          ) {
                            return;
                          }

                          if (
                            (isAuthenticated && !adminNavigatedUser && !user.subscription && user.role !== 'admin') ||
                            (isAuthenticated && adminNavigatedUser && !adminNavigatedUser.subscription)
                          ) {
                            clickUnlock('unlock-modal');
                            openModal();
                            return;
                          }

                          open();
                        }}
                      >
                        Unlock
                      </NavLink>
                    </div>
                    <div className="res-block">
                      <span>N/A%</span>
                      <span>$N/A</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="college-details-section">
              <h6>Merit Based Scholarship</h6>
              <hr className="section-hr-line" />
              <div>
                <div className="race-info-block">
                  <div>
                    <p className="unlocked-field">Freshmen w/out Need Receiving Merit Aid</p>
                  </div>
                  <div className="race-res-block">
                    <span>
                      {selectedCollege?.pcStudentsReceivingMeritAid
                        ? `${(+selectedCollege.pcStudentsReceivingMeritAid).toFixed(0)}%`
                        : '-'}
                    </span>
                  </div>
                </div>
                <div className="college-details-section-locked-schoolarships college-details-section-locked-schoolarships-second">
                  <div className="college-details-section-locked-schoolarships-content">
                    <div className="college-details-unlocked-field-content">
                      <p className="unlocked-field">Freshmen w/out Need</p>
                      <p className="unlocked-field">Average Merit Award for Freshmen w/out Need</p>
                    </div>
                    <div className="upgrade-profile-message-schoolarships-second">
                      <img src={isMobileScreen ? lockWhite : lock} alt="locked" />
                      <p>Information locked</p>
                      <span>Upgrade Profile to see all this Information!</span>
                      <NavLink
                        to={
                          (isAuthenticated && user.subscription && !adminNavigatedUser) ||
                          (adminNavigatedUser && adminNavigatedUser.subscription && isAuthenticated)
                            ? '/buy-plan'
                            : ''
                        }
                        onClick={() => {
                          if (
                            (isAuthenticated && !adminNavigatedUser && user.subscription) ||
                            (isAuthenticated && adminNavigatedUser && adminNavigatedUser.subscription)
                          ) {
                            return;
                          }

                          if (
                            (isAuthenticated && !adminNavigatedUser && !user.subscription && user.role !== 'admin') ||
                            (isAuthenticated && adminNavigatedUser && !adminNavigatedUser.subscription)
                          ) {
                            clickUnlock('unlock-modal');
                            openModal();
                            return;
                          }

                          open();
                        }}
                      >
                        Unlock
                      </NavLink>
                    </div>
                    <div className="res-block ">
                      <span>N/A%</span>
                      <span>$N/A</span>
                    </div>
                  </div>
                  <div className="price-calculation-locked">
                    <a target="_blank" className="active">
                      <button
                        className={
                          (user && user?.subscription && !adminNavigatedUser) ||
                          (adminNavigatedUser && adminNavigatedUser?.subscription)
                            ? 'price-calculation-locked-button-first'
                            : 'price-calculation-locked-button-first price-calculation-locked-button-first-locked'
                        }
                      >
                        <img src={lockedGrey} alt="locked-icon" />
                        Full Tuition or Full Ride Scholarship
                      </button>
                    </a>
                    <a target="_blank" className="active">
                      <button
                        className={
                          (user && user?.subscription && !adminNavigatedUser) ||
                          (adminNavigatedUser && adminNavigatedUser?.subscription)
                            ? ''
                            : 'price-calculation-locked-button-first-locked'
                        }
                      >
                        Full Tuition or Full Ride Scholarship
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        <div className="college-details-section-link-faqs">
          <a target="_blank" rel="noreferrer" href="https://www.road2college.com/college-insights-faqs/">
            **College Data sources{' '}
          </a>
        </div>
        <Modal>
          {isAuthenticated ? (
            <ScholarshipModal close={close} message={message} title={title} />
          ) : (
            <SignUpModal close={close} message={'Find More Schools and Start Building a College List'} />
          )}
        </Modal>
      </div>
    </section>
  );
};
