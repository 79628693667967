import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';
import { IEmailBody } from '../components';

export const emailService = (function () {
  const endpoint_send_email = async (body: IEmailBody) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.send_email, body);
  };

  return {
    endpoint_send_email,
  };
})();
