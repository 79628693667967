import { produce } from 'immer';
import { createReducer } from './index';
import {
  IAction,
  ICollege,
  IMajors,
  IMinMax,
  IPowerSearchFilterOption,
  IPowerSearchState,
  IRegion,
  IState,
} from '../../models';
import { powerSearchActionTypes } from '../../consts';
import { powerSearchState } from '../states/power-search.state';

export const powerSearchReducer = createReducer<IPowerSearchState>(powerSearchState, {
  [powerSearchActionTypes.CLEAR_FILTERS]: clearFilters,
  [powerSearchActionTypes.SET_SORT]: setSort,
  [powerSearchActionTypes.ADD_SELECTED_COLLEGE]: addSelectedCollege,
  [powerSearchActionTypes.REMOVE_FROM_SELECTED_COLLEGES]: removeFromSelectedColleges,
  [powerSearchActionTypes.ADD_SELECTED_MAJOR]: addSelectedMajor,
  [powerSearchActionTypes.REMOVE_FROM_SELECTED_MAJORS]: removeFromSelectedMajors,
  [powerSearchActionTypes.ADD_SELECTED_LOCATION]: addSelectedLocation,
  [powerSearchActionTypes.REMOVE_FROM_SELECTED_LOCATIONS]: removeFromSelectedLocations,
  [powerSearchActionTypes.ADD_SELECTED_APPLICATION_DEADLINE]: addSelectedApplicationDeadline,
  [powerSearchActionTypes.REMOVE_FROM_SELECTED_APPLICATION_DEADLINES]: removeFromSelectedApplicationDeadline,
  [powerSearchActionTypes.ADD_COST_OF_ATTENDANCE_TYPE_SELECTED]: addCostOfAttendanceTypeSelected,
  [powerSearchActionTypes.REMOVE_FROM_COST_OF_ATTENDANCE_TYPES_SELECTED]: removeFromCostOfAttendanceTypesSelected,
  [powerSearchActionTypes.SET_COST_OF_ATTENDANCE]: setCostOfAttendance,
  [powerSearchActionTypes.SET_AVG_MERIT_AWARD_SELECTED]: setAvgMeritAwardSelected,
  [powerSearchActionTypes.SET_AVG_MERIT_AWARD_TYPE_SELECTED]: setAvgMeritAwardTypeSelected,
  [powerSearchActionTypes.ADD_NET_COST_TYPE_SELECTED]: addNetCostTypeSelected,
  [powerSearchActionTypes.REMOVE_FROM_NET_COST_TYPE_SELECTED]: removeFromNetCostTypeSelected,
  [powerSearchActionTypes.SET_NET_COST_AFTER_AVG_MERIT_AWARD_SELECTED]: setNetCostAfterAvgMeritAwardSelected,
  [powerSearchActionTypes.SET_NEED_MET_SELECTED]: setNeedMetSelected,
  [powerSearchActionTypes.SET_SCHOOL_SIZE]: setSchoolSize,
  [powerSearchActionTypes.SET_TEST_SCORE_VALUE_SELECTED]: setTestScoreValueSelected,
  [powerSearchActionTypes.SET_SAT_SCORE_SELECTED]: setSatScoreSelected,
  [powerSearchActionTypes.SET_ACT_SCORE_SELECTED]: setActScoreSelected,
  [powerSearchActionTypes.SET_SELECTED_TEST_SCORE_TYPE]: setSelectedTestScoreType,
  [powerSearchActionTypes.SET_GPA]: setGpa,
  [powerSearchActionTypes.SET_STUDENT_GPA_TYPE_SELECTED]: setStudentGPATypeSelected,
});

function clearFilters(state: IPowerSearchState) {
  return produce(state, (draft) => {
    draft.sortBy = { id: -1, name: '', property: '' };
    draft.selectedColleges = [];
    draft.selectedMajors = [];
    draft.selectedLocations = [];
    draft.selectedApplicationDeadlines = [];
    draft.costOfAttendanceTypesSelected = [];
    draft.costOfAttendanceSelected = null;
    draft.avgMeritAwardSelected = null;
    draft.avgMeritAwardTypeSelected = '';
    draft.netCostTypesSelected = [];
    draft.netCostAfterAvgMeritAwardSelected = null;
    draft.needMetSelected = null;
    draft.schoolSize = null;
    draft.testScoreValueSelected = '';
    draft.satScoreSelected = null;
    draft.actScoreSelected = null;
    draft.selectedTestScoreType = '';
    draft.gpa = null;
    draft.studentGPATypeSelected = '';
  });
}

function setSort(state: IPowerSearchState, { payload }: IAction<{ id: number; name: string; property: string }>) {
  return produce(state, (draft) => {
    draft.sortBy = payload;
  });
}

function addSelectedCollege(state: IPowerSearchState, { payload }: IAction<ICollege>) {
  return produce(state, (draft) => {
    draft.selectedColleges.push(payload);
  });
}

function removeFromSelectedColleges(state: IPowerSearchState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedColleges = draft.selectedColleges.filter((c) => c.id !== payload);
  });
}

function addSelectedMajor(state: IPowerSearchState, { payload }: IAction<IMajors>) {
  return produce(state, (draft) => {
    draft.selectedMajors.push(payload);
  });
}

function removeFromSelectedMajors(state: IPowerSearchState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedMajors = draft.selectedMajors.filter((m) => m.id !== payload);
  });
}

function addSelectedLocation(state: IPowerSearchState, { payload }: IAction<IState | IRegion>) {
  return produce(state, (draft) => {
    draft.selectedLocations.push(payload);
  });
}

function removeFromSelectedLocations(state: IPowerSearchState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedLocations = draft.selectedLocations.filter((l) => l.id !== payload);
  });
}

function addSelectedApplicationDeadline(
  state: IPowerSearchState,
  { payload }: IAction<IPowerSearchFilterOption<string>>,
) {
  return produce(state, (draft) => {
    draft.selectedApplicationDeadlines.push(payload);
  });
}

function removeFromSelectedApplicationDeadline(state: IPowerSearchState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.selectedApplicationDeadlines = draft.selectedApplicationDeadlines.filter((d) => d.id !== payload);
  });
}

function addCostOfAttendanceTypeSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.costOfAttendanceTypesSelected.push(payload);
  });
}

function removeFromCostOfAttendanceTypesSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.costOfAttendanceTypesSelected = draft.costOfAttendanceTypesSelected.filter((coa) => coa !== payload);
  });
}

function setCostOfAttendance(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.costOfAttendanceSelected = payload;
  });
}

function setAvgMeritAwardSelected(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.avgMeritAwardSelected = payload;
  });
}

function setAvgMeritAwardTypeSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.avgMeritAwardTypeSelected = payload;
  });
}

function addNetCostTypeSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.netCostTypesSelected.push(payload);
  });
}

function removeFromNetCostTypeSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.netCostTypesSelected = draft.netCostTypesSelected.filter((cost) => cost !== payload);
  });
}

function setNetCostAfterAvgMeritAwardSelected(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.netCostAfterAvgMeritAwardSelected = payload;
  });
}

function setNeedMetSelected(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.needMetSelected = payload;
  });
}

function setSchoolSize(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.schoolSize = payload;
  });
}

function setTestScoreValueSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.testScoreValueSelected = payload;
  });
}

function setSatScoreSelected(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.satScoreSelected = payload;
  });
}

function setActScoreSelected(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.actScoreSelected = payload;
  });
}

function setSelectedTestScoreType(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.selectedTestScoreType = payload;
  });
}

function setGpa(state: IPowerSearchState, { payload }: IAction<IMinMax>) {
  return produce(state, (draft) => {
    draft.gpa = payload;
  });
}

function setStudentGPATypeSelected(state: IPowerSearchState, { payload }: IAction<string>) {
  return produce(state, (draft) => {
    draft.studentGPATypeSelected = payload;
  });
}
