import { NavLink, useNavigate } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { CustomCheckbox } from '../custom-checkbox/CustomCheckbox';
import { AddListModal } from '../add-list-modal/AddListModal';
import { loadingAction, myListsAction } from '../../store/actions';
import { myListService } from '../../services';
import lockWhite from '../../images/locked-icon-white.svg';
import lockGrey from '../../images/locked-icon-grey-small.svg';
import notesIcon from '../../images/notes-icon.svg';
import selectedNotes from '../../images/notes-icon-selected.svg';
import { ICollegeNote } from '../../models';
import plusIcon from '../../images/plusForForm.svg';
import minusIcon from '../../images/minusForForm.svg';
import privateSchool from '../../images/privateSchool.svg';
import publicSchool from '../../images/publicSchool.svg';
import lockedBlue from '../../images/locked-icon-blue.svg';
import deleteIcon from '../../images/delete.svg';
import createListIcon from '../../images/create-list.svg';
import addToListIcon from '../../images/add-to-list-icon.svg';

interface headerPaginationTableInterface {
  id: number;
  value: string;
  property?: string;
  color: string;
  className?: string;
}
const defaultPaginationColumns: headerPaginationTableInterface[] = [
  { id: 1, value: 'Name', property: 'name', color: 'grey' },
  { id: 2, value: 'Type', property: 'isPrivate', color: 'grey' },
  { id: 3, value: 'State', property: 'state', color: 'grey' },
  { id: 4, value: 'Region', property: 'region', color: 'grey' },
  { id: 5, value: 'Video Tour', property: 'urlVirtualTour', color: 'grey' },
  { id: 8, value: 'Merit Offered', property: 'offers', color: 'grey' },
  { id: 9, value: 'Avg GPA', property: 'avgFreshmenGpa', color: 'grey' },
  { id: 11, value: 'Co-Op', property: 'coop', color: 'grey' },
  { id: 12, value: 'Go to Website', property: 'admissionsUrl', color: 'violet' },
  {
    id: 13,
    value: 'Crowdsourced Offers',
    color: 'violet',
    className: 'crowdsourced-offers',
  },
];
const profilePaginationColumns: headerPaginationTableInterface[] = [
  {
    id: 14,
    value: 'Endowment Per Student',
    property: 'endowmentPerStudent',
    color: 'violet',
  },
  {
    id: 15,
    value: '%UG Women',
    property: 'pcUndergraduateWomen',
    color: 'violet',
  },
  {
    id: 16,
    value: '%UG Men',
    property: 'pcUndergraduateMen',
    color: 'violet',
  },
  {
    id: 50,
    value: 'Student Faculty Ratio',
    property: 'studentToFaculty',
    color: 'violet',
  },
  {
    id: 17,
    value: '% Sophomore Returns',
    property: 'pcReturningForSophomore',
    color: 'violet',
  },
  {
    id: 18,
    value: '4Yr Grad Rate',
    property: 'fourYearGraduationRate',
    color: 'violet',
  },
  {
    id: 19,
    value: '% UG White',
    property: 'enWhiteNonhispanic',
    color: 'violet',
  },
  {
    id: 20,
    value: '% UG African American',
    property: 'enBlackNonhispanic',
    color: 'violet',
  },
  {
    id: 21,
    value: '% UG Hispanic/Latino',
    property: 'enHispanicEthnicity',
    color: 'violet',
  },
  {
    id: 22,
    value: '% UG Asian',
    property: 'enAsianNonhispanic',
    color: 'violet',
  },
  {
    id: 23,
    value: '% UG Non-Residential Alien',
    property: 'enNonresAlien',
    color: 'violet',
  },
];
const admissionsPaginationColumns: headerPaginationTableInterface[] = [
  {
    id: 24,
    value: 'Average High School GPA',
    property: 'avgFreshmenGpa',
    color: 'violet',
  },
  {
    id: 25,
    value: '4.0 GPA',
    property: 'avgFreshmenGpa1P',
    color: 'violet',
  },
  {
    id: 26,
    value: '3.75 - 3.99 GPA',
    property: 'avgFreshmenGpa2P',
    color: 'violet',
  },
  {
    id: 27,
    value: '3.5 - 3.74 GPA',
    property: 'avgFreshmenGpa3P',
    color: 'violet',
  },
  {
    id: 28,
    value: '3.0 - 3.49 GPA',
    property: 'avgFreshmenGpa4P',
    color: 'violet',
  },
  {
    id: 29,
    value: 'Below 3.0 GPA',
    property: 'avgFreshmenGpaBelow3P',
    color: 'violet',
  },
  {
    id: 30,
    value: 'SAT/ACT Score Required',
    property: 'testScoreRequirementType',
    color: 'violet',
  },
  {
    id: 31,
    value: 'Average SAT',
    property: 'satAvg',
    color: 'violet',
  },
  {
    id: 32,
    value: 'SAT 75th Percentile',
    property: 'sat75thPc',
    color: 'violet',
  },
  {
    id: 33,
    value: 'Average ACT',
    property: 'actAvg',
    color: 'violet',
  },
  {
    id: 34,
    value: 'ACT 75th Percentile',
    property: 'act75thPc',
    color: 'violet',
  },
  {
    id: 6,
    value: 'Overall Admit Rate',
    property: 'admissionsRate',
    color: 'violet',
  },
  {
    id: 35,
    value: 'Early Decision Admit Rate',
    property: 'edAdmitRate',
    color: 'violet',
  },
  {
    id: 73,
    value: 'Early Action Admit Rate',
    property: 'eaAdmitRate',
    color: 'violet',
  },
  {
    id: 36,
    value: 'Regular Decision Admit Rate',
    property: 'rdAdmitRate',
    color: 'violet',
  },
  {
    id: 37,
    value: 'Percent of Class Filled ED',
    property: 'edFilledRate',
    color: 'violet',
  },
  {
    id: 38,
    value: 'Admit Yield',
    property: 'admitYield',
    color: 'violet',
  },
  {
    id: 39,
    value: 'Application Fee',
    property: 'applicationFee',
    color: 'violet',
  },
  {
    id: 40,
    value: 'Early Decision Deadline',
    property: 'edDeadline',
    color: 'violet',
  },
  {
    id: 71,
    value: 'Early Action Deadline',
    property: 'eaDeadline',
    color: 'violet',
  },
  {
    id: 41,
    value: 'Early Decision 2 Deadline',
    property: 'ed2Deadline',
    color: 'violet',
  },
  {
    id: 42,
    value: 'Regular Decision Deadline',
    property: 'regDecisionDeadline',
    color: 'violet',
    className: 'regular-decision-deadline',
  },
  {
    id: 43,
    value: 'Financial Aid Deadline',
    property: 'finAidDeadline',
    color: 'violet',
  },
];
const scholashipsPaginationColumns: headerPaginationTableInterface[] = [
  {
    id: 44,
    value: 'Percent of Need Met',
    property: 'avgPcNeedMet',
    color: 'violet',
  },
  {
    id: 45,
    value: 'Average Financial Aid for Freshmen w/ Need',
    property: 'avgNeedBasedAward',
    color: 'violet',
    className: 'average-financial-aid',
  },
  {
    id: 46,
    value: 'CSS Profile',
    property: 'cssProfileRequired',
    color: 'violet',
  },
  {
    id: 47,
    value: 'Non-Custodial CSS Form Required',
    property: 'nonCustodialCssRequired',
    color: 'violet',
  },
  {
    id: 48,
    value: 'Freshmen w/out Need',
    property: 'pcStudentsWithoutNeed',
    color: 'violet',
  },
  {
    id: 49,
    value: 'Freshmen w/out Need Receiving Merit Aid',
    property: 'pcStudentsReceivingMeritAid',
    color: 'violet',
  },
  {
    id: 50,
    value: 'Average Merit Award (Freshmen w/out Need)',
    property: 'avgMeritAidAward',
    color: 'violet',
  },
  {
    id: 51,
    value: 'Merit Scholarship Details',
    property: 'scholarshipsUrl',
    color: 'violet',
    className: 'buttons',
  },
  {
    id: 52,
    value: 'Full Tuition / Full Ride Scholarship',
    property: 'urlFullScholarship',
    color: 'violet',
    className: 'buttons',
  },
];
const financePaginationColumns: headerPaginationTableInterface[] = [
  {
    id: 70,
    value: 'Private Attendance',
    property: 'coaPrivate',
    color: 'violet',
  },
  {
    id: 53,
    value: 'In-State Cost of Attendance',
    property: 'coaInState',
    color: 'violet',
  },
  {
    id: 54,
    value: 'Out-of-State Cost of Attendance',
    property: 'coaOutState',
    color: 'violet',
  },
  {
    id: 71,
    value: 'Private Net Cost',
    property: 'privateNetCost',
    color: 'violet',
  },
  {
    id: 72,
    value: 'In-State Net Cost',
    property: 'inStateNetCost',
    color: 'violet',
  },
  {
    id: 73,
    value: 'Out-of-State Net Cost',
    property: 'outStateNetCost',
    color: 'violet',
  },
  {
    id: 55,
    value: 'Average Net Price After Gift Aid',
    property: 'avgNetPriceAfterAid',
    color: 'violet',
  },
  {
    id: 56,
    value: 'Avg Net Price Family Income $0 - $30,000',
    property: 'avgPriceTo30000',
    color: 'violet',
  },
  {
    id: 57,
    value: 'Avg Net Price Family Income $30,001 - $48,000',
    property: 'avgPriceFrom30001To48000',
    color: 'violet',
  },
  {
    id: 58,
    value: 'Avg Net Price Family Income $48,001 - $75,000',
    property: 'avgPriceFrom48001To75000',
    color: 'violet',
  },
  {
    id: 59,
    value: 'Avg Net Price Family Income $75,001 - $110,000',
    property: 'avgPriceFrom75001To110000',
    color: 'violet',
  },
  {
    id: 60,
    value: 'Avg Net Price Family Income over $110,000',
    property: 'avgPriceOver110000',
    color: 'violet',
  },
  {
    id: 61,
    value: 'Net Price Calculator',
    property: 'urlAddressPriceCalc',
    color: 'violet',
    className: 'net-price-calculator',
  },
  {
    id: 62,
    value: '%Undergrads w/ Federal Student Loans',
    property: 'gradsAnyLoanP',
    color: 'violet',
  },
  {
    id: 63,
    value: 'Avg Annual Federal Student Loans for Undergrads',
    property: 'gradsAnyLoanAvgAmt',
    color: 'violet',
  },
  {
    id: 64,
    value: 'Grads From Recent Class w/ Any Type of Loan(s)',
    property: 'pcGraduatingWithLoans',
    color: 'violet',
  },
  {
    id: 65,
    value: 'Average Balances From Loans (All Types)',
    property: 'avgLoanAmount',
    color: 'violet',
  },
  {
    id: 66,
    value: 'Percent of Undergrads w/ Parent PLUS Loans',
    property: 'parentPlusP',
    color: 'violet',
  },
  {
    id: 67,
    value: 'Average Parent PLUS Loans per Borrower',
    property: 'parentPlusAvgAmt',
    color: 'violet',
  },
];

export const MyListTablePagination = ({
  sortBy,
  onHandleSortBy,
  onHandleModalContent,
  onHandleIsSortedInFrontend,
  onHandleIsDescSort,
  onHandleIsOpenLockedPopupOffers,
  onHandleShowOffers,
  onHandleIsOpenYoutubeModal,
  onHandleClickedCollege,
  onHandleLinkYoutubeModal,
  onHandleIsOpenMeritScholarship,
  onHandleIsAddNoteModal,
  onHandleIsOpenBookMark,
  openModal,
  readyPaginatedColleges,
  isDescSort,
  isOpenMeritScholarship,
  isOpenLockedPopupOffers,
  isOpenBookMark,
  isAdmin,
  actions,
  clickedCollege,
  notes,
  userId,
  showOffers,
  getOffers,
  saveRemoveCollege,
  selectedBookMarkRef,
  clickedCollegeFun,
  profileRef,
  admissionsRef,
  scholashipsRef,
  financialRef,
}: {
  sortBy: any;
  onHandleSortBy: React.Dispatch<object>;
  onHandleModalContent: React.Dispatch<{ title: string; message: string }>;
  onHandleIsSortedInFrontend: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIsDescSort: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIsOpenMeritScholarship: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIsOpenLockedPopupOffers: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleShowOffers: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIsOpenYoutubeModal: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleClickedCollege: React.Dispatch<React.SetStateAction<any>>;
  onHandleLinkYoutubeModal: any;
  onHandleIsAddNoteModal: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleIsOpenBookMark: React.Dispatch<React.SetStateAction<boolean>>;
  openModal: Function;
  readyPaginatedColleges: any;
  isDescSort: boolean;
  isOpenMeritScholarship: boolean;
  isOpenLockedPopupOffers: boolean;
  isAdmin: boolean;
  isOpenBookMark: boolean;
  actions: any;
  clickedCollege: any;
  notes: ICollegeNote[];
  userId: number;
  showOffers: boolean;
  getOffers: any;
  saveRemoveCollege: Function;
  selectedBookMarkRef: React.Ref<any>;
  clickedCollegeFun: Function;
  profileRef: React.Ref<any>;
  admissionsRef: React.Ref<any>;
  scholashipsRef: React.Ref<any>;
  financialRef: React.Ref<any>;
}) => {
  const defaultCollege = {
    college: {},
    id: -100,
    name: '',
    stateId: -3,
    ipeds: -106263,
  };

  const { myLists, selectedList } = useSelector((state: any) => state.myListsState);
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { profile, admissions, scholaships, finance } = useSelector((state: any) => state.switcherBlocksMyListState);
  const [checkedColleges, setCheckedColleges] = useState<Array<{ id: number }>>([]);
  const [indexOpenBookmark, setIndexOpenBookmark] = useState<number>();
  const [isOpenAllNoneAutoComplete, setIsOpenAllNoneAutoComplete] = useState<boolean>(false);
  const [isOpenDotsAutoComplete, setIsOpenDotsAutoComplete] = useState<boolean>(false);
  const [isOpenAddNewListModal, setIsOpenAddNewListModal] = useState<boolean>(false);
  const navigate = useNavigate();
  const childRef = useRef<any>();
  const arrowRef = useRef<any>(null);
  const dotsRef = useRef<any>(null);
  const paginationModal = useRef<any>(null);
  const dispatch = useDispatch();

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  useEffect(() => {
    const handleClickOutsideArrow = (event): void => {
      const arrowAutocompleteRef: any = arrowRef.current;
      if (arrowAutocompleteRef && !arrowAutocompleteRef.contains(event.target)) {
        setIsOpenAllNoneAutoComplete(false);
      }
    };

    const handleClickOutsideDots = (event): void => {
      const dotsAutocompleteRef: any = dotsRef.current;
      if (dotsAutocompleteRef && !dotsAutocompleteRef.contains(event.target)) {
        setIsOpenDotsAutoComplete(false);
      }
    };

    document.addEventListener('click', handleClickOutsideArrow);
    document.addEventListener('click', handleClickOutsideDots);

    return () => {
      document.removeEventListener('click', handleClickOutsideArrow);
      document.removeEventListener('click', handleClickOutsideDots);
    };
  }, []);

  useEffect(() => {
    const modal: any = document.getElementsByClassName('pagination-modal')[0];
    const modal2: any = document.getElementsByClassName('pagination-modal-2')[0];
    const modal3: any = document.getElementsByClassName('pagination-modal-3')[0];
    const modalContent: any = document.getElementsByClassName('pagination-modal-content')[0];
    const modalContent2: any = document.getElementsByClassName('pagination-modal-content')[1];
    const modalContent3: any = document.getElementsByClassName('pagination-modal-content')[2];
    const modalImg: any = document.getElementsByClassName('pagination-modal-image')[0];
    const modalImg2: any = document.getElementsByClassName('pagination-modal-image')[1];
    const modalImg3: any = document.getElementsByClassName('pagination-modal-image')[2];
    const modalInfoLocked = document.getElementsByClassName('pagination-modal-info-locked')[0];
    const modalInfoLocked2 = document.getElementsByClassName('pagination-modal-info-locked')[1];
    const modalInfoLocked3 = document.getElementsByClassName('pagination-modal-info-locked')[2];
    const modal3Text: any = document.getElementsByClassName('pagination-modal-3-text')[0];

    if (modal && readyPaginatedColleges.length >= 5) {
      modal.setAttribute('style', `height: ${5 * 70}px`);
    }
    if (modal2) {
      modal2.setAttribute('style', `height: ${5 * 70}px`);
    }
    if (modal3) {
      modal3.setAttribute('style', `height: ${5 * 70}px`);
    }

    if (readyPaginatedColleges.length < 5) {
      if (modalContent) {
        modalContent.setAttribute(
          'style',
          'height: 34px;' +
            'top: 3.5px;' +
            'width: 80%;' +
            'left: 33px;' +
            'flex-direction: row;' +
            'justify-content: center',
        );
      }
      if (modalContent2) {
        modalContent2.setAttribute(
          'style',
          'height: 34px;' +
            'top: 3.5px;' +
            'width: 80%;' +
            'left: 33px;' +
            'flex-direction: row;' +
            'justify-content: center',
        );
      }
      if (modalContent3) {
        modalContent3.setAttribute(
          'style',
          'height: 34px;' +
            'top: 3.5px;' +
            'width: 80%;' +
            'left: 33px;' +
            'flex-direction: row;' +
            'justify-content: center',
        );
      }
      if (modal) {
        modal.style.height = `${readyPaginatedColleges.length * 77 - 21}px`;
      }
      if (modal2) {
        modal.style.height = `${readyPaginatedColleges.length * 77 - 21}px`;
      }
      if (modal3) {
        modal.style.height = `${readyPaginatedColleges.length * 77 - 21}px`;
      }
      if (modalImg) {
        modalImg.style.height = '25px';
      }
      if (modalImg2) {
        modalImg2.style.height = '25px';
      }
      if (modalImg3) {
        modalImg3.style.height = '25px';
      }
      if (modalInfoLocked) {
        modalInfoLocked.setAttribute('style', 'display: none');
      }
      if (modalInfoLocked2) {
        modalInfoLocked2.setAttribute('style', 'display: none');
      }
      if (modalInfoLocked3) {
        modalInfoLocked3.setAttribute('style', 'display: none');
      }

      if (readyPaginatedColleges.length === 2) {
        if (modal && modalContent) {
          modal.setAttribute('style', 'top: 70px; height: 140px');
          modalContent.setAttribute('style', 'top: 18.5px; height: 77px');
        }
        if (modal2 && modalContent2) {
          modal2.setAttribute('style', 'top: 70px; height: 140px');
          modalContent2.setAttribute('style', 'top: 18.5px; height: 77px');
        }
        if (modal3 && modalContent3) {
          modal3.setAttribute('style', 'top: 70px; height: 140px');
          modalContent3.setAttribute('style', 'top: 18.5px; height: 77px; width: 295px');
        }
      }
      if (readyPaginatedColleges.length >= 3 && readyPaginatedColleges.length < 5) {
        if (modal && modalContent && modalInfoLocked) {
          modal.style.height = `${readyPaginatedColleges.length * 70}px`;
          modalContent.setAttribute('style', 'flex-direction: column; height: 135px');
          modalInfoLocked.setAttribute('style', 'display: block');
        }
        if (modal2 && modalContent2 && modalInfoLocked2) {
          modal2.style.height = `${readyPaginatedColleges.length * 70}px`;
          modalContent2.setAttribute('style', 'flex-direction: column; height: 135px');
          modalInfoLocked2.setAttribute('style', 'display: block');
        }
        if (modal3 && modalContent3 && modalInfoLocked3) {
          modal3.style.height = `${readyPaginatedColleges.length * 70}px`;
          modalContent3.setAttribute('style', 'flex-direction: column; height: 135px; width: 295px');
          modalInfoLocked3.setAttribute('style', 'display: block');
        }
      }
      if (readyPaginatedColleges.length === 1) {
        if (modal && modalContent) {
          modal.style.height = '70px';
          modalContent.style.top = '7.5px';
        }
        if (modal2 && modalContent2) {
          modal2.style.height = '70px';
          modalContent2.style.top = '7.5px';
        }
        if (modal3 && modalContent3 && modal3Text) {
          modal3Text.style.display = 'none';
          modalContent3.style.left = '15px';
          modal3.style.height = '70px';
          modalContent3.style.top = '7.5px';
        }
      }
    }
  }, [readyPaginatedColleges.length]);

  const isNoteExists = (collegeId: number) => {
    if (notes.length) {
      const matchingNotes = notes.filter((note) => note.collegeId === collegeId && note.userId === userId);
      return matchingNotes.length > 0;
    }
    return false;
  };
  const removeCollegesFromAllLists = async () => {
    try {
      const idsColleges = checkedColleges.map((obj) => obj.id);
      await myListService.endpoint_remove_colleges_from_all_my_list({
        userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
        collegeIds: idsColleges,
      });
      dispatch(myListsAction.getListById(selectedList.id));
    } catch (error) {
      console.log('error', error);
    }
  };

  const removeCollegeFromList = async (listName) => {
    if (!listName) return;
    const idsColleges = checkedColleges.map((obj) => obj.id);

    await myListService.endpoint_remove_colleges_from_my_list({
      userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
      listName,
      collegeIds: idsColleges,
    });

    dispatch(myListsAction.getListById(selectedList.id));
  };

  const addCollegeToList = async (listName: string) => {
    if (!listName) return;
    const idsColleges = checkedColleges.map((obj) => obj.id);

    await myListService.endpoint_add_colleges_to_my_list({
      userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
      listName,
      collegesIds: idsColleges,
    });
  };

  const createList = async (listName: string) => {
    try {
      if (!listName) return;
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      });

      const idsColleges = checkedColleges.map((obj) => obj.id);

      const readyIds = idsColleges.map((college) => Number(college));
      const responseFromCreating = await myListService.endpoint_create_my_list({
        userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
        listName,
      });

      if (responseFromCreating.data) {
        const responseFromAdding = await myListService.endpoint_add_colleges_to_my_list({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          listName,
          collegesIds: readyIds,
        });

        if (responseFromAdding.data) {
          const { data } = await myListService.endpoint_get_my_lists(
            adminNavigatedUser ? adminNavigatedUser.id : user.id,
          );
          dispatch(myListsAction.setMyLists(data));
          dispatch(myListsAction.setSelectedList(data[data.length - 1]));
          loadingAction.updateLoadingStatus({
            myListLoading: false,
          });
        }
      }
      close();
    } catch (e: any) {
      console.error(e);
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      });
    }
  };

  const removeAllCollegesFromCheckedArray = () => {
    setCheckedColleges([]);
  };

  const addOrRemoveAllCollegesInCheckedArray = () => {
    if (checkedColleges.length > 0) {
      setCheckedColleges([]);
      return;
    }

    const readyColleges = readyPaginatedColleges.map((college) => ({ id: college.id }));
    setCheckedColleges([...readyColleges]);
  };

  const addAllCollegesInCheckedArray = () => {
    const readyCollegesArray = readyPaginatedColleges.map((college) => ({ id: college.id }));

    const result = mergeTwoArraysWithoutDuplicates(readyCollegesArray, checkedColleges);

    setCheckedColleges([...result]);
  };

  const mergeTwoArraysWithoutDuplicates = (array1: Array<any>, array2: Array<any>) => {
    const combinedArray = [...array1, ...array2];
    const uniqueObjects = new Set();

    return combinedArray.filter((obj) => {
      if (!uniqueObjects.has(obj.id)) {
        uniqueObjects.add(obj.id);
        return true;
      }
      return false;
    });
  };

  const addOrRemoveCollegeInCheckedArray = (college) => {
    if (!college) return;

    const readyCollege = {
      id: college.id,
    };

    const isInArray = checkedColleges.some((college) => college.id === readyCollege.id);

    if (isInArray) {
      const readyColleges = checkedColleges.filter((college) => college.id !== readyCollege.id);
      setCheckedColleges([...readyColleges]);
      return;
    }

    const readyColleges = [...checkedColleges, readyCollege];
    setCheckedColleges([...readyColleges]);
  };

  const checkOrCollegeIsChecked = (id: number): boolean => {
    if (!id) return false;
    return checkedColleges.some((item) => item.id === id);
  };

  const getFixedNumberWithDollar = (number: any): string => {
    const fixedNumber = (+number).toFixed(0);
    return `$ ${(+fixedNumber).toLocaleString('en-US')}`;
  };
  const getFixedNumberWithPercent = (number: any): string => {
    const fixedNumber = (+number).toFixed(0);
    return `${fixedNumber} %`;
  };
  const formatDateByMonthDay = (date: string): string => {
    const month = date.slice(5, 7);
    const day = date.slice(8, 10);
    let formatedDate;
    switch (month) {
      case '01':
        formatedDate = `Jan ${day}`;
        break;
      case '02':
        formatedDate = `Feb ${day}`;
        break;
      case '03':
        formatedDate = `Mar ${day}`;
        break;
      case '04':
        formatedDate = `Apr ${day}`;
        break;
      case '05':
        formatedDate = `May ${day}`;
        break;
      case '06':
        formatedDate = `June ${day}`;
        break;
      case '07':
        formatedDate = `July ${day}`;
        break;
      case '08':
        formatedDate = `Aug ${day}`;
        break;
      case '09':
        formatedDate = `Sep ${day}`;
        break;
      case '10':
        formatedDate = `Oct ${day}`;
        break;
      case '11':
        formatedDate = `Nov ${day}`;
        break;
      case '12':
        formatedDate = `Dec ${day}`;
        break;
      default:
        formatedDate = 'N/A';
    }
    return formatedDate;
  };

  useEffect(() => {
    if (readyPaginatedColleges?.length === 0) return;
    if (readyPaginatedColleges?.length <= 4) {
      const element: HTMLElement = document.getElementsByClassName(
        `table-pagination-row-relative-autocomplete-${indexOpenBookmark}`,
      )[0] as HTMLElement;
      if (!element) return;
      if (indexOpenBookmark === 3) {
        element.style.top = '';
      } else if (indexOpenBookmark === 0) {
        element.style.bottom = 'unset';
        element.style.top = '50px';
      }
    }
  }, [indexOpenBookmark, readyPaginatedColleges?.length]);

  return isAdmin ? (
    <>
      {isOpenAllNoneAutoComplete && (
        <div className="table-pagination-header-cont__arrow__autocomplete">
          <p onClick={addAllCollegesInCheckedArray}>All</p>
          <p onClick={removeAllCollegesFromCheckedArray}>None</p>
        </div>
      )}
      {isOpenDotsAutoComplete && (
        <div className="table-pagination-header-cont__dots__autocomplete">
          <span onClick={removeCollegesFromAllLists}>
            <img src={deleteIcon} alt="delete college from list" />
            <p>Delete from All List</p>
          </span>
          <span onClick={() => removeCollegeFromList(selectedList.name)}>
            <img src={deleteIcon} alt="delete college from list" />
            <p>Delete from {selectedList.name.length > 30 ? selectedList.name.slice(0, 30) : selectedList.name}</p>
          </span>
          {myLists
            .filter((list) => list.name !== selectedList.name)
            .map((filteredList) => (
              <span onClick={() => addCollegeToList(filteredList.name)}>
                <img src={addToListIcon} alt="add college to list" />
                <p>Add to {filteredList.name.length > 30 ? filteredList.name.slice(0, 30) : filteredList.name}</p>
              </span>
            ))}
          <span
            onClick={() => {
              open();
              setIsOpenAddNewListModal(true);
            }}
          >
            <img src={createListIcon} alt="create new list" />
            <p>Create New List</p>
          </span>
        </div>
      )}
      <section className="table-pagination">
        <div className="table-pagination-container-for-name-sticky" style={{ zIndex: 102 }}>
          <div
            style={{ width: '388px' }}
            id={`${defaultPaginationColumns[0].id}`}
            key={defaultPaginationColumns[0].value}
            className={
              defaultPaginationColumns[0].value === 'Name'
                ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${defaultPaginationColumns[0].color}`
                : defaultPaginationColumns[0].value === 'Go to Website'
                ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${defaultPaginationColumns[0].color}`
                : defaultPaginationColumns[0].className
                ? `table-pagination-header-cont header-grid-text-color-${defaultPaginationColumns[0].color} table-pagination-header-cont-${defaultPaginationColumns[0].className}`
                : `table-pagination-header-cont header-grid-text-color-${defaultPaginationColumns[0].color}`
            }
          >
            <div
              style={{ width: '88px' }}
              className={`table-pagination-header-cont table-pagination-header-cont__first header-grid-text-color-${defaultPaginationColumns[0].color}`}
            >
              <span
                className="table-pagination-header-text table-pagination-header-text__first"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="table-pagination-header-cont table-pagination-header-cont__first table-pagination-header-checkbox-cont">
                  <CustomCheckbox
                    onHandleChecked={addOrRemoveAllCollegesInCheckedArray}
                    checked={selectedList?.colleges.length === checkedColleges.length}
                    unChecked={selectedList?.colleges.length !== checkedColleges.length && checkedColleges.length > 0}
                    additionalClass="table-pagination-header-text__checkbox"
                    additionalClassForInput="table-pagination-header-text__checkbox__input"
                  />
                  <span
                    ref={arrowRef}
                    onClick={() => {
                      setIsOpenAllNoneAutoComplete(!isOpenAllNoneAutoComplete);
                    }}
                    className={`table-pagination-header-cont__arrow ${isOpenAllNoneAutoComplete ? 'opened' : 'closed'}`}
                  />
                </div>
                <span
                  ref={dotsRef}
                  onClick={() => {
                    setIsOpenDotsAutoComplete(!isOpenDotsAutoComplete);
                  }}
                  className={`table-pagination-header-cont__dots ${isOpenDotsAutoComplete ? 'opened' : 'closed'}`}
                />
              </span>
            </div>
            <div className="table-pagination-header-cont__name-cont">
              <span
                className="table-pagination-header-text"
                onClick={(e) => {
                  e.preventDefault();
                  if (sortBy === 'default') {
                    onHandleSortBy(defaultPaginationColumns[0]);
                  } else if (sortBy.value !== defaultPaginationColumns[0].value) {
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(!isDescSort);
                  } else if (sortBy.value === defaultPaginationColumns[0].value) {
                    onHandleIsDescSort(!isDescSort);
                    onHandleIsSortedInFrontend(true);
                  }
                }}
              >
                {defaultPaginationColumns[0].value}
              </span>
              <div className="table-pagination-header-cont-img">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(false);
                  }}
                  className={`${
                    sortBy?.value === defaultPaginationColumns[0].value && !isDescSort ? 'selected' : ''
                  } table-pagination-header-img-${
                    defaultPaginationColumns[0].color
                  } table-pagination-header-img-rotated`}
                />
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(true);
                  }}
                  className={`${
                    sortBy?.value === defaultPaginationColumns[0].value && isDescSort ? 'selected' : ''
                  } table-pagination-header-img-${defaultPaginationColumns[0].color}`}
                />
              </div>
            </div>
          </div>
          <div className={`table-pagination-header table-pagination-header-cont-main-content-main`}>
            {defaultPaginationColumns.slice(1).map((el: headerPaginationTableInterface) => (
              <div
                id={`${el.id}`}
                key={el.value}
                className={
                  el.value === 'Name'
                    ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${el.color}`
                    : el.value === 'Go to Website'
                    ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${el.color}`
                    : el.className
                    ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                    : `table-pagination-header-cont header-grid-text-color-${el.color}`
                }
              >
                <span
                  className="table-pagination-header-text"
                  onClick={(e) => {
                    e.preventDefault();
                    if (sortBy === 'default') {
                      onHandleSortBy(el);
                    } else if (sortBy.value !== el.value) {
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(!isDescSort);
                    } else if (sortBy.value === el.value) {
                      onHandleIsDescSort(!isDescSort);
                    }
                  }}
                >
                  {el.value}
                </span>
                <div className="table-pagination-header-cont-img">
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(false);
                    }}
                    className={`${
                      sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                    } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                  />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(true);
                    }}
                    className={`${
                      sortBy?.value === el.value && isDescSort ? 'selected' : ''
                    } table-pagination-header-img-${el.color}`}
                  />
                </div>
              </div>
            ))}
          </div>
          {profile && (
            <div
              ref={profileRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {profilePaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.value === 'Name'
                      ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${el.color}`
                      : el.value === 'Go to Website'
                      ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${el.color}`
                      : el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!adminNavigatedUser.subscription && (
                <div ref={paginationModal} className="pagination-modal pagination-modal-1">
                  <div className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span>Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}
          {admissions && (
            <div
              ref={admissionsRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {admissionsPaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!adminNavigatedUser.subscription && (
                <div id="pagination-modal" className="pagination-modal pagination-modal-2">
                  <div id="pagination-modal-content" className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span>Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}

          {scholaships && (
            <div
              ref={scholashipsRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {scholashipsPaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!adminNavigatedUser.subscription && (
                <div id="pagination-modal" className="pagination-modal pagination-modal-3">
                  <div id="pagination-modal-content" className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span className="pagination-modal-3-text">Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}
          {finance && (
            <div
              ref={financialRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {financePaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {!adminNavigatedUser.subscription && readyPaginatedColleges.length > 5 && (
          <div className="table-pagination-row-relative-locked-modal">
            <img className="table-pagination-row-relative-locked-modal-img" src={lockedBlue} alt="lock" />
            <h5 className="table-pagination-row-relative-locked-modal-locked-text">Information Locked</h5>
            <p className="table-pagination-row-relative-locked-modal-more-info">
              View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to
              premium features and data.
            </p>
            <NavLink className="table-pagination-row-relative-locked-modal-btn" to={'/buy-plan'}>
              Unlock
            </NavLink>
          </div>
        )}
        {readyPaginatedColleges.length > 0 &&
          readyPaginatedColleges?.map((el, index) => {
            if (index > 4 && !user.subscription && user.role !== 'admin') {
              el = {};
            }
            return (
              <div
                key={index}
                className={
                  index > 4 && !adminNavigatedUser.subscription && index % 2
                    ? `table-pagination-row table-pagination-row-odd table-pagination-row-blur ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : index > 4 && !adminNavigatedUser.subscription
                    ? `table-pagination-row table-pagination-row-blur ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : index === 0 && !adminNavigatedUser.subscription && readyPaginatedColleges.length > 5
                    ? `table-pagination-row ${checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'}`
                    : index % 2
                    ? `table-pagination-row table-pagination-row-odd ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : `table-pagination-row ${checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'}`
                }
              >
                <div
                  className={
                    index % 2
                      ? `table-pagination-row-container-name table-pagination-row-container-name-${index} ${
                          readyPaginatedColleges?.length <= 3 ? 'table-pagination-row-container-name-limit' : ''
                        } ${checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'}`
                      : `${
                          checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                        } table-pagination-row-container-name table-pagination-row-container-name-odd table-pagination-row-container-name-${index} ${
                          readyPaginatedColleges?.length <= 3 ? 'table-pagination-row-container-name-limit' : ''
                        }`
                  }
                  style={{
                    zIndex:
                      indexOpenBookmark === index && (user.subscription || user.role === 'admin')
                        ? '100000'
                        : indexOpenBookmark === index && (!user.subscription || user.role !== 'admin')
                        ? '100000'
                        : indexOpenBookmark !== index && (user.subscription || user.role === 'admin')
                        ? '100'
                        : '101',
                  }}
                >
                  <CustomCheckbox
                    onHandleChecked={() => addOrRemoveCollegeInCheckedArray(el)}
                    checked={checkOrCollegeIsChecked(el.id)}
                    additionalClass="table-pagination-row-container-name__checkbox"
                  />
                  <div className="table-pagination-row-container-name__content">
                    {selectedList?.colleges?.some((element) => element.collegeId === el.id) ? (
                      <img
                        ref={selectedBookMarkRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          onHandleClickedCollege(el);
                          onHandleIsOpenBookMark(!isOpenBookMark);
                          setIndexOpenBookmark(index);
                          setIsOpenDotsAutoComplete(false);
                          setIsOpenAllNoneAutoComplete(false);
                        }}
                        className="table-pagination-row-container-name-bookmark"
                        src={minusIcon}
                        alt="unsave"
                      />
                    ) : (
                      <img
                        ref={selectedBookMarkRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          onHandleClickedCollege(el);
                          onHandleIsOpenBookMark(!isOpenBookMark);
                          setIndexOpenBookmark(index);
                          setIsOpenDotsAutoComplete(false);
                          setIsOpenAllNoneAutoComplete(false);
                        }}
                        className="table-pagination-row-container-name-bookmark"
                        src={plusIcon}
                        alt="save"
                      />
                    )}
                    <a
                      onClick={() => {
                        if (!adminNavigatedUser.subscription) {
                          onHandleIsAddNoteModal(true);
                          onHandleClickedCollege(defaultCollege);
                          openModal();
                          return;
                        }
                        onHandleIsAddNoteModal(true);
                        onHandleClickedCollege(el);
                        openModal();
                      }}
                    >
                      {!adminNavigatedUser.subscription && index % 2 ? (
                        <img src={lockWhite} alt="lock" />
                      ) : !adminNavigatedUser.subscription ? (
                        <img src={lockGrey} alt="lock" />
                      ) : (
                        <span />
                      )}
                      <img
                        className="table-pagination-row-container-name-notes"
                        src={isNoteExists(el.id) ? selectedNotes : notesIcon}
                        alt="notes"
                      />
                    </a>

                    {clickedCollege && actions && el.id === clickedCollege.id && isOpenBookMark && (
                      <Autocomplete
                        classN={
                          index === readyPaginatedColleges.length ||
                          index === readyPaginatedColleges.length - 1 ||
                          index === readyPaginatedColleges.length - 2 ||
                          index === readyPaginatedColleges.length - 3
                            ? `table-pagination-row-relative-autocomplete table-pagination-row-relative-autocomplete-last table-pagination-row-relative-autocomplete-${index}`
                            : `table-pagination-row-relative-autocomplete table-pagination-row-relative-autocomplete-${index}`
                        }
                        isBudgetItems={false}
                        ref={childRef}
                        onclick={(item: any) => saveRemoveCollege(item.id, item.action)}
                        filteredItem={actions}
                        setClickedInMyListTable={clickedCollegeFun}
                      />
                    )}

                    <span
                      className="table-pagination-row-name"
                      onClick={() => navigate(`/college-details/${el.id}`, { state: '/my-lists' })}
                    >
                      {el.name}
                    </span>
                  </div>
                </div>
                <div className="table-pagination-row-main-container">
                  <div className="table-pagination-row-container-type">
                    <span data-tip={`${el.isPrivate ? 'Private' : 'Public'}`}>
                      <img src={el.isPrivate ? privateSchool : publicSchool} alt="type college" />
                    </span>
                  </div>
                  <div className="table-pagination-row-container-state">
                    <span className="table-pagination-row-state">{el?.state?.name}</span>
                  </div>
                  <div className="table-pagination-row-container-region">
                    <span className="table-pagination-row-region">{el?.state?.region?.name}</span>
                  </div>
                  <div className="table-pagination-row-container-camera">
                    {el.urlVirtualTour ? (
                      <a
                        target="_blank"
                        href={el?.urlVirtualTour}
                        rel="noreferrer"
                        aria-label="My list table 2"
                        onClick={(e) => {
                          e.preventDefault();
                          onHandleIsOpenYoutubeModal(true);
                          onHandleLinkYoutubeModal(el?.urlVirtualTour);
                          openModal();
                        }}
                      >
                        <div className="table-pagination-row-camera-img" />
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="table-pagination-row-container-merits-offered">
                    <span className="table-pagination-row-merits-offered">
                      {el?.pcStudentsReceivingMeritAid && Number(el?.pcStudentsReceivingMeritAid) > 1.0 ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className="table-pagination-row-container-avg-gpa">
                    <span className="table-pagination-row-avg-gpa">
                      {el.avgFreshmenGpa ? `${(+el.avgFreshmenGpa).toFixed(2)}` : '-'}
                    </span>
                  </div>
                  <div className="table-pagination-row-container-coop">
                    <span className="table-pagination-row-coop">{el.coop ? 'Yes' : 'No'}</span>
                  </div>
                  <div className="table-pagination-row-container-website">
                    {el.admissionsUrl ? (
                      <a
                        href={el.admissionsUrl}
                        target="_blank"
                        rel="noreferrer"
                        className="table-pagination-row-website-link"
                      >
                        <p>View</p>
                        <span className="table-pagination-row-website-link-img" />
                      </a>
                    ) : (
                      <span className="table-pagination-row-website">-</span>
                    )}
                  </div>
                  <div className="table-pagination-row-container-website table-pagination-row-container-btn">
                    {!adminNavigatedUser.subscription && (
                      <img className="table-pagination-row-container-img-locked" src={lockGrey} alt="lock icon" />
                    )}
                    <div
                      onClick={() => {
                        if (!adminNavigatedUser.subscription) {
                          onHandleIsOpenLockedPopupOffers(!isOpenLockedPopupOffers);
                          openModal();
                          return;
                        }
                        onHandleShowOffers(!showOffers);
                        getOffers(el.id);
                      }}
                      className="table-pagination-row-website-link"
                    >
                      <p>View</p>
                      <span className="table-pagination-row-website-link-img" />
                    </div>
                  </div>
                </div>
                {profile && (
                  <div className="table-pagination-row-profile-container">
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el.endowmentPerStudent
                          ? getFixedNumberWithDollar(el.endowmentPerStudent)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-underwomen">
                      <span className="table-pagination-row-underwomen">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.pcUndergraduateWomen
                          ? getFixedNumberWithPercent(el.pcUndergraduateWomen)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.pcUndergraduateMen
                          ? getFixedNumberWithPercent(el.pcUndergraduateMen)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.studentToFaculty
                          ? `${(+el.studentToFaculty).toFixed(0)} : 1`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.pcReturningForSophomore
                          ? getFixedNumberWithPercent(el?.pcReturningForSophomore)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-graduation-rate">
                      <span className="table-pagination-row-graduation-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.fourYearGraduationRate
                          ? getFixedNumberWithPercent(el.fourYearGraduationRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-white-non">
                      <span className="table-pagination-row-white-non">
                        {el?.enWhiteNonhispanic ? getFixedNumberWithPercent(el.enWhiteNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-black-non">
                      <span className="table-pagination-row-black-non">
                        {el?.enBlackNonhispanic ? getFixedNumberWithPercent(el.enBlackNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-latino-non">
                      <span className="table-pagination-row-latino-non">
                        {el?.enHispanicEthnicity ? getFixedNumberWithPercent(el.enHispanicEthnicity) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-asian-non">
                      <span className="table-pagination-row-asian-non">
                        {el?.enAsianNonhispanic ? getFixedNumberWithPercent(el.enAsianNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-alien-non">
                      <span className="table-pagination-row-alien-non">
                        {el?.enNonresAlien ? getFixedNumberWithPercent(el.enNonresAlien) : '-'}
                      </span>
                    </div>
                  </div>
                )}
                {admissions && (
                  <div className="table-pagination-row-admissions-container">
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa ? `${+el.avgFreshmenGpa}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa1P ? `${(+el.avgFreshmenGpa1P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa2P ? `${(+el.avgFreshmenGpa2P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa3P ? `${(+el.avgFreshmenGpa3P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa4P ? `${(+el.avgFreshmenGpa4P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el?.avgFreshmenGpaBelow3P
                          ? `${(+el.avgFreshmenGpaBelow3P).toFixed(0)} %`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-testScoreRequirementType">
                      <span className="table-pagination-row-testScoreRequirementType">
                        {/* {!adminNavigatedUser.subscription */}
                        {/*  ? 'N/A' */}
                        {/*  : el?.testScoreRequirementType === false */}
                        {/*  ? 'No Optional' */}
                        {/*  : el?.testScoreRequirementType === true */}
                        {/*  ? 'Optional' */}
                        {/*  : 'Test Optional'} */}
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el?.testScoreRequirementType === null
                          ? 'No Optional'
                          : `${el?.testScoreRequirementType}`}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-sat">
                      <span className="table-pagination-row-sat">
                        {!adminNavigatedUser.subscription ? 'N/A' : el?.satAvg ? `${(+el.satAvg).toFixed(0)}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-sat">
                      <span className="table-pagination-row-sat">
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el?.sat75thPc
                          ? `${(+el.sat75thPc).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-act">
                      <span className="table-pagination-row-act">
                        {!adminNavigatedUser.subscription ? 'N/A' : el?.actAvg ? `${(+el.actAvg).toFixed(0)}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-act">
                      <span className="table-pagination-row-act">
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el?.act75thPc
                          ? `${(+el.act75thPc).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-overall-admit">
                      <span className="table-pagination-row-overall-admit">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.admissionsRate
                          ? getFixedNumberWithPercent(el.admissionsRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.edAdmitRate
                          ? getFixedNumberWithPercent(el.edAdmitRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {!adminNavigatedUser.subscription
                          ? 'N/A'
                          : el.eaAdmitRate
                          ? getFixedNumberWithPercent(`${el.eaAdmitRate}`)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.rdAdmitRate
                          ? getFixedNumberWithPercent(el.rdAdmitRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.edFilledRate
                          ? getFixedNumberWithPercent(el.edFilledRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.admitYield
                          ? getFixedNumberWithPercent(el.admitYield)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-app-fee">
                      <span className="table-pagination-row-app-fee">
                        {el?.applicationFee ? getFixedNumberWithDollar(el.applicationFee) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.edDeadline ? formatDateByMonthDay(`${el.edDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.eaDeadline ? formatDateByMonthDay(`${el.eaDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.ed2Deadline ? formatDateByMonthDay(`${el.ed2Deadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-regular">
                      <span className="table-pagination-row-edDeadline">
                        {el.regDecisionDeadline ? formatDateByMonthDay(`${el.regDecisionDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.finAidDeadline ? formatDateByMonthDay(`${el.finAidDeadline}`) : '-'}
                      </span>
                    </div>
                  </div>
                )}
                {scholaships && (
                  <div className="table-pagination-row-scholarships-container">
                    <div className="table-pagination-row-container-need-met">
                      <span className="table-pagination-row-need-met">
                        {el?.avgPcNeedMet ? getFixedNumberWithPercent(el.avgPcNeedMet) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-need-met table-pagination-row-container-need-met-average">
                      <span className="table-pagination-row-need-met">
                        {el?.avgNeedBasedAward ? getFixedNumberWithDollar(el.avgNeedBasedAward) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-coop">
                      <span className="table-pagination-row-coop">{el.cssProfileRequired ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-pagination-row-container-coop table-pagination-row-container-coop-2">
                      <span className="table-pagination-row-coop">{el.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate table-pagination-row-container-admit-rate-2">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A%'
                          : el?.pcStudentsWithoutNeed
                          ? getFixedNumberWithPercent(el.pcStudentsWithoutNeed)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.pcStudentsReceivingMeritAid
                          ? getFixedNumberWithPercent(el.pcStudentsReceivingMeritAid)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!adminNavigatedUser.subscription
                          ? 'N/A %'
                          : el?.avgMeritAidAward
                          ? getFixedNumberWithDollar(el.avgMeritAidAward)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.scholarshipsUrl ? (
                        <a
                          onClick={() => {
                            if (!adminNavigatedUser.subscription) return;
                            if (!el?.scholarshipsUrl) {
                              onHandleModalContent({
                                title: 'Merit Scholarship Details not Available',
                                message:
                                  'We are sorry to inform you that Merit Scholarship details for this college are not available at the moment.',
                              });
                              onHandleIsOpenMeritScholarship(!isOpenMeritScholarship);
                              openModal();
                            }
                          }}
                          target="_blank"
                          rel="noreferrer"
                          href={el.scholarshipsUrl}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.urlFullScholarship ? (
                        <a
                          onClick={() => {
                            if (!adminNavigatedUser.subscription) return;
                            if (!el?.urlFullScholarship) {
                              onHandleModalContent({
                                title: 'Full Tuition/ Full Ride Scholarship Details not Available',
                                message:
                                  'We are sorry to inform you that Full Tuition/ Full Ride Scholarship details for this college are not available at the moment.',
                              });
                              onHandleIsOpenMeritScholarship(!isOpenMeritScholarship);
                              openModal();
                            }
                          }}
                          target="_blank"
                          rel="noreferrer"
                          href={el.urlFullScholarship}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                  </div>
                )}
                {finance && (
                  <div className="table-pagination-row-finance-container">
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.coaPrivate ? getFixedNumberWithDollar(el.coaPrivate) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.coaInState ? getFixedNumberWithDollar(el.coaInState) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-125">
                      <span className="table-pagination-row-endowment">
                        {el.coaOutState ? getFixedNumberWithDollar(el.coaOutState) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.privateNetCost ? getFixedNumberWithDollar(el.privateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.inStateNetCost ? getFixedNumberWithDollar(el.inStateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.outStateNetCost ? getFixedNumberWithDollar(el.outStateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgNetPriceAfterAid ? `$ ${(+el.avgNetPriceAfterAid).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceTo30000 ? `$ ${(+el.avgPriceTo30000).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-147">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom30001To48000
                          ? `$ ${(+el.avgPriceFrom30001To48000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-145">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom48001To75000
                          ? `$ ${(+el.avgPriceFrom48001To75000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom75001To110000
                          ? `$ ${(+el.avgPriceFrom75001To110000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceOver110000 ? `$ ${(+el.avgPriceOver110000).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.urlAddressPriceCalc ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={el.urlAddressPriceCalc}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.gradsAnyLoanP ? `${(+el.gradsAnyLoanP).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.gradsAnyLoanAvgAmt ? `$ ${(+el.gradsAnyLoanAvgAmt).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.pcGraduatingWithLoans ? `${(+el.pcGraduatingWithLoans).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgLoanAmount ? `$ ${(+el.avgLoanAmount).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.parentPlusP ? `${(+el.parentPlusP).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.parentPlusAvgAmt ? getFixedNumberWithDollar(el.parentPlusAvgAmt) : '-'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <Modal>
          {isOpenAddNewListModal && (
            <AddListModal
              createList={createList}
              close={() => {
                close();
                setIsOpenAddNewListModal(false);
              }}
            />
          )}
        </Modal>
      </section>
    </>
  ) : (
    <>
      <section
        className="table-pagination"
        style={{ overflowY: user.subscription || user.role === 'admin' ? undefined : 'hidden' }}
      >
        <div
          className="table-pagination-container-for-name-sticky"
          style={{ zIndex: user.subscription || user.role === 'admin' ? 102 : 100 }}
        >
          <div
            style={{ width: '388px' }}
            id={`${defaultPaginationColumns[0].id}`}
            key={defaultPaginationColumns[0].value}
            className={
              defaultPaginationColumns[0].value === 'Name'
                ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${defaultPaginationColumns[0].color}`
                : defaultPaginationColumns[0].value === 'Go to Website'
                ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${defaultPaginationColumns[0].color}`
                : defaultPaginationColumns[0].className
                ? `table-pagination-header-cont header-grid-text-color-${defaultPaginationColumns[0].color} table-pagination-header-cont-${defaultPaginationColumns[0].className}`
                : `table-pagination-header-cont header-grid-text-color-${defaultPaginationColumns[0].color}`
            }
          >
            <div
              style={{ width: '88px' }}
              className={`table-pagination-header-cont table-pagination-header-cont__first header-grid-text-color-${defaultPaginationColumns[0].color}`}
            >
              <span
                className="table-pagination-header-text table-pagination-header-text__first"
                onClick={(e) => {
                  e.preventDefault();
                }}
              >
                <div className="table-pagination-header-cont table-pagination-header-cont__first table-pagination-header-checkbox-cont">
                  <CustomCheckbox
                    onHandleChecked={addOrRemoveAllCollegesInCheckedArray}
                    checked={selectedList?.colleges.length === checkedColleges.length}
                    unChecked={selectedList?.colleges.length !== checkedColleges.length && checkedColleges.length > 0}
                    additionalClass="table-pagination-header-text__checkbox"
                    additionalClassForInput="table-pagination-header-text__checkbox__input"
                  />
                  <span
                    ref={arrowRef}
                    onClick={() => {
                      setIsOpenAllNoneAutoComplete(!isOpenAllNoneAutoComplete);
                    }}
                    className={`table-pagination-header-cont__arrow ${isOpenAllNoneAutoComplete ? 'opened' : 'closed'}`}
                  />
                </div>
                <span
                  ref={dotsRef}
                  onClick={() => {
                    setIsOpenDotsAutoComplete(!isOpenDotsAutoComplete);
                  }}
                  className={`table-pagination-header-cont__dots ${isOpenDotsAutoComplete ? 'opened' : 'closed'}`}
                />
              </span>
            </div>
            <div className="table-pagination-header-cont__name-cont">
              <span
                className="table-pagination-header-text"
                onClick={(e) => {
                  e.preventDefault();
                  if (sortBy === 'default') {
                    onHandleSortBy(defaultPaginationColumns[0]);
                  } else if (sortBy.value !== defaultPaginationColumns[0].value) {
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(!isDescSort);
                  } else if (sortBy.value === defaultPaginationColumns[0].value) {
                    onHandleIsDescSort(!isDescSort);
                    onHandleIsSortedInFrontend(true);
                  }
                }}
              >
                {defaultPaginationColumns[0].value}
              </span>
              <div className="table-pagination-header-cont-img">
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(false);
                  }}
                  className={`${
                    sortBy?.value === defaultPaginationColumns[0].value && !isDescSort ? 'selected' : ''
                  } table-pagination-header-img-${
                    defaultPaginationColumns[0].color
                  } table-pagination-header-img-rotated`}
                />
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    onHandleSortBy(defaultPaginationColumns[0]);
                    onHandleIsSortedInFrontend(true);
                    onHandleIsDescSort(true);
                  }}
                  className={`${
                    sortBy?.value === defaultPaginationColumns[0].value && isDescSort ? 'selected' : ''
                  } table-pagination-header-img-${defaultPaginationColumns[0].color}`}
                />
              </div>
            </div>
          </div>
          <div className={`table-pagination-header table-pagination-header-cont-main-content-main`}>
            {defaultPaginationColumns.slice(1).map((el: headerPaginationTableInterface) => (
              <div
                id={`${el.id}`}
                key={el.value}
                className={
                  el.value === 'Name'
                    ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${el.color}`
                    : el.value === 'Go to Website'
                    ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${el.color}`
                    : el.className
                    ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                    : `table-pagination-header-cont header-grid-text-color-${el.color}`
                }
              >
                <span
                  className="table-pagination-header-text"
                  onClick={(e) => {
                    e.preventDefault();
                    if (sortBy === 'default') {
                      onHandleSortBy(el);
                    } else if (sortBy.value !== el.value) {
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(!isDescSort);
                    } else if (sortBy.value === el.value) {
                      onHandleIsDescSort(!isDescSort);
                    }
                  }}
                >
                  {el.value}
                </span>
                <div className="table-pagination-header-cont-img">
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(false);
                    }}
                    className={`${
                      sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                    } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                  />
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      onHandleSortBy(el);
                      onHandleIsSortedInFrontend(true);
                      onHandleIsDescSort(true);
                    }}
                    className={`${
                      sortBy?.value === el.value && isDescSort ? 'selected' : ''
                    } table-pagination-header-img-${el.color}`}
                  />
                </div>
              </div>
            ))}
          </div>
          {profile && (
            <div
              ref={profileRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {profilePaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.value === 'Name'
                      ? `table-pagination-header-cont table-pagination-header-cont-name header-grid-text-color-${el.color}`
                      : el.value === 'Go to Website'
                      ? `table-pagination-header-cont table-pagination-header-cont-website header-grid-text-color-${el.color}`
                      : el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!user.subscription && user.role !== 'admin' && (
                <div id="pagination-modal" className="pagination-modal pagination-modal-1">
                  <div id="pagination-modal-content" className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span>Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}
          {admissions && (
            <div
              ref={admissionsRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {admissionsPaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!user.subscription && user.role !== 'admin' && (
                <div id="pagination-modal" className="pagination-modal pagination-modal-2">
                  <div id="pagination-modal-content" className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span>Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}

          {scholaships && (
            <div
              ref={scholashipsRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {scholashipsPaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
              {!user.subscription && user.role !== 'admin' && (
                <div id="pagination-modal" className="pagination-modal pagination-modal-3">
                  <div id="pagination-modal-content" className="pagination-modal-content">
                    <img className="pagination-modal-image" src={lockedBlue} alt="locked" />
                    <p className="pagination-modal-info-locked">Information locked</p>
                    <span className="pagination-modal-3-text">Upgrade Profile to see all this Information!</span>
                    <NavLink to={'/buy-plan'}>Unlock</NavLink>
                  </div>
                </div>
              )}
            </div>
          )}
          {finance && (
            <div
              ref={financialRef}
              className={`table-pagination-header table-pagination-header-cont-main-content-profile`}
            >
              {financePaginationColumns.map((el: headerPaginationTableInterface) => (
                <div
                  id={`${el.id}`}
                  key={el.value}
                  className={
                    el.className
                      ? `table-pagination-header-cont header-grid-text-color-${el.color} table-pagination-header-cont-${el.className}`
                      : `table-pagination-header-cont header-grid-text-color-${el.color}`
                  }
                >
                  <span
                    className="table-pagination-header-text"
                    onClick={(e) => {
                      e.preventDefault();
                      if (sortBy === 'default') {
                        onHandleSortBy(el);
                      } else if (sortBy.value !== el.value) {
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(!isDescSort);
                      } else if (sortBy.value === el.value) {
                        onHandleIsDescSort(!isDescSort);
                      }
                    }}
                  >
                    {el.value}
                  </span>
                  <div className="table-pagination-header-cont-img">
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(false);
                      }}
                      className={`${
                        sortBy?.value === el.value && !isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color} table-pagination-header-img-rotated`}
                    />
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        onHandleSortBy(el);
                        onHandleIsSortedInFrontend(true);
                        onHandleIsDescSort(true);
                      }}
                      className={`${
                        sortBy?.value === el.value && isDescSort ? 'selected' : ''
                      } table-pagination-header-img-${el.color}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        {!user.subscription && user.role !== 'admin' && readyPaginatedColleges.length > 5 && (
          <div className="table-pagination-row-relative-locked-modal">
            <img className="table-pagination-row-relative-locked-modal-img" src={lockedBlue} alt="lock" />
            <h5 className="table-pagination-row-relative-locked-modal-locked-text">Information Locked</h5>
            <p className="table-pagination-row-relative-locked-modal-more-info">
              View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to
              premium features and data.
            </p>
            <NavLink className="table-pagination-row-relative-locked-modal-btn" to={'/buy-plan'}>
              Unlock
            </NavLink>
          </div>
        )}
        {readyPaginatedColleges.length > 0 &&
          readyPaginatedColleges?.map((el, index) => {
            if (index > 4 && !user.subscription && user.role !== 'admin') {
              el = {};
            }
            return (
              <div
                key={index}
                className={
                  index > 4 && !user.subscription && user.role !== 'admin' && index % 2
                    ? `table-pagination-row table-pagination-row-odd table-pagination-row-blur ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : index > 4 && !user.subscription && user.role !== 'admin'
                    ? `table-pagination-row table-pagination-row-blur ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : index === 0 && !user.subscription && user.role !== 'admin' && readyPaginatedColleges.length > 5
                    ? `table-pagination-row ${checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'}`
                    : index % 2
                    ? `table-pagination-row table-pagination-row-odd ${
                        checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                      }`
                    : `table-pagination-row ${checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'}`
                }
              >
                <div
                  className={
                    index % 2
                      ? `${
                          checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                        } table-pagination-row-container-name table-pagination-row-container-name-${index} ${
                          readyPaginatedColleges?.length <= 3 ? 'table-pagination-row-container-name-limit' : ''
                        }`
                      : `${
                          checkOrCollegeIsChecked(el.id) ? 'checked' : 'unchecked'
                        } table-pagination-row-container-name table-pagination-row-container-name-odd table-pagination-row-container-name-${index} ${
                          readyPaginatedColleges?.length <= 3 ? 'table-pagination-row-container-name-limit' : ''
                        }`
                  }
                  style={{
                    zIndex:
                      indexOpenBookmark === index && (user.subscription || user.role === 'admin')
                        ? '101'
                        : indexOpenBookmark === index && (!user.subscription || user.role !== 'admin')
                        ? '102'
                        : indexOpenBookmark !== index && (user.subscription || user.role === 'admin')
                        ? '100'
                        : '101',
                  }}
                >
                  <CustomCheckbox
                    onHandleChecked={() => addOrRemoveCollegeInCheckedArray(el)}
                    checked={checkOrCollegeIsChecked(el.id)}
                    additionalClass="table-pagination-row-container-name__checkbox"
                  />
                  <div className="table-pagination-row-container-name__content">
                    {selectedList?.colleges?.some((element) => element.collegeId === el.id) ? (
                      <img
                        ref={selectedBookMarkRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          onHandleClickedCollege(el);
                          onHandleIsOpenBookMark(!isOpenBookMark);
                          setIndexOpenBookmark(index);
                          setIsOpenDotsAutoComplete(false);
                          setIsOpenAllNoneAutoComplete(false);
                        }}
                        className="table-pagination-row-container-name-bookmark"
                        src={minusIcon}
                        alt="unsave"
                      />
                    ) : (
                      <img
                        ref={selectedBookMarkRef}
                        onClick={(e) => {
                          e.stopPropagation();
                          onHandleClickedCollege(el);
                          onHandleIsOpenBookMark(!isOpenBookMark);
                          setIndexOpenBookmark(index);
                          setIsOpenDotsAutoComplete(false);
                          setIsOpenAllNoneAutoComplete(false);
                        }}
                        className="table-pagination-row-container-name-bookmark"
                        src={plusIcon}
                        alt="save"
                      />
                    )}
                    <a
                      onClick={() => {
                        if (!user.subscription && user.role !== 'admin') {
                          onHandleIsAddNoteModal(true);
                          onHandleClickedCollege(defaultCollege);
                          openModal();
                          return;
                        }
                        onHandleIsAddNoteModal(true);
                        onHandleClickedCollege(el);
                        openModal();
                      }}
                    >
                      {!user.subscription && user.role !== 'admin' && index % 2 ? (
                        <img src={lockWhite} alt="lock" />
                      ) : !user.subscription && user.role !== 'admin' ? (
                        <img src={lockGrey} alt="lock" />
                      ) : (
                        <span />
                      )}
                      <img
                        className="table-pagination-row-container-name-notes"
                        src={isNoteExists(el.id) ? selectedNotes : notesIcon}
                        alt="notes"
                      />
                    </a>

                    {clickedCollege && actions && el.id === clickedCollege.id && isOpenBookMark && (
                      <Autocomplete
                        classN={
                          index === readyPaginatedColleges.length ||
                          index === readyPaginatedColleges.length - 1 ||
                          index === readyPaginatedColleges.length - 2 ||
                          index === readyPaginatedColleges.length - 3
                            ? `table-pagination-row-relative-autocomplete table-pagination-row-relative-autocomplete-last table-pagination-row-relative-autocomplete-${index}`
                            : `table-pagination-row-relative-autocomplete table-pagination-row-relative-autocomplete-${index}`
                        }
                        isBudgetItems={false}
                        ref={childRef}
                        onclick={(item: any) => saveRemoveCollege(item.id, item.action)}
                        filteredItem={actions}
                        setClickedInMyListTable={clickedCollegeFun}
                      />
                    )}

                    <span
                      className="table-pagination-row-name"
                      onClick={() => navigate(`/college-details/${el.id}`, { state: '/my-lists' })}
                    >
                      {el.name}
                    </span>
                  </div>
                </div>
                <div className="table-pagination-row-main-container">
                  <div className="table-pagination-row-container-type">
                    <span data-tip={`${el.isPrivate ? 'Private' : 'Public'}`}>
                      <img src={el.isPrivate ? privateSchool : publicSchool} alt="type college" />
                    </span>
                  </div>
                  <div className="table-pagination-row-container-state">
                    <span className="table-pagination-row-state">{el?.state?.name}</span>
                  </div>
                  <div className="table-pagination-row-container-region">
                    <span className="table-pagination-row-region">{el?.state?.region?.name}</span>
                  </div>
                  <div className="table-pagination-row-container-camera">
                    {el.urlVirtualTour ? (
                      <a
                        target="_blank"
                        href={el?.urlVirtualTour}
                        rel="noreferrer"
                        aria-label="My list table"
                        onClick={(e) => {
                          e.preventDefault();
                          onHandleIsOpenYoutubeModal(true);
                          onHandleLinkYoutubeModal(el?.urlVirtualTour);
                          openModal();
                        }}
                      >
                        <div className="table-pagination-row-camera-img" />
                      </a>
                    ) : (
                      <span>-</span>
                    )}
                  </div>
                  <div className="table-pagination-row-container-merits-offered">
                    <span className="table-pagination-row-merits-offered">
                      {el?.pcStudentsReceivingMeritAid && Number(el?.pcStudentsReceivingMeritAid) > 1.0 ? 'Yes' : 'No'}
                    </span>
                  </div>
                  <div className="table-pagination-row-container-avg-gpa">
                    <span className="table-pagination-row-avg-gpa">
                      {el.avgFreshmenGpa ? `${(+el.avgFreshmenGpa).toFixed(2)}` : '-'}
                    </span>
                  </div>
                  <div className="table-pagination-row-container-coop">
                    <span className="table-pagination-row-coop">{el.coop ? 'Yes' : 'No'}</span>
                  </div>
                  <div className="table-pagination-row-container-website">
                    {el.admissionsUrl ? (
                      <a
                        target="_blank"
                        rel="noreferrer"
                        href={el.admissionsUrl}
                        className="table-pagination-row-website-link"
                      >
                        <p>View</p>
                        <span className="table-pagination-row-website-link-img" />
                      </a>
                    ) : (
                      <span className="table-pagination-row-website">-</span>
                    )}
                  </div>
                  <div className="table-pagination-row-container-website table-pagination-row-container-btn">
                    {!user.subscription && user.role !== 'admin' && (
                      <img className="table-pagination-row-container-img-locked" src={lockGrey} alt="lock icon" />
                    )}
                    <div
                      onClick={() => {
                        if (!user.subscription && user.role !== 'admin') {
                          onHandleIsOpenLockedPopupOffers(!isOpenLockedPopupOffers);
                          openModal();
                          return;
                        }
                        onHandleShowOffers(!showOffers);
                        getOffers(el.id);
                      }}
                      className="table-pagination-row-website-link"
                    >
                      <p>View</p>
                      <span className="table-pagination-row-website-link-img" />
                    </div>
                  </div>
                </div>
                {profile && (
                  <div className="table-pagination-row-profile-container">
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el.endowmentPerStudent
                          ? getFixedNumberWithDollar(el.endowmentPerStudent)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-underwomen">
                      <span className="table-pagination-row-underwomen">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.pcUndergraduateWomen
                          ? getFixedNumberWithPercent(el.pcUndergraduateWomen)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.pcUndergraduateMen
                          ? getFixedNumberWithPercent(el.pcUndergraduateMen)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.studentToFaculty
                          ? `${(+el.studentToFaculty).toFixed(0)} : 1`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-undermen">
                      <span className="table-pagination-row-undermen">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.pcReturningForSophomore
                          ? getFixedNumberWithPercent(el?.pcReturningForSophomore)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-graduation-rate">
                      <span className="table-pagination-row-graduation-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.fourYearGraduationRate
                          ? getFixedNumberWithPercent(el.fourYearGraduationRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-white-non">
                      <span className="table-pagination-row-white-non">
                        {el?.enWhiteNonhispanic ? getFixedNumberWithPercent(el.enWhiteNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-black-non">
                      <span className="table-pagination-row-black-non">
                        {el?.enBlackNonhispanic ? getFixedNumberWithPercent(el.enBlackNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-latino-non">
                      <span className="table-pagination-row-latino-non">
                        {el?.enHispanicEthnicity ? getFixedNumberWithPercent(el.enHispanicEthnicity) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-asian-non">
                      <span className="table-pagination-row-asian-non">
                        {el?.enAsianNonhispanic ? getFixedNumberWithPercent(el.enAsianNonhispanic) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-alien-non">
                      <span className="table-pagination-row-alien-non">
                        {el?.enNonresAlien ? getFixedNumberWithPercent(el.enNonresAlien) : '-'}
                      </span>
                    </div>
                  </div>
                )}
                {admissions && (
                  <div className="table-pagination-row-admissions-container">
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa ? `${+el.avgFreshmenGpa}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa1P ? `${(+el.avgFreshmenGpa1P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa2P ? `${(+el.avgFreshmenGpa2P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa3P ? `${(+el.avgFreshmenGpa3P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {el?.avgFreshmenGpa4P ? `${(+el.avgFreshmenGpa4P).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-avgFreshmenGpa">
                      <span className="table-pagination-row-avgFreshmenGpa">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.avgFreshmenGpaBelow3P
                          ? `${(+el.avgFreshmenGpaBelow3P).toFixed(0)} %`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-testScoreRequirementType">
                      <span className="table-pagination-row-testScoreRequirementType">
                        {/* {!user.subscription */}
                        {/*  ? 'N/A' */}
                        {/*  : el?.testScoreRequirementType === false */}
                        {/*  ? 'No Optional' */}
                        {/*  : el?.testScoreRequirementType === true */}
                        {/*  ? 'Optional' */}
                        {/*  : 'Test Optional'} */}
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.testScoreRequirementType === null
                          ? 'No Optional'
                          : `${el?.testScoreRequirementType}`}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-sat">
                      <span className="table-pagination-row-sat">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.satAvg
                          ? `${(+el.satAvg).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-sat">
                      <span className="table-pagination-row-sat">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.sat75thPc
                          ? `${(+el.sat75thPc).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-act">
                      <span className="table-pagination-row-act">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.actAvg
                          ? `${(+el.actAvg).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-act">
                      <span className="table-pagination-row-act">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el?.act75thPc
                          ? `${(+el.act75thPc).toFixed(0)}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-overall-admit">
                      <span className="table-pagination-row-overall-admit">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.admissionsRate
                          ? getFixedNumberWithPercent(el.admissionsRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.edAdmitRate
                          ? getFixedNumberWithPercent(el.edAdmitRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A'
                          : el.eaAdmitRate
                          ? getFixedNumberWithPercent(`${el.eaAdmitRate}`)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.rdAdmitRate
                          ? getFixedNumberWithPercent(el.rdAdmitRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.edFilledRate
                          ? getFixedNumberWithPercent(el.edFilledRate)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.admitYield
                          ? getFixedNumberWithPercent(el.admitYield)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-app-fee">
                      <span className="table-pagination-row-app-fee">
                        {el?.applicationFee ? getFixedNumberWithDollar(el.applicationFee) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.edDeadline ? formatDateByMonthDay(`${el.edDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.eaDeadline ? formatDateByMonthDay(`${el.eaDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.ed2Deadline ? formatDateByMonthDay(`${el.ed2Deadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-regular">
                      <span className="table-pagination-row-edDeadline">
                        {el.regDecisionDeadline ? formatDateByMonthDay(`${el.regDecisionDeadline}`) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-edDeadline">
                      <span className="table-pagination-row-edDeadline">
                        {el.finAidDeadline ? formatDateByMonthDay(`${el.finAidDeadline}`) : '-'}
                      </span>
                    </div>
                  </div>
                )}
                {scholaships && (
                  <div className="table-pagination-row-scholarships-container">
                    <div className="table-pagination-row-container-need-met">
                      <span className="table-pagination-row-need-met">
                        {el?.avgPcNeedMet ? getFixedNumberWithPercent(el.avgPcNeedMet) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-need-met table-pagination-row-container-need-met-average">
                      <span className="table-pagination-row-need-met">
                        {el?.avgNeedBasedAward ? getFixedNumberWithDollar(el.avgNeedBasedAward) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-coop">
                      <span className="table-pagination-row-coop">{el.cssProfileRequired ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-pagination-row-container-coop table-pagination-row-container-coop-2">
                      <span className="table-pagination-row-coop">{el.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate table-pagination-row-container-admit-rate-2">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A%'
                          : el?.pcStudentsWithoutNeed
                          ? getFixedNumberWithPercent(el.pcStudentsWithoutNeed)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.pcStudentsReceivingMeritAid
                          ? getFixedNumberWithPercent(el.pcStudentsReceivingMeritAid)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {!user.subscription && user.role !== 'admin'
                          ? 'N/A %'
                          : el?.avgMeritAidAward
                          ? getFixedNumberWithDollar(el.avgMeritAidAward)
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.scholarshipsUrl ? (
                        <a
                          onClick={() => {
                            if (!user.subscription && user.role !== 'admin') return;
                            if (!el?.scholarshipsUrl) {
                              onHandleModalContent({
                                title: 'Merit Scholarship Details not Available',
                                message:
                                  'We are sorry to inform you that Merit Scholarship details for this college are not available at the moment.',
                              });
                              onHandleIsOpenMeritScholarship(!isOpenMeritScholarship);
                              openModal();
                            }
                          }}
                          target="_blank"
                          rel="noreferrer"
                          href={el.scholarshipsUrl}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.urlFullScholarship ? (
                        <a
                          onClick={() => {
                            if (!user.subscription && user.role !== 'admin') return;
                            if (!el?.urlFullScholarship) {
                              onHandleModalContent({
                                title: 'Full Tuition/ Full Ride Scholarship Details not Available',
                                message:
                                  'We are sorry to inform you that Full Tuition/ Full Ride Scholarship details for this college are not available at the moment.',
                              });
                              onHandleIsOpenMeritScholarship(!isOpenMeritScholarship);
                              openModal();
                            }
                          }}
                          target="_blank"
                          rel="noreferrer"
                          href={el.urlFullScholarship}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                  </div>
                )}
                {finance && (
                  <div className="table-pagination-row-finance-container">
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.coaPrivate ? getFixedNumberWithDollar(el.coaPrivate) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.coaInState ? getFixedNumberWithDollar(el.coaInState) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-125">
                      <span className="table-pagination-row-endowment">
                        {el.coaOutState ? getFixedNumberWithDollar(el.coaOutState) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.privateNetCost ? getFixedNumberWithDollar(el.privateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.inStateNetCost ? getFixedNumberWithDollar(el.inStateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.outStateNetCost ? getFixedNumberWithDollar(el.outStateNetCost) : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgNetPriceAfterAid ? `$ ${(+el.avgNetPriceAfterAid).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceTo30000 ? `$ ${(+el.avgPriceTo30000).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-147">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom30001To48000
                          ? `$ ${(+el.avgPriceFrom30001To48000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment table-pagination-row-container-endowment-145">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom48001To75000
                          ? `$ ${(+el.avgPriceFrom48001To75000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceFrom75001To110000
                          ? `$ ${(+el.avgPriceFrom75001To110000).toLocaleString('en-US')}`
                          : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgPriceOver110000 ? `$ ${(+el.avgPriceOver110000).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-website">
                      {el.urlAddressPriceCalc ? (
                        <a
                          target="_blank"
                          rel="noreferrer"
                          href={el.urlAddressPriceCalc}
                          className="table-pagination-row-website-link"
                        >
                          <p>View</p>
                          <span className="table-pagination-row-website-link-img" />
                        </a>
                      ) : (
                        <span className="table-pagination-row-website">-</span>
                      )}
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.gradsAnyLoanP ? `${(+el.gradsAnyLoanP).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.gradsAnyLoanAvgAmt ? `$ ${(+el.gradsAnyLoanAvgAmt).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.pcGraduatingWithLoans ? `${(+el.pcGraduatingWithLoans).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.avgLoanAmount ? `$ ${(+el.avgLoanAmount).toLocaleString('en-US')}` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-admit-rate">
                      <span className="table-pagination-row-admit-rate">
                        {el?.parentPlusP ? `${(+el.parentPlusP).toFixed(0)} %` : '-'}
                      </span>
                    </div>
                    <div className="table-pagination-row-container-endowment">
                      <span className="table-pagination-row-endowment">
                        {el.parentPlusAvgAmt ? getFixedNumberWithDollar(el.parentPlusAvgAmt) : '-'}
                      </span>
                    </div>
                  </div>
                )}
              </div>
            );
          })}
        <Modal>
          {isOpenAddNewListModal && (
            <AddListModal
              createList={createList}
              close={() => {
                close();
                setIsOpenAddNewListModal(false);
              }}
            />
          )}
        </Modal>
      </section>
      {isOpenAllNoneAutoComplete && (
        <div className="table-pagination-header-cont__arrow__autocomplete">
          <p onClick={addAllCollegesInCheckedArray}>All</p>
          <p onClick={removeAllCollegesFromCheckedArray}>None</p>
        </div>
      )}
      {isOpenDotsAutoComplete && (
        <div className="table-pagination-header-cont__dots__autocomplete">
          <span onClick={removeCollegesFromAllLists}>
            <img src={deleteIcon} alt="delete college from list" />
            <p>Delete from All List</p>
          </span>
          <span onClick={() => removeCollegeFromList(selectedList.name)}>
            <img src={deleteIcon} alt="delete college from list" />
            <p>Delete from {selectedList.name.length > 30 ? selectedList.name.slice(0, 30) : selectedList.name}</p>
          </span>
          {myLists
            .filter((list) => list.name !== selectedList.name)
            .map((filteredList) => (
              <span onClick={() => addCollegeToList(filteredList.name)}>
                <img src={addToListIcon} alt="add college to list" />
                <p>Add to {filteredList.name.length > 30 ? filteredList.name.slice(0, 30) : filteredList.name}</p>
              </span>
            ))}
          <span
            onClick={() => {
              open();
              setIsOpenAddNewListModal(true);
            }}
          >
            <img src={createListIcon} alt="create new list" />
            <p>Create New List</p>
          </span>
        </div>
      )}
    </>
  );
};
