import { EStatesActionTypes } from '../../enums';
import { IRegion } from '../../models/regions.model';

const getStates = () => {
  return {
    type: EStatesActionTypes.GET_STATES,
  };
};

const setStates = (payload: Array<any>) => {
  return {
    type: EStatesActionTypes.SET_STATES,
    payload,
  };
};

const getRegions = () => {
  return {
    type: EStatesActionTypes.GET_REGIONS,
  };
};

const setRegions = (payload: IRegion[]) => {
  return {
    type: EStatesActionTypes.SET_REGIONS,
    payload,
  };
};

export const statesAction = {
  getStates,
  setStates,
  getRegions,
  setRegions,
};
