import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { myListsAction, previousRouteAction } from '../../store/actions';
import { sharedListsService } from '../../services';
import { ICollege } from '../../models';
import './SharedList.css';
import collegeIcon from '../../images/default-college.png';
import deletedListIcon from '../../images/shared-list-deleted.png';
import locationPurple from '../../images/Location_purple.svg';

const defaultColleges = [
  { id: 110060, name: 'Bethesda University', coaPrivate: 3380, stateName: 'Georgia' },
  { id: 475608, name: 'Criswell College', coaPrivate: 12550, stateName: 'California' },
  { id: 482936, name: 'Florida Polytechnic University', coaPrivate: null, stateName: 'Florida' },
  { id: 483018, name: 'Antioch College', coaPrivate: 50383, stateName: 'Ohio' },
  { id: 486840, name: 'Kennesaw State University', coaPrivate: null, stateName: 'California' },
];
export interface ISharedList {
  userId: number;
  userName: string;
  listId: number;
  listName: string;
  colleges: Partial<ICollege>[];
}

export const SharedList = () => {
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { user } = useSelector((state: any) => state.userState);
  const [sharedList, setSharedList] = useState<ISharedList>();
  const [sharedListReady, setSharedListReady] = useState<ISharedList>();

  const [lengthColleges, setLengthColleges] = useState<number>(0);
  const { loginWithRedirect } = useAuth0();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const listId = location.pathname.split('/')[2];
    fetchSharedList(Number(listId));
    if (previousRoute.payload !== '/shared-list-after-require-pay') {
      dispatch(previousRouteAction.setPreviousRoute('/shared-lists'));
    }
    dispatch(myListsAction.setIdSharedList(Number(listId)));
    dispatch(myListsAction.setIsSharedList(true));
  }, []);

  useEffect(() => {
    if (!sharedList?.listId) return;
    const copyObject = JSON.parse(JSON.stringify(sharedList));
    let copyColleges;

    if (user === undefined) {
      copyColleges = copyObject.colleges?.length >= 5 ? copyObject.colleges.slice(0, 5) : copyObject.colleges;
    }

    if (user && (user?.subscription || user.role === 'admin')) {
      copyColleges = copyObject.colleges?.length >= 30 ? copyObject.colleges.slice(0, 30) : copyObject.colleges;
    }

    if (user && !('subscription' in user) && user.role !== 'admin') {
      copyColleges = copyObject.colleges?.length >= 10 ? copyObject.colleges.slice(0, 10) : copyObject.colleges;
    }

    setSharedListReady({
      userId: sharedList.userId,
      userName: sharedList.userName,
      listId: sharedList.listId,
      listName: sharedList.listName,
      colleges: copyColleges,
    });
  }, [sharedList]);

  const coaSelect = (coasArray: any[]): string => {
    const everyNull: boolean = coasArray.every((coa) => coa === null);
    if (everyNull) {
      return '-';
    }
    return `$ ${Math.max(...coasArray.filter((coa: any) => coa !== null)).toLocaleString('en-US')}`;
  };

  const fetchSharedList = async (listId: number) => {
    const response = await sharedListsService.endpoint_get_sharedList(listId);
    if (response.data) {
      setSharedList(response.data);
      setLengthColleges(response?.data?.colleges?.length);
    }
  };

  const isObjEmpty = (obj): boolean => {
    return Object.keys(obj).length === 0;
  };

  return (
    <section className="share-list">
      {sharedListReady && !isObjEmpty(sharedListReady) ? (
        <div className="share-list__container">
          <header className="share-list__header">
            <div className="share-list__name">
              {`Checkout ${sharedListReady?.userName}${sharedListReady?.userName?.endsWith('s') ? '’ ' : '’s '}`}
              <span className="share-list__name__blue">{`“${sharedListReady?.listName}”`}</span>
            </div>
            <p className="share-list__name">
              {sharedListReady?.colleges?.length} out of {lengthColleges}
            </p>
          </header>
          <div className="share-list__colleges">
            {sharedListReady?.colleges?.length > 0 &&
              sharedListReady?.colleges?.map((college: any) => {
                return (
                  <div className="share-list__college" key={college.id}>
                    <div className="share-list__college__img-cont">
                      <img
                        className="share-list__college__img"
                        src={
                          college?.hasImage
                            ? `${process.env.REACT_APP_COLLEGE_IMAGES}${college?.ipeds}.png`
                            : collegeIcon
                        }
                        alt="CollegePhoto"
                      />
                    </div>
                    <div className="share-list__college__info">
                      <p className="share-list__college__name">{college.name}</p>
                      <div>
                        <div className="share-list__college__description__item">
                          <img src={locationPurple} alt="location" />
                          <span className="share-list__college__description__item__location-text">
                            {college?.state?.name ? college.state.name : '---'}
                          </span>
                        </div>
                        <div className="share-list__college__description__item share-list__college__description__item--second">
                          <span>Avg Cost of Attendance</span>
                          <span>{coaSelect([college.coaPrivate, college.coaInState, college.coaOutState])}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
          </div>
          {(user === null || user === undefined) && (
            <div className="share-list__checkout first">
              <h3 className="share-list__checkout__title">
                To see more, sign up for a free account or log in.
                <br />
                Then get started creating your own lists with Insights!
              </h3>
              <button
                className="share-list__checkout__button"
                onClick={() => loginWithRedirect({ screen_hint: 'signup' })}
              >
                Try it FREE
              </button>
            </div>
          )}
          {previousRoute.payload === '/shared-list-after-require-pay' && (
            <button
              className="share-list__container__btn-save"
              onClick={() => {
                dispatch(previousRouteAction.setPreviousRoute('/shared-lists-after-pay'));
                navigate('/my-lists');
              }}
            >
              Save List
            </button>
          )}
          {user && (!user.subscription || user.subscription.length === 0) && user.role !== 'admin' && (
            <div className="share-list__checkout">
              {defaultColleges.map((college) => {
                return (
                  <div className="share-list__college" key={college.id}>
                    <div className="share-list__college__img-cont">
                      <img className="share-list__college__img" src={collegeIcon} alt="CollegePhoto" />
                    </div>
                    <div className="share-list__college__info">
                      <p className="share-list__college__name">{college.name}</p>
                      <div>
                        <div className="share-list__college__description__item">
                          <img src={locationPurple} alt="location" />
                          <span className="share-list__college__description__item__location-text">
                            {college.stateName}
                          </span>
                        </div>
                        <div className="share-list__college__description__item share-list__college__description__item--second">
                          <span>Avg Cost of Attendance</span>
                          <span>{coaSelect([college.coaPrivate])}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}

              <section className="share-list__checkout__blur">
                <div className="share-list__checkout__blur__center">
                  <p className="share-list__checkout__blur__center__p">Upgrade to see all colleges on the list.</p>
                  <div className="share-list__checkout__blur__center__cont-btns">
                    <button className="share-list__checkout__blur__center__btn" onClick={() => navigate('/my-lists')}>
                      Save list
                    </button>
                    <button
                      className="share-list__checkout__blur__center__btn second"
                      onClick={() => {
                        dispatch(previousRouteAction.setPreviousRoute('/shared-list-after-require-pay'));
                        navigate('/buy-plan');
                      }}
                    >
                      Upgrade
                    </button>
                  </div>
                </div>
              </section>
            </div>
          )}
        </div>
      ) : (
        <>
          <h2 className="share-list__delete__title">The List you are looking for seems to be deleted by its owner</h2>
          <div className="share-list__delete__img__cont">
            <img src={deletedListIcon} alt="DeletedListPhoto" />
          </div>
        </>
      )}
    </section>
  );
};
