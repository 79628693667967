import { axiosService } from '.';
import { apiUrls } from '../consts';
import { ICollegeNote } from '../models';
import { getApiAccessToken } from '../utils';

export const collegeNotesService = (function () {
  const endpoint_get_college_notes = async (collegeId: number, userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_college_notes(collegeId, userId));
  };

  const endpoint_get_user_notes = async (userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_user_notes(userId));
  };

  const endpoint_create_college_note = async (note: Partial<ICollegeNote>) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.create_college_note, note);
  };

  const endpoint_update_college_note = async (id: number, text: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.college_note_by_id(id), { text });
  };

  const endpoint_delete_college_note = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.college_note_by_id(id));
  };

  return {
    endpoint_get_college_notes,
    endpoint_create_college_note,
    endpoint_update_college_note,
    endpoint_delete_college_note,
    endpoint_get_user_notes,
  };
})();
