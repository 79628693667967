/* eslint-disable */
import './Nav.css';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import road2CollegeIcon from '../../images/icons/road2college-icon.svg';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';

import localForage from 'localforage';
import { useEffect, useRef, useState } from 'react';
import localforage from 'localforage';

import userGratuatedBlack from '../../images/user-graduate-icon-black.svg';
import userGratuatedWhite from '../../images/user-graduate-icon-white.svg';

import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

export const Nav = () => {
  const mobileMenu = useRef(null);
  const mobileBtn = useRef(null);
  const menuRef = useRef(null);
  const submenuRef = useRef(null);
  const profileBtn = useRef(null);

  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { pathname } = useLocation();

  //Dropwdowns
  const [supportDropdownOpen, setSupportDropdownOpen] = useState(false);
  const toggleSupport = () => setSupportDropdownOpen((prevState) => !prevState);

  const [profileDropdownOpen, setProfileDropdownOpen] = useState(false);
  const toggleProfile = () => setProfileDropdownOpen((prevState) => !prevState);
  const [profileSubDropdownOpen, setProfileSubDropdownOpen] = useState(false);
  const toggleProfileSub = () => setProfileSubDropdownOpen((prevState) => !prevState);

  const [profileIconActive, setProfileIconActive] = useState(false);

  const [isInfoHubVisibleAndGetStarted, setIsInfoHubVisibleAndGetStartedAndGetStarted] = useState(false);
  const [isGradesVisible, setIsGradesVisible] = useState(false);
  const [isProcessVisible, setIsProcessVisible] = useState(false);
  const [isOpenSaveListModal, setIsOpenSaveListModal] = useState<boolean>(false);
  const [isOpenAddNewListModal, setIsOpenAddNewListModal] = useState<boolean>(false);

  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const closeSubmenuMobile = () => {
    setIsProcessVisible(false);
    setIsGradesVisible(false);
    setIsInfoHubVisibleAndGetStartedAndGetStarted(false);
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      const profBtn: any = profileBtn.current;
      const menu: any = menuRef.current;
      const submenu: any = submenuRef.current;
      const mobileMenuRef: any = mobileMenu.current;
      const mobileBtnRef: any = mobileBtn.current;

      if (
        mobileMenuRef &&
        !mobileMenuRef.contains(event.target) &&
        mobileBtnRef &&
        !mobileBtnRef.contains(event.target) &&
        menu &&
        !menu.contains(event.target) &&
        submenu &&
        !submenu.contains(event.target) &&
        profBtn &&
        !profBtn.contains(event.target)
      ) {
        closeSubmenuMobile();
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  const scrollToSection = (section_id) => {
    const section = document.getElementById(section_id);
    section ? section.scrollIntoView({ behavior: 'smooth' }) : '';
  };

  const blockedClick = () => {
    return (
      previousRoute.payload === '/home-page-after-require' ||
      previousRoute.payload === '/require-pay' ||
      previousRoute.payload === '/upgrade' ||
      (previousRoute.payload === '/shared-list-after-require-pay' && window.location.pathname === '/buy-plan')
    );
  };

  return (
    <div className="nav-component-container">
      <div className="nav-router">
        <div className="nav-flex">
          <div className="road2CollegeIcon__box">
            <NavLink to={isAuthenticated ? '/home' : '/landing-page'} className="college-insights">
              <img className="road2CollegeIcon" src={road2CollegeIcon} alt={'title'} />
            </NavLink>
          </div>

          <div className="nav-link-box">
            <a href="https://www.road2college.com" target="_blank" className="nav-link">
              LEARN
            </a>
          </div>
          <div className="nav-link-box active">
            <NavLink to={'/home'} target="_blank" className="nav-link">
              INSIGHTS
            </NavLink>
          </div>
          <div className="nav-link-box">
            <a href="https://compareoffers.road2college.com" target="_blank" className="nav-link">
              COMPARE OFFERS
            </a>
          </div>
        </div>

        <div className="nav-mobile-noAuthenticated nav-flex">
          {!isAuthenticated && (
            <>
              <div className="nav-link-box">
                {!pathname.includes('/my-lists/') && (
                  <span
                    className="nav-link"
                    onClick={() => {
                      if (blockedClick()) {
                        return;
                      }
                      if (pathname === '/create-profile') {
                        localStorage.clear();
                        localForage.clear().then(() => {
                          logout({
                            returnTo: window.location.origin,
                          });
                        });
                      }
                      loginWithRedirect();
                    }}
                  >
                    LOGIN
                  </span>
                )}
              </div>

              <div className="nav-link-button">
                <button
                  onClick={() => {
                    if (blockedClick()) {
                      return;
                    }
                    if (pathname === '/create-profile') {
                      localStorage.clear();
                      localForage.clear().then(() => {
                        logout({
                          returnTo: window.location.origin,
                        });
                      });
                    }
                    loginWithRedirect({ screen_hint: 'signup' });
                  }}
                  className="btn-get-started"
                >
                  GET STARTED FOR FREE
                </button>
              </div>
            </>
          )}

          {isAuthenticated && (
            <>
              <div
                className="custom-nav"
                onMouseOver={() => setSupportDropdownOpen(true)}
                onFocus={() => setSupportDropdownOpen(true)}
                onMouseLeave={() => setSupportDropdownOpen(false)}
                onBlur={() => setSupportDropdownOpen(false)}
              >
                <Dropdown
                  isOpen={supportDropdownOpen}
                  toggle={() => setSupportDropdownOpen(!supportDropdownOpen)}
                  direction="down"
                  className="dropdown-insights dropdown-insights-support dropdown-bg-none text-black"
                >
                  <DropdownToggle caret className="title-dropdown">
                    SUPPORT
                  </DropdownToggle>
                  <DropdownMenu>
                    <DropdownItem href="https://www.road2college.com/contact-us/">Contact Us</DropdownItem>
                    <DropdownItem href="https://www.road2college.com/college-insights-faqs/">FAQ</DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>

              <div
                className="custom-nav"
                onMouseOver={() => {
                  setProfileDropdownOpen(true);
                  setProfileIconActive(!profileIconActive);
                }}
                onFocus={() => {
                  setProfileDropdownOpen(true);
                  setProfileIconActive(!profileIconActive);
                }}
                onMouseLeave={() => setProfileDropdownOpen(false)}
                onBlur={() => setProfileDropdownOpen(false)}
              >
                <Dropdown
                  isOpen={profileDropdownOpen}
                  toggle={() => setProfileDropdownOpen(!profileDropdownOpen)}
                  onClick={() => {
                    setProfileIconActive(!profileIconActive);
                  }}
                  direction="down"
                  className="dropdown-insights dropdown-profile dropdown-bg-none text-black"
                >
                  <DropdownToggle caret>
                    <img
                      src={profileDropdownOpen ? userGratuatedWhite : userGratuatedBlack}
                      alt="profile"
                      className="profile-user"
                    />
                  </DropdownToggle>

                  <DropdownMenu>
                    <DropdownItem href="/my-profile">Your Profile</DropdownItem>
                    {user.role === 'admin' && (
                      <>
                        <DropdownItem href="/dashboard">Admin Dashboard</DropdownItem>
                        <DropdownItem
                          onClick={() => {
                            navigate('/admin-user-profile', { state: { user: adminNavigatedUser } });
                          }}
                        >
                          Visit User Profile
                        </DropdownItem>
                      </>
                    )}

                    <DropdownItem href="https://www.road2college.com/updated-membership-plans/">
                      Subscription Options
                    </DropdownItem>
                    
                    {!user.subscription  && user.role !== 'admin' && (
                      <DropdownItem href="/buy-plan">Update Plan</DropdownItem>
                    )}
                    <DropdownItem>
                      <NavLink
                        to={'/home?calendar=true'}
                        className="text-white"
                        onClick={() => {
                          setTimeout(() => {
                            scrollToSection('calendar');
                          }, 150);
                        }}
                      >
                        Yearly Calendar
                      </NavLink>
                    </DropdownItem>

                    <DropdownItem
                      className="nav-red"
                      onMouseDown={(e) => e.preventDefault()}
                      onClick={() => {
                        if (
                          previousRoute.payload === '/shared-list-after-require-pay' &&
                          window.location.pathname !== '/buy-plan'
                        ) {
                          navigate('/my-lists');
                          return;
                        }
                        if (
                          previousRoute.payload === '/home-page-after-require' ||
                          previousRoute.payload === '/require-pay' ||
                          previousRoute.payload === '/upgrade' ||
                          previousRoute.payload === '/shared-list-after-require-pay'
                        ) {
                          return;
                        }
                        localStorage.clear();
                        localforage.clear().then(() => {
                          logout({
                            returnTo: window.location.origin,
                          });
                        });
                      }}
                    >
                      Logout
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
