import './My-list-college.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import { useAuth0 } from '@auth0/auth0-react';
import { useNavigate, NavLink } from 'react-router-dom';
import moment from 'moment';
import unsaveIcon from '../../images/unsave-icon.svg';
import saveIcon from '../../images/save_university.svg';
import collegeIcon from '../../images/default-college.png';
import { AddListModal, AddNotesModal, Autocomplete, SignUpModal, OffersModal } from '..';
import { ICollegeNote, IMyList } from '../../models';
import { collegesAction, myListsAction } from '../../store/actions';
import deleteIcon from '../../images/delete.svg';
import addToListIcon from '../../images/add-to-list-icon.svg';
import createListIcon from '../../images/create-list.svg';
import { ScholarshipModal } from '../scholarship-modal/ScholarshipModal';
import lock from '../../images/locked-icon-white.svg';
import iconGrey from '../../images/locked-icon-grey.svg';
import notesIcon from '../../images/notes-icon.svg';
import selectedNotes from '../../images/notes-icon-selected.svg';
import { InfoLockedModal } from '../info-locked-modal/Info-locked-modal';
import { UniversalModal } from '../universal-modal/UniversalModal';

export const MyListCollege = ({
  college,
  isSchoolarship,
  isProfile,
  isAdmission,
  isFinance,
  createList,
  isListsActionsActive,
  setIsListsActionsActive,
  allowed = true,
  userId,
  notes,
}: // paginationLimit,
{
  college: any;
  isSchoolarship: boolean;
  isProfile: boolean;
  isAdmission: boolean;
  isFinance: boolean;
  createList: Function;
  isListsActionsActive: boolean;
  setIsListsActionsActive: Function;
  allowed?: boolean;
  userId: number;
  notes: ICollegeNote[];
  // paginationLimit: number;
}) => {
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const { isAuthenticated } = useAuth0();

  const { myLists, selectedList } = useSelector((state: any) => state.myListsState);
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);

  const [actions, setActions] = useState<any[]>([]);
  const [isInList, setIsInList] = useState(!!selectedList?.colleges?.find((l: any) => l.collegeId === college.id));

  const [modalContent, setModalContent] = useState({ title: '', message: '' });

  const [isAddListModal, setIsAddListModal] = useState<boolean>(false);

  const [showOffers, setShowOffers] = useState(false);
  const [showScholarshipModal, setScholashipModal] = useState<boolean>(false);
  const [showLockedScholarshipModal, setShowLockedScholarshipModal] = useState<boolean>(false);

  const [isAddNotes, setIsAddNotes] = useState<boolean>(false);
  const [allowedCreateList, setAllowedCreateList] = useState(true);

  const childRef = useRef<any>();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const getOffers = async (id: number) => {
    if (id) {
      dispatch(
        collegesAction.getFullCollegeById({
          collegeId: +id,
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
        }),
      );
    }
    setShowOffers(true);
  };

  useEffect(() => {
    if (showOffers) {
      open();
    }
  }, [showOffers]);

  const isNoteExists = (collegeId: number): boolean => {
    if (notes.length) {
      const matchingNotes = notes.filter((note) => note.collegeId === collegeId && note.userId === userId);
      return matchingNotes.length > 0;
    }
    return false;
  };

  const sorted = (array: Object[]): Object[] => {
    return Array.from(array).sort((a: any, b: any) => a.collegeId - b.collegeId);
  };

  const customOffsetTop = (element: HTMLElement): number => {
    return element.offsetTop + (element.offsetParent ? customOffsetTop(element.offsetParent as HTMLElement) : 0);
  };

  const placeHeaderOnScroll = () => {
    const headers = document.getElementsByClassName('college-header-my-list') as unknown as HTMLElement[];
    const photo = document.getElementsByClassName('college-list-photo')[0] as HTMLElement;
    const footer = document.getElementsByClassName('delete-list')[0] as unknown as HTMLElement;
    if (photo && footer) {
      if (
        window.scrollY >= customOffsetTop(photo) &&
        window.scrollY - headers[0].clientHeight <= customOffsetTop(footer)
      ) {
        for (let i = 0; i < headers.length; i++) {
          headers[i].style.position = 'fixed';
          if (window.innerWidth <= 520) {
            headers[i].style.width = 'calc(100% - 102px)';
            headers[i].style.padding = '10px 0';
            headers[i].style.left = '51px';
            headers[i].style.borderBottom = '2px solid #a7a7a7';
          } else {
            headers[i].style.width = 'inherit';
            headers[i].style.marginLeft = '-1.9vw';
            headers[i].style.padding = '10px 0';
            headers[i].style.borderBottom = '2px solid #a7a7a7';
          }
        }
      } else if (window.scrollY < customOffsetTop(photo) || window.scrollY > customOffsetTop(footer)) {
        for (let i = 0; i < headers.length; i++) {
          headers[i].style.position = 'relative';
          headers[i].style.left = 'auto';
          headers[i].style.width = '100%';
          headers[i].style.marginLeft = '0';
          headers[i].style.padding = '0';
          headers[i].style.borderBottom = 'none';
        }
      }
    }
  };

  window.onscroll = () => {
    placeHeaderOnScroll();
  };

  useEffect(() => {
    placeHeaderOnScroll();
    setIsInList(!!selectedList?.colleges?.find((l: any) => l.collegeId === college.id));
  }, [selectedList]);

  useEffect(() => {
    if (isInList) {
      const arr = [
        { id: -1, action: 'delete', name: 'Delete from All Lists', img: deleteIcon },
        { id: selectedList.id, action: 'delete', name: `Delete from ${selectedList.name}`, img: deleteIcon },
      ];
      myLists.forEach((l: IMyList, i: number) => {
        if (l.id !== selectedList.id) {
          arr.push({ id: l.id, action: 'add', name: `Add to ${l.name}`, img: addToListIcon });
        }
        if (i === myLists.length - 1) {
          arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
        }
      });
      setActions(arr);
    } else {
      const arr = [{ id: -1, action: 'add', name: 'Bookmark to All Lists', img: unsaveIcon }];
      myLists.forEach((l: IMyList, i: number) => {
        if (l.colleges.find((c) => c.id !== college.id)) {
          arr.push({ id: l.id, action: 'add', name: `Bookmark to ${l.name}`, img: unsaveIcon });
        }
        if (i === myLists.length - 1) {
          arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
        }
      });
      setActions(arr);
    }
  }, [isInList, myLists, selectedList]);

  const saveRemoveCollege = (listId: number, action: string) => {
    if (!user.subscription && user.role !== 'admin' && listId === -2) {
      setAllowedCreateList(false);
      open();
      return;
    }
    setIsListsActionsActive(null);
    if (listId >= 0) {
      if (action === 'delete') {
        dispatch(
          myListsAction.removeCollegeFromMyList({
            userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
            listId,
            collegeId: college.id,
          }),
        );
        const newList = {
          ...selectedList,
          colleges: selectedList.colleges.filter((l: any) => l.collegeId !== college.id),
        };
        dispatch(myListsAction.setSelectedList(newList));

        if (listId === selectedList.id) {
          setIsInList(false);
        }
      } else {
        dispatch(
          myListsAction.addCollegesToMyList({
            userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
            listName: myLists.find((l: IMyList) => l.id === listId).name,
            collegesIds: [college.id],
          }),
        );
        if (listId === selectedList.id) {
          const newCollegeList = [...selectedList.colleges, { college, collegeId: college.id, listId }];
          const newList = { ...selectedList, colleges: newCollegeList };
          dispatch(myListsAction.setSelectedList(newList));
          setIsInList(true);
        }
      }
    } else if (action === 'delete') {
      setIsInList(false);
      dispatch(
        myListsAction.removeCollegeFromAllLists({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          collegeId: college.id,
        }),
      );
      const newList = {
        ...selectedList,
        colleges: selectedList.colleges.filter((l: any) => l.collegeId !== college.id),
      };
      dispatch(myListsAction.setSelectedList(newList));
    } else if (listId === -2) {
      setIsAddListModal(true);
      open();
    } else {
      dispatch(
        myListsAction.addCollegeToAllLists({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          collegeId: college.id,
        }),
      );
      setIsInList(true);
    }
  };
  const formatDate = (str: string) => {
    if (str === null) return '-';
    return moment(str, 'YYYY-MM-DD').format('MMM DD');
  };

  return (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>
      {adminNavigatedUser ? (
        <div
          onClick={() => setIsListsActionsActive(null)}
          className={`my-list-college ${isInList ? '' : 'padding-my-list-college-mobile'} ${
            !adminNavigatedUser.subscription &&
            !allowed &&
            sorted(selectedList.colleges)[5] &&
            college.id === sorted(selectedList.colleges)[5]['collegeId']
              ? 'not-allowed'
              : ''
          }`}
        >
          {!isInList && <span className="recommended">RECOMMENDED</span>}
          <header className="college-header-my-list">
            <img
              onClick={(e) => {
                e.stopPropagation();
                setIsListsActionsActive(college.id);
              }}
              src={isInList ? unsaveIcon : saveIcon}
              alt="unsave"
            />
            <span
              className="college-header-my-list-title"
              onClick={() => navigate(`/college-details/${college.id}`, { state: '/my-lists' })}
            >
              {college.name}
            </span>
            {isListsActionsActive && actions && (
              <Autocomplete
                isBudgetItems={false}
                ref={childRef}
                onclick={(item: any) => saveRemoveCollege(item.id, item.action)}
                filteredItem={actions}
              />
            )}
            <img
              onClick={() => {
                if (!adminNavigatedUser.subscription) {
                  setIsAddNotes(true);
                  open();
                  return;
                }
                setIsAddNotes(true);
                open();
              }}
              src={isNoteExists(college.id) ? selectedNotes : notesIcon}
              alt="notes"
            />
          </header>
          <img
            className="college-list-photo"
            loading="lazy"
            src={college?.hasImage ? `${process.env.REACT_APP_COLLEGE_IMAGES}${college?.ipeds}.png` : collegeIcon}
            alt="college"
          />
          <div>
            <p>Location</p>
            <span>{college.state?.name}</span>
          </div>
          <div>
            <p>Full Time Undergraduates</p>
            <span>{college.fullTimeUndergraduates}</span>
          </div>
          <div>
            <p>Student : Faculty Ratio</p>
            <span>{college?.studentToFaculty ? `${(+college.studentToFaculty).toFixed(0)} : 1` : '-'}</span>
          </div>
          <div>
            <p>Athletic Divisions</p>
            <span className="division-name">{college.athleticDivision?.name || '-'}</span>
          </div>
          <div>
            <p>Honors College</p>
            <span>{college.honorsCollege ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <p>Honors Program</p>
            <span>{college.honorsProgram ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <p>Co-Op</p>
            <span>{college.coop ? 'Yes' : 'No'}</span>
          </div>
          {adminNavigatedUser.subscription ? (
            <button type="button" className="my-list-primary-button" onClick={() => getOffers(college.id)}>
              <a>Crowdsourced Offers</a>
            </button>
          ) : (
            <button
              type="button"
              className="my-list-primary-button my-list-primary-button-locked"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
                open();
                setShowLockedScholarshipModal(!showLockedScholarshipModal);
              }}
            >
              <img src={iconGrey} alt="icon-grey" />
              <a>Crowdsourced Offers</a>
            </button>
          )}
          <hr className="my-list-line" />
          {isProfile && (
            <>
              {!adminNavigatedUser.subscription ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-profile">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Profile</h4>
                  </span>
                  <h4>Undergraduates</h4>
                  <div>
                    <p>Endowment Per Student</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Undergraduate Women</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Undergraduate Men</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Returning for Sophomore Year</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>4-Year Graduation Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <h4>Race/Enthnicity</h4>
                  <div>
                    <p>% UG White</p>
                    <span>{college?.enWhiteNonhispanic ? `${(+college.enWhiteNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG African American</p>
                    <span>{college?.enBlackNonhispanic ? `${(+college.enBlackNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Hispanic/Latino</p>
                    <span>{college?.enHispanicEthnicity ? `${(+college.enHispanicEthnicity).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Asian</p>
                    <span>{college?.enAsianNonhispanic ? `${(+college.enAsianNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Non-Residential Alien</p>
                    <span>{college?.enNonresAlien ? `${(+college.enNonresAlien).toFixed(0)} %` : '-'}</span>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Profile</h4>
                  </span>
                  <h4>Undergraduates</h4>
                  <div>
                    <p>Endowment Per Student</p>
                    <span>
                      {college?.endowmentPerStudent
                        ? `$ ${(+college.endowmentPerStudent).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Undergraduate Women</p>
                    <span>
                      {college?.pcUndergraduateWomen
                        ? `${(+college.pcUndergraduateWomen).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Undergraduate Men</p>
                    <span>
                      {college?.pcUndergraduateMen ? `${(+college.pcUndergraduateMen).toLocaleString('en-US')} %` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Returning for Sophomore Year</p>
                    <span>
                      {college?.pcReturningForSophomore
                        ? `${(+college.pcReturningForSophomore).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>4-Year Graduation Rate</p>
                    <span>
                      {college?.fourYearGraduationRate
                        ? `${(+college.fourYearGraduationRate).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <h4>Race/Enthnicity</h4>
                  <div>
                    <p>% UG White</p>
                    <span>{college?.enWhiteNonhispanic ? `${(+college.enWhiteNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG African American</p>
                    <span>{college?.enBlackNonhispanic ? `${(+college.enBlackNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Hispanic/Latino</p>
                    <span>{college?.enHispanicEthnicity ? `${(+college.enHispanicEthnicity).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Asian</p>
                    <span>{college?.enAsianNonhispanic ? `${(+college.enAsianNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Non-Residential Alien</p>
                    <span>{college?.enNonresAlien ? `${(+college.enNonresAlien).toFixed(0)} %` : '-'}</span>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isAdmission && (
            <>
              {!adminNavigatedUser.subscription ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-admission">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Admission</h4>
                  </span>
                  <h4>Admission Stats</h4>
                  <div>
                    <p>Average High School GPA</p>
                    <span>{college.avgFreshmenGpa}</span>
                  </div>
                  <div>
                    <p>4.0 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.75 - 3.99 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.5 - 3.49 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.0 - 3.49 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Below 3.0 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>SAT/ACT Score Required</p>
                    <span>
                      {college?.testScoreRequirementType === false
                        ? 'No'
                        : college?.testScoreRequirementType === true
                        ? 'Yes'
                        : 'Test Optional'}
                    </span>
                  </div>
                  <div>
                    <p>Avg SAT</p>
                    <span>{college?.satAvg ? +college.satAvg : '-'}</span>
                  </div>
                  <div>
                    <p>SAT 75th Percentile</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Avg ACT</p>
                    <span>{college?.actAvg ? (+college.actAvg).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>ACT 75th Percentile</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Overall Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>ED Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>EA Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>RD Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Percent of Class Filled ED</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Admit Yeild</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>App Fee</p>
                    <span>
                      {college?.applicationFee ? `$ ${(+college.applicationFee).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <h4>Deadlines</h4>
                  <div>
                    <p>Early Decision Deadline</p>
                    <span>{formatDate(college.edDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Action Deadline</p>
                    <span>{formatDate(college.eaDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Decision 2 Deadline</p>
                    <span>{formatDate(college.ed2Deadline)}</span>
                  </div>
                  <div>
                    <p>Regular Decision Deadline</p>
                    <span>{formatDate(college.regDecisionDeadline)}</span>
                  </div>
                  <div>
                    <p>Financial Aid Deadline</p>
                    <span>{formatDate(college.finAidDeadline)}</span>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Admission</h4>
                  </span>
                  <h4>Admission Stats</h4>
                  <div>
                    <p>Average High School GPA</p>
                    <span>{college.avgFreshmenGpa}</span>
                  </div>
                  <div>
                    <p>4.0 GPA</p>
                    <span>-</span>
                  </div>
                  <div>
                    <p>3.75 - 3.99 GPA</p>
                    <span>-</span>
                  </div>
                  <div>
                    <p>3.5 - 3.49 GPA</p>
                    <span>-</span>
                  </div>
                  <div>
                    <p>3.0 - 3.49 GPA</p>
                    <span>-</span>
                  </div>
                  <div>
                    <p>Below 3.0 GPA</p>
                    <span>-</span>
                  </div>
                  <div>
                    <p>SAT/ACT Score Required</p>
                    <span>
                      {college?.testScoreRequirementType === false
                        ? 'No'
                        : college?.testScoreRequirementType === true
                        ? 'Yes'
                        : 'Test Optional'}
                    </span>
                  </div>
                  <div>
                    <p>Avg SAT</p>
                    <span>{college?.satAvg ? +college.satAvg : '-'}</span>
                  </div>
                  <div>
                    <p>SAT 75th Percentile</p>
                    <span>{college?.sat75thPc ? +college.sat75thPc : '-'}</span>
                  </div>
                  <div>
                    <p>Avg ACT</p>
                    <span>{college?.actAvg ? (+college.actAvg).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>ACT 75th Percentile</p>
                    <span>{college?.act75thPc ? (+college.act75thPc).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>Overall Admit Rate</p>
                    <span>{college?.admissionsRate ? `${(+college.admissionsRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>ED Admit Rate</p>
                    <span>{college?.edAdmitRate ? `${(+college.edAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>EA Admit Rate</p>
                    <span>{college?.eaAdmitRate ? `${(+college.eaAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>RD Admit Rate</p>
                    <span>{college?.rdAdmitRate ? `${(+college.rdAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Percent of Class Filled ED</p>
                    <span>{college?.edFilledRate ? `${(+college.edFilledRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Admit Yeild</p>
                    <span>{college?.admitYield ? `${(+college.admitYield).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>App Fee</p>
                    <span>
                      {college?.applicationFee ? `$ ${(+college.applicationFee).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <h4>Deadlines</h4>
                  <div>
                    <p>Early Decision Deadline</p>
                    <span>{formatDate(college.edDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Action Deadline</p>
                    <span>{formatDate(college.eaDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Decision 2 Deadline</p>
                    <span>{formatDate(college.ed2Deadline)}</span>
                  </div>
                  <div>
                    <p>Regular Decision Deadline</p>
                    <span>{formatDate(college.regDecisionDeadline)}</span>
                  </div>
                  <div>
                    <p>Financial Aid Deadline</p>
                    <span>{formatDate(college.finAidDeadline)}</span>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isSchoolarship && (
            <>
              {!adminNavigatedUser.subscription ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-schoolarship">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Scholarship</h4>
                  </span>
                  <h4>Need Based Aid</h4>
                  <div>
                    <p>% of Need Met</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Avg Fin Aid Pkg For Freshmen w/Need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>CSS Profile</p>
                    <span>{college?.cssProfileRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <p>Non-Custodial CSS Form Required</p>
                    <span>{college?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <h4>Merit Based Scholarship</h4>
                  <div>
                    <p>Freshmen without need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Freshmen without need recieving merit aid</p>
                    <span>
                      {college?.pcStudentsReceivingMeritAid
                        ? `${(+college.pcStudentsReceivingMeritAid).toFixed(0)} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Avg merit award for freshmen without need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div className="scholarship-buttons-locked">
                    <a target="_blank" rel="noreferrer">
                      <img src={iconGrey} alt="icon-locked grey" />
                      <button>Merit Scholarship (s) Details</button>
                    </a>
                  </div>
                  <div className="scholarship-buttons-locked">
                    <a target="_blank" rel="noreferrer">
                      <img src={iconGrey} alt="icon-locked grey" />
                      <button>Full Tuition or Full Ride Scholarship</button>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Scholarship</h4>
                  </span>
                  <h4>Need Based Aid</h4>
                  <div>
                    <p>% of Need Met</p>
                    <span>{college?.avgPcNeedMet ? `${(+college.avgPcNeedMet).toLocaleString('en-US')} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Avg Fin Aid Pkg For Freshmen w/Need</p>
                    <span>
                      {college?.avgNeedBasedAward ? `$ ${(+college.avgNeedBasedAward).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>CSS Profile</p>
                    <span>{college?.cssProfileRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <p>Non-Custodial CSS Form Required</p>
                    <span>{college?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <h4>Merit Based Scholarship</h4>
                  <div>
                    <p>Freshmen without need</p>
                    <span>
                      {college?.pcStudentsWithoutNeed ? `${(+college.pcStudentsWithoutNeed).toFixed(0)} %` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Freshmen without need recieving merit aid</p>
                    <span>
                      {college?.pcStudentsReceivingMeritAid
                        ? `${(+college.pcStudentsReceivingMeritAid).toFixed(0)} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Avg merit award for freshmen without need</p>
                    <span>
                      {college?.avgMeritAidAward ? `$ ${(+college.avgMeritAidAward).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <div className="scholarship-buttons">
                    <a
                      onClick={() => {
                        if (!college?.scholarshipsUrl) {
                          setModalContent({
                            title: 'Merit Scholarship Details not Available',
                            message:
                              'We are sorry to inform you that Merit Scholarship details for this college are not available at the moment.',
                          });
                          open();
                        }
                      }}
                      target="_blank"
                      href={college?.scholarshipsUrl || undefined}
                      rel="noreferrer"
                    >
                      <button>Merit Scholarship (s) Details</button>
                    </a>
                  </div>
                  <div className="scholarship-buttons">
                    <a
                      onClick={() => {
                        if (!college?.urlFullScholarship) {
                          setModalContent({
                            title: 'Full Tuition/ Full Ride Scholarship Details not Available',
                            message:
                              'We are sorry to inform you that Full Tuition/ Full Ride Scholarship details for this college are not available at the moment.',
                          });
                          setScholashipModal(true);
                          open();
                        }
                      }}
                      target="_blank"
                      href={college?.urlFullScholarship || undefined}
                      rel="noreferrer"
                    >
                      <button>Full Tuition or Full Ride Scholarship</button>
                    </a>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isFinance && (
            <>
              <div className="my-list-college-route">
                <span>
                  <h4 className="my-list-college-title">Finance</h4>
                </span>
                <h4>Costs</h4>
                <div>
                  <p>Private COA</p>
                  <span>{college?.coaPrivate ? `$ ${(+college.coaPrivate).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>In-State Cost of Attendance</p>
                  <span>{college?.coaInState ? `$ ${(+college.coaInState).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Out-of-State Cost of Attendance</p>
                  <span>{college?.coaOutState ? `$ ${(+college.coaOutState).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Average Net Price After Gift Aid</p>
                  <span>
                    {college?.avgNetPriceAfterAid ? `$ ${(+college.avgNetPriceAfterAid).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div className="net-price-calc">
                  <p>Net Price Calculator</p>
                  <span>
                    <a
                      target="_blank"
                      href={college?.urlAddressPriceCalc || undefined}
                      rel="noreferrer"
                      onClick={() => {
                        if (!college.urlAddressPriceCalc) {
                          setModalContent({
                            title: 'Calculator unavailable',
                            message:
                              'We are sorry to inform you that calculator for this college are not available at the moment.',
                          });
                          setScholashipModal(true);
                          open();
                        }
                      }}
                    >
                      <button>Calculator</button>
                    </a>
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $0 - $30,000</p>
                  <span>
                    {college?.avgPriceTo30000 ? `$ ${(+college.avgPriceTo30000).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $30,001 - $48,000</p>
                  <span>
                    {college?.avgPriceFrom30001To48000
                      ? `$ ${(+college.avgPriceFrom30001To48000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $48,001 - $75,000</p>
                  <span>
                    {college?.avgPriceFrom48001To75000
                      ? `$ ${(+college.avgPriceFrom48001To75000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $75,001 - $110,000</p>
                  <span>
                    {college?.avgPriceFrom75001To110000
                      ? `$ ${(+college.avgPriceFrom75001To110000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income over $110,000</p>
                  <span>
                    {college?.avgPriceOver110000 ? `$ ${(+college.avgPriceOver110000).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <h4>Debt</h4>
                <div>
                  <p>Percent of Undergrads w/ Federal Student Loans</p>
                  <span>{college?.gradsAnyLoanP ? `${(+college.gradsAnyLoanP).toLocaleString('en-US')} %` : '-'}</span>
                </div>
                <div>
                  <p>Average Annual Federal Student Loans for Undergrads</p>
                  <span>
                    {college?.gradsAnyLoanAvgAmt ? `$ ${(+college.gradsAnyLoanAvgAmt).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div>
                  <p>Grads From Recent Class w/ Any Type of Loan(s)</p>
                  <span>
                    {college?.pcGraduatingWithLoans
                      ? `${(+college.pcGraduatingWithLoans).toLocaleString('en-US')}%`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Balances From Loans (All Types)</p>
                  <span>{college?.avgLoanAmount ? `$ ${(+college.avgLoanAmount).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Percent of Undergrads w/ Parent PLUS Loans</p>
                  <span>{college?.parentPlusP ? `${(+college.parentPlusP).toLocaleString('en-US')} %` : '-'}</span>
                </div>
                <div>
                  <p>Average Parent PLUS Loans per Borrower</p>
                  <span>
                    {college?.parentPlusAvgAmt ? `$ ${(+college.parentPlusAvgAmt).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
              </div>
              <hr className="my-list-line" />
            </>
          )}
          <Modal>
            {adminNavigatedUser?.subscription && showOffers && !showScholarshipModal ? (
              <OffersModal
                close={() => {
                  setShowOffers(false);
                  close();
                }}
              />
            ) : !adminNavigatedUser.subscription && showLockedScholarshipModal ? (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setShowLockedScholarshipModal(!showLockedScholarshipModal)}
                title={'Premium Feature'}
              />
            ) : isAddListModal && !adminNavigatedUser?.subscription ? (
              <AddListModal createList={createList} close={close} />
            ) : isAddListModal ? (
              <AddListModal
                close={() => {
                  close();
                  setIsAddListModal(false);
                }}
                needAddToList={true}
                additionalValue={college.id}
              />
            ) : isAddNotes && !adminNavigatedUser.subscription ? (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                title={'Premium Feature'}
                anyAction={() => setIsAddNotes(false)}
              />
            ) : !allowedCreateList ? (
              <UniversalModal
                close={() => {
                  setAllowedCreateList(true);
                  close();
                }}
                textForTitle={'Upgrade Account'}
                textForDescription={
                  'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            ) : isAddNotes ? (
              <AddNotesModal
                close={() => {
                  setIsAddNotes(false);
                  close();
                }}
                propNotes={college.notes}
                collegeId={college.id}
                collegeName={college.name}
                userId={adminNavigatedUser.id}
              />
            ) : !college?.urlAddressPriceCalc ? (
              <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
            ) : (
              !college?.urlFullScholarship && (
                <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
              )
            )}
          </Modal>

          {!adminNavigatedUser.subscription &&
            !allowed &&
            sorted(selectedList.colleges)[5] &&
            college.id === sorted(selectedList.colleges)[5]['collegeId'] && (
              <div className="not-allowed-modal">
                <InfoLockedModal
                  close={close}
                  textForTitle={'Information Locked'}
                  textForDescription={
                    'View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to premium features and data.'
                  }
                  textSecondBtn={'Unlock'}
                  withClose={false}
                />

                <div className="not-allowed-modal-bg" />
              </div>
            )}
        </div>
      ) : (
        <div
          onClick={() => setIsListsActionsActive(null)}
          className={`my-list-college ${isInList ? '' : 'padding-my-list-college-mobile'} ${
            !user.subscription &&
            user.role !== 'admin' &&
            !allowed &&
            sorted(selectedList.colleges)[5] &&
            college.id === sorted(selectedList.colleges)[5]['collegeId']
              ? 'not-allowed'
              : ''
          }`}
        >
          {!isInList && <span className="recommended">RECOMMENDED</span>}
          <header className="college-header-my-list">
            <img
              onClick={(e) => {
                e.stopPropagation();
                setIsListsActionsActive(college.id);
              }}
              src={isInList ? unsaveIcon : saveIcon}
              alt="unsave"
            />
            <span
              className="college-header-my-list-title"
              onClick={() => navigate(`/college-details/${college.id}`, { state: '/my-lists' })}
            >
              {college.name}
            </span>
            {isListsActionsActive && actions && (
              <Autocomplete
                isBudgetItems={false}
                ref={childRef}
                onclick={(item: any) => saveRemoveCollege(item.id, item.action)}
                filteredItem={actions}
              />
            )}
            <img
              onClick={() => {
                if (!user.subscription && user.role !== 'admin') {
                  setIsAddNotes(true);
                  open();
                  return;
                }
                setIsAddNotes(true);
                open();
              }}
              src={isNoteExists(college.id) ? selectedNotes : notesIcon}
              alt="notes"
            />
          </header>
          <img
            className="college-list-photo"
            loading="lazy"
            src={college?.hasImage ? `${process.env.REACT_APP_COLLEGE_IMAGES}${college?.ipeds}.png` : collegeIcon}
            alt="college"
          />
          <div>
            <p>Location</p>
            <span>{college.state?.name}</span>
          </div>
          <div>
            <p>Full Time Undergraduates</p>
            <span>{college.fullTimeUndergraduates}</span>
          </div>
          <div>
            <p>Student : Faculty Ratio</p>
            <span>{college?.studentToFaculty ? `${(+college.studentToFaculty).toFixed(0)} : 1` : '-'}</span>
          </div>
          <div>
            <p>Athletic Divisions</p>
            <span className="division-name">{college.athleticDivision?.name || '-'}</span>
          </div>
          <div>
            <p>Honors College</p>
            <span>{college.honorsCollege ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <p>Honors Program</p>
            <span>{college.honorsProgram ? 'Yes' : 'No'}</span>
          </div>
          <div>
            <p>Co-Op</p>
            <span>{college.coop ? 'Yes' : 'No'}</span>
          </div>
          {user.subscription || user.role === 'admin' ? (
            <button type="button" className="my-list-primary-button" onClick={() => getOffers(college.id)}>
              <a>Crowdsourced Offers</a>
            </button>
          ) : (
            <button
              type="button"
              className="my-list-primary-button my-list-primary-button-locked"
              onClick={() => {
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: 'smooth',
                });
                open();
                setShowLockedScholarshipModal(!showLockedScholarshipModal);
              }}
            >
              <img src={iconGrey} alt="icon-grey" />
              <a>Crowdsourced Offers</a>
            </button>
          )}
          <hr className="my-list-line" />
          {isProfile && (
            <>
              {!user.subscription && user.role !== 'admin' ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-profile">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Profile</h4>
                  </span>
                  <h4>Undergraduates</h4>
                  <div>
                    <p>Endowment Per Student</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Undergraduate Women</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Undergraduate Men</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>% Returning for Sophomore Year</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>4-Year Graduation Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <h4>Race/Enthnicity</h4>
                  <div>
                    <p>% UG White</p>
                    <span>{college?.enWhiteNonhispanic ? `${(+college.enWhiteNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG African American</p>
                    <span>{college?.enBlackNonhispanic ? `${(+college.enBlackNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Hispanic/Latino</p>
                    <span>{college?.enHispanicEthnicity ? `${(+college.enHispanicEthnicity).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Asian</p>
                    <span>{college?.enAsianNonhispanic ? `${(+college.enAsianNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Non-Residential Alien</p>
                    <span>{college?.enNonresAlien ? `${(+college.enNonresAlien).toFixed(0)} %` : '-'}</span>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Profile</h4>
                  </span>
                  <h4>Undergraduates</h4>
                  <div>
                    <p>Endowment Per Student</p>
                    <span>
                      {college?.endowmentPerStudent
                        ? `$ ${(+college.endowmentPerStudent).toLocaleString('en-US')}`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Undergraduate Women</p>
                    <span>
                      {college?.pcUndergraduateWomen
                        ? `${(+college.pcUndergraduateWomen).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Undergraduate Men</p>
                    <span>
                      {college?.pcUndergraduateMen ? `${(+college.pcUndergraduateMen).toLocaleString('en-US')} %` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>% Returning for Sophomore Year</p>
                    <span>
                      {college?.pcReturningForSophomore
                        ? `${(+college.pcReturningForSophomore).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>4-Year Graduation Rate</p>
                    <span>
                      {college?.fourYearGraduationRate
                        ? `${(+college.fourYearGraduationRate).toLocaleString('en-US')} %`
                        : '-'}
                    </span>
                  </div>
                  <h4>Race/Enthnicity</h4>
                  <div>
                    <p>% UG White</p>
                    <span>{college?.enWhiteNonhispanic ? `${(+college.enWhiteNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG African American</p>
                    <span>{college?.enBlackNonhispanic ? `${(+college.enBlackNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Hispanic/Latino</p>
                    <span>{college?.enHispanicEthnicity ? `${(+college.enHispanicEthnicity).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Asian</p>
                    <span>{college?.enAsianNonhispanic ? `${(+college.enAsianNonhispanic).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>% UG Non-Residential Alien</p>
                    <span>{college?.enNonresAlien ? `${(+college.enNonresAlien).toFixed(0)} %` : '-'}</span>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isAdmission && (
            <>
              {!user.subscription && user.role !== 'admin' ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-admission">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Admission</h4>
                  </span>
                  <h4>Admission Stats</h4>
                  <div>
                    <p>Average High School GPA</p>
                    <span>{college.avgFreshmenGpa}</span>
                  </div>
                  <div>
                    <p>4.0 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.75 - 3.99 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.5 - 3.49 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>3.0 - 3.49 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Below 3.0 GPA</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>SAT/ACT Score Required</p>
                    <span>
                      {college?.testScoreRequirementType === false
                        ? 'No'
                        : college?.testScoreRequirementType === true
                        ? 'Yes'
                        : 'Test Optional'}
                    </span>
                  </div>
                  <div>
                    <p>Avg SAT</p>
                    <span>{college?.satAvg ? +college.satAvg : '-'}</span>
                  </div>
                  <div>
                    <p>SAT 75th Percentile</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Avg ACT</p>
                    <span>{college?.actAvg ? (+college.actAvg).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>ACT 75th Percentile</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Overall Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>ED Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>EA Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>RD Admit Rate</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Percent of Class Filled ED</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Admit Yeild</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>App Fee</p>
                    <span>
                      {college?.applicationFee ? `$ ${(+college.applicationFee).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <h4>Deadlines</h4>
                  <div>
                    <p>Early Decision Deadline</p>
                    <span>{formatDate(college.edDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Action Deadline</p>
                    <span>{formatDate(college.eaDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Decision 2 Deadline</p>
                    <span>{formatDate(college.ed2Deadline)}</span>
                  </div>
                  <div>
                    <p>Regular Decision Deadline</p>
                    <span>{formatDate(college.regDecisionDeadline)}</span>
                  </div>
                  <div>
                    <p>Financial Aid Deadline</p>
                    <span>{formatDate(college.finAidDeadline)}</span>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Admission</h4>
                  </span>
                  <h4>Admission Stats</h4>
                  <div>
                    <p>Average High School GPA</p>
                    <span>{college.avgFreshmenGpa}</span>
                  </div>
                  <div>
                    <p>4.0 GPA</p>
                    <span>{college?.avgFreshmenGpa1P ? `${Math.ceil(+college.avgFreshmenGpa1P)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>3.75 - 3.99 GPA</p>
                    <span>{college?.avgFreshmenGpa2P ? `${Math.ceil(+college.avgFreshmenGpa2P)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>3.5 - 3.74 GPA</p>
                    <span>{college?.avgFreshmenGpa3P ? `${Math.ceil(+college.avgFreshmenGpa3P)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>3.0 - 3.49 GPA</p>
                    <span>{college?.avgFreshmenGpa4P ? `${+Math.ceil(college.avgFreshmenGpa4P)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Below 3.0 GPA</p>
                    <span>
                      {college?.avgFreshmenGpaBelow3P ? `${Math.ceil(+college.avgFreshmenGpaBelow3P)} %` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>SAT/ACT Score Required</p>
                    <span>
                      {college?.testScoreRequirementType === false
                        ? 'No'
                        : college?.testScoreRequirementType === true
                        ? 'Yes'
                        : 'Test Optional'}
                    </span>
                  </div>
                  <div>
                    <p>Avg SAT</p>
                    <span>{college?.satAvg ? +college.satAvg : '-'}</span>
                  </div>
                  <div>
                    <p>SAT 75th Percentile</p>
                    <span>{college?.sat75thPc ? +college.sat75thPc : '-'}</span>
                  </div>
                  <div>
                    <p>Avg ACT</p>
                    <span>{college?.actAvg ? (+college.actAvg).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>ACT 75th Percentile</p>
                    <span>{college?.act75thPc ? (+college.act75thPc).toLocaleString('en-US') : '-'}</span>
                  </div>
                  <div>
                    <p>Overall Admit Rate</p>
                    <span>{college?.admissionsRate ? `${(+college.admissionsRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>ED Admit Rate</p>
                    <span>{college?.edAdmitRate ? `${(+college.edAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>EA Admit Rate</p>
                    <span>{college?.eaAdmitRate ? `${(+college.eaAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>RD Admit Rate</p>
                    <span>{college?.rdAdmitRate ? `${(+college.rdAdmitRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Percent of Class Filled ED</p>
                    <span>{college?.edFilledRate ? `${(+college.edFilledRate).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Admit Yeild</p>
                    <span>{college?.admitYield ? `${(+college.admitYield).toFixed(0)} %` : '-'}</span>
                  </div>
                  <div>
                    <p>App Fee</p>
                    <span>
                      {college?.applicationFee ? `$ ${(+college.applicationFee).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <h4>Deadlines</h4>
                  <div>
                    <p>Early Decision Deadline</p>
                    <span>{formatDate(college.edDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Action Deadline</p>
                    <span>{formatDate(college.eaDeadline)}</span>
                  </div>
                  <div>
                    <p>Early Decision 2 Deadline</p>
                    <span>{formatDate(college.ed2Deadline)}</span>
                  </div>
                  <div>
                    <p>Regular Decision Deadline</p>
                    <span>{formatDate(college.regDecisionDeadline)}</span>
                  </div>
                  <div>
                    <p>Financial Aid Deadline</p>
                    <span>{formatDate(college.finAidDeadline)}</span>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isSchoolarship && (
            <>
              {!user.subscription && user.role !== 'admin' ? (
                <div className="my-list-college-route">
                  <div className="my-list-modal-schoolarship">
                    <img src={lock} alt="locked" className="my-list-modal-profile-img" />
                    {/* <p>Information locked</p> */}
                    <div className="my-list-modal-text">Upgrade Profile to see all this Information!</div>
                    <NavLink to={isAuthenticated ? '/buy-plan' : ''} onClick={() => !isAuthenticated && open()}>
                      Unlock
                    </NavLink>
                  </div>
                  <span>
                    <h4 className="my-list-college-title">Scholarship</h4>
                  </span>
                  <h4>Need Based Aid</h4>
                  <div>
                    <p>% of Need Met</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Avg Fin Aid Pkg For Freshmen w/Need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>CSS Profile</p>
                    <span>{college?.cssProfileRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <p>Non-Custodial CSS Form Required</p>
                    <span>{college?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <h4>Merit Based Scholarship</h4>
                  <div>
                    <p>Freshmen without need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div>
                    <p>Freshmen without need recieving merit aid</p>
                    <span>
                      {college?.pcStudentsReceivingMeritAid
                        ? `${(+college.pcStudentsReceivingMeritAid).toFixed(0)} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Avg merit award for freshmen without need</p>
                    <img src={iconGrey} alt="icon-grey" />
                  </div>
                  <div className="scholarship-buttons-locked">
                    <a target="_blank" rel="noreferrer">
                      <img src={iconGrey} alt="icon-locked grey" />
                      <button>Merit Scholarship (s) Details</button>
                    </a>
                  </div>
                  <div className="scholarship-buttons-locked">
                    <a target="_blank" rel="noreferrer">
                      <img src={iconGrey} alt="icon-locked grey" />
                      <button>Full Tuition or Full Ride Scholarship</button>
                    </a>
                  </div>
                </div>
              ) : (
                <div className="my-list-college-route">
                  <span>
                    <h4 className="my-list-college-title">Scholarship</h4>
                  </span>
                  <h4>Need Based Aid</h4>
                  <div>
                    <p>% of Need Met</p>
                    <span>{college?.avgPcNeedMet ? `${(+college.avgPcNeedMet).toLocaleString('en-US')} %` : '-'}</span>
                  </div>
                  <div>
                    <p>Avg Fin Aid Pkg For Freshmen w/Need</p>
                    <span>
                      {college?.avgNeedBasedAward ? `$ ${(+college.avgNeedBasedAward).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>CSS Profile</p>
                    <span>{college?.cssProfileRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <div>
                    <p>Non-Custodial CSS Form Required</p>
                    <span>{college?.nonCustodialCssRequired ? 'Yes' : 'No'}</span>
                  </div>
                  <h4>Merit Based Scholarship</h4>
                  <div>
                    <p>Freshmen without need</p>
                    <span>
                      {college?.pcStudentsWithoutNeed ? `${(+college.pcStudentsWithoutNeed).toFixed(0)} %` : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Freshmen without need recieving merit aid</p>
                    <span>
                      {college?.pcStudentsReceivingMeritAid
                        ? `${(+college.pcStudentsReceivingMeritAid).toFixed(0)} %`
                        : '-'}
                    </span>
                  </div>
                  <div>
                    <p>Avg merit award for freshmen without need</p>
                    <span>
                      {college?.avgMeritAidAward ? `$ ${(+college.avgMeritAidAward).toLocaleString('en-US')}` : '-'}
                    </span>
                  </div>
                  <div className="scholarship-buttons">
                    <a
                      onClick={() => {
                        if (!college?.scholarshipsUrl) {
                          setModalContent({
                            title: 'Merit Scholarship Details not Available',
                            message:
                              'We are sorry to inform you that Merit Scholarship details for this college are not available at the moment.',
                          });
                          open();
                        }
                      }}
                      target="_blank"
                      href={college?.scholarshipsUrl || undefined}
                      rel="noreferrer"
                    >
                      <button>Merit Scholarship (s) Details</button>
                    </a>
                  </div>
                  <div className="scholarship-buttons">
                    <a
                      onClick={() => {
                        if (!college?.urlFullScholarship) {
                          setModalContent({
                            title: 'Full Tuition/ Full Ride Scholarship Details not Available',
                            message:
                              'We are sorry to inform you that Full Tuition/ Full Ride Scholarship details for this college are not available at the moment.',
                          });
                          setScholashipModal(true);
                          open();
                        }
                      }}
                      target="_blank"
                      href={college?.urlFullScholarship || undefined}
                      rel="noreferrer"
                    >
                      <button>Full Tuition or Full Ride Scholarship</button>
                    </a>
                  </div>
                </div>
              )}
              <hr className="my-list-line" />
            </>
          )}
          {isFinance && (
            <>
              <div className="my-list-college-route">
                <span>
                  <h4 className="my-list-college-title">Finance</h4>
                </span>
                <h4>Costs</h4>
                <div>
                  <p>Private COA</p>
                  <span>{college?.coaPrivate ? `$ ${(+college.coaPrivate).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>In-State Cost of Attendance</p>
                  <span>{college?.coaInState ? `$ ${(+college.coaInState).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Out-of-State Cost of Attendance</p>
                  <span>{college?.coaOutState ? `$ ${(+college.coaOutState).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Average Net Price After Gift Aid</p>
                  <span>
                    {college?.avgNetPriceAfterAid ? `$ ${(+college.avgNetPriceAfterAid).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div className="net-price-calc">
                  <p>Net Price Calculator</p>
                  <span>
                    <a
                      target="_blank"
                      href={college?.urlAddressPriceCalc || undefined}
                      rel="noreferrer"
                      onClick={() => {
                        if (!college.urlAddressPriceCalc) {
                          setModalContent({
                            title: 'Calculator unavailable',
                            message:
                              'We are sorry to inform you that calculator for this college are not available at the moment.',
                          });
                          setScholashipModal(true);
                          open();
                        }
                      }}
                    >
                      <button>Calculator</button>
                    </a>
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $0 - $30,000</p>
                  <span>
                    {college?.avgPriceTo30000 ? `$ ${(+college.avgPriceTo30000).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $30,001 - $48,000</p>
                  <span>
                    {college?.avgPriceFrom30001To48000
                      ? `$ ${(+college.avgPriceFrom30001To48000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $48,001 - $75,000</p>
                  <span>
                    {college?.avgPriceFrom48001To75000
                      ? `$ ${(+college.avgPriceFrom48001To75000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income $75,001 - $110,000</p>
                  <span>
                    {college?.avgPriceFrom75001To110000
                      ? `$ ${(+college.avgPriceFrom75001To110000).toLocaleString('en-US')}`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Net Price Family Income over $110,000</p>
                  <span>
                    {college?.avgPriceOver110000 ? `$ ${(+college.avgPriceOver110000).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <h4>Debt</h4>
                <div>
                  <p>Percent of Undergrads w/ Federal Student Loans</p>
                  <span>{college?.gradsAnyLoanP ? `${(+college.gradsAnyLoanP).toLocaleString('en-US')} %` : '-'}</span>
                </div>
                <div>
                  <p>Average Annual Federal Student Loans for Undergrads</p>
                  <span>
                    {college?.gradsAnyLoanAvgAmt ? `$ ${(+college.gradsAnyLoanAvgAmt).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
                <div>
                  <p>Grads From Recent Class w/ Any Type of Loan(s)</p>
                  <span>
                    {college?.pcGraduatingWithLoans
                      ? `${(+college.pcGraduatingWithLoans).toLocaleString('en-US')}%`
                      : '-'}
                  </span>
                </div>
                <div>
                  <p>Average Balances From Loans (All Types)</p>
                  <span>{college?.avgLoanAmount ? `$ ${(+college.avgLoanAmount).toLocaleString('en-US')}` : '-'}</span>
                </div>
                <div>
                  <p>Percent of Undergrads w/ Parent PLUS Loans</p>
                  <span>{college?.parentPlusP ? `${(+college.parentPlusP).toLocaleString('en-US')} %` : '-'}</span>
                </div>
                <div>
                  <p>Average Parent PLUS Loans per Borrower</p>
                  <span>
                    {college?.parentPlusAvgAmt ? `$ ${(+college.parentPlusAvgAmt).toLocaleString('en-US')}` : '-'}
                  </span>
                </div>
              </div>
              <hr className="my-list-line" />
            </>
          )}
          <Modal>
            {(user?.subscription || user.role === 'admin') && showOffers && !showScholarshipModal ? (
              <OffersModal
                close={() => {
                  setShowOffers(false);
                  close();
                }}
              />
            ) : !user.subscription && user.role !== 'admin' && showLockedScholarshipModal ? (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setShowLockedScholarshipModal(!showLockedScholarshipModal)}
                title={'Premium Feature'}
              />
            ) : isAddListModal && !user?.subscription && user.role !== 'admin' ? (
              <AddListModal createList={createList} close={close} />
            ) : isAddListModal ? (
              <AddListModal
                close={() => {
                  close();
                  setIsAddListModal(false);
                }}
                needAddToList={true}
                additionalValue={college.id}
              />
            ) : isAddNotes && !user.subscription && user.role !== 'admin' ? (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                title={'Premium Feature'}
                anyAction={() => setIsAddNotes(false)}
              />
            ) : !allowedCreateList ? (
              <UniversalModal
                close={() => {
                  setAllowedCreateList(true);
                  close();
                }}
                textForTitle={'Upgrade Account'}
                textForDescription={
                  'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            ) : isAddNotes ? (
              <AddNotesModal
                close={() => {
                  setIsAddNotes(false);
                  close();
                }}
                propNotes={college.notes}
                collegeId={college.id}
                collegeName={college.name}
                userId={user.id}
              />
            ) : !college?.urlAddressPriceCalc ? (
              <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
            ) : (
              !college?.urlFullScholarship && (
                <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
              )
            )}
          </Modal>

          {!user.subscription &&
            user.role !== 'admin' &&
            !allowed &&
            sorted(selectedList.colleges)[5] &&
            college.id === sorted(selectedList.colleges)[5]['collegeId'] && (
              <div className="not-allowed-modal">
                <InfoLockedModal
                  close={close}
                  textForTitle={'Information Locked'}
                  textForDescription={
                    'View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to premium features and data.'
                  }
                  textSecondBtn={'Unlock'}
                  withClose={false}
                />

                <div className="not-allowed-modal-bg" />
              </div>
            )}
        </div>
      )}
    </>
  );
};
