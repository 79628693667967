import { produce } from 'immer';
import { createReducer } from './index';
import { IPreviousRouteState } from '../../models';
import { previousRouteActionTypes } from '../../consts';
import { previousRouteState } from '../states/previous-route.state';

export const previousRouteReducer = createReducer<IPreviousRouteState>(previousRouteState, {
  [previousRouteActionTypes.SET_PREVIOUS_ROUTE]: setPreviousRoute,
});

function setPreviousRoute(state: IPreviousRouteState, payload: string) {
  return produce(state, (draft) => {
    draft.previousRoute = payload;
  });
}
