import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect } from 'react';
import { EUserRoles } from '../enums';
import { IUser } from '../models';
import { userAction } from '../store/actions';
import { selectUserRole } from '../store/selectors';
import { AdminNavigator } from './admin.navigator';
import { UserNavigator } from './user.navigator';

interface AuthNavigatorProps {}

export const AuthNavigator: React.FC<AuthNavigatorProps> = () => {
  const dispatch = useDispatch();
  const role = useSelector(selectUserRole);

  const { user } = useAuth0();

  useEffect(() => {
    if (user) {
      const { email } = user;

      dispatch(
        userAction.setUser({
          email,
        } as IUser),
      );

      dispatch(
        userAction.getUser({
          email: email as string,
        }),
      );
    }
  }, []);

  return role === EUserRoles.ADMIN ? <AdminNavigator /> : <UserNavigator />;
};
