import { produce } from 'immer';

import { createReducer } from '.';
import { offersActionTypes } from '../../consts';
import { IAction, IOffersState } from '../../models';
import { offersState } from '../states/offers.state';

export const offersReducer = createReducer<IOffersState>(offersState, {
  [offersActionTypes.SET_OFFERS]: setOffers,
});

function setOffers(state: IOffersState, { payload }: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.offers = payload;
  });
}
