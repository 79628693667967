import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {
  CollegeDetails,
  LandingPage,
  Membership,
  MarketingPage,
  Header,
  Footer,
  SharedList,
  BuyPlanPage,
} from '../components';
import { RedirectPage } from '../components/redirect-page/RedirectPage';
import { RequirePay } from '../components/require-pay/RequirePay';
import { myListsAction } from '../store/actions';

export const UnauthNavigator = () => {
  const dispatch = useDispatch();
  const isSharedListV2 = window.location.pathname.includes('shared-lists');
  const isBuyPlan = window.location.pathname.includes('buy-plan');

  useEffect(() => {
    const numbersAfterLastSlash = window.location.href.split('/').pop();
    if (numbersAfterLastSlash && isSharedListV2) {
      dispatch(myListsAction.setIdSharedList(Number(numbersAfterLastSlash)));
      dispatch(myListsAction.setIsSharedList(true));
    }
  }, []);

  return (
    <>
      {(isSharedListV2 || isBuyPlan) && <Header />}
      <Routes>
        <Route path="/marketing-page" element={<MarketingPage />} />
        <Route path={'require-pay'} element={<RequirePay />} />
        <Route path={'redirect-page'} element={<RedirectPage />} />
        <Route path="/landing-page" element={<LandingPage />} />
        <Route path="/membership" element={<Membership />} />
        <Route path="/college-details/:id" element={<CollegeDetails />} />
        <Route path="/buy-plan" element={<BuyPlanPage />} />
        <Route path="/shared-lists/:id" element={<SharedList />} />
        <Route path="/*" element={<Navigate replace to="/landing-page" />} />
      </Routes>
      {(isSharedListV2 || isBuyPlan) && <Footer />}
    </>
  );
};
