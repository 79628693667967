import { createSelector } from 'reselect';

import { IStatesState, IRootState } from '../../models';
import { selectUserStateId } from './user.selector';

export const selectStatesState = (rootState: IRootState): IStatesState => rootState.statesState;

export const selectStates = createSelector([selectStatesState], (statesState) => statesState.states);

export const selectStateById = createSelector([selectStates, selectUserStateId], (state, stateId) =>
  state.find((s) => s.id === stateId),
);
