import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';
import { IConfigurationModel } from '../models';

export const configurationService = (function () {
  const endpoint_get_configuration = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_configurations);
  };

  const endpoint_update_configuration = async (body: IConfigurationModel) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.patch(apiUrls.update_configuration, body);
  };

  return {
    endpoint_get_configuration,
    endpoint_update_configuration,
  };
})();
