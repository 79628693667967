import './Delete-list-modal.css';
import cross from '../../images/cross.svg';

export const DeleteListModal = ({
  close,
  deleteList,
  listName,
  err,
}: {
  close: any;
  deleteList: any;
  listName: string;
  err?: string;
}) => {
  return (
    <div className="modal-sign-up">
      <img onClick={close} src={cross} alt="cross" />
      <h1>Delete {listName}</h1>
      <p>Confirm that you want to delete {listName}</p>
      {err && <div className="error">{err}</div>}
      <div>
        <button type="button" className="cancle-button" onClick={close}>
          Cancel
        </button>
        <button
          type="button"
          className="sign-up-modal-button"
          onClick={() => {
            close();
            deleteList();
          }}
        >
          Delete
        </button>
      </div>
    </div>
  );
};
