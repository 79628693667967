import React from 'react';
import { btnStyles, modalStyles } from '../social-medias/SocialMedias';

interface IMetadataFacebook {
  url: string;
  quote: string;
  description: string;
  imageUrl: string;
}

type Props = {
  onHandleClickShareList: () => Promise<void>;
  onHandleOpenedSocialMedia: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleOpenModalWithZeroSharingColleges: () => boolean;
};

export const FacebookButton = ({
  onHandleClickShareList,
  onHandleOpenedSocialMedia,
  onHandleOpenModalWithZeroSharingColleges,
}: Props) => {
  const metadataFacebook: IMetadataFacebook = {
    url: `${window.location.href.replace('my-lists', 'shared-lists')}`,
    quote: 'Check out my College List!',
    description: '',
    imageUrl:
      'https://static01.nyt.com/images/2015/02/19/arts/international/19iht-btnumbers19A/19iht-btnumbers19A-facebookJumbo-v2.jpg',
  };

  const handleButtonClick = () => {
    const needOpenModal = onHandleOpenModalWithZeroSharingColleges();
    if (needOpenModal) return;

    const facebookUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
      metadataFacebook.url,
    )}&quote=${encodeURIComponent(metadataFacebook.quote)}&description=${encodeURIComponent(
      metadataFacebook.description,
    )}&picture=${encodeURIComponent(metadataFacebook.imageUrl)}`;

    window.open(
      facebookUrl,
      'Share on Facebook',
      `width=${modalStyles.modalWidth},height=${modalStyles.modalHeight},left=${modalStyles.left},top=${modalStyles.top}`,
    );
    onHandleClickShareList();
    onHandleOpenedSocialMedia(false);
  };

  return (
    <button
      onClick={handleButtonClick}
      aria-label="Facebook Button"
      className="social-button"
      style={{ width: btnStyles.size, height: btnStyles.size }}
    >
      <span className="social-button__inside facebook" />
    </button>
  );
};
