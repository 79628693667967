import { ECollegeNotesActionTypes } from '../../enums';
import { ICollegeNote } from '../../models';

const getCollegeNotes = (payload: { collegeId: number; userId: number }) => {
  return {
    type: ECollegeNotesActionTypes.GET_COLLEGE_NOTES,
    payload,
  };
};

const createCollegeNote = (payload: Partial<ICollegeNote>) => {
  return {
    type: ECollegeNotesActionTypes.CREATE_COLLEGE_NOTE,
    payload,
  };
};

const updateCollegeNote = (payload: Partial<ICollegeNote>) => {
  return {
    type: ECollegeNotesActionTypes.UPDATE_COLLEGE_NOTE,
    payload,
  };
};

const deleteCollegeNote = (payload: Partial<ICollegeNote>) => {
  return {
    type: ECollegeNotesActionTypes.DELETE_COLLEGE_NOTE,
    payload,
  };
};

const setCollegeNotes = (payload: Array<ICollegeNote>) => {
  return {
    type: ECollegeNotesActionTypes.SET_COLLEGE_NOTES,
    payload,
  };
};

export const collegeNotesAction = {
  getCollegeNotes,
  updateCollegeNote,
  createCollegeNote,
  deleteCollegeNote,
  setCollegeNotes,
};
