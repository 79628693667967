import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const subscriptionService = (function () {
  const endpoint_get_payment_intent = async (packageId: number, userEmail: string, couponId: string | null) => {
    return axiosService.get(apiUrls.get_payment_intent(packageId, userEmail, couponId));
  };

  const endpoint_get_payment_method_by_userId = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_payment_method_by_userId(id));
  };

  const endpoint_get_subscription_from_stripe = async (subscriptionId: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.stripe_subscription_list(subscriptionId));
  };

  const endpoint_get_subscription_by_userId = async (userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_subscription_by_userId(userId));
  };

  const endpoint_delete_subscription = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.subscription(id));
  };

  const endpoint_change_status_subscription = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.del(apiUrls.change_status_subscription(id));
  };

  const endpoint_delete_subscription_by_subscriptionId = async (subscriptionId: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_subscription_by_subscriptionId(subscriptionId));
  };

  const endpoint_update_subscription = async (id: number, body: any) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.subscription(id), body);
  };

  const endpoint_create_subscription = async (body: any) => {
    return axiosService.post(apiUrls.create_subscription, body);
  };

  const endpoint_admin_create_internal_subscription = async (
    email: string,
    date: string,
    coupon: string | null,
    packageId: number,
  ) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.admin_create_internal_subscription, { email, expiredAt: date, coupon, packageId });
  };

  const endpoint_admin_create_stripe_subscription = async (email: string, packageId: number, coupon: string | null) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.admin_create_stripe_subscription, { email, packageId, coupon });
  };

  const endpoint_cancel_subscription = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.patch(apiUrls.cancel_subscription(id));
  };

  return {
    endpoint_get_payment_intent,
    endpoint_create_subscription,
    endpoint_get_payment_method_by_userId,
    endpoint_delete_subscription,
    endpoint_change_status_subscription,
    endpoint_update_subscription,
    endpoint_admin_create_internal_subscription,
    endpoint_admin_create_stripe_subscription,
    endpoint_get_subscription_by_userId,
    endpoint_delete_subscription_by_subscriptionId,
    endpoint_cancel_subscription,
    endpoint_get_subscription_from_stripe,
  };
})();
