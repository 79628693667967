import { all, call, put, takeLatest } from 'redux-saga/effects';
import { EBackupsUserListActionTypes } from '../../enums';
import { backupsUserListService } from '../../services/backup-user-list.service';
import { backupUserListAction } from '../actions';

export default function* root() {
  yield all([
    takeLatest(EBackupsUserListActionTypes.DOWNLOAD_BACKUP_USER_LIST_BY_LIST_ID as any, watchDownloadBackupUserList),
    takeLatest(EBackupsUserListActionTypes.GET_ALL_BACKUP_LIST_BY_USER_ID as any, watchGetBackupUserList),
  ]);
}

function* watchDownloadBackupUserList(action: { type: number; payload: number }) {
  try {
    const listId = action.payload;
    yield call(backupsUserListService.endpoint_download_backup_user_list_by_listId, listId);
  } catch (error: any) {
    console.error('watchDownloadBackups: ', error.response);
  }
}

function* watchGetBackupUserList(action: { type: number; payload: number }) {
  try {
    const userId = action.payload;
    const { data } = yield call(backupsUserListService.endpoint_get_list_backups_by_userId, userId);

    yield put(backupUserListAction.setAllUserListBackup(data as Array<any>));
  } catch (error: any) {
    console.error('watchGetListBackups: ', error.response);
  }
}
