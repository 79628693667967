import { all, call, put, takeLatest } from 'redux-saga/effects';

import { EStatesActionTypes } from '../../enums';
import { IRegion, IState } from '../../models';
import { statesService } from '../../services';
import { loadingAction, statesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EStatesActionTypes.GET_STATES as any, watchGetStates)]);
  yield all([takeLatest(EStatesActionTypes.GET_REGIONS as any, watchGetRegions)]);
}

function* watchGetStates() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: true,
      }),
    );

    const { data } = yield call(statesService.endpoint_get_states);

    yield put(statesAction.setStates(data as Array<IState>));

    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetStates: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: false,
      }),
    );
  }
}

function* watchGetRegions() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: true,
      }),
    );

    const { data } = yield call(statesService.endpoint_get_regions);

    yield put(statesAction.setRegions(data as Array<IRegion>));

    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetRegions: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getStatesLoading: false,
      }),
    );
  }
}
