import React from 'react';
import clsx from 'clsx';
import styles from './SubscriptionTypeSwitcher.module.css';
import checkmark from '../../../../images/checkmarkWhiteIcon.svg';

interface SubscriptionTypeSwitcherProps {
  typeSelected: string;
  onTypeSelect: (type: string) => void;
  subscriptionTypes: string[];
}

export const SubscriptionTypeSwitcher: React.FC<SubscriptionTypeSwitcherProps> = ({
  typeSelected,
  onTypeSelect,
  subscriptionTypes,
}) => {
  return (
    <div className={styles.container}>
      <h4 className={styles.title}>Choose a Plan:</h4>
      <div className={styles.buttons__Container}>
        {subscriptionTypes.map((type) => {
          return (
            <div
              onClick={() => onTypeSelect(type)}
              className={clsx(styles.button, typeSelected === type && styles.selected)}
              key={type}
            >
              {typeSelected === type && <img draggable={false} src={checkmark} alt="Checkmark" />}
              <p className={styles.text}>{type}</p>
            </div>
          );
        })}
      </div>
    </div>
  );
};
