import { produce } from 'immer';
import { createReducer } from './index';
import { IAction, ISelectedCollegesForSharing } from '../../models';
import { selectedCollegesForSharingState } from '../states/selected-colleges-for-sharing.state';
import { selectedCollegesForSharingTypes } from '../../consts';

export const selectedCollegesForSharingReducer = createReducer<ISelectedCollegesForSharing>(
  selectedCollegesForSharingState,
  {
    [selectedCollegesForSharingTypes.SET_SELECTED_COLLEGES_FOR_SHARING]: setSelectedCollegesForSharing,
  },
);

function setSelectedCollegesForSharing(state: ISelectedCollegesForSharing, payload: IAction<Array<any>>) {
  return produce(state, (draft) => {
    draft.selectedCollegesForSharing = payload.payload;
  });
}
