import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import styles from './MinMaxSelectCard.module.css';
import { Autocomplete } from '../../../components';
import { useClickOutside } from '../../../hooks/useClickOutside';

type SelectVariant = 'rangeSlider' | 'autocompleteButtons';

interface MinMaxSelectCardProps<T> {
  title: string | React.ReactNode;
  selectedValues: { min: number | string | null; max: number | string | null } | null;
  onMinSelectHandler: (value: { name: string } & T) => void;
  onMaxSelectHandler: (value: { name: string } & T) => void;
  values: { name: string; value: any }[];
  rangeMinValue: number;
  rangeMaxValue: number;
  rangeStep: number;
  rangeValues: number[];
  handleChangeRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  selectVariant: SelectVariant;
  valueSign: string | null;
  signBeforeValue?: boolean;
}

function valuetext(value: number) {
  return `${value}`;
}

export const MinMaxSelectCard: React.FC<MinMaxSelectCardProps<any>> = ({
  title,
  selectedValues,
  onMinSelectHandler,
  onMaxSelectHandler,
  values,
  rangeMinValue,
  rangeMaxValue,
  rangeStep,
  rangeValues,
  handleChangeRange,
  selectVariant,
  valueSign,
  signBeforeValue = false,
}) => {
  const [isSelectActiveMin, setIsSelectActiveMin] = useState<boolean>(false);
  const [isSelectActiveMax, setIsSelectActiveMax] = useState<boolean>(false);

  const minSelectRef = useRef<HTMLDivElement | null>(null);
  const maxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(minSelectRef, [], () => {
    setIsSelectActiveMin(false);
  });
  useClickOutside(maxSelectRef, [], () => {
    setIsSelectActiveMax(false);
  });

  return (
    <div className={styles.container}>
      <h5 className={styles.title}>{title}</h5>
      {selectVariant === 'autocompleteButtons' && (
        <div className={styles.buttonsContainer}>
          <div ref={minSelectRef} onClick={() => setIsSelectActiveMin(!isSelectActiveMin)} className={styles.button}>
            {!selectedValues?.min && selectedValues?.min !== 0
              ? 'Min'
              : values[values.findIndex((value) => value.value === selectedValues.min)]?.name ||
                `${(signBeforeValue && valueSign) || ''}${selectedValues.min.toLocaleString('en-US')}${
                  (!signBeforeValue && valueSign) || ''
                }`}
            <span className={styles.buttonArrowIcon} />
            {isSelectActiveMin && values && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={values}
                onclick={(value) => {
                  onMinSelectHandler(value);
                  setIsSelectActiveMin(false);
                }}
                classN={styles.autocomplete}
              />
            )}
          </div>
          <div ref={maxSelectRef} onClick={() => setIsSelectActiveMax(!isSelectActiveMax)} className={styles.button}>
            {!selectedValues?.max && selectedValues?.max !== 0
              ? 'Max'
              : values[values.findIndex((value) => value.value === selectedValues.max)]?.name ||
                `${(signBeforeValue && valueSign) || ''}${selectedValues.max.toLocaleString('en-US')}${
                  (!signBeforeValue && valueSign) || ''
                }`}
            <span className={styles.buttonArrowIcon} />
            {isSelectActiveMax && values && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={values}
                onclick={(value) => {
                  onMaxSelectHandler(value);
                  setIsSelectActiveMax(false);
                }}
                classN={styles.autocomplete}
              />
            )}
          </div>
        </div>
      )}
      {selectVariant === 'rangeSlider' && (
        <div className={styles.rangeWrapper}>
          <Slider
            sx={{
              width: '23rem',
              marginTop: '1rem',
              marginLeft: '1rem',
              marginRight: '1rem',
              color: '#7e106e',
              '& .MuiSlider-thumb': {
                background: 'white',
                border: '2px solid #7e106e',
              },
            }}
            getAriaLabel={() => 'Minimum distance'}
            step={rangeStep}
            min={rangeMinValue}
            max={rangeMaxValue}
            value={rangeValues}
            onChange={handleChangeRange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            valueLabelFormat={(value) =>
              `${signBeforeValue ? valueSign || '' : ''}${value.toLocaleString('en-US')}${
                signBeforeValue ? '' : valueSign || ''
              }`
            }
            disableSwap
          />
        </div>
      )}
    </div>
  );
};
