import { EBackupsActionTypes } from '../../enums';

const deleteBackupByName = (payload: string) => {
  return {
    type: EBackupsActionTypes.DELETE_BACKUP_BY_NAME,
    payload,
  };
};

const createBackup = () => {
  return {
    type: EBackupsActionTypes.CREATE_BACKUP,
  };
};

const downloadBackupByName = (payload: string) => {
  return {
    type: EBackupsActionTypes.DOWNLOAD_BACKUP_BY_NAME,
    payload,
  };
};

const restoreBackupByName = (payload: string) => {
  return {
    type: EBackupsActionTypes.RESTORE_BACKUP_BY_NAME,
    payload,
  };
};

export const backupAction = {
  deleteBackupByName,
  createBackup,
  downloadBackupByName,
  restoreBackupByName,
};
