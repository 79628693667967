import './App.css';
import { Provider } from 'react-redux';
import { BrowserRouter as Router } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { store } from './store';
import { RootNavigator } from './navigators';
import ScrollTop from './components/scroll-top-hook/Scroll-top';


function App() {
  const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_KEY}`);

  return (
    <Provider store={store.store}>
      <PersistGate loading={null} persistor={store.persistor}>
        <Router>
          <ScrollTop>
            <Elements stripe={stripePromise}>
              <div className="app">
                <RootNavigator />
              </div>
            </Elements>
          </ScrollTop>
        </Router>
      </PersistGate>
    </Provider>
  );
}

export default App;
