import { ENotesActionTypes } from '../../enums';
import { INote } from '../../models';

const getNoteById = (payload: number) => {
  return {
    type: ENotesActionTypes.GET_NOTE_BY_ID,
    payload,
  };
};

const getListNotes = (payload: number) => {
  return {
    type: ENotesActionTypes.GET_LIST_NOTES,
    payload,
  };
};

const setNote = (payload: INote) => {
  return {
    type: ENotesActionTypes.SET_NOTE,
    payload,
  };
};

const createNote = (payload: Partial<INote>) => {
  return {
    type: ENotesActionTypes.CREATE_NOTE,
    payload,
  };
};

const updateNote = (payload: Partial<INote>) => {
  return {
    type: ENotesActionTypes.UPDATE_NOTE,
    payload,
  };
};

const deleteNote = (payload: Partial<INote>) => {
  return {
    type: ENotesActionTypes.DELETE_NOTE,
    payload,
  };
};

const setListNotes = (payload: Array<INote>) => {
  return {
    type: ENotesActionTypes.SET_LIST_NOTES,
    payload,
  };
};

export const notesAction = {
  getListNotes,
  getNoteById,
  updateNote,
  createNote,
  deleteNote,
  setListNotes,
  setNote,
};
