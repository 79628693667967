import { useStripe } from '@stripe/react-stripe-js';
import { useState } from 'react';
import './StripeFormKlarna.css';
import { useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { subscriptionService } from '../../services';

const StripeFormKlarna = ({
  selectedPlan,
  couponCode,
  email,
  isValidEmail,
}: {
  selectedPlan: number;
  couponCode: string;
  email: string;
  isValidEmail: RegExp;
}) => {
  const stripe = useStripe();
  const { user } = useSelector((state: any) => state.userState);
  const { isAuthenticated } = useAuth0();
  const [emailInputValue, setEmailInputValue] = useState('');
  const [isLoaderKlarna, setIsLoaderKlarna] = useState(false);

  const onSubmit = async (e) => {
    e.preventDefault();
    if (!stripe || (!isAuthenticated && !email.match(isValidEmail))) return;

    async function fetchMyAPI() {
      setIsLoaderKlarna(true);
      await subscriptionService
        .endpoint_get_payment_intent(selectedPlan, user?.email || email, couponCode)
        .then(async (response) => {
          if (!stripe) return;
          await stripe.confirmKlarnaPayment(response.data.clientSecret, {
            payment_method: {
              billing_details: {
                email: isAuthenticated ? user.email : email,
                address: {
                  country: 'US',
                },
              },
            },
            return_url: `${process.env.REACT_APP_API_BASE_URL?.slice(0, -3)}${
              isAuthenticated ? '/home' : `/landing-page?login_email=${encodeURIComponent(email)}&payment_type=klarna`
            }`,
            // return_url: 'http://localhost:3000/home',
          });
        })
        .catch((error) => console.error('Get payment intent error: ', error))
        .finally(() => setIsLoaderKlarna(false));
    }
    fetchMyAPI();
  };

  return (
    <form id="payment-form-klarna" className="payment-form-klarna" onSubmit={onSubmit}>
      <label htmlFor="email">Email</label>
      <input
        type="email"
        id="email"
        placeholder="jenny.rosen@example.com"
        value={emailInputValue}
        onChange={(e) => setEmailInputValue(e.target.value)}
        required
        className="payment-form-klarna-input"
      />
      <button id="submit" className="payment-form-klarna-button">
        {isLoaderKlarna && <div id="loading-klarna-payment" />}
        {!isLoaderKlarna && <>Pay</>}
      </button>
    </form>
  );
};

export default StripeFormKlarna;
