import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import styles from './TestScoreFilter.module.css';
import sharedStyles from '../Shared.module.css';
import { Autocomplete } from '../../../../../autocomplete/Autocomplete';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';

type Score = { id: number; name: number; value: number };
type SelectVariant = 'rangeSlider' | 'autocompleteButtons';

const testScoreRadioValues: { id: number; name: string }[] = [
  { id: 1, name: 'SAT' },
  { id: 2, name: 'ACT' },
  { id: 3, name: 'Both' },
];

const satValues: Score[] = [
  { id: 0, name: 700, value: 700 },
  { id: 1, name: 800, value: 800 },
  { id: 2, name: 900, value: 900 },
  { id: 3, name: 1000, value: 1000 },
  { id: 4, name: 1100, value: 1100 },
  { id: 5, name: 1200, value: 1200 },
  { id: 6, name: 1300, value: 1300 },
  { id: 7, name: 1400, value: 1400 },
  { id: 8, name: 1500, value: 1500 },
  { id: 9, name: 1600, value: 1600 },
];

const actValues: Score[] = [
  { id: 0, name: 12, value: 12 },
  { id: 1, name: 14, value: 14 },
  { id: 2, name: 16, value: 16 },
  { id: 3, name: 18, value: 18 },
  { id: 4, name: 20, value: 20 },
  { id: 5, name: 22, value: 22 },
  { id: 6, name: 24, value: 24 },
  { id: 7, name: 26, value: 26 },
  { id: 8, name: 28, value: 28 },
  { id: 9, name: 30, value: 30 },
  { id: 10, name: 32, value: 32 },
  { id: 11, name: 34, value: 34 },
  { id: 12, name: 36, value: 36 },
];

const scoreFallTypes: { id: number; name: string; value: string }[] = [
  { id: 1, name: 'Average Range', value: 'avg' },
  { id: 2, name: 'Top Range (75th Percentile)', value: 'top75' },
  { id: 3, name: 'Data from Crowdsourced Offers', value: 'crowdsourcedColleges' },
];

function valuetext(value: number) {
  return `${value}`;
}

interface TestScoreFilterProps {
  selectedTestScore: string;
  changeSelectedTestScore: (event: React.MouseEvent<HTMLInputElement>) => void;
  selectedSatValues: { min: number | null; max: number | null } | null;
  selectedSatRangeValues: number[];
  selectSatMin: (score: Score) => void;
  selectSatMax: (score: Score) => void;
  handleChangeSelectedSatRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  selectedActValues: { min: number | null; max: number | null } | null;
  selectedActRangeValues: number[];
  selectActMin: (score: Score) => void;
  selectActMax: (score: Score) => void;
  handleChangeSelectedActRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  selectedFallType: string;
  changeSelectedFallType: (event: React.MouseEvent<HTMLInputElement>) => void;
  selectVariant: SelectVariant;
}

export const TestScoreFilter: React.FC<TestScoreFilterProps> = ({
  selectedTestScore,
  changeSelectedTestScore,
  selectedSatValues,
  selectedSatRangeValues,
  selectSatMin,
  selectSatMax,
  handleChangeSelectedSatRange,
  selectedActValues,
  selectedActRangeValues,
  selectActMin,
  selectActMax,
  handleChangeSelectedActRange,
  selectedFallType,
  changeSelectedFallType,
  selectVariant,
}) => {
  const [isSATMinSelectActive, setIsSATMinSelectActive] = useState<boolean>(false);
  const [isSATMaxSelectActive, setIsSATMaxSelectActive] = useState<boolean>(false);
  const [isACTMinSelectActive, setIsACTMinSelectActive] = useState<boolean>(false);
  const [isACTMaxSelectActive, setIsACTMaxSelectActive] = useState<boolean>(false);

  const SATMinSelectRef = useRef<HTMLDivElement | null>(null);
  const SATMaxSelectRef = useRef<HTMLDivElement | null>(null);
  const ACTMinSelectRef = useRef<HTMLDivElement | null>(null);
  const ACTMaxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(SATMinSelectRef, [], () => {
    if (isSATMinSelectActive) setIsSATMinSelectActive(false);
  });
  useClickOutside(SATMaxSelectRef, [], () => {
    if (isSATMaxSelectActive) setIsSATMaxSelectActive(false);
  });
  useClickOutside(ACTMinSelectRef, [], () => {
    if (isACTMinSelectActive) setIsACTMinSelectActive(false);
  });
  useClickOutside(ACTMaxSelectRef, [], () => {
    if (isACTMaxSelectActive) setIsACTMaxSelectActive(false);
  });

  return (
    <div className={sharedStyles.container}>
      <h5 className={sharedStyles.title}>Test Score(s)</h5>
      <div className={sharedStyles.radioInputsContainer}>
        {testScoreRadioValues.map((el) => {
          return (
            <label key={el.id} className={sharedStyles.radioInputWrapper}>
              <input
                type="radio"
                name="testScore"
                value={el.name}
                checked={selectedTestScore === el.name}
                onClick={changeSelectedTestScore}
                className={sharedStyles.radioInput}
                readOnly
              />
              {el.name}
            </label>
          );
        })}
      </div>
      {(selectedTestScore === 'SAT' || selectedTestScore === 'Both') && (
        <>
          <h5 className={sharedStyles.title}>SAT</h5>
          {selectVariant === 'autocompleteButtons' && (
            <div className={sharedStyles.buttonsContainer}>
              <div
                onClick={() => setIsSATMinSelectActive(!isSATMinSelectActive)}
                ref={SATMinSelectRef}
                className={sharedStyles.button}
              >
                {selectedSatValues?.min ? selectedSatValues.min : 'Min'}
                <span className={sharedStyles.buttonArrowIcon} />
                {isSATMinSelectActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    filteredItem={satValues}
                    onclick={(value: Score) => {
                      selectSatMin(value);
                      setIsSATMinSelectActive(false);
                    }}
                    classN={sharedStyles.autocomplete}
                  />
                )}
              </div>
              <div
                onClick={() => setIsSATMaxSelectActive(!isSATMaxSelectActive)}
                ref={SATMaxSelectRef}
                className={sharedStyles.button}
              >
                {selectedSatValues?.max ? selectedSatValues.max : 'Max'}
                <span className={sharedStyles.buttonArrowIcon} />
                {isSATMaxSelectActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    filteredItem={satValues}
                    onclick={(value: Score) => {
                      selectSatMax(value);
                      setIsSATMaxSelectActive(false);
                    }}
                    classN={sharedStyles.autocomplete}
                  />
                )}
              </div>
            </div>
          )}
          {selectVariant === 'rangeSlider' && (
            <Slider
              sx={{
                marginTop: '1rem',
                color: '#7e106e',
                '& .MuiSlider-thumb': {
                  background: 'white',
                  border: '2px solid #7e106e',
                },
              }}
              getAriaLabel={() => 'Minimum distance'}
              step={100}
              min={satValues[0].name}
              max={satValues[9].name}
              value={selectedSatRangeValues}
              onChange={handleChangeSelectedSatRange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              disableSwap
            />
          )}
        </>
      )}
      {(selectedTestScore === 'ACT' || selectedTestScore === 'Both') && (
        <>
          <h5 className={sharedStyles.title}>ACT</h5>
          {selectVariant === 'autocompleteButtons' && (
            <div className={sharedStyles.buttonsContainer}>
              <div
                onClick={() => setIsACTMinSelectActive(!isACTMinSelectActive)}
                ref={ACTMinSelectRef}
                className={sharedStyles.button}
              >
                {selectedActValues?.min ? selectedActValues.min : 'Min'}
                <span className={sharedStyles.buttonArrowIcon} />
                {isACTMinSelectActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    filteredItem={actValues}
                    onclick={(value: Score) => {
                      selectActMin(value);
                      setIsACTMinSelectActive(false);
                    }}
                    classN={sharedStyles.autocomplete}
                  />
                )}
              </div>
              <div
                onClick={() => setIsACTMaxSelectActive(!isACTMaxSelectActive)}
                ref={ACTMaxSelectRef}
                className={sharedStyles.button}
              >
                {selectedActValues?.max ? selectedActValues.max : 'Max'}
                <span className={sharedStyles.buttonArrowIcon} />
                {isACTMaxSelectActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    filteredItem={actValues}
                    onclick={(value: Score) => {
                      selectActMax(value);
                      setIsACTMaxSelectActive(false);
                    }}
                    classN={sharedStyles.autocomplete}
                  />
                )}
              </div>
            </div>
          )}
          {selectVariant === 'rangeSlider' && (
            <Slider
              sx={{
                marginTop: '1rem',
                color: '#7e106e',
                '& .MuiSlider-thumb': {
                  background: 'white',
                  border: '2px solid #7e106e',
                },
              }}
              getAriaLabel={() => 'Minimum distance'}
              step={2}
              min={actValues[0].name}
              max={actValues[12].name}
              value={selectedActRangeValues}
              onChange={handleChangeSelectedActRange}
              valueLabelDisplay="on"
              getAriaValueText={valuetext}
              disableSwap
            />
          )}
        </>
      )}
      {selectedTestScore && (
        <>
          <h6 className={styles.subtitle}>Filter by:</h6>
          {scoreFallTypes.map((el) => {
            return (
              <label key={el.id} className={sharedStyles.radioInputWrapper}>
                <input
                  type="radio"
                  name="testFall"
                  value={el.value}
                  checked={selectedFallType === el.value}
                  onClick={changeSelectedFallType}
                  className={sharedStyles.radioInput}
                  readOnly
                />
                {el.name}
              </label>
            );
          })}
        </>
      )}
    </div>
  );
};
