import { ICollegesState } from '../../models';

export const collegesState: ICollegesState = {
  colleges: [],
  trendingColleges: [],
  collegesNames: [],
  selectedCollege: undefined,
  advancedColleges: [],
  recommendedColleges: [],
  updatedRecommendedColleges: [],
};
