import * as React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { CreateProfile, Footer, Header, MarketingPage, SharedList } from '../components';

export const ProfileUncompletedNavigator = () => {
  const { isSharedList, idSharedList } = useSelector((state: any) => state.myListsState);

  return (
    <>
      <Header />
      <Routes>
        <Route path="/marketing-page" element={<MarketingPage />} />
        <Route path="/create-profile" element={<CreateProfile />} />
        <Route path="/*" element={<Navigate replace to="/create-profile" />} />
        {idSharedList && isSharedList && <Route path={`/shared-lists/${idSharedList}`} element={<SharedList />} />}
      </Routes>
      <Footer />
    </>
  );
};
