/* eslint-disable */
import './Header-admin.css';
import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import localforage from 'localforage';
import { Nav } from '../header/Nav';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';

import profile from '../../images/profile-icon.svg';
import dashboard from '../../images/dashboard.svg';
import adminProfileMobile from '../../images/admin-profile-mobile.svg';
import road2college from '../../images/icons/r2c-logo.svg';
import { HeaderMobile } from '../header/HeaderMobile';

export const HeaderAdmin = () => {
  const mobileMenu = useRef(null);
  const mobileBtn = useRef(null);
  const menuRef = useRef(null);
  const submenuRef = useRef(null);
  const profileBtn = useRef(null);

  //Dropwdowns
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const toggleServices = () => setServicesDropdownOpen((prevState) => !prevState);
  const [myInsightsDropdownOpen, setMyInsightsDropdownOpen] = useState(false);
  const toggleMyInsights = () => setMyInsightsDropdownOpen((prevState) => !prevState);
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { logout, isAuthenticated } = useAuth0();

  const blockedClick = () => {
    return (
      previousRoute.payload === '/home-page-after-require' ||
      previousRoute.payload === '/require-pay' ||
      previousRoute.payload === '/upgrade' ||
      (previousRoute.payload === '/shared-list-after-require-pay' && window.location.pathname === '/buy-plan')
    );
  };

  //Scroll function
  const scrollToSection = (section_id) => {
    const section = document.getElementById(section_id);
    section ? section.scrollIntoView({ behavior: 'smooth' }) : '';
  };

  return (
    <nav>
      <Nav />
      <header className="header-container">
        <div className="header header-admin">
          <div className="first-block-header">
            <img className="road2college" src={road2college} alt="Road2College Icon" />
            <NavLink to={'/home'} className="college-insights">
              Insights
            </NavLink>
            <>
              <div className="header-items">
                <div
                  className={`header-item-container ${
                    window.location.pathname.split('/')[1] == 'my-lists' ? 'active' : ''
                  }`}
                >
                  <NavLink
                    className="header-item-link text-black"
                    to={blockedClick() ? `${window.location.pathname}` : '/my-lists'}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      if (blockedClick()) {
                        return;
                      }
                    }}
                  >
                    My Lists
                  </NavLink>
                </div>

                <div
                  className={`header-item-container ${window.location.pathname == '/home' ? 'active' : ''}`}
                  onMouseOver={() => setMyInsightsDropdownOpen(true)}
                  onMouseLeave={() => setMyInsightsDropdownOpen(false)}
                  onFocus={() => setMyInsightsDropdownOpen(true)}
                  onBlur={() => setMyInsightsDropdownOpen(false)}
                >
                  <Dropdown
                    isOpen={myInsightsDropdownOpen}
                    toggle={toggleMyInsights}
                    direction="down"
                    className="dropdown-custom-header dropdown-bg-none text-black p-0"
                  >
                    <DropdownToggle caret>Insights</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavLink
                          to={'/home?calendar=true'}
                          className="text-black"
                          onClick={() => {
                            setTimeout(() => {
                              scrollToSection('recommendations');
                            }, 150);
                          }}
                        >
                          Recommendations
                        </NavLink>
                      </DropdownItem>
                      <DropdownItem>
                        <NavLink
                          to={'/home?calendar=true'}
                          className="text-black"
                          onClick={() => {
                            setTimeout(() => {
                              scrollToSection('calendar');
                            }, 150);
                          }}
                        >
                          Yearly Calendar
                        </NavLink>
                      </DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div
                  className={`header-item-container ${window.location.pathname == '/services' ? 'active' : ''}`}
                  onMouseOver={() => setServicesDropdownOpen(true)}
                  onFocus={() => setServicesDropdownOpen(true)}
                  onMouseLeave={() => setServicesDropdownOpen(false)}
                  onBlur={() => setServicesDropdownOpen(false)}
                >
                  <Dropdown
                    isOpen={servicesDropdownOpen}
                    direction="down"
                    toggle={() => setServicesDropdownOpen(!servicesDropdownOpen)}
                    className="dropdown-custom-header dropdown-bg-none text-black p-0"
                  >
                    <DropdownToggle caret>Services</DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem href="/services">1:1 Services</DropdownItem>
                      <DropdownItem href="/buy-plan">Membership Plan</DropdownItem>
                    </DropdownMenu>
                  </Dropdown>
                </div>

                <div className={`header-item-container ${window.location.pathname == '/power-search' ? 'active' : ''}`}>
                  <NavLink to={'/power-search'} className="header-item-link text-black">
                    Power Search
                  </NavLink>
                </div>

                <div className="header-item-container">
                  <NavLink
                    to={'/home'}
                    onClick={() => {
                      setTimeout(() => {
                        scrollToSection('scoresmart');
                      }, 150);
                    }}
                    className="header-item-link text-black"
                  >
                    Free Practice Digital SAT
                  </NavLink>
                </div>
              </div>
            </>
          </div>

          <HeaderMobile />
        </div>
      </header>
    </nav>
  );
};
