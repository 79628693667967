import axios from 'axios';
import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const usersService = (function () {
  const endpoint_get_users = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_users);
  };

  const endpoint_get_all_users = async (
    limit: number,
    skip: number,
    sortBy: string,
    searchValue: string,
    descOrAsc: string,
    typeOfSubscription: string,
    subscriptionStatus: string,
  ) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(
      apiUrls.get_all_users(limit, skip, sortBy, searchValue, descOrAsc, typeOfSubscription, subscriptionStatus),
    );
  };

  const endpoint_get_count_all_users = async (
    sortBy: string,
    searchValue: string,
    descOrAsc: string,
    typeOfSubscription: string,
    subscriptionStatus: string,
  ) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(
      apiUrls.get_count_all_users(sortBy, searchValue, descOrAsc, typeOfSubscription, subscriptionStatus),
    );
  };

  const endpoint_download_users = async (
    usersProfile: string,
    searchValue: string,
    typeOfSubscription: string,
    subscriptionStatus: string,
  ) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.download_users(usersProfile, searchValue, typeOfSubscription, subscriptionStatus));
  };

  const endpoint_upload_csvs = async (type: string, file: File) => {
    const data = new FormData();
    data.append('file', file);
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.post(apiUrls.uploadCsvs(type), data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const endpoint_get_user_auth0 = async (email: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(`${apiUrls.get_user_auth0}?email=${email}`);
  };

  const endpoint_send_pass_reset_email = async (email: string) => {
    const resp = await axios.post(`https://${process.env.REACT_APP_AUTH0_DOMAIN}/dbconnections/change_password`, {
      client_id: process.env.REACT_APP_AUTH0_API_ID,
      email,
      connection: 'Username-Password-Authentication',
    });
    return resp;
  };

  return {
    endpoint_get_users,
    endpoint_get_all_users,
    endpoint_get_count_all_users,
    endpoint_upload_csvs,
    endpoint_get_user_auth0,
    endpoint_download_users,
    endpoint_send_pass_reset_email,
  };
})();
