import style from './mobileSelect.module.css';

interface Props {
  options: { title: string; value: string }[];
  selected: string;
  setSelected: Function;
}

export const MobileSelect = ({ options, selected, setSelected }: Props) => {
  const handleChange = (value: string) => {
    setSelected(value);
  };
  return (
    <div className={style.mobile_select} style={selected === 'profile' ? { marginBottom: 10 } : { marginBottom: 0 }}>
      <select className={style.custom_select} onChange={(e) => handleChange(e.target.value)} value={selected}>
        {options.map((option: { title: string; value: string }) => (
          <option key={option.value} value={option.value}>
            {option.title}
          </option>
        ))}
      </select>
    </div>
  );
};
