import { useState } from 'react';
import { useSelector } from 'react-redux';
import { DebounceInput } from 'react-debounce-input';
import arrowBack from '../../images/arrow-back-pink.svg';
import cross from '../../images/cross.svg';
import './EmailModal.css';
import { emailService } from '../../services/email.service';
import { IEmailBody } from '../social-medias/SocialMedias';

interface IEmailForm {
  value: string;
  id: number;
}

type Props = {
  close: () => void;
  onHandleOpenModalAfterSendEmail: () => void;
  openSocialMediaModal: () => void;
  onHandleClickShareList: () => Promise<void>;
};

export const EmailModal = ({
  close,
  onHandleOpenModalAfterSendEmail,
  openSocialMediaModal,
  onHandleClickShareList,
}: Props) => {
  const { selectedList } = useSelector((state: any) => state.myListsState);
  const { user } = useSelector((state: any) => state.userState);
  const [selectedEmails, setSelectedEmails] = useState<Array<IEmailForm>>([]);
  const [selectedEmail, setSelectedEmail] = useState<IEmailForm>();
  const [valueInput, setValueInput] = useState('');

  const handleChangeInput = (event: { target: { value: string } }): void => {
    const { value } = event.target;
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

    if (value.length > 0 && emailPattern.test(value)) {
      if (selectedEmails.length === 1) {
        setSelectedEmail({ value, id: 0 });
      } else if (selectedEmails.length === 2) {
        setSelectedEmail({ value, id: 1 });
      } else {
        setSelectedEmail({ value, id: selectedEmails.length - 1 });
      }
      setValueInput(value);
    }
  };

  const handleDeleteEmail = (emailId: number): void => {
    const filteredEmails: IEmailForm[] = selectedEmails.filter((email: IEmailForm) => email.id !== emailId);
    setSelectedEmails(filteredEmails);
  };

  const handleAdd = (e): void => {
    e.preventDefault();
    if (selectedEmail) {
      const includeInSelectedEmails: boolean = selectedEmails.some((email) => email.value === selectedEmail.value);
      if (includeInSelectedEmails) return;
      setSelectedEmails([...selectedEmails, selectedEmail]);
    }
    setValueInput('');
  };

  const handleSubmitSendEmail = async (e) => {
    e.preventDefault();
    if (selectedEmails.length === 0) return;
    const emails = selectedEmails.map((email) => email.value);
    const to = emails.join(',');
    const listLink = `${window.location.href.replace('my-lists', 'shared-lists')}`;
    const listName = selectedList.name || 'My College List';
    const originLink = `${window.location.origin}`;

    if (!user) return;

    const body: IEmailBody = {
      to,
      listLink,
      listName,
      originLink,
      userName: user.name,
      userEmail: user.email,
    };

    const response = await emailService.endpoint_send_email(body);
    if (response.data.length > 0) {
      close();
      onHandleOpenModalAfterSendEmail();
      onHandleClickShareList();
    }
  };

  return (
    <section className="email-modal">
      <div className="email-modal__cont-top">
        <img
          className="email-modal__arrow"
          src={arrowBack}
          alt="arrow back"
          onClick={() => {
            close();
            openSocialMediaModal();
          }}
        />
        <h4 className="email-modal__title">{selectedList ? `Share ${selectedList.name}` : 'Share My List'}</h4>
      </div>
      <form className="email-modal__multi-select" onSubmit={handleSubmitSendEmail}>
        <div className="email-modal__multi-select__form">
          {selectedEmails.length > 0 && (
            <div className="email-modal__multi-select__selected-emails">
              {selectedEmails.map((email: IEmailForm) => (
                <div className="email-modal__multi-select__selected-email" key={email.id}>
                  <p className="email-modal__multi-select__selected-email__text">{email.value}</p>
                  <img
                    className="email-modal__multi-select__selected-email__img"
                    src={cross}
                    alt="cross"
                    onClick={() => handleDeleteEmail(email.id)}
                  />
                </div>
              ))}
            </div>
          )}
          <DebounceInput
            className="email-modal__multi-select__input"
            value={valueInput}
            onChange={handleChangeInput}
            type="text"
            debounceTimeout={500}
            placeholder="Add people"
          />
        </div>
        <div className="email-modal__multi-select__cont-btn">
          <button className="email-modal__btn add" onClick={handleAdd}>
            Add
          </button>
          <button className="email-modal__btn share" type="submit">
            Share
          </button>
        </div>
      </form>
    </section>
  );
};
