import React from 'react';
import sharedStyles from '../Shared.module.css';
import styles from './ApplicationDeadlineFilter.module.css';
import { applicationDeadlines } from '../../../../PowerSearch';
import { IPowerSearchFilterOption } from '../../../../../../models';

interface ApplicationDeadlineFilterProps {
  checkboxesSelected: IPowerSearchFilterOption<string>[];
  changeCheckboxesSelected: (value: IPowerSearchFilterOption<string>) => void;
}

export const ApplicationDeadlineFilter: React.FC<ApplicationDeadlineFilterProps> = ({
  checkboxesSelected,
  changeCheckboxesSelected,
}) => {
  return (
    <div className={sharedStyles.container}>
      <h5 className={sharedStyles.title}>Application Deadline</h5>
      <div className={sharedStyles.checkboxesContainer}>
        {applicationDeadlines.map((el) => {
          return (
            <div key={el.id} className={styles.checkboxContainer}>
              <input
                type="checkbox"
                value={el.value}
                checked={checkboxesSelected.some((element) => el.id === element.id)}
                onChange={() => changeCheckboxesSelected(el)}
                name="applicationDeadline"
                id={el.name}
              />
              <label className={sharedStyles.checkboxLabel} htmlFor={el.name}>
                {el.name}
              </label>
            </div>
          );
        })}
      </div>
    </div>
  );
};
