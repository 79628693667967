import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';

export const backupsUserListService = (function () {
  const endpoint_download_backup_user_list_by_listId = async (listId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.download_backup_user_list_by_listId(listId));
  };

  const endpoint_get_list_backups_by_userId = async (userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_all_list_backups_by_userId(userId));
  };

  return {
    endpoint_download_backup_user_list_by_listId,
    endpoint_get_list_backups_by_userId,
  };
})();
