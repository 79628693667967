import './UniversalModal.css';
import { NavLink } from 'react-router-dom';
import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { StripeCardNumberElement } from '@stripe/stripe-js';
import { useDispatch, useSelector } from 'react-redux';
import { useAuth0 } from '@auth0/auth0-react';
import { loadingAction, userAction } from '../../store/actions';
import { subscriptionService } from '../../services/subscription.service';
import cross from '../../images/cross.svg';

export const UniversalModal = ({
  close,
  open,
  textForTitle,
  textForDescription,
  textFirstBtn,
  textSecondBtn,
  withClose = true,
  couponCodeNew,
  firstname,
  lastname,
  useOldCard,
  packageId,
  setIsLoading,
  setIsError,
  setIsAnnualSuccess,
  isAnnualSuccess,
}: {
  close: () => void;
  textForTitle?: string;
  textForDescription?: string;
  textFirstBtn?: string;
  textSecondBtn?: string;
  withClose?: boolean;
  couponCodeNew?: string | null;
  firstname?: string;
  lastname?: string;
  useOldCard?: any;
  packageId?: number;
  setIsLoading?: any;
  setIsError?: any;
  open?: any;
  setIsAnnualSuccess?: any;
  isAnnualSuccess?: boolean;
}) => {
  const usersPaymentMethod = useSelector((state: any) => state.subscriptionState.subscription);
  const { user } = useSelector((state: any) => state.userState);
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const clickFirstBtn = (e) => {
    e.stopPropagation();
    close();
  };
  
  const clickSecondBtn = async (e) => {
    e.stopPropagation();
    close();

    const newUser = user;

    if (window.location.pathname.includes('/my-lists')) {
      if (setIsLoading) {
        setIsLoading(true);
      }
      close();
      return;
    }

    if (newUser?.subscription && newUser.subscription.packageId % 2 === 1 && packageId && packageId % 2 === 0) return;
    if (setIsLoading) {
      setIsLoading(true);
    }
    if (packageId && packageId % 2 === 1 && isAnnualSuccess && isAuthenticated) {
      const body = {
        email: newUser.email,
        hadSubscription: true,
        canceledSubscription: false,
        countDownloadCsv: 1,
      };
      dispatch(userAction.updateUser({ user: body }));

      dispatch(userAction.getUser({ email: newUser.email }));
      if (setIsLoading) {
        setIsLoading(false);
      }
      dispatch(
        loadingAction.updateLoadingStatus({
          getUserLoading: false,
        }),
      );
      return;
    }

    let data;

    const fullname = `${firstname} ${lastname}`;

    if (newUser?.subscription) {
      if (setIsLoading) {
        setIsLoading(true);
      }
      let payload;

      if (!stripe || !elements) return;

      if (useOldCard) {
        payload = usersPaymentMethod;
      } else {
        payload = await stripe.createPaymentMethod({
          type: 'card',
          card: elements.getElement(CardNumberElement) as StripeCardNumberElement,
          billing_details: {
            name: newUser?.name,
            email: newUser.email,
          },
        });
      }
      data = await subscriptionService
        .endpoint_update_subscription(newUser.subscription.id, {
          email: newUser.email,
          paymentMethodId: payload.paymentMethod?.id || payload.id,
          packageId,
          couponCode: couponCodeNew,
          timeSubscription: packageId && packageId % 2 === 0 ? 'month' : 'year',
          fullname,
        })
        .catch(() => {
          if (setIsError) {
            setIsError(true);
          }
          if (setIsLoading) {
            setIsLoading(false);
          }
          if (open) {
            open();
          }
        });
      if (data) {
        dispatch(userAction.getUser({ email: newUser.email }));
        if (setIsLoading) {
          setIsLoading(false);
        }
        dispatch(
          loadingAction.updateLoadingStatus({
            getUserLoading: false,
          }),
        );
        if (setIsAnnualSuccess) {
          setIsAnnualSuccess(true);
        }
        if (open) {
          open();
        }
      }
    }
  };

  const closeModal = (e) => {
    e.stopPropagation();
    close();
  };

  return (
    <div className="modal-advanced-search-universal">
      {withClose && (
        <img onClick={closeModal} src={cross} alt="cross" className="modal-advanced-search-universal-img" />
      )}
      <h5 className={textForTitle?.includes('Looking for') ? 'modal-advanced-search-universal-title-looking' : ''}>
        {textForTitle}
      </h5>
      {textForDescription && <p>{textForDescription}</p>}
      <div className="modal-advanced-search-universal-body" />
      <div>
        <NavLink
          to={textFirstBtn === 'Learn More' ? '/marketing-page' : '/buy-plan'}
          type="button"
          className={
            textFirstBtn
              ? 'modal-advanced-search-universal-cancel-button'
              : 'modal-advanced-search-universal-cancel-button-hidden'
          }
          onClick={clickFirstBtn}
        >
          {textFirstBtn}
        </NavLink>
        <NavLink
          to={
            isAnnualSuccess? '/home': textSecondBtn === 'Upgrade' || textSecondBtn === 'Upgrade Now'? 'https://www.road2college.com/updated-membership-plans/'
              : textForDescription ===
                'This feature is only available for annual subscribers. Upgrade now to download one list of up to 20 schools and up to 10 unique categories.'
              ? '/upgrade'
              : textSecondBtn === 'Ok' && window.location.pathname.includes('/my-lists')
              ? '/my-lists'
              : ''
          }
          type="button"
          className="modal-advanced-search-universal-button"
          onClick={clickSecondBtn}
        >
          {textSecondBtn}
        </NavLink>
      </div>
    </div>
  );
};
