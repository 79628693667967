import { produce } from 'immer';
import { createReducer } from '.';
import { switcherBlockMyListTypes } from '../../consts';
import { IAction, ISwitcherBlocksMyList } from '../../models';
import { switcherBlocksMyListState } from '../states/switcher-blocks-my-list.state';

export const switcherBlockMyListReducer = createReducer<ISwitcherBlocksMyList>(switcherBlocksMyListState, {
  [switcherBlockMyListTypes.SET_PROFILE]: setProfile,
  [switcherBlockMyListTypes.SET_ADMISSIONS]: setAdmissions,
  [switcherBlockMyListTypes.SET_SCHOLASHIPS]: setScholarships,
  [switcherBlockMyListTypes.SET_FINANCE]: setFinance,
});

function setProfile(state: ISwitcherBlocksMyList, { payload }: IAction<boolean>) {
  return produce(state, (draft) => {
    draft.profile = payload;
  });
}

function setAdmissions(state: ISwitcherBlocksMyList, { payload }: IAction<boolean>) {
  return produce(state, (draft) => {
    draft.admissions = payload;
  });
}

function setScholarships(state: ISwitcherBlocksMyList, { payload }: IAction<boolean>) {
  return produce(state, (draft) => {
    draft.scholaships = payload;
  });
}

function setFinance(state: ISwitcherBlocksMyList, { payload }: IAction<boolean>) {
  return produce(state, (draft) => {
    draft.finance = payload;
  });
}
