import './Delete-all-colleges-from-list-modal.css';
import cross from '../../images/cross.svg';

export const DeleteAllCollegesFromListModal = ({
  close,
  deleteAll,
  listName,
}: {
  close: any;
  deleteAll: any;
  listName: string;
}) => {
  return (
    <div className="modal-sign-up">
      <img onClick={close} src={cross} alt="cross" />
      <h1> Confirm Delete All Colleges From List</h1>
      <p>Are you sure you want to delete all colleges from {listName}</p>
      <div>
        <button type="button" className="cancle-button" onClick={close}>
          Cancel
        </button>
        <button
          type="button"
          className="sign-up-modal-button"
          onClick={() => {
            close();
            deleteAll();
          }}
        >
          Delete all
        </button>
      </div>
    </div>
  );
};
