import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams, useSearchParams, NavLink } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import { useAuth0 } from '@auth0/auth0-react';
import style from './CollegeDetails.module.css';
import arrowBack from '../../images/arrow-back-pink.svg';
import collegeIcon from '../../images/default-college.png';
import videoIcon from '../../images/video-icon.svg';
import saveIcon from '../../images/save_university.svg';
import unsaveIcon from '../../images/unsave-icon.svg';
import { collegesAction, myListsAction, offersAction, previousRouteAction } from '../../store/actions';
import { AddListModal, AddNotesModal, Autocomplete, SignUpModal, OffersModal } from '..';
import { IMyList } from '../../models';
import { myListService } from '../../services';
import { CollegeDetailsProfile } from '../college-details-profile/CollegeDetailsProfile';
import { CollegeDetailsAdmissions } from '../college-details-admissions/CollegeDetailsAdmissions';
import { CollegeDetailsFinance } from '../college-details-finance/CollegeDetailsFinance';
import { CollegeDetailsSchoolarships } from '../college-details-schoolarships/CollegeDetailsSchoolarships';
import { MobileSelect } from '../mobile-select/mobileSelect';
import locked from '../../images/locked-icon-white.svg';
import lockedGrey from '../../images/locked-icon-grey.svg';
import notesIcon from '../../images/notes-icon.svg';
import selectedNotes from '../../images/notes-icon-selected.svg';
import { YoutubeModal } from '../youtube-modal/YoutubeModal';
import { UniversalModal } from '../universal-modal/UniversalModal';

const tabs = [
  { title: 'School Profile', value: 'profile' },
  { title: 'Admissions', value: 'admissions' },
  { title: 'Aid/ Merit Scholarships', value: 'schoolarships' },
  { title: 'Financial Information', value: 'finance' },
];

export const CollegeDetails = () => {
  const { isAuthenticated } = useAuth0();
  const { selectedCollege } = useSelector((state: any) => state.collegesState);
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { myLists } = useSelector((state: any) => state.myListsState);
  const { offers } = useSelector((state: any) => state.offersState);

  const [isMyListsSelectActive, setIsMyListsSelectActive] = useState(false);
  const [lists, setLists] = useState(myLists);
  const [isInList, setIsInList] = useState(false);

  const [selectedTab, setSelectedTab] = useState('profile');
  const [showOffers, setShowOffers] = useState(false);
  const [showMarks, setShowMarks] = useState(false);

  const [isYoutube, setIsYoutube] = useState(false);

  const [prevRoute, setPrevRoute] = useState(null);

  const [isAddNotes, setIsAddNotes] = useState(false);
  const [clickedLockedData, setClickedLockedData] = useState('');
  const [allowedCreateList, setAllowedCreateList] = useState(true);

  const { id } = useParams();
  const dispatch = useDispatch();
  const { state } = useLocation();

  const childRef = useRef<any>();

  const [searchParams, setSearchParams] = useSearchParams();

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  const convertToEmbedLink = (youtubeLink) => {
    let videoId = youtubeLink.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');

    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }

    return `https://www.youtube.com/embed/${videoId}`;
  };

  const closeYoutube = () => {
    setIsYoutube(false);
    close();
  };

  useEffect(() => {
    setAllowedCreateList(true);
  }, []);

  useEffect(() => {
    dispatch(previousRouteAction.setPreviousRoute('/college-details'));
  }, []);

  useEffect(() => {
    setPrevRoute(state);
  }, []);

  useEffect(() => {
    if (isYoutube) open();
  }, [isYoutube]);

  useEffect(() => {
    if (selectedCollege) {
      document.title = `Insights | ${selectedCollege.name}`;
      setSearchParams({ college: `${selectedCollege.name}` });
    }
    return () => {
      const deletedSearchParams = searchParams.get('college');
      if (deletedSearchParams) {
        searchParams.delete(deletedSearchParams);
      }
    };
  }, [selectedCollege]);

  useEffect(() => {
    setLists([{ id: -1, name: 'Add New List' }, ...myLists]);
    setIsInList(myLists.find((l: IMyList) => l.colleges.find((c: any) => id && c.collegeId === +id)));
  }, [myLists]);

  useEffect(() => {
    if (id) {
      dispatch(
        collegesAction.getFullCollegeById({
          collegeId: +id,
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
        }),
      );
    }
  }, [id]);

  useEffect(() => {
    if (showOffers) {
      open();
    }
  }, [showOffers]);

  const changeSection = (e: any, id: string) => {
    e.preventDefault();
    const prevNav = document.getElementsByClassName(style.selected_nav)[0];
    const nav = document.getElementById(id);
    prevNav.classList.remove(style.selected_nav);
    prevNav.classList.add(style.college_details_navigations_h6);
    nav?.classList.add(style.selected_nav);
    setSelectedTab(id);
  };

  const selectListMyList = (list: IMyList) => {
    if (list.id === -1 && !user.subscription && user.role !== 'admin' && myLists.length >= 1) {
      open();
      setAllowedCreateList(false);
      return;
    }
    setAllowedCreateList(true);
    setIsMyListsSelectActive(false);
    if (list.id === -1) {
      open();
    } else {
      dispatch(
        myListsAction.addCollegesToMyList({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
          collegesIds: [selectedCollege.id],
          listName: list.name,
        }),
      );
      setIsInList(true);
      dispatch(myListsAction.getMyLists(adminNavigatedUser ? adminNavigatedUser.id : user.id));
    }
    setIsMyListsSelectActive(false);
  };

  const createList = async (listName: string) => {
    await myListService.endpoint_create_my_list({
      userId: adminNavigatedUser ? adminNavigatedUser.id : user.id,
      listName,
    });
    const { data } = await myListService.endpoint_get_my_lists(adminNavigatedUser ? adminNavigatedUser.id : user.id);
    dispatch(myListsAction.setMyLists(data));
    selectListMyList(data[data.length - 1]);
    close();
  };

  const clickUnlockModal = (value: string): void => {
    if (!value) {
      setClickedLockedData('');
    }
    setClickedLockedData(value);
  };

  return (
    <div onClick={() => setIsMyListsSelectActive(false)}>
      <NavLink to={prevRoute || '/home'} className={style.return_back}>
        <img src={arrowBack} alt="arrow back" />
        <p className="subtitle2">Back</p>
      </NavLink>
      <div className={style.college_details_cont}>
        <div className={style.college_details_wrapper}>
          <div className={style.right_main_block}>
            <header className={style.college_header_mobile}>
              <h3>{selectedCollege?.name}</h3>
              <div className={style.college_header_mobile_container}>
                <div
                  className={`${style.image_block} ${
                    !user?.subscription && user.role !== 'admin' ? style.locked_note : ''
                  }`}
                >
                  <img
                    onClick={(e) => {
                      if (!isAuthenticated) {
                        open();
                      } else {
                        e.stopPropagation();
                        setIsMyListsSelectActive(!isMyListsSelectActive);
                        setIsAddNotes(false);
                        setShowOffers(false);
                      }
                    }}
                    src={isInList ? unsaveIcon : saveIcon}
                    alt="save/unsave icon"
                  />
                  {adminNavigatedUser && !adminNavigatedUser?.subscription ? (
                    <img src={lockedGrey} className={style.image_lock_icon} alt="lock icon" />
                  ) : (
                    !user?.subscription &&
                    !adminNavigatedUser &&
                    user.role !== 'admin' && <img src={lockedGrey} className={style.image_lock_icon} alt="lock icon" />
                  )}

                  <img
                    onClick={() => {
                      if (adminNavigatedUser && adminNavigatedUser?.subscription) {
                        setIsAddNotes(!isAddNotes);
                        setShowOffers(false);
                        setIsMyListsSelectActive(false);
                        open();
                        return;
                      }

                      if (user && (user?.subscription || user.role === 'admin')) {
                        setIsAddNotes(!isAddNotes);
                        setShowOffers(false);
                        setIsMyListsSelectActive(false);
                        open();
                      } else {
                        setClickedLockedData('notes');
                        open();
                      }
                    }}
                    src={selectedCollege?.notes.length ? selectedNotes : notesIcon}
                    alt="notes"
                  />
                </div>
              </div>
              {isMyListsSelectActive && lists && (
                <Autocomplete
                  isBudgetItems={false}
                  classN={'college_details'}
                  ref={childRef}
                  onclick={selectListMyList}
                  filteredItem={lists}
                />
              )}
            </header>
            <h4 className={style.college_name_mobile}>{selectedCollege?.state?.name}</h4>
          </div>

          <div className={style.main_college_block}>
            <div className={style.college_photo}>
              <img
                className={style.photo}
                loading="lazy"
                src={
                  selectedCollege?.hasImage
                    ? `${process.env.REACT_APP_COLLEGE_IMAGES}${selectedCollege?.ipeds}.png`
                    : collegeIcon
                }
                alt="college"
              />
              <div className={style.youtube_video_container}>
                <a
                  target="_blank"
                  href={selectedCollege?.urlVirtualTour}
                  rel="noreferrer"
                  onClick={(e) => {
                    e.preventDefault();
                    setIsYoutube(true);
                    open();
                  }}
                >
                  <img className={style.video_icon} src={videoIcon} alt="video icon" />
                </a>
              </div>
            </div>

            <div className={style.right_main_block_info}>
              <div className={style.right_main_block_res}>
                <h4 className={style.college_name}>{selectedCollege?.state?.name}</h4>
                <h4>Public/Private College</h4>
                <h4>Full Time Undergraduates</h4>
                <h4>Student: Faculty Ratio</h4>
                <h4>Athletic Divisions</h4>
                <h4>Honors College</h4>
                <h4>Honors Program</h4>
                <h4>Co-op</h4>
                {/* <h4 className={style.college_name}> */}
                {/*  <strong> */}
                {/*    {selectedCollege?.name.replace('College', '')}, {selectedCollege?.state?.name} */}
                {/*  </strong> */}
                {/* </h4> */}
                {/* <h4>Geographic Location</h4> */}
                {/* <h4>Overall Admit Rate</h4> */}
                {/* <h4>Early Admission</h4> */}
                {/* <h4>Percentage of Need- Met</h4> */}
                {/* <h4>Merits Offered</h4> */}
                {/* <h4>Avg GPA</h4> */}
                {/* <h4>Cost of Attendance</h4> */}
                {/* <h4>Co-Op</h4> */}
              </div>
              <div className={style.right_main_block_res}>
                <span>{selectedCollege?.isPrivate ? 'Private' : 'Public'}</span>
                <span>
                  {selectedCollege?.fullTimeUndergraduates
                    ? (+selectedCollege.fullTimeUndergraduates).toLocaleString('en-US')
                    : '-'}
                </span>
                <span>
                  {selectedCollege?.studentToFaculty ? `${(+selectedCollege.studentToFaculty).toFixed(0)} : 1` : '-'}
                </span>
                <span>{selectedCollege?.athleticDivision?.name ? selectedCollege?.athleticDivision?.name : 'No'}</span>
                <span>{selectedCollege?.honorsCollege ? 'Yes' : 'No'}</span>
                <span>{selectedCollege?.honorsProgram ? 'Yes' : 'No'}</span>
                <span>{selectedCollege?.coop ? 'Yes' : 'No'}</span>
                {/* <span>{selectedCollege?.state?.region?.name}</span> */}
                {/* <span> */}
                {/*  {selectedCollege?.admissionsRate ? `${Math.round(+selectedCollege.admissionsRate)} %` : '-'} */}
                {/* </span> */}
                {/* <span> */}
                {/*  {selectedCollege?.studentToFaculty ? `${(+selectedCollege.studentToFaculty).toFixed(0)} : 1` : '-'} */}
                {/* </span> */}
                {/* <span>{selectedCollege?.athleticDivision?.name ? selectedCollege?.athleticDivision?.name : 'No'}</span> */}
                {/* <span>{offers && offers?.length > 0 ? 'Yes' : 'No'}</span> */}
                {/* <span>{selectedCollege?.honorsProgram ? 'Yes' : 'No'}</span> */}
                {/* <span> */}
                {/*  {selectedCollege?.coaPrivate */}
                {/*    ? readyCostOfAttendance(selectedCollege?.coaPrivate) */}
                {/*    : selectedCollege?.coaInState */}
                {/*    ? readyCostOfAttendance(selectedCollege?.coaInState) */}
                {/*    : selectedCollege?.coaOutState */}
                {/*    ? readyCostOfAttendance(selectedCollege?.coaOutState) */}
                {/*    : '-'} */}
                {/* </span> */}
                {/* <span>{selectedCollege?.coop ? 'Yes' : 'No'}</span> */}
              </div>
            </div>

            <div className={style.right_main_block_info_mobile}>
              <div className={style.right_main_block_res_mobile}>
                <div>
                  <h4>Public/Private College</h4>
                  <span>{selectedCollege?.isPrivate ? 'Private' : 'Public'}</span>
                </div>
                <div>
                  <h4>Full Time Undergraduates</h4>
                  <span>
                    {selectedCollege?.fullTimeUndergraduates
                      ? (+selectedCollege.fullTimeUndergraduates).toLocaleString('en-US')
                      : '-'}
                  </span>
                </div>
                <div>
                  <h4>Student: Faculty Ratio</h4>
                  <span>
                    {selectedCollege?.studentToFaculty ? `${(+selectedCollege.studentToFaculty).toFixed(0)} : 1` : '-'}
                  </span>
                </div>
                <div>
                  <h4>Athletic Divisions</h4>
                  <span>
                    {selectedCollege?.athleticDivision?.name ? selectedCollege?.athleticDivision?.name : 'No'}
                  </span>
                </div>
                <div>
                  <h4>Honors College</h4>
                  <span>{selectedCollege?.honorsCollege ? 'Yes' : 'No'}</span>
                </div>
                <div>
                  <h4>Honors Program</h4>
                  <span>{selectedCollege?.honorsProgram ? 'Yes' : 'No'}</span>
                </div>
                <div>
                  <h4>Co-op</h4>
                  <span>{selectedCollege?.coop ? 'Yes' : 'No'}</span>
                </div>
              </div>
            </div>
          </div>
          {(adminNavigatedUser && adminNavigatedUser?.subscription) ||
          ((user?.subscription || user.role === 'admin') && !adminNavigatedUser) ? (
            <div className={style.college_details_buttons}>
              <button type="button" className="primary-button primary-button-first">
                <a target="_blank" href={selectedCollege?.admissionsUrl} rel="noreferrer">
                  Go to Website
                </a>
              </button>
              <button
                type="button"
                className="primary-button primary-button-second"
                onClick={() => {
                  setShowOffers(!showOffers);
                  setIsAddNotes(false);
                  setIsMyListsSelectActive(false);
                }}
              >
                <a>Crowdsourced Offers</a>
              </button>
            </div>
          ) : (
            <div className={style.college_details_buttons}>
              <button type="button" className="primary-button first">
                <a target="_blank" href={selectedCollege?.admissionsUrl} rel="noreferrer">
                  Go to website
                </a>
              </button>
              <NavLink
                to={user?.subscription || user.role === 'admin' ? '/buy-plan' : ''}
                onClick={() => {
                  if (user?.subscription || user.role === 'admin') {
                    return;
                  }
                  open();
                  setClickedLockedData('crowdsourced');
                }}
                type="button"
                className={style.primary_button_first}
              >
                <img src={locked} alt="block" />
                Crowdsourced Offers
              </NavLink>
            </div>
          )}
          <div className={style.college_details_navigations_cont}>
            <nav className={style.college_details_navigations}>
              <h6 onClick={(e) => changeSection(e, 'profile')} id="profile" className={style.selected_nav}>
                School Profile
              </h6>
              <h6
                className={style.college_details_navigations_h6}
                onClick={(e) => changeSection(e, 'admissions')}
                id="admissions"
              >
                Admissions
              </h6>
              <h6
                className={style.college_details_navigations_h6}
                onClick={(e) => changeSection(e, 'schoolarships')}
                id="schoolarships"
              >
                Aid/ Merit Scholarships
              </h6>
              <h6
                className={style.college_details_navigations_h6}
                onClick={(e) => changeSection(e, 'finance')}
                id="finance"
              >
                Financial Information
              </h6>
            </nav>
          </div>
          <MobileSelect options={tabs} setSelected={setSelectedTab} selected={selectedTab} />
          {selectedTab === 'profile' && <CollegeDetailsProfile clickUnlock={clickUnlockModal} openModal={open} />}
          {selectedTab === 'admissions' && <CollegeDetailsAdmissions clickUnlock={clickUnlockModal} openModal={open} />}
          {selectedTab === 'finance' && <CollegeDetailsFinance />}
          {selectedTab === 'schoolarships' && (
            <CollegeDetailsSchoolarships clickUnlock={clickUnlockModal} openModal={open} />
          )}
          <Modal>
            {!isAuthenticated ? (
              <SignUpModal close={close} message={'Find More Schools and Start Building a College List'} />
            ) : showMarks && !isAuthenticated ? (
              <SignUpModal
                close={close}
                message={'Find More Schools and Start Building a College List'}
                anyAction={() => setShowMarks(!showMarks)}
              />
            ) : showOffers ? (
              <OffersModal
                close={() => {
                  setShowOffers(false);
                  close();
                }}
              />
            ) : isAddNotes ? (
              <AddNotesModal
                close={() => {
                  setIsAddNotes(false);
                  close();
                }}
                propNotes={selectedCollege.notes}
                collegeId={selectedCollege.id}
                collegeName={selectedCollege.name}
                userId={adminNavigatedUser ? adminNavigatedUser.id : user.id}
                isOpenOnCollegeDetails={true}
              />
            ) : clickedLockedData === 'crowdsourced' ||
              clickedLockedData === 'unlock-modal' ||
              clickedLockedData === 'notes' ? (
              <SignUpModal
                close={close}
                message={
                  clickedLockedData === 'crowdsourced'
                    ? 'Upgrade Your Plan To View This Data'
                    : 'Upgrade Your Plan To Use This Feature'
                }
                anyAction={() => setClickedLockedData('')}
                title={clickedLockedData === 'crowdsourced' ? 'Premium Data' : 'Premium Feature'}
              />
            ) : isYoutube ? (
              <YoutubeModal close={closeYoutube} videoUrl={convertToEmbedLink(selectedCollege?.urlVirtualTour)} />
            ) : !allowedCreateList ? (
              <UniversalModal
                close={() => {
                  setAllowedCreateList(true);
                  close();
                }}
                textForTitle={'Upgrade Account'}
                textForDescription={
                  'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            ) : (
              <AddListModal createList={createList} close={close} />
            )}
          </Modal>
        </div>
      </div>
    </div>
  );
};
