import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ECollegeNotesActionTypes } from '../../enums';
import { ICollegeNote } from '../../models';
import { collegeNotesService } from '../../services';
import { collegeNotesAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ECollegeNotesActionTypes.GET_COLLEGE_NOTES as any, watchGetCollegeNotes)]);
  yield all([takeLatest(ECollegeNotesActionTypes.CREATE_COLLEGE_NOTE as any, watchCreateCollegeNote)]);
  yield all([takeLatest(ECollegeNotesActionTypes.UPDATE_COLLEGE_NOTE as any, watchUpdateCollegeNote)]);
  yield all([takeLatest(ECollegeNotesActionTypes.DELETE_COLLEGE_NOTE as any, watchDeleteCollegeNote)]);
}

function* watchGetCollegeNotes(action: { type: string; payload: { userId: number; collegeId: number } }) {
  try {
    const { data } = yield call(
      collegeNotesService.endpoint_get_college_notes,
      action.payload.collegeId,
      action.payload.userId,
    );

    yield put(collegeNotesAction.setCollegeNotes(data as ICollegeNote[]));
  } catch (error: any) {
    console.error('watchGetCollegeNotes: ', error.response);
  }
}

function* watchCreateCollegeNote(action: { type: string; payload: Partial<ICollegeNote> }) {
  try {
    yield call(collegeNotesService.endpoint_create_college_note, action.payload);

    const { data } = yield call(
      collegeNotesService.endpoint_get_college_notes,
      action.payload.collegeId as number,
      action.payload.userId as number,
    );

    yield put(collegeNotesAction.setCollegeNotes(data as ICollegeNote[]));
  } catch (error: any) {
    console.error('watchCreateCollegeNote: ', error.response);
  }
}

function* watchUpdateCollegeNote(action: { type: string; payload: Partial<ICollegeNote> }) {
  try {
    yield call(
      collegeNotesService.endpoint_update_college_note,
      action.payload.id as number,
      action.payload.text as string,
    );

    const { data } = yield call(
      collegeNotesService.endpoint_get_college_notes,
      action.payload.collegeId as number,
      action.payload.userId as number,
    );

    yield put(collegeNotesAction.setCollegeNotes(data as ICollegeNote[]));
  } catch (error: any) {
    console.error('watchUpdateCollegeNote: ', error.response);
  }
}

function* watchDeleteCollegeNote(action: { type: string; payload: Partial<ICollegeNote> }) {
  try {
    yield call(collegeNotesService.endpoint_delete_college_note, action.payload.id as number);

    const { data } = yield call(
      collegeNotesService.endpoint_get_college_notes,
      action.payload.collegeId as number,
      action.payload.userId as number,
    );

    yield put(collegeNotesAction.setCollegeNotes(data as ICollegeNote[]));
  } catch (error: any) {
    console.error('watchDeleteCollegeNote: ', error.response);
  }
}
