/* eslint-disable */
import './Header.css';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink, useLocation, useNavigate, Link } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import React, { useEffect, useRef, useState } from 'react';
import localforage from 'localforage';
import { useDispatch, useSelector } from 'react-redux';
import { SignUpModal } from '../signup-modal/SignUpModal';
import { SaveListModal } from '../save-list-modal/SaveListModal';
import { AddNewListModal } from '../add-new-list-modal/AddNewListModal';
import { collegesAction, myListsAction, previousRouteAction } from '../../store/actions';
import { Nav } from './Nav';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { ICollege } from '../../models';

import road2college from '../../images/icons/r2c-logo.svg';
import { HeaderMobile } from './HeaderMobile';

export const Header = () => {
  const mobileMenu = useRef(null);
  const mobileBtn = useRef(null);
  const menuRef = useRef(null);
  const submenuRef = useRef(null);
  const profileBtn = useRef(null);
  const childRef = useRef<any>();

  //Dropwdowns
  const [servicesDropdownOpen, setServicesDropdownOpen] = useState(false);
  const toggleServices = () => setServicesDropdownOpen((prevState) => !prevState);

  const [myInsightsDropdownOpen, setMyInsightsDropdownOpen] = useState(false);
  const toggleMyInsights = () => setMyInsightsDropdownOpen((prevState) => !prevState);

  const [menuDropdown, setMenuDropdownOpen] = useState(false);
  const toggleMenu = () => setMenuDropdownOpen((prevState) => !prevState);

  //Log User
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);

  //Search bar
  const { recommendedColleges, colleges, updatedRecommendedColleges } = useSelector(
    (state: any) => state.collegesState,
  );

  const [isInputActive, setIsInputActive] = useState(false);
  const [searchCollegeValue, setSearchCollegeValue] = useState('');
  const [filteredColleges, setFilteredColleges] = useState(colleges);

  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  //Search baar
  const filterCollegesNames = (inputValue: string) => {
    setIsInputActive(true);
    const nextValue = colleges.filter((college: ICollege) =>
      college.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredColleges(nextValue.sort((a: { name: string }, b: { name: any }) => a.name.localeCompare(b.name)));
  };

  const selectCollege = (college: ICollege) => {
    setSearchCollegeValue(college.name);
    setIsInputActive(false);
    navigate(`/college-details/${college.id}`, { state: '/home' });
  };

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isInfoHubVisibleAndGetStarted, setIsInfoHubVisibleAndGetStartedAndGetStarted] = useState(false);
  const [isGradesVisible, setIsGradesVisible] = useState(false);
  const [isProcessVisible, setIsProcessVisible] = useState(false);
  const [isOpenSaveListModal, setIsOpenSaveListModal] = useState<boolean>(false);
  const [isOpenAddNewListModal, setIsOpenAddNewListModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const scrollToSection = (section_id) => {
    const section = document.getElementById(section_id);
    section ? section.scrollIntoView({ behavior: 'smooth' }) : '';
  };

  const blockedClick = () => {
    return (
      previousRoute.payload === '/home-page-after-require' ||
      previousRoute.payload === '/require-pay' ||
      previousRoute.payload === '/upgrade' ||
      (previousRoute.payload === '/shared-list-after-require-pay' && window.location.pathname === '/buy-plan')
    );
  };

  const closeSubmenuMobile = () => {
    setIsProcessVisible(false);
    setIsGradesVisible(false);
    setIsInfoHubVisibleAndGetStartedAndGetStarted(false);
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      const profBtn: any = profileBtn.current;
      const menu: any = menuRef.current;
      const submenu: any = submenuRef.current;
      const mobileMenuRef: any = mobileMenu.current;
      const mobileBtnRef: any = mobileBtn.current;

      if (
        mobileMenuRef &&
        !mobileMenuRef.contains(event.target) &&
        mobileBtnRef &&
        !mobileBtnRef.contains(event.target) &&
        menu &&
        !menu.contains(event.target) &&
        submenu &&
        !submenu.contains(event.target) &&
        profBtn &&
        !profBtn.contains(event.target)
      ) {
        setIsMenuVisible(false);
        closeSubmenuMobile();
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  const handleSetIsOpenAddNewListModal = () => {
    open();
    setIsOpenAddNewListModal(!isOpenAddNewListModal);
    setIsOpenSaveListModal(false);
  };

  return (
    <nav>
      <Nav></Nav>
      <header className="header-container">
        <div className="header">
          <div className="first-block-header">
            <img className="road2college" src={road2college} alt={'title'} />
            <NavLink
              to={
                blockedClick()
                  ? `${window.location.pathname}`
                  : window.location.pathname.includes('/shared-lists') && isAuthenticated
                  ? '/my-lists'
                  : isAuthenticated
                  ? '/home'
                  : '/landing-page'
              }

              className="college-insights"
              onClick={() => {
                if (window.location.pathname.includes('/shared-lists')) {
                  return;
                }
                if (
                  previousRoute.payload === '/shared-list-after-require-pay' &&
                  window.location.pathname !== '/buy-plan'
                ) {
                  navigate('/my-lists');
                  return;
                }
                if (
                  previousRoute.payload === '/home-page-after-require' ||
                  previousRoute.payload === '/require-pay' ||
                  previousRoute.payload === '/upgrade' ||
                  previousRoute.payload === '/shared-list-after-require-pay'
                ) {
                  return;
                }
                if (isAuthenticated) {
                  dispatch(previousRouteAction.setPreviousRoute('/home'));
                }
              }}
            >
              Insights
            </NavLink>

            {isAuthenticated && (
              <>
                <div className="header-items">
                  <div
                    className={`header-item-container ${
                      window.location.pathname.split('/')[1] == 'my-lists' ? 'active' : ''
                    }`}
                  >
                    <a href="/my-lists" className="header-item-link text-black">
                      My Lists
                    </a>
                  </div>

                  <div
                    className={`header-item-container ${window.location.pathname == '/home' ? 'active' : ''}`}
                    onMouseOver={() => setMyInsightsDropdownOpen(true)}
                    onMouseLeave={() => setMyInsightsDropdownOpen(false)}
                    onFocus={() => setMyInsightsDropdownOpen(true)}
                    onBlur={() => setMyInsightsDropdownOpen(false)}
                  >
                    <Dropdown
                      isOpen={myInsightsDropdownOpen}
                      toggle={() => setMyInsightsDropdownOpen(true)}
                      direction="down"
                      className="dropdown-custom-header dropdown-bg-none text-black p-0"
                    >
                      <DropdownToggle caret>Insights</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem>
                          <NavLink
                            to={'/home?calendar=true'}
                            className="text-black"
                            onClick={() => {
                              setTimeout(() => {
                                scrollToSection('recommendations');
                              }, 150);
                            }}
                          >
                            Recommendations
                          </NavLink>
                        </DropdownItem>
                        <DropdownItem>
                          <NavLink
                            to={'/home?calendar=true'}
                            className="text-black"
                            onClick={() => {
                              setTimeout(() => {
                                scrollToSection('calendar');
                              }, 150);
                            }}
                          >
                            Yearly Calendar
                          </NavLink>
                        </DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div
                    className={`header-item-container ${window.location.pathname == '/services' ? 'active' : ''}`}
                    onMouseOver={() => setServicesDropdownOpen(true)}
                    onFocus={() => setServicesDropdownOpen(true)}
                    onMouseLeave={() => setServicesDropdownOpen(false)}
                    onBlur={() => setServicesDropdownOpen(false)}
                  >
                    <Dropdown
                      isOpen={servicesDropdownOpen}
                      direction="down"
                      toggle={() => setServicesDropdownOpen(!servicesDropdownOpen)}
                      className="dropdown-custom-header dropdown-bg-none text-black p-0"
                    >
                      <DropdownToggle caret>Services</DropdownToggle>
                      <DropdownMenu>
                        <DropdownItem href="/services">1:1 Services</DropdownItem>
                        <DropdownItem href="/buy-plan">Membership Plan</DropdownItem>
                      </DropdownMenu>
                    </Dropdown>
                  </div>

                  <div
                    className={`header-item-container ${window.location.pathname == '/power-search' ? 'active' : ''}`}
                  >
                    <a href="/power-search" className="header-item-link text-black">
                      Power Search
                    </a>
                  </div>

                  <div className="header-item-container">
                    <NavLink
                      to={'/home'}
                      onClick={() => {
                        setTimeout(() => {
                          scrollToSection('scoresmart');
                        }, 150);
                      }}
                      className="header-item-link text-black"
                    >
                      Free Practice Digital SAT
                    </NavLink>
                  </div>
                </div>
              </>
            )}
          </div>

          <HeaderMobile />

          <Modal>
            {isOpenSaveListModal && (
              <SaveListModal
                close={() => {
                  close();
                  setIsOpenSaveListModal(false);
                }}
                onHandleSetIsOpenAddNewListModal={handleSetIsOpenAddNewListModal}
              />
            )}
            {isOpenAddNewListModal && (
              <AddNewListModal
                close={() => {
                  close();
                  setIsOpenAddNewListModal(false);
                }}
              />
            )}
            <SignUpModal close={close} message={'Please Sign In or Sign Up and fill your details'} />
          </Modal>
        </div>
      </header>
    </nav>
  );
};
