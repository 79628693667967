import './My-list.css';
import { useParams, NavLink } from 'react-router-dom';
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useModal } from 'react-hooks-use-modal';
import moment from 'moment';
// eslint-disable-next-line import/no-extraneous-dependencies
import { saveAs } from 'file-saver';
import { ICollege, IHistoryDownloadCreate, IMyList, IUser } from '../../models';
import {
  collegesAction,
  myListsAction,
  notesAction,
  previousRouteAction,
  switcherBlocksMyListAction,
  selectedCollegesAndColumnsAction,
  userAction,
  loadingAction,
  selectedCollegesForSharingAction,
} from '../../store/actions';
import {
  collegeNotesService,
  historyDownloadService,
  myListService,
  sharedListsService,
  userService,
} from '../../services';

// COMPONENTS
import {
  AddListModal,
  AddNotesModal,
  ConfirmDownloadListModal,
  DeleteAllCollegesFromListModal,
  DeleteListModal,
  DownloadListModal,
  MyListCollege,
  SignUpModal,
  InfoLockedModal,
  UniversalModal,
  YoutubeModal,
  ScholarshipModal,
  MyListTablePagination,
  Autocomplete,
  EmailModal,
  SocialMediaModal,
  OffersModal,
  CustomLoader,
  SaveListModal,
  AddNewListModal,
  ISharedListResponse,
} from '..';

// IMAGES
import plus from '../../images/plus-icon.svg';
import sort from '../../images/sort-arrows.svg';
import sortGreyBlack from '../../images/sort-arrowsGreyBlack.svg';
import sortBlackGrey from '../../images/sort-arrowsBlackGrey.svg';
import arrowWhite from '../../images/input_arrow.svg';
import deleteIcon from '../../images/delete.svg';
import addToListIcon from '../../images/add-to-list-icon.svg';
import createListIcon from '../../images/create-list.svg';
import unsaveIcon from '../../images/unsave-icon.svg';
import shareIcon from '../../images/shareArrows.svg';
import editIcon from '../../images/editSquare.svg';
import noList from '../../images/noList.svg';
import notesIcon from '../../images/notes-icon.svg';
import lockWhite from '../../images/locked-icon-white.svg';
import listIcon from '../../images/list-icon.svg';
import arrow from '../../images/silver-arrow.svg';
import { SingleInputModal } from '../single-input-modal/SingleInputModal';

const defaultCollege = {
  college: {},
  id: -100,
  name: '',
  stateId: -3,
  ipeds: -106263,
};
const sortCategories = [
  { id: 1, name: 'College Name', property: 'name', value: 'name' },
  { id: 2, name: 'Location', property: 'state', value: 'stateId' },
  { id: 3, name: 'School Size', property: 'fullTimeUndergraduates', value: 'fullTimeUndergraduates' },
  { id: 4, name: 'In-State COA', property: 'coaInState' },
  { id: 5, name: 'Out-of-State COA', property: 'coaOutState' },
  { id: 6, name: 'Private COA', property: 'coaPrivate' },
  { id: 7, name: 'Avg SAT', property: 'satAvg', value: 'satAvg' },
  { id: 8, name: 'Avg ACT', property: 'actAvg', value: 'actAvg' },
  { id: 9, name: 'Avg GPA', property: 'avgFreshmenGpa', value: 'avgFreshmenGpa' },
  { id: 10, name: 'SAT 75th Percentile', property: 'sat75thPc', value: 'sat75thPc' },
  { id: 11, name: 'ACT 75th Percentile', property: 'act75thPc', value: 'act75thPc' },
  { id: 12, name: '4 Yr Grad Rate', property: 'fourYearGraduationRate', value: 'fourYearGraduationRate' },
  { id: 13, name: 'Overall Admit Rate', property: 'admissionsRate', value: 'admissionsRate' },
  { id: 14, name: 'ED Admit Rate', property: 'edAdmitRate', value: 'edAdmitRate' },
  { id: 15, name: 'EA Admit Rate', property: 'eaAdmitRate', value: 'eaAdmitRate' },
  { id: 16, name: 'Class Filled ED', property: 'edFilledRate', value: 'edFilledRate' },
  { id: 17, name: 'Need Met', property: 'avgPcNeedMet', value: 'avgPcNeedMet' },
  {
    id: 16,
    name: 'Freshmen w/out Need Receiving Merit',
    property: 'pcStudentsWithoutNeed',
    value: 'pcStudentsWithoutNeed',
  },
  { id: 17, name: 'Avg Merit Aid', property: 'avgMeritAidAward', value: 'avgMeritAidAward' },
];
const chooseInformation = [
  { id: 0, name: 'Choose Information', value: 'none' },
  { id: 1, name: 'School Profile', value: 'profile' },
  { id: 2, name: 'Admissions', value: 'admissions' },
  { id: 3, name: 'Aid/Merit Scholarship', value: 'scholarships' },
  { id: 4, name: 'Financial Information', value: 'finance' },
];
const firstStep = {
  title: 'Choose schools that you want to download information for',
  descriptionFirstPart: 'You can choose up to ',
  descriptionCenterPart: '20 schools ',
  descriptionLastPart: 'per download',
  titleLeftColumn: 'Schools in Selected List',
  titleRightColumn: 'Selected Schools',
  submitBtn: 'Next',
};
const secondStep = {
  title: 'Choose data that you want to download ',
  descriptionFirstPart: 'Out of 40+ data parameters, please go ahead and choose ',
  descriptionCenterPart: 'any 10',
  titleLeftColumn: 'School Profile',
  titleRightColumn: 'Selected Data for Download',
  submitBtn: 'Next',
};
const columnsForDownloading = [
  {
    nameBlock: 'School Profile',
    secondLevelBlocks: [
      {
        nameSecondBlock: 'Undergraduates',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Endowment Per Student', nameColumn: 'endowmentPerStudent' },
          { nameThirdBlock: '% Undergraduate Women', nameColumn: 'pcUndergraduateWomen' },
          { nameThirdBlock: '% Undergraduate Men', nameColumn: 'pcUndergraduateMen' },
          { nameThirdBlock: '% Returning for Sophomore Year', nameColumn: 'pcReturningForSophomore' },
          { nameThirdBlock: '4-Year Graduation Rate', nameColumn: 'fourYearGraduationRate' },
        ],
      },
      {
        nameSecondBlock: 'Race/Ethnicity',
        thirdLevelBlocks: [
          { nameThirdBlock: '% UG White', nameColumn: 'enWhiteNonhispanic' },
          { nameThirdBlock: '% UG African American', nameColumn: 'enBlackNonhispanic' },
          { nameThirdBlock: '% UG Hispanic/Latino', nameColumn: 'enHispanicEthnicity' },
          { nameThirdBlock: '% UG Asian', nameColumn: 'enAsianNonhispanic' },
          { nameThirdBlock: '% UG Non-Residential Alien', nameColumn: 'enNonresAlien' },
        ],
      },
    ],
  },
  {
    nameBlock: 'Admissions',
    secondLevelBlocks: [
      {
        nameSecondBlock: 'Admission Stats',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Average High School GPA', nameColumn: 'avgFreshmenGpa' },
          { nameThirdBlock: '4.0 GPA', nameColumn: 'avgFreshmenGpa1P' },
          { nameThirdBlock: '3.75 - 3.99 GPA', nameColumn: 'avgFreshmenGpa2P' },
          { nameThirdBlock: '3.5 - 3.74 GPA', nameColumn: 'avgFreshmenGpa3P' },
          { nameThirdBlock: '3.0 - 3.49 GPA', nameColumn: 'avgFreshmenGpa4P' },
          { nameThirdBlock: 'Below 3.0 GPA', nameColumn: 'avgFreshmenGpaBelow3P' },
          { nameThirdBlock: 'SAT/ACT Score Required', nameColumn: 'testScoreRequirementType' },
          { nameThirdBlock: 'Average SAT', nameColumn: 'satAvg' },
          { nameThirdBlock: 'SAT 75th Percentile', nameColumn: 'sat75thPc' },
          { nameThirdBlock: 'Average ACT', nameColumn: 'actAvg' },
          { nameThirdBlock: 'ACT 75th Percentile', nameColumn: 'act75thPc' },
          { nameThirdBlock: 'Overall Admit Rate', nameColumn: 'admissionsRate' },
          { nameThirdBlock: 'Early Decision Admit Rate', nameColumn: 'edAdmitRate' },
          { nameThirdBlock: 'Early Action Admit Rate', nameColumn: 'eaAdmitRate' },
          { nameThirdBlock: 'Regular Decision Admit Rate', nameColumn: 'rdAdmitRate' },
          { nameThirdBlock: 'Percent of Class Filled ED', nameColumn: 'edFilledRate' },
          { nameThirdBlock: 'Admit Yield', nameColumn: 'admitYield' },
          { nameThirdBlock: 'Application Fee', nameColumn: 'applicationFee' },
        ],
      },
      {
        nameSecondBlock: 'Deadlines',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Early Decision Deadline', nameColumn: 'edDeadline' },
          { nameThirdBlock: 'Early Action Deadline', nameColumn: 'eaDeadline' },
          { nameThirdBlock: 'Early Decision 2 Deadline', nameColumn: 'ed2Deadline' },
          { nameThirdBlock: 'Regular Decision Deadline', nameColumn: 'regDecisionDeadline' },
          { nameThirdBlock: 'Financial Aid Deadline', nameColumn: 'finAidDeadline' },
        ],
      },
    ],
  },
  {
    nameBlock: 'Aid/Merit Scholarships',
    secondLevelBlocks: [
      {
        nameSecondBlock: 'Need Based Aid',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Percent of Need Met', nameColumn: 'avgPcNeedMet' },
          { nameThirdBlock: 'Average Financial Aid for Freshmen w/ Need', nameColumn: 'avgNeedBasedAward' },
          { nameThirdBlock: 'CSS Profile', nameColumn: 'cssProfileRequired' },
          { nameThirdBlock: 'Non-Custodial CSS Form Required', nameColumn: 'nonCustodialCssRequired' },
        ],
      },
      {
        nameSecondBlock: 'Merit Based Scholarship',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Freshmen w/out Need', nameColumn: 'pcStudentsWithoutNeed' },
          { nameThirdBlock: 'Freshmen w/out Need Receiving Merit Aid', nameColumn: 'pcStudentsReceivingMeritAid' },
          { nameThirdBlock: 'Average Merit Award (Freshmen w/out Need)', nameColumn: 'avgMeritAidAward' },
          { nameThirdBlock: 'Merit Scholarship Details', nameColumn: 'scholarshipsUrl' },
          { nameThirdBlock: 'Full Tuition / Full Ride Scholarship', nameColumn: 'urlFullScholarship' },
        ],
      },
    ],
  },
  {
    nameBlock: 'Financial Information',
    secondLevelBlocks: [
      {
        nameSecondBlock: 'Costs',
        thirdLevelBlocks: [
          { nameThirdBlock: 'Private Attendance', nameColumn: 'coaPrivate' },
          { nameThirdBlock: 'In-State Cost of Attendance', nameColumn: 'coaInState' },
          { nameThirdBlock: 'Out-of-State Cost of Attendance', nameColumn: 'coaOutState' },
          { nameThirdBlock: 'Average Net Price After Gift Aid', nameColumn: 'avgNetPriceAfterAid' },
          { nameThirdBlock: 'Avg Net Price Family Income $0 - $30,000', nameColumn: 'avgPriceTo30000' },
          { nameThirdBlock: 'Avg Net Price Family Income $30,001 - $48,000', nameColumn: 'avgPriceFrom30001To48000' },
          { nameThirdBlock: 'Avg Net Price Family Income $48,001 - $75,000', nameColumn: 'avgPriceFrom48001To75000' },
          { nameThirdBlock: 'Avg Net Price Family Income $75,001 - $110,000', nameColumn: 'avgPriceFrom75001To110000' },
          { nameThirdBlock: 'Avg Net Price Family Income over $110,000', nameColumn: 'avgPriceOver110000' },
          { nameThirdBlock: 'Net Price Calculator', nameColumn: 'urlAddressPriceCalc' },
        ],
      },
      {
        nameSecondBlock: 'Debt',
        thirdLevelBlocks: [
          { nameThirdBlock: '%Undergrads w/ Federal Student Loans', nameColumn: 'gradsAnyLoanP' },
          { nameThirdBlock: 'Avg Annual Federal Student Loans for Undergrads', nameColumn: 'gradsAnyLoanAvgAmt' },
          { nameThirdBlock: 'Grads From Recent Class w/ Any Type of Loan(s)', nameColumn: 'pcGraduatingWithLoans' },
          { nameThirdBlock: 'Average Balances From Loans (All Types)', nameColumn: 'avgLoanAmount' },
          { nameThirdBlock: 'Percent of Undergrads w/ Parent PLUS Loans', nameColumn: 'parentPlusP' },
          { nameThirdBlock: 'Average Parent PLUS Loans per Borrower', nameColumn: 'parentPlusAvgAmt' },
        ],
      },
    ],
  },
];
interface CSVData {
  columns: string[];
  values: string[][];
}

interface ISharedList {
  userId: number;
  userName: string;
  listId: number;
  listName: string;
  collegeIds: string;
}

export interface ISharedCollege {
  id: number;
  name: string;
}

export interface ISharedListBeforeSending {
  id: number;
  name: string;
  userId: number;
  colleges: Array<ISharedCollege>;
}

export const MyList = () => {
  const isTabletOrMobile: boolean = window.innerWidth <= 768;

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  // REDUX STATES
  const { myLists, selectedList, paginatedColleges, recommendations } = useSelector((state: any) => state.myListsState);
  const { selectedCollegesForCsv, selectedColumnsForCsv } = useSelector(
    (state: any) => state.selectedCollegesAndColumnsForCsvState,
  );
  const { profile, admissions, scholaships, finance } = useSelector((state: any) => state.switcherBlocksMyListState);
  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { user: stateUser, adminNavigatedUser } = useSelector((state: any) => state.userState);
  const { myListLoading } = useSelector((state: any) => state.loadingState);
  const { selectedCollegesForSharing } = useSelector((state: any) => state.selectedCollegesForSharingState);
  const { collegeNotes } = useSelector((state: any) => state.collegeNotesState);

  // BOOLEAN STATES
  const [isMyListsSelectActive, setIsMyListsSelectActive] = useState<boolean>(false);
  const [chooseInfo, setChooseInfo] = useState<boolean>(false);
  const [isSortByInputActive, setIsSortByInputActive] = useState(false);
  const [isDescSort, setIsDescSort] = useState<boolean>(true);
  const [deleteAllFromList, setDeleteAllFromList] = useState<boolean>(false);
  const [isAddList, setIsAddList] = useState<boolean>(false);
  const [isDelete, setIsDelete] = useState<boolean>(false);
  const [showRecommendations] = useState<boolean>(false);
  const [isAddNoteModal, setIsAddNoteModal] = useState<boolean>(false);
  const [isAddNoteModalToList, setIsAddNoteModalToList] = useState<boolean>(false);
  const [allowedCreateList, setAllowedCreateList] = useState<boolean>(true);
  const [allowShowRecs, setAllowShowRecs] = useState<boolean>(true);
  const [showFeatureModal, setShowFeatureModal] = useState<boolean>(false);
  const [isOpenBookMark, setIsOpenBookMark] = useState<boolean>(false);
  const [isVisibleTablePagination, setIsVisibleTablePagination] = useState<boolean>(!isTabletOrMobile);
  const [isOpenYoutubeModal, setIsOpenYoutubeModal] = useState<boolean>(false);
  const [isOpenLockedPopupOffers, setIsOpenLockedPopupOffers] = useState<boolean>(false);
  const [isOpenMeritScholarship, setIsOpenMeritScholarship] = useState<boolean>(false);
  const [isAddListModal, setIsAddListModal] = useState<boolean>(false);
  const [isOpenListViewInMobile, setIsOpenListViewInMobile] = useState<boolean>(false);
  const [isOpenDownloadCSVInMobile, setIsOpenDownloadCSVInMobile] = useState<boolean>(false);
  const [isOpenDownloadCSVInMobileFree, setIsOpenDownloadCSVInMobileFree] = useState<boolean>(false);
  const [isOpenDownloadCSVInPCFree, setIsOpenDownloadCSVInPCFree] = useState<boolean>(false);
  const [isOpenDownloadCSVEarlyWithCountZero, setIsOpenDownloadCSVEarlyWithCountZero] = useState<boolean>(false);
  const [showOffers, setShowOffers] = useState<boolean>(false);
  const [isSortedInFronted, setIsSortedInFrontend] = useState<boolean>(false);
  const [isOpenDownloadModal, setIsOpenDownloadModal] = useState<boolean>(false);
  const [isOpenSocialMedias, setIsOpenSocialMedias] = useState<boolean>(false);
  const [isOpenDownloadWithoutCollegesInList, setIsOpenDownloadWithoutCollegesInList] = useState<boolean>(false);
  const [isOpenMobileModalSocial, setIsOpenMobileModalSocial] = useState<boolean>(false);
  const [isOpenEmailModal, setIsOpenEmailModal] = useState<boolean>(false);
  const [isOpenModalAfterSendEmail, setIsOpenModalAfterSendEmail] = useState<boolean>(false);
  const [isOpenSaveListModal, setIsOpenSaveListModal] = useState<boolean>(false);
  const [isOpenAddNewListModal, setIsOpenAddNewListModal] = useState<boolean>(false);
  const [isEditListMenuOpen, setIsEditListMenuOpen] = useState<boolean>(false);
  const [isEditListNameModalOpen, setIsEditListNameModalOpen] = useState<boolean>(false);
  const [isFetchingDataFromModal, setIsFetchingDataFromModal] = useState<boolean>(false);

  const [user, setUser] = useState<IUser>(stateUser);
  const [lists, setLists] = useState(myLists);
  const [selectedInformation, setSelectedInformation] = useState<any>(chooseInformation[0]);
  const [sortBy, setSortBy] = useState<any>();
  const [collegeSelectedListActive, setCollegeSelectedListActive] = useState<number | null>(null);
  const [paginationLimit, setPaginationLimit] = useState<number>();
  const [paginationSkip, setPaginationSkip] = useState<number>(0);
  const [swipeCoord, setSwipeCoord] = useState<any>();
  const [paginationPage, setPaginationPage] = useState<number>();
  const [maxPaginationPage, setMaxPaginationPage] = useState<number>();
  const [inputPagination, setInputPagination] = useState<number>();
  const [heightSlider, setHeightSlider] = useState<number>(60.5);
  const [lockedPage, setLockerPage] = useState<number>(3);
  const [linkYoutubeModal, setLinkYoutubeModal] = useState<string>('');
  const [modalContent, setModalContent] = useState({ title: '', message: '' });
  const [clickedCollege, setClickedCollege] = useState<any>(defaultCollege);
  const [readyPaginatedColleges, setReadyPaginatedColleges] = useState<any>([]);
  const [stepDownloading, setStepDownloading] = useState<number>(1);
  const [selectedCollegeForCsv, setSelectedCollegeForCsv] = useState<any>();
  const [selectedColumnForCsv, setSelectedColumnForCsv] = useState<any>();
  const [basketLists, setBasketLists] = useState<any[]>([]);
  const [list, setList] = useState<IMyList | null>(selectedList || null);
  const [notes, setNotes] = useState<any>([]);
  const [modalErrorMessage, setModalErrorMessage] = useState<string>('');

  const [pagePaginationListView, setPagePaginationListView] = useState<number>(
    Number(localStorage.getItem('paginationPageListView')) || 1,
  );

  const dispatch = useDispatch();
  const childRef = useRef<any>();
  const selectedBookMarkRef = useRef(null);
  const socialMediaRef = useRef(null);
  const profileRef = useRef(null);
  const admissionsRef = useRef(null);
  const scholashipsRef = useRef(null);
  const financialRef = useRef(null);
  const paginationRef = useRef(null);
  const editListMenuRef = useRef(null);
  const editListBtnRef = useRef(null);

  const { id }: any = useParams();
  const [actions, setActions] = useState<any[]>([]);
  const [csvData, setCSVData] = useState<CSVData>({
    columns: ['Id', 'Name'],
    values: [[]],
  });
  const [isInList, setIsInList] = useState<boolean>(
    !!selectedList?.colleges?.find((college: any) =>
      clickedCollege ? college.collegeId === clickedCollege.id : college.collegeId === -1,
    ),
  );

  const { idSharedList } = useSelector((state: any) => state.myListsState);
  const [sharedList, setSharedList] = useState<ISharedListResponse | null>();

  useEffect(() => {
    if (!idSharedList) return;
    fetchSharedList(Number(idSharedList));
  }, []);

  const fetchSharedList = async (listId: number) => {
    const { data } = await sharedListsService.endpoint_get_sharedList(listId);
    if (data) {
      setSharedList(data);
    }
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      const editListMenu: any = editListMenuRef.current;
      const editListBtn: any = editListBtnRef.current;

      if (editListMenu && !editListMenu.contains(event.target) && editListBtn && !editListBtn.contains(event.target)) {
        setIsEditListMenuOpen(false);
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  useEffect(() => {
    const routes = ['/shared-lists', '/shared-list-after-require-pay'];
    if (sharedList?.id && routes.includes(previousRoute.payload)) {
      open();
      setIsOpenSaveListModal(true);
      dispatch(previousRouteAction.setPreviousRoute('my-lists'));
    }
    if (sharedList?.id && previousRoute.payload === '/shared-lists-after-pay') {
      open();
      setIsOpenAddNewListModal(true);
      dispatch(previousRouteAction.setPreviousRoute('my-lists'));
    }
  }, [sharedList]);

  useEffect(() => {
    if (isVisibleTablePagination) {
      if (paginatedColleges?.colleges?.length === 0) return;
      const maxPage = Math.ceil(Number(paginatedColleges?.colleges?.length) || 0);

      if (maxPage < pagePaginationListView) {
        setPagePaginationListView(1);
        localStorage.setItem('paginationPageListView', `${1}`);
      }
    }
  }, [paginatedColleges?.colleges?.length, showRecommendations, selectedList]);

  const handleSetIsOpenAddNewListModal = () => {
    open();
    setIsOpenAddNewListModal(!isOpenAddNewListModal);
    setIsOpenSaveListModal(false);
  };

  const handleOpenModalAfterSendEmail = () => {
    setIsOpenEmailModal(false);
    setIsOpenModalAfterSendEmail(true);
    open();
  };

  const handleClickOpenEmailModal = () => {
    setIsOpenEmailModal(true);
    setIsOpenSocialMedias(false);
    open();

    if (isOpenMobileModalSocial) {
      setIsOpenMobileModalSocial(false);
    }
  };

  useEffect(() => {
    userService.endpoint_get_user({ email: stateUser.email }).then(({ data: user }) => {
      setUser(user);
    });
  }, [stateUser]);

  const handleProfile = (boolValue: boolean) => {
    dispatch(switcherBlocksMyListAction.setProfile(boolValue));
    setTimeout(() => {
      if (boolValue) {
        handleScroll(profileRef);
      }
    }, 300);
  };

  const handleAdmissions = (boolValue: boolean) => {
    dispatch(switcherBlocksMyListAction.setAdmissions(boolValue));
    setTimeout(() => {
      if (boolValue) {
        handleScroll(admissionsRef);
      }
    }, 300);
  };

  const handleScholaships = (boolValue: boolean) => {
    dispatch(switcherBlocksMyListAction.setScholaships(boolValue));
    setTimeout(() => {
      if (boolValue) {
        handleScroll(scholashipsRef);
      }
    }, 300);
  };

  const handleFinance = (boolValue: boolean) => {
    dispatch(switcherBlocksMyListAction.setFinance(boolValue));
    setTimeout(() => {
      if (boolValue) {
        handleScroll(financialRef);
      }
    }, 300);
  };

  useEffect(() => {
    if (paginatedColleges) {
      const copyColleges = paginatedColleges?.colleges;
      setReadyPaginatedColleges(copyColleges);
    }
  }, [paginatedColleges, selectedList]);

  useEffect(() => {
    collegeNotesService.endpoint_get_user_notes(user?.id as number).then(({ data }) => {
      setNotes(data);
    });
  }, [paginatedColleges?.colleges, collegeNotes]);

  useEffect(() => {
    if (!clickedCollege) {
      setTimeout(() => {
        if (isInList) {
          const arr = [
            { id: -1, action: 'delete', name: 'Delete from All Lists', img: deleteIcon },
            { id: selectedList.id, action: 'delete', name: `Delete from ${selectedList.name}`, img: deleteIcon },
          ];
          myLists.forEach((l: IMyList, i: number) => {
            if (l.id !== selectedList.id) {
              arr.push({ id: l.id, action: 'add', name: `Add to ${l.name}`, img: addToListIcon });
            }
            if (i === myLists.length - 1) {
              arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
            }
          });
          setActions(arr);
        } else {
          const arr = [{ id: -1, action: 'add', name: 'Bookmark to All Lists', img: unsaveIcon }];
          myLists.forEach((l: IMyList, i: number) => {
            if (l.colleges.find((c) => c.id !== (clickedCollege ? clickedCollege.id : 0))) {
              arr.push({ id: l.id, action: 'add', name: `Bookmark to ${l.name}`, img: unsaveIcon });
            }
            if (i === myLists.length - 1) {
              arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
            }
          });
          setActions(arr);
        }
      }, 1000);
    } else if (isInList) {
      const arr = [
        { id: -1, action: 'delete', name: 'Delete from All Lists', img: deleteIcon },
        { id: selectedList.id, action: 'delete', name: `Delete from ${selectedList.name}`, img: deleteIcon },
      ];
      myLists.forEach((l: IMyList, i: number) => {
        if (l.id !== selectedList.id) {
          arr.push({ id: l.id, action: 'add', name: `Add to ${l.name}`, img: addToListIcon });
        }
        if (i === myLists.length - 1) {
          arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
        }
      });
      setActions(arr);
    } else if (selectedList?.colleges?.some((el) => el?.college?.id === clickedCollege?.id)) {
      const arr = [
        { id: -1, action: 'delete', name: 'Delete from All Lists', img: deleteIcon },
        { id: selectedList.id, action: 'delete', name: `Delete from ${selectedList.name}`, img: deleteIcon },
      ];
      myLists.forEach((l: IMyList, i: number) => {
        if (l.id !== selectedList.id) {
          arr.push({ id: l.id, action: 'add', name: `Add to ${l.name}`, img: addToListIcon });
        }
        if (i === myLists.length - 1) {
          arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
        }
      });
      setActions(arr);
    } else {
      const arr = [{ id: -1, action: 'add', name: 'Bookmark to All Lists', img: unsaveIcon }];
      myLists.forEach((l: IMyList, i: number) => {
        if (l.colleges.find((c) => c.id !== (clickedCollege.id ? clickedCollege.id : 0))) {
          arr.push({ id: l.id, action: 'add', name: `Bookmark to ${l.name}`, img: unsaveIcon });
        }
        if (i === myLists.length - 1) {
          arr.push({ id: -2, action: 'add', name: 'Create New List', img: createListIcon });
        }
      });
      setActions(arr);
    }
  }, [isInList, myLists, selectedList, clickedCollege]);
  
  const saveRemoveCollege = (listId: number, action: string) => {
    if ((user?.role === 'admin' || !user?.subscription) && listId === -2) {
      setAllowedCreateList(false);
      open();
      return;
    }
    setCollegeSelectedListActive(null);
    if (listId >= 0) {
      if (action === 'delete') {
        dispatch(
          myListsAction.removeCollegeFromMyList({
            userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
            listId,
            collegeId: clickedCollege.id,
          }),
        );
        const newList = {
          ...selectedList,
          colleges: selectedList.colleges.filter((l: any) => l.collegeId !== clickedCollege.id),
        };
        dispatch(myListsAction.setSelectedList(newList));

        if (listId === selectedList.id) {
          setIsInList(false);
        }
      } else {
        dispatch(
          myListsAction.addCollegesToMyList({
            userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
            listName: myLists.find((l: IMyList) => l.id === listId).name,
            collegesIds: [clickedCollege.id],
          }),
        );
        if (listId === selectedList.id) {
          const newCollegeList = [...selectedList.colleges, { clickedCollege, collegeId: clickedCollege.id, listId }];
          const newList = { ...selectedList, colleges: newCollegeList };
          dispatch(myListsAction.setSelectedList(newList));
          setIsInList(true);
        }
      }
    } else if (action === 'delete') {
      setIsInList(false);
      dispatch(
        myListsAction.removeCollegeFromAllLists({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
          collegeId: clickedCollege.id,
        }),
      );
      const newList = {
        ...selectedList,
        colleges: selectedList.colleges.filter((l: any) => l.collegeId !== clickedCollege.id),
      };
      dispatch(myListsAction.setSelectedList(newList));
    } else if (listId === -2) {
      setIsAddListModal(true);
      open();
    } else {
      dispatch(
        myListsAction.addCollegeToAllLists({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
          collegeId: clickedCollege.id,
        }),
      );
      setIsInList(true);
    }

    if (showRecommendations) {
      const indexEl = readyPaginatedColleges.indexOf(clickedCollege);
      readyPaginatedColleges.splice(indexEl, 1);
    }
  };

  const convertToEmbedLink = (youtubeLink): string => {
    let videoId = youtubeLink.split('v=')[1];
    const ampersandPosition = videoId.indexOf('&');

    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition);
    }

    return `https://www.youtube.com/embed/${videoId}`;
  };

  const closeYoutube = () => {
    setIsOpenYoutubeModal(false);
    setLinkYoutubeModal('');
    close();
  };

  useEffect(() => {
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    dispatch(myListsAction.setSharedList(null));
    if (id && !myLists.find((l: IMyList) => l.id === +id)) {
      dispatch(collegesAction.setRecommendedColleges([]));
      if (sharedList) {
        setLists([sharedList]);
      }
      window.history.replaceState(null, '', `/my-lists/${id}`);
    } else {
      const recommendationCategory = localStorage.getItem('recommendationCategory');
      dispatch(
        collegesAction.getRecommendedColleges({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
          category: recommendationCategory ? JSON.parse(recommendationCategory).value : 'location',
        }),
      );
      myListService.endpoint_get_my_lists(adminNavigatedUser ? adminNavigatedUser.id : user?.id).then(({ data }) => {
        dispatch(myListsAction.setMyLists(data as IMyList[]));
        const updatedList = data.filter((dataList: IMyList) => dataList?.id === selectedList?.id);
        dispatch(myListsAction.setSelectedList(updatedList[0]));
        if (!selectedList || selectedList.userId !== (adminNavigatedUser ? adminNavigatedUser.id : user?.id)) {
          dispatch(myListsAction.setSelectedList(data[0]));
        }
      });
    }
    localStorage.setItem('paginationPage', '1');
  }, []);

  useEffect(() => {
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    if (sharedList) {
      setLists([sharedList]);
    } else if (selectedList) {
      setLists([{ id: -1, name: 'Add New List' }, ...myLists]);
      if (myLists.find((l) => l.id === selectedList.id)) {
        window.history.replaceState(null, '', `/my-lists/${selectedList.id}`);
      } else {
        dispatch(myListsAction.setSelectedList(myLists[0]));
        window.history.replaceState(null, '', `/my-lists/${myLists[0].id}`);
      }
    } else {
      setLists([{ id: -1, name: 'Add New List' }, ...myLists]);
      if (myLists[0]) {
        window.history.replaceState(null, '', `/my-lists/${myLists[0].id}`);
      }
    }
  }, [myLists]);
  useEffect(() => {
    const width: number = window.innerWidth;
    if (showRecommendations && paginationLimit) {
      setMaxPaginationPage(
        selectedList?.colleges?.length && recommendations
          ? Math.ceil((selectedList.colleges.length + recommendations) / paginationLimit)
          : recommendations
          ? Math.ceil(recommendations / paginationLimit)
          : selectedList?.colleges?.length
          ? Math.ceil(selectedList.colleges.length / paginationLimit)
          : 1,
      );
      if (width <= 520) {
        setPaginationLimit(1);
        setLockerPage(6);
      } else if (width > 520 && width <= 900) {
        setPaginationLimit(2);
        setLockerPage(4);
      } else {
        setPaginationLimit(3);
        setLockerPage(3);
      }
    } else if (paginationLimit) {
      setSortBy('default');
      const maxP = selectedList?.colleges?.length ? Math.ceil(selectedList.colleges.length / paginationLimit) : 1;
      setMaxPaginationPage(maxP);
      if (paginationPage && paginationPage > maxP) {
        setInputPagination(maxP);
        setPaginationPage(maxP);
      }
      if (width <= 520) {
        setPaginationLimit(!showRecommendations && selectedList?.colleges?.length < 1 ? 0 : 1);
        setLockerPage(6);
      } else if (width > 520 && width <= 900) {
        setPaginationLimit(
          !showRecommendations && selectedList?.colleges?.length < 2 ? selectedList?.colleges?.length : 2,
        );
        setLockerPage(4);
      } else {
        setPaginationLimit(
          !showRecommendations && selectedList?.colleges?.length < 3 ? selectedList?.colleges?.length : 3,
        );
        setLockerPage(3);
      }
    }
  }, [selectedList, recommendations, showRecommendations, paginationLimit, isVisibleTablePagination]);
  useEffect(() => {
    const width: number = window.innerWidth;
    const page = localStorage.getItem('paginationPage');
    // setIsLoading(true);
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    if (page) {
      setPaginationPage(+page);
      setPaginationSkip((+page - 1) * (width <= 520 ? 1 : width > 520 && width <= 900 ? 2 : 3));
      setInputPagination(+page);
    } else {
      setPaginationPage(1);
      setPaginationSkip(3);
      setInputPagination(1);
    }
    if (selectedList) {
      dispatch(notesAction.getListNotes(selectedList.id));
      window.history.replaceState(null, '', `/my-lists/${selectedList.id}`);
      dispatch(myListsAction.getRecommendations(selectedList.id));
    } else {
      window.history.replaceState(null, '', `/my-lists`);
    }
  }, [selectedList]);

  const selectList = (newList: IMyList) => {
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });

    setIsMyListsSelectActive(false);
    rotateArrow('lists', false);
    if (newList.id === -1) {
      if (adminNavigatedUser) {
        if (adminNavigatedUser.subscription) {
          setIsAddList(true);
          open();
        } else if (!adminNavigatedUser.subscription && myLists.length < 1) {
          setIsAddList(true);
          open();
        } else {
          setAllowedCreateList(false);
          open();
        }
        return;
      }
      if (user?.subscription || user?.role === 'admin') {
        setIsAddList(true);
        open();
      } else if (!user?.subscription && myLists.length < 1) {
        setIsAddList(true);
        open();
      } else {
        setAllowedCreateList(false);
        open();
      }
    } else {
      dispatch(myListsAction.setSelectedList(newList));
      window.history.replaceState(null, '', `/my-lists/${newList.id}`);
      if (paginationRef.current) {
        (paginationRef.current as HTMLDivElement).scrollIntoView({
          behavior: 'smooth',
          block: 'start',
        });
      }
    }

    setTimeout(() => {
      // setIsLoadingSelectedList(false);
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      });
    }, 5000);
  };
  const selectCollegeInformation = (value: any) => {
    setSelectedInformation(value);
    selectFlag(value.value);
    setChooseInfo(false);
  };
  const selectFlag = (value: string) => {
    if (value === 'profile') {
      handleProfile(true);
      handleAdmissions(false);
      handleScholaships(false);
      handleFinance(false);
    }
    if (value === 'admissions') {
      handleProfile(false);
      handleAdmissions(true);
      handleScholaships(false);
      handleFinance(false);
    }
    if (value === 'scholarships') {
      handleProfile(false);
      handleAdmissions(false);
      handleScholaships(true);
      handleFinance(false);
    }
    if (value === 'finance') {
      handleProfile(false);
      handleAdmissions(false);
      handleScholaships(false);
      handleFinance(true);
    }
    rotateArrow('choose', !chooseInfo);
  };

  const openSocialMediaPopup = (): void => {
    open();
    setIsOpenSocialMedias(!isOpenSocialMedias);
  };
  const createList = async (listName: string) => {
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    await myListService.endpoint_create_my_list({
      userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
      listName,
    });
    const { data } = await myListService.endpoint_get_my_lists(adminNavigatedUser ? adminNavigatedUser.id : user?.id);
    dispatch(myListsAction.setMyLists(data));
    dispatch(myListsAction.setSelectedList(data[data.length - 1]));
    close();
  };
  const resizeArrows = () => {
    const width: number = window.innerWidth;
    if (width <= 520) {
      setPaginationLimit(!showRecommendations && selectedList?.colleges?.length < 1 ? 0 : 1);
    } else if (width > 520 && width <= 900) {
      setPaginationLimit(
        !showRecommendations && selectedList?.colleges?.length < 2 ? selectedList?.colleges?.length : 2,
      );
    } else {
      setPaginationLimit(
        !showRecommendations && selectedList?.colleges?.length < 3 ? selectedList?.colleges?.length : 3,
      );
    }
    const wrapper = document.getElementById('list-container');
    const arrow = document.getElementById('next-college');
    const arrowLeft = document.getElementById('next-college-left');
    if (wrapper && arrow && arrowLeft) {
      arrow.style.height = `${wrapper.clientHeight}px`;
      arrowLeft.style.height = `${wrapper.clientHeight}px`;
    }
  };

  window.onresize = resizeArrows;

  useEffect(() => {
    resizeArrows();
  }, [profile, finance, admissions, scholaships, selectedList, myListLoading]);

  const scroll = (direction: string) => {
    if (paginationPage && maxPaginationPage && direction === 'right' && paginationPage < maxPaginationPage) {
      localStorage.setItem('paginationPage', `${paginationPage + 1}`);
      setPaginationPage(paginationPage + 1);
      if (inputPagination) {
        setInputPagination(inputPagination + 1);
      }
    } else if (paginationPage && direction === 'left' && paginationPage > 1) {
      localStorage.setItem('paginationPage', `${paginationPage - 1}`);
      setPaginationPage(paginationPage - 1);
      if (inputPagination) {
        setInputPagination(inputPagination - 1);
      }
    }
  };

  useEffect(() => {
    if (!isSortedInFronted) return;
    if (!paginatedColleges?.colleges) return;
    const copyColleges = [...paginatedColleges.colleges];
    switch (sortBy?.property) {
      case 'region':
        if (isDescSort) {
          copyColleges.sort((college1, college2) => {
            return college1.state.region.name.localeCompare(college2.state.region.name);
          });
          setReadyPaginatedColleges(copyColleges);
          return;
        }
        copyColleges.sort((college1, college2) => {
          return college2.state.region.name.localeCompare(college1.state.region.name);
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'offers':
        if (isDescSort) {
          copyColleges.sort((college1, college2) => {
            return +college1.pcStudentsReceivingMeritAid - +college2.pcStudentsReceivingMeritAid;
          });
          setReadyPaginatedColleges(copyColleges);
          return;
        }
        copyColleges.sort((college1, college2) => {
          return +college2.pcStudentsReceivingMeritAid - +college1.pcStudentsReceivingMeritAid;
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'state':
        if (isDescSort) {
          copyColleges.sort((college1, college2) => {
            return college1[sortBy.property].name.localeCompare(college2[sortBy.property].name);
          });
          setReadyPaginatedColleges(copyColleges);
          return;
        }
        copyColleges.sort((college1, college2) => {
          return college2[sortBy.property].name.localeCompare(college1[sortBy.property].name);
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'name':
        if (isDescSort) {
          copyColleges.sort((college1, college2) => {
            return college1[sortBy.property].localeCompare(college2[sortBy.property]);
          });
          setReadyPaginatedColleges(copyColleges);
          return;
        }
        copyColleges.sort((college1, college2) => {
          return college2[sortBy.property].localeCompare(college1[sortBy.property]);
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'isPrivate':
      case 'usServiceSchool':
      case 'honorsCollege':
      case 'honorsProgram':
      case 'coop':
      case 'frshGpaWeighted':
      case 'testScoreOptional':
      case 'cssProfileRequired':
      case 'nonCustodialCssRequired':
      case 'hasImage':
        if (isDescSort) {
          copyColleges.sort((college1, college2) => {
            return college1[sortBy.property] - college2[sortBy.property];
          });
          setReadyPaginatedColleges(copyColleges);
          return;
        }
        copyColleges.sort((college1, college2) => {
          return college2[sortBy.property] - college1[sortBy.property];
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'admissionsUrl':
      case 'urlVirtualTour':
      case 'testScoreRequirementType':
      case 'scholarshipsUrl':
      case 'urlFullScholarship':
      case 'urlAddressPriceCalc':
      case 'mainFunctionType':
        copyColleges.sort((college1, college2) => {
          if (isDescSort) {
            if (college1[sortBy.property] === null) {
              return 1;
            }

            if (college2[sortBy.property] === null) {
              return -1;
            }
            return college2[sortBy.property].localeCompare(college1[sortBy.property]);
          }
          if (college1[sortBy.property] === null) {
            return -1;
          }

          if (college2[sortBy.property] === null) {
            return 1;
          }
          return college1[sortBy.property].localeCompare(college2[sortBy.property]);
        });
        setReadyPaginatedColleges(copyColleges);
        // sliceCollegeByPaginationSettings(copyColleges);
        break;
      case 'enWhiteNonhispanic':
      case 'enBlackNonhispanic':
      case 'enHispanicEthnicity':
      case 'enAsianNonhispanic':
      case 'enNonresAlien':
      case 'avgFreshmenGpa':
      case 'avgFreshmenGpaBelow3P':
      case 'avgFreshmenGpa4P':
      case 'avgFreshmenGpa3P':
      case 'avgFreshmenGpa2P':
      case 'avgFreshmenGpa1P':
      case 'satAvg':
      case 'actAvg':
      case 'admissionsRate':
      case 'edAdmitRate':
      case 'eaAdmitRate':
      case 'rdAdmitRate':
      case 'edFilledRate':
      case 'admitYield':
      case 'applicationFee':
      case 'avgNeedBasedAward':
      case 'pcStudentsWithoutNeed':
      case 'pcStudentsReceivingMeritAid':
      case 'avgMeritAidAward':
      case 'avgNetPriceAfterAid':
      case 'gradsAnyLoanP':
      case 'gradsAnyLoanAvgAmt':
      case 'pcGraduatingWithLoans':
      case 'avgLoanAmount':
      case 'parentPlusP':
      case 'parentPlusAvgAmt':
      case 'endowmentPerStudent':
      case 'endowmentTotal':
      case 'avgPriceTo30000':
      case 'avgPriceFrom30001To48000':
      case 'avgPriceFrom48001To75000':
      case 'avgPriceFrom75001To110000':
      case 'avgPriceOver110000':
        copyColleges.sort((college1, college2) => {
          if (college1[sortBy?.property] === null) {
            return 1;
          }
          if (college2[sortBy?.property] === null) {
            return -1;
          }
          if (isDescSort) {
            return Number(college2[sortBy.property]) - Number(college1[sortBy.property]);
          }
          return Number(college1[sortBy.property]) - Number(college2[sortBy.property]);
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'coaInState':
      case 'coaOutState':
      case 'coaPrivate':
      case 'privateNetCost':
      case 'inStateNetCost':
      case 'outStateNetCost':
      case 'coaPublicWithPrivate':
      case 'fullTimeUndergraduates':
      case 'pcUndergraduateWomen':
      case 'pcUndergraduateMen':
      case 'studentToFaculty':
      case 'pcReturningForSophomore':
      case 'fourYearGraduationRate':
      case 'sat75thPc':
      case 'act75thPc':
      case 'avgPcNeedMet':
        copyColleges.sort((college1, college2) => {
          if (college1[sortBy?.property] === null) {
            return 1;
          }
          if (college2[sortBy?.property] === null) {
            return -1;
          }
          if (isDescSort) {
            return Number(college2[sortBy.property]) - Number(college1[sortBy.property]);
          }
          return Number(college1[sortBy.property]) - Number(college2[sortBy.property]);
        });
        setReadyPaginatedColleges(copyColleges);
        break;
      case 'edDeadline':
      case 'eaDeadline':
      case 'ed2Deadline':
      case 'regDecisionDeadline':
      case 'finAidDeadline':
      case 'createdAt':
      case 'updatedAt':
        // eslint-disable-next-line no-case-declarations
        const collegesWithoutDate = copyColleges.filter((el) => el[sortBy.property] === null);
        // eslint-disable-next-line no-case-declarations
        const collegesWithDate = copyColleges.filter((el) => el[sortBy.property] !== null);
        collegesWithDate.sort((college1, college2) => {
          if (isDescSort) {
            return moment(college1[sortBy.property]).isAfter(college2[sortBy.property]) ? 1 : -1;
          }
          return moment(college2[sortBy.property]).isAfter(college1[sortBy.property]) ? 1 : -1;
        });
        if (isDescSort) {
          const sortedColleges: ICollege[] = collegesWithDate.concat(collegesWithoutDate);
          setReadyPaginatedColleges(sortedColleges);
        } else {
          const sortedColleges: ICollege[] = collegesWithDate.concat(collegesWithoutDate);
          setReadyPaginatedColleges(sortedColleges);
        }

        break;
      default:
        setReadyPaginatedColleges(copyColleges);
        break;
    }
    setIsSortedInFrontend(false);
  }, [isDescSort, sortBy, pagePaginationListView]);

  useEffect(() => {
    if (paginationLimit && paginationPage) {
      setPaginationSkip((paginationPage - 1) * paginationLimit);
    }
  }, [paginationPage]);
  useEffect(() => {
    if (selectedInformation === chooseInformation[4]) {
      setHeightSlider(185);
      return;
    }
    if (selectedInformation === chooseInformation[3] || selectedInformation === chooseInformation[1]) {
      setHeightSlider(124);
      return;
    }
    if (selectedInformation === chooseInformation[2]) {
      setHeightSlider(166.5);
      return;
    }
    setHeightSlider(100.5);
  }, [selectedInformation]);

  useEffect(() => {
    // setIsLoading(true);
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    if (isVisibleTablePagination && sortBy !== 'default') return;
    if (isSortedInFronted) return;

    if (!isVisibleTablePagination) {
      if (selectedList && paginationLimit) {
        dispatch(
          myListsAction.getPaginatedList({
            listId: selectedList.id,
            limit: paginationLimit,
            skip: paginationSkip,
            sortBy: sortBy?.value,
            order: isDescSort ? 'desc' : 'asc',
            showRecommendations,
          }),
        );
      }
    } else if (selectedList) {
      dispatch(
        myListsAction.getPaginatedList({
          listId: selectedList.id,
          limit: 2500,
          skip: 0,
          sortBy: sortBy?.property,
          order: isDescSort ? 'desc' : 'asc',
          showRecommendations,
        }),
      );
    }
  }, [
    paginationLimit,
    paginationSkip,
    isDescSort,
    sortBy,
    selectedList,
    showRecommendations,
    isVisibleTablePagination,
  ]);

  const selectSort = (item: any) => {
    if (adminNavigatedUser) {
      if (isSortByInputActive && !adminNavigatedUser.subscription && item.id > 7) {
        open();
        return;
      }
      setIsSortByInputActive(false);
      setSortBy(item);
      setIsDescSort(false);
      rotateArrow('sort', false);
      return;
    }

    if (isSortByInputActive && user?.role !== 'admin' && !user?.subscription && item.id > 7) {
      open();
      return;
    }
    setIsSortByInputActive(false);
    setSortBy(item);
    setIsDescSort(false);
    rotateArrow('sort', false);
  };
  const rotateArrow = (id: string, isDown: boolean) => {
    const arrow = document.getElementById(id);
    if (isDown) {
      arrow?.classList.add('look-up');
      arrow?.classList.remove('look-down');
    } else {
      arrow?.classList.remove('look-up');
      arrow?.classList.add('look-down');
    }
  };
  const down = (e: any) => {
    setSwipeCoord({ start: e.changedTouches[0].clientX });
  };
  const up = (e: any) => {
    setSwipeCoord({ ...swipeCoord, end: e.changedTouches[0].clientX });
  };

  useEffect(() => {
    if (swipeCoord?.end) {
      const isRight = swipeCoord.start - swipeCoord.end > 60;
      const isLeft = swipeCoord.end - swipeCoord.start > 60;
      if (isRight) {
        scroll('right');
      }
      if (isLeft) {
        scroll('left');
      }
    }
  }, [swipeCoord]);

  const deleteMyList = async () => {
    // setIsLoading(true);
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });

    try {
      if (selectedList) {
        await myListService.endpoint_remove_my_list({ listId: selectedList.id });
        const { data } = await myListService.endpoint_get_my_lists(
          adminNavigatedUser ? adminNavigatedUser.id : user?.id,
        );
        dispatch(myListsAction.setMyLists(data));
        dispatch(myListsAction.setSelectedList(data[0]));
        dispatch(myListsAction.setPaginatedList(null));
        setReadyPaginatedColleges([]);
        setIsDelete(false);
        loadingAction.updateLoadingStatus({
          myListLoading: false,
        });
        setModalErrorMessage('');
      }
    } catch (e) {
      console.log(e);
      setModalErrorMessage('An error occurred while deleting');
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      });
    }
  };
  const deleteAllCollegesFromMyList = () => {
    loadingAction.updateLoadingStatus({
      myListLoading: true,
    });
    setDeleteAllFromList(false);
    if (selectedList) {
      dispatch(
        myListsAction.deleteAllCollegesFromMyList({
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
          listId: selectedList.id,
        }),
      );
    }
  };

  useEffect(() => {
    const handleClickOutsideBookMark = (event): void => {
      const bookMarkRef: any = selectedBookMarkRef.current;
      if (bookMarkRef && !bookMarkRef.contains(event.target)) {
        setIsOpenBookMark(false);
      }
    };

    document.addEventListener('click', handleClickOutsideBookMark);

    return () => {
      document.removeEventListener('click', handleClickOutsideBookMark);
    };
  }, []);
  const getOffers = async (id: number) => {
    if (id) {
      dispatch(
        collegesAction.getFullCollegeById({
          collegeId: +id,
          userId: adminNavigatedUser ? adminNavigatedUser.id : user?.id,
        }),
      );
    }
    setShowOffers(true);
  };

  useEffect(() => {
    if (showOffers) {
      open();
    }
  }, [showOffers]);

  const clickedCollegeFun = () => {
    setIsOpenBookMark(false);
  };

  const formatterNumberWithDollars = (value: number): string => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
    });
    return formatter.format(value).replace(/\.00$/, '');
  };

  const formatterDateFormatMonthDay = (value: string): string => {
    const parsedDate: moment.Moment = moment(value, 'MMM-YY');
    return parsedDate.format('MMM DD');
  };

  useEffect(() => {
    loadingAction.updateLoadingStatus({
      myListLoading: false,
    });
  }, [paginatedColleges?.colleges?.length]);

  useEffect(() => {
    if (selectedCollegesForCsv.length > 0 && selectedColumnsForCsv.length > 0) {
      const copySelectedColumnsForCsv = [
        { nameThirdBlock: 'Name', nameColumn: 'name' },
        { nameThirdBlock: 'Type', nameColumn: 'isPrivate' },
        { nameThirdBlock: 'State', nameColumn: 'state' },
        { nameThirdBlock: 'Region', nameColumn: 'region' },
        { nameThirdBlock: 'Merit Offered', nameColumn: 'offers' },
        { nameThirdBlock: 'Avg GPA', nameColumn: 'avgFreshmenGpa' },
        { nameThirdBlock: 'Co-Op', nameColumn: 'coop' },
        ...selectedColumnsForCsv,
      ];
      const readyColleges = [
        selectedCollegesForCsv.map((college) => {
          return copySelectedColumnsForCsv.map((column) => {
            const readyColumn = column.nameColumn;
            if (readyColumn === 'isPrivate') {
              if (college.isPrivate === true) return 'Private';
              return 'Public';
            }
            if (typeof college[readyColumn] === 'boolean') {
              return college[readyColumn] === true ? 'Yes' : 'No';
            }
            switch (readyColumn) {
              case 'state':
                return `${college.state.name}`;
              case 'region':
                return `${college.state.region.name}`;
              case 'offers':
                if (+college.pcStudentsReceivingMeritAid > 1.0) return 'Yes';
                return 'No';
              // values with dollars
              case 'avgNeedBasedAward':
              case 'coaPrivate':
              case 'coaInState':
              case 'coaOutState':
              case 'parentPlusAvgAmt':
              case 'avgLoanAmount':
              case 'gradsAnyLoanAvgAmt':
              case 'endowmentPerStudent':
              case 'avgPriceTo30000':
              case 'avgPriceFrom30001To48000':
              case 'avgPriceFrom48001To75000':
              case 'avgPriceFrom75001To110000':
              case 'avgPriceOver110000':
              case 'avgNetPriceAfterAid':
              case 'avgMeritAidAward':
              case 'applicationFee':
                if (college[readyColumn] === null) return '-';
                return formatterNumberWithDollars(college[readyColumn]);
              // values with percent
              case 'parentPlusP':
              case 'pcStudentsWithoutNeed':
              case 'pcStudentsReceivingMeritAid':
              case 'gradsAnyLoanP':
              case 'avgPcNeedMet':
              case 'avgFreshmenGpa1P':
              case 'avgFreshmenGpa2P':
              case 'avgFreshmenGpa3P':
              case 'avgFreshmenGpa4P':
              case 'avgFreshmenGpaBelow3P':
              case 'admissionsRate':
              case 'edAdmitRate':
              case 'eaAdmitRate':
              case 'rdAdmitRate':
              case 'edFilledRate':
              case 'admitYield':
              case 'enNonresAlien':
              case 'enAsianNonhispanic':
              case 'enHispanicEthnicity':
              case 'enBlackNonhispanic':
              case 'enWhiteNonhispanic':
              case 'fourYearGraduationRate':
              case 'pcReturningForSophomore':
              case 'pcUndergraduateMen':
              case 'pcUndergraduateWomen':
                if (college[readyColumn] === null || college[readyColumn] === undefined) return '-';
                return `${Number(college[readyColumn]).toFixed(0)} %`;
              case 'edDeadline':
              case 'eaDeadline':
              case 'ed2Deadline':
              case 'regDecisionDeadline':
              case 'finAidDeadline':
              case 'createdAt':
              case 'updatedAt':
                if (college[readyColumn] === null) return '-';
                return formatterDateFormatMonthDay(college[readyColumn]);
              default:
                return `${college[readyColumn]}`;
            }
          });
        }),
      ];

      const readyColumns = copySelectedColumnsForCsv.map((column) => {
        return column.nameThirdBlock;
      });

      const resultColleges = readyColleges[0].map((college) => {
        return college.map((collegeElement) => {
          if (collegeElement === 'null') {
            collegeElement = '-';
          }
          return collegeElement;
        });
      });
      setCSVData({
        columns: readyColumns,
        values: resultColleges,
      });
    }
  }, [selectedCollegesForCsv, selectedColumnsForCsv]);

  function generateCSVContent(data: CSVData): string {
    const rows = data.values.map((row) => {
      const joinedRow = row.map((column) => {
        const hasComma = column.includes(',');

        if (hasComma) {
          // Wrap column in quotes
          return `"${column}"`;
        }
        return column;
      });

      return joinedRow.join(',');
    });

    const quotedColumns = data.columns.map((column) => {
      // Wrap column name in quotes
      return `"${column}"`;
    });

    const content = `${quotedColumns.join(',')}\n${rows.join('\n')}`;
    const lineBreak = getLineBreakCharacter();
    return content.replace(/\n/g, lineBreak);
  }

  function getLineBreakCharacter(): string {
    const isWindows = navigator && /Win/i.test(navigator.userAgent);
    return isWindows ? '\r\n' : '\n';
  }
  function downloadCSVFile(data: CSVData, filename: string) {
    const csvContent = generateCSVContent(data);
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
    saveAs(blob, filename);
  }

  const handleDownload = () => {
    const date = moment();
    const formattedDate = date.format('MM-DD-YYYY');
    const replacedDate = formattedDate.replace(/-/g, '_');
    const filename = `Road2College_${replacedDate}.csv`;
    if (csvData) {
      downloadCSVFile(csvData, filename);
      const body = {
        email: user?.email,
        countDownloadCsv: (user?.countDownloadCsv as number) - 1,
      };

      dispatch(userAction.updateUser({ user: body }));
      historyDownload();
    }
  };

  const historyDownload = async () => {
    let readyColumnNames = '';
    let readyCollegeIds = '';

    selectedCollegesForCsv.map((selectedCollege) => {
      readyCollegeIds += String(`${selectedCollege.id},`);
      return selectedCollege;
    });

    selectedColumnsForCsv.map((selectedColumns) => {
      readyColumnNames += String(`${selectedColumns.nameColumn},`);
      return selectedColumns;
    });

    const historyDownload: IHistoryDownloadCreate = {
      userId: user?.id as number,
      collegeIds: readyCollegeIds.slice(0, readyCollegeIds.length - 1),
      columnNames: readyColumnNames.slice(0, readyColumnNames.length - 1),
    };
    await historyDownloadService.endpoint_create_history_download(historyDownload);
  };
  const clearValuesAfterDownload = () => {
    dispatch(selectedCollegesAndColumnsAction.setSelectedCollegesForCsv([]));
    dispatch(selectedCollegesAndColumnsAction.setSelectedColumnsForCsv([]));
  };

  const handleClickDownloadCsv = () => {
    if (adminNavigatedUser) {
      open();
      setIsOpenDownloadModal(!isOpenDownloadModal);
      return;
    }
    const subscriptionUser = user?.subscription;
    if (user?.role === 'admin' && selectedList && selectedList.colleges.length === 0) {
      setIsOpenDownloadWithoutCollegesInList(true);
      open();
      return;
    }
    if (isTabletOrMobile && !subscriptionUser) {
      setIsOpenDownloadCSVInMobileFree(true);
      setIsOpenDownloadCSVInPCFree(false);
      open();
      return;
    }
    if (isTabletOrMobile && subscriptionUser) {
      setIsOpenDownloadCSVInMobile(true);
      open();
      return;
    }
    if (!subscriptionUser) {
      setIsOpenDownloadCSVInPCFree(true);
      open();
      return;
    }
    if (subscriptionUser && user?.countDownloadCsv === 0) {
      setIsOpenDownloadCSVEarlyWithCountZero(true);
      open();
      return;
    }
    if (user?.countDownloadCsv === 0) return;
    if (selectedList && selectedList.colleges.length === 0) {
      setIsOpenDownloadWithoutCollegesInList(true);
      open();
      return;
    }

    open();
    setIsOpenDownloadModal(!isOpenDownloadModal);
  };

  const handleScroll = (ref) => {
    if (ref && ref.current) {
      ref.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  // function for sending data in backend and do list as shared
  const handleClickShareList = async () => {
    if (selectedCollegesForSharing.length === 0 || !list) return;

    const object = selectedCollegesForSharing.find((obj) => obj.id === list.id);
    if (!object) return;
    const idsColleges: number[] = object.colleges.map((college) => college.id);

    const sharedList: ISharedList = {
      userId: user?.id as number,
      userName: user?.name as string,
      listId: selectedList.id,
      listName: selectedList.name,
      collegeIds: idsColleges.join(','),
    };

    const responseAboutSharedList = await sharedListsService.endpoint_create_sharedList(sharedList);
    if (
      responseAboutSharedList.data === 'SHARED_LIST_WAS_UPDATED' ||
      responseAboutSharedList.data === 'SHARED_LIST_CREATED'
    ) {
      setBasketLists([]);
      dispatch(selectedCollegesForSharingAction.setSelectedCollegesForSharing([]));
    }
  };

  const deleteObjectInSelectedSharedListById = (id: number) => {
    // Create a new array that filters out the object with the specified id. Don't need do copy, filter return new array
    const updatedArray: ISharedListBeforeSending[] = selectedCollegesForSharing.filter(
      (obj: ISharedListBeforeSending) => {
        return obj.id !== id;
      },
    );

    // Update the state with the modified array
    dispatch(selectedCollegesForSharingAction.setSelectedCollegesForSharing([...updatedArray]));
  };

  const handleChangeListName = async (newName: string): Promise<void> => {
    try {
      if (newName) {
        setIsFetchingDataFromModal(true);
        await myListService.endpoint_update_list_name({ listId: selectedList.id, newName });
        const { data } = await myListService.endpoint_get_my_lists(user.id as number);
        dispatch(myListsAction.setMyLists(data));
        dispatch(myListsAction.setSelectedList(data[data.findIndex((list) => list.id === selectedList.id)]));
        setIsEditListNameModalOpen(false);
        setIsFetchingDataFromModal(false);

        close();
        setModalErrorMessage('');
      }
    } catch (e) {
      console.log(e);

      setModalErrorMessage('An error occurred while renaming');
      setIsFetchingDataFromModal(false);
    }
  };

  return (
    <div
      onClick={() => {
        rotateArrow('lists', false);
        setIsMyListsSelectActive(false);
        setCollegeSelectedListActive(null);
        rotateArrow('sort', false);
        setIsSortByInputActive(false);
      }}
      className="my-list"
    >
      {adminNavigatedUser ? (
        <>
          <header className="my-list-header">
            <nav className="my-list-navigation">
              <NavLink to={'/home'} id="discover">
                Discover
              </NavLink>
              <span id="list" className="selected-nav">
                My Lists
              </span>
            </nav>
            <div
              className="input-select-list"
              onClick={(e) => {
                e.stopPropagation();
                setIsMyListsSelectActive(!isMyListsSelectActive);
                rotateArrow('lists', !isMyListsSelectActive);
                setIsAddNoteModal(false);
              }}
            >
              <img src={arrow} alt="arrow" id="lists" />
              <div className="custom-input">
                {selectedList?.colleges ? (
                  <>
                    {selectedList.name} <span>{selectedList?.colleges?.length}</span>
                  </>
                ) : (
                  'Create your first list'
                )}
              </div>
              {isMyListsSelectActive && lists && (
                <Autocomplete
                  isBudgetItems={false}
                  ref={childRef}
                  onclick={selectList}
                  icon={listIcon}
                  filteredItem={lists}
                  lockedAfterItems={user?.subscription || user?.role === 'admin' ? 0 : 1}
                />
              )}
            </div>
            <hr className="line" />
          </header>
          <div className="hr-my-list" />
          <div className="list-colleges-wrapper">
            <div className="list-colleges-wrapper-container">
              <div />
              <div className="switcher-pagination-my-list">
                <span
                  className={`switcher-pagination-my-list-text ${isVisibleTablePagination ? 'active' : ''}`}
                  onClick={() => {
                    if (isTabletOrMobile) {
                      setIsVisibleTablePagination(false);
                      setIsOpenListViewInMobile(true);
                      open();
                      return;
                    }
                    setIsVisibleTablePagination(true);
                  }}
                >
                  Table View
                </span>
                <span
                  className={`switcher-pagination-my-list-text switcher-pagination-my-list-text-second ${
                    !isVisibleTablePagination ? 'active' : ''
                  }`}
                  onClick={() => {
                    setIsVisibleTablePagination(false);
                  }}
                >
                  Thumbnail View
                </span>
              </div>
              <button
                className={
                  (!user?.subscription || user?.countDownloadCsv === 0 || isTabletOrMobile) && user?.role !== 'admin'
                    ? 'list-colleges-wrapper-btn-download list-colleges-wrapper-btn-download-disabled'
                    : 'list-colleges-wrapper-btn-download'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickDownloadCsv();
                }}
              >
                Download as CSV
              </button>
            </div>
            <header className="my-list-body-header">
              <div className="list-details">
                {myLists[0] && (
                  <>
                    <div className="list-name">
                      {selectedList?.name}
                      <div className="edit-list-button">
                        <img
                          ref={editListBtnRef}
                          onClick={() => {
                            setIsEditListMenuOpen(!isEditListMenuOpen);
                          }}
                          src={editIcon}
                          alt="Edit list button"
                        />
                        <div ref={editListMenuRef} className={`dropdown-menu ${isEditListMenuOpen ? 'active' : ''}`}>
                          <p
                            onClick={() => {
                              setIsEditListMenuOpen(false);
                              setIsEditListNameModalOpen(true);
                              open();
                            }}
                            className="dropdown-menu__option"
                          >
                            Edit Name
                          </p>
                          <p
                            onClick={() => {
                              setIsEditListMenuOpen(false);
                              setIsDelete(true);
                              open();
                            }}
                            className="dropdown-menu__option"
                          >
                            Delete List
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-list-body-header__secondary-header">
                      <p>Share List</p>
                      <div
                        className={`share-icon share-icon--${isOpenSocialMedias ? 'open' : 'close'}`}
                        ref={socialMediaRef}
                        onClick={() => openSocialMediaPopup()}
                        data-tip={'Share'}
                      >
                        <img src={shareIcon} alt="share" className="share-icon-img" />
                      </div>
                    </div>
                    <div className="my-list-body-header__secondary-header">
                      {selectedList?.colleges?.length} Colleges{' '}
                      <img
                        onClick={() => {
                          setDeleteAllFromList(true);
                          open();
                        }}
                        src={unsaveIcon}
                        alt="unsave"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="list-header-buttons">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    if (!adminNavigatedUser.subscription) {
                      setShowFeatureModal(true);
                      open();
                      return;
                    }
                    setIsAddNoteModalToList(true);
                    // setIsAddNoteModal(true);
                    open();
                  }}
                  className={
                    adminNavigatedUser.subscription
                      ? 'primary-button primary-button-list add-notes-btn'
                      : 'primary-button primary-button-list add-notes-btn primary-button-list-first'
                  }
                >
                  {!adminNavigatedUser.subscription && <img src={lockWhite} alt="lock" />}
                  <img src={notesIcon} alt="notesIcon" />
                  Add Notes to List
                </a>
                <NavLink to={'/home'} className="primary-button primary-button-list">
                  <img src={plus} alt="plus" />
                  Add Colleges to List
                </NavLink>
              </div>
            </header>
            <div
              className="input-select input-select-choose-information"
              onClick={(e) => {
                e.stopPropagation();
                rotateArrow('choose', !chooseInfo);
                setChooseInfo(!chooseInfo);
              }}
            >
              <img src={arrow} alt="arrow" id="choose" />
              <input
                readOnly
                type="text"
                value={selectedInformation.name}
                onBlur={() => setChooseInfo(false)}
                onKeyDown={(evt) => {
                  if (childRef.current) childRef.current.onKeyDown(evt);
                }}
              />
              {chooseInfo && (
                <Autocomplete
                  isBudgetItems={false}
                  ref={childRef}
                  onclick={selectCollegeInformation}
                  filteredItem={chooseInformation.filter((x) => x.id !== 0)}
                />
              )}
            </div>
            <div className="pagination-navigation navigation-start">
              <div className="my-list-checkboxes">
                <div
                  className="my-list-checkbox-container w-custom-1"
                  onClick={() => {
                    handleProfile(!profile);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="profile"
                      checked={profile}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">School Profile</p>
                  </div>
                </div>
                <div
                  className="my-list-checkbox-container w-custom-1"
                  onClick={() => {
                    handleAdmissions(!admissions);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="admissions"
                      checked={admissions}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Admissions</p>
                  </div>
                </div>

                <div
                  className="my-list-checkbox-container w-custom-2"
                  onClick={() => {
                    handleScholaships(!scholaships);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="scholarships"
                      checked={scholaships}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Aid/Merit Scholarships</p>
                  </div>
                </div>
                <div
                  className="my-list-checkbox-container w-custom-2"
                  onClick={() => {
                    handleFinance(!finance);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="finance"
                      checked={finance}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Financial Information</p>
                  </div>
                </div>
              </div>
              <div className="sorting-block">
                <div className="sort-header">
                  <p>Sort By</p>
                </div>
                <div
                  className="sort-input sort-input-my-list"
                  onClick={(e) => {
                    e.stopPropagation();
                    rotateArrow('sort', !isSortByInputActive);
                  }}
                >
                  <img src={arrow} alt="arrow" id="sort" />
                  <input
                    className="sort-input-choose-sort-by"
                    type="text"
                    readOnly
                    placeholder="Choose to sort by"
                    value={sortBy?.name || ''}
                    onBlur={() => {
                      if (adminNavigatedUser.subscription) {
                        setIsSortByInputActive(false);
                      }
                      rotateArrow('sort', false);
                    }}
                    onKeyDown={(evt) => {
                      if (childRef.current) childRef.current.onKeyDown(evt);
                    }}
                    onClick={() => {
                      setIsSortByInputActive(!isSortByInputActive);
                    }}
                  />
                  <img
                    src={sortBy === 'default' ? sort : isDescSort ? sortGreyBlack : sortBlackGrey}
                    alt="sort"
                    onClick={() => {
                      if (adminNavigatedUser.subscription) {
                        setIsDescSort((prev) => !prev);
                        setIsSortByInputActive(!isSortByInputActive);
                      }
                    }}
                  />
                  {isSortByInputActive && sortCategories && (
                    <Autocomplete
                      classN="sort-input-my-list-autocomplete"
                      isBudgetItems={false}
                      ref={childRef}
                      onclick={selectSort}
                      filteredItem={sortCategories}
                    />
                  )}
                </div>
              </div>
            </div>
            <div />
          </div>
          {!isVisibleTablePagination && <hr className="line" />}
          {paginatedColleges?.colleges?.length > 0 && !isVisibleTablePagination && (
            <div className="my-list-page-cont pagination-navigation">
              <span className="my-list-page">Page</span>
              <input
                type="text"
                value={inputPagination || ''}
                onChange={({ target }) => {
                  if (typeof +target.value === 'number' && !Number.isNaN(+target.value)) {
                    setInputPagination(+target.value);
                  }
                }}
                onBlur={() => {
                  if (inputPagination && inputPagination < 1) {
                    setPaginationPage(1);
                    setInputPagination(1);
                  } else if (inputPagination && maxPaginationPage && inputPagination > maxPaginationPage) {
                    setPaginationPage(maxPaginationPage);
                    setInputPagination(maxPaginationPage);
                  } else {
                    setPaginationPage(inputPagination || 1);
                    localStorage.setItem('paginationPage', `${inputPagination}`);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (document.activeElement as HTMLInputElement).blur();
                  }
                }}
              />
              <span className="my-list-page">of {maxPaginationPage}</span>
              <button onClick={() => scroll('left')}>{'<'}</button>
              <button onClick={() => scroll('right')}>{'>'}</button>
            </div>
          )}
          <div
            ref={paginationRef}
            className={
              isVisibleTablePagination ? 'list-colleges-cont' : 'list-colleges-cont list-colleges-cont-with-list'
            }
          >
            {isVisibleTablePagination && readyPaginatedColleges.length === 0 ? (
              <div className={'no-list-container table-pagination-no-result'}>
                <h6>This list looks a little lonely</h6>
                <img src={noList} alt={'no-list'} />
                <NavLink to={'/home'}>Discover Colleges</NavLink>
              </div>
            ) : isVisibleTablePagination ? (
              <MyListTablePagination
                isAdmin={true}
                sortBy={sortBy}
                onHandleSortBy={setSortBy}
                onHandleModalContent={setModalContent}
                onHandleIsSortedInFrontend={setIsSortedInFrontend}
                onHandleIsDescSort={setIsDescSort}
                onHandleIsOpenMeritScholarship={setIsOpenMeritScholarship}
                onHandleIsOpenLockedPopupOffers={setIsOpenLockedPopupOffers}
                onHandleShowOffers={setShowOffers}
                onHandleIsOpenYoutubeModal={setIsOpenYoutubeModal}
                onHandleClickedCollege={setClickedCollege}
                onHandleLinkYoutubeModal={setLinkYoutubeModal}
                onHandleIsAddNoteModal={setIsAddNoteModal}
                onHandleIsOpenBookMark={setIsOpenBookMark}
                openModal={open}
                readyPaginatedColleges={readyPaginatedColleges}
                isDescSort={isDescSort}
                isOpenMeritScholarship={isOpenMeritScholarship}
                isOpenLockedPopupOffers={isOpenLockedPopupOffers}
                isOpenBookMark={isOpenBookMark}
                actions={actions}
                clickedCollege={clickedCollege}
                notes={notes}
                userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
                showOffers={showOffers}
                getOffers={getOffers}
                saveRemoveCollege={saveRemoveCollege}
                selectedBookMarkRef={selectedBookMarkRef}
                clickedCollegeFun={clickedCollegeFun}
                profileRef={profileRef}
                admissionsRef={admissionsRef}
                scholashipsRef={scholashipsRef}
                financialRef={financialRef}
              />
            ) : myListLoading ? (
              <div className="no-colleges-block-my-list">
                <CustomLoader size="big" />
              </div>
            ) : (
              <div>
                {paginationSkip !== 0 && (
                  <div
                    onClick={() => scroll('left')}
                    id="next-college-left"
                    className={'button-next-college left'}
                    style={{ height: `${heightSlider}%` }}
                  >
                    <img className="left-arrow" src={arrowWhite} alt="arrow left" />
                  </div>
                )}
                {paginationPage !== maxPaginationPage && paginatedColleges?.colleges?.length !== 0 && (
                  <div
                    onClick={() => scroll('right')}
                    id="next-college"
                    className={'button-next-college'}
                    style={{ height: `${heightSlider}%` }}
                  >
                    <img src={arrowWhite} alt="arrow right" />
                  </div>
                )}
                <div
                  id="list-container"
                  className={`selected-list ${
                    showRecommendations || paginatedColleges?.colleges?.length > 0 ? '' : 'selected-list-without-rec'
                  } ${
                    !adminNavigatedUser.subscription && paginationPage && paginationPage >= lockedPage
                      ? 'not-allowed-list'
                      : ''
                  }`}
                  onTouchStart={(e) => down(e)}
                  onTouchEnd={(e) => up(e)}
                >
                  {!!paginatedColleges?.colleges?.length &&
                    paginatedColleges?.colleges.map((c: any) => (
                      <MyListCollege
                        key={c.id}
                        college={c}
                        isAdmission={admissions}
                        isFinance={finance}
                        isProfile={profile}
                        isSchoolarship={scholaships}
                        createList={createList}
                        isListsActionsActive={c.id === collegeSelectedListActive}
                        setIsListsActionsActive={(id: number | null) => setCollegeSelectedListActive(id)}
                        allowed={false}
                        userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
                        notes={notes}
                      />
                    ))}
                  {paginatedColleges?.colleges?.length === 0 && !showRecommendations && (
                    <div className={'no-list-container'}>
                      <h6>This list looks a little lonely</h6>
                      <img src={noList} alt={'no-list'} />
                      <NavLink to={'/home'}>Discover Colleges</NavLink>
                    </div>
                  )}
                  {!adminNavigatedUser.subscription && paginationPage && paginationPage >= lockedPage && (
                    <div className="not-allowed-modal">
                      <InfoLockedModal
                        close={close}
                        textForTitle={'Information Locked'}
                        textForDescription={
                          'View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to premium features and data.'
                        }
                        textSecondBtn={'Unlock'}
                        withClose={false}
                      />

                      <div className="not-allowed-modal-bg" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!myListLoading && !isVisibleTablePagination && (
              <div className="delete-list">
                {paginatedColleges?.colleges?.length > 0 && (
                  <>
                    <span
                      onClick={() => {
                        setIsDelete(true);
                        open();
                      }}
                      className="selected-nav selected-nav-delete-bottom"
                    >
                      Delete List
                    </span>
                    <div className="pagination-navigation pagination-navigation-bottom">
                      <span>Page</span>
                      <input
                        type="text"
                        value={inputPagination || ''}
                        onChange={({ target }) => {
                          if (typeof +target.value === 'number' && !Number.isNaN(+target.value)) {
                            setInputPagination(+target.value);
                          }
                        }}
                        onBlur={() => {
                          if (inputPagination && inputPagination < 1) {
                            setPaginationPage(1);
                            setInputPagination(1);
                          } else if (inputPagination && maxPaginationPage && inputPagination > maxPaginationPage) {
                            setPaginationPage(maxPaginationPage);
                            setInputPagination(maxPaginationPage);
                          } else {
                            setPaginationPage(inputPagination || 1);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            (document.activeElement as HTMLInputElement).blur();
                          }
                        }}
                      />
                      <span>of {maxPaginationPage}</span>
                      <button onClick={() => scroll('left')}>{'<'}</button>
                      <button onClick={() => scroll('right')}>{'>'}</button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <hr className="line" />
          <Modal>
            {!adminNavigatedUser.subscription && isSortByInputActive && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setIsSortByInputActive(!isSortByInputActive)}
                title={'Premium Feature'}
              />
            )}
            {isAddList && (
              <AddListModal
                createList={createList}
                close={() => {
                  setIsAddList(false);
                  close();
                }}
              />
            )}
            {isEditListNameModalOpen && (
              <SingleInputModal
                title="Rename the List"
                inputPlaceholder="Type New Name"
                positiveButtonText="Confirm"
                negativeButtonText="Cancel"
                close={() => {
                  setIsEditListNameModalOpen(false);
                  setModalErrorMessage('');
                  close();
                }}
                onConfirmHandler={handleChangeListName}
                previousInputValue={selectedList?.name}
                isLoading={isFetchingDataFromModal}
                err={modalErrorMessage}
                hideError={() => setModalErrorMessage('')}
                maxLength={255}
              />
            )}
            {showFeatureModal && !adminNavigatedUser.subscription && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setShowFeatureModal(!showFeatureModal)}
                title={'Premium Feature'}
              />
            )}
            {isAddNoteModal && clickedCollege && clickedCollege.id !== -100 && (
              <AddNotesModal
                close={() => {
                  setIsAddNoteModal(false);
                  setClickedCollege(defaultCollege);
                  close();
                }}
                propNotes={clickedCollege.notes}
                collegeId={clickedCollege.id}
                collegeName={clickedCollege.name}
                userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
              />
            )}
            {isAddNoteModalToList && (
              <AddNotesModal
                close={() => {
                  setIsAddNoteModalToList(false);
                  setClickedCollege(defaultCollege);
                  close();
                }}
                propNotes={clickedCollege.notes}
                listId={selectedList.id}
              />
            )}
            {isDelete && (
              <DeleteListModal
                close={() => {
                  setIsDelete(false);
                  setModalErrorMessage('');
                  close();
                }}
                listName={selectedList?.name}
                deleteList={deleteMyList}
                err={modalErrorMessage}
              />
            )}
            {deleteAllFromList && (
              <DeleteAllCollegesFromListModal
                close={() => {
                  setDeleteAllFromList(false);
                  close();
                }}
                listName={selectedList?.name}
                deleteAll={deleteAllCollegesFromMyList}
              />
            )}
            {!allowShowRecs && (
              <UniversalModal
                close={() => {
                  setAllowShowRecs(true);
                  close();
                }}
                textForTitle={'Looking for Recommendations?'}
                textForDescription={
                  'Curated college recommendations available are based on your list!\n' +
                  '\n' +
                  'To unlock these recommendations and get unlimited use of Insights- please upgrade your account.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            )}
            {!allowedCreateList && (
              <UniversalModal
                close={() => {
                  setAllowedCreateList(true);
                  close();
                }}
                textForTitle={'Upgrade Account'}
                textForDescription={
                  'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            )}
            {isOpenYoutubeModal && (
              <YoutubeModal close={closeYoutube} videoUrl={convertToEmbedLink(linkYoutubeModal || '')} />
            )}
            {showOffers && (
              <OffersModal
                close={() => {
                  setShowOffers(false);
                  close();
                }}
              />
            )}
            {isOpenMeritScholarship && (
              <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
            )}

            {isAddNoteModal && !adminNavigatedUser.subscription && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                title={'Premium Feature'}
                anyAction={() => setIsAddNoteModal(false)}
              />
            )}
            {isAddListModal && !adminNavigatedUser?.subscription && (
              <AddListModal createList={createList} close={close} />
            )}
            {isAddListModal && clickedCollege.id !== -100 && (
              <AddListModal
                close={() => {
                  close();
                  setIsAddListModal(false);
                }}
                needAddToList={true}
                additionalValue={clickedCollege.id}
              />
            )}
            {isOpenLockedPopupOffers && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To View This Data'}
                anyAction={() => setIsOpenLockedPopupOffers(false)}
                title={'Premium Data'}
              />
            )}
            {isOpenDownloadModal && stepDownloading <= 2 && (
              <DownloadListModal
                close={() => {
                  close();
                  setIsOpenDownloadModal(false);
                  setStepDownloading(1);
                }}
                setStepDownloading={setStepDownloading}
                stepDownloading={stepDownloading}
                setSelectedCollegeForCsv={setSelectedCollegeForCsv}
                selectedCollegeForCsv={selectedCollegeForCsv}
                setSelectedColumnForCsv={setSelectedColumnForCsv}
                selectedColumnForCsv={selectedColumnForCsv}
                firstStep={firstStep}
                secondStep={secondStep}
                columnsForDownloading={columnsForDownloading}
                clearValuesAfterDownload={clearValuesAfterDownload}
              />
            )}
            {isOpenDownloadModal && stepDownloading >= 3 && (
              <ConfirmDownloadListModal
                close={() => {
                  close();
                  setIsOpenDownloadModal(false);
                  setStepDownloading(1);
                }}
                setStepDownloading={setStepDownloading}
                stepDownloading={stepDownloading}
                onHandleDownload={handleDownload}
                clearValuesAfterDownload={clearValuesAfterDownload}
                countDownloadCsv={user?.countDownloadCsv as number}
              />
            )}
            {isOpenListViewInMobile && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenListViewInMobile(false);
                }}
                textForDescription={'Login to your account on desktop to access a list view.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInMobile && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInMobile(false);
                }}
                textForDescription={'Login to your account on desktop to complete your download.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInMobileFree && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInMobileFree(false);
                }}
                textForDescription={'Upgrade your account to download schools and data.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadWithoutCollegesInList && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadWithoutCollegesInList(false);
                }}
                textForDescription={'You do not have colleges in the list. Please, add them to create your download.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInPCFree && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInPCFree(false);
                }}
                textForDescription={
                  'This feature is only available for paid subscribers. Upgrade now to download one list of up to 20 schools and up to 10 unique categories.'
                }
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVEarlyWithCountZero && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVEarlyWithCountZero(false);
                }}
                textForDescription={
                  'You have used your download. Please email support@road2college if you’d like to purchase additional downloads'
                }
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenEmailModal && (
              <EmailModal
                close={() => {
                  close();
                  setIsOpenEmailModal(false);
                }}
                onHandleOpenModalAfterSendEmail={handleOpenModalAfterSendEmail}
                openSocialMediaModal={openSocialMediaPopup}
                onHandleClickShareList={handleClickShareList}
              />
            )}
            {isOpenModalAfterSendEmail && (
              <ScholarshipModal
                close={() => {
                  close();
                  setIsOpenModalAfterSendEmail(false);
                }}
                title="Congratulations!"
                message="Email has been sent"
                textButton="Ok"
              />
            )}
            {isOpenSocialMedias && (
              <SocialMediaModal
                closeModal={() => {
                  close();
                  setIsOpenSocialMedias(false);
                }}
                openEmailModal={handleClickOpenEmailModal}
                onHandleClickShareList={handleClickShareList}
                onHandleSetBasketLists={setBasketLists}
                onHandleSetList={setList}
                deleteObjectInSelectedSharedListById={deleteObjectInSelectedSharedListById}
                basketLists={basketLists}
                list={list}
              />
            )}
            {isOpenSaveListModal && (
              <SaveListModal
                close={() => {
                  close();
                  setIsOpenSaveListModal(false);
                }}
                onHandleSetIsOpenAddNewListModal={handleSetIsOpenAddNewListModal}
              />
            )}
            {isOpenAddNewListModal && (
              <AddNewListModal
                close={() => {
                  close();
                  setIsOpenAddNewListModal(false);
                }}
              />
            )}
          </Modal>
          <div
            className="fb-login-button"
            data-width="200"
            data-size=""
            data-button-type=""
            data-layout=""
            data-auto-logout-link="true"
            data-use-continue-as="false"
          />
        </>
      ) : (
        <>
          <header className="my-list-header">
            <nav className="my-list-navigation">
              <NavLink to={'/home'} id="discover">
                Discover
              </NavLink>
              <span id="list" className="selected-nav">
                My Lists
              </span>
            </nav>
            <div
              className="input-select-list"
              onClick={(e) => {
                e.stopPropagation();
                setIsMyListsSelectActive(!isMyListsSelectActive);
                rotateArrow('lists', !isMyListsSelectActive);
                setIsAddNoteModal(false);
              }}
            >
              <img src={arrow} alt="arrow" id="lists" />
              <div className="custom-input">
                {selectedList?.colleges ? (
                  <>
                    {selectedList.name} <span>{selectedList?.colleges?.length}</span>
                  </>
                ) : (
                  'Create your first list'
                )}
              </div>
              {isMyListsSelectActive && lists && (
                <Autocomplete
                  isBudgetItems={false}
                  ref={childRef}
                  onclick={selectList}
                  icon={listIcon}
                  filteredItem={lists}
                  lockedAfterItems={user?.subscription || user?.role === 'admin' ? 0 : 1}
                />
              )}
            </div>
            <hr className="line" />
          </header>
          <div className="hr-my-list" />
          <div className="list-colleges-wrapper">
            <div className="list-colleges-wrapper-container">
              <div className="fake-element" />
              <div className="switcher-pagination-my-list">
                <span
                  className={`switcher-pagination-my-list-text ${isVisibleTablePagination ? 'active' : ''}`}
                  onClick={() => {
                    if (isTabletOrMobile) {
                      setIsVisibleTablePagination(false);
                      setIsOpenListViewInMobile(true);
                      open();
                      return;
                    }
                    setIsVisibleTablePagination(true);
                  }}
                >
                  Table View
                </span>
                <span
                  className={`switcher-pagination-my-list-text switcher-pagination-my-list-text-second ${
                    !isVisibleTablePagination ? 'active' : ''
                  }`}
                  onClick={() => {
                    setIsVisibleTablePagination(false);
                  }}
                >
                  Thumbnail View
                </span>
              </div>
              <button
                className={
                  (!user?.subscription || user?.countDownloadCsv === 0 || isTabletOrMobile) && user?.role !== 'admin'
                    ? 'list-colleges-wrapper-btn-download list-colleges-wrapper-btn-download-disabled'
                    : 'list-colleges-wrapper-btn-download'
                }
                onClick={(e) => {
                  e.stopPropagation();
                  handleClickDownloadCsv();
                }}
              >
                Download as CSV
              </button>
            </div>
            <header className="my-list-body-header">
              <div className="list-details">
                {myLists[0] && (
                  <>
                    <div className="list-name">
                      {selectedList?.name}
                      <div className="edit-list-button">
                        <img
                          ref={editListBtnRef}
                          onClick={() => {
                            setIsEditListMenuOpen(!isEditListMenuOpen);
                          }}
                          src={editIcon}
                          alt="Edit list button"
                        />
                        <div ref={editListMenuRef} className={`dropdown-menu ${isEditListMenuOpen ? 'active' : ''}`}>
                          <p
                            onClick={() => {
                              setIsEditListMenuOpen(false);
                              setIsEditListNameModalOpen(true);
                              open();
                            }}
                            className="dropdown-menu__option"
                          >
                            Edit Name
                          </p>
                          <p
                            onClick={() => {
                              setIsEditListMenuOpen(false);
                              setIsDelete(true);
                              open();
                            }}
                            className="dropdown-menu__option"
                          >
                            Delete List
                          </p>
                        </div>
                      </div>
                    </div>
                    <div className="my-list-body-header__secondary-header">
                      <p>Share List</p>
                      <div
                        className={`share-icon share-icon--${isOpenSocialMedias ? 'open' : 'close'}`}
                        ref={socialMediaRef}
                        onClick={() => openSocialMediaPopup()}
                        data-tip={'Share'}
                      >
                        <img src={shareIcon} alt="share" className="share-icon-img" />
                      </div>
                    </div>
                    <div className="my-list-body-header__secondary-header">
                      {selectedList?.colleges?.length} Colleges{' '}
                      <img
                        onClick={() => {
                          setDeleteAllFromList(true);
                          open();
                        }}
                        src={unsaveIcon}
                        alt="unsave"
                        className="unsave-icon-img"
                      />
                    </div>
                  </>
                )}
              </div>
              <div className="list-header-buttons">
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    if (!user?.subscription && user?.role !== 'admin') {
                      setShowFeatureModal(true);
                      open();
                      return;
                    }
                    setIsAddNoteModalToList(true);
                    open();
                  }}
                  className={
                    user?.subscription && user?.role !== 'admin'
                      ? 'primary-button primary-button-list add-notes-btn'
                      : 'primary-button primary-button-list add-notes-btn primary-button-list-first'
                  }
                >
                  {!user?.subscription && user?.role !== 'admin' && <img src={lockWhite} alt="lock" />}
                  <img src={notesIcon} alt="notes" />
                  Add Notes to List
                </a>
                <NavLink to={'/home'} className="primary-button primary-button-list">
                  <img src={plus} alt="plus" /> Add Colleges to List
                </NavLink>
              </div>
            </header>
            <div
              className="input-select input-select-choose-information"
              onClick={(e) => {
                e.stopPropagation();
                rotateArrow('choose', !chooseInfo);
                setChooseInfo(!chooseInfo);
              }}
            >
              <img src={arrow} alt="arrow" id="choose" />
              <input
                readOnly
                type="text"
                value={selectedInformation.name}
                onBlur={() => setChooseInfo(false)}
                onKeyDown={(evt) => {
                  if (childRef.current) childRef.current.onKeyDown(evt);
                }}
              />
              {chooseInfo && (
                <Autocomplete
                  isBudgetItems={false}
                  ref={childRef}
                  onclick={selectCollegeInformation}
                  filteredItem={chooseInformation.filter((x) => x.id !== 0)}
                />
              )}
            </div>
            <div className="pagination-navigation navigation-start">
              <div className="my-list-checkboxes">
                <div
                  className="my-list-checkbox-container w-custom-1"
                  onClick={() => {
                    handleProfile(!profile);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="profile"
                      checked={profile}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">School Profile</p>
                  </div>
                </div>
                <div
                  className="my-list-checkbox-container w-custom-1"
                  onClick={() => {
                    handleAdmissions(!admissions);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="admissions"
                      checked={admissions}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Admissions</p>
                  </div>
                </div>
                <div
                  className="my-list-checkbox-container w-custom-2"
                  onClick={() => {
                    handleScholaships(!scholaships);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="scholarships"
                      checked={scholaships}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Aid/Merit Scholarships</p>
                  </div>
                </div>
                <div
                  className="my-list-checkbox-container w-custom-2"
                  onClick={() => {
                    handleFinance(!finance);
                  }}
                >
                  <div className="flex-custom-center">
                    <input
                      readOnly
                      type="checkbox"
                      name="finance"
                      checked={finance}
                      className="my-list-checkboxes-input"
                    />
                    <p className="my-list-checkmark" />
                    <p className="checkmark-title">Financial Information</p>
                  </div>
                </div>
              </div>
              <div className="sorting-block">
                <div className="sort-header">
                  <p>Sort By</p>
                </div>
                <div
                  className="sort-input sort-input-my-list"
                  onClick={(e) => {
                    e.stopPropagation();
                    rotateArrow('sort', !isSortByInputActive);
                    setIsSortedInFrontend(true);
                  }}
                >
                  <img
                    src={arrow}
                    alt="arrow"
                    id="sort"
                    onClick={() => {
                      setIsSortByInputActive(!isSortByInputActive);
                    }}
                  />
                  <input
                    className="sort-input-choose-sort-by"
                    type="text"
                    readOnly
                    placeholder="Choose to sort by"
                    value={sortBy?.name || ''}
                    onBlur={() => {
                      if (user?.subscription || user?.role === 'admin') {
                        setIsSortByInputActive(false);
                      }
                      rotateArrow('sort', false);
                    }}
                    onKeyDown={(evt) => {
                      if (childRef.current) childRef.current.onKeyDown(evt);
                    }}
                    onClick={() => {
                      setIsSortByInputActive(!isSortByInputActive);
                    }}
                  />
                  <img
                    src={sortBy === 'default' ? sort : isDescSort ? sortGreyBlack : sortBlackGrey}
                    alt="sort sort-list"
                    onClick={() => {
                      if (user?.subscription || user?.role === 'admin') {
                        setIsDescSort((prev) => !prev);
                      }
                    }}
                  />
                  {isSortByInputActive && sortCategories && (
                    <Autocomplete
                      classN="sort-input-my-list-autocomplete"
                      isBudgetItems={false}
                      ref={childRef}
                      onclick={selectSort}
                      filteredItem={sortCategories}
                    />
                  )}
                </div>
              </div>
            </div>
            <div />
          </div>
          {!isVisibleTablePagination && <hr className="line" />}
          {paginatedColleges?.colleges?.length > 0 && !isVisibleTablePagination && (
            <div className="my-list-page-cont pagination-navigation">
              <span className="my-list-page">Page</span>
              <input
                type="text"
                value={inputPagination || ''}
                onChange={({ target }) => {
                  if (typeof +target.value === 'number' && !Number.isNaN(+target.value)) {
                    setInputPagination(+target.value);
                  }
                }}
                onBlur={() => {
                  if (inputPagination && inputPagination < 1) {
                    setPaginationPage(1);
                    setInputPagination(1);
                  } else if (inputPagination && maxPaginationPage && inputPagination > maxPaginationPage) {
                    setPaginationPage(maxPaginationPage);
                    setInputPagination(maxPaginationPage);
                  } else {
                    setPaginationPage(inputPagination || 1);
                    localStorage.setItem('paginationPage', `${inputPagination}`);
                  }
                }}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    (document.activeElement as HTMLInputElement).blur();
                  }
                }}
              />
              <span className="my-list-page">of {maxPaginationPage}</span>
              <button onClick={() => scroll('left')}>{'<'}</button>
              <button onClick={() => scroll('right')}>{'>'}</button>
            </div>
          )}
          <div
            ref={paginationRef}
            className={
              isVisibleTablePagination ? 'list-colleges-cont' : 'list-colleges-cont list-colleges-cont-with-list'
            }
          >
            {isVisibleTablePagination && readyPaginatedColleges.length === 0 ? (
              <div className={'no-list-container table-pagination-no-result'}>
                <h6>This list looks a little lonely</h6>
                <img src={noList} alt={'no-list'} />
                <NavLink to={'/home'}>Discover Colleges</NavLink>
              </div>
            ) : isVisibleTablePagination && !myListLoading ? (
              <MyListTablePagination
                isAdmin={false}
                sortBy={sortBy}
                onHandleSortBy={setSortBy}
                onHandleModalContent={setModalContent}
                onHandleIsSortedInFrontend={setIsSortedInFrontend}
                onHandleIsDescSort={setIsDescSort}
                onHandleIsOpenLockedPopupOffers={setIsOpenLockedPopupOffers}
                onHandleIsOpenMeritScholarship={setIsOpenMeritScholarship}
                onHandleShowOffers={setShowOffers}
                onHandleIsOpenYoutubeModal={setIsOpenYoutubeModal}
                onHandleClickedCollege={setClickedCollege}
                onHandleLinkYoutubeModal={setLinkYoutubeModal}
                onHandleIsAddNoteModal={setIsAddNoteModal}
                onHandleIsOpenBookMark={setIsOpenBookMark}
                openModal={open}
                readyPaginatedColleges={readyPaginatedColleges}
                isDescSort={isDescSort}
                isOpenMeritScholarship={isOpenMeritScholarship}
                isOpenLockedPopupOffers={isOpenLockedPopupOffers}
                isOpenBookMark={isOpenBookMark}
                actions={actions}
                clickedCollege={clickedCollege}
                notes={notes}
                userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
                showOffers={showOffers}
                getOffers={getOffers}
                saveRemoveCollege={saveRemoveCollege}
                selectedBookMarkRef={selectedBookMarkRef}
                clickedCollegeFun={clickedCollegeFun}
                profileRef={profileRef}
                admissionsRef={admissionsRef}
                scholashipsRef={scholashipsRef}
                financialRef={financialRef}
              />
            ) : myListLoading ? (
              <div className="no-colleges-block-my-list">
                <CustomLoader size="big" />
              </div>
            ) : (
              <div>
                {paginationSkip !== 0 && (
                  <div
                    onClick={() => scroll('left')}
                    id="next-college-left"
                    className={'button-next-college left'}
                    style={{ height: `${heightSlider}%` }}
                  >
                    <img className="left-arrow" src={arrowWhite} alt="arrow left" />
                  </div>
                )}
                {paginationPage !== maxPaginationPage && paginatedColleges?.colleges?.length !== 0 && (
                  <div
                    onClick={() => scroll('right')}
                    id="next-college"
                    className={'button-next-college'}
                    style={{ height: `${heightSlider}%` }}
                  >
                    <img src={arrowWhite} alt="arrow right" />
                  </div>
                )}
                <div
                  id="list-container"
                  className={`selected-list ${
                    showRecommendations || paginatedColleges?.colleges?.length > 0 ? '' : 'selected-list-without-rec'
                  } ${
                    !user?.subscription && user?.role !== 'admin' && paginationPage && paginationPage >= lockedPage
                      ? 'not-allowed-list'
                      : ''
                  }`}
                  onTouchStart={(e) => down(e)}
                  onTouchEnd={(e) => up(e)}
                >
                  {!!paginatedColleges?.colleges?.length &&
                    paginatedColleges?.colleges.map((c: any) => (
                      <MyListCollege
                        key={c.id}
                        college={c}
                        isAdmission={admissions}
                        isFinance={finance}
                        isProfile={profile}
                        isSchoolarship={scholaships}
                        createList={createList}
                        isListsActionsActive={c.id === collegeSelectedListActive}
                        setIsListsActionsActive={(id: number | null) => setCollegeSelectedListActive(id)}
                        allowed={false}
                        userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
                        notes={notes}
                      />
                    ))}
                  {paginatedColleges?.colleges?.length === 0 && !showRecommendations && (
                    <div className={'no-list-container'}>
                      <h6>This list looks a little lonely</h6>
                      <img src={noList} alt={'no-list'} />
                      <NavLink to={'/home'}>Discover Colleges</NavLink>
                    </div>
                  )}
                  {!user?.subscription && user?.role !== 'admin' && paginationPage && paginationPage >= lockedPage && (
                    <div className="not-allowed-modal">
                      <InfoLockedModal
                        close={close}
                        textForTitle={'Information Locked'}
                        textForDescription={
                          'View More Colleges from Your List! Upgrade to get unlimited searches, unlimited lists, and access to premium features and data.'
                        }
                        textSecondBtn={'Unlock'}
                        withClose={false}
                      />

                      <div className="not-allowed-modal-bg" />
                    </div>
                  )}
                </div>
              </div>
            )}
            {!myListLoading && !isVisibleTablePagination && (
              <div className="delete-list">
                {paginatedColleges?.colleges?.length > 0 && (
                  <>
                    <span
                      onClick={() => {
                        setIsDelete(true);
                        open();
                      }}
                      className="selected-nav selected-nav-delete-bottom"
                    >
                      Delete List
                    </span>
                    <div className="pagination-navigation pagination-navigation-bottom">
                      <span>Page</span>
                      <input
                        type="text"
                        value={inputPagination || ''}
                        onChange={({ target }) => {
                          if (typeof +target.value === 'number' && !Number.isNaN(+target.value)) {
                            setInputPagination(+target.value);
                          }
                        }}
                        onBlur={() => {
                          if (inputPagination && inputPagination < 1) {
                            setPaginationPage(1);
                            setInputPagination(1);
                          } else if (inputPagination && maxPaginationPage && inputPagination > maxPaginationPage) {
                            setPaginationPage(maxPaginationPage);
                            setInputPagination(maxPaginationPage);
                          } else {
                            setPaginationPage(inputPagination || 1);
                          }
                        }}
                        onKeyDown={(e) => {
                          if (e.key === 'Enter') {
                            (document.activeElement as HTMLInputElement).blur();
                          }
                        }}
                      />
                      <span>of {maxPaginationPage}</span>
                      <button onClick={() => scroll('left')}>{'<'}</button>
                      <button onClick={() => scroll('right')}>{'>'}</button>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>
          <hr className="line" />
          <Modal>
            {!user?.subscription && user?.role !== 'admin' && isSortByInputActive && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setIsSortByInputActive(!isSortByInputActive)}
                title={'Premium Feature'}
              />
            )}
            {isAddList && (
              <AddListModal
                createList={createList}
                close={() => {
                  setIsAddList(false);
                  close();
                }}
              />
            )}
            {isEditListNameModalOpen && (
              <SingleInputModal
                title="Rename the List"
                inputPlaceholder="Type New Name"
                positiveButtonText="Confirm"
                negativeButtonText="Cancel"
                close={() => {
                  setIsEditListNameModalOpen(false);
                  setModalErrorMessage('');
                  close();
                }}
                onConfirmHandler={handleChangeListName}
                previousInputValue={selectedList?.name}
                isLoading={isFetchingDataFromModal}
                err={modalErrorMessage}
                hideError={() => setModalErrorMessage('')}
                maxLength={255}
              />
            )}
            {showFeatureModal && !user?.subscription && user?.role !== 'admin' && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                anyAction={() => setShowFeatureModal(!showFeatureModal)}
                title={'Premium Feature'}
              />
            )}
            {isAddNoteModal && clickedCollege && clickedCollege.id !== -100 && (
              <AddNotesModal
                close={() => {
                  setIsAddNoteModal(false);
                  setClickedCollege(defaultCollege);
                  close();
                }}
                propNotes={clickedCollege.notes}
                collegeId={clickedCollege.id}
                collegeName={clickedCollege.name}
                userId={adminNavigatedUser ? adminNavigatedUser.id : user?.id}
              />
            )}
            {isAddNoteModalToList && (
              <AddNotesModal
                close={() => {
                  setIsAddNoteModalToList(false);
                  setClickedCollege(defaultCollege);
                  close();
                }}
                propNotes={clickedCollege.notes}
                listId={selectedList.id}
              />
            )}
            {isDelete && (
              <DeleteListModal
                close={() => {
                  setIsDelete(false);
                  setModalErrorMessage('');
                  close();
                }}
                listName={selectedList?.name}
                deleteList={deleteMyList}
                err={modalErrorMessage}
              />
            )}
            {deleteAllFromList && (
              <DeleteAllCollegesFromListModal
                close={() => {
                  setDeleteAllFromList(false);
                  close();
                }}
                listName={selectedList?.name}
                deleteAll={deleteAllCollegesFromMyList}
              />
            )}
            {!allowShowRecs && (
              <UniversalModal
                close={() => {
                  setAllowShowRecs(true);
                  close();
                }}
                textForTitle={'Looking for Recommendations?'}
                textForDescription={
                  'Curated college recommendations available are based on your list!\n' +
                  '\n' +
                  'To unlock these recommendations and get unlimited use of Insights- please upgrade your account.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            )}
            {!allowedCreateList && (
              <UniversalModal
                close={() => {
                  setAllowedCreateList(true);
                  close();
                }}
                textForTitle={'Upgrade Account'}
                textForDescription={
                  'Your current free plan allows a maximum of 1 lists. Upgrade to create unlimited lists.'
                }
                textSecondBtn={'Upgrade Now'}
              />
            )}
            {isOpenYoutubeModal && (
              <YoutubeModal close={closeYoutube} videoUrl={convertToEmbedLink(linkYoutubeModal || '')} />
            )}
            {showOffers && (
              <OffersModal
                close={() => {
                  setShowOffers(false);
                  close();
                }}
              />
            )}
            {isOpenMeritScholarship && (
              <ScholarshipModal close={close} message={modalContent.message} title={modalContent.title} />
            )}

            {isAddNoteModal && !user?.subscription && user?.role !== 'admin' && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To Use This Feature'}
                title={'Premium Feature'}
                anyAction={() => setIsAddNoteModal(false)}
              />
            )}
            {isAddListModal && !user?.subscription && user?.role !== 'admin' && (
              <AddListModal createList={createList} close={close} />
            )}
            {isAddListModal && clickedCollege.id !== -100 && (
              <AddListModal
                close={() => {
                  close();
                  setIsAddListModal(false);
                }}
                needAddToList={true}
                additionalValue={clickedCollege.id}
              />
            )}
            {isOpenLockedPopupOffers && (
              <SignUpModal
                close={close}
                message={'Upgrade Your Plan To View This Data'}
                anyAction={() => setIsOpenLockedPopupOffers(false)}
                title={'Premium Data'}
              />
            )}
            {isOpenDownloadModal && stepDownloading <= 2 && (
              <DownloadListModal
                close={() => {
                  close();
                  setIsOpenDownloadModal(false);
                  setStepDownloading(1);
                }}
                setStepDownloading={setStepDownloading}
                stepDownloading={stepDownloading}
                setSelectedCollegeForCsv={setSelectedCollegeForCsv}
                selectedCollegeForCsv={selectedCollegeForCsv}
                setSelectedColumnForCsv={setSelectedColumnForCsv}
                selectedColumnForCsv={selectedColumnForCsv}
                firstStep={firstStep}
                secondStep={secondStep}
                columnsForDownloading={columnsForDownloading}
                clearValuesAfterDownload={clearValuesAfterDownload}
              />
            )}
            {isOpenDownloadModal && stepDownloading >= 3 && (
              <ConfirmDownloadListModal
                close={() => {
                  close();
                  setIsOpenDownloadModal(false);
                  setStepDownloading(1);
                }}
                setStepDownloading={setStepDownloading}
                stepDownloading={stepDownloading}
                onHandleDownload={handleDownload}
                clearValuesAfterDownload={clearValuesAfterDownload}
                countDownloadCsv={user?.countDownloadCsv as number}
              />
            )}
            {isOpenListViewInMobile && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenListViewInMobile(false);
                }}
                textForDescription={'Login to your account on desktop to access a list view.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInMobile && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInMobile(false);
                }}
                textForDescription={'Login to your account on desktop to complete your download.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInMobileFree && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInMobileFree(false);
                }}
                textForDescription={'Upgrade your account to download schools and data.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadWithoutCollegesInList && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadWithoutCollegesInList(false);
                }}
                textForDescription={'You do not have colleges in the list. Please, add them to create your download.'}
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVInPCFree && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVInPCFree(false);
                }}
                textForDescription={
                  'This feature is only available for paid subscribers. Upgrade now to download one list of up to 20 schools and up to 10 unique categories.'
                }
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenDownloadCSVEarlyWithCountZero && (
              <UniversalModal
                close={() => {
                  close();
                  setIsOpenDownloadCSVEarlyWithCountZero(false);
                }}
                textForDescription={
                  'You have used your download. Please email support@road2college if you’d like to purchase additional downloads'
                }
                textSecondBtn={'Ok'}
              />
            )}
            {isOpenEmailModal && (
              <EmailModal
                close={() => {
                  close();
                  setIsOpenEmailModal(false);
                }}
                onHandleOpenModalAfterSendEmail={handleOpenModalAfterSendEmail}
                openSocialMediaModal={openSocialMediaPopup}
                onHandleClickShareList={handleClickShareList}
              />
            )}
            {isOpenModalAfterSendEmail && (
              <ScholarshipModal
                close={() => {
                  close();
                  setIsOpenModalAfterSendEmail(false);
                }}
                title="Congratulations!"
                message="Email has been sent"
                textButton="Ok"
              />
            )}
            {isOpenSocialMedias && (
              <SocialMediaModal
                closeModal={() => {
                  close();
                  setIsOpenSocialMedias(false);
                }}
                openEmailModal={handleClickOpenEmailModal}
                onHandleClickShareList={handleClickShareList}
                onHandleSetBasketLists={setBasketLists}
                onHandleSetList={setList}
                deleteObjectInSelectedSharedListById={deleteObjectInSelectedSharedListById}
                basketLists={basketLists}
                list={list}
              />
            )}
            {isOpenSaveListModal && (
              <SaveListModal
                close={() => {
                  close();
                  setIsOpenSaveListModal(false);
                }}
                onHandleSetIsOpenAddNewListModal={handleSetIsOpenAddNewListModal}
              />
            )}
            {isOpenAddNewListModal && (
              <AddNewListModal
                close={() => {
                  close();
                  setIsOpenAddNewListModal(false);
                }}
              />
            )}
          </Modal>
        </>
      )}
    </div>
  );
};
