import './PopUpModal.css';
import { ReactNode } from 'react';
import cross from '../../images/cross.svg';

export const PopUpModal = ({
  close,
  textForTitle,
  textFirstBtn,
  textSecondBtn,
  confirm,
  anotherContent,
}: {
  close: () => void;
  textForTitle: string;
  textFirstBtn?: string;
  textSecondBtn?: string;
  confirm?: () => void;
  anotherContent?: ReactNode;
}) => {
  const clickFirstBtn = (e) => {
    e.stopPropagation();
    close();
  };
  const clickSecondBtn = async (e) => {
    e.stopPropagation();
    if (confirm) {
      confirm();
    }
    close();
  };

  const closeModal = (e) => {
    e.stopPropagation();
    close();
  };

  return (
    <div className="popup-modal-container">
      <img onClick={closeModal} src={cross} alt="cross" className="popup-modal-close" />
      <h5 className="popup-header">{textForTitle}</h5>
      {anotherContent}
      <div className="popup-modal-buttons">
        <button
          className={textSecondBtn ? 'popup-modal-first-button' : 'popup-modal-second-button'}
          onClick={clickFirstBtn}
        >
          {textFirstBtn ?? 'OK'}
        </button>
        {textSecondBtn && (
          <button className="popup-modal-second-button" onClick={clickSecondBtn}>
            {textSecondBtn}
          </button>
        )}
      </div>
    </div>
  );
};
