import { IMyListsState } from '../../models';

export const myListsState: IMyListsState = {
  myLists: [],
  selectedList: null,
  sharedList: null,
  paginatedColleges: null,
  recommendations: null,
  idSharedList: null,
  isSharedList: false,
};
