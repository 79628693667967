import React, { useEffect, useRef, useState } from 'react';
import { clsx } from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { AutocompleteSelect } from '../../../../UI/selects/autocomplete-select/AutocompleteSelect';
import { Autocomplete } from '../../../autocomplete/Autocomplete';
import { EPlatform } from '../../../../enums/platform.enum';
import { ICollege, IMajors, IPowerSearchFilterOption, IRegion, IRootState, IState } from '../../../../models';
import styles from './SearchInputs.module.css';
import { SelectedItemsList } from '../selected-items-list/SelectedItemsList';
import { applicationDeadlines } from '../../PowerSearch';
import { powerSearchAction } from '../../../../store/actions';
import { useClickOutside } from '../../../../hooks/useClickOutside';
import { collegeService } from '../../../../services';

const filterListByProperty = <T,>(sourceList: T[], inputValue: string, filterProperty: string): T[] => {
  if (inputValue) {
    return sourceList.filter((item: T) => item[filterProperty].toLowerCase().includes(inputValue.toLowerCase().trim()));
  }
  return sortAlphabeticallyByProperty(sourceList, filterProperty);
};

const sortAlphabeticallyByProperty = <T,>(list: T[], sortProperty: string): T[] => {
  const copy = [...list];
  return copy.sort((a, b) => a[sortProperty].localeCompare(b[sortProperty]));
};

interface SearchInputsProps {
  platform: EPlatform;
  isFiltersModal: boolean;
}

export const SearchInputs: React.FC<SearchInputsProps> = ({ platform, isFiltersModal }) => {
  const { selectedColleges, selectedMajors, selectedLocations, selectedApplicationDeadlines } = useSelector(
    (state: IRootState) => state.powerSearchState,
  );
  const autocompleteColleges = useSelector((state: IRootState) => state.collegesState.colleges);
  const { majors } = useSelector((state: IRootState) => state.majorsState);
  const { states, regions } = useSelector((state: IRootState) => state.statesState);

  const dispatch = useDispatch();

  const [isCollegesSelectActive, setIsCollegesSelectActive] = useState<boolean>(false);
  const [searchCollegesValue, setSearchCollegesValue] = useState<string>('');
  const [filteredColleges, setFilteredColleges] = useState<ICollege[]>(autocompleteColleges);

  const [isMajorsSelectActive, setIsMajorsSelectActive] = useState<boolean>(false);
  const [searchMajorsValue, setSearchMajorsValue] = useState<string>('');
  const [filteredMajors, setFilteredMajors] = useState<IMajors[]>(majors);

  const [isStateSelectActive, setIsStateSelectActive] = useState<boolean>(false);
  const [searchLocationsValue, setSearchLocationsValue] = useState<string>('');
  const [filteredStates, setFilteredStates] = useState<(IState | IRegion)[]>([...regions, ...states]);

  const [isApplicationDeadlineSelectActive, setIsApplicationDeadlineSelectActive] = useState<boolean>(false);

  useEffect(() => {
    const sortedColleges = sortAlphabeticallyByProperty<ICollege>(autocompleteColleges, 'name');
    setFilteredColleges(sortedColleges);

    const sortedMajors = sortAlphabeticallyByProperty<IMajors>(majors, 'name');
    setFilteredMajors(sortedMajors);

    const sortedStates = sortAlphabeticallyByProperty<IState>(states, 'name');
    setFilteredStates([...regions, ...sortedStates]);
  }, []);

  const selectCollege = async (college: ICollege): Promise<void> => {
    if (selectedColleges.findIndex((c) => c.id === college.id) === -1) {
      try {
        const { data } = await collegeService.endpoint_get_college_by_id(college.id);
        dispatch(powerSearchAction.addSelectedCollege(data as ICollege));
      } catch (error) {
        console.error('Error', error);
      }
      setSearchCollegesValue('');
    }
  };

  const filterColleges = (inputValue: string): void => {
    const filtered = filterListByProperty(autocompleteColleges, inputValue, 'name');
    const sorted = sortAlphabeticallyByProperty<ICollege>(filtered, 'name');
    setFilteredColleges(sorted);
  };

  const selectMajor = (major: IMajors): void => {
    if (selectedMajors.findIndex((m) => m.id === major.id) === -1) {
      dispatch(powerSearchAction.addSelectedMajor(major));
      setSearchMajorsValue('');
    }
  };

  const filterMajors = (inputValue: string): void => {
    const filtered = filterListByProperty<IMajors>(majors, inputValue, 'name');
    const sorted = sortAlphabeticallyByProperty<IMajors>(filtered, 'name');

    setFilteredMajors(sorted);
  };

  const selectLocation = (state: IState | IRegion): void => {
    if (selectedLocations.findIndex((s) => s.id === state.id) === -1) {
      dispatch(powerSearchAction.addSelectedLocation(state));
      setSearchLocationsValue('');
    }
  };

  const filterStatesNames = (inputValue: string): void => {
    const filtered = filterListByProperty<IState | IRegion>([...states, ...regions], inputValue, 'name');
    const sorted = sortAlphabeticallyByProperty<IState | IRegion>(filtered, 'name');
    setFilteredStates(sorted);
  };

  const selectApplicationDeadline = (deadline: IPowerSearchFilterOption<string>): void => {
    if (selectedApplicationDeadlines.findIndex((d) => d.id === deadline.id) === -1) {
      dispatch(powerSearchAction.addSelectedApplicationDeadline(deadline));
    }
  };

  const collegeSelectRef = useRef(null);
  const majorSelectRef = useRef(null);
  const locationsSelectRef = useRef(null);
  const applicationDeadlineSelectRef = useRef(null);

  useClickOutside(collegeSelectRef, [], () => {
    if (isCollegesSelectActive) setIsCollegesSelectActive(false);
  });
  useClickOutside(majorSelectRef, [], () => {
    if (isMajorsSelectActive) setIsMajorsSelectActive(false);
  });
  useClickOutside(locationsSelectRef, [], () => {
    if (isStateSelectActive) setIsStateSelectActive(false);
  });
  useClickOutside(applicationDeadlineSelectRef, [], () => {
    if (isApplicationDeadlineSelectActive) setIsApplicationDeadlineSelectActive(false);
  });

  return (
    <div className={clsx(styles.inputsContainer, isFiltersModal && styles.filtersModal)}>
      <div ref={collegeSelectRef} className={styles.inputWrapper}>
        <AutocompleteSelect
          label="College"
          placeholder="Select Colleges"
          inputName="colleges"
          value={searchCollegesValue}
          onClickHandler={() => setIsCollegesSelectActive(!isCollegesSelectActive)}
          onChangeHandler={(event) => {
            setIsCollegesSelectActive(true);
            filterColleges(event.target.value);
            setSearchCollegesValue(event.target.value);
          }}
          isDisabledInput={false}
          inputStyles={isFiltersModal ? '' : styles.autocompleteSelectInput}
        >
          {isCollegesSelectActive && filteredColleges && (
            <Autocomplete
              isBudgetItems={false}
              filteredItem={filteredColleges}
              onclick={async (college: ICollege) => {
                setIsCollegesSelectActive(false);
                setFilteredColleges(sortAlphabeticallyByProperty<ICollege>(autocompleteColleges, 'name'));
                await selectCollege(college);
              }}
              classN={styles.autocomplete}
            />
          )}
        </AutocompleteSelect>
        {selectedColleges.length > 0 && (
          <SelectedItemsList
            list={selectedColleges}
            onDeleteHandler={(id) => dispatch(powerSearchAction.removeFromSelectedColleges(id))}
          />
        )}
      </div>
      <div ref={majorSelectRef} className={styles.inputWrapper}>
        <AutocompleteSelect
          label="Major"
          placeholder="Select Majors"
          inputName="majors"
          value={searchMajorsValue}
          onClickHandler={() => setIsMajorsSelectActive(!isMajorsSelectActive)}
          isDisabledInput={false}
          onChangeHandler={(event) => {
            setIsMajorsSelectActive(true);
            filterMajors(event.target.value);
            setSearchMajorsValue(event.target.value);
          }}
          inputStyles={isFiltersModal ? '' : styles.autocompleteSelectInput}
        >
          {isMajorsSelectActive && filteredMajors && (
            <Autocomplete
              isBudgetItems={false}
              filteredItem={filteredMajors}
              onclick={(major: IMajors) => {
                selectMajor(major);
                setIsMajorsSelectActive(false);
                setFilteredMajors(sortAlphabeticallyByProperty<IMajors>(majors, 'name'));
              }}
              classN={styles.autocomplete}
            />
          )}
        </AutocompleteSelect>
        {selectedMajors.length > 0 && (
          <SelectedItemsList
            list={selectedMajors}
            onDeleteHandler={(id) => dispatch(powerSearchAction.removeFromSelectedMajors(id))}
          />
        )}
      </div>
      <div ref={locationsSelectRef} className={styles.inputWrapper}>
        <AutocompleteSelect
          label="Desired Location"
          placeholder="Select a State/Region"
          inputName="location"
          value={searchLocationsValue}
          onClickHandler={() => setIsStateSelectActive(!isStateSelectActive)}
          isDisabledInput={false}
          onChangeHandler={(event) => {
            setIsStateSelectActive(true);
            filterStatesNames(event.target.value);
            setSearchLocationsValue(event.target.value);
          }}
          inputStyles={isFiltersModal ? '' : styles.autocompleteSelectInput}
        >
          {isStateSelectActive && filteredStates && (
            <Autocomplete
              isBudgetItems={false}
              filteredItem={filteredStates}
              onclick={(location: IState | IRegion) => {
                selectLocation(location);
                setIsStateSelectActive(false);
                setFilteredStates([...regions, ...sortAlphabeticallyByProperty<IState>(states, 'name')]);
              }}
              classN={styles.autocomplete}
            />
          )}
        </AutocompleteSelect>
        {selectedLocations.length > 0 && (
          <SelectedItemsList
            list={selectedLocations}
            onDeleteHandler={(id) => dispatch(powerSearchAction.removeFromSelectedLocations(id))}
          />
        )}
      </div>
      {(platform === EPlatform.MOBILE || platform === EPlatform.TABLET) && isFiltersModal && (
        <div ref={applicationDeadlineSelectRef} className={styles.inputWrapper}>
          <AutocompleteSelect
            label="Application Deadline"
            placeholder="Select Deadline"
            inputName="deadline"
            onClickHandler={() => setIsApplicationDeadlineSelectActive(!isApplicationDeadlineSelectActive)}
            isDisabledInput={true}
            inputStyles={isFiltersModal ? '' : styles.autocompleteSelectInput}
          >
            {isApplicationDeadlineSelectActive && applicationDeadlines && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={applicationDeadlines}
                onclick={(deadline: IPowerSearchFilterOption<string>) => {
                  selectApplicationDeadline(deadline);
                  setIsApplicationDeadlineSelectActive(false);
                }}
                classN={styles.autocomplete}
              />
            )}
          </AutocompleteSelect>
          {selectedApplicationDeadlines.length > 0 && (
            <SelectedItemsList
              list={selectedApplicationDeadlines}
              onDeleteHandler={(id) => dispatch(powerSearchAction.removeFromSelectedApplicationDeadline(id))}
            />
          )}
        </div>
      )}
    </div>
  );
};
