import { EOffersActionTypes } from '../../enums';
import { IOffer } from '../../models';

const getOffers = () => {
  return {
    type: EOffersActionTypes.GET_OFFERS,
  };
};

const setOffers = (payload: Array<IOffer>) => {
  return {
    type: EOffersActionTypes.SET_OFFERS,
    payload,
  };
};

const getCrowdsourcedOffers = (payload: { ipeds: string; year: string }) => {
  return {
    type: EOffersActionTypes.GET_CROWDSOURCED_OFFERS,
    payload,
  };
};

export const offersAction = {
  getOffers,
  setOffers,
  getCrowdsourcedOffers,
};
