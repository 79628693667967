import * as React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { useEffect, useState } from 'react';
import { SyncLoader } from 'react-spinners';
import localforage from 'localforage';
import { AuthNavigator, UnauthNavigator } from '.';

interface RootNavigatorProps {}

export const RootNavigator: React.FunctionComponent<RootNavigatorProps> = () => {
  const { isAuthenticated, error, isLoading, getAccessTokenSilently, loginWithRedirect, logout } = useAuth0();
  const [authError, setAuthError] = useState<boolean>(false);
  const [isTokenLoading, setIsTokenLoading] = useState<boolean>(true);
  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const error_type = urlParams.get('error');
  const error_description = urlParams.get('error_description');

  useEffect(() => {
    setAuthError(!!error);
  }, [error]);

  useEffect(() => {
    try {
      if (isAuthenticated) {
        getAccessTokenSilently({ cacheMode: 'on' }).then((token) => {
          localStorage.setItem('auth', token);

          setIsTokenLoading(false);
        });
      } else if (!isLoading) {
        setIsTokenLoading(false);
      }
    } catch (error) {
      loginWithRedirect();
    }
  }, [isAuthenticated, isLoading]);

  if (isLoading || isTokenLoading) {
    return (
      <div className="app-wrapper">
        {/* <h4 className="app-header">Please wait while your credentials are verified</h4>
        <SyncLoader className="app-header" color="var(--color-primary)" margin={7} size={20} speedMultiplier={0.7} /> */}
      </div>
    );
  }
  if (authError && error_type) {
    return (
      <div className="app-wrapper">
        <h1 className="app-header">Verification Email on its Way</h1>
        {error_description?.split('.').map(function (object, i) {
          return <h2 className="app-description">{object}</h2>;
        })}

        <button
          className="primary-button"
          onClick={() => {
            setAuthError(false);
          }}
        >
          <a
            onClick={() => {
              localStorage.clear();
              localforage.clear().then(() => {
                logout({
                  returnTo: window.location.origin,
                });
              });
            }}
          >
            Back to home
          </a>
        </button>
      </div>
    );
  }

  return isAuthenticated ? <AuthNavigator /> : <UnauthNavigator />;
};
