import { produce } from 'immer';
import { IAction } from '../../models';
import { createReducer } from './index';
import { couponActionTypes } from '../../consts';
import { ICoupon } from '../../models/coupon.model';
import { couponState } from '../states/coupon.state';

export const couponReducer = createReducer<{
  coupon: ICoupon | null;
}>(couponState, {
  [couponActionTypes.SET_COUPON]: setCoupon,
});

function setCoupon(state: { coupon: ICoupon | null }, { payload }: IAction<ICoupon>) {
  return produce(state, (draft) => {
    draft.coupon = { ...payload };
  });
}
