import React from 'react';
import { btnStyles } from '../social-medias/SocialMedias';

type Props = {
  onHandleClickShareList: () => Promise<void>;
  onHandleOpenedSocialMedia: React.Dispatch<React.SetStateAction<boolean>>;
  onHandleOpenModalWithZeroSharingColleges: () => boolean;
};

export const FacebookMessengerButton = ({
  onHandleClickShareList,
  onHandleOpenedSocialMedia,
  onHandleOpenModalWithZeroSharingColleges,
}: Props) => {
  const handleButtonClick = () => {
    const needOpenModal = onHandleOpenModalWithZeroSharingColleges();
    if (needOpenModal) return;

    navigator.clipboard.writeText(window.location.href);

    const isMobileOrTablet = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    // need to add variable in env and put here
    const appID = '2401909006644282';

    const messengerLink = isMobileOrTablet
      ? 'https://m.me'
      : `https://www.facebook.com/dialog/send?link=${encodeURIComponent(
          window.location.href.replace('my-lists', 'shared-lists'),
        )}&app_id=${appID}&redirect_uri=${encodeURIComponent(window.location.href)}&display=popup`;

    const modalWidth = 500;
    const modalHeight = 600;
    const left = (window.innerWidth - modalWidth) / 2;
    const top = (window.innerHeight - modalHeight) / 2;

    const messengerWindow = window.open(
      messengerLink,
      '_blank',
      `width=${modalWidth},height=${modalHeight},left=${left},top=${top}`,
    );

    if (messengerWindow) {
      messengerWindow.moveTo(left, top);
    }
    onHandleClickShareList();
    onHandleOpenedSocialMedia(false);
  };

  return (
    <button
      onClick={handleButtonClick}
      className="social-button"
      aria-label="Facebook Button"
      style={{ width: btnStyles.size, height: btnStyles.size }}
    >
      <span className="social-button__inside facebook-messenger" />
    </button>
  );
};
