import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import sharedStyles from '../Shared.module.css';
import { EPlatform } from '../../../../../../enums/platform.enum';
import { Autocomplete } from '../../../../../autocomplete/Autocomplete';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';
import { IPowerSearchFilterOption } from '../../../../../../models';

const avgMeritAward: IPowerSearchFilterOption<number>[] = [
  { id: 1, name: '$0', value: 0 },
  { id: 2, name: '$10k', value: 10000 },
  { id: 3, name: '$20k', value: 20000 },
  { id: 4, name: '$30k', value: 30000 },
  { id: 5, name: '$40k', value: 40000 },
  { id: 6, name: '$50k', value: 50000 },
  { id: 7, name: '$60k', value: 60000 },
  { id: 8, name: '$70k', value: 70000 },
  { id: 9, name: '$80k', value: 80000 },
  { id: 10, name: '$90k', value: 90000 },
  { id: 11, name: '$100k', value: 100000 },
];

const avgMeritAwardRadioOptions: IPowerSearchFilterOption<string>[] = [
  { id: 1, name: 'School Reported', value: 'schoolReported' },
  { id: 2, name: 'Crowdsourced From Families', value: 'crowdsourced' },
];

interface AvgMeritAwardFilterProps {
  selectedRadioValue: string;
  changeSelectedRadioValue: (event: React.MouseEvent<HTMLInputElement>) => void;
  selectedValues: { min: number | null; max: number | null } | null;
  selectMin: (value: IPowerSearchFilterOption<number>) => void;
  selectMax: (value: IPowerSearchFilterOption<number>) => void;
  selectedRangeValues: number[];
  handleChangeSelectedRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  platform: EPlatform;
}

function valuetext(value: number) {
  return `${value}`;
}

export const AvgMeritAwardFilter: React.FC<AvgMeritAwardFilterProps> = ({
  selectedRadioValue,
  changeSelectedRadioValue,
  selectedValues,
  selectMin,
  selectMax,
  selectedRangeValues,
  handleChangeSelectedRange,
  platform,
}) => {
  const [isAvgMeritAwardMinSelectActive, setIsAvgMeritAwardMinSelectActive] = useState<boolean>(false);
  const [isAvgMeritAwardMaxSelectActive, setIsAvgMeritAwardMaxSelectActive] = useState<boolean>(false);

  const avgMeritAwardMinSelectRef = useRef<HTMLDivElement | null>(null);
  const avgMeritAwardMaxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(avgMeritAwardMinSelectRef, [], () => {
    if (isAvgMeritAwardMinSelectActive) setIsAvgMeritAwardMinSelectActive(false);
  });
  useClickOutside(avgMeritAwardMaxSelectRef, [], () => {
    if (isAvgMeritAwardMaxSelectActive) setIsAvgMeritAwardMaxSelectActive(false);
  });

  return (
    <div className={sharedStyles.container}>
      <h5 className={sharedStyles.title}>Avg. Merit Award</h5>
      {platform === EPlatform.DESKTOP && (
        <div className={sharedStyles.buttonsContainer}>
          <div
            onClick={() => setIsAvgMeritAwardMinSelectActive(!isAvgMeritAwardMinSelectActive)}
            ref={avgMeritAwardMinSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.min && selectedValues?.min !== 0
              ? 'Min'
              : avgMeritAward[avgMeritAward.findIndex((value) => value.value === selectedValues.min)]?.name ||
                `$${selectedValues.min.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isAvgMeritAwardMinSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={avgMeritAward}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMin(value);
                  setIsAvgMeritAwardMinSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
          <div
            onClick={() => setIsAvgMeritAwardMaxSelectActive(!isAvgMeritAwardMaxSelectActive)}
            ref={avgMeritAwardMaxSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.max && selectedValues?.max !== 0
              ? 'Max'
              : avgMeritAward[avgMeritAward.findIndex((value) => value.value === selectedValues.max)]?.name ||
                `$${selectedValues.max.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isAvgMeritAwardMaxSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={avgMeritAward}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMax(value);
                  setIsAvgMeritAwardMaxSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
        </div>
      )}
      <div className={sharedStyles.radioInputsContainer}>
        {avgMeritAwardRadioOptions.map((option) => {
          return (
            <label key={option.id} className={sharedStyles.radioInputWrapper}>
              <input
                type="radio"
                value={option.value}
                name="avgMeritAwardRadio"
                onClick={changeSelectedRadioValue}
                readOnly
                checked={selectedRadioValue === option.value}
                className={sharedStyles.radioInput}
              />
              {option.name}
            </label>
          );
        })}
      </div>
      {(platform === EPlatform.MOBILE || platform === EPlatform.TABLET) && (
        <div className={sharedStyles.rangeWrapper}>
          <Slider
            sx={{
              width: '95%',
              color: '#7e106e',
              '& .MuiSlider-thumb': {
                background: 'white',
                border: '2px solid #7e106e',
              },
            }}
            getAriaLabel={() => 'Minimum distance'}
            step={10000}
            min={0}
            max={100000}
            value={selectedRangeValues}
            onChange={handleChangeSelectedRange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            valueLabelFormat={(value) => `$${value.toLocaleString('en-US')}`}
            disableSwap
          />
        </div>
      )}
    </div>
  );
};
