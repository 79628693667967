import './AddListModal.css';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cross from '../../images/cross.svg';
import { myListService } from '../../services';
import { myListsAction } from '../../store/actions';

export const AddListModal = ({
  close,
  createList,
  err,
  needAddToList,
  additionalValue,
}: {
  close: any;
  createList?: any;
  err?: string;
  needAddToList?: boolean;
  additionalValue?: any;
}) => {
  const { user } = useSelector((state: any) => state.userState);
  const [listName, setListName] = useState('');
  const dispatch = useDispatch();
  return (
    <div onClick={(e) => e.stopPropagation()} className="modal-sign-up">
      <img onClick={close} src={cross} alt="cross" />
      <h1>Add New List</h1>
      <input placeholder="Type List Name" value={listName} onChange={({ target }) => setListName(target.value)} />
      {err && <div className="create-list-err">{err}</div>}
      <div>
        <button type="button" className="cancle-button" onClick={close}>
          Cancel
        </button>
        <button
          type="button"
          className="sign-up-modal-button"
          onClick={async () => {
            if (needAddToList && listName) {
              await myListService.endpoint_create_list_and_add_college({
                userId: user.id,
                collegeId: additionalValue,
                listName,
              });
              const { data } = await myListService.endpoint_get_my_lists(user.id);
              dispatch(myListsAction.setMyLists(data));
              dispatch(myListsAction.setSelectedList(data[data.length - 1]));
              close();
              return;
            }
            if (listName) {
              createList(listName);
              close();
            }
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
