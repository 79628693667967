import { ESelectedCollegesForSharingTypes } from '../../enums';

const getSelectedCollegesForSharing = () => {
  return {
    type: ESelectedCollegesForSharingTypes.GET_SELECTED_COLLEGES_FOR_SHARING,
  };
};

const setSelectedCollegesForSharing = (payload: Array<any>) => {
  return {
    type: ESelectedCollegesForSharingTypes.SET_SELECTED_COLLEGES_FOR_SHARING,
    payload,
  };
};

export const selectedCollegesForSharingAction = {
  getSelectedCollegesForSharing,
  setSelectedCollegesForSharing,
};
