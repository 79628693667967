import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import sharedStyles from '../Shared.module.css';
import { Autocomplete } from '../../../../../autocomplete/Autocomplete';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';
import styles from './StudentGPAFilter.module.css';

type GPAValue = { id: number; name: number; value: number };
type SelectVariant = 'rangeSlider' | 'autocompleteButtons';

const gpaValues: GPAValue[] = [
  { id: 0, name: 0.0, value: 0.0 },
  { id: 1, name: 0.25, value: 0.25 },
  { id: 2, name: 0.5, value: 0.5 },
  { id: 3, name: 0.75, value: 0.75 },
  { id: 4, name: 1.0, value: 1.0 },
  { id: 5, name: 1.25, value: 1.25 },
  { id: 6, name: 1.5, value: 1.5 },
  { id: 7, name: 1.75, value: 1.7 },
  { id: 8, name: 2.0, value: 2.0 },
  { id: 9, name: 2.25, value: 2.25 },
  { id: 10, name: 2.5, value: 2.5 },
  { id: 11, name: 2.75, value: 2.75 },
  { id: 12, name: 3.0, value: 3.0 },
  { id: 13, name: 3.25, value: 3.25 },
  { id: 14, name: 3.5, value: 3.5 },
  { id: 15, name: 3.75, value: 3.75 },
  { id: 16, name: 4.0, value: 4.0 },
];

const studentGPARadioValues: { id: number; name: string; value: string }[] = [
  { id: 1, name: 'Avg GPA School Reported', value: 'schoolReported' },
  { id: 2, name: 'Crowdsourced From Families', value: 'crowdsourced' },
];

function valuetext(value: number) {
  return `${value}`;
}

interface StudentGPAFilterProps {
  selectedGpa: { min: number | null; max: number | null } | null;
  selectGpaMin: (gpaValue: GPAValue) => void;
  selectGpaMax: (gpaValue: GPAValue) => void;
  selectedRangeValues: number[];
  handleChangeRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  selectedRadioValue: string;
  changeSelectedRadioValue: (event: React.MouseEvent<HTMLInputElement>) => void;
  selectVariant: SelectVariant;
}

export const StudentGPAFilter: React.FC<StudentGPAFilterProps> = ({
  selectedGpa,
  selectGpaMin,
  selectGpaMax,
  selectedRangeValues,
  handleChangeRange,
  selectedRadioValue,
  changeSelectedRadioValue,
  selectVariant,
}) => {
  const [isStudentGPAMinSelectActive, setIsStudentGPAMinSelectActive] = useState<boolean>(false);
  const [isStudentGPAMaxSelectActive, setIsStudentGPAMaxSelectActive] = useState<boolean>(false);

  const studentGPAMinSelectRef = useRef<HTMLDivElement | null>(null);
  const studentGPAMaxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(studentGPAMinSelectRef, [], () => {
    if (isStudentGPAMinSelectActive) setIsStudentGPAMinSelectActive(false);
  });
  useClickOutside(studentGPAMaxSelectRef, [], () => {
    if (isStudentGPAMaxSelectActive) setIsStudentGPAMaxSelectActive(false);
  });

  return (
    <div className={sharedStyles.container}>
      <h5 className={styles.title}>
        GPA <p className={sharedStyles.subtitle}>(Unweighted)</p>
      </h5>
      {selectVariant === 'autocompleteButtons' && (
        <div className={sharedStyles.buttonsContainer}>
          <div
            onClick={() => setIsStudentGPAMinSelectActive(!isStudentGPAMinSelectActive)}
            ref={studentGPAMinSelectRef}
            className={sharedStyles.button}
          >
            {!selectedGpa?.min || selectedGpa.min === gpaValues[0].value ? 'Min' : selectedGpa.min}
            <span className={sharedStyles.buttonArrowIcon} />
            {isStudentGPAMinSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={gpaValues}
                onclick={(value: GPAValue) => {
                  selectGpaMin(value);
                  setIsStudentGPAMinSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
          <div
            onClick={() => setIsStudentGPAMaxSelectActive(!isStudentGPAMaxSelectActive)}
            ref={studentGPAMaxSelectRef}
            className={sharedStyles.button}
          >
            {!selectedGpa?.max || selectedGpa.max === gpaValues[gpaValues.length - 1].value ? 'Max' : selectedGpa.max}
            <span className={sharedStyles.buttonArrowIcon} />
            {isStudentGPAMaxSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={gpaValues}
                onclick={(value: GPAValue) => {
                  selectGpaMax(value);
                  setIsStudentGPAMaxSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
        </div>
      )}
      <div className={sharedStyles.radioInputsContainer}>
        {studentGPARadioValues.map((el) => {
          return (
            <label key={el.id} className={sharedStyles.radioInputWrapper}>
              <input
                type="radio"
                name="studentGPA"
                checked={selectedRadioValue === el.value}
                onClick={changeSelectedRadioValue}
                value={el.value}
                readOnly
                className={sharedStyles.radioInput}
              />
              {el.name}
            </label>
          );
        })}
      </div>
      {selectVariant === 'rangeSlider' && (
        <div className={sharedStyles.rangeWrapper}>
          <Slider
            sx={{
              width: '95%',
              color: '#7e106e',
              '& .MuiSlider-thumb': {
                background: 'white',
                border: '2px solid #7e106e',
              },
            }}
            getAriaLabel={() => 'Minimum distance'}
            step={0.25}
            min={gpaValues[0].name}
            max={gpaValues[16].name}
            value={selectedRangeValues}
            onChange={handleChangeRange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            disableSwap
          />
        </div>
      )}
    </div>
  );
};
