import './LandingPage.css';
import { NavLink, useSearchParams } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import { useState } from 'react';
import collegeInsightsLanding from '../../images/road2college.svg';
import computerLanding from '../../images/computer-landing-page.svg';
import { Header } from '../header/Header';
import { Footer } from '../footer/Footer';

export const LandingPage = () => {
  const { loginWithRedirect } = useAuth0();
  const [searchParams] = useSearchParams();

  const [emailHint] = useState(searchParams.get('login_email'));
  const [paymentType] = useState(searchParams.get('payment_type'));

  return (
    <div>
      <Header />
      <section className="landing-page">
        <h4 className="landing-page-welcome-text">WELCOME TO</h4>
        <h1 className="landing-page-college-insights-text">Insights</h1>
        <div className="landing-page-container-logo">
          <p className="landing-page-by-text">by</p>
          <img className="landing-page-logo" src={collegeInsightsLanding} alt="Insights logo" />
        </div>
        <h2 className="landing-page-find-merit">Find Merit. Compare Colleges. Build Your List.</h2>
        <img className="landing-page-computer-img" src={computerLanding} alt="computer" />
        <h3 className="landing-page-free-account-text">Create your free account</h3>
        <button
          className="landing-page-get-started-btn"
          onClick={() => {
            if (paymentType === 'klarna' && emailHint) {
              loginWithRedirect({ screen_hint: 'signup', login_hint: emailHint });
            } else {
              loginWithRedirect({ screen_hint: 'signup' });
            }
          }}
        >
          Get Started
        </button>
        <p className="landing-page-already-text">
          Already have an account?{' '}
          <NavLink to={''} className="landing-page-sign-in-link" onClick={() => loginWithRedirect()}>
            Sign In
          </NavLink>{' '}
          here
        </p>
      </section>
      <Footer />
    </div>
  );
};
