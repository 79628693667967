import { produce } from 'immer';

import { createReducer } from '.';
import { myListActionTypes } from '../../consts';
import { IAction, IMyList, IMyListsState } from '../../models';
import { myListsState } from '../states/my-list.state';
import { ISharedList } from '../../models/shared-list.model';

export const myListsReducer = createReducer<IMyListsState>(myListsState, {
  [myListActionTypes.SET_MY_LISTS]: setMyLists,
  [myListActionTypes.SET_SELECTED_LIST]: setSelectedList,
  [myListActionTypes.SET_SHARED_LIST]: setSharedList,
  [myListActionTypes.SET_PAGINATED_COLLEGES_MY_LIST]: setPaginatedList,
  [myListActionTypes.SET_RECOMMENDATIONS_AMOUNT_FOR_LIST]: setRecommendations,
  [myListActionTypes.SET_ID_SHARED_LIST]: setIdSharedList,
  [myListActionTypes.SET_IS_SHARED_LIST]: setIsSharedList,
});

function setMyLists(state: IMyListsState, { payload }: IAction<IMyList[]>) {
  return produce(state, (draft) => {
    draft.myLists = payload;
  });
}

function setIdSharedList(state: IMyListsState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.idSharedList = payload;
  });
}

function setIsSharedList(state: IMyListsState, { payload }: IAction<boolean>) {
  return produce(state, (draft) => {
    draft.isSharedList = payload;
  });
}

function setSelectedList(state: IMyListsState, { payload }: IAction<IMyList>) {
  return produce(state, (draft) => {
    draft.selectedList = payload;
  });
}

function setSharedList(state: IMyListsState, { payload }: IAction<ISharedList>) {
  return produce(state, (draft) => {
    draft.sharedList = payload;
  });
}

function setPaginatedList(state: IMyListsState, { payload }: IAction<ISharedList>) {
  return produce(state, (draft) => {
    draft.paginatedColleges = payload;
  });
}

function setRecommendations(state: IMyListsState, { payload }: IAction<number>) {
  return produce(state, (draft) => {
    draft.recommendations = payload;
  });
}
