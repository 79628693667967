import React from 'react';
import { clsx } from 'clsx';
import styles from './Button.module.css';

type ButtonVariants = 'primaryBlue' | 'primaryTransparent';

interface ButtonProps {
  textContent: string;
  onClickHandler: () => void;
  variant: ButtonVariants;
}

export const Button: React.FC<ButtonProps> = ({ textContent, onClickHandler, variant}) => {
  return (
    <button
      onClick={onClickHandler}
      className={clsx(styles.button, {
        [styles.primaryBlue]: variant === 'primaryBlue',
        [styles.primaryTransparent]: variant === 'primaryTransparent',
      })}
    >
      {textContent}
    </button>
  );
};
