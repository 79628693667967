import React from 'react';
import './CustomLoader.css';

type Props = {
  size?: 'big' | 'medium' | 'small';
  backgroundColor?: string;
};

export const CustomLoader = ({ size = 'medium', backgroundColor }: Props) => {
  return (
    <div className={`loader ${size}`}>
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
      <div style={{ backgroundColor }} />
    </div>
  );
};
