import { Reducer, combineReducers } from 'redux';

import { IAction, IRootState } from '../../models';
import { collegesReducer } from './colleges.reducer';
import { efcsReducer } from './efcs.reducer';
import { highschoolsReducer } from './highschools.reducer';
import { loadingReducer } from './loading.reducer';
import { majorsReducer } from './majors.reducer';
import { myListsReducer } from './my-list.reducer';
import { offersReducer } from './offers.reducer';
import { statesReducer } from './states.reducer';
import { userReducer } from './user.reducer';
import { usersReducer } from './users.reducer';
import { subscriptionReducer } from './subscription.reducer';
import { notesReducer } from './notes.reducer';
import { collegeNotesReducer } from './college-notes.reducer';
import { listBackupsReducer } from './list-backups.reducer';
import { backupUserListReducer } from './backup-user-list.reducer';
import { previousRouteReducer } from './previous-route.reducer';
import { houseHoldIncomeReducer } from './household-income.reducer';
import { switcherBlockMyListReducer } from './switcher-blocks-my-list.reducer';
import { selectedCollegesAndColumnsReducer } from './selected-colleges-and-columns-for-csv.reducer';
import { selectedCollegesForSharingReducer } from './selected-colleges-for-sharing.reducer';
import { couponReducer } from './coupon.reducer';
import { powerSearchReducer } from './power-search.reducer';

export function createReducer<S>(initialState: S, handlers: any): Reducer<S> {
  const r = (state: S = initialState, action: IAction<S>): S => {
    if (Object.prototype.hasOwnProperty.call(handlers, action.type)) {
      return handlers[action.type](state, action);
    }
    return state;
  };

  return r as Reducer<S>;
}

const rootReducer: Reducer<IRootState> = combineReducers({
  collegesState: collegesReducer,
  efcsState: efcsReducer,
  highschoolsState: highschoolsReducer,
  couponState: couponReducer,
  loadingState: loadingReducer,
  offersState: offersReducer,
  statesState: statesReducer,
  userState: userReducer,
  usersState: usersReducer,
  majorsState: majorsReducer,
  myListsState: myListsReducer,
  subscriptionState: subscriptionReducer,
  notesState: notesReducer,
  collegeNotesState: collegeNotesReducer,
  listBackupsState: listBackupsReducer,
  backupUserListState: backupUserListReducer,
  previousRouteState: previousRouteReducer,
  houseHoldIncomeState: houseHoldIncomeReducer,
  switcherBlocksMyListState: switcherBlockMyListReducer,
  selectedCollegesAndColumnsForCsvState: selectedCollegesAndColumnsReducer,
  selectedCollegesForSharingState: selectedCollegesForSharingReducer,
  powerSearchState: powerSearchReducer,
});

export default rootReducer;
