import { all, call, put, takeLatest } from 'redux-saga/effects';

import { ESubscriptionActionTypes } from '../../enums';
import { subscriptionService } from '../../services/subscription.service';
import { subscriptionAction } from '../actions';

export default function* root() {
  yield all([takeLatest(ESubscriptionActionTypes.GET_PAYMENT_METHOD_BY_USER_ID as any, watchGetPaymentMethodByUserId)]);
  yield all([takeLatest(ESubscriptionActionTypes.GET_SUBSCRIPTION_BY_USERID as any, watchGetSubscriptionByUserId)]);
  yield all([takeLatest(ESubscriptionActionTypes.DELETE_SUBSCRIPTION as any, watchDeleteSubscription)]);
  yield all([
    takeLatest(ESubscriptionActionTypes.ADMIN_ADD_INTERNAL_SUBSCRIPTION as any, watchAdminCreateInternalSubscription),
  ]);
  yield all([
    takeLatest(ESubscriptionActionTypes.ADMIN_ADD_STRIPE_SUBSCRIPTION as any, watchAdminCreateStripeSubscription),
  ]);
}

function* watchGetPaymentMethodByUserId(action: { type: string; payload: number }) {
  try {
    const { data } = yield call(subscriptionService.endpoint_get_payment_method_by_userId, action.payload);

    yield put(subscriptionAction.setSubscription(data));
  } catch (error: any) {
    console.error('watchGetPaymentMethodByUserId: ', error);
  }
}

function* watchGetSubscriptionByUserId(action: { type: string; payload: number }) {
  try {
    const { data } = yield call(subscriptionService.endpoint_get_subscription_by_userId, action.payload);

    yield put(subscriptionAction.setSubscription(data));
  } catch (error: any) {
    console.error('watchGetSubscriptionByUserId: ', error);
  }
}

function* watchDeleteSubscription(action: { type: string; payload: number }) {
  try {
    const { data } = yield call(subscriptionService.endpoint_delete_subscription, action.payload);

    yield put(subscriptionAction.setSubscription(data));
  } catch (error: any) {
    console.error('watchDeleteSubscription: ', error);
  }
}

function* watchAdminCreateInternalSubscription(action: {
  type: string;
  payload: { email: string; date: string; coupon: string | null; packageId: number };
}) {
  try {
    yield call(
      subscriptionService.endpoint_admin_create_internal_subscription,
      action.payload.email,
      action.payload.date,
      action.payload.coupon,
      action.payload.packageId,
    );
  } catch (error: any) {
    console.error('watchAdminCreateInternalSubscription: ', error);
  }
}

function* watchAdminCreateStripeSubscription(action: {
  type: string;
  payload: { email: string; packageId: number; coupon: string | null };
}) {
  try {
    yield call(
      subscriptionService.endpoint_admin_create_stripe_subscription,
      action.payload.email,
      action.payload.packageId,
      action.payload.coupon,
    );
  } catch (error: any) {
    console.error('watchAdminCreateSubscription: ', error);
  }
}
