import { useState } from 'react';
import Calendar from 'react-calendar';
import moment from 'moment';
import { subscriptionService, userService } from '../../services';
import cross from '../../images/cross.svg';
import calendar from '../../images/calendar-new.svg';
import './AddSubscriptionModal.css';

interface IUserModelForAdmin {
  id: number;
  email: string;
  name: string;
  typeSubscription: string;
  expiredAtSubscription: string;
  createdAtSubscription: string;
}

const AddSubscriptionModal = ({ close, email, user, setModalText }: any) => {
  const [date, setDate] = useState<Date | null>(new Date());
  const [isLoader, setIsLoader] = useState(false);
  const [internal, setInternal] = useState(false);
  const [monthly, setMonthly] = useState(false);
  const [hasCoupon, setHasCoupon] = useState(true);
  const [showCalendar, setShowCalendar] = useState(false);
  const [coupon, setCoupon] = useState('');

  const cancelButton = () => {
    setDate(null);
    close();
  };

  const createSubscription = async () => {
    if (email && user) {
      setIsLoader(true);
      if (date && internal) {
        const endDate = moment(date).toISOString();
        const res = await subscriptionService.endpoint_admin_create_internal_subscription(
          email,
          endDate,
          coupon,
          monthly ? 2 : 3,
        );

        if (res) {
          setModalText(res.data);
          setIsLoader(false);
        }

        const expiredDate: string = moment(date).endOf('day').toISOString();
        const createdDate: string = moment(new Date()).endOf('day').toISOString();
        const body: IUserModelForAdmin = {
          id: user.id,
          email: user.email,
          name: user.name,
          typeSubscription: 'internal',
          expiredAtSubscription: expiredDate,
          createdAtSubscription: createdDate,
        };

        await userService.endpoint_update_user_from_admin(body);
      } else if (!internal) {
        const packageId = monthly ? 2 : 3;
        setIsLoader(true);

        const res = await subscriptionService.endpoint_admin_create_stripe_subscription(email, packageId, coupon);
        if (res) {
          setModalText(res.data);
          setIsLoader(false);
        }
      }
      close();
    }
  };
  return (
    <div className="modal_content">
      <img onClick={close} src={cross} alt={'close'} />
      <div>
        <h2 className="modal_header text">Add Subscription</h2>
      </div>
      <div>
        <div className="type-subscription-radio-button">
          <div className="radio-option-block">
            <input
              className="radio-option"
              type="radio"
              value="yes"
              checked={!internal}
              onChange={() => setInternal(false)}
            />
            <p className={!internal ? 'option-selected option-text' : 'option-text'}>Paid</p>
          </div>
          <div className="radio-option-block">
            <input
              className="radio-option"
              type="radio"
              value="no"
              checked={internal}
              onChange={() => setInternal(true)}
            />
            <p className={internal ? 'option-selected option-text' : 'option-text'}>Internal</p>
          </div>
        </div>
      </div>
      <div>
        <div>
          <div>
            <div className="type-subscription-package-radio-button">
              <div className="radio-package-option-block">
                <input
                  className="package-radio-option"
                  type="radio"
                  value="yes"
                  checked={!monthly}
                  onChange={() => setMonthly(false)}
                />
                <p className={!monthly ? 'package-option-selected package-option-text' : 'package-option-text'}>
                  Annual
                </p>
              </div>
              <div className="radio-package-option-block">
                <input
                  className="package-radio-option"
                  type="radio"
                  value="no"
                  checked={monthly}
                  onChange={() => setMonthly(true)}
                />
                <p className={monthly ? 'package-option-selected package-option-text' : 'package-option-text'}>
                  Monthly
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        {internal && (
          <div>
            <div className="modal_body">
              <p className="text">Expires at:</p>
              <img
                src={calendar}
                alt="calendar-new"
                onClick={() => {
                  setShowCalendar(!showCalendar);
                }}
              />
              <input
                src={calendar}
                type="text"
                name="expireDate"
                className="calendar-input"
                placeholder={'Enter expirations date'}
                value={moment(date).format('MM/DD/YYYY')}
                onClick={() => {
                  setShowCalendar(!showCalendar);
                }}
              />
            </div>
            {showCalendar && (
              <div>
                <Calendar
                  className="custom_datepicker"
                  calendarType={'gregory'}
                  locale={'en'}
                  showNeighboringMonth={false}
                  formatWeekday={(locale, date) => moment(date).format('d')}
                  formatShortWeekday={(locale, date) => moment(date).format('dd').slice(0, 1)}
                  onChange={(date: any) => setDate(date)}
                />
              </div>
            )}
          </div>
        )}
      </div>
      <div className="radio-option-block checked-block">
        <input
          type="checkbox"
          name="hasCoupon"
          className="checked-input"
          checked={hasCoupon}
          onChange={() => {
            setHasCoupon(!hasCoupon);
          }}
        />
        <p className={hasCoupon ? 'checked-block-label active' : 'checked-block-label'}>Add Promotional Code</p>
      </div>
      {hasCoupon && (
        <input
          src={calendar}
          type="text"
          name="couponValue"
          className="coupon-input"
          placeholder={'Enter promotion code'}
          value={coupon}
          onChange={({ target }) => setCoupon(target.value)}
        />
      )}
      <div>
        <div className="modal_footer">
          <button className="add-sub-buttons cancel" onClick={cancelButton}>
            Cancel
          </button>
          <button className="add-sub-buttons ok" onClick={createSubscription}>
            {!isLoader && 'OK'}
            {isLoader && <div className="admin-loader" />}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddSubscriptionModal;
