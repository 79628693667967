import { all, call, put, takeLatest } from 'redux-saga/effects';

import axios, { AxiosResponse, CancelTokenSource } from 'axios';
import { EMyListsActionTypes } from '../../enums';
import { IMyList } from '../../models';
import { myListService } from '../../services';
import { loadingAction, myListsAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EMyListsActionTypes.CREATE_MY_LIST_AND_ADD_COLLEGE as any, watchCreateMyListAndAddCollege)]);
  yield all([takeLatest(EMyListsActionTypes.GET_MY_LISTS as any, watchGetMyLists)]);
  yield all([takeLatest(EMyListsActionTypes.ADD_COLLEGES_TO_MY_LIST as any, watchAddCollegeToMyList)]);
  yield all([takeLatest(EMyListsActionTypes.GET_MY_LIST as any, watchGetMyList)]);
  yield all([takeLatest(EMyListsActionTypes.CREATE_MY_LIST as any, watchCreateMyList)]);
  yield all([takeLatest(EMyListsActionTypes.DELETE_COLLEGE_FROM_MY_LIST as any, watchDeleteCollegeFromMyList)]);
  yield all([takeLatest(EMyListsActionTypes.DELETE_MY_LIST as any, watchDeleteMyList)]);
  yield all([takeLatest(EMyListsActionTypes.GET_LIST_BY_ID as any, watchGetListById)]);
  yield all([takeLatest(EMyListsActionTypes.ADD_COLLEGE_TO_ALL_LISTS as any, watchAddCollegeToAllLists)]);
  yield all([takeLatest(EMyListsActionTypes.DELETE_COLLEGE_FROM_ALL_LISTS as any, watchRemoveCollegeFromAllLists)]);
  yield all([takeLatest(EMyListsActionTypes.GET_SHARED_LIST as any, watchGetSharedList)]);
  yield all([takeLatest(EMyListsActionTypes.DELETE_ALL_COLLEGES_FROM_LIST as any, watchRemoveAllCollegesFromMyLists)]);
  yield all([takeLatest(EMyListsActionTypes.GET_PAGINATED_COLLEGES_MY_LIST as any, watchGetPaginatedList)]);
  yield all([
    takeLatest(EMyListsActionTypes.GET_RECOMMENDATIONS_AMOUNT_FOR_LIST as any, watchGetRecommendationsAmountForList),
  ]);
}

function* watchGetMyLists(action: { type: string; userId: number }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    const { userId } = action;
    const { data } = yield call(myListService.endpoint_get_my_lists, userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchGetMyLists: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchAddCollegeToMyList(action: { type: string; payload: any }) {
  try {
    yield call(myListService.endpoint_add_colleges_to_my_list, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchAddCollegeToMyList: ', error.response);
  }
}

function* watchAddCollegeToAllLists(action: { type: string; payload: any }) {
  try {
    yield call(myListService.endpoint_add_college_to_all_lists, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchAddCollegeToAllLists: ', error.response);
  }
}

function* watchRemoveCollegeFromAllLists(action: { type: string; payload: any }) {
  try {
    yield call(myListService.endpoint_delete_college_from_all_lists, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchRemoveCollegeFromAllLists: ', error.response);
  }
}

function* watchRemoveAllCollegesFromMyLists(action: { type: string; payload: any }) {
  try {
    yield call(myListService.endpoint_delete_all_colleges_from_my_list, action.payload.listId);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchRemoveAllCollegesFromMyLists: ', error.response);
  }
}

function* watchGetMyList(action: { type: string; payload: any }) {
  try {
    const { userId, listName } = action.payload;
    const { data } = yield call(myListService.endpoint_get_my_list, userId, listName);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchGetMyList: ', error.response);
  }
}

function* watchCreateMyListAndAddCollege(action: { type: string; payload: any }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    yield call(myListService.endpoint_create_list_and_add_college, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchCreateMyListAndAddCollege: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}
function* watchCreateMyList(action: { type: string; payload: any }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    yield call(myListService.endpoint_create_my_list, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchCreateMyList: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchDeleteMyList(action: { type: string; payload: any }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    yield call(myListService.endpoint_remove_my_list, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchDeleteMyList: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchDeleteCollegeFromMyList(action: { type: string; payload: any }) {
  try {
    yield call(myListService.endpoint_remove_college_from_my_list, action.payload);

    const { data } = yield call(myListService.endpoint_get_my_lists, action.payload.userId);

    yield put(myListsAction.setMyLists(data as IMyList[]));
  } catch (error: any) {
    console.error('watchDeleteCollegeFromMyList: ', error.response);
  }
}

function* watchGetListById(action: { type: string; payload: number }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    const { data } = yield call(myListService.endpoint_get_list_by_id, action.payload);

    if (data) {
      yield put(myListsAction.setSharedList(data));
      yield put(myListsAction.setSelectedList(data));
    }
  } catch (error: any) {
    console.error('watchGetListById: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchGetSharedList(action: { type: string; payload: string }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    const { data } = yield call(myListService.endpoint_get_shared_list, action.payload);

    yield put(myListsAction.setSharedList(data));
  } catch (error: any) {
    console.error('watchGetSharedList: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

// function* watchGetPaginatedList(action: { type: string; payload: any }) {
//   try {
//     yield put(
//       loadingAction.updateLoadingStatus({
//         myListLoading: true,
//       }),
//     );
//     const { data } = yield call(myListService.endpoint_get_paginated_list_by_id, action.payload);
//
//     yield put(myListsAction.setPaginatedList(data as IMyList));
//   } catch (error: any) {
//     console.error('watchGetPaginatedList: ', error.response);
//   } finally {
//     yield put(
//       loadingAction.updateLoadingStatus({
//         myListLoading: false,
//       }),
//     );
//   }
// }

let cancelTokenSource: CancelTokenSource | null = null;

function* watchGetPaginatedList(action: { type: string; payload: any }) {
  try {
    // Cancel any previous request if it exists
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }

    // Create a new cancel token source
    cancelTokenSource = axios.CancelToken.source();

    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: true,
      }),
    );

    // Store the current value to determine if a cancellation occurred
    const currentValue = action.payload.showRecommendations;

    // @ts-ignore
    const { data }: AxiosResponse<IMyList> = yield call(
      myListService.endpoint_get_paginated_list_by_id,
      action.payload,
      {
        cancelToken: cancelTokenSource.token,
      },
    );

    // Check if the current value has changed since the request started
    if (currentValue === action.payload.showRecommendations) {
      yield put(myListsAction.setPaginatedList(data));
    }
  } catch (error: any) {
    console.error('watchGetPaginatedList: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        myListLoading: false,
      }),
    );
  }
}

function* watchGetRecommendationsAmountForList(action: { type: string; payload: any }) {
  try {
    const { data } = yield call(myListService.get_recommended_for_list_amount, action.payload);

    yield put(myListsAction.setRecommendations(data.recommended));
  } catch (error: any) {
    console.error('watchGetRecommendationsAmountForList: ', error.response);
  }
}
