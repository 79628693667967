import { all, call, put, select, takeLatest } from 'redux-saga/effects';

import { EOffersActionTypes } from '../../enums';
import { IOffer } from '../../models';
import { offerService } from '../../services';
import { loadingAction, offersAction } from '../actions';
import { selectUserId } from '../selectors';

export default function* root() {
  yield all([takeLatest(EOffersActionTypes.GET_OFFERS as any, watchGetOffers)]);
  yield all([takeLatest(EOffersActionTypes.GET_CROWDSOURCED_OFFERS as any, watchGetCrowsourcedOffers)]);
}

function* watchGetCrowsourcedOffers(action: { type: string; payload: any }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getCrowdsourcedOffersLoading: true,
      }),
    );

    const { data } = yield call(offerService.endpoint_crowdsourced_offers, action.payload);

    yield put(offersAction.setOffers(data as Array<IOffer>));

    yield put(
      loadingAction.updateLoadingStatus({
        getCrowdsourcedOffersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetOffers: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getCrowdsourcedOffersLoading: false,
      }),
    );
  }
}

function* watchGetOffers() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: true,
      }),
    );

    const userId: number = yield select(selectUserId);
    const { data } = yield call(offerService.endpoint_get_offers, { userId });

    yield put(offersAction.setOffers(data as Array<IOffer>));

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  } catch (error: any) {
    console.error('watchGetOffers: ', error.response);

    yield put(
      loadingAction.updateLoadingStatus({
        getOffersLoading: false,
      }),
    );
  }
}
