import { axiosService } from '.';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const householdIncomeService = (function () {
  const endpoint_get_all_household_income = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_household_income);
  };

  return {
    endpoint_get_all_household_income,
  };
})();
