import { axiosService } from './axios.service';
import { getApiAccessToken } from '../utils';
import { apiUrls } from '../consts';

export const sharedListsService = (function () {
  const endpoint_create_sharedList = async (body) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.post(apiUrls.create_shared_list, body);
  };

  const endpoint_get_sharedList = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());
    return axiosService.get(apiUrls.get_shared_list_by_urlId(id));
  };

  return {
    endpoint_create_sharedList,
    endpoint_get_sharedList,
  };
})();
