/* eslint-disable */
import './Header.css';
import { useAuth0 } from '@auth0/auth0-react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { useEffect, useRef, useState } from 'react';
import localforage from 'localforage';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { ICollege } from '../../models';

import mobilenavigate from '../../images/navigate-menu-mobile.svg';
import mobilemenuactive from '../../images/mobile-menu-active.svg';
import cross from '../../images/icons/times-mobile.png';
import info from '../../images/info.svg';

export const HeaderMobile = () => {
  const mobileMenu = useRef(null);
  const mobileBtn = useRef(null);
  const menuRef = useRef(null);
  const submenuRef = useRef(null);
  const profileBtn = useRef(null);
  const childRef = useRef<any>();

  const [width, setWidth] = useState(window.innerWidth);

  //Dropwdowns
  const [menuDropdown, setMenuDropdownOpen] = useState(false);
  const toggleMenu = () => setMenuDropdownOpen((prevState) => !prevState);

  //Log User
  const { user, adminNavigatedUser } = useSelector((state: any) => state.userState);

  const { previousRoute } = useSelector((state: any) => state.previousRouteState);
  const { loginWithRedirect, logout, isAuthenticated } = useAuth0();
  const { pathname } = useLocation();
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isInfoHubVisibleAndGetStarted, setIsInfoHubVisibleAndGetStartedAndGetStarted] = useState(false);
  const [isGradesVisible, setIsGradesVisible] = useState(false);
  const [isProcessVisible, setIsProcessVisible] = useState(false);
  const [isOpenSaveListModal, setIsOpenSaveListModal] = useState<boolean>(false);
  const [isOpenAddNewListModal, setIsOpenAddNewListModal] = useState<boolean>(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const blockedClick = () => {
    return (
      previousRoute.payload === '/home-page-after-require' ||
      previousRoute.payload === '/require-pay' ||
      previousRoute.payload === '/upgrade' ||
      (previousRoute.payload === '/shared-list-after-require-pay' && window.location.pathname === '/buy-plan')
    );
  };

  const scrollToSection = (section_id) => {
    const section = document.getElementById(section_id);
    section ? section.scrollIntoView({ behavior: 'smooth' }) : '';
  };

  const closeSubmenuMobile = () => {
    setIsProcessVisible(false);
    setIsGradesVisible(false);
    setIsInfoHubVisibleAndGetStartedAndGetStarted(false);
  };

  useEffect(() => {
    const handleClickOutsideMenu = (event) => {
      const profBtn: any = profileBtn.current;
      const menu: any = menuRef.current;
      const submenu: any = submenuRef.current;
      const mobileMenuRef: any = mobileMenu.current;
      const mobileBtnRef: any = mobileBtn.current;

      if (
        mobileMenuRef &&
        !mobileMenuRef.contains(event.target) &&
        mobileBtnRef &&
        !mobileBtnRef.contains(event.target) &&
        menu &&
        !menu.contains(event.target) &&
        submenu &&
        !submenu.contains(event.target) &&
        profBtn &&
        !profBtn.contains(event.target)
      ) {
        setIsMenuVisible(false);
        closeSubmenuMobile();
      }
    };

    document.addEventListener('click', handleClickOutsideMenu);

    return () => {
      document.removeEventListener('click', handleClickOutsideMenu);
    };
  }, []);

  const handleSetIsOpenAddNewListModal = () => {
    open();
    setIsOpenAddNewListModal(!isOpenAddNewListModal);
    setIsOpenSaveListModal(false);
  };

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      {isAuthenticated ? (
        <>
          {pathname !== '/advanced-search' && (
            <div className="menu-mobile">
              <Dropdown
                isOpen={menuDropdown}
                toggle={toggleMenu}
                direction="down"
                className="dropdown-custom-header dropdown-bg-none text-black p-0"
              >
                <DropdownToggle block>
                  <img
                    className="menu"
                    ref={mobileBtn}
                    src={menuDropdown ? cross : mobilenavigate}
                    alt="Navigate menu Road2"
                  />
                </DropdownToggle>
                <DropdownMenu style={{ width: width }}>
                  <DropdownItem href="https://www.road2college.com/contact-us/">Contact Us</DropdownItem>
                  <DropdownItem href="https://www.road2college.com/college-insights-faqs">FAQs</DropdownItem>
                  <DropdownItem href="/my-profile">Your Profile</DropdownItem>
                  {user.role === 'admin' && (
                    <>
                      <DropdownItem href="/dashboard">Admin Dashboard</DropdownItem>
                      <DropdownItem
                        onClick={() => {
                          navigate('/admin-user-profile', { state: { user: adminNavigatedUser } });
                        }}
                      >
                        Visit User Profile
                      </DropdownItem>
                    </>
                  )}

                  <DropdownItem>
                    <NavLink to={blockedClick() ? `${window.location.pathname}` : '/my-lists'} className="text-black">
                      My Lists
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem href="https://www.road2college.com/updated-membership-plans/">
                    Subscription Options
                  </DropdownItem>

                  {!user.subscription && user.role !== 'admin' && (
                    <DropdownItem href="/buy-plan">Update Plan</DropdownItem>
                  )}
                  <DropdownItem>
                    <NavLink
                      to={'/home?calendar=true'}
                      className="text-black"
                      onClick={() => {
                        setTimeout(() => {
                          scrollToSection('calendar');
                        }, 150);
                      }}
                    >
                      Yearly Calendar
                    </NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink
                      to={'/home?calendar=true'}
                      className="text-black"
                      onClick={() => {
                        setTimeout(() => {
                          scrollToSection('recommendations');
                        }, 150);
                      }}
                    >
                      Recommendations
                    </NavLink>
                  </DropdownItem>

                  <DropdownItem href="/services">1:1 Services</DropdownItem>

                  <DropdownItem href="/buy-plan">Membership Plan</DropdownItem>

                  <DropdownItem href="/power-search" className="text-black">
                    Power Search
                  </DropdownItem>

                  <DropdownItem href="#scoresmart" className="text-black">
                    Free Practice Digital SAT
                  </DropdownItem>

                  <DropdownItem
                    className="nav-red"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      localStorage.clear();
                      localforage.clear().then(() => {
                        logout({
                          returnTo: window.location.origin,
                        });
                      });
                    }}
                  >
                    Logout
                  </DropdownItem>
                </DropdownMenu>
              </Dropdown>
            </div>
          )}

          {isMenuVisible && (
            <span>
              <div className="profile-menu-mobile-registered profile-menu-mobile-registered-log" ref={mobileMenu}>
                {/*  <NavLink className="header-mobile-question">
                      <img src={questionMobile} alt={'question icon'} />
                    </NavLink> */}
                <img
                  src={cross}
                  alt="cross"
                  onClick={() => {
                    if (blockedClick()) {
                      return;
                    }
                    setIsMenuVisible(false);
                    setIsInfoHubVisibleAndGetStartedAndGetStarted(false);
                  }}
                />

                <div className="header-mobile-item profile-menu-mobile-registered-container-dropdown">
                  <div
                    className={`header-mobile-item-submenu ${isInfoHubVisibleAndGetStarted ? 'active' : ''}`}
                    onClick={() => {
                      if (blockedClick()) {
                        return;
                      }
                      setIsInfoHubVisibleAndGetStartedAndGetStarted(!isInfoHubVisibleAndGetStarted);
                      setIsGradesVisible(false);
                      setIsProcessVisible(false);
                    }}
                    onBlur={() => {
                      setTimeout(() => {
                        setIsInfoHubVisibleAndGetStartedAndGetStarted(false);
                      }, 100);
                    }}
                  >
                    <div>
                      <img src={info} alt="info" />
                      Information Hub
                    </div>

                    <span />
                  </div>
                  {isInfoHubVisibleAndGetStarted && (
                    <ul className="header-mobile-subitem">
                      <li>
                        <h6
                          onClick={() => setIsGradesVisible(!isGradesVisible)}
                          className={`${isGradesVisible ? 'active' : ''}`}
                        >
                          BY GRADE: <span />
                        </h6>

                        {isGradesVisible && (
                          <div>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/grades/9th-grade/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                9th Grade
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/grades/10th-grade/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                10th Grade
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/grades/11th-grade/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                11th Grade
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/grades/12th-grade/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                12th Grade
                              </a>
                            </p>
                          </div>
                        )}
                      </li>

                      <li>
                        <h6
                          onClick={() => setIsProcessVisible(!isProcessVisible)}
                          className={`${isProcessVisible ? 'active' : ''}`}
                        >
                          BY STAGE IN THE PROCESS: <span />
                        </h6>

                        {isProcessVisible && (
                          <div>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/plan/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Plan</b> Academic & Financial
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/research/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Research</b> Colleges
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/apply/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Apply</b> to Colleges
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/compare/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Compare</b> College Offers
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/decide/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Decide</b> Where to Attend
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/pay/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Pay</b> for College
                              </a>
                            </p>
                            <p>
                              <a
                                target="_blank"
                                href="https://www.road2college.com/stages/go/"
                                rel="noreferrer"
                                onClick={() => {
                                  setIsMenuVisible(false);
                                  closeSubmenuMobile();
                                }}
                              >
                                <b>Go</b> to College!
                              </a>
                            </p>
                          </div>
                        )}
                      </li>
                    </ul>
                  )}
                </div>
              </div>
            </span>
          )}
        </>
      ) : (
        <>
          <span>
            {pathname === '/advanced-search' || (
              <img
                className="menu"
                onClick={() => {
                  if (blockedClick()) {
                    return;
                  }
                  setIsMenuVisible(!isMenuVisible);
                }}
                onBlur={() => {
                  setIsMenuVisible(false);
                }}
                src={isMenuVisible ? mobilemenuactive : mobilenavigate}
                alt={'Navigate menu'}
              />
            )}
            {isMenuVisible && (
              <div className="profile-menu-mobile-registered">
                <div className="profile-menu-mobile-registered-container-btn">
                  <button
                    className="profile-menu-mobile-registered-get-started"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      if (blockedClick()) {
                        return;
                      }
                      open();
                    }}
                  >
                    Get Started
                  </button>
                  <button
                    className="profile-menu-mobile-registered-login"
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      if (blockedClick()) {
                        return;
                      }
                      setIsMenuVisible(false);
                      if (pathname === '/create-profile') {
                        localStorage.clear();
                        localforage.clear().then(() => {
                          logout({
                            returnTo: window.location.origin,
                          });
                        });
                      }
                      loginWithRedirect();
                    }}
                  >
                    Login
                  </button>
                </div>
              </div>
            )}
          </span>
        </>
      )}
    </>
  );
};
