import './Membership.css';
import { useEffect } from 'react';
import { MembershipPlan } from '../membership-plan/Membership-plan';

const planOptions = [
  {
    id: 1,
    title: 'INSIGHTS',
    name: 'essential',
    price: 24.99,
    class: 'free-plan',
    options: [
      'Create Saved list of favorite colleges',
      'Filter, sort and compare unlimited colleges',
      'Search by 75th Percentile and Merit Aid data',
    ],
  },
  {
    id: 2,
    title: 'INSIGHTS ACADEMY',
    additionally: 'Most Popular',
    name: 'plus',
    price: 39.99,
    class: 'popular-plan',
    options: [
      'Full access to our Insights™ tool',
      'Membership to our Paying for College 101™ group',
      'Complete access to our Insights™ Academy (IA) — full library of past recordings',
      '20% discount on services',
    ],
  },
  {
    id: 3,
    title: 'INSIGHTS ACADEMY',
    name: 'live',
    price: 79.99,
    class: 'plus-plan',
    options: [
      'Full access to our Insights™ tool',
      'Membership to our private, expert-led, Live community with Q&A support',
      'Complete access to our Insights™ Academy (IA) — full library of past recordings',
      'Live classes/Q&A with our experts (4-6 sessions monthly)',
      '30% discount on services',
    ],
  },
];

export const Membership = () => {
  useEffect(() => {
    const element = document.getElementsByClassName('membership-plans');
    element[0].scrollTo(0.41 * window.innerWidth, 0);
  }, []);
  return (
    <div className="membership">
      <h4>Membership Options</h4>
      <p>
        A membership for families looking for support and guidance on their admissions and paying for college journey.
      </p>
      <div className="membership-plans">
        {planOptions.map((option) => (
          <MembershipPlan key={option.name} plan={option} />
        ))}
      </div>
    </div>
  );
};
