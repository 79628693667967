import { EUserActionTypes } from '../../enums';
import { IUser, IUpdateUser, ICountFree } from '../../models';

const getUser = (payload: { email: string }) => {
  return {
    type: EUserActionTypes.GET_USER,
    payload,
  };
};

const setUser = (payload: IUser) => {
  return {
    type: EUserActionTypes.SET_USER,
    payload,
  };
};

const getAdminUser = (payload: { email: string }) => {
  return {
    type: EUserActionTypes.GET_ADMIN_USER,
    payload,
  };
};

const setAdminUser = (payload: IUser | undefined) => {
  return {
    type: EUserActionTypes.SET_ADMIN_USER,
    payload,
  };
};

const updateUser = (payload: { user: IUpdateUser; next?: Function }) => {
  return {
    type: EUserActionTypes.UPDATE_USER,
    payload,
  };
};

export const userAction = {
  getUser,
  setUser,
  updateUser,
  getAdminUser,
  setAdminUser,
};
