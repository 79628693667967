import './CustomCheckbox.css';
import { ReactNode } from 'react';

type Props = {
  onHandleChecked: Function;
  checked: boolean;
  label?: string;
  border?: string;
  backgroundColor?: string;
  checkMarkColor?: string;
  width?: string;
  height?: string;
  unChecked?: boolean;
  children?: ReactNode;
  checkedInRedux?: boolean;
  additionalClass?: string;
  additionalClassForInput?: string;
};

export const CustomCheckbox = ({
  onHandleChecked,
  checked,
  unChecked,
  label,
  border = '1.5px solid #030054',
  backgroundColor = '#ffffff',
  checkMarkColor = '#030054',
  width = '16px',
  height = '16px',
  children,
  checkedInRedux,
  additionalClass = '',
  additionalClassForInput = '',
}: Props) => {
  return (
    <div
      className={`custom-checkbox ${additionalClass}`}
      onClick={(e) => {
        e.stopPropagation();
        onHandleChecked(!checked);
      }}
    >
      <div
        className={`custom-checkbox__input ${additionalClassForInput}`}
        style={{ border, backgroundColor, width, height }}
      >
        {checked && (
          <svg width="10" height="8" viewBox="0 0 10 8" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g id="Vector">
              <path
                id="checkmark"
                d="M8.5 1L3.25 7.25L1 4.75"
                stroke={checkMarkColor}
                stroke-width="1.5"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
          </svg>
        )}
        {unChecked && (
          <svg width="15" height="14" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9 7H4"
              stroke={checkMarkColor}
              stroke-width="1.5"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        )}
      </div>
      {label && <span className={`custom-checkbox__text ${checkedInRedux ? 'checked' : 'unchecked'}`}>{label}</span>}
      {children}
    </div>
  );
};
