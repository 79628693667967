import { all, call, put, takeLatest } from 'redux-saga/effects';
import { EBackupsActionTypes } from '../../enums';
import { backupsService } from '../../services';
import { listBackupsAction, loadingAction } from '../actions';

export default function* root() {
  yield all([takeLatest(EBackupsActionTypes.CREATE_BACKUP as any, watchCreateBackup)]);
  yield all([takeLatest(EBackupsActionTypes.DELETE_BACKUP_BY_NAME as any, watchDeleteBackupByName)]);
  yield all([takeLatest(EBackupsActionTypes.DOWNLOAD_BACKUP_BY_NAME as any, watchDownloadBackup)]);
  yield all([takeLatest(EBackupsActionTypes.RESTORE_BACKUP_BY_NAME as any, watchRestoreBackup)]);
}

function* watchDeleteBackupByName(action: { type: string; payload: string }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        deleteBackupLoading: true,
      }),
    );
    const backupName = action.payload;

    yield call(backupsService.endpoint_delete_backup_by_name, backupName);
    yield put(listBackupsAction.getListBackups());
  } catch (error: any) {
    console.error('watchDeleteBackupByName: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        deleteBackupLoading: false,
      }),
    );
  }
}

function* watchCreateBackup() {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        createBackupLoading: true,
      }),
    );

    yield call(backupsService.endpoint_create_backup);
    yield put(listBackupsAction.getListBackups());
  } catch (error: any) {
    console.error('watchCreateBackups: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        createBackupLoading: false,
      }),
    );
  }
}
function* watchDownloadBackup(action: { type: string; payload: string }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        downloadBackupLoading: true,
      }),
    );

    const backupName = action.payload;
    yield call(backupsService.endpoint_download_backup_by_name, backupName);
  } catch (error: any) {
    console.error('watchDownloadBackups: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        downloadBackupLoading: false,
      }),
    );
  }
}

function* watchRestoreBackup(action: { type: string; payload: string }) {
  try {
    yield put(
      loadingAction.updateLoadingStatus({
        restoreBackupLoading: true,
      }),
    );

    const backupName = action.payload;
    yield call(backupsService.endpoint_restore_backup_by_name, backupName);
  } catch (error: any) {
    console.error('watchRestoreBackups: ', error.response);
  } finally {
    yield put(
      loadingAction.updateLoadingStatus({
        restoreBackupLoading: false,
      }),
    );
  }
}
