import React, { useRef, useState } from 'react';
import Slider from '@mui/material/Slider';
import sharedStyles from '../Shared.module.css';
import { EPlatform } from '../../../../../../enums/platform.enum';
import { Autocomplete } from '../../../../../autocomplete/Autocomplete';
import { useClickOutside } from '../../../../../../hooks/useClickOutside';
import { IPowerSearchFilterOption } from '../../../../../../models';

const attendanceValues: IPowerSearchFilterOption<number>[] = [
  { id: 1, name: '$0', value: 0 },
  { id: 2, name: '$10k', value: 10000 },
  { id: 3, name: '$20k', value: 20000 },
  { id: 4, name: '$30k', value: 30000 },
  { id: 5, name: '$40k', value: 40000 },
  { id: 6, name: '$50k', value: 50000 },
  { id: 7, name: '$60k', value: 60000 },
  { id: 8, name: '$60k+', value: 999999 },
];

const costOfAttendanceTypes: IPowerSearchFilterOption<string>[] = [
  { id: 1, name: 'Out of State', value: 'outState' },
  { id: 2, name: 'In State', value: 'inState' },
  { id: 3, name: 'Private', value: 'private' },
  { id: 4, name: 'Unknown', value: 'unknown' },
];

function valuetext(value: number) {
  return `${value}`;
}

interface CostOfAttendanceFilterProps {
  checkboxesSelected: string[];
  changeCheckboxesSelected: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedValues: { min: number | null; max: number | null } | null;
  selectMin: (value: IPowerSearchFilterOption<number>) => void;
  selectMax: (value: IPowerSearchFilterOption<number>) => void;
  rangeValues: number[];
  selectedRangeValues: number[];
  handleChangeRange: (event: Event, newValue: number | number[], activeThumb: number) => void;
  platform: EPlatform;
}

export const CostOfAttendanceFilter: React.FC<CostOfAttendanceFilterProps> = ({
  checkboxesSelected,
  changeCheckboxesSelected,
  selectedValues,
  selectMin,
  selectMax,
  rangeValues,
  selectedRangeValues,
  handleChangeRange,
  platform,
}) => {
  const [isCostOfAttendanceMinSelectActive, setIsCostOfAttendanceMinSelectActive] = useState<boolean>(false);
  const [isCostOfAttendanceMaxSelectActive, setIsCostOfAttendanceMaxSelectActive] = useState<boolean>(false);

  const costOfAttendanceMinSelectRef = useRef<HTMLDivElement | null>(null);
  const costOfAttendanceMaxSelectRef = useRef<HTMLDivElement | null>(null);

  useClickOutside(costOfAttendanceMinSelectRef, [], () => {
    if (isCostOfAttendanceMinSelectActive) setIsCostOfAttendanceMinSelectActive(false);
  });
  useClickOutside(costOfAttendanceMaxSelectRef, [], () => {
    if (isCostOfAttendanceMaxSelectActive) setIsCostOfAttendanceMaxSelectActive(false);
  });

  return (
    <div className={sharedStyles.container}>
      <h5 className={sharedStyles.title}>Cost of Attendance (COA)</h5>
      <div className={sharedStyles.checkboxesContainer}>
        {costOfAttendanceTypes.map((el) => {
          return (
            <div key={el.id} className={sharedStyles.checkboxContainer}>
              <input
                type="checkbox"
                value={el.value}
                checked={checkboxesSelected.includes(el.value)}
                onChange={changeCheckboxesSelected}
                name="costOfAttendanceCheckboxes"
                id={el.value}
              />
              <label className={sharedStyles.checkboxLabel} htmlFor={el.value}>
                {el.name}
              </label>
            </div>
          );
        })}
      </div>
      {platform === EPlatform.DESKTOP && (
        <div className={sharedStyles.buttonsContainer}>
          <div
            onClick={() => setIsCostOfAttendanceMinSelectActive(!isCostOfAttendanceMinSelectActive)}
            ref={costOfAttendanceMinSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.min && selectedValues?.min !== 0
              ? 'Min'
              : attendanceValues[attendanceValues.findIndex((value) => value.value === selectedValues.min)]?.name ||
                `$${selectedValues.min.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isCostOfAttendanceMinSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={attendanceValues}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMin(value);
                  setIsCostOfAttendanceMinSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
          <div
            onClick={() => setIsCostOfAttendanceMaxSelectActive(!isCostOfAttendanceMaxSelectActive)}
            ref={costOfAttendanceMaxSelectRef}
            className={sharedStyles.button}
          >
            {!selectedValues?.max && selectedValues?.max !== 0
              ? 'Max'
              : attendanceValues[attendanceValues.findIndex((value) => value.value === selectedValues.max)]?.name ||
                `$${selectedValues.max.toLocaleString('en-US')}`}
            <span className={sharedStyles.buttonArrowIcon} />
            {isCostOfAttendanceMaxSelectActive && (
              <Autocomplete
                isBudgetItems={false}
                filteredItem={attendanceValues}
                onclick={(value: IPowerSearchFilterOption<number>) => {
                  selectMax(value);
                  setIsCostOfAttendanceMaxSelectActive(false);
                }}
                classN={sharedStyles.autocomplete}
              />
            )}
          </div>
        </div>
      )}
      {(platform === EPlatform.TABLET || platform === EPlatform.MOBILE) && (
        <div className={sharedStyles.rangeWrapper}>
          <Slider
            sx={{
              width: '95%',
              color: '#7e106e',
              '& .MuiSlider-thumb': {
                background: 'white',
                border: '2px solid #7e106e',
              },
            }}
            getAriaLabel={() => 'Minimum distance'}
            step={10000}
            min={rangeValues[0]}
            max={rangeValues[1]}
            value={selectedRangeValues}
            onChange={handleChangeRange}
            valueLabelDisplay="on"
            getAriaValueText={valuetext}
            valueLabelFormat={(value) => `$${value.toLocaleString('en-US')}`}
            disableSwap
          />
        </div>
      )}
    </div>
  );
};
