/* eslint-disable */
import './services-component.css';
import { Link } from 'react-router-dom';

import { useEffect, useState } from 'react';
import { WooCommerce } from '../../utils/woocommerce.util';
import placeholderProduct from '../../images/placeholders/placeholder-image.png';
import { ShimmerThumbnail } from 'react-shimmer-effects';
import _ from 'lodash';

export const ServicesComponent = () => {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  function getProducts() {
    setIsLoading(true);
    WooCommerce.get('products/?category=562&orderby=menu_order&order=asc')
      .then((response) => {
        setProducts(response.data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.log(error.response.data);
      });
  }

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <div className="service-component">
      <h1 className="title">Services</h1>

      <div className="row">
        {isLoading && (
          <>
            {_.times(8, (i) => (
              <ShimmerThumbnail height={430} rounded />
            ))}
          </>
        )}

        {products &&
          products.map((product: any, i: number) => (
            <div className="card">
              <img
                src={product.images.length > 0 ? product.images[0].src : placeholderProduct}
                alt=""
                className="card__image"
              />
              <div className="card__information__container">
                <div>
                  <h1 className="card__title">{product.name}</h1>
                  <p className="card__price">Non-Member price ${product.regular_price}</p>
                </div>
                <div className="card__button__container">
                  <a href={product.permalink} className="card__button">
                    Buy now
                  </a>
                </div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
};
