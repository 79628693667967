import { useState } from 'react';
import styles from './SingleInputModal.module.css';
import cross from '../../images/cross.svg';

export const SingleInputModal = ({
  title,
  inputPlaceholder,
  positiveButtonText,
  negativeButtonText,
  close,
  onConfirmHandler,
  isLoading,
  err,
  hideError,
  previousInputValue,
  maxLength,
}: {
  title: string;
  inputPlaceholder: string;
  positiveButtonText: string;
  negativeButtonText: string;
  close: any;
  onConfirmHandler: (inputValue: string) => Promise<void>;
  isLoading?: boolean;
  err?: string;
  hideError?: () => void;
  previousInputValue?: string;
  maxLength?: number;
}) => {
  const [inputValue, setInputValue] = useState(previousInputValue || '');

  return (
    <div onClick={(e) => e.stopPropagation()} className={styles.modalContainer}>
      <img onClick={close} src={cross} alt="cross" />
      <h1>{title}</h1>
      <input
        placeholder={inputPlaceholder}
        value={inputValue}
        onChange={({ target }) => setInputValue(target.value)}
        onInput={hideError}
        maxLength={maxLength}
      />
      {err && <div className={styles.error}>{err}</div>}
      <div className={styles.buttonWrapper}>
        <button type="button" className={styles.negativeButton} onClick={close}>
          {negativeButtonText}
        </button>
        <button type="button" className={styles.positiveModalButton} onClick={() => onConfirmHandler(inputValue)}>
          {isLoading ? <div className={styles.loader} /> : positiveButtonText}
        </button>
      </div>
    </div>
  );
};
