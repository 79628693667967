import { axiosService } from './axios.service';
import { apiUrls } from '../consts';
import { getApiAccessToken } from '../utils';

export const backupsService = (function () {
  const endpoint_delete_backup_by_name = async (backupName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.delete_backup_by_name(backupName));
  };

  const endpoint_create_backup = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.create_backup);
  };

  const endpoint_download_backup_by_name = async (backupName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.download_backup_by_name(backupName));
  };

  const endpoint_restore_backup_by_name = async (backupName: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.restore_backup_by_name(backupName));
  };
  return {
    endpoint_delete_backup_by_name,
    endpoint_download_backup_by_name,
    endpoint_create_backup,
    endpoint_restore_backup_by_name,
  };
})();
