import { EBackupsActionTypes } from '../../enums';

const getListBackups = () => {
  return {
    type: EBackupsActionTypes.GET_LIST_BACKUPS,
  };
};

const setListBackups = (payload: Array<any>) => {
  return {
    type: EBackupsActionTypes.SET_LIST_BACKUPS,
    payload,
  };
};

export const listBackupsAction = {
  getListBackups,
  setListBackups,
};
