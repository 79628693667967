import { EPowerSearchTypes } from '../../enums';
import { ICollege, IMajors, IMinMax, IPowerSearchFilterOption, IRegion, IState } from '../../models';

const clearFilters = () => {
  return {
    type: EPowerSearchTypes.CLEAR_FILTERS,
  };
};

const setSort = (payload: { id: number; name: string; property: string }) => {
  return {
    type: EPowerSearchTypes.SET_SORT,
    payload,
  };
};

const addSelectedCollege = (payload: ICollege) => {
  return {
    type: EPowerSearchTypes.ADD_SELECTED_COLLEGE,
    payload,
  };
};

const removeFromSelectedColleges = (payload: number) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_SELECTED_COLLEGES,
    payload,
  };
};

const addSelectedMajor = (payload: IMajors) => {
  return {
    type: EPowerSearchTypes.ADD_SELECTED_MAJOR,
    payload,
  };
};

const removeFromSelectedMajors = (payload: number) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_SELECTED_MAJORS,
    payload,
  };
};

const addSelectedLocation = (payload: IState | IRegion) => {
  return {
    type: EPowerSearchTypes.ADD_SELECTED_LOCATION,
    payload,
  };
};

const removeFromSelectedLocations = (payload: number) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_SELECTED_LOCATIONS,
    payload,
  };
};

const addSelectedApplicationDeadline = (payload: IPowerSearchFilterOption<string>) => {
  return {
    type: EPowerSearchTypes.ADD_SELECTED_APPLICATION_DEADLINE,
    payload,
  };
};

const removeFromSelectedApplicationDeadline = (payload: number) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_SELECTED_APPLICATION_DEADLINES,
    payload,
  };
};

const addCostOfAttendanceTypeSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.ADD_COST_OF_ATTENDANCE_TYPE_SELECTED,
    payload,
  };
};

const removeFromCostOfAttendanceTypesSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_COST_OF_ATTENDANCE_TYPES_SELECTED,
    payload,
  };
};

const setCostOfAttendance = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_COST_OF_ATTENDANCE,
    payload,
  };
};

const setAvgMeritAwardSelected = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_AVG_MERIT_AWARD_SELECTED,
    payload,
  };
};

const setAvgMeritAwardTypeSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.SET_AVG_MERIT_AWARD_TYPE_SELECTED,
    payload,
  };
};

const addNetCostTypeSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.ADD_NET_COST_TYPE_SELECTED,
    payload,
  };
};

const removeFromNetCostTypeSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.REMOVE_FROM_NET_COST_TYPE_SELECTED,
    payload,
  };
};

const setNetCostAfterAvgMeritAwardSelected = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_NET_COST_AFTER_AVG_MERIT_AWARD_SELECTED,
    payload,
  };
};

const setNeedMetSelected = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_NEED_MET_SELECTED,
    payload,
  };
};

const setSchoolSize = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_SCHOOL_SIZE,
    payload,
  };
};

const setTestScoreValueSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.SET_TEST_SCORE_VALUE_SELECTED,
    payload,
  };
};

const setSatScoreSelected = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_SAT_SCORE_SELECTED,
    payload,
  };
};

const setActScoreSelected = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_ACT_SCORE_SELECTED,
    payload,
  };
};

const setSelectedTestScoreType = (payload: string) => {
  return {
    type: EPowerSearchTypes.SET_SELECTED_TEST_SCORE_TYPE,
    payload,
  };
};

const setGpa = (payload: IMinMax) => {
  return {
    type: EPowerSearchTypes.SET_GPA,
    payload,
  };
};

const setStudentGPATypeSelected = (payload: string) => {
  return {
    type: EPowerSearchTypes.SET_STUDENT_GPA_TYPE_SELECTED,
    payload,
  };
};

export const powerSearchAction = {
  clearFilters,
  setSort,
  addSelectedCollege,
  removeFromSelectedColleges,
  addSelectedMajor,
  removeFromSelectedMajors,
  addSelectedLocation,
  removeFromSelectedLocations,
  addSelectedApplicationDeadline,
  removeFromSelectedApplicationDeadline,
  addCostOfAttendanceTypeSelected,
  removeFromCostOfAttendanceTypesSelected,
  setCostOfAttendance,
  setAvgMeritAwardSelected,
  setAvgMeritAwardTypeSelected,
  addNetCostTypeSelected,
  removeFromNetCostTypeSelected,
  setNetCostAfterAvgMeritAwardSelected,
  setNeedMetSelected,
  setSchoolSize,
  setTestScoreValueSelected,
  setSatScoreSelected,
  setActScoreSelected,
  setSelectedTestScoreType,
  setGpa,
  setStudentGPATypeSelected,
};
