import './Info-locked-modal.css';
import { NavLink } from 'react-router-dom';
import cross from '../../images/cross.svg';
import lock from '../../images/locked-icon-blue.svg';

export const InfoLockedModal = ({
  close,
  textForTitle,
  textForDescription,
  textSecondBtn,
  withClose = true,
}: {
  close: any;
  textForTitle?: string;
  textForDescription?: string;
  textSecondBtn?: string;
  withClose?: boolean;
}) => {
  const clickBtn = () => {
    close();
  };

  return (
    <div className="modal-advanced-search-universal modal-not-allowed">
      {withClose && <img onClick={close} src={cross} alt="cross" className="modal-advanced-search-universal-img" />}
      <img src={lock} alt="cross" className="lock-icon" />
      <h5>{textForTitle}</h5>
      <p>{textForDescription}</p>
      <div>
        <NavLink to={'/buy-plan'} type="button" className="modal-advanced-search-universal-button" onClick={clickBtn}>
          {textSecondBtn}
        </NavLink>
      </div>
    </div>
  );
};
