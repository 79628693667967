import { createSelector } from 'reselect';

import { ICollegesState, IRootState } from '../../models';
import { selectUserStateId } from './user.selector';

export const selectCollegesState = (rootState: IRootState): ICollegesState => rootState.collegesState;

export const selectBasicColleges = createSelector([selectCollegesState], (collegesState) => collegesState.colleges);

export const selectSelectedCollegeId = createSelector(
  [selectCollegesState],
  (collegesState) => collegesState.selectedCollegeId,
);

export const selectCollegesByState = createSelector([selectBasicColleges, selectUserStateId], (colleges, stateId) => {
  return colleges.filter((c) => c?.state?.id === stateId);
});
