/* eslint-disable */
import './ScoreSmartModal.css';
import { useState } from 'react';
import crypto from "crypto-js";
import { useDispatch, useSelector } from 'react-redux';
import { scoreSmartService } from '../../services/scoresmart.service';
import cross from '../../images/cross.svg';
import {IUser} from "../../models";
import {userAction} from "../../store/actions";

const createScoreSmart = (username, parentemail) => {
    const app_key = 'abdjsbdwddasjbddig';
    const app_secret = 'ldfvnkjdbckjbddig';
    const path= '/api/create/login/user';
    const query_string = 'accesskey='+app_key+'&timestamp='+(Date.now()+36000);
    const auth_token = crypto.HmacSHA1('POST'+path+'?'+query_string, app_secret);
    const url= 'https://digitaltests.score-smart.com'+path+'?'+query_string+'&authtoken='+auth_token;
    const body = {
        username,
        parentemail
    }

    const request = fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(body)
    }).then(
        response => response.json()
    ).then(
        json => {
            window.open(json, '_blank')
        }
    );
    return [url] as const;
}

export const ScoreSmartModal = ({
  user,
  close,
  modalType,
}: {
  user?: IUser;
  close: any;
  modalType?: number;
}) => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState('');
    // @ts-ignore
  const userType = user.role === 'student' ? "parent's" : "student's";
  return (
    <div className={`modal-first ${modalType === 2 ? 'small' : ''}`}>
      <img onClick={close} src={cross} alt="cross" />
    <h1>
        Enter your { userType } email address to get your free test today!
    </h1>
        <div className="modal-first-body">
            <input id={"ssusername"} type={"text"} placeholder={"name@domain.com"} value={inputValue}
                   onChange={(e) => setInputValue(e.target.value)}/>
            <div id={"ss-error-message"} style={{color: 'red'}}/>
        </div>
        <div className="modal-first-footer">
            <button type="button" className="modal-first-confirm" onClick={(e) => {
            e.stopPropagation();
            let username = '';
            let parentemail = '';
            // @ts-ignore
            const userinput = document.getElementById('ssusername').value;
            const errorContainer = document.getElementById('ss-error-message');
            if(!userinput) {
                // @ts-ignore
                errorContainer.innerHTML = '<p>Please enter an email address</p>';
                return;
            }
            if(!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(userinput)) {
                // @ts-ignore
                errorContainer.innerHTML = '<p>Email address is invalid</p>';
                return;
            }
            // @ts-ignore
            if(user.role==='student') {
                // @ts-ignore
                username = user.email;
                parentemail = userinput;
            } else {
                username = userinput;
                // @ts-ignore
                parentemail = user.email;
            }
            // @ts-ignore
            scoreSmartService.createScoreSmartAccount(username, parentemail);
            dispatch(userAction.updateUser({ user: {
                // @ts-ignore
                email: user.email,
                scoresmartUsername: username,
                scoresmartParentEmail: parentemail
            }}));
            close();
        }}>
          Get Your Free Test!
        </button>
      </div>
    </div>
  );
};
