import { axiosService } from '.';
import { apiUrls } from '../consts';
import { IAdvancedSearch, ICollege, IPowerSearch, IUpdatedRecommendedSearch } from '../models';
import { getApiAccessToken } from '../utils';

export const collegeService = (function () {
  const endpoint_get_colleges = async () => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_colleges);
  };

  const endpoint_get_colleges_names = async () => {
    return axiosService.get(apiUrls.get_colleges_names);
  };

  const endpoint_get_college_by_id = async (collegeId: number) => {
    return axiosService.get(apiUrls.get_college_by_id(collegeId));
  };

  const endpoint_get_full_college_by_id = async (collegeId: number, userId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_full_college_by_id(collegeId, userId));
  };

  const endpoint_get_nearest_college = async (payload: string) => {
    return axiosService.get(apiUrls.get_nearest_colleges(payload));
  };

  const endpoint_get_colleges_for_landing_page = async (payload: string) => {
    return axiosService.get(apiUrls.get_colleges_for_landing_page(payload));
  };

  const endpoint_get_colleges_by_size = async () => {
    return axiosService.get(apiUrls.get_colleges_by_size);
  };

  const endpoint_get_private_colleges = async () => {
    return axiosService.get(apiUrls.get_private_colleges);
  };

  const endpoint_get_public_colleges = async () => {
    return axiosService.get(apiUrls.get_public_colleges);
  };

  const endpoint_update_college = async (payload: { collegeId: number; data: ICollege }) => {
    const { collegeId, data } = payload;
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.put_college(collegeId), data);
  };

  const endpoint_post_college_advanced_search = async (options: IAdvancedSearch | IPowerSearch) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.get_colleges_advanced_search, options);
  };

  const endpoint_get_recommended_colleges = async (payload: { userId: number; category: string }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_recommended_colleges(payload.userId));
  };

  const endpoint_get_recommended_colleges_by_category = async (payload: { userId: number; category: string }) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_recommended_colleges_by_category(payload.userId, payload.category));
  };

  const endpoint_post_updated_recommended_colleges_by_category = async (options: IUpdatedRecommendedSearch) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.get_updated_recommended_colleges_by_category, options);
  };

  return {
    endpoint_get_colleges,
    endpoint_update_college,
    endpoint_get_colleges_by_size,
    endpoint_get_private_colleges,
    endpoint_get_public_colleges,
    endpoint_get_colleges_names,
    endpoint_get_college_by_id,
    endpoint_get_nearest_college,
    endpoint_post_college_advanced_search,
    endpoint_get_full_college_by_id,
    endpoint_get_recommended_colleges,
    endpoint_get_recommended_colleges_by_category,
    endpoint_get_colleges_for_landing_page,
    endpoint_post_updated_recommended_colleges_by_category,
  };
})();
