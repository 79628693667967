import { EPreviousRouteTypes } from '../../enums';

const getPreviousRoute = () => {
  return {
    type: EPreviousRouteTypes.GET_PREVIOUS_ROUTE,
  };
};

const setPreviousRoute = (payload: string) => {
  return {
    type: EPreviousRouteTypes.SET_PREVIOUS_ROUTE,
    payload,
  };
};

export const previousRouteAction = {
  setPreviousRoute,
  getPreviousRoute,
};
