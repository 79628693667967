import { ESubscriptionActionTypes } from '../../enums';
import { ISubscription } from '../../models';

const getPaymentMethodByUserId = (payload: number) => {
  return {
    type: ESubscriptionActionTypes.GET_PAYMENT_METHOD_BY_USER_ID,
    payload,
  };
};

const getSubscriptionByUserId = (payload: number) => {
  return {
    type: ESubscriptionActionTypes.GET_SUBSCRIPTION_BY_USERID,
    payload,
  };
};

const deleteSubscription = (payload: number) => {
  return {
    type: ESubscriptionActionTypes.DELETE_SUBSCRIPTION,
    payload,
  };
};

const setSubscription = (payload: ISubscription | null) => {
  return {
    type: ESubscriptionActionTypes.SET_SUBSCRIPTION,
    payload,
  };
};

const adminCreateInternalSubscription = (payload: { email: string; date: string; coupon: string | null }) => {
  return {
    type: ESubscriptionActionTypes.ADMIN_ADD_INTERNAL_SUBSCRIPTION,
    payload,
  };
};

const adminCreateStripeSubscription = (payload: { email: string; packageId: number; coupon: string | null }) => {
  return {
    type: ESubscriptionActionTypes.ADMIN_ADD_STRIPE_SUBSCRIPTION,
    payload,
  };
};

export const subscriptionAction = {
  getPaymentMethodByUserId,
  setSubscription,
  deleteSubscription,
  adminCreateInternalSubscription,
  adminCreateStripeSubscription,
  getSubscriptionByUserId,
};
