import { useDispatch, useSelector } from 'react-redux';
import React, { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import { myListService } from '../../services';
import { selectedCollegesForSharingAction } from '../../store/actions';
import { CustomCheckbox, CustomLoader, ISharedCollege, ISharedListBeforeSending, SocialMedias } from '..';
import { IMyList, IRootState } from '../../models';
import './SocialMediaModal.css';
import arrowInput from '../../images/select-arrow-grey.svg';
import minus from '../../images/minus.svg';
import plusViolet from '../../images/plusForForm.svg';
import minusViolet from '../../images/minusForForm.svg';
import noResult from '../../images/noResult.svg';

interface ITitle {
  readonly title: string;
  readonly titleSecond: string;
  readonly titleLeftColumn: string;
  readonly titleRightColumn: string;
}

const step: ITitle = {
  title: 'Choose Colleges to Share',
  titleSecond: '(max of 30)',
  titleLeftColumn: 'Colleges in My List',
  titleRightColumn: 'Colleges to Share',
};

type Props = {
  closeModal: () => void;
  openEmailModal: () => void;
  onHandleClickShareList: () => Promise<void>;
  onHandleSetBasketLists: React.Dispatch<React.SetStateAction<any[]>>;
  onHandleSetList: React.Dispatch<React.SetStateAction<IMyList | null>>;
  deleteObjectInSelectedSharedListById: (id: number) => void;
  basketLists: ISharedListBeforeSending[];
  list: IMyList | null;
};

export const SocialMediaModal = ({
  closeModal,
  openEmailModal,
  onHandleClickShareList,
  onHandleSetBasketLists,
  onHandleSetList,
  deleteObjectInSelectedSharedListById,
  basketLists,
  list,
}: Props) => {
  const { user } = useSelector((state: any) => state.userState);
  const { myLists, selectedList } = useSelector((state: IRootState) => state.myListsState);
  const { selectedCollegesForSharing } = useSelector((state: IRootState) => state.selectedCollegesForSharingState);
  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  const [listColleges, setListColleges] = useState<ISharedListBeforeSending | null>();
  const [collegesRightBlock, setCollegesRightBlock] = useState<ISharedCollege[]>([]);
  const [isOpenAutocompleteLists, setIsOpenAutocompleteLists] = useState<boolean>(false);
  const [isCheckedAll, setIsCheckedAll] = useState<boolean>(false);
  const [isMinus, setIsMinus] = useState<boolean>(false);
  const [isOpenedSocialMedia, setIsOpenedSocialMedia] = useState<boolean>(false);
  const [isOpenedBigArrayModal, setIsOpenedBigArrayModal] = useState<boolean>(false);
  const [isOpenedModalWithZeroSharingColleges, setIsOpenedModalWithZeroSharingColleges] = useState<boolean>(false);
  const [isLoader, setIsLoader] = useState<boolean>(false);
  const [isError, setIsError] = useState<boolean>(false);

  const dispatch = useDispatch();
  const selectedListRef = useRef(null);
  const shareBtnRef = useRef(null);
  const navigate = useNavigate();

  useEffect(() => {
    if (selectedList && selectedCollegesForSharing.length > 0) {
      const currentObj: ISharedListBeforeSending = selectedCollegesForSharing.find((obj) => obj.id === selectedList.id);
      if (currentObj) {
        const colleges: ISharedCollege[] = currentObj.colleges;
        setCollegesRightBlock([...colleges]);
      } else {
        setCollegesRightBlock([]);
      }
    }
  }, []);

  useEffect(() => {
    const handleClickOutsideSelectListInput = (event) => {
      const listRef: any = selectedListRef.current;
      if (listRef && !listRef.contains(event.target)) {
        setTimeout(() => {
          setIsOpenAutocompleteLists(false);
        }, 1000);
      }
    };
    const handleClickOutsideShareBtn = (event) => {
      const shareRef: any = shareBtnRef.current;
      if (shareRef && !shareRef.contains(event.target)) {
        setTimeout(() => {
          setIsOpenedSocialMedia(false);
        }, 1000);
      }
    };
    document.addEventListener('click', handleClickOutsideSelectListInput);
    document.addEventListener('click', handleClickOutsideShareBtn);

    return () => {
      document.removeEventListener('click', handleClickOutsideSelectListInput);
      document.removeEventListener('click', handleClickOutsideShareBtn);
    };
  }, []);

  useEffect(() => {
    if (!selectedList) return;
    handleFetchList(selectedList.id);
    onHandleSetList(selectedList);
  }, [selectedList]);

  useEffect(() => {
    if (!list) return;
    setIsMinus(false);
    setIsCheckedAll(false);

    const obj = basketLists.find((basketList) => basketList.id === list.id);
    if (!obj) return;
    if (listColleges && obj.colleges.length < listColleges.colleges.length && obj.colleges.length > 0) {
      setIsMinus(true);
    }

    if (listColleges && obj.colleges.length === listColleges.colleges.length) {
      setIsCheckedAll(true);
    }
  }, [basketLists]);

  const handleOpenModalWithZeroSharingColleges = (): boolean => {
    if (collegesRightBlock.length === 0) {
      setIsOpenedModalWithZeroSharingColleges(true);
      open();
      return true;
    }
    return false;
  };

  // function for selecting list
  const handleSetSelectedList = (list): void => {
    handleCloseOpeningModalWithTimeout();
    if (!list) return;
    handleFetchList(list?.id);
    onHandleSetList(list);
    const currentList = basketLists.find((listBasket) => listBasket.id === list.id);
    if (!currentList) {
      setIsMinus(false);
      setIsCheckedAll(false);
    } else if (currentList.colleges.length === list.colleges.length) {
      setIsMinus(false);
      setIsCheckedAll(true);
    } else if (currentList.colleges.length > 0) {
      setIsMinus(true);
      setIsCheckedAll(false);
    } else {
      setIsMinus(false);
      setIsCheckedAll(false);
    }

    const index = selectedCollegesForSharing.findIndex((obj) => obj.id === list.id);
    if (!isLoader) {
      setIsOpenAutocompleteLists(false);
    }
    if (index === -1) {
      setCollegesRightBlock([]);
    } else {
      setCollegesRightBlock(selectedCollegesForSharing[index].colleges);
    }
  };

  // fetch data from endpoint
  const handleFetchList = async (listId: number) => {
    try {
      if (!listId) return;
      setListColleges(null);
      setIsLoader(true);
      const colleges = await myListService.endpoint_colleges_for_sharing(listId);
      if (!colleges.data) return;
      setListColleges(colleges.data);
      setIsLoader(false);
      setIsError(false);
      handleCloseOpeningModalWithTimeout();
    } catch (error) {
      console.error(error);
      setIsError(true);
    }
  };

  // function for detecting college in array
  const checkOrCollegeIsInArray = (college, arrayForChecking): boolean => {
    if (!college || arrayForChecking.length === 0) {
      return false;
    }
    const yes = arrayForChecking.findIndex((collegeArr) => collegeArr.name === college.name);
    return yes !== -1;
  };

  // function for checking or college is in basket
  const checkOrCollegeIsInArrayBasket = (college): boolean => {
    if (!college || basketLists.length === 0 || !list) {
      return false;
    }
    const currentObj = basketLists.find((obj) => obj.id === list.id);
    if (currentObj) {
      const yes = currentObj.colleges.findIndex((collegeArr) => collegeArr.name === college.name);
      return yes !== -1;
    }
    return false;
  };

  // function for adding college in basket or remove college
  const handleAddOrRemoveCollegeInBasket = (college): void => {
    if (!college || !list) return;
    handleCloseOpeningModalWithTimeout();

    // do copy object for modifications
    const copiedArray: ISharedListBeforeSending[] = JSON.parse(JSON.stringify(basketLists));
    const objectIndex: number = copiedArray.findIndex((obj: ISharedListBeforeSending) => obj.id === list.id);

    if (objectIndex !== -1) {
      const includedInArray: boolean = checkOrCollegeIsInArray(college, copiedArray[objectIndex].colleges);
      if (!includedInArray) {
        // need to add college if not included in array
        copiedArray[objectIndex].colleges.unshift(college);
        onHandleSetBasketLists(copiedArray);
        return;
      }
      const indexCollege = copiedArray[objectIndex].colleges.findIndex((item) => item.id === college.id);
      copiedArray[objectIndex].colleges.splice(indexCollege, 1);
      onHandleSetBasketLists(copiedArray);
    } else {
      // Object not found, create a new object and add it to the array
      const newObject: ISharedListBeforeSending = {
        id: list.id,
        name: list.name,
        userId: user.id,
        colleges: [college],
      };
      onHandleSetBasketLists([newObject, ...basketLists]);
    }
  };

  // function to delete an object from the array by id
  const deleteObjectInBasketById = (id: number) => {
    // Create a new array that filters out the object with the specified id. Don't need do copy, filter return new array
    const updatedArray: ISharedListBeforeSending[] = basketLists.filter(
      (obj: ISharedListBeforeSending) => obj.id !== id,
    );

    // Update the state with the modified array
    onHandleSetBasketLists([...updatedArray]);
  };

  // function for adding all colleges in basket or remove colleges
  const handleAddOrRemoveAllCollegesInBasket = (array) => {
    if (!array || !list) return;
    handleCloseOpeningModalWithTimeout();

    const objectIndex: number = basketLists.findIndex((obj: ISharedListBeforeSending) => obj.id === list.id);
    if (objectIndex !== -1) {
      // if index found
      if (basketLists[objectIndex].colleges.length > 0 && basketLists[objectIndex].colleges.length < array.length) {
        deleteObjectInBasketById(list.id);
        // setIsCheckedAll(false);
        return;
      }
    }

    if (objectIndex !== -1) {
      deleteObjectInBasketById(list.id);
      return;
    }

    // if object with this id don't have, need to create and set in state
    const newObject: ISharedListBeforeSending = {
      id: list.id,
      name: list.name,
      userId: user.id,
      colleges: [...array],
    };
    onHandleSetBasketLists([newObject, ...basketLists]);
  };

  // function for adding or removing college from redux array with sharing
  const handleAddOrRemoveCollegeInSelectedSharedColleges = (college) => {
    if (!college || !list) return;
    handleCloseOpeningModalWithTimeout();

    // do copy object for modifications
    const object: ISharedListBeforeSending = selectedCollegesForSharing.find((obj) => obj.id === list.id);
    if (!object) {
      // Object not found, create a new object and add it to the array
      const newObject: ISharedListBeforeSending = {
        id: list.id,
        name: list.name,
        userId: user.id,
        colleges: [college],
      };
      dispatch(
        selectedCollegesForSharingAction.setSelectedCollegesForSharing([newObject, ...selectedCollegesForSharing]),
      );
      setCollegesRightBlock(newObject.colleges);
      return;
    }
    const copyObject = { ...object, colleges: [...object.colleges] };

    // Object found, modify the 'colleges' field of the object
    const includedInArray: boolean = checkOrCollegeIsInArray(college, copyObject.colleges);

    if (!includedInArray) {
      // need to add college if not included in array
      if (copyObject.colleges.length >= 30) return;
      copyObject.colleges.unshift(college);
      dispatch(
        selectedCollegesForSharingAction.setSelectedCollegesForSharing([copyObject, ...selectedCollegesForSharing]),
      );
      setCollegesRightBlock(copyObject.colleges);
      return;
    }

    const indexCollege = copyObject.colleges.findIndex((item) => item.id === college.id);
    copyObject.colleges.splice(indexCollege, 1);
    dispatch(
      selectedCollegesForSharingAction.setSelectedCollegesForSharing([copyObject, ...selectedCollegesForSharing]),
    );
    setCollegesRightBlock(copyObject.colleges);
  };

  // function for checking or user can add college in sharing array and call functions according to specific
  const handleAddAllCollegesToSharedListAfterModalOfBefore = (needAddAll: boolean, college?: ISharedCollege) => {
    if (!list) return;

    if (needAddAll) {
      const objectBasket = basketLists.find((obj: ISharedListBeforeSending) => obj.id === list.id);

      if (!objectBasket || objectBasket.colleges.length === 0) return;

      const object: ISharedListBeforeSending = selectedCollegesForSharing.find((obj) => obj.id === list.id);

      if (object) {
        if (objectBasket.colleges.length + object.colleges.length > 30) {
          setIsOpenedBigArrayModal(true);
          open();
          return;
        }
      } else if (objectBasket.colleges.length > 30) {
        setIsOpenedBigArrayModal(true);
        open();
        return;
      }

      handleAddAllCollegesToSharedList();
    } else {
      if (!college) return;

      const object: ISharedListBeforeSending = selectedCollegesForSharing.find((obj) => obj.id === list.id);
      if (object) {
        const includedCollege = checkOrCollegeIsInArray(college, object.colleges);

        if (object.colleges.length >= 30 && !includedCollege) {
          setIsOpenedBigArrayModal(true);
          open();
          return;
        }
      }
      handleAddOrRemoveCollegeInSelectedSharedColleges(college);
    }
  };

  // function for adding all colleges in selected shared list
  const handleAddAllCollegesToSharedList = () => {
    if (!list || basketLists.length === 0) return;
    handleCloseOpeningModalWithTimeout();
    deleteObjectInBasketById(list.id);
    const objectBasket = basketLists.find((obj: ISharedListBeforeSending) => obj.id === list.id);

    if (!objectBasket) return;
    const readyColleges: ISharedCollege[] =
      objectBasket.colleges.length >= 30 ? objectBasket.colleges.slice(0, 30) : objectBasket.colleges;

    // if global array have length 0
    if (selectedCollegesForSharing.length === 0) {
      dispatch(
        selectedCollegesForSharingAction.setSelectedCollegesForSharing([
          {
            id: list.id,
            name: list.name,
            userId: user.id,
            colleges: readyColleges,
          },
        ]),
      );
      setCollegesRightBlock(readyColleges);
      return;
    }

    const object: ISharedListBeforeSending = selectedCollegesForSharing.find((obj) => obj.id === list.id);

    // if don't have the same object, need to create and add colleges
    if (!object && objectBasket) {
      const newObject = {
        id: list.id,
        name: list.name,
        userId: user.id,
        colleges: readyColleges,
      };
      dispatch(
        selectedCollegesForSharingAction.setSelectedCollegesForSharing([newObject, ...selectedCollegesForSharing]),
      );
      setCollegesRightBlock([...newObject.colleges]);
      return;
    }

    // if there is object, need to add colleges without duplicates
    const copyObject = { ...object, colleges: [...readyColleges, ...object.colleges] };
    copyObject.colleges = mergeTwoArrayWithoutDuplicates(object.colleges, readyColleges, 'id');
    dispatch(
      selectedCollegesForSharingAction.setSelectedCollegesForSharing([copyObject, ...selectedCollegesForSharing]),
    );
    setCollegesRightBlock(copyObject.colleges);
  };

  // function for removing all colleges in selected shared list
  const handleRemoveAllCollegesFromSharedList = () => {
    if (!list) return;
    deleteObjectInSelectedSharedListById(list.id);
    setCollegesRightBlock([]);
  };

  // function for merge two arrays without duplicates by property
  function mergeTwoArrayWithoutDuplicates(array1, array2, property: string) {
    const mergedArray = [...array1];

    array2.forEach((obj) => {
      const existingObj = mergedArray.find((item) => item[property] === obj[property]);
      if (!existingObj) {
        mergedArray.push(obj);
      }
    });
    handleCloseOpeningModalWithTimeout();

    return mergedArray;
  }

  // function for opening sub-modal with shared buttons
  const handleClickOpeningSubModal = () => {
    setIsOpenedSocialMedia(!isOpenedSocialMedia);
  };

  // function for opening modal with some delay
  const handleCloseOpeningModalWithTimeout = () => {
    setTimeout(() => {
      setIsOpenedSocialMedia(false);
    }, 1000);
  };

  const htmlForCountAddingCollegesFromBasket = (): string => {
    if (!list) return '';

    const objectBasket = basketLists.find((obj) => obj.id === list.id);

    if (objectBasket) {
      return `
          You can share up to 30 colleges. You are trying to add ${objectBasket.colleges.length} colleges. Please make
          your selections.
        `;
    }

    return '';
  };

  return (
    <section className="social-media-modal">
      <span className="social-media-modal__close" onClick={() => closeModal()} />
      <h4 className="social-media-modal__choose-info">
        {step.title}
        <br />
        {step.titleSecond}
      </h4>
      <div className="social-media-modal-cont-select-list-and-clear">
        <div className="social-media-modal-select-list">
          <h3 className="social-media-modal-select-list-title">Select List</h3>
          <div className="social-media-modal-select-list-container-main-content" ref={selectedListRef}>
            <input
              readOnly={true}
              className="social-media-modal-select-list-input"
              type="text"
              placeholder="Select List"
              value={listColleges?.name || ''}
              onClick={(e) => {
                e.stopPropagation();
                if (isLoader) return;
                setIsOpenAutocompleteLists(!isOpenAutocompleteLists);
              }}
            />
            <img
              onClick={(e) => {
                e.stopPropagation();
                if (isLoader) return;
                setIsOpenAutocompleteLists(!isOpenAutocompleteLists);
              }}
              className={
                isOpenAutocompleteLists
                  ? 'social-media-modal-select-list-input-arrow social-media-modal-select-list-input-arrow-opened'
                  : 'social-media-modal-select-list-input-arrow'
              }
              src={arrowInput}
              alt="arrow"
            />
            {isOpenAutocompleteLists && (
              <div className="social-media-modal-select-list-autocomplete">
                {myLists.map((list) => (
                  <div
                    key={list.id}
                    className="social-media-modal-select-list-autocomplete-item"
                    onClick={() => handleSetSelectedList(list)}
                  >
                    {list.name}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>
        <button className="social-media-modal__btn-clear" onClick={handleRemoveAllCollegesFromSharedList}>
          Clear
        </button>
      </div>
      <div className="social-media-modal__main-content">
        <div className="social-media-modal__main-content__left-side">
          <div className="social-media-modal__main-content__left-side-title-container">
            <h4 className="social-media-modal__main-content__left-side-title-column">{step.titleLeftColumn}</h4>
          </div>
          <div
            className={`social-media-modal__main-content__left-side-center ${
              listColleges && listColleges.colleges.length === 0 ? 'no-result' : ''
            }`}
          >
            <CustomCheckbox
              onHandleChecked={() => {
                handleAddOrRemoveAllCollegesInBasket(listColleges?.colleges);
              }}
              checked={isCheckedAll}
              unChecked={isMinus}
            >
              <button
                className="social-media-modal__main-content__left-side-center__btn"
                onClick={(e) => {
                  e.stopPropagation();
                  // handleAddAllCollegesToSharedList();
                  handleAddAllCollegesToSharedListAfterModalOfBefore(true);
                }}
              >
                Add
              </button>
            </CustomCheckbox>
          </div>
          <div className="social-media-modal__main-content__left-side-list ">
            {listColleges &&
              listColleges.colleges.length > 0 &&
              listColleges.colleges.map((college: ISharedCollege) => (
                <div className="social-media-modal__main-content__left-side-item" key={college.id}>
                  <p className="social-media-modal__main-content__left-side-item-name">
                    <CustomCheckbox
                      onHandleChecked={() => {
                        handleAddOrRemoveCollegeInBasket(college);
                      }}
                      checked={checkOrCollegeIsInArrayBasket(college)}
                      label={college.name}
                      checkedInRedux={checkOrCollegeIsInArray(college, collegesRightBlock)}
                    />
                  </p>
                  <p
                    className="social-media-modal__main-content__left-side-item-text"
                    onClick={(e) => {
                      e.stopPropagation();
                      // handleAddOrRemoveCollegeInSelectedSharedColleges(college);
                      handleAddAllCollegesToSharedListAfterModalOfBefore(false, college);
                    }}
                  >
                    {checkOrCollegeIsInArray(college, collegesRightBlock) ? (
                      <img className="minus" src={minusViolet} alt="minus" />
                    ) : (
                      <img className="plus" src={plusViolet} alt="plus" />
                    )}
                  </p>
                </div>
              ))}
            {((listColleges && listColleges.colleges.length === 0) || isError) && (
              <div className="social-media-modal__no-result">
                <img className="social-media-modal__no-result__img" src={noResult} alt="no result" />
                <p className="social-media-modal__no-result__description">There are no colleges on this list</p>
                <button className="social-media-modal__no-result__btn" onClick={() => navigate('/advanced-search')}>
                  Discover Colleges
                </button>
              </div>
            )}
            {isLoader && (
              <div className="container-loader">
                <CustomLoader />
              </div>
            )}
          </div>
        </div>
        <div className="social-media-modal__main-content__right-side">
          <div className="social-media-modal__main-content__left-side-title-container">
            <h4 className="social-media-modal__main-content__right-side-title-column">{step.titleRightColumn}</h4>
          </div>
          <div className="social-media-modal__main-content__right-side-list">
            {collegesRightBlock.length > 0 &&
              collegesRightBlock.map((selectedCollege) => (
                <div className="social-media-modal__main-content__right-side-item" key={selectedCollege.id}>
                  <img
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddOrRemoveCollegeInSelectedSharedColleges(selectedCollege);
                    }}
                    className="social-media-modal__main-content__right-side-item__minus"
                    src={minus}
                    alt="minus"
                  />
                  <p className="social-media-modal__main-content__right-side-item__name social-media-modal__main-content__right-side-item-selected-colleges">
                    {selectedCollege.name}
                  </p>
                </div>
              ))}
          </div>
        </div>
      </div>
      <div className="social-media-modal__cont-count">
        <button className="social-media-modal__btn-clear second" onClick={handleRemoveAllCollegesFromSharedList}>
          Clear
        </button>
        <p
          className={`social-media-modal__choose-colleges social-media-modal__choose-colleges-${selectedCollegesForSharing.length}`}
        >
          Colleges Chosen <strong>{collegesRightBlock?.length} of 30</strong>
        </p>
      </div>
      <div className="social-media-modal__cont-btns">
        <button className="social-media-modal__btn-share" onClick={handleClickOpeningSubModal} ref={shareBtnRef}>
          Share
        </button>
        <SocialMedias
          openModal={openEmailModal}
          onHandleClickShareList={onHandleClickShareList}
          onHandleOpenedSocialMedia={setIsOpenedSocialMedia}
          onHandleOpenModalWithZeroSharingColleges={handleOpenModalWithZeroSharingColleges}
          additionalClass={`social-media-modal__cont-btns__sub-modal ${isOpenedSocialMedia ? 'visible' : 'hidden'}`}
        />
      </div>
      <Modal>
        {isOpenedBigArrayModal && (
          <section className="social-media-modal__submodal">
            <span
              className="social-media-modal__submodal__cross"
              onClick={() => {
                close();
                setIsOpenedBigArrayModal(false);
              }}
            />
            <p className="social-media-modal__submodal__p">{htmlForCountAddingCollegesFromBasket()}</p>
            <button
              className="social-media-modal__btn-share social-media-modal__submodal__btn"
              onClick={() => {
                close();
                setIsOpenedBigArrayModal(false);
              }}
            >
              OK
            </button>
          </section>
        )}
        {isOpenedModalWithZeroSharingColleges && (
          <section className="social-media-modal__submodal">
            <span
              className="social-media-modal__submodal__cross"
              onClick={() => {
                close();
                setIsOpenedModalWithZeroSharingColleges(false);
              }}
            />
            <p className="social-media-modal__submodal__p">There are no colleges on this list</p>
            <button
              className="social-media-modal__btn-share social-media-modal__submodal__btn"
              onClick={() => {
                close();
                setIsOpenedModalWithZeroSharingColleges(false);
              }}
            >
              OK
            </button>
          </section>
        )}
      </Modal>
    </section>
  );
};
