/* eslint-disable */
import moment, { Moment } from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate, NavLink } from 'react-router-dom';
import { useModal } from 'react-hooks-use-modal';
import {
  backupUserListAction,
  efcsAction,
  highschoolsAction,
  householdIncomeAction,
  majorsAction,
  userAction,
  usersAction,
} from '../../store/actions';
import { userService, usersService, subscriptionService } from '../../services';
import AddSubscriptionModal from '../admin-add-subscription/AddSubscriptionModal';
import { Autocomplete } from '../autocomplete/Autocomplete';
import { AdminUserListModal } from '../admin-user-list-modal/Admin-user-list-modal';
import { CountDownloadForm } from '../count-download-form/CountDownloadForm';
import { EUserRoles } from '../../enums';
import { IEfc, IHighschool, IHouseHoldIncome, IMajors, IState } from '../../models';
import back from '../../images/back.svg';
import cross from '../../images/cross.svg';
import './Admin-user-profile.css';
import { PopUpModal } from '../pop-up-modal/PopUpModal';

const listGender = [
  {
    id: 1,
    name: 'Male',
    value: 'male',
  },
  {
    id: 2,
    name: 'Female',
    value: 'female',
  },
];
const listAthlete = [
  {
    id: 1,
    name: 'Yes',
    value: true,
  },
  {
    id: 2,
    name: 'No',
    value: false,
  },
];
const listRole = [
  {
    id: 1,
    name: 'Parent',
    value: 'parent',
  },
  {
    id: 2,
    name: 'Student',
    value: 'student',
  },
  {
    id: 3,
    name: 'Counselor',
    value: 'professional',
  },
];
const packages = [
  { id: 1, name: 'Annual Old' },
  { id: 2, name: 'Monthly Basic' },
  { id: 3, name: 'Annual Basic' },
  { id: 4, name: 'Monthly Plus' },
  { id: 5, name: 'Annual Plus' },
  { id: 6, name: 'Monthly Premium' },
  { id: 7, name: 'Annual Premium' },
];
export const AdminUserProfile = () => {
  const { backupUserList } = useSelector((state: any) => state.backupUserListState);
  const locationUser: any = useLocation();
  const navigate = useNavigate();
  const childRef = useRef<any>();
  const dispatch = useDispatch();

  const [user, setUser] = useState(locationUser?.state?.user);

  const { highschools } = useSelector((state: any) => state.highschoolsState);
  const { efcs } = useSelector((state: any) => state.efcsState);
  const { states }: { states: IState[] } = useSelector((state: any) => state.statesState);
  const { majors } = useSelector((state: any) => state.majorsState);
  const { householdIncome } = useSelector((state: any) => state.houseHoldIncomeState);

  const [isFamilyIncomeInputActive, setIsFamilyIncomeInputActive] = useState(false);
  const [familyIncome, setFamilyIncome] = useState(
    householdIncome.find((e: IHouseHoldIncome) => e.name === user?.familyIncome) || null,
  );

  const [isFamilyAverageIncomeInputActive, setIsFamilyAverageIncomeInputActive] = useState(false);
  const [familyAverageIncome, setFamilyAverageIncome] = useState('');

  const [graduationYear, setGraduationYear] = useState<string>('');
  const [isGraduationYearInputActive, setIsGraduationYearInputActive] = useState(false);

  const [filteredMajors, setFilteredMajors] = useState(majors || '');
  const [searchMajorsValue, setSearchMajorsValue] = useState('');
  const [isMajorsInputActive, setIsMajorsInputActive] = useState(false);
  const [selectedMajors, setSelectedMajors] = useState<IMajors[]>([]);

  const [isRole, setIsRole] = useState(false);
  const [role, setRole] = useState<any | null>(null);

  const [isGender, setIsGender] = useState(false);
  const [gender, setGender] = useState('');

  const [isAthelete, setIsAthelete] = useState(false);
  const [athelete, setAthelete] = useState<boolean | null>(null);

  const [isBudgetInputActive, setIsBudgetInputActive] = useState(false);
  const [isStateInputActive, setIsStateInputActive] = useState(false);
  const [searchStateValue, setSearchStateValue] = useState('');
  const [selectedState, setSelectedState] = useState<IState | null>(null);
  const [filteredStates, setFilteredStates] = useState(states);

  const [fullNameValue, setfullNameValue] = useState('');

  const [emailValue, setEmailValue] = useState('');

  const [filteredHighschools, setFilteredHighschools] = useState(highschools);
  const [searchHighschoolsValue, setSearchHighschoolsValue] = useState('');
  const [selectedHighSchool, setSelectedHighSchool] = useState<IHighschool | null>(null);
  const [isHighschoolsInputActive, setIsHighschoolsInputActive] = useState(false);

  const [gpa, setGpa] = useState<number>();
  const [act, setAct] = useState(!!(user?.act || 0));
  const [sat, setSat] = useState(!!(user?.sat || 0));
  const [satValue, setSatValue] = useState<number | null>(user?.sat || null);
  const [actValue, setActValue] = useState<number | null>(user?.act || null);
  const [budget, setBudget] = useState<IEfc | null>(null);
  const [modalText, setModalText] = useState<string | null>(null);

  const [isAdmin, setIsAdmin] = useState(false);

  const [emailSentMessage, setEmailSentMessage] = useState();
  const [isError, setIsError] = useState(false);

  const [isOpenedListModal, setIsOpenedListModal] = useState(false);
  const [isAddSubscriptionModalOpen, setIsAddSubscriptionModalOpen] = useState(false);

  const [userWithAllInfo, setUserWithAllInfo] = useState<any>();

  const [isLoaderInCancelSub, setIsLoaderInCancelSub] = useState<any>(null);
  const [isLoaderSubmit, setIsLoaderSubmit] = useState(false);
  const [psatScore, setPsatScore] = useState(user?.psatScore || null);

  useEffect(() => {
    const callback = async () => {
      if (user && user.id) {
        const user_with_all_info = await userService.endpoint_get_user_with_all_info(+user.id);
        setUserWithAllInfo(user_with_all_info.data);
      }
    };
    callback();
  }, []);

  useEffect(() => {
    dispatch(backupUserListAction.getAllUserListBackup(user?.id));
  }, []);

  useEffect(() => {
    dispatch(highschoolsAction.getHighschools({ stateId: user?.stateId }));
    dispatch(majorsAction.getMajors());
    dispatch(efcsAction.getEfcs());
    dispatch(householdIncomeAction.getHouseHoldIncome());
  }, []);

  useEffect(() => {
    setFamilyAverageIncome(familyAverageIncome);
  }, [familyAverageIncome]);

  useEffect(() => {
    setFilteredHighschools(highschools);
  }, [highschools]);

  useEffect(() => {
    setFilteredMajors(majors);
  }, [majors]);

  const handleSetModalText = async (text: any) => {
    if (!text) {
      return;
    }

    const textModal = modifyTextForModal(text.status ?? text);
    const user_with_all_info = await userService.endpoint_get_user_with_all_info(+user.id);
    if (user_with_all_info) {
      setUserWithAllInfo(user_with_all_info.data);
    }
    setModalText(textModal);
    open();
  };

  const modifyTextForModal = (text: string | null) => {
    switch (text) {
      case 'SUBSCRIPTION_WAS_CREATED':
        return 'Subscription was created successfully!';
      case 'SUBSCRIPTION_WAS_NOT_CREATED':
        return 'Subscription was not created';
      case 'USER_DOES_NOT_EXIST':
        return 'User does not exist';
      case 'USER_DOES_NOT_EXIST_IN_STRIPE':
        return 'User does not exist in stripe';
      case 'USER_DOES_NOT_HAVE_PAYMENT_METHOD':
        return 'User does not have payment method';
      case 'SUBSCRIPTION_DELETED':
        return 'The subscription was successfully deleted.';
      case 'SUBSCRIPTION_WAS_DELETED':
        return 'The subscription was successfully deleted.';
      case 'SUBSCRIPTION_WAS_NOT_FOUND':
        return 'The subscription was not found.';
      default:
        return null;
    }
  };
  
  useEffect(() => {
    if (userWithAllInfo) {
      if (userWithAllInfo?.state?.name !== null) {
        setSearchStateValue(userWithAllInfo?.state?.name);
      }
      setSelectedState({ ...userWithAllInfo.state, id: userWithAllInfo.stateId });
      dispatch(highschoolsAction.getHighschools({ stateId: userWithAllInfo.stateId }));
      const highSchool = highschools.find((h: IHighschool) => h.id === userWithAllInfo.highschoolId);
      if (highSchool) {
        setSelectedHighSchool(highSchool);
        setSearchHighschoolsValue(highSchool.name);
      }
      setBudget(efcs.find((e: IEfc) => e.id === userWithAllInfo?.efcId));
      setFamilyAverageIncome(userWithAllInfo?.familyContributions);
      setGraduationYear(userWithAllInfo?.yearOfHighSchoolGraduation);
      if (userWithAllInfo?.role === 'admin') {
        setIsAdmin(true);
      } else {
        setRole(listRole.find((r) => r.value === userWithAllInfo?.role));
      }
      setGender(userWithAllInfo.gender);
      setAthelete(userWithAllInfo.athlete);
      if (userWithAllInfo?.name !== null) {
        setfullNameValue(userWithAllInfo.name);
      }
      setEmailValue(userWithAllInfo.email);
      setGpa(userWithAllInfo.gpa);
      setSelectedMajors(userWithAllInfo.majors || []);
      setPsatScore(userWithAllInfo.psatScore);
    }
  }, [userWithAllInfo]);

  const selectUserRole = (list: any) => {
    setRole(list);
    setIsRole(false);
  };

  const selectUserAthlete = (athlete: any) => {
    setAthelete(athlete.value);
    setIsAthelete(false);
  };
  const selectUserGender = (gender: any) => {
    setGender(gender.value);
    setIsGender(false);
  };

  const selectEfc = (efc: IEfc) => {
    setBudget(efc);
    setIsFamilyIncomeInputActive(false);
  };
  const selectFamilyIncome = (householdIncome: IHouseHoldIncome) => {
    setFamilyIncome(householdIncome);
    setIsFamilyIncomeInputActive(false);
    // rotateArrow('income', false);
  };
  const selectAverageFamilyIncome = (efc: IEfc) => {
    setFamilyAverageIncome(efc.name);
    setIsFamilyAverageIncomeInputActive(false);
  };

  const filterHighschools = (inputValue: string) => {
    const nextValue = highschools.filter(
      (highschool: IHighschool) =>
        highschool.name.toLowerCase().includes(inputValue.toLowerCase().trim()) &&
        highschool.name !== 'School Not Listed',
    );
    setFilteredHighschools([highschools[0], ...nextValue]);
    setIsHighschoolsInputActive(true);
  };

  const selectState = (state: IState) => {
    dispatch(highschoolsAction.getHighschools({ stateId: state.id }));
    setSearchStateValue(state.name);
    setSelectedState(state);
    setIsStateInputActive(false);
  };

  const filterStatesNames = (inputValue: string) => {
    const nextValue = states.filter((state: IState) =>
      state.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredStates(nextValue);
    setIsStateInputActive(true);
  };

  const filterMajors = (inputValue: string) => {
    const nextValue = majors.filter((major: IMajors) =>
      major.name.toLowerCase().includes(inputValue.toLowerCase().trim()),
    );
    setFilteredMajors(nextValue);
    setIsMajorsInputActive(true);
  };

  const selectHighschool = (highschool: IHighschool) => {
    setSearchHighschoolsValue(highschool.name);
    setSelectedHighSchool(highschool);
    setIsHighschoolsInputActive(false);
  };

  const selectMajors = (major: IMajors) => {
    if (selectedMajors.findIndex((m) => m.id === major.id) === -1) {
      setSelectedMajors([...selectedMajors, major]);
      setSearchMajorsValue('');
      setIsMajorsInputActive(false);
    }
  };

  const selectYearOfGraduation = (year: any) => {
    setGraduationYear(year.name);
    setIsGraduationYearInputActive(false);
  };

  const yearsOfGraduation: any[] = [];
  for (let i = 0; i < 11; i++) {
    const currentYear = moment().year() - 2;
    yearsOfGraduation.push({ id: i, name: currentYear + i });
  }

  const upgradeProfile = () => {
    if (isAdmin) {
      setIsLoaderSubmit(true);
      const body = {
        email: emailValue || userWithAllInfo.email,
        role: EUserRoles.ADMIN,
        stateId: selectedState?.id || userWithAllInfo.stateId,
        highschoolId: selectedHighSchool?.id || userWithAllInfo.highschoolId,
        familyIncome: familyIncome?.name || userWithAllInfo?.familyIncome || null,
        familyContributions: familyAverageIncome || userWithAllInfo.familyContributions,
        efcId: budget?.id || userWithAllInfo.efcId || null,
        yearOfHighSchoolGraduation: graduationYear || userWithAllInfo.yearOfHighSchoolGraduation,
        gender: gender || userWithAllInfo.gender,
        name: fullNameValue || userWithAllInfo.name,
        athlete: athelete || userWithAllInfo.athlete,
        sat: Number(satValue) || userWithAllInfo?.sat || null,
        act: Number(actValue) || userWithAllInfo?.act || null,
        gpa: gpa || userWithAllInfo.gpa,
        psatScore: psatScore,
        majors: selectedMajors ? selectedMajors.map((m) => m.id) : userWithAllInfo.majors ? userWithAllInfo.majors : [],
        canceledSubscription: false,
        countFreeSearch: userWithAllInfo?.countFreeSearch || 5,
        hadSubscription: userWithAllInfo?.hadSubscription || false,
      };
      dispatch(usersAction.createUpdateUser({ user: body }));
      setIsLoaderSubmit(true);
      return;
    }
    if (!fullNameValue) {
      const fullNameInput = document.getElementById('fullNameChecked') as HTMLInputElement;
      if (fullNameInput) {
        fullNameInput.required = true;
      }
      return;
    }

    if (!emailValue) {
      const emailInput = document.getElementById('emailChecked') as HTMLInputElement;
      if (emailInput) {
        emailInput.required = true;
      }
      return;
    }

    setIsLoaderSubmit(true);

    const body = {
      email: emailValue || userWithAllInfo.email,
      role: isAdmin ? 'admin' : role?.value ? role?.value : 'student',
      stateId: selectedState ? selectedState?.id : userWithAllInfo ? userWithAllInfo.stateId : null,
      highschoolId: selectedHighSchool ? selectedHighSchool?.id : userWithAllInfo ? userWithAllInfo.highschoolId : null,
      familyIncome: familyIncome?.name || userWithAllInfo?.familyIncome || null,
      familyContributions: familyAverageIncome || (userWithAllInfo ? userWithAllInfo.familyContributions : null),
      efcId:
        budget && budget?.id ? budget?.id : userWithAllInfo && userWithAllInfo?.efcId ? userWithAllInfo?.efcId : null,
      yearOfHighSchoolGraduation:
        graduationYear || (userWithAllInfo ? userWithAllInfo.yearOfHighSchoolGraduation : null),
      gender: gender || (userWithAllInfo ? userWithAllInfo.gender : 'Male'),
      name: fullNameValue || userWithAllInfo?.name,
      athlete: athelete || (userWithAllInfo ? userWithAllInfo.athlete : null),
      sat: Number(satValue) || userWithAllInfo?.sat || null,
      act: Number(actValue) || userWithAllInfo?.act || null,
      gpa: gpa || (userWithAllInfo ? userWithAllInfo.gpa : null),
      psatScore: psatScore || userWithAllInfo.psatscore,
      majors: selectedMajors ? selectedMajors.map((m) => m.id) : userWithAllInfo ? userWithAllInfo?.majors : [],
      canceledSubscription: userWithAllInfo?.canceledSubscription || false,
      countFreeSearch: userWithAllInfo?.countFreeSearch || 5,
      hadSubscription: userWithAllInfo?.hadSubscription || false,
    };
    dispatch(usersAction.createUpdateUser({ user: body }));
    setTimeout(() => {
      setIsLoaderSubmit(false);
    }, 3000);
  };

  const removeSubscription = (subscription) => {
    setIsLoaderInCancelSub(subscription);
    if (subscription.subscriptionId === null) {
      try {
        subscriptionService.endpoint_delete_subscription(subscription.id).then(async (res) => {
          const user_with_all_info = await userService.endpoint_get_user_with_all_info(+user.id);
          if (user_with_all_info) {
            setUserWithAllInfo(user_with_all_info.data);
          }
          await handleSetModalText(res.data);
          setIsLoaderInCancelSub(null);
        });
        return;
      } catch (error) {
        console.error(error);
        setIsLoaderInCancelSub(null);
      }
    }
    try {
      subscriptionService
        .endpoint_delete_subscription_by_subscriptionId(subscription.subscriptionId)
        .then(async (res) => {
          const user_with_all_info = await userService.endpoint_get_user_with_all_info(+user.id);
          if (user_with_all_info) {
            setUserWithAllInfo(user_with_all_info.data);
          }
          await handleSetModalText(res.data);
          setIsLoaderInCancelSub(null);
        });
    } catch (error) {
      console.error(error);
      setIsLoaderInCancelSub(null);
    }
  };
  
  const sendResetPassEmail = () => {
    usersService
      .endpoint_send_pass_reset_email(user?.email)
      .then(({ data }) => {
        setEmailSentMessage(data);
        setIsError(false);
      })
      .catch(({ response }) => {
        setEmailSentMessage(response.data.error);
        setIsError(true);
      });
  };
  const [Modal, open, close] = useModal('root', {
    preventScroll: true,
    closeOnOverlayClick: false,
  });

  const prettyNumbers = (efc: string): string => {
    return efc
      .split(' ')
      .map((item: any) => {
        if (Number.isNaN(+item)) {
          return item;
        }
        return `$ ${(+item).toLocaleString('en-US')}`;
      })
      .join(' ');
  };

  const selectBudget = (efc: IEfc) => {
    setBudget(efc);
    setIsBudgetInputActive(false);
  };

  const checkTwoDates = (date1: string, date2: string): boolean => {
    const format = 'YYYY-MM-DD'; // Specify the desired common format

    const date1Ready: Moment = moment(date1, format);
    const date2Ready: Moment = moment(date2, format);
    return date1Ready.isSame(date2Ready);
  };

  return (
    <div className="admin-user-profile-wrapper">
      <div className="header-user-profile-admin">
        <div className="header-left-block-admin">
          <NavLink to={'/users'}>
            <span>
              <img src={back} alt="arrow" />
              Back
            </span>
          </NavLink>
          <div className="edit-user">
            {userWithAllInfo ? 'Edit' : 'Create'} User {userWithAllInfo && `#${user?.id}`}
          </div>
          {/* {lastLogin && <div>Last Login: {moment(lastLogin).format('HH:mm / DD MMM`YY')}</div>} */}
        </div>
        <div className="header-right-block-admin">
          <button
            className="header-right-block-admin-login-btn"
            onClick={() => {
              if (userWithAllInfo) {
                dispatch(userAction.getAdminUser({ email: userWithAllInfo.email }));
                navigate('/home');
              }
            }}
          >
            Login
          </button>
          <button disabled={!user} onClick={sendResetPassEmail}>
            {' '}
            Send Reset Passsword Link
          </button>
          {emailSentMessage && <p className={isError ? 'error-message' : 'success-message'}>{emailSentMessage}</p>}
        </div>
      </div>
      <div className="admin-body-wrapper">
        <div className="admin-user-profile-body-wrapper">
          <div className="my-profile-body-admin">
            <div>
              <div className="input-location-admin">
                <input
                  id="fullNameChecked"
                  type="text"
                  name="get"
                  placeholder="Full Name"
                  value={fullNameValue || userWithAllInfo?.name}
                  onChange={({ target }) => setfullNameValue(target.value)}
                />
                <p className="input-location-admin-text-first-row">Full Name</p>
                <div className="container">
                  <p>Administrator</p>
                  <input readOnly type="checkbox" name="isAdmin" checked={isAdmin} />
                  <p onClick={() => setIsAdmin(!isAdmin)} className="checkmark" />
                </div>
              </div>
              <div className="input-location-admin">
                <input
                  id="emailChecked"
                  type="text"
                  placeholder="Email"
                  value={emailValue || user?.email}
                  onChange={({ target }) => setEmailValue(target.value)}
                />
                <p className="input-location-admin-text-first-row">Email</p>
              </div>
            </div>
            <div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  placeholder="Gender"
                  value={gender === 'male' ? 'Male' : gender === 'female' ? 'Female' : '' || userWithAllInfo?.gender}
                  onClick={() => setIsGender(true)}
                  onBlur={() => setIsGender(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isGender && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectUserGender}
                    filteredItem={listGender}
                  />
                )}
                <p>Gender</p>
              </div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  placeholder="Role"
                  value={role?.name || user?.role || 'Student'}
                  onClick={() => setIsRole(true)}
                  onBlur={() => setIsRole(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isRole && (
                  <Autocomplete isBudgetItems={false} ref={childRef} onclick={selectUserRole} filteredItem={listRole} />
                )}
                <p>Role</p>
              </div>
            </div>
            <div>
              <div className="input-select-admin">
                <input
                  id="locationChecked"
                  type="text"
                  placeholder="Location"
                  value={searchStateValue || user?.state?.name || ''}
                  onClick={() => setIsStateInputActive(true)}
                  onChange={({ target }) => setSearchStateValue(target.value)}
                  onInput={({ target }: { target: any }) => filterStatesNames(target.value)}
                  onBlur={() => setIsStateInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                <p>Location</p>
                {isStateInputActive && filteredStates && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectState}
                    filteredItem={filteredStates}
                  />
                )}
              </div>
              <div className="input-select-admin">
                <input
                  id="highschoolChecked"
                  type="text"
                  placeholder="Choose High School"
                  value={searchHighschoolsValue}
                  onClick={() => setIsHighschoolsInputActive(true)}
                  onChange={({ target }) => setSearchHighschoolsValue(target.value)}
                  onInput={({ target }: { target: any }) => filterHighschools(target.value)}
                  onBlur={() => setIsHighschoolsInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isHighschoolsInputActive && filteredHighschools && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectHighschool}
                    filteredItem={filteredHighschools}
                  />
                )}
                <p>High School</p>
              </div>
            </div>
            <div>
              <div className="input-select input-select-admin">
                <input
                  id="budgetChecked"
                  type="text"
                  placeholder="Your Budget"
                  value={budget ? prettyNumbers(budget.name) : ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsBudgetInputActive(true);
                  }}
                  onBlur={(e) => {
                    e.stopPropagation();
                    setIsBudgetInputActive(false);
                  }}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isBudgetInputActive && efcs && (
                  <Autocomplete isBudgetItems={true} ref={childRef} onclick={selectBudget} filteredItem={efcs} />
                )}
                <p>College Budget</p>
              </div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  placeholder="Family"
                  value={familyAverageIncome || ''}
                  onClick={() => setIsFamilyAverageIncomeInputActive(true)}
                  onBlur={() => setIsFamilyAverageIncomeInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isFamilyAverageIncomeInputActive && efcs && (
                  <Autocomplete
                    isBudgetItems={true}
                    ref={childRef}
                    onclick={selectAverageFamilyIncome}
                    filteredItem={efcs}
                  />
                )}
                <p>Student Aid Index (SAI)</p>
                {/* formerly 'Expected Family Contribution' */}
              </div>
            </div>
            <div>
              <div className="input-select-admin">
                <input
                  type="text"
                  placeholder="Interest"
                  value={searchMajorsValue}
                  onClick={() => setIsMajorsInputActive(true)}
                  onChange={({ target }) => setSearchMajorsValue(target.value)}
                  onInput={({ target }: { target: any }) => filterMajors(target.value)}
                  onBlur={() => setIsMajorsInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isMajorsInputActive && filteredMajors && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectMajors}
                    filteredItem={filteredMajors}
                  />
                )}
                <p>Potential Major(s)</p>
              </div>
              <div className="input-select-admin">
                <input
                  id="gpaChecked"
                  type="number"
                  step="0.01"
                  min="0"
                  max="5"
                  placeholder="GPA"
                  value={gpa || user?.gpa || ''}
                  onChange={({ target }) => setGpa(+target.value)}
                  onKeyDown={(e) => {
                    if (e.key.includes('+') || e.key.includes('-')) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={({ target }) => {
                    if (+target.value > 5) {
                      setGpa(5);
                    } else if (+target.value < 0) {
                      setGpa(0);
                    } else {
                      setGpa(+(+target.value).toFixed(2));
                    }
                  }}
                />
                <p>Unweighted GPA</p>
              </div>
            </div>
            {selectedMajors?.length > 0 && (
              <div className="selected-colleges-list">
                {selectedMajors.map((major) => (
                  <div key={major.id} className="selected-college">
                    <span>{major.name}</span>
                    <img
                      onClick={() => setSelectedMajors(selectedMajors.filter((m) => m.id !== major.id))}
                      src={cross}
                      alt="cross"
                    />
                  </div>
                ))}
              </div>
            )}
            <div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  value={graduationYear || ''}
                  onClick={() => setIsGraduationYearInputActive(true)}
                  onBlur={() => setIsGraduationYearInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isGraduationYearInputActive && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectYearOfGraduation}
                    filteredItem={yearsOfGraduation}
                  />
                )}
                <p>Year of High School Graduation</p>
              </div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  placeholder="Athlete"
                  value={athelete ? 'Yes' : athelete === false ? 'No' : ''}
                  onClick={() => setIsAthelete(true)}
                  onBlur={() => setIsAthelete(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isAthelete && (
                  <Autocomplete
                    isBudgetItems={false}
                    ref={childRef}
                    onclick={selectUserAthlete}
                    filteredItem={listAthlete}
                  />
                )}
                <p>Athlete</p>
              </div>
            </div>
            <div>
              <div className="input-select-admin">
                <input
                  readOnly
                  type="text"
                  placeholder="Your Income Range"
                  value={familyIncome?.name || ''}
                  onClick={() => setIsFamilyIncomeInputActive(true)}
                  onBlur={() => setIsFamilyIncomeInputActive(false)}
                  onKeyDown={(evt) => {
                    if (childRef.current) childRef.current.onKeyDown(evt);
                  }}
                />
                {isFamilyIncomeInputActive && householdIncome && (
                  <Autocomplete
                    isBudgetItems={true}
                    ref={childRef}
                    onclick={selectFamilyIncome}
                    filteredItem={householdIncome}
                  />
                )}
                <p>Household Income</p>
              </div>

              <div className="input-select-admin">
                <input
                  id="psatScoreInput"
                  type="number"
                  min="320"
                  max="1520"
                  step="20"
                  placeholder="PSAT Score"
                  className={user?.psatScore === null ? 'my-profile-input-orange' : ''}
                  value={psatScore || 0}
                  onChange={({ target }) => setPsatScore(+target.value)}
                  required={false}
                  onKeyDown={(e) => {
                    if (e.key.includes('+') || e.key.includes('-')) {
                      e.preventDefault();
                    }
                  }}
                  onBlur={({ target }) => {
                    if (+target.value > 1520) {
                      setPsatScore(1520);
                    } else if (+target.value < 320) {
                      setPsatScore(320);
                    } else {
                      setPsatScore(+(+target.value).toFixed(2));
                    }
                  }}
                />
                <p>PSAT Score</p>
              </div>
            </div>
            <div className="student-took-act-sat-block-admin">
              <div className="student-took-act-sat-block-left-admin">
                <p className="student-took-act-admin">Student took ACT</p>
                <div>
                  <div className="choice-radio choice-radio-act">
                    <div>
                      <input
                        className="choice-radio-curcle"
                        type="radio"
                        name="studentIsAct"
                        value="yes"
                        checked={!!act}
                        onChange={() => setAct(true)}
                      />
                      <p className="choice-radio-text">Yes</p>
                    </div>
                    <div>
                      <input
                        className="choice-radio-curcle"
                        type="radio"
                        name="studentIsAct"
                        value="no"
                        checked={!act}
                        onChange={() => setAct(false)}
                      />
                      <p className="choice-radio-text">No</p>
                    </div>
                  </div>
                </div>
                {act && (
                  <div className="my-profile-form-act">
                    <p className="act-score student-took-act-admin student-took-act-admin-inside">
                      Student’s ACT Score
                    </p>
                    <input
                      // ref={actRef}
                      type="number"
                      step="0.5"
                      min="1"
                      max="36"
                      className="student-took-input"
                      onWheel={(e) => e.currentTarget.blur()}
                      placeholder="ACT Score"
                      value={actValue || ''}
                      onKeyDown={(e) => {
                        if (e.key.includes('+') || e.key.includes('-')) {
                          e.preventDefault();
                        }
                      }}
                      onChange={({ target }) => setActValue(+target.value)}
                      onBlur={({ target }) => {
                        if (+target.value > 36) {
                          setActValue(36);
                        } else if (+target.value < 1) {
                          setActValue(1);
                        } else {
                          setActValue(+(+target.value).toFixed(2));
                        }
                      }}
                    />
                  </div>
                )}
              </div>
              <div className="student-took-act-sat-block-right-admin">
                <p className="student-took-act-admin">Student took SAT</p>
                <div>
                  <div className="choice-radio choice-radio-sat">
                    <div>
                      <input
                        className="choice-radio-curcle"
                        type="radio"
                        name="studentIsSat"
                        value="yes"
                        checked={!!sat}
                        onChange={() => setSat(true)}
                      />
                      <p className="choice-radio-text">Yes</p>
                    </div>
                    <div>
                      <input
                        className="choice-radio-curcle"
                        type="radio"
                        name="studentIsSat"
                        value="no"
                        checked={!sat}
                        onChange={() => setSat(false)}
                      />
                      <p className="choice-radio-text">No</p>
                    </div>
                  </div>
                </div>
                {sat && (
                  <div className="my-profile-form-sat">
                    <p className="act-score student-took-act-admin student-took-act-admin-inside">
                      Student’s SAT Score
                    </p>
                    <input
                      // ref={satRef}
                      type="number"
                      step="50"
                      min="400"
                      max="1600"
                      onWheel={(e) => e.currentTarget.blur()}
                      className="student-took-input"
                      placeholder="SAT Score"
                      value={satValue || ''}
                      onKeyDown={(e) => {
                        if (e.key.includes('+') || e.key.includes('-')) {
                          e.preventDefault();
                        }
                      }}
                      onChange={({ target }) => setSatValue(+target.value)}
                      onBlur={({ target }) => {
                        if (+target.value > 1600) {
                          setSatValue(1600);
                        } else if (+target.value < 400) {
                          setSatValue(400);
                        } else {
                          setSatValue(+(+target.value).toFixed(2));
                        }
                      }}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="upgrade-button upgrade-button-submit">
              <button onClick={upgradeProfile}>
                {isLoaderSubmit && <div id="loading-submit-subscription" />}
                {!isLoaderSubmit && <>Submit</>}
              </button>
            </div>
          </div>
          <div className="user-subscriptions-body-admin">
            <header>
              <h3>
                Subscriptions{' '}
                <span>
                  {userWithAllInfo?.subscriptions?.find((s) => s.status === 'active') ? '(Full User)' : '(Free User)'}
                </span>
              </h3>
              <div>
                <button
                  onClick={() => {
                    setIsOpenedListModal(true);
                    open();
                  }}
                >
                  View Lists
                </button>
                <button
                  onClick={() => {
                    setIsAddSubscriptionModalOpen(true);
                    open();
                  }}
                >
                  Add Subscription
                </button>
              </div>
            </header>
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Subscription ID</th>
                  <th>Type of Subscription</th>
                  <th>Status</th>
                  <th>Expires on</th>
                  <th>Promo Code</th>
                  <th>Options</th>
                </tr>
              </thead>
              <tbody>
                {userWithAllInfo?.subscriptions?.length > 0 ? (
                  userWithAllInfo?.subscriptions.map((sub: any, index: number) => {
                    return (
                      <tr key={sub.expiresAt + index}>
                        <td>{sub?.id ?? '-'}</td>
                        <td>{sub?.subscriptionId ? sub?.subscriptionId : '-'}</td>
                        <td style={{ color: userWithAllInfo?.subscriptions ? '#00B4D8' : 'inherit' }}>
                          {userWithAllInfo?.subscriptions && sub?.packageId
                            ? packages.find((p) => p.id === sub.packageId)?.name
                            : '-'}
                        </td>
                        <td className={sub?.status === 'active' ? 'subscription-active' : 'subscription-expired'}>
                          {`${sub?.status?.charAt(0)?.toUpperCase()}${sub?.status?.slice(1)}`}
                          {!sub.subscriptionId ? '(Internal)' : ''}
                        </td>
                        <td>{moment(sub.expiresAt).format('yyyy-MM-DD HH:mm:ss')}</td>
                        <td>{sub?.couponId || '-'}</td>
                        <td className={sub?.status === 'active' ? 'cancel-btn' : ''}>
                          {sub?.status === 'active' ? (
                            <button onClick={() => removeSubscription(sub)}>
                              {(isLoaderInCancelSub?.id && isLoaderInCancelSub.id === sub.id) ||
                              (isLoaderInCancelSub?.subscriptionId &&
                                isLoaderInCancelSub.subscriptionId === sub.subscriptionId) ? (
                                <div id="loading-cancel-subscription" />
                              ) : (
                                <>Cancel</>
                              )}
                            </button>
                          ) : (
                            '-'
                          )}
                        </td>
                      </tr>
                    );
                  })
                ) : (
                  <tr>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                    <td>-</td>
                  </tr>
                )}
              </tbody>
            </table>
            <div className="downloaded-lists">
              <h5>Downloaded lists</h5>
              <CountDownloadForm user={userWithAllInfo} />
            </div>
          </div>
        </div>
      </div>
      <Modal>
        {isOpenedListModal ? (
          <AdminUserListModal
            close={close}
            user={userWithAllInfo}
            setIsOpenedListModal={setIsOpenedListModal}
            backupUserList={backupUserList}
          />
        ) : isAddSubscriptionModalOpen ? (
          <AddSubscriptionModal
            email={userWithAllInfo?.email}
            close={() => {
              setIsAddSubscriptionModalOpen(false);
              close();
            }}
            user={userWithAllInfo}
            setModalText={handleSetModalText}
          />
        ) : (
          modalText && (
            <PopUpModal
              close={() => {
                close();
                setModalText(null);
              }}
              textForTitle={modalText}
            />
          )
        )}
      </Modal>
    </div>
  );
};
