import './Download-list-modal.css';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { myListsAction, selectedCollegesAndColumnsAction } from '../../store/actions';
import arrowInput from '../../images/select-arrow-grey.svg';
import minus from '../../images/minus.svg';

export const DownloadListModal = ({
  close,
  setStepDownloading,
  stepDownloading,
  setSelectedColumnForCsv,
  selectedColumnForCsv,
  setSelectedCollegeForCsv,
  selectedCollegeForCsv,
  firstStep,
  secondStep,
  columnsForDownloading,
  clearValuesAfterDownload,
}: {
  close: Function;
  setStepDownloading: Function;
  stepDownloading: number;
  setSelectedColumnForCsv: Function;
  selectedColumnForCsv: any;
  setSelectedCollegeForCsv: Function;
  selectedCollegeForCsv: any;
  firstStep: any;
  secondStep: any;
  columnsForDownloading: any;
  clearValuesAfterDownload: Function;
}) => {
  const { myLists, selectedList, paginatedColleges } = useSelector((state: any) => state.myListsState);
  const { selectedCollegesForCsv, selectedColumnsForCsv } = useSelector(
    (state: any) => state.selectedCollegesAndColumnsForCsvState,
  );

  const [isOpenAutocompleteLists, setIsOpenAutocompleteLists] = useState<boolean>(false);
  const [isVisibleBookmark, setIsVisibleBookmark] = useState<boolean>(false);
  const [openFirstLevel, setOpenFirstLevel] = useState('');
  const [openSecondLevel, setOpenSecondLevel] = useState<any[]>([]);

  const dispatch = useDispatch();
  const selectedListRef = useRef(null);

  const handleSetSelectedList = (list): void => {
    setIsOpenAutocompleteLists(false);
    if (!list) return;
    dispatch(myListsAction.setSelectedList(list));
    setStepDownloading(1);
    setOpenFirstLevel('');
    setOpenSecondLevel([]);
  };
  const handleAddColumnToCsv = (column): void => {
    if (!column) return;

    if (selectedColumnsForCsv.length >= 10) {
      setIsVisibleBookmark(true);
      setSelectedColumnForCsv(column);
      return;
    }
    dispatch(selectedCollegesAndColumnsAction.setSelectedColumnsForCsv([column, ...selectedColumnsForCsv]));
    setSelectedCollegeForCsv(null);
  };
  const handleRemoveColumnFromCsv = (column): void => {
    if (!column) return;
    const arrayColumnsWithoutCurrent = selectedColumnsForCsv.filter(
      (columnArr) => columnArr.nameThirdBlock !== column.nameThirdBlock,
    );
    dispatch(selectedCollegesAndColumnsAction.setSelectedColumnsForCsv(arrayColumnsWithoutCurrent));
  };
  const handleAddCollegeToCsv = (college): void => {
    if (!college) return;

    if (selectedCollegesForCsv.length >= 20) {
      setIsVisibleBookmark(true);
      setSelectedCollegeForCsv(college);
      return;
    }
    dispatch(selectedCollegesAndColumnsAction.setSelectedCollegesForCsv([college, ...selectedCollegesForCsv]));
    setSelectedCollegeForCsv(null);
  };
  const handleRemoveCollegeFromCsv = (college): void => {
    if (!college) return;
    const arrayCollegesWithoutCurrent = selectedCollegesForCsv.filter((collegeArr) => collegeArr.name !== college.name);
    dispatch(selectedCollegesAndColumnsAction.setSelectedCollegesForCsv(arrayCollegesWithoutCurrent));
  };
  const checkOrCollegeIsInSelectedCollegesForCsv = (college): boolean => {
    if (!selectedCollegesForCsv || selectedCollegesForCsv.length === 0) {
      return false;
    }
    const i = selectedCollegesForCsv.findIndex((collegeArr) => collegeArr.name === college.name);
    return i !== -1;
  };
  const checkOrColumnIsInSelectedColumnForCsv = (column): boolean => {
    if (!selectedColumnsForCsv || selectedColumnsForCsv.length === 0) {
      return false;
    }
    const i = selectedColumnsForCsv.findIndex((columnArr) => columnArr.nameThirdBlock === column.nameThirdBlock);
    return i !== -1;
  };
  const handleOpenOrCloseSecondLevel = (name: string): void => {
    if (!name) return;
    if (openSecondLevel.includes(name)) {
      const filteredSecondLevel = openSecondLevel.filter((column) => column !== name);
      setOpenSecondLevel(filteredSecondLevel);
      return;
    }
    setOpenSecondLevel([...openSecondLevel, name]);
  };

  useEffect(() => {
    const handleClickOutsideSelectListInput = (event) => {
      const listRef: any = selectedListRef.current;
      if (listRef && !listRef.contains(event.target)) {
        setIsOpenAutocompleteLists(false);
      }
    };
    document.addEventListener('click', handleClickOutsideSelectListInput);

    return () => {
      document.removeEventListener('click', handleClickOutsideSelectListInput);
    };
  }, []);

  useEffect(() => {
    if (isVisibleBookmark) {
      setTimeout(() => {
        setIsVisibleBookmark(false);
      }, 1000);
    }
  }, [isVisibleBookmark]);

  return (
    <section className="download-list-modal">
      {stepDownloading === 1 && (
        <>
          <span className="download-list-modal__close" onClick={() => close()} />
          <h4 className="download-list-modal__choose-info">{firstStep.title}</h4>
          <p className="download-list-modal__limit-text">
            {firstStep.descriptionFirstPart}
            <span className="download-list-modal__limit-text-20-schools">{firstStep.descriptionCenterPart}</span>
            {firstStep.descriptionLastPart}
          </p>
          <div className="download-list-modal-cont-select-list-and-clear">
            <div className="download-list-modal-select-list">
              <h3 className="download-list-modal-select-list-title">Select List</h3>
              <div className="download-list-modal-select-list-container-main-content">
                <input
                  readOnly={true}
                  ref={selectedListRef}
                  className="download-list-modal-select-list-input"
                  type="text"
                  placeholder="Select List"
                  value={selectedList.name || ''}
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsOpenAutocompleteLists(!isOpenAutocompleteLists);
                  }}
                />
                <img
                  className={
                    isOpenAutocompleteLists
                      ? 'download-list-modal-select-list-input-arrow download-list-modal-select-list-input-arrow-opened'
                      : 'download-list-modal-select-list-input-arrow'
                  }
                  src={arrowInput}
                  alt="arrow"
                />
                {isOpenAutocompleteLists && (
                  <div className="download-list-modal-select-list-autocomplete">
                    {myLists.map((list) => (
                      <div
                        key={list.id}
                        className="download-list-modal-select-list-autocomplete-item"
                        onClick={() => handleSetSelectedList(list)}
                      >
                        {list.name}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
            <button className="download-list-modal__btn-clear" onClick={() => clearValuesAfterDownload()}>
              Clear
            </button>
          </div>
          <div className="download-list-modal__main-content">
            <div className="download-list-modal__main-content__left-side">
              <div className="download-list-modal__main-content__left-side-title-container">
                <h4 className="download-list-modal__main-content__left-side-title-column">
                  {firstStep.titleLeftColumn}
                </h4>
              </div>
              <div className="download-list-modal__main-content__left-side-list">
                {paginatedColleges &&
                  paginatedColleges.colleges.length > 0 &&
                  paginatedColleges.colleges.map((college, index) => (
                    <div
                      className={
                        selectedCollegesForCsv &&
                        selectedCollegesForCsv.length >= 20 &&
                        !checkOrCollegeIsInSelectedCollegesForCsv(college)
                          ? 'download-list-modal__main-content__left-side-item download-list-modal__main-content__left-side-item-not-allowed'
                          : 'download-list-modal__main-content__left-side-item'
                      }
                      key={college.id}
                    >
                      {isVisibleBookmark && selectedCollegeForCsv.id === college.id ? (
                        index <= 3 ? (
                          <div className={'download-list-modal__bookmark download-list-modal__bookmark--bottom'}>
                            Max Data Selected
                          </div>
                        ) : (
                          <div className="download-list-modal__bookmark">Max Data Selected</div>
                        )
                      ) : index <= 3 ? (
                        <div className="download-list-modal__bookmark download-list-modal__bookmark--bottom hidden">
                          Max Data Selected
                        </div>
                      ) : (
                        <div className="download-list-modal__bookmark hidden">Max Data Selected</div>
                      )}
                      <p
                        className={
                          !checkOrCollegeIsInSelectedCollegesForCsv(college)
                            ? 'download-list-modal__main-content__left-side-item-name'
                            : 'download-list-modal__main-content__left-side-item-name download-list-modal__main-content__left-side-item-name-added'
                        }
                      >
                        {college.name}
                      </p>
                      {!checkOrCollegeIsInSelectedCollegesForCsv(college) ? (
                        <div
                          className="download-list-modal__main-content__left-side-item__add"
                          onClick={() => handleAddCollegeToCsv(college)}
                        >
                          <p className="download-list-modal__main-content__left-side-item__add__text">+Add</p>
                        </div>
                      ) : (
                        <div
                          className="download-list-modal__main-content__left-side-item__remove"
                          onClick={() => handleRemoveCollegeFromCsv(college)}
                        >
                          <p className="download-list-modal__main-content__left-side-item__remove__text">- Remove</p>
                        </div>
                      )}
                    </div>
                  ))}
              </div>
            </div>
            <div className="download-list-modal__main-content__right-side">
              <div className="download-list-modal__main-content__left-side-title-container">
                <h4 className="download-list-modal__main-content__right-side-title-column">Selected Schools</h4>
              </div>
              <div className="download-list-modal__main-content__right-side-list">
                {selectedCollegesForCsv.map((selectedCollege) => (
                  <div className="download-list-modal__main-content__right-side-item" key={selectedCollege.id}>
                    <img
                      onClick={() => handleRemoveCollegeFromCsv(selectedCollege)}
                      className="download-list-modal__main-content__right-side-item__minus"
                      src={minus}
                      alt="minus"
                    />
                    <p className="download-list-modal__main-content__right-side-item__name download-list-modal__main-content__right-side-item-selected-colleges">
                      {selectedCollege.name}
                    </p>
                  </div>
                ))}
              </div>
              <p className="download-list-modal__main-content__right-side__info-selected">
                Schools Chosen{' '}
                <span
                  className={
                    selectedCollegesForCsv.length === 0
                      ? 'download-list-modal__main-content__right-side__info-selected--warning'
                      : 'download-list-modal__main-content__right-side__info-selected--accept'
                  }
                >
                  {selectedCollegesForCsv ? `${selectedCollegesForCsv.length}` : '0'}
                  /20
                </span>
              </p>
            </div>
          </div>
          <button
            className={
              selectedCollegesForCsv.length === 0
                ? 'download-list-modal__btn download-list-modal__btn--not-allowed'
                : 'download-list-modal__btn'
            }
            onClick={() => {
              if (selectedCollegesForCsv.length === 0) return;
              setStepDownloading(2);
            }}
          >
            {firstStep.submitBtn}
          </button>
        </>
      )}
      {stepDownloading === 2 && (
        <>
          <span className="download-list-modal__close" onClick={() => close()} />
          <h4 className="download-list-modal__choose-info">{secondStep.title}</h4>
          <p className="download-list-modal__limit-text">
            {secondStep.descriptionFirstPart}
            <span className="download-list-modal__limit-text-20-schools">{secondStep.descriptionCenterPart}</span>
          </p>
          <div className="download-list-modal__main-content download-list-modal__main-content--second">
            <div className="download-list-modal__main-content__left-side">
              {columnsForDownloading.map((firstLevel) => (
                <div
                  key={firstLevel.nameBlock}
                  className={`download-list-modal__main-content__left-side__first-level ${
                    openFirstLevel === firstLevel.nameBlock ? 'active' : ''
                  }`}
                >
                  <div
                    className="download-list-modal__main-content__left-side__first-level-up"
                    onClick={(e) => {
                      e.stopPropagation();
                      if (openFirstLevel === firstLevel.nameBlock) {
                        setOpenFirstLevel('');
                        setOpenSecondLevel([]);
                        return;
                      }
                      setOpenFirstLevel(firstLevel.nameBlock);
                    }}
                  >
                    <p className="download-list-modal__main-content__left-side__first-level-title">
                      {firstLevel.nameBlock}
                    </p>
                    <span />
                  </div>
                  {openFirstLevel === firstLevel.nameBlock &&
                    firstLevel.secondLevelBlocks.map((secondLevel: any) => (
                      <div
                        key={secondLevel.nameSecondBlock}
                        className={`download-list-modal__main-content__left-side__second-level ${
                          openSecondLevel.includes(secondLevel.nameSecondBlock) ? 'active' : ''
                        }`}
                      >
                        <div
                          onClick={(e) => {
                            e.stopPropagation();
                            handleOpenOrCloseSecondLevel(secondLevel.nameSecondBlock);
                          }}
                          className="download-list-modal__main-content__left-side__second-level-up"
                        >
                          <p className="download-list-modal__main-content__left-side__second-level-title">
                            {secondLevel.nameSecondBlock}
                          </p>
                          <span />
                        </div>
                        {openSecondLevel.includes(secondLevel.nameSecondBlock) &&
                          secondLevel.thirdLevelBlocks.map((thirdLevel, index) => (
                            <div
                              key={index}
                              className={
                                selectedColumnsForCsv.length >= 10 && !checkOrColumnIsInSelectedColumnForCsv(thirdLevel)
                                  ? 'download-list-modal__main-content__left-side__third-level download-list-modal__main-content__left-side__third-level--not-allowed'
                                  : 'download-list-modal__main-content__left-side__third-level'
                              }
                            >
                              {isVisibleBookmark &&
                              selectedColumnForCsv.nameThirdBlock === thirdLevel.nameThirdBlock ? (
                                index <= 3 ? (
                                  <div
                                    className={'download-list-modal__bookmark download-list-modal__bookmark--bottom'}
                                  >
                                    Max Data Selected
                                  </div>
                                ) : (
                                  <div className="download-list-modal__bookmark">Max Data Selected</div>
                                )
                              ) : index <= 3 ? (
                                <div className="download-list-modal__bookmark download-list-modal__bookmark--bottom hidden">
                                  Max Data Selected
                                </div>
                              ) : (
                                <div className="download-list-modal__bookmark hidden">Max Data Selected</div>
                              )}
                              <p className="download-list-modal__main-content__left-side__third-level-name">
                                {thirdLevel.nameThirdBlock}
                              </p>
                              {!checkOrColumnIsInSelectedColumnForCsv(thirdLevel) ? (
                                <div
                                  className="download-list-modal__main-content__left-side-item__add columns"
                                  onClick={() => handleAddColumnToCsv(thirdLevel)}
                                >
                                  <p className="download-list-modal__main-content__left-side-item__add__text">+Add</p>
                                </div>
                              ) : (
                                <div
                                  className="download-list-modal__main-content__left-side-item__remove columns"
                                  onClick={() => handleRemoveColumnFromCsv(thirdLevel)}
                                >
                                  <p className="download-list-modal__main-content__left-side-item__remove__text">
                                    - Remove
                                  </p>
                                </div>
                              )}
                            </div>
                          ))}
                      </div>
                    ))}
                </div>
              ))}
            </div>
            <div className="download-list-modal__main-content__right-side">
              <div className="download-list-modal__main-content__left-side-title-container">
                <h4 className="download-list-modal__main-content__right-side-title-column">
                  {secondStep.titleRightColumn}
                </h4>
              </div>
              <div className="download-list-modal__main-content__right-side-list download-list-modal__main-content__right-side-list--second">
                {selectedColumnsForCsv.map((selectedColumn, index) => (
                  <div className="download-list-modal__main-content__right-side-item" key={index}>
                    <img
                      onClick={() => handleRemoveColumnFromCsv(selectedColumn)}
                      className="download-list-modal__main-content__right-side-item__minus"
                      src={minus}
                      alt="minus"
                    />
                    <p className="download-list-modal__main-content__right-side-item__name">
                      {selectedColumn.nameThirdBlock}
                    </p>
                  </div>
                ))}
              </div>
              <p className="download-list-modal__main-content__right-side__info-selected">
                Chosen Data Parameters
                <span
                  className={
                    selectedColumnsForCsv.length === 0
                      ? 'download-list-modal__main-content__right-side__info-selected--warning'
                      : 'download-list-modal__main-content__right-side__info-selected--accept'
                  }
                >
                  {selectedColumnsForCsv ? `${selectedColumnsForCsv.length}` : '0'}
                  /10
                </span>
              </p>
            </div>
          </div>
          <div className="download-list-modal__cont-bottom-btns">
            <button
              className="download-list-modal__btn download-list-modal__btn--back"
              onClick={() => {
                setStepDownloading(1);
              }}
            >
              Back
            </button>
            <button
              className={
                selectedColumnsForCsv.length === 0
                  ? 'download-list-modal__btn download-list-modal__btn--not-allowed'
                  : 'download-list-modal__btn'
              }
              onClick={() => {
                if (selectedColumnsForCsv.length === 0) return;
                setStepDownloading(3);
              }}
            >
              {secondStep.submitBtn}
            </button>
          </div>
        </>
      )}
    </section>
  );
};
