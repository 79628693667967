import { axiosService } from '.';
import { apiUrls } from '../consts';
import { INote } from '../models';
import { getApiAccessToken } from '../utils';

export const notesService = (function () {
  const endpoint_get_list_notes = async (listId: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.get_list_notes(listId));
  };

  const endpoint_get_note = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.get(apiUrls.note_by_id(id));
  };

  const endpoint_create_note = async (note: Partial<INote>) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.post(apiUrls.create_note, note);
  };

  const endpoint_update_note = async (id: number, text: string) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.put(apiUrls.note_by_id(id), { text });
  };

  const endpoint_delete_note = async (id: number) => {
    axiosService.setAuthorizationToken(getApiAccessToken());

    return axiosService.del(apiUrls.note_by_id(id));
  };

  return {
    endpoint_get_list_notes,
    endpoint_get_note,
    endpoint_create_note,
    endpoint_delete_note,
    endpoint_update_note,
  };
})();
