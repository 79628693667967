import { all, fork, put } from 'redux-saga/effects';

import { EBasicActionTypes } from '../../enums';

import collegesSaga from './colleges.saga';
import efcsSaga from './efcs.saga';
import highschoolsSaga from './highschools.saga';
import offersSaga from './offers.saga';
import statesSaga from './states.saga';
import userSaga from './user.saga';
import usersSaga from './users.saga';
import majorsSaga from './majors.saga';
import myListSaga from './my-lists.saga';
import subscriptionSaga from './subscription.saga';
import notesSaga from './notes.saga';
import collegeNotesSaga from './college-notes.saga';
import listBackupsSaga from './list-backups.saga';
import backupSaga from './backup.saga';
import backupUserListSaga from './backup-user-list.saga';
import houseHoldIncomeSaga from './household-income-saga';
import couponSaga from './coupon.saga';

export default function* root() {
  yield all([
    fork(collegesSaga),
    fork(efcsSaga),
    fork(highschoolsSaga),
    fork(offersSaga),
    fork(statesSaga),
    fork(userSaga),
    fork(usersSaga),
    fork(majorsSaga),
    fork(myListSaga),
    fork(subscriptionSaga),
    fork(notesSaga),
    fork(collegeNotesSaga),
    fork(listBackupsSaga),
    fork(backupSaga),
    fork(backupUserListSaga),
    fork(houseHoldIncomeSaga),
    fork(couponSaga),
  ]);

  yield put({
    type: EBasicActionTypes.INIT_STORE,
  });
}
