import './Admin-configuration.css';
import { NavLink } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { useModal } from 'react-hooks-use-modal';
import axios from 'axios';
// images
import arrowBackBlue from '../../images/arrow-back-blue.svg';
import configBlue from '../../images/config-icon-blue.svg';
// service and models
import { configurationService } from '../../services/configuration.service';
import { IConfigurationModel } from '../../models';
import { AdminModal } from '../admin-modal/AdminModal';

export const AdminConfiguration = () => {
  const [configurations, setConfigurations] = useState<IConfigurationModel[]>();
  const [updatedConfiguration, setUpdatedConfiguration] = useState<IConfigurationModel>();
  const [isOpenUpdateConfigurationModal, setIsOpenUpdateConfigurationModal] = useState<boolean>(false);
  const [status, setStatus] = useState<number>();

  const [Modal, open, close] = useModal('root', {
    preventScroll: false,
    closeOnOverlayClick: false,
  });

  useEffect((): void => {
    const callBackConfigurations = async (): Promise<void> => {
      const configurations: any = await configurationService.endpoint_get_configuration();
      setConfigurations(configurations.data);
    };
    callBackConfigurations();
  }, [status]);

  const handleClickOpenModalUpdateConfiguration = (config: IConfigurationModel): void => {
    open();
    setIsOpenUpdateConfigurationModal(true);
    setUpdatedConfiguration(config);
  };

  const changeConfiguration = async (config: IConfigurationModel) => {
    const { status } = await configurationService.endpoint_update_configuration(config);
    if (status === 200) {
      const appId = `${process.env.REACT_APP_FACEBOOK_ID}`;
      const clientSecret = `${process.env.REACT_APP_FACEBOOK_CLIENT_SECRET}`;
      const objectId = `${process.env.REACT_APP_FACEBOOK_OBJECT_ID}`;
      const scrapeUrl = `https://graph.facebook.com/v13.0/${objectId}?scrape=true`;
      const triggerScrape = async (): Promise<void> => {
        try {
          // Get an app access token using your app ID and client secret
          const response = await axios.get(
            `https://graph.facebook.com/v13.0/oauth/access_token?client_id=${appId}&client_secret=${clientSecret}&grant_type=client_credentials`,
          );

          // Extract the app access token from the response
          const appAccessToken = response.data.access_token;

          // Set the Authorization header with the app access token
          const headers = {
            Authorization: `Bearer ${appAccessToken}`,
            'Content-Type': 'application/json',
          };

          // Trigger the scrape request using the app access token
          await axios.post(scrapeUrl, {}, { headers });
        } catch (error) {
          console.error('Scrape request failed:', error);
          // Handle the error scenario as needed
        }
      };
      // Call the triggerScrape function to initiate the scrape request after build
      setTimeout(() => {
        triggerScrape();
      }, 60000);
    }
    setStatus(status);
  };

  const handleClickChangeStatus = (status: number) => {
    setStatus(status);
  };

  return (
    <section className="admin-configuration">
      <NavLink to={'/dashboard'} className="admin-backups-nav-dashboard">
        <img src={arrowBackBlue} alt="arrow back blue" />
        <p>Back to admin dashboard</p>
      </NavLink>
      <div className="admin-configuration__main">
        <div className="admin-configuration__cont-title">
          <img className="admin-configuration__cont-title__img" src={configBlue} alt="config icon" />
          <h4 className="admin-configuration__cont-title__title">Configuration Parameters</h4>
        </div>
        <div className="admin-configuration__table">
          <div className="admin-configuration__table__header">
            <span>Id</span>
            <span>Name</span>
            <span>Value</span>
            <span>Options</span>
          </div>
          <ul className="admin-configuration__table__list">
            {configurations &&
              configurations.map((config: IConfigurationModel, index: number) => (
                <li
                  key={config.id}
                  className={
                    index % 2 !== 0 ? 'admin-configuration__table__item' : 'admin-configuration__table__item odd'
                  }
                >
                  <p>{config.id}</p>
                  <p>{config.name}</p>
                  <p className={config.id === 3 ? 'link' : ''}>{config.value}</p>
                  <div className="admin-configuration__table__item__cont-btn">
                    <button
                      className="admin-configuration__table__item__btn"
                      onClick={() => handleClickOpenModalUpdateConfiguration(config)}
                    >
                      Edit
                    </button>
                  </div>
                </li>
              ))}
          </ul>
        </div>
      </div>
      <Modal>
        {isOpenUpdateConfigurationModal && updatedConfiguration && (
          <AdminModal
            changeConfiguration={changeConfiguration}
            id={updatedConfiguration.id}
            name={updatedConfiguration.name}
            value={updatedConfiguration.value}
            adminId={updatedConfiguration.adminId}
            status={status || 0}
            changeStatus={handleClickChangeStatus}
            close={() => {
              close();
              setIsOpenUpdateConfigurationModal(false);
            }}
          />
        )}
      </Modal>
    </section>
  );
};
