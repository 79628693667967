import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { clsx } from 'clsx';
import styles from './PowerSearchHeader.module.css';
import { NavigateBackButton } from '../../../../UI/buttons/navigation-buttons/navigate-back-button/NavigateBackButton';
import { Button } from '../../../../UI/buttons/button/Button';
import { EPlatform } from '../../../../enums/platform.enum';
import { SearchInputs } from '../search-inputs/SearchInputs';
import lampsWithStar from '../../../../images/lampsWithStarIcon.svg';
import { IRootState } from '../../../../models';

interface PowerSearchHeaderProps {
  onFiltersOpenClick: () => void;
  onClearSearchHandler: () => void;
  onSearchClickHandler: () => void;
  platform: EPlatform;
  isDisabled?: boolean;
}

export const PowerSearchHeader: React.FC<PowerSearchHeaderProps> = ({
  onFiltersOpenClick,
  onClearSearchHandler,
  onSearchClickHandler,
  platform,
  isDisabled,
}) => {
  const { user } = useSelector((state: IRootState) => state.userState);
  const [isDisabledHeader, setIsDisabledHeader] = useState<boolean>(isDisabled as boolean);

  return (
    <div className={clsx(styles.backgroundContainer, isDisabledHeader && styles.active)}>
      <div className={styles.container}>
        <NavigateBackButton
          route={'/home'}
          color="white"
          textContent="Back to Recommendations"
          additionalClassName={styles.navBackBtn}
        />
        <h1 className={styles.title}>
          Power Search{' '}
          <img className={styles.titleIcon} alt="Lamp with star icon" src={lampsWithStar} draggable={false} />
        </h1>
        <h4 className={styles.subTitle}>Search All Colleges in the U.S. Based on Your Preferences</h4>
        <SearchInputs platform={platform} isFiltersModal={false} />
        {(platform !== EPlatform.DESKTOP || (!user?.subscription && user?.role !== 'admin')) && (
          <div className={styles.buttonsContainer}>
            {(platform === EPlatform.MOBILE || platform === EPlatform.TABLET) && (
              <Button
                textContent="More Filters"
                onClickHandler={() => {
                  onFiltersOpenClick();
                }}
                variant="primaryTransparent"
              />
            )}
            {((platform === EPlatform.DESKTOP && !user?.subscription && user?.role !== 'admin') ||
              platform === EPlatform.MOBILE ||
              platform === EPlatform.TABLET) && (
              <Button
                textContent={`See Results`}
                onClickHandler={() => {
                  onSearchClickHandler();
                  setIsDisabledHeader(true);
                  setTimeout(() => {
                    setIsDisabledHeader(false);
                  }, 5000);
                }}
                variant="primaryBlue"
              />
            )}
          </div>
        )}
        <div className={styles.clearSearchBtn} onClick={onClearSearchHandler}>
          Clear Search
        </div>
      </div>
    </div>
  );
};
